/* eslint-disable jsx-a11y/anchor-is-valid */
import { CheckIcon } from '@heroicons/react/20/solid';
import clsx from 'clsx';
import React from 'react';
import { ConfigAssistantProgressBar_LocalStep } from './ConfigAssistantProgressBar';

export function ConfigAssistantProgressBar_Step({
  step,
  className,
}: {
  step: ConfigAssistantProgressBar_LocalStep;
  className?: string;
}) {
  const Icon = step.step.page.icon ?? CheckIcon;
  return (
    <li key={step.name} className={clsx('relative', className)}>
      {step.status === 'complete' ? (
        <>
          <div
            aria-hidden="true"
            className="absolute inset-0 flex items-center"
          >
            <div className="h-0.5 w-full bg-status-success" />
          </div>
          <a
            href={step.href ?? '#'}
            className="relative flex size-8 items-center justify-center rounded-full bg-status-success hover:bg-status-success-dark"
          >
            <Icon aria-hidden="true" className="size-5 text-white" />
            <span className="sr-only">{step.name}</span>
          </a>
        </>
      ) : step.status === 'current' ? (
        <>
          <div
            aria-hidden="true"
            className="absolute inset-0 flex items-center"
          >
            <div className="h-0.5 w-full bg-gray-200" />
          </div>
          <a
            href={step.href ?? '#'}
            aria-current="step"
            className="relative flex size-8 items-center justify-center rounded-full border-2 border-app-primary bg-white"
          >
            {Icon ? (
              <Icon aria-hidden="true" className="size-5 text-app-primary" />
            ) : (
              <span
                aria-hidden="true"
                className="size-2.5 rounded-full bg-app-primary"
              />
            )}

            <span className="sr-only">{step.name}</span>
          </a>
        </>
      ) : (
        <>
          <div
            aria-hidden="true"
            className="absolute inset-0 flex items-center"
          >
            <div className="h-0.5 w-full bg-gray-200" />
          </div>
          <a
            href={step.href ?? '#'}
            className="group relative flex size-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white hover:border-gray-400"
          >
            <span
              aria-hidden="true"
              className="size-2.5 rounded-full bg-transparent group-hover:bg-gray-300"
            />
            <span className="sr-only">{step.name}</span>
          </a>
        </>
      )}
    </li>
  );
}
