/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  AppEntityUpdatePatch,
  BookingProduct,
  ClubProductPackageMeta,
  ClubProductPackageOffer,
  ClubProductPackageOfferView,
  ClubPurchasePackage,
  ClubPurchasePackageCredits,
  JsonPatchOperation,
} from '@mabadive/app-common-model';
import { jsonPatcher } from '@mabadive/app-common-services';
import { appLogger } from 'src/business/_core/modules/root/logger';
import { diverPurchasePackageBuilder } from '../DiverPurchaseCommonEditorDialog';
import { DiverPurchasePlanFormModel } from './components';

export const diverPurchasePlanEditorDialogUpdator = {
  buildUpdatePatches,
  buildUpdatedProductPatches,
};

function buildUpdatePatches({
  clubReference,
  initialPurchasePackage,
  selectedOffer,
  selectedOfferProductPackageMeta,
  formValue,
  isPaymentEnabled,
  credits,
  associatedDiversIds,
}: {
  clubReference: string;
  initialPurchasePackage: ClubPurchasePackage;
  selectedOffer: ClubProductPackageOfferView;
  selectedOfferProductPackageMeta: ClubProductPackageMeta;
  formValue: DiverPurchasePlanFormModel;
  isPaymentEnabled: boolean;
  credits: ClubPurchasePackageCredits;
  associatedDiversIds: string[];
}): JsonPatchOperation[] {
  if (!!selectedOffer) {
    const initialValue: Partial<ClubPurchasePackage> = {
      ...initialPurchasePackage,
    };

    const isUnitOffer =
      selectedOfferProductPackageMeta?.diveAttributes?.divesCount === 1;

    const {
      totalPrice,
      unitPrice,
      totalPriceThirdPartyCollect,
      unitQuantity,
      discountAmount,
      totalPriceBeforeDiscount,
      totalPriceExtraCosts,
    } = diverPurchasePackageBuilder.buildTotalPrice({
      isPaymentEnabled,
      isUnitOffer,
      totalPriceThirdPartyCollect: formValue.totalPriceThirdPartyCollect,
      unitPrice: formValue.unitPrice,
      unitQuantity: formValue.unitQuantity,
      discountAmount: formValue.discountAmount,
      extraCosts: formValue.extraCosts,
    });

    const finalValue: Partial<ClubPurchasePackage> = {
      ...initialValue,
      diverIds: associatedDiversIds,
      purchaseDate: formValue.purchaseDate,
      extraCosts: formValue.extraCosts,
      isUnitOffer,
      unitPrice,
      totalPriceThirdPartyCollect,
      unitQuantity,
      discountAmount,
      price: totalPrice,
      totalPriceExtraCosts,
      totalPriceBeforeDiscount,
      comment: formValue.comment,
      validityStatus: formValue.validityStatus,
      purchasePaymentStatus: isPaymentEnabled
        ? diverPurchasePackageBuilder.buildPaymentStatus({
            price: totalPrice,
            payedAmount: initialValue.payedAmount ?? 0,
          })
        : initialValue.purchasePaymentStatus,
      credits,
      billedByBookingAgencyId: formValue.billedByBookingAgencyId,
      bookingId: formValue.bookingId,
    };

    buildOfferDiff({
      initialPurchasePackage,
      selectedOffer,
      clubReference,
      finalValue,
      isPaymentEnabled,
    });

    const patchOperations = jsonPatcher.compareObjects(
      initialValue,
      finalValue,
      {
        // else, value won't be deleted by typeorm
        // https://github.com/typeorm/typeorm/issues/2934
        replaceDeleteByNullValue: true,
        attributesToReplaceFully: ['productPackageOffer'],
      },
    );

    return patchOperations;
  } else {
    appLogger.warn('[diverPurchasePlanEditorDialogUpdator] Offer not selected');
  }
}
function buildOfferDiff({
  initialPurchasePackage,
  selectedOffer,
  clubReference,
  finalValue,
  isPaymentEnabled,
}: {
  initialPurchasePackage: ClubPurchasePackage;
  selectedOffer: ClubProductPackageOfferView;
  clubReference: string;
  finalValue: Partial<ClubPurchasePackage>;
  isPaymentEnabled: boolean;
}) {
  if (
    initialPurchasePackage.productPackageOffer.reference !==
    selectedOffer.reference
  ) {
    // update offer
    const offer: ClubProductPackageOffer = {
      reference: selectedOffer.reference,
      clubReference,
      price: isPaymentEnabled ? selectedOffer.price : undefined,
      productPackage: selectedOffer.productPackage,
      thirdPartyCollectPrice: selectedOffer.thirdPartyCollectPrice,
      agencyNonCommissionable: selectedOffer.agencyNonCommissionable,
    };
    finalValue.productPackageOffer = offer;
  }
}

function buildUpdatedProductPatches({
  initialDiveBookingProducts,
  updatedAssociatedBookingProductIds,
  purchasePackageId,
}: {
  initialDiveBookingProducts: BookingProduct[];
  updatedAssociatedBookingProductIds: string[];
  purchasePackageId: string;
}): AppEntityUpdatePatch[] {
  const updatedProducts: AppEntityUpdatePatch[] = [];

  initialDiveBookingProducts.forEach((p) => {
    const isFinalAssociated = updatedAssociatedBookingProductIds.includes(
      p._id,
    );
    const isInitialAssociated = p.purchaseStatus === 'purchased';
    if (isFinalAssociated !== isInitialAssociated) {
      const pFinal: BookingProduct = {
        ...p,
        purchaseStatus: isFinalAssociated ? 'purchased' : 'pending',
        purchasePackageId: isFinalAssociated ? purchasePackageId : null,
      };
      const productPatchOperations = jsonPatcher.compareObjects(p, pFinal, {});
      if (productPatchOperations.length) {
        updatedProducts.push({
          pk: p._id,
          patchOperations: productPatchOperations,
        });
      }
    }
  });
  return updatedProducts;
}
