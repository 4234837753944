import { ClubDiverCertificationLabels } from '@mabadive/app-common-model';
import {
  dateService,
  diverCertificationFormatter,
  nameFormatter,
  phoneLinkBuilder,
  smsLinkBuilder,
} from '@mabadive/app-common-services';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { AppButton } from 'src/business/_core/modules/layout';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import {
  PRO_BookingMemberFull,
  PRO_BookingResume,
} from '../../DiverBookingPage/models';
import { DiveCenterBookingMemberCertification } from './DiveCenterBookingMemberCertification';
import { DiveCenterBookingMembersListDiffItemCardDetails } from './DiveCenterBookingMembersListDiffItemCardDetails';
import { DiveCenterBookingMemberDiff } from './model';

export function DiveCenterBookingMembersListDiffItemCard({
  aggregatedBookingResume,
  mode,
  memberDiff,
  className = '',
}: {
  aggregatedBookingResume: PRO_BookingResume;
  mode: 'original' | 'final';
  memberDiff: DiveCenterBookingMemberDiff;
  className?: string;
}) {
  const { status, original, final, changes } = memberDiff;

  const memberFocus: PRO_BookingMemberFull =
    mode === 'original' ? original : final;
  const memberOther: PRO_BookingMemberFull =
    mode === 'original' ? final : original;

  const diverFocus = memberFocus?.diver;
  const diverOther = memberOther?.diver;

  const ageFocus = useMemo(
    () =>
      diverFocus.birthdate
        ? dateService.getAge(diverFocus.birthdate)
        : diverFocus.age,
    [diverFocus.age, diverFocus.birthdate],
  );
  const ageOther = useMemo(
    () =>
      diverOther.birthdate
        ? dateService.getAge(diverOther.birthdate)
        : diverOther.age,
    [diverOther.age, diverOther.birthdate],
  );

  const certificationLabelsFocus: ClubDiverCertificationLabels = useMemo(
    () =>
      diverCertificationFormatter.formatCertificationLabels({
        diver: diverFocus,
        format: 'short',
      }),
    [diverFocus],
  );
  const certificationLabelsOther: ClubDiverCertificationLabels = useMemo(
    () =>
      diverCertificationFormatter.formatCertificationLabels({
        diver: diverOther,
        format: 'short',
      }),
    [diverOther],
  );
  const isCertificationLabelsUpdated = useMemo(
    () =>
      certificationLabelsFocus?.gazCertificationLabel !==
        certificationLabelsOther?.gazCertificationLabel ||
      certificationLabelsFocus?.mainCertificationLabel !==
        certificationLabelsOther?.mainCertificationLabel ||
      certificationLabelsFocus?.orgReference !==
        certificationLabelsOther?.orgReference ||
      certificationLabelsFocus?.hasCertification !==
        certificationLabelsOther?.hasCertification,
    [
      certificationLabelsFocus?.gazCertificationLabel,
      certificationLabelsFocus?.hasCertification,
      certificationLabelsFocus?.mainCertificationLabel,
      certificationLabelsFocus?.orgReference,
      certificationLabelsOther?.gazCertificationLabel,
      certificationLabelsOther?.hasCertification,
      certificationLabelsOther?.mainCertificationLabel,
      certificationLabelsOther?.orgReference,
    ],
  );
  const phoneNumberFocus = useMemo(
    () => smsLinkBuilder.cleanPhoneNumber(diverFocus?.phoneNumber),
    [diverFocus?.phoneNumber],
  );
  const phoneNumberOther = useMemo(
    () => smsLinkBuilder.cleanPhoneNumber(diverOther?.phoneNumber),
    [diverOther?.phoneNumber],
  );
  const emailAddressFocus = useMemo(
    () => diverFocus?.emailAddress?.trim(),
    [diverFocus?.emailAddress],
  );
  const emailAddressOther = useMemo(
    () => diverOther?.emailAddress?.trim(),
    [diverOther?.emailAddress],
  );

  const unchangedClass = 'grayscale opacity-80';

  const isUniqueMember = aggregatedBookingResume.bookingMembers.length === 1;

  return !memberFocus ? null : (
    <div className={className}>
      <div className="w-full flex flex-wrap gap-2 items-center justify-between">
        <div className="flex-grow flex gap-2 justify-start items-center">
          <div
            className={`flex flex-col justify-center text-left ${className}`}
          >
            <p
              className={`leading-4 font-bold text-sm  text-app-blue whitespace-nowrap ${
                memberFocus?.bookingMember?.details?.creationStatus === 'draft'
                  ? 'italic'
                  : ''
              }`}
            >
              <span
                className={clsx(
                  diverFocus.firstName === diverOther.firstName &&
                    diverFocus.lastName === diverOther.lastName &&
                    unchangedClass,
                )}
              >
                {nameFormatter.formatFullName(diverFocus, {
                  format: 'firstName-first',
                })}
              </span>
              {ageFocus > 0 && (
                <span
                  className={clsx(
                    'font-bold text-app-primary',
                    ageFocus === ageOther && unchangedClass,
                  )}
                >
                  {' '}
                  {ageFocus} ans
                </span>
              )}
              {!!(diverFocus.birthdate || diverOther.birthdate) &&
                diverFocus.birthdate?.getTime() !==
                  diverOther.birthdate?.getTime() && (
                  <span
                    className={clsx(
                      'font-bold',
                      !diverFocus.birthdate
                        ? 'line-through text-status-error' // donnée supprimée!
                        : 'text-app-primary',
                    )}
                  >
                    {' '}
                    (
                    {dateService.formatUTC(
                      diverFocus.birthdate ?? diverOther.birthdate,
                      'DD/MM/YYYY',
                    )}
                    )
                  </span>
                )}
            </p>
            <p className="leading-4 flex gap-2">
              {certificationLabelsFocus.hasCertification && (
                <span
                  className={clsx(
                    'uppercase text-gray-600 font-bold',
                    !isCertificationLabelsUpdated && unchangedClass,
                  )}
                >
                  <DiveCenterBookingMemberCertification
                    certificationLabels={certificationLabelsFocus}
                  />
                </span>
              )}
              {diverFocus._id === memberFocus.booking.bookingContactDiverId &&
                !isUniqueMember && (
                  <span className="uppercase px-0.5 text-app-orange font-medium text-app-xxs">
                    contact résa
                  </span>
                )}
            </p>
          </div>
        </div>
        <div className="flex-0 flex gap-2">
          {(phoneNumberFocus || emailAddressFocus) && (
            <div className="hidden md:flex gap-2">
              {phoneNumberFocus && (
                <AppButton
                  fullWidth={false}
                  icon={AppHeroIcons.phone}
                  color={'phone-outline'}
                  href={`tel:${phoneNumberFocus}`}
                  // size="small"
                  onClick={(e) => e.stopPropagation()}
                  className={clsx(
                    'whitespace-nowrap',
                    phoneNumberOther === phoneNumberFocus && unchangedClass,
                  )}
                >
                  <span className="">
                    {phoneLinkBuilder.formatPhoneNumber(phoneNumberFocus)}
                  </span>
                </AppButton>
              )}
              {emailAddressFocus && (
                <AppButton
                  className={clsx(
                    'whitespace-nowrap truncate',
                    emailAddressFocus === emailAddressOther && unchangedClass,
                  )}
                  color={'mail-outline'}
                  icon={AppHeroIcons.contact}
                  href={`mailto:${emailAddressFocus}`}
                  // size="small"
                  onClick={(e) => e.stopPropagation()}
                  target="mabadive_mail_client"
                >
                  {/* {emailAddress} */}
                  <span className="">{emailAddressFocus}</span>
                </AppButton>
              )}
            </div>
          )}
        </div>
      </div>

      <DiveCenterBookingMembersListDiffItemCardDetails
        className="mt-2"
        aggregatedBookingResume={aggregatedBookingResume}
        memberDiff={memberDiff}
        mode={mode}
      />
    </div>
  );
}
