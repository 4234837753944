/* eslint-disable @typescript-eslint/no-unused-vars */
import clsx from 'clsx';
import React from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { useClubResume } from 'src/business/club/data/hooks';
import {
  AppFormControlRHF,
  AppInputBooleanSwitchRHF,
  AppInputRHF,
} from 'src/lib/form';
import { useAppSecurityUserHasRole } from '../../../../../../business/auth/services';
import { ClubSettingsInquiryEditPagePanelLocalState } from '../ClubSettingsInquiryEditPagePanel/useClubSettingsInquiryEditPagePanelLocalState.hook';
import { AppInquiryFormModel } from './model';

export const ClubSettingsInquiryEditPageForm_TabMainSettings = ({
  localState,
  form,
  className,
}: {
  localState: ClubSettingsInquiryEditPagePanelLocalState;
  form: UseFormReturn<AppInquiryFormModel>;
  className?: string;
}) => {
  const { state, data } = localState;

  const clubResume = useClubResume();
  const isMultiDiveCenters = clubResume.diveCenters.length > 1;

  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const [color, inquiryType, enabled, autoActivationEnabled] = useWatch({
    control,
    name: [
      'settings.color',
      'type',
      'settings.enabled',
      'settings.autoActivation.enabled',
    ],
  });

  const isSuperAdmin = useAppSecurityUserHasRole('super-admin');

  return (
    <div
      className={clsx('app-card p-4 border-t-8', className)}
      style={{
        borderColor: color,
      }}
    >
      <div className="grid gap-4">
        <div
          className={clsx('w-full grid sm:grid-cols-2 md:grid-cols-4 gap-4')}
        >
          <AppFormControlRHF
            className={'w-full'}
            label="Durée de validité (en année)"
            control={control}
            validation={{
              rules: {
                min: 0,
              },
            }}
            name={'settings.docValidityInYears'}
            required={false}
            renderComponent={(props) => (
              <AppInputRHF {...props} fullWidth type="number" />
            )}
          />
          <AppFormControlRHF
            label={'Signature manuscrite'}
            helpDescription={
              'Le client devra reproduire sa signature manuscrite avec le doigt ou la souris'
            }
            control={control}
            name={'settings.consents.padSignature.enabled'}
            renderComponent={(props) => (
              <AppInputBooleanSwitchRHF {...props} label={'Activé'} />
            )}
          />
          <AppFormControlRHF
            label={"Exiger l'acceptation du RGPD"}
            control={control}
            name={'settings.consents.gdpr.enabled'}
            renderComponent={(props) => (
              <AppInputBooleanSwitchRHF {...props} label={'Activé'} />
            )}
          />
          {isSuperAdmin && (
            <AppFormControlRHF
              className={'w-full'}
              label="Activé"
              control={control}
              name={'settings.enabled'}
              visibility="super-admin"
              renderComponent={(props) => (
                <AppInputBooleanSwitchRHF
                  {...props}
                  className="mt-1"
                  label={'Activé'}
                  labelToggleFalse="Désactivé"
                />
              )}
            />
          )}
        </div>
        <div className="">
          <AppFormControlRHF
            className="w-full"
            label="Commentaire privé 🚫"
            control={control}
            name="settings.privateComment"
            renderComponent={(props) => (
              <AppInputRHF
                {...props}
                fullWidth
                multiline
                rowsMax={15}
                rows={2}
              />
            )}
          />
          {/* <AppFormControlRHF
                className="w-full"
                label="Commentaire client 👨‍🦰👩‍🦰"
                control={control}
                name="settings.details.customerComment"
                renderComponent={(props) => (
                  <AppInputRHF
                    {...props}
                    fullWidth
                    multiline
                    rowsMax={15}
                    rows={2}
                  />
                )}
              /> */}
        </div>
      </div>
    </div>
  );
};
