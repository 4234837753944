/* eslint-disable @typescript-eslint/no-unused-vars */
import { diveGazFormatter } from '@mabadive/app-common-services';
import { default as React } from 'react';
import { useAppSecurityUserClubAuthorizations } from 'src/business/auth/services';
import { AppFeatureListItem } from 'src/business/club/modules/_common/ui';
import { useAppCurrencyMain } from 'src/pages/_components/options';
import {
  useClubResume,
  useDiveCenterResume,
} from '../../../../../business/club/data/hooks';

export const ClubSettingsCustomerViewCard_Participant = () => {
  const mainCurrency = useAppCurrencyMain();
  const diveCenterResume = useDiveCenterResume();
  const clubResume = useClubResume();

  const clubSettings = clubResume?.clubSettings;
  const customerSettings = clubSettings?.customer;
  const diveCenterPublicSettings = diveCenterResume?.publicSettings;

  const az = useAppSecurityUserClubAuthorizations();

  return (
    <>
      <h2 className="py-2  text-left uppercase text-lg leading-6 font-medium text-gray-800">
        Plongée
      </h2>
      <ul className="relative grid gap-2 p-2 sm:px-4 font-bold text-gray-600">
        <AppFeatureListItem
          enabled={customerSettings?.diving?.medicalCertificateDate}
          label="Certificat médical (date)"
        />
        <AppFeatureListItem
          enabled={customerSettings?.diving?.medicalCertificateChecked}
          label="Certificat médical vérifié (oui/non)"
        />
        <AppFeatureListItem
          enabled={customerSettings?.diving?.medicalFormDate}
          label="Formulaire médical (date)"
        />
        <AppFeatureListItem
          enabled={customerSettings?.diving?.medicalFormChecked}
          label="Formulaire médical vérifié (oui/non)"
        />
        <AppFeatureListItem
          enabled={customerSettings?.diving?.insurance}
          label="Assurance (oui/non, nom/numéro, date d'expiration)"
        />
        <AppFeatureListItem
          enabled={customerSettings?.diving?.divingTechnicalLevel}
          label="Niveau technique (débutant, confirmé...)"
        />
        <AppFeatureListItem
          enabled={customerSettings?.diving?.photoInterest}
          label="Photo / vidéo sous-marine (oui/non)"
        />
        <AppFeatureListItem
          enabled={customerSettings?.diving?.freeDiveCertificationReference}
          label="Niveau d'apnée (A1, A2, A3, A4)"
        />
        <AppFeatureListItem
          enabled={customerSettings?.diving?.safety}
          label="Niveau secourisme (RIFAP)"
        />
        <AppFeatureListItem
          enabled={customerSettings?.diving?.nitrox}
          label="Niveau nitrox (simple/confirmé)"
        />
        {diveCenterPublicSettings?.diving?.defaultGaz?.gazType && (
          <AppFeatureListItem
            enabled={true}
            isDiveCenterSpecificFeature={true}
            label={`Gaz par défaut: ${diveGazFormatter.formatGazType(
              diveCenterPublicSettings?.diving?.defaultGaz?.gazType,
              { format: 'long' },
            )}`}
          />
        )}
      </ul>
    </>
  );
};
