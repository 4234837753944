/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { useRedirect } from 'src/business/_core/data/hooks';
import { AppPageContainer } from 'src/business/_core/modules/layout';
import {
  useClubResume,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import { ClubDialogsProvider } from 'src/pages/_dialogs';

import useRouter from 'use-react-router';
import {
  ClubSettingsServicesFreeDiveViewPanel,
  ClubSettingsServicesStoreViewPanel,
} from './tabs';
import { ClubSettingsServicesActivitiesViewPanel } from './tabs/01.activities';
import { ClubSettingsServicesFirstDiveViewPanel } from './tabs/02.first-dive';
import { ClubSettingsServicesTrainingViewPanel } from './tabs/03.training';
import { ClubSettingsServicesExplosViewPanel } from './tabs/04.explos';
import { ClubSettingsServicesOthersViewPanel } from './tabs/05.others';
import { ClubSettingsServicesExtrasViewPanel } from './tabs/06.extra';
import {
  ClubSettingsServicesPageLocalState,
  useClubSettingsServicesPageLocalState,
} from './useClubSettingsServicesPageLocalState.hook';

export const ClubSettingsServicesPage = () => {
  const { match } = useRouter();

  const redirectTo = useRedirect();

  const diveCenterResume = useDiveCenterResume();
  const clubResume = useClubResume();

  const clubSettings = clubResume?.clubSettings;
  const localState: ClubSettingsServicesPageLocalState =
    useClubSettingsServicesPageLocalState();
  const { state, dialogs } = localState;

  const selectedTab = state.tabs.selected;

  return (
    <AppPageContainer className={'bg-white'}>
      <ClubDialogsProvider dialogsState={localState.dialogs.state}>
        {/* <AppPageContentContainer
          paddingBottom={false}
          className="bg-gray-50 app-p-content"
        >
          <AppTabsTailwind
            className="w-full"
            tabs={state.tabs.data}
            selected={selectedTab}
            theme="underline"
            breakpoint="sm"
            onChange={(tabId) => state.tabs.setSelected(tabId)}
          />
          <div
            className={clsx(
              'mt-4 app-card p-4',
              state.editEnabled && 'app-card-highlight',
            )}
            onClick={() => {
              state.editEnabled &&
                dialogs.clubSettingsServices.open(selectedTab);
            }}
          > */}
        {selectedTab === 'activities' ? (
          <ClubSettingsServicesActivitiesViewPanel localState={localState} />
        ) : selectedTab === 'first-dive' ? (
          <ClubSettingsServicesFirstDiveViewPanel localState={localState} />
        ) : selectedTab === 'training' ? (
          <ClubSettingsServicesTrainingViewPanel localState={localState} />
        ) : selectedTab === 'explo' ? (
          <ClubSettingsServicesExplosViewPanel localState={localState} />
        ) : selectedTab === 'free-dive' ? (
          <ClubSettingsServicesFreeDiveViewPanel localState={localState} />
        ) : selectedTab === 'other' ? (
          <ClubSettingsServicesOthersViewPanel localState={localState} />
        ) : selectedTab === 'extra' ? (
          <ClubSettingsServicesExtrasViewPanel localState={localState} />
        ) : selectedTab === 'store' ? (
          <ClubSettingsServicesStoreViewPanel localState={localState} />
        ) : null}

        {/* {state.editMode === 'super-admin-only' ? (
              <div className="my-8 text-gray-500 flex md:flex-col flex-wrap gap-4 justify-between items-start">
                <p>Pour modifier la configuration, veuillez nous contacter:</p>

                <AppButton
                  className=""
                  color="primary-outline-light"
                  icon={AppHeroIcons.contact}
                  onClick={() => redirectTo(appRouteBuilder.getContactRoute())}
                >
                  Contactez-nous
                </AppButton>
              </div>
            ) : state.editEnabled ? (
              state.tabs.selected === 'first-dive' ? null : ( // on cache le bouton "Modifier"
                <div className="mt-8 mb-2">
                  <AppButton
                    color={'primary-outline-light'}
                    size="normal"
                    icon={AppIcons.action?.edit}
                    // onClick={(e) => {
                    //   e.stopPropagation();
                    // }}
                  >
                    Modifier
                  </AppButton>
                </div>
              )
            ) : null}
          </div> */}
        {/* </AppPageContentContainer> */}
      </ClubDialogsProvider>
    </AppPageContainer>
  );
};
