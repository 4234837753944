import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/24/outline';
import {
  EQUIPMENT_TYPES,
  EQUIPMENT_TYPES_DEFAULT_CUSTOMER_SPACE,
} from '@mabadive/app-common-model';
import { equipmentFormatter } from '@mabadive/app-common-services';
import clsx from 'clsx';
import React from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { useClubResume, useClubSettings } from 'src/business/club/data/hooks';
import {
  AppFormControlRHF,
  AppInputBooleanRHF,
  AppInputBooleanSwitchRHF,
} from 'src/lib/form';
import { useMediaSizeTailwind } from '../../../../../../business/_core/modules/layout';
import { AppIconsDiveEquipmentType } from '../../../../../../business/_core/modules/layout/icons';
import { CustomerSpaceConfigFormModel } from '../model';

// const CLUB_SETTINGS_UI_PLANNING_SHOW_PASS_MODES_OPTIONS =
//   CLUB_SETTINGS_UI_PLANNING_SHOW_PASS_MODES.map((value) => {
//     const option: ValueLabel<ClubSettingsUIPlanningShowPassMode> = {
//       label: (value === 'consumed'
//         ? 'consommé (8/10)'
//         : 'restant (2)'
//       ).toUpperCase(),
//       value,
//     };
//     return option;
//   });

export function CustomerSpaceConfigFormCustomerUpdate_DiversEquipmentInfo({
  form,
  displayExpertMode,
}: {
  form: UseFormReturn<CustomerSpaceConfigFormModel>;
  displayExpertMode: boolean;
}) {
  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const clubResume = useClubResume();
  const customerSettings = clubResume?.clubSettings?.customer;
  const clubReference = clubResume.reference;
  const formPrefix = 'clubSettings.publicSettings.customerSpace' as const;
  const clubSettings = useClubSettings();

  const [diversEquipmentInfo, selfEquipmentEnabled, bodyMeasurementsEnabled] =
    useWatch({
      control,
      name: [
        `${formPrefix}.defaultBookingConfig.pendingList.diversEquipmentInfo`,
        `${formPrefix}.displayFields.diversEquipmentInfo.selfEquipment.enabled`,
        `${formPrefix}.displayFields.diversEquipmentInfo.bodyMeasurements.enabled`,
      ],
    });
  const mediaSize = useMediaSizeTailwind();
  const isSmallWidth = mediaSize === 'xxs' || mediaSize === 'xs';

  return (
    <Disclosure defaultOpen={!isSmallWidth}>
      {({ open }) => (
        <div className={clsx(open && 'border-l-4 border-gray-200')}>
          <Disclosure.Button
            className={clsx(
              'flex w-full justify-between rounded-lg text-left text-sm font-medium',
              open &&
                'focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75',
            )}
          >
            <div onClick={(e) => e.stopPropagation()}>
              <AppFormControlRHF
                className="my-1 w-full xs:col-span-6 sm:col-span-6"
                control={control}
                name={`${formPrefix}.defaultBookingConfig.pendingList.diversEquipmentInfo`}
                renderComponent={(props) => (
                  <AppInputBooleanSwitchRHF
                    className="w-full"
                    {...props}
                    label={'équipement'}
                    label2={'Equipement perso, besoins, tailles...'}
                    theme="success"
                    onChange={(value) => {
                      if (value) {
                        setValue(
                          `${formPrefix}.defaultBookingConfig.customerUi.enableCustomerUpdate`,
                          value,
                        );
                        setValue(
                          `${formPrefix}.displayFields.diversEquipmentInfo.selfEquipment.enabled`,
                          value,
                        );
                        enableSelfEquipmentDefaultConfig();
                      } else {
                        setValue(
                          `${formPrefix}.defaultBookingConfig.pendingList.diversExpectedDiveInfo`,
                          false,
                        );
                      }
                    }}
                  />
                )}
              />
            </div>
            {diversEquipmentInfo && (
              <div className="flex gap-2 text-gray-600 ">
                <span>Configuration avancée</span>
                <ChevronUpIcon
                  className={`${
                    open ? 'rotate-180 transform' : ''
                  } h-5 w-5 text-gray-500`}
                />
              </div>
            )}
          </Disclosure.Button>

          <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
            {diversEquipmentInfo && (
              <div className="grid gap-4">
                <div className="grid gap-2">
                  <h2 className="flex gap-2">
                    <AppInputBooleanSwitchRHF
                      control={control}
                      name={`${formPrefix}.displayFields.diversEquipmentInfo.selfEquipment.enabled`}
                      theme="secondary"
                      onChange={(enabled) => {
                        if (enabled) {
                          enableSelfEquipmentDefaultConfig();
                        }
                      }}
                      label={
                        <span
                          className={clsx(
                            'text-app-secondary',
                            !selfEquipmentEnabled && 'opacity-50',
                          )}
                        >
                          Equipement perso
                        </span>
                      }
                    />
                  </h2>
                  {selfEquipmentEnabled && (
                    <div className="border-l border-app-secondary pl-4 grid gap-2">
                      <div className="text-app-secondary-dark mb-2">
                        Le client coche les équipements dont il dispose:
                      </div>
                      <div className="mb-3 w-full grid grid-cols-2 sm:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-x-4 gap-y-2">
                        <div className="flex flex-col gap-2">
                          <AppFormControlRHF
                            control={control}
                            label="Plongeur complètement équipé?"
                            name={`${formPrefix}.displayFields.diversEquipmentInfo.selfEquipment.hasSelfEquipment.edit`}
                            renderComponent={(props) => (
                              <AppInputBooleanSwitchRHF {...props} />
                            )}
                          />
                        </div>
                        {EQUIPMENT_TYPES.map((equipmentType) => (
                          <div
                            className="flex flex-col gap-2"
                            key={equipmentType}
                          >
                            <AppFormControlRHF
                              control={control}
                              label={equipmentFormatter.formatEquipmentType(
                                equipmentType,
                              )}
                              name={`${formPrefix}.displayFields.diversEquipmentInfo.selfEquipment.details.${equipmentType}.edit`}
                              renderComponent={(props) => {
                                const Icon =
                                  AppIconsDiveEquipmentType[equipmentType];

                                return (
                                  <AppInputBooleanSwitchRHF
                                    {...props}
                                    label={<Icon className="w-6 h-6" />}
                                  />
                                );
                              }}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>{' '}
                <div className="grid gap-2">
                  <h2 className="flex gap-2">
                    <AppInputBooleanSwitchRHF
                      theme="secondary"
                      control={control}
                      name={`${formPrefix}.displayFields.diversEquipmentInfo.bodyMeasurements.enabled`}
                      onChange={(enabled) => {
                        if (enabled) {
                          setValue(
                            `${formPrefix}.displayFields.diversEquipmentInfo.bodyMeasurements.size.edit`,
                            true,
                          );
                          setValue(
                            `${formPrefix}.displayFields.diversEquipmentInfo.bodyMeasurements.weight.edit`,
                            true,
                          );
                          setValue(
                            `${formPrefix}.displayFields.diversEquipmentInfo.bodyMeasurements.shoeSize.edit`,
                            true,
                          );
                        }
                      }}
                      label={
                        <span
                          className={clsx(
                            'text-app-secondary',
                            !bodyMeasurementsEnabled && 'opacity-50',
                          )}
                        >
                          Mensurations du plongeur
                        </span>
                      }
                    />
                  </h2>
                  {bodyMeasurementsEnabled && (
                    <div className="border-l border-app-secondary pl-4 grid gap-2">
                      <div className="text-app-secondary-dark mb-2">
                        Le client précise ses mensurations pour pouvoir évaluer
                        la taille des équipements dont il a besoin:
                      </div>
                      <div className="mb-3 w-full grid grid-cols-2 sm:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-x-4 gap-y-2">
                        <div className="flex flex-col gap-2">
                          <AppFormControlRHF
                            control={control}
                            label="Taille (cm)"
                            name={`${formPrefix}.displayFields.diversEquipmentInfo.bodyMeasurements.size.edit`}
                            renderComponent={(props) => (
                              <AppInputBooleanSwitchRHF {...props} />
                            )}
                          />
                          {displayExpertMode && (
                            <AppFormControlRHF
                              control={control}
                              name={`${formPrefix}.displayFields.diversEquipmentInfo.bodyMeasurements.size.required`}
                              renderComponent={(props) => (
                                <AppInputBooleanRHF
                                  type="checkbox"
                                  className="-ml-2 scale-125 block mx-auto mt-1"
                                  label="Obligatoire"
                                  {...props}
                                />
                              )}
                            />
                          )}
                        </div>{' '}
                        <div className="flex flex-col gap-2">
                          <AppFormControlRHF
                            control={control}
                            label="Poids (Kg)"
                            name={`${formPrefix}.displayFields.diversEquipmentInfo.bodyMeasurements.weight.edit`}
                            renderComponent={(props) => (
                              <AppInputBooleanSwitchRHF {...props} />
                            )}
                          />
                          {displayExpertMode && (
                            <AppFormControlRHF
                              control={control}
                              name={`${formPrefix}.displayFields.diversEquipmentInfo.bodyMeasurements.weight.required`}
                              renderComponent={(props) => (
                                <AppInputBooleanRHF
                                  type="checkbox"
                                  className="-ml-2 scale-125 block mx-auto mt-1"
                                  label="Obligatoire"
                                  {...props}
                                />
                              )}
                            />
                          )}
                        </div>
                        <div className="flex flex-col gap-2">
                          <AppFormControlRHF
                            control={control}
                            label="Pointure"
                            name={`${formPrefix}.displayFields.diversEquipmentInfo.bodyMeasurements.shoeSize.edit`}
                            renderComponent={(props) => (
                              <AppInputBooleanSwitchRHF {...props} />
                            )}
                          />
                          {displayExpertMode && (
                            <AppFormControlRHF
                              control={control}
                              name={`${formPrefix}.displayFields.diversEquipmentInfo.bodyMeasurements.shoeSize.required`}
                              renderComponent={(props) => (
                                <AppInputBooleanRHF
                                  type="checkbox"
                                  className="-ml-2 scale-125 block mx-auto mt-1"
                                  label="Obligatoire"
                                  {...props}
                                />
                              )}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </Disclosure.Panel>
        </div>
      )}
    </Disclosure>
  );
  function enableSelfEquipmentDefaultConfig() {
    setValue(
      `${formPrefix}.displayFields.diversEquipmentInfo.selfEquipment.hasSelfEquipment.edit`,
      true,
    );
    EQUIPMENT_TYPES_DEFAULT_CUSTOMER_SPACE.forEach((equipmentType) => {
      setValue(
        `${formPrefix}.displayFields.diversEquipmentInfo.selfEquipment.details.${equipmentType}.edit`,
        true,
      );
    });
  }
}
