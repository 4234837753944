/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ClubProductPackageOfferView,
  ClubProductPackageOfferViewDisplayGroup,
  ClubSettings,
} from '@mabadive/app-common-model';
import { buildFirstDiveCustomTypes } from '../../../tabs/02.first-dive/view/ClubSettingsServicesFirstDiveViewPanelCustomTypes';

export const offersDisplayGroupsBuilder_FirstDive = {
  buildGroups_FirstDive,
};

function buildGroups_FirstDive({
  clubSettings,
  offers,
}: {
  clubSettings: ClubSettings;
  offers: ClubProductPackageOfferView[];
}) {
  const offersByDisplayGroups: ClubProductPackageOfferViewDisplayGroup[] = [];
  const customTypes = buildFirstDiveCustomTypes(clubSettings);

  if (customTypes.length > 1) {
    const { groupedOffers, remainingOffers } = offers.reduce(
      (acc, offer) => {
        const trainingRef =
          offer.productPackage?.diveAttributes?.firstDiveTrainingReference;
        // Vérifier si l'offre correspond à un type personnalisé
        const customType = customTypes.find(
          (type) => type.originalRef === trainingRef,
        );
        if (customType) {
          // Ajouter l'offre au groupe correspondant
          if (!acc.groupedOffers[customType.labelLong]) {
            acc.groupedOffers[customType.labelLong] = [];
          }
          acc.groupedOffers[customType.labelLong].push(offer);
        } else {
          // Ajouter l'offre aux "remainingOffers" si elle n'appartient à aucun type personnalisé
          acc.remainingOffers.push(offer);
        }

        return acc;
      },
      {
        groupedOffers: {} as Record<string, ClubProductPackageOfferView[]>,
        remainingOffers: [] as ClubProductPackageOfferView[],
      },
    );

    // Transformer groupedOffers en un tableau d'objets avec un titre et des offres
    for (const [title, offers] of Object.entries(groupedOffers)) {
      offersByDisplayGroups.push({ title, offers });
    }

    // Ajouter les offres restantes dans un groupe "Autres"
    if (remainingOffers.length > 0) {
      offersByDisplayGroups.push({
        title: 'Autres',
        offers: remainingOffers,
      });
    }
  } else {
    // Si aucun type personnalisé, ajouter toutes les offres sans tri
    offersByDisplayGroups.push({
      offers: offers,
    });
  }

  return offersByDisplayGroups;
}
