/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback } from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import {
  useClubResume,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import { AppFormControlRHF } from 'src/lib/form/components/AppFormControl/AppFormControlRHF';

import { ClubOfferState } from '@mabadive/app-common-model';
import { AppInputBooleanSwitchRHF, AppInputRHF } from 'src/lib/form';
import { ClubSettingsSection } from 'src/pages/SE-settings/_core';
import { AppIconsSocialMedia } from '../../../../../../../business/_core/modules/layout/icons';
import {
  ClubSettingsCommunicationDialogInitialState,
  ClubSettingsCommunicationFormModel,
} from '../../../_model';

export const ClubSettingsCommunicationEmailAutoForm_General = ({
  form,
  initialState,
}: {
  form: UseFormReturn<ClubSettingsCommunicationFormModel>;
  initialState: ClubSettingsCommunicationDialogInitialState;
}) => {
  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const clubResume = useClubResume();

  const diveCenterResume = useDiveCenterResume();
  const defaultEmailAddress =
    diveCenterResume?.publicData?.contact?.emailAddress;

  const defaultFromName = diveCenterResume?.publicData?.brand?.name;

  const [
    directManualEnabled,
    directAutoEnabled,
    autoModeFromName,
    autoModeReplyToAddress,
  ] = useWatch({
    control,
    name: [
      'clubSettings.communication.channels.email.directMode.directManualEnabled',
      'clubSettings.communication.channels.email.directMode.directAutoEnabled',
      'clubSettings.communication.channels.email.directMode.fromName',
      'clubSettings.communication.channels.email.directMode.replyToAddress',
    ],
  });

  const directModeEnabled = directManualEnabled || directAutoEnabled;

  const trustedStates: ClubOfferState[] = ['trial', 'active', 'demo'];
  const isTrustedClub = trustedStates.includes(clubResume?.offer?.state);

  const setDefaultReplyTo = useCallback(() => {
    if (defaultFromName && !autoModeFromName) {
      setValue(
        'clubSettings.communication.channels.email.directMode.fromName',
        defaultFromName,
        {
          shouldDirty: true,
          shouldTouch: true,
          shouldValidate: true,
        },
      );
    }
    if (defaultEmailAddress && !autoModeReplyToAddress) {
      setValue(
        'clubSettings.communication.channels.email.directMode.replyToAddress',
        defaultEmailAddress,
        {
          shouldDirty: true,
          shouldTouch: true,
          shouldValidate: true,
        },
      );
    }
  }, [
    autoModeFromName,
    autoModeReplyToAddress,
    defaultEmailAddress,
    defaultFromName,
    setValue,
  ]);

  return (
    <ClubSettingsSection
      title="Emails direct"
      comment="Configuration des e-mails envoyés par mabadive"
    >
      <div className="grid gap-y-4">
        <AppFormControlRHF
          control={control}
          label={'Envoi direct des messages manuels ⚡'}
          name={
            'clubSettings.communication.channels.email.directMode.directManualEnabled'
          }
          disabled={!isTrustedClub}
          helpDescription={
            isTrustedClub
              ? 'Quand vous envoyez un message manuellement, le mail est envoyé par mabadive, sans ouvrir votre logiciel de messagerie.'
              : 'Fonctionnalité désactivée sur les comptes de démo'
          }
          renderComponent={(props) => (
            <AppInputBooleanSwitchRHF
              {...props}
              label="email"
              icon={
                <AppIconsSocialMedia.mail className="h-6 w-6 text-app-mail" />
              }
              onChange={(autoModeEnabled) => {
                setValue(
                  'clubSettings.communication.booking.email.mode',
                  autoModeEnabled ? 'auto' : 'manual',
                );
                setValue(
                  'clubSettings.communication.session.email.mode',
                  autoModeEnabled ? 'auto' : 'manual',
                );
                if (autoModeEnabled) {
                  setDefaultReplyTo();
                }
              }}
            />
          )}
        />
        <AppFormControlRHF
          control={control}
          label={'Envoi automatique de messages (confirmation, rappel) 🚀'}
          name={
            'clubSettings.communication.channels.email.directMode.directAutoEnabled'
          }
          disabled={!isTrustedClub}
          helpDescription={
            isTrustedClub
              ? 'Mabadive peut envoyer des e-mails automatiques (ex: vente en ligne)'
              : 'Fonctionnalité désactivée sur les comptes de démo'
          }
          renderComponent={(props) => (
            <AppInputBooleanSwitchRHF
              {...props}
              label="email"
              icon={
                <AppIconsSocialMedia.mail className="h-6 w-6 text-app-mail" />
              }
              onChange={(autoModeEnabled) => {
                if (autoModeEnabled) {
                  setDefaultReplyTo();
                }
              }}
            />
          )}
        />
        <div className="flex flex-wrap gap-x-4 gap-y-2">
          {directModeEnabled && (
            <>
              <AppFormControlRHF
                control={control}
                className="w-80"
                name={
                  'clubSettings.communication.channels.email.directMode.fromName'
                }
                label={'Nom de l\'expéditeur'}
                required={directModeEnabled}
                helpDescription="Quel est le nom de l'expéditeur dans l'email reçu par les clients"
                renderComponent={(props) => (
                  <AppInputRHF className="w-full" type="text" {...props} />
                )}
              />
              <AppFormControlRHF
                control={control}
                className="w-80"
                name={
                  'clubSettings.communication.channels.email.directMode.replyToAddress'
                }
                label={'Adresse de réponse'}
                required={directModeEnabled}
                helpDescription="A quelle adresse les clients peuvent-ils répondre aux e-mails?"
                validation={{
                  email: true,
                }}
                renderComponent={(props) => (
                  <AppInputRHF className="w-full" type="text" {...props} />
                )}
              />
              <AppFormControlRHF
                control={control}
                className="w-80"
                name={
                  'clubSettings.communication.channels.email.directMode.copyToAddress'
                }
                label={'Recevoir une copie à'}
                required={false}
                helpDescription="Recevoir une copie de chaque mail à cette adresse"
                validation={{
                  email: true,
                }}
                renderComponent={(props) => (
                  <AppInputRHF className="w-full" type="text" {...props} />
                )}
              />
            </>
          )}
        </div>
      </div>
    </ClubSettingsSection>
  );
};
