/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  BookingParticipantEditorFormModel,
  BookingParticipantEditorFormModelClubParticipant,
  ClubDiver,
  ClubDiverDefaultDiveConfig,
  ClubParticipant,
  ClubParticipantEquipment,
  ClubParticipantGaz,
  DiveCertificationReference,
  DiveTrainingReference,
  FirstDiveTrainingReference,
  JsonPatchOperation,
} from '@mabadive/app-common-model';
import { jsonPatcher } from '@mabadive/app-common-services';
import {
  BookingParticipantEditorParticipant,
  BookingParticipantEditorResult,
} from '../../../../../../models';
import { clubDiverFormParser } from './clubDiverFormParser.service';

export const clubDiverChangesBuilder = {
  buildClubDiverChanges,
  buildDefaultDiveConfigDiff,
};

function buildClubDiverChanges({
  formValue,
  participantData,
  result,
}: {
  formValue: BookingParticipantEditorFormModel;
  participantData?: Pick<
    BookingParticipantEditorParticipant,
    'diver' | 'clubParticipant'
  >;
  result: BookingParticipantEditorResult;
}): {
  finalDiver: ClubDiver;
} {
  const initialDiver: ClubDiver = participantData?.diver;
  const initialParticipant: ClubParticipant = participantData?.clubParticipant;
  const finalParticipant = formValue?.clubParticipant;
  const diverId = participantData?.diver?._id;

  const clubDiverFromForm = clubDiverFormParser.parseFormValueDiver(formValue);
  const finalDiver: ClubDiver = {
    ...initialDiver,
    ...clubDiverFromForm,
  };

  if (
    initialParticipant &&
    finalParticipant &&
    isDefaultDiveConfigChanged({ finalParticipant, initialParticipant })
  ) {
    // participant updated
    finalDiver.defaultDiveConfig =
      buildDefaultDiveConfigFromParticipant(finalParticipant);
  } else if (finalParticipant) {
    // participant created
    finalDiver.defaultDiveConfig =
      buildDefaultDiveConfigFromParticipant(finalParticipant);
  }

  result.aggregated.finalDiver = finalDiver;
  const patchOperationsDiver = jsonPatcher.compareObjects(
    initialDiver,
    finalDiver,
    {
      // else, value won't be deleted by typeorm
      // https://github.com/typeorm/typeorm/issues/2934
      replaceDeleteByNullValue: true,
      attributesToReplaceFully: [
        'orgsMembers',
        'divingCertification1',
        'divingCertification2',
        'homeAddress',
        'defaultDiveConfig',
      ],
      attributesToIgnore: ['bookingDeposits'],
    },
  );

  if (patchOperationsDiver.length) {
    result.changes.updatedDivers.push({
      pk: diverId,
      patchOperations: patchOperationsDiver,
    });
  }

  return {
    finalDiver,
  };
}

function buildDefaultDiveConfigDiff({
  initialDiver,
  finalDiver,
}: {
  initialDiver: Pick<ClubDiver, 'defaultDiveConfig'>;
  finalDiver: Pick<ClubDiver, 'defaultDiveConfig'>;
}): JsonPatchOperation[] {
  const initialDefaultDiveConfig = initialDiver?.defaultDiveConfig ?? {};
  const finalDefaultDiveConfig = finalDiver?.defaultDiveConfig ?? {};

  let defaultDiveConfigJsonPatchOperations: JsonPatchOperation[] = [];
  {
    const patchOperationsDefaultDiveConfigCore = jsonPatcher.compareObjects(
      { ...initialDefaultDiveConfig, equipment: null, gaz: null },
      { ...finalDefaultDiveConfig, equipment: null, gaz: null },
      {
        replaceDeleteByNullValue: true,
        attributesToReplaceFully: [],
      },
    );
    if (patchOperationsDefaultDiveConfigCore.length) {
      defaultDiveConfigJsonPatchOperations =
        defaultDiveConfigJsonPatchOperations.concat(
          patchOperationsDefaultDiveConfigCore,
        );
    }
  }
  {
    const patchOperationsEquipment = jsonPatcher.compareObjects(
      initialDefaultDiveConfig?.equipment ?? {},
      finalDefaultDiveConfig?.equipment ?? {},
      {
        replaceDeleteByNullValue: true,
        attributesToReplaceFully: [
          'jacket',
          'wetsuit',
          'fins',
          'scubaRegulator',
          'divingBoots',
          'mask',
          'flashLight',
          'computer',
          'tank',
          'compass',
          'weighting',
          'rebreather',
          'underwaterScooter',
        ],
      },
    );
    if (patchOperationsEquipment.length) {
      defaultDiveConfigJsonPatchOperations =
        defaultDiveConfigJsonPatchOperations.concat(
          patchOperationsEquipment.map((x) => ({
            ...x,
            path: '/equipment' + x.path,
          })),
        );
    }
  }
  {
    const patchOperationsGaz = jsonPatcher.compareObjects(
      initialDefaultDiveConfig?.gaz ?? {},
      finalDefaultDiveConfig?.gaz ?? {},
      {
        replaceDeleteByNullValue: true,
        attributesToReplaceFully: [],
      },
    );
    if (patchOperationsGaz.length) {
      defaultDiveConfigJsonPatchOperations =
        defaultDiveConfigJsonPatchOperations.concat(
          patchOperationsGaz.map((x) => ({
            ...x,
            path: '/gaz' + x.path,
          })),
        );
    }
  }
  return defaultDiveConfigJsonPatchOperations;
}

function buildDefaultDiveConfigFromParticipant(
  finalParticipant: BookingParticipantEditorFormModelClubParticipant,
): ClubDiverDefaultDiveConfig {
  return {
    diveMode: finalParticipant.diveMode,
    autoSupervisedDetails: finalParticipant.autoSupervisedDetails,
    certificationReference:
      finalParticipant.certificationReference as unknown as DiveCertificationReference,
    firstDiveReference:
      finalParticipant.firstDiveReference as unknown as FirstDiveTrainingReference,
    trainingReference:
      finalParticipant.trainingReference as unknown as DiveTrainingReference,
    equipment: finalParticipant.equipment,
    gaz: finalParticipant.gaz,
    certificationDeepDiver: finalParticipant.certificationDeepDiver,
    aptitude: finalParticipant.aptitude,
    targetDeep: finalParticipant.targetDeep,
  };
}

function isDefaultDiveConfigChanged({
  initialParticipant,
  finalParticipant,
}: {
  initialParticipant: ClubParticipant;
  finalParticipant: BookingParticipantEditorFormModelClubParticipant;
}) {
  return (
    finalParticipant?.diveMode !== initialParticipant?.diveMode ||
    finalParticipant?.autoSupervisedDetails?.diveMode !==
      initialParticipant?.autoSupervisedDetails?.diveMode ||
    finalParticipant?.autoSupervisedDetails?.certificationDeepDiver !==
      initialParticipant?.autoSupervisedDetails?.certificationDeepDiver ||
    finalParticipant?.autoSupervisedDetails?.certificationReference !==
      initialParticipant?.autoSupervisedDetails?.certificationReference ||
    finalParticipant?.autoSupervisedDetails?.trainingReference !==
      initialParticipant?.autoSupervisedDetails?.trainingReference ||
    finalParticipant?.certificationReference !==
      initialParticipant?.certificationReference ||
    finalParticipant?.certificationDeepDiver !==
      initialParticipant?.certificationDeepDiver ||
    finalParticipant?.firstDiveReference !==
      initialParticipant?.firstDiveReference ||
    finalParticipant?.trainingReference !==
      initialParticipant?.trainingReference ||
    isEquipmentChanged(
      initialParticipant?.equipment,
      finalParticipant.equipment,
    ) ||
    isGazChanged(initialParticipant?.gaz, finalParticipant.gaz) ||
    finalParticipant?.aptitude !== initialParticipant?.aptitude ||
    finalParticipant?.targetDeep !== initialParticipant?.targetDeep
  );
}

function isEquipmentChanged(
  initialEquipment: ClubParticipantEquipment,
  finalEquipment: ClubParticipantEquipment,
): boolean {
  const patchOperations = jsonPatcher.compareObjects(
    initialEquipment,
    finalEquipment,
    {},
  );
  return patchOperations.length !== 0;
}

function isGazChanged(
  initialGaz: ClubParticipantGaz,
  finalGaz: ClubParticipantGaz,
): boolean {
  const patchOperations = jsonPatcher.compareObjects(initialGaz, finalGaz, {});
  return patchOperations.length !== 0;
}
