/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import {
  FieldArrayWithId,
  UseFieldArrayReturn,
  UseFormReturn,
  useWatch,
} from 'react-hook-form';
import { AppInputRHF } from 'src/lib/form';
import { AppFormControlRHF } from 'src/lib/form/components/AppFormControl/AppFormControlRHF';

import clsx from 'clsx';
import { useClubResume } from 'src/business/club/data/hooks';
import { ClubSettingsProductOffersFormModel } from '../../_model';

export const ClubSettingsProductOffersFormOfferCommon_DivesCount = ({
  form,
  offerField,
  offerFieldIndex: i,
  offersFieldArray,
}: {
  form: UseFormReturn<ClubSettingsProductOffersFormModel>;
  offerField: FieldArrayWithId<
    ClubSettingsProductOffersFormModel,
    'offers',
    'id'
  >;
  offerFieldIndex: number;
  offersFieldArray: UseFieldArrayReturn<
    ClubSettingsProductOffersFormModel,
    'offers',
    'id'
  >;
}) => {
  const clubResume = useClubResume();

  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const [divesCountInfinite, divePriceType] = useWatch({
    control,
    name: [
      `offers.${i}.productPackage.diveAttributes.divesCountInfinite`,
      `offers.${i}.productPackage.diveAttributes.divePriceType`,
    ],
  });
  return (
    <AppFormControlRHF
      className={clsx('w-full', divesCountInfinite && 'invisible')}
      control={control}
      label="Nb plongées"
      name={`offers.${i}.productPackage.diveAttributes.divesCount`}
      renderComponent={(props) => (
        <AppInputRHF
          className="w-full"
          {...props}
          type="number"
          onChange={(value) => {
            const count = value as number;
            if (count > 1 && divePriceType === 'single') {
              setValue(
                `offers.${i}.productPackage.diveAttributes.divePriceType`,
                'package',
              );
            } else if (count === 1 && divePriceType === 'package') {
              setValue(
                `offers.${i}.productPackage.diveAttributes.divePriceType`,
                'single',
              );
            }
          }}
        />
      )}
    />
  );
};
