/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useMemo } from 'react';
import { FieldArrayWithId, UseFormReturn, useWatch } from 'react-hook-form';
import { AppSingleAutocomplete2RHF } from 'src/lib/form';
import { AppFormControlRHF } from 'src/lib/form/components/AppFormControl/AppFormControlRHF';

import {
  ClubProductPackageAttributesDivePriceType,
  PRODUCT_PACKAGE_PRICE_TYPES,
} from '@mabadive/app-common-model';
import { divePriceTypeFormatter } from '@mabadive/app-common-services';
import { useAppSecurityUserHasRole } from 'src/business/auth/services';
import { ValueLabel } from 'src/business/club/modules/_common/form';
import { ClubSettingsProductOffersFormModel } from '../../_model';

export const ClubSettingsProductOffersFormOffer_Explo_PriceType_SA = ({
  form,
  offerField,
  offerFieldIndex,
}: {
  form: UseFormReturn<ClubSettingsProductOffersFormModel>;
  offerField: FieldArrayWithId<
    ClubSettingsProductOffersFormModel,
    'offers',
    'id'
  >;
  offerFieldIndex: number;
}) => {
  const isSuperAdmin = useAppSecurityUserHasRole('super-admin');

  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const i = offerFieldIndex;

  const [diveMode] = useWatch({
    control,
    name: [`offers.${i}.productPackage.diveAttributes.diveMode`],
  });

  const divePriceTypeOptions = useMemo(
    () =>
      PRODUCT_PACKAGE_PRICE_TYPES.filter((x) => {
        if (x === 'package') {
          return (
            diveMode === 'instructor' ||
            diveMode === 'autonomous' ||
            diveMode === 'supervised' ||
            diveMode === 'autoSupervised'
          );
        } else if (x === 'successive') {
          return (
            diveMode === 'autonomous' ||
            diveMode === 'supervised' ||
            diveMode === 'instructor'
          );
        }
        return true;
      }).map((divePriceType) => {
        const option: ValueLabel<ClubProductPackageAttributesDivePriceType> = {
          label: divePriceTypeFormatter
            .formatDivePriceType(divePriceType)
            .toUpperCase(),
          value: divePriceType,
        };
        return option;
      }),
    [diveMode],
  );

  const onDivePriceTypeChanges = useCallback(
    (divePriceType: ClubProductPackageAttributesDivePriceType) => {
      if (divePriceType === 'successive') {
        setValue(
          `offers.${i}.productPackage.diveAttributes.successiveDivesCount`,
          2,
          {
            shouldDirty: true,
            shouldTouch: true,
          },
        );
      } else {
        setValue(
          `offers.${i}.productPackage.diveAttributes.successiveDivesCount`,
          null,
          {
            shouldDirty: true,
            shouldTouch: true,
          },
        );
      }
      if (divePriceType !== 'package') {
        setValue(`offers.${i}.productPackage.diveAttributes.divesCount`, 1, {
          shouldDirty: true,
          shouldTouch: true,
        });
        setValue(
          `offers.${i}.productPackage.diveAttributes.divesCountInfinite`,
          false,
          {
            shouldDirty: true,
            shouldTouch: true,
          },
        );
      }
    },
    [i, setValue],
  );

  return isSuperAdmin && diveMode && divePriceTypeOptions.length > 1 ? (
    <AppFormControlRHF
      visibility="super-admin"
      className="col-span-2"
      label="Type"
      control={control}
      name={`offers.${i}.productPackage.diveAttributes.divePriceType`}
      required={true}
      renderComponent={(props) => (
        <AppSingleAutocomplete2RHF
          {...props}
          options={divePriceTypeOptions}
          onChange={(divePriceType) => onDivePriceTypeChanges(divePriceType)}
        />
      )}
    />
  ) : null;
};
