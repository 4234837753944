/* eslint-disable @typescript-eslint/no-unused-vars */
import { Typography } from '@material-ui/core';
import React from 'react';
import { useRedirect } from 'src/business/_core/data/hooks';
import {
  AppLoadableContentContainer,
  AppPageContainerWithFixedBars,
  AppPageContentContainer,
  AppTabsBar,
  useAutoFocus,
} from 'src/business/_core/modules/layout';
import {
  useClubResume,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import { CLUB_LISTS_TABS } from '../_common';
import { clubListsUrlBuilder } from '../clubListsUrlBuilder.service';
import { ClubPurchasesListPageConfigBar } from './ClubPurchasesListPageConfigBar';
import { ClubPurchasesTable } from './ClubPurchasesTable/ClubPurchasesTable';
import {
  ClubPurchasesListPageLocalState,
  useClubPurchasesListPageLocalState,
} from './useClubPurchasesListPageLocalState.hook';

export const ClubPurchasesListPage = () => {
  const clubResume = useClubResume();
  const diveCenterResume = useDiveCenterResume();
  const diveCenterId = diveCenterResume._id;
  const autoFocus = useAutoFocus();

  const localState: ClubPurchasesListPageLocalState =
    useClubPurchasesListPageLocalState();

  const {
    data: { loadableContent, filteredPurchasesLimited },
  } = localState;

  const redirectTo = useRedirect();

  return (
    <AppPageContainerWithFixedBars
      marginBottom={false}
      paddingBottom={false}
      contentClassName="bg-gray-50 app-p-content"
      headerBar={() => (
        <>
          <AppTabsBar
            theme="primary"
            tabs={CLUB_LISTS_TABS}
            context={{}}
            value={'billing'}
            onChange={(tab) => {
              const url = clubListsUrlBuilder.buildClubListsTabUrl(tab);
              redirectTo(url);
            }}
            // disableNavigation={globalState?.updateState.hasChanges}
          />
          <div className="bg-gray-50 app-p-content">
            <ClubPurchasesListPageConfigBar localState={localState} />
          </div>
        </>
      )}
    >
      <AppPageContentContainer
        className="app-card-no-padding"
        paddingBottom={false}
      >
        <AppLoadableContentContainer {...loadableContent}>
          <ClubPurchasesTable purchases={filteredPurchasesLimited} />
          {filteredPurchasesLimited?.length === 0 ? (
            <div className="mx-2 my-3">
              <Typography variant="subtitle2">
                Aucune réservation disponible pour cette période
              </Typography>
            </div>
          ) : null}
        </AppLoadableContentContainer>
      </AppPageContentContainer>
    </AppPageContainerWithFixedBars>
  );
};
