/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ClubPlanningLightSessionDef,
  DiveSessionTimeDayPeriod,
} from '@mabadive/app-common-model';
import {
  clubDiveSessionThemeBuilder,
  diveSessionTitleBuilder,
} from '@mabadive/app-common-services';
import React, { useMemo } from 'react';
import {
  useDiveCenterDiveSites,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import {
  ClubDiveSessionName,
  DiveSiteIconDef,
  useDiveSiteIcon,
} from 'src/business/club/modules/club-dive-session/components';
import { ClubBoatLabel } from 'src/pages/_components/company';
import { ClubBoatLabelShort } from '../../../../../../../pages/_components/company/ClubBoat/ClubBoatLabelShort';
import { CalendarSessionWidthMode } from '../session-common';
export const DiveSessionSimpleCardHeader = ({
  session,
  display,
  widthMode,
  onClick = () => {},
  className,
}: {
  session: ClubPlanningLightSessionDef;
  display: {
    boats: boolean;
    truncateText?: boolean;
  };
  widthMode: CalendarSessionWidthMode;
  onClick?: () => void;
  className?: string;
}) => {
  const diveCenterResume = useDiveCenterResume();
  const clubResume = diveCenterResume?.clubResume;

  const diveCenterId = diveCenterResume?._id;
  const clubReference = diveCenterResume?.clubReference;

  const timeDayPeriod: DiveSessionTimeDayPeriod = useMemo(
    () => clubDiveSessionThemeBuilder.buildTimeDayPeriod(session.time),
    [session.time],
  );

  const [diveSite, diveSite2] = useDiveCenterDiveSites([
    session.diveSiteId,
    session.diveTourSession2?.diveSiteId,
  ]);
  const diveSiteIcon: DiveSiteIconDef = useDiveSiteIcon(
    session.diveSiteId,
    session.diveTourSession2?.diveSiteId,
  );

  const boats = useMemo(() => {
    return session.boatsIds
      .map((boatId) => clubResume?.boats.find((b) => b._id === boatId))
      .filter((x) => !!x);
  }, [clubResume?.boats, session.boatsIds]);

  const sessionTitle = useMemo(
    () => diveSessionTitleBuilder.build(session),
    [session],
  );
  const hasAnySessionName: boolean =
    session.status !== 'on' ||
    session.details?.isFull ||
    !!diveSiteIcon ||
    !!diveSite ||
    !!sessionTitle ||
    !!session.diveTourSession2;
  const hasAnyBoatName: boolean =
    display.boats &&
    session.status !== 'off' &&
    session.status !== 'deleted' &&
    boats.length !== 0;
  const hasAnyTitle = hasAnySessionName || hasAnyBoatName;
  return (
    <div
      className={`bg-day-period-${timeDayPeriod}-light bg-opacity-80 group-hover:bg-opacity-100 text-gray-600 group-hover:text-gray-700 block font-bold
       ${session.status === 'cancelled' ? 'line-through' : ''}
       ${
         widthMode === 'tiny'
           ? ' text-app-xxs2'
           : widthMode === 'small'
           ? 'text-[.575rem]'
           : ' text-app-xxs'
       }
       ${className ?? ''}
       `}
      onClick={onClick}
    >
      {hasAnyBoatName && (
        <div className={'z-boats flex items-center gap-1 overflow-hidden'}>
          {boats.map((boat) => (
            <div
              key={boat._id}
              className={'flex items-center gap-0.5 truncate'}
            >
              <ClubBoatLabel
                className="hidden md:flex"
                clubBoat={boat}
                iconSize="w-3 h-3"
              />

              <ClubBoatLabelShort
                className="flex md:hidden"
                iconClassName="w-3 h-3"
                clubBoat={boat}
              />
            </div>
          ))}
        </div>
      )}
      {hasAnySessionName && (
        <ClubDiveSessionName
          className={'z-session-name md:block font-bold'}
          diveSession={session}
          diveSite={diveSite}
          diveSite2={diveSite2}
          truncateText={display.truncateText}
          skipCancelStatusLabel={false}
          widthMode={widthMode}
        />
      )}
      {!hasAnyTitle && <div className="">&nbsp;</div>}
    </div>
  );
};
