import {
  AppEntityUpdatePatch,
  BookingProduct,
} from '@mabadive/app-common-model';
import { dateService, jsonPatcher } from '@mabadive/app-common-services';
import React, { useCallback, useMemo } from 'react';
import {
  AppHeroIcons,
  EntitiesIcons,
} from 'src/business/_core/modules/layout/icons';
import {
  useAppSecurityUser,
  useAppSecurityUserClubAuthorizations,
} from 'src/business/auth/services';
import { AppHamburgerMenuItem } from 'src/business/club/modules/_common/ui';
import { ClubDialogsStateOld } from 'src/pages/_dialogs';
import {
  BillingTabDiveSessionBillingResume,
  BillingTabParticipantPurchase,
} from '../../models';
import { DiverBookingPageActions } from '../../useDiverBookingPageActions.hook';
import { DiverBookingPageGlobalState } from '../../useDiverBookingPageGlobalState.hook';
import { useOnClickCreateEditPurchase } from './useOnClickCreateEditPurchase.hook';

export function useBillingParticipantPurchaseResumeTableHamburgerMenuItems({
  participantPurchase,
  globalState,
  dialogs,
  actions,
  isExpanded,
  setIsExpanded,
}: {
  participantPurchase: BillingTabParticipantPurchase;
  globalState: DiverBookingPageGlobalState;
  dialogs: ClubDialogsStateOld;
  actions: DiverBookingPageActions;
  isExpanded: boolean;
  setIsExpanded: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const user = useAppSecurityUser();
  const az = useAppSecurityUserClubAuthorizations();

  const { isVirtual, diver, isArchived } = participantPurchase;

  const { aggregatedData } = globalState;

  const { openCreatePurchasePaymentDialog } = actions;

  const purchaseEnabled = useMemo(
    () =>
      user &&
      (participantPurchase.type === 'plan' ||
        participantPurchase.type === 'other' ||
        participantPurchase.type === 'training' ||
        participantPurchase.type === 'store'),
    [participantPurchase.type, user],
  );

  const onClickCreateEditPurchase = useOnClickCreateEditPurchase({
    actions,
    participantPurchase,
    aggregatedData,
  });

  const nonPurchasedConfirmedProducts = useMemo(
    () =>
      participantPurchase.sessionsBillingResumes.sameTypeSameDiver.filter(
        (x) =>
          !x.purchaseParticipant.isAssociatedToPurchase &&
          x.purchaseParticipant.bookingProduct.bookingProductStatus ===
            'confirmed',
      ),
    [participantPurchase.sessionsBillingResumes],
  );

  const nonPurchasedNonArchivedCancelledProducts = useMemo(
    () =>
      participantPurchase.sessionsBillingResumes.sameTypeSameDiver.filter(
        (x) =>
          !x.purchaseParticipant.isAssociatedToPurchase &&
          x.purchaseParticipant.bookingProduct.bookingProductStatus ===
            'cancelled' &&
          x.purchaseParticipant.bookingProduct.purchaseStatus !== 'archived',
      ),
    [participantPurchase.sessionsBillingResumes],
  );

  const nonPurchasedArchivedProducts = useMemo(
    () =>
      participantPurchase.sessionsBillingResumes.sameTypeSameDiver.filter(
        (x) =>
          !x.purchaseParticipant.isAssociatedToPurchase &&
          x.purchaseParticipant.bookingProduct.purchaseStatus === 'archived',
      ),
    [participantPurchase.sessionsBillingResumes],
  );

  const nonPurchasedConfirmedPastProducts = useMemo(
    () =>
      nonPurchasedConfirmedProducts.filter((x) =>
        dateService.isPastUTC(x.diveSession.time),
      ),
    [nonPurchasedConfirmedProducts],
  );

  const onClickArchiveNonPurchased = useCallback(
    (products: BillingTabDiveSessionBillingResume[]) => {
      const updatedBookingProductsPatchs: AppEntityUpdatePatch[] = [];

      products.forEach((x) => {
        const product = x.purchaseParticipant.bookingProduct;
        const updatedProduct: BookingProduct = {
          ...product,
          purchaseStatus: 'archived',
        };
        const patchOperations = jsonPatcher.compareObjects(
          product,
          updatedProduct,
          {},
        );
        if (patchOperations.length) {
          const patch: AppEntityUpdatePatch = {
            pk: product._id,
            patchOperations,
          };
          updatedBookingProductsPatchs.push(patch);
        }
      });
      if (updatedBookingProductsPatchs.length) {
        actions.updateBookingProducts({
          updatedBookingProductsPatchs,
        });
      }
    },
    [actions],
  );
  const onClickUnarchive = useCallback(
    (products: BillingTabDiveSessionBillingResume[]) => {
      const updatedBookingProductsPatchs: AppEntityUpdatePatch[] = [];

      products.forEach((x) => {
        const product = x.purchaseParticipant.bookingProduct;
        const updatedProduct: BookingProduct = {
          ...product,
          purchaseStatus: 'pending',
        };

        const patchOperations = jsonPatcher.compareObjects(
          product,
          updatedProduct,
          {},
        );
        if (patchOperations.length) {
          const patch: AppEntityUpdatePatch = {
            pk: product._id,
            patchOperations,
          };
          updatedBookingProductsPatchs.push(patch);
        }
      });
      if (updatedBookingProductsPatchs.length) {
        actions.updateBookingProducts({
          updatedBookingProductsPatchs,
        });
      }
    },
    [actions],
  );

  const onClickMarkAsPaid = useCallback(() => {
    const purchasePackage = participantPurchase.purchasePackage;

    const patchOperations = jsonPatcher.compareObjects(
      purchasePackage,
      {
        ...purchasePackage,
        purchasePaymentStatus: 'done',
        purchasePaymentPending: null,
        purchasePaymentWithCreditNote: null,
      },
      {},
    );

    if (patchOperations.length) {
      const patch: AppEntityUpdatePatch = {
        pk: purchasePackage._id,
        patchOperations,
      };
      actions.updateProductPurchase({
        updatedClubPurchasePackage: patch,
        updatedProducts: [],
      });
    }
  }, [actions, participantPurchase.purchasePackage]);

  const markPurchasePackageAsCompleted = useCallback(() => {
    const purchasePackage = participantPurchase.purchasePackage;

    const patchOperations = jsonPatcher.compareObjects(
      purchasePackage,
      {
        ...purchasePackage,
        validityStatus: 'completed',
      },
      {},
    );

    if (patchOperations.length) {
      const patch: AppEntityUpdatePatch = {
        pk: purchasePackage._id,
        patchOperations,
      };
      actions.updateProductPurchase({
        updatedClubPurchasePackage: patch,
        updatedProducts: [],
      });
    }
  }, [actions, participantPurchase.purchasePackage]);

  const onClickAssignPastProducts = useCallback(
    async (operation: 'assign' | 'unassign') => {
      await actions.assignProductsToPurchasePackage({
        purchasePackageId: participantPurchase?.purchasePackage?._id,
        bookingProducts: nonPurchasedConfirmedPastProducts.map(
          (x) => x.purchaseParticipant.bookingProduct,
        ),
        operation,
        confirmValidityStatusUpdate: true,
        countSuccessiveAsSingle: participantPurchase?.countSuccessiveAsSingle,
      });
    },
    [
      actions,
      nonPurchasedConfirmedPastProducts,
      participantPurchase?.countSuccessiveAsSingle,
      participantPurchase?.purchasePackage?._id,
    ],
  );

  const hamburgerMenuItems: AppHamburgerMenuItem[] = useMemo(() => {
    const hamburgerMenuItems: AppHamburgerMenuItem[] = [];
    if (purchaseEnabled) {
      if (isVirtual && az.edit.participant.billingCreate) {
        hamburgerMenuItems.push({
          id: 'create-or-edit-purchase',
          title: 'Facturer la prestation',
          titleShort: 'Facturer',
          description: 'Configurer les détails de cette prestation',
          icon: AppHeroIcons.actionAdd,
          onClick: () => onClickCreateEditPurchase(),
        });
      } else if (!isVirtual && az.edit.participant.billingEdit) {
        hamburgerMenuItems.push({
          id: 'create-or-edit-purchase',
          title: 'Modifier la prestation',
          titleShort: 'Modifier',
          description: 'Configurer les détails de cette prestation',
          icon: AppHeroIcons.actionEdit,
          onClick: () => onClickCreateEditPurchase(),
        });
      }
    }
    if (
      az.edit.participant.billingEdit &&
      participantPurchase.purchasePackage &&
      participantPurchase.purchasePackage?.validityStatus === 'active' &&
      nonPurchasedConfirmedProducts?.length === 0
    ) {
      hamburgerMenuItems.push({
        id: 'mark-purchase-as-completed',
        title: 'Terminer la prestation',
        description: 'Marquer la prestation comme terminée',
        icon: AppHeroIcons.check,
        onClick: () => {
          markPurchasePackageAsCompleted();
        },
      });
    }
    if (
      participantPurchase.purchasePackage &&
      participantPurchase.purchasePackage?.purchasePaymentStatus !== 'done'
    ) {
      if (az?.edit?.participant?.paymentCreate) {
        hamburgerMenuItems.push({
          id: 'pay',
          title: 'Payer',
          description: 'Payer une ou plusieurs prestations',
          icon: EntitiesIcons.payment,
          onClick: () => {
            if (participantPurchase.purchasePackage.price > 0) {
              openCreatePurchasePaymentDialog({
                defaultDiverId: diver?._id,
                defaultPackagesIds: aggregatedData.purchasePackages
                  .filter((x) => x.diverId === participantPurchase.diver?._id)
                  .map((x) => x._id), // defaultPackagesIds: [participantPurchase.purchasePackage?._id],
                aggregatedData,
                // dans un autre contexte, on met à jour 'bookingDepositsWithPayments' avant d'ouvrir le dialog, donc on ne le prend pas toujours dans aggregatedData
                bookingDepositsWithPayments:
                  aggregatedData.bookingDepositsWithPayments,
              });
            } else {
              onClickMarkAsPaid();
            }
          },
        });
      }
      if (!isVirtual && az?.edit?.participant?.paymentCreate) {
        hamburgerMenuItems.push({
          title: 'Archiver',
          description: 'Marquer comme payé sans préciser les détails',
          icon: EntitiesIcons.payment,
          onClick: () => {
            onClickMarkAsPaid();
          },
        });
      }
    }

    if (az.edit.participant.billingEdit) {
      if (nonPurchasedNonArchivedCancelledProducts.length) {
        hamburgerMenuItems.push({
          id: 'archive-cancelled-non-purchased',
          title: 'Archiver',
          description:
            nonPurchasedNonArchivedCancelledProducts.length === 1
              ? `Archiver la plongée annulée (${nonPurchasedNonArchivedCancelledProducts.length})`
              : `Archiver les plongées annulées (${nonPurchasedNonArchivedCancelledProducts.length})`,
          icon: AppHeroIcons.actionArchive,
          onClick: () => {
            onClickArchiveNonPurchased(
              nonPurchasedNonArchivedCancelledProducts,
            );
          },
        });
      }
      if (nonPurchasedArchivedProducts.length) {
        hamburgerMenuItems.push({
          id: 'unarchive',
          title: 'Restaurer',
          description:
            nonPurchasedArchivedProducts.length === 1
              ? `Restaurer la plongée archivée (${nonPurchasedArchivedProducts.length})`
              : `Archiver les plongées archivées (${nonPurchasedArchivedProducts.length})`,
          icon: AppHeroIcons.actionArchive,
          onClick: () => {
            onClickUnarchive(nonPurchasedArchivedProducts);
          },
        });
      }
      if (!isArchived) {
        if (isVirtual) {
          if (nonPurchasedConfirmedPastProducts.length) {
            hamburgerMenuItems.push({
              id: 'archive-virtual-past-non-purchased',
              title:
                nonPurchasedConfirmedPastProducts.length === 1
                  ? `Archiver la plongée passée (${nonPurchasedConfirmedPastProducts.length})`
                  : `Archiver les plongées passées (${nonPurchasedConfirmedPastProducts.length})`,
              description:
                'Archiver les plongées passées non-imputées sans les facturer',
              icon: AppHeroIcons.actionArchive,
              onClick: () => {
                onClickArchiveNonPurchased(nonPurchasedConfirmedPastProducts);
              },
            });
          }
        } else {
          if (nonPurchasedConfirmedPastProducts.length) {
            hamburgerMenuItems.push({
              id: 'auto-impute-past-non-purchased',
              title:
                nonPurchasedConfirmedPastProducts.length === 1
                  ? `Auto-imputer (${nonPurchasedConfirmedPastProducts.length})`
                  : `Auto-imputer (${nonPurchasedConfirmedPastProducts.length})`,
              description: 'Imputer toutes les plongées passées',
              icon: AppHeroIcons.actionImpute,
              onClick: async () => {
                await onClickAssignPastProducts('assign');
              },
            });
            hamburgerMenuItems.push({
              id: 'archive-real-past-non-purchased',
              title:
                nonPurchasedConfirmedPastProducts.length === 1
                  ? `Archiver sans imputer (${nonPurchasedConfirmedPastProducts.length})`
                  : `Archiver sans imputer (${nonPurchasedConfirmedPastProducts.length})`,
              description: 'Archiver les plongées passées non-imputées',
              icon: AppHeroIcons.actionArchive,
              onClick: () => {
                onClickArchiveNonPurchased(nonPurchasedConfirmedPastProducts);
              },
            });
          }
        }
      }
    }
    if (isExpanded) {
      hamburgerMenuItems.push({
        title: 'Masquer le détail',
        description:
          'Masquer le détail de cette prestation (plongées, paiements)',
        icon: AppHeroIcons.eyeOff,
        onClick: () => {
          setIsExpanded(!isExpanded);
        },
      });
    } else {
      hamburgerMenuItems.push({
        title: 'Afficher le détail',
        description:
          'Afficher le détail de cette prestation (plongées, paiements)',
        icon: AppHeroIcons.eye,
        onClick: () => {
          setIsExpanded(!isExpanded);
        },
      });
    }
    return hamburgerMenuItems;
  }, [
    purchaseEnabled,
    az.edit.participant.billingEdit,
    az.edit.participant.billingCreate,
    az.edit.participant?.paymentCreate,
    participantPurchase.purchasePackage,
    participantPurchase.diver?._id,
    nonPurchasedConfirmedProducts?.length,
    isExpanded,
    isVirtual,
    onClickCreateEditPurchase,
    markPurchasePackageAsCompleted,
    openCreatePurchasePaymentDialog,
    aggregatedData,
    diver,
    onClickMarkAsPaid,
    nonPurchasedNonArchivedCancelledProducts,
    nonPurchasedArchivedProducts,
    isArchived,
    onClickArchiveNonPurchased,
    onClickUnarchive,
    nonPurchasedConfirmedPastProducts,
    onClickAssignPastProducts,
    setIsExpanded,
  ]);

  return hamburgerMenuItems;
}
