/* eslint-disable @typescript-eslint/no-unused-vars */
import { default as React } from 'react';
import { AppButton } from 'src/business/_core/modules/layout';
import { AppIconsDiveEquipment } from 'src/business/_core/modules/layout/icons';
import { useAppSecurityUserClubAuthorizations } from 'src/business/auth/services';
import { clubSettingsDiveCenterUrlBuilder } from 'src/pages/SE-settings/SE-01_dive-center/clubSettingsDiveCenterUrlBuilder.service';
import { useAppCurrencyMain } from 'src/pages/_components/options';
import {
  useClubResume,
  useDiveCenterResume,
} from '../../../../../business/club/data/hooks';

export const ClubSettingsCustomerViewCard_Equipment = () => {
  const mainCurrency = useAppCurrencyMain();
  const diveCenterResume = useDiveCenterResume();
  const clubResume = useClubResume();

  const clubSettings = clubResume?.clubSettings;
  const customerSettings = clubSettings?.customer;

  const az = useAppSecurityUserClubAuthorizations();

  return (
    <>
      {az?.edit?.settings?.equipment && (
        <div className="my-4">
          <h2 className="text-left uppercase text-lg leading-6 py-2 font-medium text-gray-800">
            Équipements
          </h2>
          <AppButton
            icon={AppIconsDiveEquipment.all}
            color={'gray-outline-light'}
            href={clubSettingsDiveCenterUrlBuilder.equipments.list()}
          >
            Voir la configuration des équipements
          </AppButton>
        </div>
      )}
    </>
  );
};
