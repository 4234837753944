/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  BookingParticipantEditorFormModel,
  DiveMode,
  DiveServiceOrganizationReference,
  FFESSM_LICENCE_NUMBER_PATTERN,
} from '@mabadive/app-common-model';
import clsx from 'clsx';
import React, { useEffect, useMemo, useState } from 'react';
import { UseFormReturn, useFieldArray, useWatch } from 'react-hook-form';
import { useAppSecurityUser } from 'src/business/auth/services';
import { useClubResume } from 'src/business/club/data/hooks';
import { ValueLabel } from 'src/business/club/modules/_common/form/components/ValueLabel.model';
import {
  AppInputBooleanRHF,
  AppInputBooleanSwitchRHF,
  AppInputRHF,
  AppSingleAutocomplete2RHF,
} from 'src/lib/form';
import { AppFormControlV2 } from 'src/lib/form/components/AppFormControl';
import { AppFormControlRHF } from 'src/lib/form/components/AppFormControl/AppFormControlRHF';
import { AppSingleSelect2HeadlessUI } from 'src/lib/form/components/AppSingleSelect/AppSingleSelect2HeadlessUI';
import {
  useDiveModesOptions,
  useDiverTechnicalLevelOptions,
  useFreeDiveCertificationOptions,
} from 'src/pages/_components/options';
import { AppInputDatePickerMaterialRHF } from 'src/stories/components/04-form';
import { useOnDiveModeChangesContextDiver } from '../BookingParticipantDefaultParamsEditForm';
import { BookingParticipantDiveModeSpecificReference } from '../BookingParticipantEditForm/BookingParticipantDiveModeSpecificReference';
import { BookingMemberDiverEditFormRHFFieldsToDisplay } from './BookingMemberCreateFormRHF';
import { BookingMemberDiverEditFormOrgCertification } from './BookingMemberDiverEditFormOrgCertification';
import { BookingMemberDiverEditFormRHFMedicalCertificate } from './BookingMemberDiverEditFormRHFMedicalCertificate';
import { BookingMemberGeneralEditFormInsurance } from './BookingMemberGeneralEditForm/BookingMemberGeneralEditFormInsurance';
import { DIVE_SERVICE_ORGANIZATIONS_OPTIONS } from './DIVE_SERVICE_ORGANIZATIONS_OPTIONS.const';
export const BookingMemberDiverEditFormRHF = ({
  form,
  mode,
  fieldsToDisplay = 'all',
  isDiverWithMember,
  className = '',
}: {
  form: UseFormReturn<BookingParticipantEditorFormModel>;
  mode: 'create' | 'edit';
  fieldsToDisplay?: BookingMemberDiverEditFormRHFFieldsToDisplay;
  isDiverWithMember: boolean;
  className?: string;
}) => {
  const securityUser = useAppSecurityUser();

  const clubResume = useClubResume();
  const clubSettings = clubResume.clubSettings;

  const customerSettings = clubResume.clubSettings?.customer;

  const { control, setValue } = form;

  const [
    divingCertification1,
    divingCertification2,
    medicalCertificateFilled,
    medicalFormFilled,
  ] = useWatch({
    control,
    name: [
      'diver.divingCertification1',
      'diver.divingCertification2',
      'diver.medicalCertificateChecked',
      'bookingMember.details.medicalForm.filled',
    ],
  });
  const technicalLevelOptions = useDiverTechnicalLevelOptions();
  const freeDiveCertificationOptions = useFreeDiveCertificationOptions();

  const [certificationsCount, setCertificationsCount] = useState(
    divingCertification2 ? 2 : 1,
  );

  const {
    fields: orgMembersFields,
    append,
    prepend,
    remove,
    swap,
    move,
    insert,
  } = useFieldArray({
    control,
    name: 'diver.orgsMembers',
  });

  const diverNumberOrgsReferencesExpected: DiveServiceOrganizationReference[] =
    useMemo(
      () => [
        ...new Set(
          [
            'ffessm' as DiveServiceOrganizationReference, // en dur: toujours visible
            divingCertification1?.orgReference,
            divingCertification2?.orgReference,
          ].filter((x) => !!x),
        ),
      ],
      [divingCertification1?.orgReference, divingCertification2?.orgReference],
    );

  const diverNumberOrgsReferencesActual: DiveServiceOrganizationReference[] =
    useMemo(
      () => [
        ...new Set(
          orgMembersFields.map((x) => x.orgReference).filter((x) => !!x),
        ),
      ],
      [orgMembersFields],
    );

  useEffect(() => {
    diverNumberOrgsReferencesExpected.forEach((orgReference) => {
      // dynamically append missing fields
      if (!diverNumberOrgsReferencesActual.includes(orgReference)) {
        append({
          orgReference,
          orgDiverId: '',
        });
      }
    });
  }, [
    append,
    diverNumberOrgsReferencesActual,
    diverNumberOrgsReferencesExpected,
    divingCertification1?.orgReference,
    divingCertification2?.orgReference,
    orgMembersFields,
  ]);

  const remainingDiverIdOrganizationsOptions = useMemo(
    () =>
      DIVE_SERVICE_ORGANIZATIONS_OPTIONS.filter(
        (x) => !diverNumberOrgsReferencesActual.includes(x.value),
      ),
    [diverNumberOrgsReferencesActual],
  );
  const showMedicalForm =
    isDiverWithMember &&
    (customerSettings?.diving?.medicalFormChecked ||
      customerSettings?.diving?.medicalFormDate);

  const showMedicalCertificate =
    customerSettings?.diving?.medicalCertificateDate ||
    customerSettings?.diving?.medicalCertificateChecked;

  const onDiveModeChange = useOnDiveModeChangesContextDiver({ form });

  const diveModesOptions: ValueLabel<DiveMode>[] = useDiveModesOptions({
    format: 'short-ref-label',
  });

  return (
    <div className={`${className}`}>
      <div
        className={
          'w-full flex flex-col sm:grid sm:grid-cols-2 md:grid-cols-4 gap-4'
        }
      >
        <BookingMemberDiverEditFormOrgCertification
          className="sm:col-span-2 md:col-span-4"
          form={form}
          baseControlName="diver.divingCertification1"
          certificationIndex={1}
          certificationsCount={certificationsCount}
          onClickAdd={
            certificationsCount > 1
              ? undefined
              : () => setCertificationsCount(2)
          }
        />
        <BookingMemberDiverEditFormOrgCertification
          className={clsx(
            'sm:col-span-2 md:col-span-4',
            `${certificationsCount === 2 ? '' : 'hidden'}`,
          )}
          form={form}
          baseControlName="diver.divingCertification2"
          certificationIndex={2}
          certificationsCount={certificationsCount}
          onClickRemove={() => {
            setCertificationsCount(1);
            setValue('diver.divingCertification2.orgGroup', null);
            setValue('diver.divingCertification2.orgReference', null);
            setValue(
              'diver.divingCertification2.mainCertificationReference',
              null,
            );
            setValue(
              'diver.divingCertification2.gazCertificationReference',
              null,
            );
            setValue(
              'diver.divingCertification2.safetyCertificationReferences',
              [],
            );
            setValue('diver.divingCertification2.checkedByClub', null);
          }}
        />
      </div>

      <div
        className={
          'mt-2 w-full flex flex-col sm:grid sm:grid-cols-2 md:grid-cols-4 gap-4'
        }
      >
        {fieldsToDisplay === 'all' && (
          <>
            {customerSettings?.diving?.insurance && (
              <BookingMemberGeneralEditFormInsurance
                className="w-full sm:col-span-2 md:col-span-4"
                form={form}
              />
            )}
            <BookingMemberDiverEditFormRHFMedicalCertificate
              className={clsx(!showMedicalForm && 'md:col-span-2')}
              form={form}
            />

            {showMedicalForm && (
              <div
                className={clsx(
                  'w-full  flex gap-2 justify-between',
                  !showMedicalCertificate && 'md:col-span-2',
                )}
              >
                {customerSettings?.diving?.medicalFormDate && (
                  <AppFormControlRHF
                    className={clsx('w-full')}
                    label="Formulaire médical"
                    control={control}
                    name={'bookingMember.details.medicalForm.date'}
                    renderComponent={(props) => (
                      <AppInputDatePickerMaterialRHF
                        {...props}
                        className="w-full"
                        onChange={(date) => {
                          if (date && !medicalFormFilled) {
                            setValue(
                              'bookingMember.details.medicalForm.filled',
                              true,
                            );
                          }
                        }}
                      />
                    )}
                  />
                )}
                {customerSettings?.diving?.medicalFormChecked && (
                  <AppFormControlRHF
                    label={
                      customerSettings?.diving?.medicalFormDate
                        ? 'Vérifié'
                        : 'Formulaire médical'
                    }
                    control={control}
                    name={'bookingMember.details.medicalForm.filled'}
                    renderComponent={(props) => (
                      <div
                        className={clsx(
                          'w-full flex',
                          customerSettings?.diving?.medicalFormDate &&
                            'justify-center',
                        )}
                      >
                        <AppInputBooleanRHF
                          className="mt-2"
                          {...props}
                          scale="lg"
                          type="checkbox"
                        />
                      </div>
                    )}
                  />
                )}
              </div>
              // <AppFormControlRHF
              //     className={clsx(
              //       'w-full',
              //       !(
              //         customerSettings?.diving?.medicalCertificateDate ||
              //         customerSettings?.diving?.medicalCertificateChecked
              //       ) && 'md:col-span-2',
              //     )}
              //     label="Formulaire médical"
              //     control={control}
              //     name={'bookingMember.details.medicalFormChecked.filled'}
              //     renderComponent={(props) => (
              //       <AppInputBooleanRHF
              //         className="mt-2"
              //         {...props}
              //         type="checkbox"
              //         scale="lg"
              //         description="Renseigné"
              //       />
              //     )}
              //   />
            )}
          </>
        )}
        {fieldsToDisplay === 'all' && (
          <>
            {orgMembersFields.map((field, fieldIndex) => {
              return (
                <div className="md:col-span-2" key={field.orgReference}>
                  {field.orgReference === 'ffessm' ? (
                    <AppFormControlRHF
                      className=" w-full"
                      label="N° licence FFESSM"
                      control={control}
                      validation={{
                        pattern: FFESSM_LICENCE_NUMBER_PATTERN,
                      }}
                      renderError={({ error }) => {
                        if (error?.type === 'pattern') {
                          return 'Le numéro est de la forme "A-01-000001"';
                        }
                      }}
                      name={`diver.orgsMembers.${fieldIndex}.orgDiverId`}
                      renderComponent={(props) => (
                        <AppInputRHF
                          placeholder="A-01-000001"
                          {...props}
                          fullWidth
                        />
                      )}
                    />
                  ) : (
                    <AppFormControlRHF
                      className="w-full"
                      label={`Numéro ${field.orgReference?.toUpperCase()}`}
                      control={control}
                      name={`diver.orgsMembers.${fieldIndex}.orgDiverId`}
                      renderComponent={(props) => (
                        <AppInputRHF
                          placeholder={
                            field.orgReference === 'padi' ? '12345X6789' : ''
                          }
                          {...props}
                          fullWidth
                        />
                      )}
                    />
                  )}
                </div>
              );
            })}
          </>
        )}
        {fieldsToDisplay === 'all' && (
          <>
            {remainingDiverIdOrganizationsOptions.length > 0 && (
              <AppFormControlV2
                label={'Ajouter numéro'}
                renderComponent={() => (
                  <AppSingleSelect2HeadlessUI
                    className={'w-full'}
                    theme="material-ui"
                    color="theme"
                    options={remainingDiverIdOrganizationsOptions}
                    onChange={(
                      orgReference: DiveServiceOrganizationReference,
                    ) => {
                      append({
                        orgReference,
                        orgDiverId: '',
                      });
                    }}
                  />
                )}
              />
            )}
            {clubSettings.customer.diving.divingTechnicalLevel && (
              <AppFormControlRHF
                className=" w-full"
                label="Niveau technique"
                control={control}
                name={'diver.details.divingTechnicalLevel'}
                renderComponent={(props) => (
                  <AppSingleSelect2HeadlessUI
                    {...props}
                    className="w-full"
                    theme="material-ui"
                    options={technicalLevelOptions}
                  />
                )}
              />
            )}
            {clubSettings.customer.diving.freeDiveCertificationReference && (
              <AppFormControlRHF
                className=" w-full"
                label="Niveau d'apnée"
                control={control}
                name={
                  'diver.details.freeDiveCertification1.freeDiveCertificationReference'
                }
                renderComponent={(props) => (
                  <AppSingleSelect2HeadlessUI
                    {...props}
                    className="w-full"
                    theme="material-ui"
                    options={freeDiveCertificationOptions}
                  />
                )}
              />
            )}
            {clubSettings.customer.diving.photoInterest && (
              <AppFormControlRHF
                className=" w-full"
                label="Photo / vidéo"
                control={control}
                name={'diver.details.photoInterest'}
                renderComponent={(props) => (
                  <AppInputBooleanSwitchRHF {...props} />
                )}
              />
            )}
          </>
        )}
      </div>
      {mode === 'create' && (
        <>
          <div
            className={
              'mt-2 w-full flex flex-col sm:grid sm:grid-cols-2 md:grid-cols-4 gap-4'
            }
          >
            <BookingParticipantDiveModeSpecificReference
              className="md:col-span-2"
              form={form}
              context={'clubDiver'}
              mode={mode}
              onDiveModeChange={onDiveModeChange}
            />
          </div>
          {isDiverWithMember && (
            <>
              <div
                className={
                  'mt-2 w-full flex flex-col sm:grid sm:grid-cols-2 md:grid-cols-4 gap-4'
                }
              >
                <AppFormControlRHF
                  className="w-full"
                  label="Dernière plongée"
                  control={control}
                  name={'bookingMember.details.lastDiveDate' as const}
                  renderComponent={(props) => (
                    <AppInputDatePickerMaterialRHF
                      className="w-full"
                      {...props}
                    />
                  )}
                />
                <AppFormControlRHF
                  className="w-full"
                  label="Total plongées"
                  control={control}
                  name={'bookingMember.details.totalDivesCount' as const}
                  renderComponent={(props) => (
                    <AppInputRHF className="w-full" type="number" {...props} />
                  )}
                />
                {fieldsToDisplay === 'all' && (
                  <AppFormControlRHF
                    className={'md:col-span-2 w-full'}
                    label="Commentaire du client 👨‍🦰👩‍🦰"
                    helpDescription="Renseigné par le client depuis son espace client"
                    control={control}
                    name={'bookingMember.details.expectedDive.customerComment'}
                    renderComponent={(props) => (
                      <AppInputRHF
                        {...props}
                        fullWidth
                        multiline
                        rowsMax={15}
                        rows={2}
                      />
                    )}
                  />
                )}
              </div>
            </>
          )}
        </>
      )}
      <AppFormControlRHF
        className={'mt-2 w-full'}
        label="Commentaire plongée privé 🚫"
        helpDescription="Un indicateur sera visible sur le planning si ce commentaire est renseigné"
        control={control}
        name={'diver.divingComment'}
        renderComponent={(props) => (
          <AppInputRHF {...props} fullWidth multiline rowsMax={15} rows={2} />
        )}
      />
      {mode === 'edit' && isDiverWithMember && (
        <>
          {fieldsToDisplay === 'all' && (
            <h3 className="mt-6 text-base font-bold text-gray-600 uppercase">
              Informations complémentaires
            </h3>
          )}
          {fieldsToDisplay === 'all' && (
            <div
              className={
                'mt-2 w-full flex flex-col sm:grid sm:grid-cols-2 md:grid-cols-4 gap-4'
              }
            >
              <AppFormControlRHF
                className="w-full"
                label="Dernière plongée"
                control={control}
                name={'bookingMember.details.lastDiveDate' as const}
                renderComponent={(props) => (
                  <AppInputDatePickerMaterialRHF
                    className="w-full"
                    {...props}
                  />
                )}
              />
              <AppFormControlRHF
                className="w-full"
                label="Total plongées"
                control={control}
                name={'bookingMember.details.totalDivesCount' as const}
                renderComponent={(props) => (
                  <AppInputRHF className="w-full" type="number" {...props} />
                )}
              />

              <AppFormControlRHF
                className="md:col-span-2 w-full"
                label="Prestation souhaitée"
                control={control}
                name={'bookingMember.details.expectedDive.diveMode' as const}
                required={false}
                renderComponent={(props) => (
                  <AppSingleAutocomplete2RHF
                    {...props}
                    options={diveModesOptions}
                    onChange={(diveMode: DiveMode) => {
                      // onDiveModeChange(diveMode);
                    }}
                  />
                )}
              />
            </div>
          )}
          {fieldsToDisplay === 'all' && (
            <AppFormControlRHF
              className={'mt-2 w-full'}
              label="Commentaire du client 👨‍🦰👩‍🦰"
              helpDescription="Renseigné par le client depuis son espace client"
              control={control}
              name={'bookingMember.details.expectedDive.customerComment'}
              renderComponent={(props) => (
                <AppInputRHF
                  {...props}
                  fullWidth
                  multiline
                  rowsMax={15}
                  rows={2}
                />
              )}
            />
          )}
        </>
      )}
    </div>
  );
};
