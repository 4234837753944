import {
  ClubProductPackageOfferViewDisplayCategory,
  ProMultiOperationPayload,
  ProMultiOperationPayloadAction,
} from '@mabadive/app-common-model';
import { useCallback, useMemo } from 'react';
import { proMultiMutationsApiClient } from 'src/business/_core/data/app-operation';
import { confirmDialog } from 'src/business/_core/modules/layout/components/ConfirmDialog/confirmDialog.service';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import {
  useAppSecurityUser,
  useAppSecurityUserClubAuthorizations,
} from 'src/business/auth/services';
import { useClubResume, useClubSettings } from 'src/business/club/data/hooks';
import { AppHamburgerMenuItem } from 'src/business/club/modules/_common/ui';
import { ClubSettingsServicesViewPanelOffersPricesLocalState } from '../../../_common/offers-prices/view/useClubSettingsServicesViewPanelOffersPricesLocalState.hook';

export function useClubSettingsServicesStoreViewPanel_CategoryGroupMenuItems({
  localState,
  categoryGroup,
}: {
  localState: ClubSettingsServicesViewPanelOffersPricesLocalState;
  categoryGroup: ClubProductPackageOfferViewDisplayCategory;
}) {
  const user = useAppSecurityUser();
  const az = useAppSecurityUserClubAuthorizations();

  const clubResume = useClubResume();
  const clubSettings = useClubSettings();
  const publicSettings = clubResume.clubSettings.publicSettings;

  const { category: offerCategory, offers } = categoryGroup;

  const onDeleteOfferCategory = useCallback(async () => {
    if (offerCategory?._id) {
      const payload: ProMultiOperationPayload =
        proMultiMutationsApiClient.createEmptyPayload({
          logContext: 'delete offer category',
          bookingIdsToClean: [],
        });

      const payloadAction: ProMultiOperationPayloadAction = {
        actionContext: 'ProductOfferCategoryDialog',
        actionSteps: [
          {
            label: 'delete offer category',
            date: new Date(),
          },
        ],
        clubProductPackageOfferCategory: {
          deletedIds: [offerCategory?._id],
        },
      };
      payload.actions.push(payloadAction);

      await proMultiMutationsApiClient.update(payload);
    }
  }, [offerCategory?._id]);
  const confirmDeleteOfferCategory = useCallback(() => {
    confirmDialog
      .confirm({
        title: `Supprimer la prestation "${offerCategory?.title}"`,
        message: 'Êtes-vous sûr de vouloir supprimer cette prestation?',
        type: 'remove',
      })
      .subscribe((confirmed) => {
        if (confirmed) {
          onDeleteOfferCategory();
        }
      });
  }, [offerCategory?.title, onDeleteOfferCategory]);

  const hamburgerMenuItems: AppHamburgerMenuItem[] = useMemo(() => {
    const hamburgerMenuItems: AppHamburgerMenuItem[] = [];

    // if (az?.edit.participant.paymentEdit) {
    hamburgerMenuItems.push({
      id: 'create-offer',
      titleShort: 'Nouveau produit',
      title: 'Nouveau produit',
      // description: 'Modifier les détails du tarif',
      icon: AppHeroIcons.actionEdit,
      // severity: 'success',
      onClick: () => {
        localState.actions.createProduct(offerCategory);
      },
    });
    hamburgerMenuItems.push({
      id: 'edit-offer-category',
      titleShort: 'Modifier catégorie',
      title: 'Modifier catégorie',
      // description: 'Modifier les détails du tarif',
      icon: AppHeroIcons.actionEdit,
      // severity: 'success',
      onClick: () => {
        localState.actions.openOfferEditCategoryDialog({
          mode: 'edit',
          defaultValue: { offerCategory },
          serviceCategory: offerCategory.serviceCategory,
        });
      },
    });
    // hamburgerMenuItems.push({
    //   id: 'clone-offer',
    //   titleShort: 'Dupliquer',
    //   title: 'Dupliquer',
    //   // description: 'Dupliquer les détails du tarif',
    //   icon: AppHeroIcons.actionClone,
    //   onClick: () => {
    //     localState.actions.openOfferEditDialog({
    //       mode: 'create',
    //       offers: [
    //         {
    //           offer: {
    //             ...offer,
    //             _id: null,
    //           },
    //         },
    //       ],
    //     });
    //   },
    // });
    if (offers.length === 0) {
      hamburgerMenuItems.push({
        id: 'delete-offer-category',
        titleShort: 'Supprimer',
        title: 'Supprimer',
        severity: 'danger',
        // description: 'Modifier les détails du tarif',
        icon: AppHeroIcons.actionDelete,
        onClick: () => {
          confirmDeleteOfferCategory();
        },
      });
    }
    return hamburgerMenuItems;
  }, [
    confirmDeleteOfferCategory,
    localState.actions,
    offerCategory,
    offers.length,
  ]);

  return hamburgerMenuItems;
}
