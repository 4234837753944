import React, { useMemo } from 'react';
import { DiveCenterProBadge } from '../../../../../../../../pages/_components/company';
import { ClubDialogsStateOld } from '../../../../../../../../pages/_dialogs';
import {
  AppButton,
  AppMessage,
  AppVisible,
} from '../../../../../../../_core/modules/layout';
import { AppHeroIcons } from '../../../../../../../_core/modules/layout/icons';
import {
  useClubResume,
  useDiveCenterResume,
} from '../../../../../../data/hooks';
import { BookingTabModel } from '../../../DiverBookingPage/models';
import { bookingTabMultiBookingsAutoFixSuggestionDetector } from '../../../DiverBookingPage/services';
import { DiverBookingPageBookingTabHeaderAutoFix } from '../../../DiverBookingPage/tabs/DiverBookingPageBookingTab/DiverBookingPageBookingTabHeaderAutoFix';
import { DiverBookingPageBookingTabHeaderNoBooking } from '../../../DiverBookingPage/tabs/DiverBookingPageBookingTab/DiverBookingPageBookingTabHeaderNoBooking';
import { DiverBookingPageActions } from '../../../DiverBookingPage/useDiverBookingPageActions.hook';
import { DiverBookingPageGlobalState } from '../../../DiverBookingPage/useDiverBookingPageGlobalState.hook';
import { DiverBookingSpace_BookingListItem } from './DiverBookingSpace_BookingListItem';
import { DiverBookingSpace_BookingList_SwitchArchivedBookingVisibility } from './DiverBookingSpace_BookingList_SwitchArchivedBookingVisibility';

export const DiverBookingSpace_BookingList = ({
  globalState,
  dialogs,
  actions,
}: {
  globalState: DiverBookingPageGlobalState;
  dialogs: ClubDialogsStateOld;
  actions: DiverBookingPageActions;
}) => {
  const { state, data } = globalState;

  const diveCenterResume = useDiveCenterResume();
  const clubResume = useClubResume();
  const isMultiDiveCenters = clubResume.diveCenters.length > 1;
  const diveCenterId = diveCenterResume?._id;

  const bookingTabModels: BookingTabModel[] = data.bookingTabModels;
  const showExtraCreateButtonOnTop = useMemo(() => {
    if (isMultiDiveCenters && bookingTabModels.length !== 0) {
      const firstBooking = bookingTabModels[0];
      return (
        (firstBooking.bookingDiveCenterId !== diveCenterId &&
          !firstBooking.aggregatedBooking.booking?.isSharedBooking) ||
        !firstBooking.aggregatedBooking.booking?.active
      );
    }
    return false;
  }, [bookingTabModels, diveCenterId, isMultiDiveCenters]);

  const bookingTabMultiBookingsAutoFixSuggestions = useMemo(() => {
    // apply only on current center bookings
    const currentCenterBookingTabModels = bookingTabModels.filter(
      (x) => x.bookingDiveCenterId === diveCenterId,
    );
    return bookingTabMultiBookingsAutoFixSuggestionDetector.detectAll(
      currentCenterBookingTabModels,
    );
  }, [bookingTabModels, diveCenterId]);

  return (
    <div className="app-p-content grid gap-4">
      <DiverBookingSpace_BookingList_SwitchArchivedBookingVisibility
        globalState={globalState}
      />
      <DiverBookingPageBookingTabHeaderAutoFix
        globalState={globalState}
        actions={actions}
        dialogs={dialogs}
        bookingTabMultiBookingsAutoFixSuggestions={
          bookingTabMultiBookingsAutoFixSuggestions
        }
      />

      <DiverBookingPageBookingTabHeaderNoBooking
        globalState={globalState}
        actions={actions}
        dialogs={dialogs}
        bookingTabModels={bookingTabModels}
      />

      {showExtraCreateButtonOnTop && (
        <AppVisible role="club-edit-participant">
          <div className="text-center">
            <AppButton
              icon={AppHeroIcons.actionAdd}
              color="primary-bg"
              onClick={() => {
                const newBooking = actions.createNewBooking({
                  onSuccess: 'openBooking',
                });
                actions.openBooking(newBooking.booking._id);
              }}
            >
              <div className="flex gap-2 items-center">
                <span>Créer une nouvelle réservation</span>
                {isMultiDiveCenters && (
                  <DiveCenterProBadge diveCenter={diveCenterResume} />
                )}
              </div>
            </AppButton>
          </div>
        </AppVisible>
      )}
      {bookingTabModels.map((bookingTabModel, bookingIndex) => (
        <DiverBookingSpace_BookingListItem
          key={bookingTabModel.bookingId}
          bookingTabModel={bookingTabModel}
          globalState={globalState}
          actions={actions}
        />
      ))}
      {bookingTabModels.length !== 0 && (
        <>
          <AppVisible role="club-edit-participant">
            <div className="my-3">
              <div className="flex flex-col sm:flex-row justify-start gap-x-8 gap-y-4">
                <AppButton
                  icon={AppHeroIcons.actionAdd}
                  color="primary-bg"
                  onClick={() => {
                    actions.createNewBooking({ onSuccess: 'openBooking' });
                    actions.scrollToTopWithDelay();
                  }}
                >
                  <span>Créer une nouvelle réservation</span>
                </AppButton>
              </div>
            </div>
          </AppVisible>
        </>
      )}
      <AppVisible role="club-edit-participant">
        <AppMessage
          className="hidden sm:block mb-5"
          title={'Instruction'}
          message={
            <>
              <div>
                Sur cette page, vous pouvez gérer les réservations d'un ou
                plusieurs plongeurs.
              </div>
              <div className="mt-2 mb-1 font-bold">Principales actions:</div>
              <ul className="ml-3 list-disc">
                <li>
                  Cliquer sur <b>"+ SESSION"</b> pour ajouter une session de
                  plongée à la réservation
                </li>
                <li>
                  Cliquer sur <b>"+ PLONGEUR"</b> pour ajouter un plongeur à la
                  réservation
                </li>
                <li>
                  Cliquer sur <b>la date ou l'heure d'une session</b> pour voir
                  le détail des participants
                </li>
                <li>
                  Cliquer sur le <b>nom d'un plongeur</b> pour modifier ses
                  caractéristiques (nom, niveau, téléphone, date de
                  naissance...)
                </li>
                <li>
                  Cliquer sur la <b>case plongée d'un plongeur</b> l'inscrire ou
                  modifier sa plongée (PA20, baptême, gaz, matériel...)
                </li>
              </ul>
            </>
          }
        />
      </AppVisible>
    </div>
  );
};
