import { Booking } from '@mabadive/app-common-model';
import { useMemo } from 'react';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { useAppSecurityUserClubAuthorizations } from 'src/business/auth/services';
import { AppHamburgerMenuItem } from 'src/business/club/modules/_common/ui';
import { ClubDialogsStateOld } from 'src/pages/_dialogs';
import { useClubResume } from '../../../../../../data/hooks';
import { DiverBookingCardBookingAction } from '../../../_from-diver-app';
import { BookingTabModel, PRO_BookingMemberFull } from '../../models';
import { useDiverBookingPageDuplicateBooking } from '../../services';
import { DiverBookingPageActions } from '../../useDiverBookingPageActions.hook';
import { DiverBookingPageGlobalState } from '../../useDiverBookingPageGlobalState.hook';

export function useDiverBookingCardHamburgerMenu({
  globalState,
  booking,
  bookingMembersFull,
  actions,
  dialogs,
  onClickBooking,
  bookingTabModel,
  setIsExpanded,
  extraMenuItems,
}: {
  globalState: DiverBookingPageGlobalState;
  booking: Booking;
  bookingMembersFull?: PRO_BookingMemberFull[]; // edition only
  actions: DiverBookingPageActions;
  dialogs: ClubDialogsStateOld;
  onClickBooking: (
    bookingTabModel: BookingTabModel,
    action: DiverBookingCardBookingAction,
  ) => void;
  bookingTabModel: BookingTabModel;
  setIsExpanded: (value: boolean) => void;
  extraMenuItems: AppHamburgerMenuItem[];
}) {
  const { data, state } = globalState;
  const { aggregatedData } = data;
  const { clubReference, diveCenterId, updateState, setUpdateState } = state;

  const { openDiverSelector, openSessionSelector, openBookingMassiveEditor } =
    actions;
  const az = useAppSecurityUserClubAuthorizations();

  const clubResume = useClubResume();
  const clubSettings = clubResume?.clubSettings;

  const clubPublicReference = clubResume.clubSettings?.clubPublicReference;
  const customerSpaceEnabled =
    clubPublicReference &&
    clubResume.clubSettings?.publicSettings?.customerSpace?.enabled &&
    booking?._id;
  const sendMessageEnabled =
    clubSettings.communication?.booking?.enableMessage &&
    az.communication?.action?.sendBookingMessage;

  const { setBookingGroupAndJourneyEditDialogState } = dialogs;

  const duplicateBooking = useDiverBookingPageDuplicateBooking({
    clubReference,
    updateState,
    setUpdateState,
  });

  return useMemo(() => {
    const hamburgerMenuItems: AppHamburgerMenuItem[] = [];

    if (az.edit.participant.booking) {
      hamburgerMenuItems.push({
        icon: AppHeroIcons.actionEdit,
        severity: 'info',
        title: 'Modifier',
        description: 'Dates de séjours, club...',
        onClick: () => {
          setBookingGroupAndJourneyEditDialogState({
            isOpen: true,
            booking,
            bookingMembersFull,
          });
        },
      });
      if (booking.active) {
        if (booking.bookingStatus === 'pending') {
          hamburgerMenuItems.push({
            icon: AppHeroIcons.check,
            severity: 'success',
            title: 'Confirmer la réservation',
            // description: 'Vous ne pourrez plus ajouter de plongeur',
            onClick: () => {
              onClickBooking(bookingTabModel, 'confirm-booking');
            },
          });
        }
      }
      if (customerSpaceEnabled) {
        hamburgerMenuItems.push({
          icon: AppHeroIcons.link,
          severity: 'danger',
          title: 'Espace client',
          /*************  ✨ Codeium Command ⭐  *************/
          /**
           * Persist booking changes and open customer space
           */
          /******  a66bbaa2-56f8-47c7-97a7-6b6be11807f2  *******/
          onClick: () => {
            actions.persistAndOpenCustomerSpace(bookingTabModel);
          },
        });
      }
      if (sendMessageEnabled) {
        hamburgerMenuItems.push({
          icon: AppHeroIcons.sms,
          severity: 'info',
          title: 'Envoyer un MESSAGE',
          onClick: () => {
            actions.persistAndOpenCreateMessage(bookingTabModel);
          },
        });
      }
      const { futureSessionsCount } = bookingTabModel.meta;

      const showMassiveDialogButton =
        (bookingMembersFull.length > 1 && futureSessionsCount > 0) ||
        (bookingMembersFull.length > 0 && futureSessionsCount > 1);

      if (booking.active && showMassiveDialogButton) {
        hamburgerMenuItems.push({
          icon: AppHeroIcons.actionEditBulk,
          severity: 'info',
          title: 'Inscription en masse',
          onClick: () => {
            openBookingMassiveEditor({
              bookingId: booking._id,
            });
          },
        });
      }
    }

    if (booking.active) {
      if (az.edit.participant.booking) {
        hamburgerMenuItems.push({
          title: 'Ajouter un plongeur',
          description:
            'Créez un nouveau plongeur et ajoutez-le à cette réservation',
          icon: AppHeroIcons.actionAdd,
          onClick: () => {
            openDiverSelector({ bookingId: booking._id });
          },
        });
        hamburgerMenuItems.push({
          title: 'Ajouter une session',
          description:
            'Ajouter une session de plongée à cette réservation et inscrivez-y les plongeurs',
          icon: AppHeroIcons.actionAdd,
          onClick: () => {
            openSessionSelector({ bookingId: booking._id });
          },
        });
      }
    }

    if (az.edit.participant.booking) {
      hamburgerMenuItems.push({
        icon: AppHeroIcons.actionClone,
        severity: 'info',
        title: 'Dupliquer la réservation',
        description: 'Créer une nouvelle réservation avec les mêmes plongeurs',
        onClick: () => {
          const newBooking = duplicateBooking({
            bookingSource: bookingTabModel,
          });
          actions.openBooking(newBooking.booking._id);
        },
      });
      if (booking.active) {
        hamburgerMenuItems.push({
          icon: AppHeroIcons.actionArchive,
          severity: 'warn',
          title: 'Archiver la réservation',
          description: 'Vous ne pourrez plus ajouter de plongeur',
          onClick: () => {
            onClickBooking(bookingTabModel, 'archive-booking');
            setIsExpanded(false);
          },
        });
      } else {
        hamburgerMenuItems.push({
          icon: AppHeroIcons.actionArchive,
          severity: 'info',
          title: 'Ré-activer la réservation',
          description: 'Vous pourrez de nouveau ajouter des plongeurs',
          onClick: () => {
            onClickBooking(bookingTabModel, 'unarchive-booking');
            setIsExpanded(true);
          },
        });
      }
      hamburgerMenuItems.push({
        icon: AppHeroIcons.actionRemove,
        severity: 'danger',
        title: 'Supprimer cette réservation et ses plongées',
        description: 'ATTENTION: la suppression est définitive',
        onClick: () => {
          onClickBooking(bookingTabModel, 'delete-booking');
        },
      });
    }

    return hamburgerMenuItems.concat(extraMenuItems);
  }, [
    az.edit.participant.booking,
    booking,
    extraMenuItems,
    customerSpaceEnabled,
    sendMessageEnabled,
    bookingTabModel,
    bookingMembersFull,
    setBookingGroupAndJourneyEditDialogState,
    onClickBooking,
    actions,
    openBookingMassiveEditor,
    openDiverSelector,
    openSessionSelector,
    duplicateBooking,
    setIsExpanded,
  ]);
}
