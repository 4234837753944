import {
  ClubProductPackageOfferView,
  DIVE_TRAININGS_REFERENCES_FR,
  DIVE_TRAININGS_REFERENCES_INT,
  DiveTrainingReference,
  DiveTrainingReferenceFrench,
  DiveTrainingReferenceInternational,
} from '@mabadive/app-common-model';
import { useMemo } from 'react';

export function useUsedTrainingReferencesFromOffers(
  existingOffers?: ClubProductPackageOfferView[],
): {
  trainingReferencesFr: DiveTrainingReference[];
  trainingReferencesInt: DiveTrainingReference[];
} {
  return useMemo(() => {
    const trainingReferencesFr: DiveTrainingReference[] = [];
    const trainingReferencesInt: DiveTrainingReference[] = [];
    if (existingOffers) {
      existingOffers.forEach((offer) => {
        if (offer.productPackage.type === 'training') {
          const reference =
            offer.productPackage.trainingAttributes?.diveTrainingReference;
          if (reference) {
            if (
              DIVE_TRAININGS_REFERENCES_FR.includes(
                reference as DiveTrainingReferenceFrench,
              ) &&
              !trainingReferencesFr.includes(reference)
            ) {
              trainingReferencesFr.push(reference);
            } else if (
              DIVE_TRAININGS_REFERENCES_INT.includes(
                reference as DiveTrainingReferenceInternational,
              ) &&
              !trainingReferencesInt.includes(reference)
            ) {
              trainingReferencesInt.push(reference);
            }
          }
        }
      });
    }

    return {
      trainingReferencesFr,
      trainingReferencesInt,
    };
  }, [existingOffers]);
}
