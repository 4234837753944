/* eslint-disable jsx-a11y/alt-text */
import {
  AppDocImageDimensions,
  AppDocumentCreatorUploadStatus,
  PRO_AppDocResume,
} from '@mabadive/app-common-model';
import React, { useCallback, useState } from 'react';
import { useAppInputFileUpload } from '../../../../_common-browser';
import {
  AppButton,
  AppDialogModal,
  AppDialogModalFixedBar,
  AppDropzone,
  AppLoaderSpinner,
} from '../../../../business/_core/modules/layout';
import { AppIconsAction } from '../../../../business/_core/modules/layout/icons';
import { AppFormLabel } from '../AppFormLabel';
import { AppImageCropper } from './AppImageCropper';
import { appImageConverter } from './appImageConverter.service';

export type AppInputImageViewMode =
  | 'dropzone'
  | 'uploading'
  | 'crop'
  | 'preview';

export const AppDocResumeImageUploader = ({
  initialViewMode: initialViewModeInput,
  initialImageSrc,
  uploadUrl,
  uploadPayload,
  onSuccess,
  onCancel,
  expectedImage,
}: {
  initialViewMode?: AppInputImageViewMode;
  initialImageSrc?: string;
  uploadUrl: string;
  uploadPayload: any;
  onSuccess: (appDoc: PRO_AppDocResume) => any;
  onCancel: () => any;
  expectedImage: {
    width: number;
    height: number;
    variantsRatio: number[]; // en % de la taille principale (ex: 50%)
  };
}) => {
  const initialViewMode: AppInputImageViewMode =
    initialViewModeInput ?? initialImageSrc ? 'preview' : 'dropzone';

  const [selectedFile, setSelectedFile] = React.useState<File>();
  const [imageSrc, setImageSrc] = React.useState<string>(initialImageSrc);
  const [croppedImageSrc, setCroppedImageSrc] = React.useState<string>();
  const [uploadStatus, setUploadStatus] =
    React.useState<AppDocumentCreatorUploadStatus>('pending');

  const [viewMode, setViewMode] =
    useState<AppInputImageViewMode>(initialViewMode);

  const { isFileLoading, uploadFile: uploadFileCore } =
    useAppInputFileUpload<PRO_AppDocResume>({
      onSuccess: (appDoc, event) => {
        setUploadStatus('success');
        setViewMode('preview');
        onSuccess(appDoc);
        setIsOpen(false);
      },
      onError: (err) => {
        setUploadStatus('error');
      },
    });

  const uploadFile = useCallback(async () => {
    setViewMode('uploading');
    setUploadStatus('uploading');
    // TODO utiliser le nom original du fichier
    const fileName = selectedFile?.name;
    const file = await appImageConverter.dataURLToFile(croppedImageSrc, {
      fileName,
      // maxWidthOrLength: Math.max(expectedImage.width, expectedImage.height),
    });
    const imageDimensions: AppDocImageDimensions = {
      width: expectedImage.width,
      height: expectedImage.height,
    };
    const imageVariants: AppDocImageDimensions[] = (
      expectedImage.variantsRatio ?? []
    ).map((ratio) => {
      return {
        width: Math.round(expectedImage.width * ratio),
        height: Math.round(expectedImage.height * ratio),
      };
    });
    await uploadFileCore(file, {
      url: uploadUrl,
      json: {
        payload: uploadPayload,
        imageDimensions,
        imageVariants,
      },
    });
  }, [
    croppedImageSrc,
    expectedImage.height,
    expectedImage.variantsRatio,
    expectedImage.width,
    selectedFile?.name,
    uploadFileCore,
    uploadPayload,
    uploadUrl,
  ]);

  const [isOpen, setIsOpen] = useState(true);

  const onCancelClick = useCallback(() => {
    setIsOpen(false);
    onCancel();
  }, [onCancel]);

  return (
    <AppDialogModal
      open={isOpen}
      className="bg-white"
      maxHeight="md:max-h-[900px]"
      maxWidth="md:max-w-2xl lg:max-w-3xl 2xl:max-w-5xl"
      expandWidth={true}
      expandHeight={true}
      setOpen={(isOpen) => {
        if (!isOpen) {
          onCancelClick();
        } else {
          setIsOpen(true);
        }
      }}
    >
      <div className="w-full app-p-content grid 2xl:grid-cols-2 gap-y-4 gap-x-8">
        {viewMode === 'dropzone' && (
          <AppDropzone
            maxFileSizeLabel="10 Mo"
            acceptFileTypes={['image']}
            label="Ajouter une image"
            onFileLoaded={async ({ file, content, fileType }) => {
              setSelectedFile(file);
              const imageDataUrl = await appImageConverter.getImageDataUrl(
                file,
                {
                  // maxWidthOrLength: 5000,
                },
              );
              setImageSrc(imageDataUrl);
              setViewMode('crop');
            }}
          />
        )}
        {viewMode === 'crop' && (
          <AppFormLabel
            label="Recadrage de l'image"
            required={true}
            labelFontSize="md"
          >
            <AppImageCropper
              aspectRatio={expectedImage.width / expectedImage.height}
              imageSrc={imageSrc} // "https://img.huffingtonpost.com/asset/5ab4d4ac2000007d06eb2c56.jpeg?cache=sih0jwle4e&ops=1910_1000"
              onCropped={(convertedImage, attributes) => {
                setCroppedImageSrc(convertedImage);
                // TODO: gérer un mode preview sans le crop
              }}
            />
          </AppFormLabel>
        )}
        {croppedImageSrc && (
          <AppFormLabel label="Aperçu" labelFontSize="md">
            <div className="pb-8 flex flex-col items-center gap-8">
              <img
                className={'max-h-[180px] sm:max-h-[200px] lg:max-h-[300px]'}
                src={croppedImageSrc}
              />
              {viewMode === 'uploading' && (
                <>
                  {isFileLoading && (
                    <div className="flex gap-2 items-center">
                      <AppLoaderSpinner className="flex-shrink-0 w-6 h-6 md:w-8 md:h-8 bg-app-primary" />{' '}
                      envoi du fichier en cours...
                    </div>
                  )}
                  {uploadStatus === 'error' && (
                    <div className="flex gap-2 items-center">
                      <div className="text-sm text-status-error font-bold uppercase">
                        Erreur lors de l'enregistrement
                      </div>
                      <AppButton
                        color="primary-outline-light"
                        onClick={() => {
                          // retry
                          uploadFile();
                        }}
                      >
                        Ré-essayer
                      </AppButton>
                    </div>
                  )}
                </>
              )}
            </div>
          </AppFormLabel>
        )}
      </div>
      <AppDialogModalFixedBar>
        <div className="flex gap-8 justify-between md:justify-center">
          <AppButton
            className="w-full uppercase sm:max-w-xs"
            fullWidth={true}
            size="normal"
            icon={AppIconsAction.back}
            color={'gray-outline-light'}
            tabIndex={500}
            // disabled={viewMode === 'uploading'}
            onClick={() => {
              onCancelClick();
            }}
          >
            Annuler
          </AppButton>
          {(viewMode === 'crop' || viewMode === 'uploading') && (
            <AppButton
              className="w-full uppercase sm:max-w-xs"
              color={'primary-outline-light'}
              size="normal"
              disabled={viewMode === 'uploading'}
              icon={AppIconsAction.upload}
              onClick={() => {
                uploadFile();
              }}
            >
              Valider
            </AppButton>
          )}
        </div>
      </AppDialogModalFixedBar>
    </AppDialogModal>
  );
};
