import {
  AppDocStatus,
  Booking,
  DiveMode,
  PRO_AppDocResume,
} from '@mabadive/app-common-model';
import {
  appInquiryFilter,
  dateService,
  phoneLinkBuilder,
  smsLinkBuilder,
} from '@mabadive/app-common-services';
import Tippy from '@tippyjs/react';
import clsx from 'clsx';
import React, { useCallback, useMemo } from 'react';
import {
  AppButton,
  AppButtonMultiActions,
} from '../../../../../../../_core/modules/layout';
import {
  AppHeroIcons,
  AppIconsAction,
  AppIconsInquiry,
  AppIconsStatus,
} from '../../../../../../../_core/modules/layout/icons';
import { useAppSecurityUserClubAuthorizations } from '../../../../../../../auth/services';
import { useClubResume } from '../../../../../../data/hooks';
import {
  BookingResumeOnClickMemberAction,
  ClubDiverProfilePicture,
  DiveCenterBookingMemberNameLabel,
} from '../../../_from-diver-app';
import { DiverBillingSessionsTableDayHeader } from '../../../DiverBookingPage/components/DiverBillingSessionsTable/DiverBillingSessionsTableDayHeader';
import { DiverBillingSessionsTableSessionHeader } from '../../../DiverBookingPage/components/DiverBillingSessionsTable/DiverBillingSessionsTableSessionHeader';
import { useBookingResumeMemberHamburgerMenuItems } from '../../../DiverBookingPage/components/DiverBookingCard/BookingResumeMembersTable/useBookingResumeMemberHamburgerMenuItems.hook';
import { ParticipantCellBadge } from '../../../DiverBookingPage/components/DiverBookingSessionsTable';
import {
  PRO_BookingMemberFull_WithDocs,
  PRO_BookingParticipantFull,
  PRO_BookingResume,
} from '../../../DiverBookingPage/models';
import { DiverBookingPageGlobalState } from '../../../DiverBookingPage/useDiverBookingPageGlobalState.hook';
import { DiverBookingSpace_DocDataResumeBadge } from '../DiverBookingSpace_DocDataResume';
import { DiverBookingSpace_DocDataResume } from '../DiverBookingSpace_DocDataResume/model/DiverBookingSpace_DocDataResume.type';
import {
  docDataResumeBuilder_DocOnly,
  docDataResumeBuilder_Insurance,
  docDataResumeBuilder_MainCertification,
  docDataResumeBuilder_MedicalCertificate,
  docDataResumeBuilder_MedicalForm,
} from '../DiverBookingSpace_DocDataResume/services';

export type DocDataResume_Status = AppDocStatus | 'missing' | 'expired';

export type DocDataResume = {
  title: string;
  docDate: Date;
  docStatus: DocDataResume_Status;
  docId: string;
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
};

export function DiverBookingSpace_BookingParticipantsListItem({
  bookingMembersCount,
  booking,
  aggregatedBookingResume,
  bookingMemberFull,
  showProfilePhoto,
  showParticipantDetails,
  globalState,
  onClick,
  className,
}: {
  bookingMembersCount: number;
  booking: Booking;
  aggregatedBookingResume: PRO_BookingResume;
  bookingMemberFull: PRO_BookingMemberFull_WithDocs;
  showProfilePhoto: boolean;
  showParticipantDetails: boolean;
  globalState: DiverBookingPageGlobalState;
  onClick?: (
    bookingMemberFull: PRO_BookingMemberFull_WithDocs,
    action: BookingResumeOnClickMemberAction,
  ) => void;
  className?: string;
}) {
  const { bookingOpeningStates, setBookingOpeningStates } = globalState;

  const bookingId = booking._id;

  const diver = bookingMemberFull?.diver;
  const bookingMember = bookingMemberFull?.bookingMember;

  // bookingMemberFull?.booking?.s

  const phoneNumberClean = useMemo(
    () => smsLinkBuilder.cleanPhoneNumber(diver?.phoneNumber),
    [diver?.phoneNumber],
  );
  const emailAddress = useMemo(
    () => diver?.emailAddress?.trim(),
    [diver?.emailAddress],
  );

  const clubResume = useClubResume();

  const {
    nextDiveModes,
    lastMemberBookingParticipantFull,
    nextMemberBookingParticipantFull,
  } = useMemo(() => {
    // on va chercher dans toutes les réservations chargées, pour afficher ces infos
    const memberBookingParticipantsFull =
      globalState?.aggregatedData?.bookingParticipantsFull.filter(
        (bpf) => bpf.diver?._id === diver?._id,
      );
    // only next
    const {
      nextMemberBookingParticipantsFull,
      lastMemberBookingParticipantsFull,
    } = memberBookingParticipantsFull?.reduce(
      (acc, bpf) => {
        if (
          dateService.isTodayUTC(bpf?.diveSession?.time) ||
          dateService.isFutureDayUTC(bpf?.diveSession?.time)
        ) {
          acc.nextMemberBookingParticipantsFull.push(bpf);
        } else {
          acc.lastMemberBookingParticipantsFull.push(bpf);
        }
        return acc;
      },
      {
        nextMemberBookingParticipantsFull: [],
        lastMemberBookingParticipantsFull: [],
      } as {
        nextMemberBookingParticipantsFull: PRO_BookingParticipantFull[];
        lastMemberBookingParticipantsFull: PRO_BookingParticipantFull[];
      },
    );

    const nextMemberBookingParticipantFull =
      nextMemberBookingParticipantsFull?.[0];

    const lastMemberBookingParticipantFull =
      lastMemberBookingParticipantsFull?.[0];

    const diveModes = nextMemberBookingParticipantsFull?.reduce((acc, bpf) => {
      if (!acc.includes(bpf?.diveSessionParticipant?.diveMode)) {
        acc.push(bpf?.diveSessionParticipant?.diveMode);
      }
      return acc;
    }, [] as DiveMode[]);
    const nextDiveModes = [...new Set(diveModes)];
    return {
      nextDiveModes,
      lastMemberBookingParticipantFull,
      nextMemberBookingParticipantFull,
    };
  }, [diver?._id, globalState?.aggregatedData?.bookingParticipantsFull]);

  const enabledInquiries = useMemo(
    () =>
      (clubResume.inquiries ?? []).filter(
        (inquiry) => inquiry.settings?.enabled,
      ),
    [clubResume.inquiries],
  );

  const DISABLE_INQUIRIES = false;
  const activeInquiries = useMemo(
    () =>
      DISABLE_INQUIRIES
        ? []
        : appInquiryFilter.findActiveInquiries(enabledInquiries, {
            nextDiveModes,
            diver,
            bookingMemberConfigInquiry:
              bookingMemberFull?.bookingMember?.config?.inquiry,
          }),
    [
      DISABLE_INQUIRIES,
      enabledInquiries,
      nextDiveModes,
      diver,
      bookingMemberFull?.bookingMember?.config?.inquiry,
    ],
  );

  const inquiryResponses = bookingMemberFull.inquiryResponses;

  const medicalCertificateDocData: DiverBookingSpace_DocDataResume = useMemo(
    () =>
      docDataResumeBuilder_MedicalCertificate.buildMedicalCertificate({
        bookingMemberFull,
        clubResume,
      }),
    [bookingMemberFull, clubResume],
  );

  const mainCertificationDocData: DiverBookingSpace_DocDataResume = useMemo(
    () =>
      docDataResumeBuilder_MainCertification.buildMainCertification({
        bookingMemberFull,
        clubResume,
      }),
    [bookingMemberFull, clubResume],
  );
  const medicalFormDocData: DiverBookingSpace_DocDataResume = useMemo(
    () =>
      docDataResumeBuilder_MedicalForm.buildMedicalForm({
        bookingMemberFull,
        clubResume,
      }),
    [bookingMemberFull, clubResume],
  );
  const parentalConsentDocData: DiverBookingSpace_DocDataResume = useMemo(
    () =>
      docDataResumeBuilder_DocOnly.buildDocForm({
        bookingMemberFull,
        docRef: 'parental-consent',
        title: 'Autorisation parentale',
        icon: AppIconsInquiry['parental-consent'],
      }),
    [bookingMemberFull],
  );
  const insuranceDocData: DiverBookingSpace_DocDataResume = useMemo(
    () =>
      docDataResumeBuilder_Insurance.buildInsurance({
        bookingMemberFull,
        clubResume,
      }),
    [bookingMemberFull, clubResume],
  );

  // console.log('xxx activeInquiries:', activeInquiries);
  const az = useAppSecurityUserClubAuthorizations();

  const hamburgerMenuItems = useBookingResumeMemberHamburgerMenuItems({
    onClick,
    bookingMemberFull,
    bookingMembersCount,
  });

  const openEditAppDocDialog = useCallback(
    (docResume: PRO_AppDocResume) => {
      globalState.state.dialogsState.appDocEditDialog.openDialog({
        bookingId,
        bookingMemberId: bookingMemberFull?.bookingMember?._id,
        clubDiverId: diver?._id,
        mode: 'edit',
        docResume,
      });
    },
    [
      bookingId,
      bookingMemberFull?.bookingMember?._id,
      diver?._id,
      globalState.state.dialogsState.appDocEditDialog,
    ],
  );

  return (
    <div
      className={clsx(
        onClick && 'cursor-pointer hover:bg-gray-50/60',
        className,
      )} // `${className} ${onClick ? 'cursor-pointer' : ''}`}
      onClick={() => {
        onClick(bookingMemberFull, 'edit');
      }}
    >
      <div
        // defaultExpanded={showParticipantDetails}
        // renderExpandIcon={({ isOpen }: { isOpen: boolean }) => (
        //   <div className="flex flex-col gap-2 justify-between items-end">
        //     {isOpen ? (
        //       <AppHeroIcons.actionReduce className="w-4 h-4" />
        //     ) : (
        //       <AppHeroIcons.actionExtend className="w-4 h-4" />
        //     )}
        //     {hamburgerMenuItems?.length !== 0 && (
        //       <AppButtonMultiActions items={hamburgerMenuItems} />
        //     )}
        //   </div>
        // )}
        // expandIconPosition="left"
        className="w-full px-1 py-0.5 flex flex-col gap-2 text-gray-600"
      >
        <div className="w-full flex gap-2 items-center justify-between">
          <div className="w-full flex gap-2 justify-start items-center">
            {/* {hamburgerMenuItems?.length !== 0 && (
                <AppButtonMultiActions
                  items={hamburgerMenuItems}
                  buttonLabel=" "
                />
              )} */}
            {az.edit.participant.booking && (
              <AppIconsAction.editAlt
                className="w-10 h-10 text-gray-400 hover:text-gray-600"
                title="Modifier"
                // onClick={(e) => {
                //   e.stopPropagation();
                //   onClick(bookingMemberFull, 'edit');
                // }}
              />
            )}

            <DiveCenterBookingMemberNameLabel
              displayResidentType={true}
              bookingMemberFull={bookingMemberFull}
              isUniqueMember={
                aggregatedBookingResume.bookingMembers.length === 1
              }
              // className={'sm:min-w-[15rem]'}
            />
          </div>
          <div className="flex-0 flex gap-2">
            {(phoneNumberClean || emailAddress) && (
              <div className="hidden md:flex gap-2">
                {phoneNumberClean && (
                  <AppButton
                    fullWidth={false}
                    className="whitespace-nowrap"
                    icon={AppHeroIcons.phone}
                    color={'phone-outline'}
                    href={`tel:${phoneNumberClean}`}
                    // size="small"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <span className="">
                      {phoneLinkBuilder.formatPhoneNumber(phoneNumberClean)}
                    </span>
                  </AppButton>
                )}
                {emailAddress && (
                  <AppButton
                    className="whitespace-nowrap truncate"
                    color={'mail-outline'}
                    icon={AppHeroIcons.contact}
                    href={`mailto:${emailAddress}`}
                    // size="small"
                    onClick={(e) => e.stopPropagation()}
                    target="mabadive_mail_client"
                  >
                    {/* {emailAddress} */}
                    <span className="">{'E-MAIL'}</span>
                  </AppButton>
                )}
              </div>
            )}
            {hamburgerMenuItems?.length !== 0 && (
              <AppButtonMultiActions items={hamburgerMenuItems} />
            )}
          </div>
        </div>
        {showParticipantDetails && (
          <div className="py-2 flex items-start gap-x-4 gap-y-2 flex-wrap">
            {showProfilePhoto && (
              <ClubDiverProfilePicture
                className="mt-1.5 size-[6.5rem] cursor-pointer"
                shape="square"
                diver={diver}
                // onClick={(e) => {
                //   e.stopPropagation();
                //   onClick(bookingMemberFull, 'edit');
                // }}
              />
            )}
            <div className="grid gap-y-1">
              <h4 className="w-28 text-xs uppercase font-medium text-gray-600 truncate">
                Dernière plongée
              </h4>
              {lastMemberBookingParticipantFull ? (
                <div>
                  <DiverBillingSessionsTableSessionHeader
                    className="mb-1"
                    theme={'light'}
                    diveSession={lastMemberBookingParticipantFull.diveSession}
                    // onClick={onClickSession ? () => onClickSession(diveSession) : undefined}
                  />
                  <ParticipantCellBadge
                    // onClick={() => onClickInner('cell')}
                    participant={
                      lastMemberBookingParticipantFull.diveSessionParticipant
                    }
                    diver={lastMemberBookingParticipantFull.diver}
                    diveSession={lastMemberBookingParticipantFull.diveSession}
                    theme={'light'}
                    includeBookingStateTag={true}
                    includeParticipantSessionNumber={true}
                  />
                </div>
              ) : bookingMember?.details?.lastDiveDate ? (
                <Tippy
                  delay={[200, 50]}
                  placement="top"
                  content={'Aucune plongée'}
                >
                  <div
                    className={
                      'font-medium uppercase align-top tracking-wider text-gray-500'
                    }
                  >
                    <DiverBillingSessionsTableDayHeader
                      date={bookingMember?.details?.lastDiveDate}
                    />

                    {bookingMember?.details?.totalDivesCount ? (
                      <div
                        className={
                          'text-gray-500 flex flex-col justify-center gap-1'
                        }
                      >
                        <div className="text-center text-2xl font-bold">
                          {bookingMember?.details?.totalDivesCount}
                        </div>
                        {/* <div className="text-center text-2xl py-4 font-bold text-app-primary">
                      + 14
                    </div> */}
                        <div className="text-center">plongées</div>
                      </div>
                    ) : (
                      <div className="text-center py-4">
                        AUTRE
                        <br />
                        STRUCTURE
                      </div>
                    )}
                  </div>
                </Tippy>
              ) : (
                <div className="flex justify-center items-center">
                  <AppIconsStatus.none className="size-12 text-gray-200" />
                </div>
              )}
            </div>
            <div className="w-28 grid gap-y-1">
              <h4 className="text-xs uppercase font-medium text-gray-600 truncate">
                Proch. plongée
              </h4>
              {nextMemberBookingParticipantFull ? (
                <div>
                  <DiverBillingSessionsTableSessionHeader
                    className="mb-1"
                    theme={'normal'}
                    diveSession={nextMemberBookingParticipantFull.diveSession}
                    // onClick={onClickSession ? () => onClickSession(diveSession) : undefined}
                  />
                  <ParticipantCellBadge
                    // onClick={() => onClickInner('cell')}
                    participant={
                      nextMemberBookingParticipantFull.diveSessionParticipant
                    }
                    diver={nextMemberBookingParticipantFull.diver}
                    diveSession={nextMemberBookingParticipantFull.diveSession}
                    theme={'normal'}
                    includeBookingStateTag={true}
                    includeParticipantSessionNumber={true}
                  />
                </div>
              ) : (
                <div className="flex justify-center items-center">
                  <AppIconsStatus.none className="size-12 text-gray-200" />
                </div>
              )}
            </div>
            {medicalCertificateDocData?.status !== 'missing' && (
              <DiverBookingSpace_DocDataResumeBadge
                docDataResume={medicalCertificateDocData}
                onClick={
                  medicalCertificateDocData?.appDoc
                    ? (e) => {
                        e.stopPropagation();
                        openEditAppDocDialog(medicalCertificateDocData?.appDoc);
                      }
                    : undefined
                }
              />
            )}
            {medicalFormDocData?.status !== 'missing' && (
              <DiverBookingSpace_DocDataResumeBadge
                docDataResume={medicalFormDocData}
                onClick={
                  medicalFormDocData?.appDoc
                    ? (e) => {
                        e.stopPropagation();
                        openEditAppDocDialog(medicalFormDocData?.appDoc);
                      }
                    : undefined
                }
              />
            )}
            {parentalConsentDocData?.status !== 'missing' && (
              <DiverBookingSpace_DocDataResumeBadge
                docDataResume={parentalConsentDocData}
                onClick={
                  parentalConsentDocData?.appDoc
                    ? (e) => {
                        e.stopPropagation();
                        openEditAppDocDialog(parentalConsentDocData?.appDoc);
                      }
                    : undefined
                }
              />
            )}
            {insuranceDocData?.status !== 'missing' && (
              <DiverBookingSpace_DocDataResumeBadge
                docDataResume={insuranceDocData}
                onClick={
                  insuranceDocData?.appDoc
                    ? (e) => {
                        e.stopPropagation();
                        openEditAppDocDialog(insuranceDocData?.appDoc);
                      }
                    : undefined
                }
              />
            )}
            {mainCertificationDocData?.status !== 'missing' && (
              <DiverBookingSpace_DocDataResumeBadge
                docDataResume={mainCertificationDocData}
                onClick={
                  mainCertificationDocData?.appDoc
                    ? (e) => {
                        e.stopPropagation();
                        openEditAppDocDialog(mainCertificationDocData?.appDoc);
                      }
                    : undefined
                }
              />
            )}
            {/* {inquiryResponses.length > 0 && (
            <div className="flex gap-1">
              {inquiryResponses.map((inquiryResponse) => (
                <div key={inquiryResponse._id}>
                  {inquiryResponse.inquiryDetails.name}
                </div>
              ))}
            </div>
          )}
          {activeInquiries.length > 0 && (
            <div className="flex gap-1">
              {activeInquiries.map((inquiry) => (
                <BookingResumeMembersInquiryBadge
                  key={inquiry._id}
                  inquiry={inquiry}
                  status="ok"
                />
              ))}
            </div>
          )} */}
          </div>
        )}
      </div>
    </div>
  );
}
