import {
  BookingProduct,
  ClubPurchasePackage,
  ClubPurchasePackageMeta,
  DiveCenterResume,
} from '@mabadive/app-common-model';
import {
  clubPurchasePackageMetaReader,
  dateService,
} from '@mabadive/app-common-services';
import {
  AggregatedBookingPurchasePackageWithPayments,
  BillingTabDiveSessionBillingResume,
  BillingTabDiveSessionBillingResumePurchaseParticipant,
  BillingTabModelBuildContext,
  BillingTabParticipantPurchase,
  BillingTabParticipantPurchaseDivesCounts,
  BillingTabParticipantPurchaseSessionsBillingResumes,
  BookingResumeParticipantForSession,
  DiverBookingPageLoadedContentFocus,
  PRO_BookingMemberFull_WithDocs,
  PRO_BookingParticipantFull,
  SessionsCountByDiver,
} from '../../../../models';
import { bookingPagePackageConsumedCounter } from '../../../02.update-state/services';
import { billingTabModelCountsBuilder } from '../billingTabModelCountsBuilder';
import { billingTabModelSorter } from '../billingTabModelSorter.service';

export const realParticipantPurchasesBuilder = { buildAll };

function buildAll({
  diveCenterResume,
  buildContext,
  focus,
  sessionsCountsByDiver,
}: {
  diveCenterResume: DiveCenterResume;
  buildContext: BillingTabModelBuildContext;
  focus: DiverBookingPageLoadedContentFocus;
  sessionsCountsByDiver: {
    [diverId: string]: SessionsCountByDiver;
  };
}): BillingTabParticipantPurchase[] {
  const clubResume = diveCenterResume.clubResume;
  const clubSettings = clubResume?.clubSettings;
  const realPurchasePackagesWithPayments =
    buildContext?.realPurchasePackagesWithPayments;

  let allRealPurchaseMatchingProductsIds: string[] = [];

  const realParticipantPurchases: BillingTabParticipantPurchase[] =
    realPurchasePackagesWithPayments
      .map(
        (
          purchasePackageWithPayments: AggregatedBookingPurchasePackageWithPayments,
        ) => {
          const purchasePackage: ClubPurchasePackage =
            purchasePackageWithPayments.purchasePackage;

          const purchaseDiver = buildContext.divers.find(
            (d) => d._id === purchasePackage.diverId,
          );
          const purchaseDivers = buildContext.divers.filter((d) =>
            purchasePackage.diverIds.includes(d._id),
          );

          const referenceDiverId = purchaseDiver?._id;
          const purchaseDiverIds = purchasePackage.diverIds;

          if (
            purchasePackage.productPackageType === 'training' ||
            purchasePackage.productPackageType === 'dive' ||
            purchasePackage.productPackageType === 'store'
          ) {
            const purchasePackageMeta: ClubPurchasePackageMeta =
              clubPurchasePackageMetaReader.readMeta(purchasePackage);

            const purchaseProductPackage =
              purchasePackage.productPackageOffer.productPackage;

            const matchingParticipants = findMatchingParticipants({
              buildContext,
              purchasePackage,
              referenceDiverId,
              diveCenterResume,
            });

            // 09/05/2023 : on ne filtre pas les produits, donc il peuvent apparaitre sur plusieurs prestations (ce qui permet de les assigner là où on veut)
            // filter out matching products from build context
            const matchingProductsIds = matchingParticipants.map(
              (x) => x.bookingProductDive._id,
            );
            allRealPurchaseMatchingProductsIds =
              allRealPurchaseMatchingProductsIds.concat(matchingProductsIds);
            // buildContext.remainingParticipants =
            //   buildContext.remainingParticipants.filter(
            //     (x) => !matchingProductsIds.includes(x.bookingProductDive._id),
            //   );

            const sameTypeSameDiver: BillingTabDiveSessionBillingResume[] =
              billingTabModelSorter.sortBillingTabDiveSessionBillingResume(
                buildProductSessionBillingResumes({
                  matchingParticipants,
                  purchasePackage,
                  focus,
                }),
              );

            const otherTypeSameDiver: BillingTabDiveSessionBillingResume[] =
              buildProductSessionBillingResumes({
                matchingParticipants: findMatchingProductsNotAssigned({
                  purchasePackage,
                  buildContext,
                  referenceDiverId,
                  diveCenterResume,
                  criteria: {
                    isReferenceDiver: true,
                    isSameType: false,
                  },
                }),
                purchasePackage,
                focus,
              });
            const sameTypeOtherDiver: BillingTabDiveSessionBillingResume[] =
              buildProductSessionBillingResumes({
                matchingParticipants: findMatchingProductsNotAssigned({
                  purchasePackage,
                  buildContext,
                  referenceDiverId,
                  diveCenterResume,
                  criteria: {
                    isReferenceDiver: false,
                    isSameType: true,
                  },
                }),
                purchasePackage,
                focus,
              });
            const otherTypeOtherDiver: BillingTabDiveSessionBillingResume[] =
              buildProductSessionBillingResumes({
                matchingParticipants: findMatchingProductsNotAssigned({
                  purchasePackage,
                  buildContext,
                  referenceDiverId,
                  diveCenterResume,
                  criteria: {
                    isReferenceDiver: false,
                    isSameType: false,
                  },
                }),
                purchasePackage,
                focus,
              });

            const sessionsBillingResumes: BillingTabParticipantPurchaseSessionsBillingResumes =
              {
                all: otherTypeOtherDiver
                  .concat(sameTypeSameDiver)
                  .concat(otherTypeSameDiver)
                  .concat(sameTypeOtherDiver),
                sameTypeSameDiver,
                otherTypeSameDiver,
                sameTypeOtherDiver,
                otherTypeOtherDiver,
              };

            const allDates = sameTypeSameDiver.map((x) =>
              x.diveSession.time.getTime(),
            );
            const beginDate =
              allDates.length > 0
                ? dateService.getUTCDateSetTime(new Date(Math.min(...allDates)))
                : dateService.getUTCDateSetTime(purchasePackage?.purchaseDate);
            const endDate =
              allDates.length > 0
                ? dateService.getUTCDateSetTime(new Date(Math.max(...allDates)))
                : dateService.getUTCDateSetTime(purchasePackage?.purchaseDate);

            const successiveDivesCount: number =
              purchasePackage.productPackageOffer?.productPackage
                ?.diveAttributes?.successiveDivesCount;
            const minDistance: number =
              purchasePackage.productPackageOffer?.productPackage
                ?.diveAttributes?.minDistance;
            const maxDistance: number =
              purchasePackage.productPackageOffer?.productPackage
                ?.diveAttributes?.maxDistance;

            const minDepth: number =
              purchasePackage.productPackageOffer?.productPackage
                ?.diveAttributes?.minDepth;
            const maxDepth: number =
              purchasePackage.productPackageOffer?.productPackage
                ?.diveAttributes?.maxDepth;

            const specialDiveType =
              purchasePackage?.productPackageOffer?.productPackage
                ?.diveAttributes?.specialDiveType;

            const diveSessionTheme =
              purchasePackage?.productPackageOffer?.productPackage
                ?.salesCriteria?.diveSessionTheme;

            const divesCounts: BillingTabParticipantPurchaseDivesCounts = {
              // divesCounts mis à jour plus tard dans billingTabModelCountsBuilder à partir des participants
              assigned: 0,
              cancelled: 0,
              toAssign: 0,
              future: 0,
              consumedExternalCount:
                purchasePackageMeta.credits?.consumedExternalCount,
              total:
                purchasePackageMeta.credits?.creditsTotalCount *
                purchasePackage.unitQuantity,
            };

            // isOnlyCancelled mis à jour plus tard dans billingTabModelCountsBuilder
            const isOnlyCancelled =
              billingTabModelCountsBuilder.buildIsOnlyCancelledFromDivesCounts(
                divesCounts,
              );

            const isSuccessivePackage =
              purchaseProductPackage?.diveAttributes?.divePriceType ===
              'successive';

            const countSuccessiveAsSingle =
              bookingPagePackageConsumedCounter.testIfCountSuccessiveAsSingle({
                clubSettings,
                isSuccessivePackage,
              });

            const diverSessionsCount: SessionsCountByDiver =
              sessionsCountsByDiver[purchaseDiver?._id] ?? {
                futureSessionsCounts: 0,
                pastSessionsCounts: 0,
                totalSessionsCounts: 0,
                pastBilledExploSessionsCounts: 0,
              };

            const participantPurchase: BillingTabParticipantPurchase = {
              groupBaseKey: 'not-used', // on l'utilise seulement pour les virtual
              ref: purchasePackage._id,
              type: purchasePackageMeta.isStore
                ? 'store'
                : purchasePackageMeta.isTraining
                ? 'training'
                : purchasePackageMeta.isOther
                ? 'other'
                : 'plan',
              diveMode: purchasePackageMeta.diveMode,
              isVirtual: false,
              successiveDivesCount,
              minDistance,
              maxDistance,
              minDepth,
              maxDepth,
              beginDate,
              endDate,
              diver: purchaseDiver,
              divers: purchaseDivers,
              purchasePackage,
              purchasePackageWithPayments,
              purchasePackageMeta,
              isArchived:
                purchasePackage?.validityStatus !== 'active' &&
                purchasePackage?.purchasePaymentStatus === 'done' &&
                !purchasePackage?.purchasePaymentPending,
              diveTrainingReference:
                purchaseProductPackage?.trainingAttributes
                  ?.diveTrainingReference,
              // supervision: attributesPlan?.supervision,
              // equipment: attributesPlan?.equipment,
              // hasSupervision: isTraining ? true : attributesPlan?.supervision==='supervised',
              planSupervision:
                purchaseProductPackage.diveAttributes?.supervision,
              planEquipment: purchaseProductPackage.diveAttributes?.equipment,
              sessionsBillingResumes,
              divesCounts,
              isOnlyCancelled,
              specialDiveType,
              diveSessionTheme,
              countSuccessiveAsSingle,
              diverSessionsCount,
            };

            billingTabModelCountsBuilder.updateCountsFromParticipants({
              participants: matchingParticipants,
              participantPurchase,
              clubSettings,
            });
            return participantPurchase;
          }
        },
      )
      .filter((x) => !!x);

  // const matchingProductsIds = buildContext.remainingParticipants.map(
  //   (x) => x.bookingProductDive._id,
  // );
  buildContext.remainingParticipants =
    buildContext.remainingParticipants.filter(
      (x) =>
        !allRealPurchaseMatchingProductsIds.includes(x.bookingProductDive._id),
    );

  return realParticipantPurchases;
}

function findMatchingParticipants({
  buildContext,
  purchasePackage,
  referenceDiverId,
  diveCenterResume,
}: {
  buildContext: BillingTabModelBuildContext;
  purchasePackage: ClubPurchasePackage;
  referenceDiverId: string;
  diveCenterResume: DiveCenterResume;
}): PRO_BookingParticipantFull[] {
  const associatedParticipants = buildContext.remainingParticipants.filter(
    (remainingParticipant) =>
      remainingParticipant.bookingProductDive.type === 'dive' &&
      remainingParticipant.bookingProductDive.purchasePackageId ===
        purchasePackage._id,
  );

  const matchingProductsNotAssigned = findMatchingProductsNotAssigned({
    purchasePackage,
    buildContext,
    referenceDiverId,
    diveCenterResume,
    criteria: {
      isReferenceDiver: true,
      isSameType: true,
    },
  });
  const matchingParticipants = associatedParticipants.concat(
    matchingProductsNotAssigned,
  );
  return matchingParticipants;
}

function buildProductSessionBillingResumes({
  purchasePackage,
  matchingParticipants,
  focus,
}: {
  purchasePackage: ClubPurchasePackage;
  matchingParticipants: PRO_BookingParticipantFull[];
  focus: DiverBookingPageLoadedContentFocus;
}) {
  const sessionsBillingResumes: BillingTabDiveSessionBillingResume[] =
    matchingParticipants.map((matchingParticipant) => {
      const { bookingProductDive, diveSession, booking, bookingMember } =
        matchingParticipant;

      const isFocusDiver =
        focus?.clubDiver?._id === matchingParticipant.diver._id;
      const isFocusSession =
        focus?.diveSession?.reference === diveSession.reference;
      const style =
        isFocusDiver && isFocusSession
          ? 'highlight-strong'
          : isFocusDiver || isFocusSession
          ? 'highlight-light'
          : 'normal';

      const bookingMemberFull: PRO_BookingMemberFull_WithDocs = {
        booking: booking,
        bookingMember: bookingMember,
        diver: matchingParticipant.diver,
        docResumes: matchingParticipant.docResumes,
        inquiryResponses: matchingParticipant.inquiryResponses,
      };

      const participant: BookingResumeParticipantForSession = {
        bookingMemberFull,
        bookingParticipantFull: matchingParticipant,
        bookingParticipantFullSameBooking: matchingParticipant,
        bookingParticipantFullAnyBooking: matchingParticipant,
        style,
      };

      const purchaseParticipant: BillingTabDiveSessionBillingResumePurchaseParticipant =
        {
          isAssociatedToPurchase:
            bookingProductDive.purchasePackageId === purchasePackage._id,
          bookingProduct: bookingProductDive,
          participant,
        };

      const r: BillingTabDiveSessionBillingResume = {
        diveSession,
        purchaseParticipant,
      };
      return r;
    });
  // si
  return billingTabModelSorter.sortBillingTabDiveSessionBillingResume(
    sessionsBillingResumes,
  );
}

function findMatchingProductsNotAssigned({
  purchasePackage,
  buildContext,
  referenceDiverId,
  criteria,
  diveCenterResume,
}: {
  purchasePackage: ClubPurchasePackage;
  buildContext: BillingTabModelBuildContext;
  referenceDiverId: string;
  criteria: {
    isReferenceDiver: boolean;
    isSameType: boolean;
  };
  diveCenterResume: DiveCenterResume;
}): PRO_BookingParticipantFull[] {
  // 26/07/2022 : si on laisse cette condition, on ne voit plus les autres prestations quand on édite => NORMAL CAR SI C'est INACTIF, on n'ajoute pas
  // 01/08/2022 : si on retire cette condition, on associe des plongées non facturée à une prestation imputée
  if (purchasePackage.validityStatus !== 'active') {
    return []; // don't auto-assign products to inactive purchase
  }

  const purchaseProductPackage =
    purchasePackage.productPackageOffer.productPackage;

  const isTraining = purchasePackage.productPackageType === 'training';
  const isPlan =
    purchasePackage.productPackageType === 'dive' &&
    (purchaseProductPackage.diveAttributes?.diveMode === 'supervised' ||
      purchaseProductPackage.diveAttributes?.diveMode === 'autonomous');

  const isOther = !(isTraining || isPlan);

  const purchasePackageDetails = {
    isPlan,
    isOther,
    isTraining,
  };

  const matchingParticipants = buildContext.remainingParticipants.filter(
    (remainingParticipant) => {
      const bookingProduct = remainingParticipant.bookingProductDive;

      if (bookingProduct.purchaseStatus !== 'pending') {
        return false;
      }

      if (bookingProduct.type === 'dive') {
        if (
          bookingProduct.purchasePackageId &&
          bookingProduct.purchasePackageId !== purchasePackage._id
        ) {
          // already associated to an other package
          return false;
        }

        if (
          (criteria.isReferenceDiver &&
            referenceDiverId === remainingParticipant.diver._id) ||
          (!criteria.isReferenceDiver &&
            referenceDiverId !== remainingParticipant.diver._id)
        ) {
          // same diver
          const isMatching = isMatchingProduct({
            bookingProduct,
            purchasePackage,
            diveCenterResume,
            remainingParticipant,
            purchasePackageDetails,
          });
          if (criteria.isSameType) {
            return isMatching;
          } else {
            // non-matching dives

            return !isMatching;
          }
        }
      }

      return false;
    },
  );
  return matchingParticipants;
}

function isMatchingProduct({
  bookingProduct,
  purchasePackage,
  diveCenterResume,
  remainingParticipant,
  purchasePackageDetails,
}: {
  bookingProduct: BookingProduct;
  purchasePackage: ClubPurchasePackage;
  diveCenterResume: DiveCenterResume;
  remainingParticipant: PRO_BookingParticipantFull;
  purchasePackageDetails: {
    isPlan: boolean;
    isOther: boolean;
    isTraining: boolean;
  };
}): boolean {
  const { isPlan, isOther, isTraining } = purchasePackageDetails;

  const clubBillingExplorationsSettings =
    diveCenterResume.clubResume.clubSettings.general?.billing?.explorations;

  const groupAutonomousAndSupervised =
    clubBillingExplorationsSettings?.groupsCriteria
      ?.groupAutonomousAndSupervised;
  const explorationsGroupsCriteria =
    clubBillingExplorationsSettings?.groupsCriteria;

  const purchaseProductPackage =
    purchasePackage.productPackageOffer.productPackage;

  switch (bookingProduct.attributes.diveMode) {
    case 'instructor': // à facturer comme un autonome
    case 'autoSupervised':
    case 'autonomous':
    case 'supervised': {
      if (
        isPlan &&
        (groupAutonomousAndSupervised ||
          bookingProduct.attributes.diveMode === 'instructor' ||
          bookingProduct.attributes.hasSupervision ===
            (purchaseProductPackage.diveAttributes?.supervision !==
              'autonomous'))
      ) {
        // same plan
        // NOTE: on ne prend pas en compte l'équipement ici pour le moment
        if (explorationsGroupsCriteria?.multiSessions) {
          // session multiple (double ou triple bloc)
          const packageSuccessiveDivesCount =
            purchasePackage.productPackageOffer?.productPackage?.diveAttributes
              ?.successiveDivesCount;
          const isExploMultipleSession =
            !!remainingParticipant.diveSession.diveTourSession2;
          if (packageSuccessiveDivesCount > 1) {
            // pour l'instant on ne supporte que les double block (pas les triple)
            return isExploMultipleSession === true;
          } else {
            return isExploMultipleSession === false;
          }
        }

        if (
          explorationsGroupsCriteria?.distance &&
          (purchasePackage.productPackageOffer?.productPackage?.diveAttributes
            ?.minDistance > 0 ||
            purchasePackage.productPackageOffer?.productPackage?.diveAttributes
              ?.maxDistance > 0)
        ) {
          const diveSite = diveCenterResume?.diveSites.find(
            (s) => s._id === remainingParticipant.diveSession.diveSiteId,
          );
          if (diveSite?.distance > 0) {
            // on tient compte de la distance
            if (
              (!purchasePackage.productPackageOffer?.productPackage
                ?.diveAttributes?.minDistance ||
                purchasePackage.productPackageOffer?.productPackage
                  ?.diveAttributes?.minDistance <= diveSite?.distance) &&
              (!purchasePackage.productPackageOffer?.productPackage
                ?.diveAttributes?.maxDistance ||
                purchasePackage.productPackageOffer?.productPackage
                  ?.diveAttributes?.maxDistance > diveSite?.distance)
            ) {
              return true;
            }
            return false;
          }
        }

        return true;
      }
      break;
    }
    case 'theoretical-training':
    case 'training': {
      if (
        isTraining &&
        bookingProduct.attributes.trainingReference ===
          purchaseProductPackage.trainingAttributes?.diveTrainingReference
      ) {
        // same training
        return true;
      }
      break;
    }
    case 'first-dive':
    case 'free-dive':
    case 'free-dive-auto':
    case 'free-dive-training':
    case 'observer':
    case 'snorkeling':
    case 'snorkelingSupervised':
    case 'watchingTour': {
      if (
        isOther &&
        bookingProduct.attributes.diveMode ===
          purchaseProductPackage.diveAttributes?.diveMode
      ) {
        // same
        return true;
      }
      break;
    }
    default:
      return false;
  }

  return false;
}
