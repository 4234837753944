/* eslint-disable @typescript-eslint/no-unused-vars */
import { AppInquirySection } from '@mabadive/app-common-model';
import { jsonParser, uuidGenerator } from '@mabadive/app-common-services';
import clsx from 'clsx';
import React, { useCallback, useState } from 'react';
import {
  FieldArrayWithId,
  UseFormReturn,
  useFieldArray,
} from 'react-hook-form';
import { AppButton } from 'src/business/_core/modules/layout';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { ClubSettingsEquipmentEditPageFormModelOnClickAction } from '../../../../../SE-02_account/equipment/ClubSettingsEquipmentEditPage/ClubSettingsEquipmentEditPageForm/ClubSettingsEquipmentEditPageFormModel';
import { inquerySectionBuilder } from '../../../_services';
import { ClubSettingsInquiryEditPagePanelLocalState } from '../../ClubSettingsInquiryEditPagePanel';
import { AppInquiryFormModel } from '../model';
import { ClubSettingsInquiryEditPageFormSection } from './ClubSettingsInquiryEditPageFormSection';

export const ClubSettingsInquiryEditPageForm_TabSections = ({
  localState,
  form,
  className,
}: {
  localState: ClubSettingsInquiryEditPagePanelLocalState;
  form: UseFormReturn<AppInquiryFormModel, object>;
  className?: string;
}) => {
  const {
    register,
    handleSubmit,
    watch,
    formState,
    control,
    setValue,
    reset,
    getValues,
  } = form;

  // const [sectionEnabled] = useWatch({
  //   control,
  //   name: [`${sectionsGroupName}.enabled`],
  // });

  const sectionsFieldArray = useFieldArray({
    control,
    name: 'sections',
    keyName: 'sectionId',
    shouldUnregister: false, // si true, le tableau est vidé si le composant est masqué (unmount)
  });

  const [sectionAnimations, setModelAnimations] =
    useState<{
      update: Date;
      up?: number;
      down?: number;
      remove?: number;
      clone?: number;
    }>();

  const isRecentAnimationUpdate =
    new Date().getTime() - sectionAnimations?.update?.getTime() < 1000;

  const onClick = useCallback(
    (
      field: FieldArrayWithId<AppInquiryFormModel, 'sections', 'sectionId'>,
      sectionIndex: number,
      action: ClubSettingsEquipmentEditPageFormModelOnClickAction,
    ) => {
      const from = sectionIndex;

      const update = new Date();
      switch (action) {
        case 'move-down': {
          sectionsFieldArray.move(from, from + 1);
          setModelAnimations({ update, up: from + 1, down: from });
          break;
        }
        case 'move-up': {
          sectionsFieldArray.move(from, from - 1);
          setModelAnimations({ update, up: from, down: from - 1 });
          break;
        }
        case 'delete': {
          // setModelAnimations({ update, remove: from });
          sectionsFieldArray.remove(from);
          // setTimeout(() => sectionsFieldArray.remove(from), 100);
          break;
        }
        case 'clone': {
          if (field) {
            const original: AppInquirySection = getValues(
              `sections.${sectionIndex}`,
            );
            const clone: AppInquirySection =
              jsonParser.parseJSONWithDates<AppInquirySection>(
                JSON.stringify({
                  ...original,
                  sectionId: uuidGenerator.random(),
                  questions: original.questions.map((q) => ({
                    ...q,
                    questionId: uuidGenerator.random(),
                  })),
                }),
              );

            sectionsFieldArray.insert(from + 1, clone as any);
            setModelAnimations({ update, clone: from });
            break;
          }
        }
      }
    },
    [getValues, sectionsFieldArray],
  );

  return (
    <div className={clsx('grid gap-4', className)}>
      {sectionsFieldArray.fields.map((field, sectionIndex) => {
        return (
          <ClubSettingsInquiryEditPageFormSection
            key={`${field.sectionId}-${sectionIndex}`}
            localState={localState}
            form={form}
            fieldArray={sectionsFieldArray}
            field={field}
            sectionIndex={sectionIndex}
            className={clsx(
              isRecentAnimationUpdate &&
                sectionAnimations?.up === sectionIndex &&
                'animate-move-up',
              isRecentAnimationUpdate &&
                sectionAnimations?.down === sectionIndex &&
                'animate-move-down',
              isRecentAnimationUpdate &&
                sectionAnimations?.clone === sectionIndex &&
                'animate-create',
              isRecentAnimationUpdate &&
                sectionAnimations?.remove === sectionIndex &&
                'animate-remove',
            )}
            onClick={(action) => onClick(field, sectionIndex, action)}
          />
        );
      })}
      <AppButton
        className="my-4"
        size="normal"
        icon={AppHeroIcons.actionAdd}
        color="primary-bg"
        onClick={() => {
          const sortIndex = sectionsFieldArray.fields.length;
          const newSection: AppInquirySection =
            inquerySectionBuilder.buildSection({
              sortIndex,
              sectionTitle: `Section ${sortIndex + 1}`,
            });

          sectionsFieldArray.append(newSection);
        }}
      >
        Ajouter une section
      </AppButton>
    </div>
  );
};
