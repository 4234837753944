/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  AssignedDiveGuide,
  AssignedInstructorStaff,
  DiveMode,
  DiveSessionGroup,
  DiveSessionGroupSession1,
  DiveSessionGroupSession2,
  DiveSessionResumeGroup,
  DiveSessionResumeParticipant,
  MultipleDiveSessionNumber,
} from '@mabadive/app-common-model';
import {
  changeDescriptorManager,
  diveModeAnalyser,
  diveSessionGroupDiveModeBuilder,
  jsonParser,
  jsonPatcher,
} from '@mabadive/app-common-services';
import { useCallback, useMemo } from 'react';
import { useClubResume, useDiveModeColor } from 'src/business/club/data/hooks';
import { staffMemberOptionsBuilder } from '../../../../services';
import { DiveSessionEditorDialogLocalState } from '../../../../useDiveSessionEditorDialogLocalState.hook';

export function useDiveSessionDialogTab3EditableGroupLocalState({
  parentLocalState,
  group,
  participants,
}: {
  parentLocalState: DiveSessionEditorDialogLocalState;
  group: DiveSessionResumeGroup;
  participants: DiveSessionResumeParticipant[];
}) {
  const {
    form,
    clubReference,
    aggregatedData: { diveSession },
    updateState,
    setUpdateState,
    isMultiSessionConfigForStaff,
    staffMembersFull,
  } = parentLocalState;

  const clubResume = useClubResume();

  const divingInstructorsStaffOptions = useMemo(
    () =>
      staffMemberOptionsBuilder.buildDivingInstructorsStaffOptions({
        staffMembersFull,
        date: diveSession.time,
      }),
    [diveSession, staffMembersFull],
  );
  const onUpdateGroupInstructor_GP = useCallback(
    ({
      group,
      instructorStaffId,
      sessionNumber,
    }: {
      group: Pick<
        DiveSessionGroup,
        '_id' | 'diveTourGroupSession1' | 'diveTourGroupSession2'
      >;
      instructorStaffId: string;
      sessionNumber: MultipleDiveSessionNumber;
    }) => {
      const instructorStaffMember = staffMembersFull
        .map((x) => x.staffMember)
        .find((x) => x._id === instructorStaffId);
      const degree = instructorStaffMember?.scubaDivingInstructor?.degree;

      const instructor: AssignedInstructorStaff = instructorStaffMember
        ? {
            staffId: instructorStaffMember._id,
            degree: {
              level: degree?.level,
              name: degree?.name,
            },
          }
        : undefined;

      const diveTourGroupSession1: DiveSessionGroupSession1 =
        sessionNumber === 1
          ? {
              instructor,
              diveGuide: group.diveTourGroupSession1?.diveGuide,
              divingInstructors: group.diveTourGroupSession1?.divingInstructors,
              isAutonomous: group.diveTourGroupSession1?.isAutonomous,
            }
          : group.diveTourGroupSession1;

      const diveTourGroupSession2: DiveSessionGroupSession2 =
        sessionNumber === 2 ||
        (isMultiSessionConfigForStaff &&
          !group.diveTourGroupSession2?.instructor?.staffId) // copy from session 1 if session 2 is empty
          ? {
              instructor,
              diveGuide: group.diveTourGroupSession2?.diveGuide,
              divingInstructors: group.diveTourGroupSession2?.divingInstructors,
              isAutonomous: group.diveTourGroupSession2?.isAutonomous,
            }
          : group.diveTourGroupSession2;
      const diveMode =
        diveSessionGroupDiveModeBuilder.buildFromParticipantsDiveModes({
          diveModes: participants.map((p) => p.diveMode),
          hasDiveGuide: !!diveTourGroupSession1?.diveGuide,
          hasInstructor: !!diveTourGroupSession1?.instructor,
          debugContext: '3 removeParticipants',
        });
      // console.log('xxx onUpdateGroupInstructor_GP diveMode:', diveMode);

      const patchOperations = jsonPatcher.compareObjects<DiveSessionGroup>(
        group,
        {
          ...group,
          diveTourGroupSession1,
          diveTourGroupSession2,
          diveMode,
        },
        { replaceDeleteByNullValue: true },
      );
      if (patchOperations.length) {
        const localUpdateState = {
          ...updateState,
        };
        localUpdateState.diveSessionGroupsChanges =
          changeDescriptorManager.updateOne(
            {
              pk: group._id,
              patchOperations,
            },
            {
              changeDescriptors: localUpdateState.diveSessionGroupsChanges,
            },
          );
        setUpdateState(localUpdateState);
      }
    },
    [
      isMultiSessionConfigForStaff,
      participants,
      setUpdateState,
      staffMembersFull,
      updateState,
    ],
  );
  const onUpdateGroupInstructor_ExtraDiver = useCallback(
    ({
      group,
      instructorStaffIdToRemove,
      instructorStaffIdToAdd,
      sessionNumber,
    }: {
      group: Pick<
        DiveSessionGroup,
        '_id' | 'diveTourGroupSession1' | 'diveTourGroupSession2'
      >;
      instructorStaffIdToRemove: string;
      instructorStaffIdToAdd: string;
      sessionNumber: MultipleDiveSessionNumber;
    }) => {
      const instructorStaffMemberToAdd = staffMembersFull
        .map((x) => x.staffMember)
        .find((x) => x._id === instructorStaffIdToAdd);
      const degree = instructorStaffMemberToAdd?.scubaDivingInstructor?.degree;

      const instructorToAdd: AssignedInstructorStaff =
        instructorStaffMemberToAdd
          ? {
              staffId: instructorStaffMemberToAdd._id,
              degree: {
                level: degree?.level,
                name: degree?.name,
              },
            }
          : undefined;

      const diveTourGroupSession1: DiveSessionGroupSession1 =
        jsonParser.parseJSONWithDates(
          JSON.stringify(group.diveTourGroupSession1),
        );

      const diveTourGroupSession2: DiveSessionGroupSession2 =
        jsonParser.parseJSONWithDates(
          JSON.stringify(group.diveTourGroupSession2),
        );

      let divingInstructors: AssignedInstructorStaff[] = [
        ...(sessionNumber === 2
          ? diveTourGroupSession2?.divingInstructors ?? []
          : diveTourGroupSession1?.divingInstructors ?? []),
      ];

      if (
        instructorStaffIdToAdd &&
        divingInstructors.find((x) => x.staffId === instructorStaffIdToAdd) ===
          undefined
      ) {
        // add instructor

        const instructorStaffIdToRemoveIndex = divingInstructors.findIndex(
          (x) => x.staffId === instructorStaffIdToRemove,
        );

        if (instructorStaffIdToRemoveIndex !== -1) {
          // replace
          divingInstructors.splice(
            instructorStaffIdToRemoveIndex,
            1,
            instructorToAdd,
          );
        } else {
          //only add
          divingInstructors.push(instructorToAdd);
        }
      }

      if (instructorStaffIdToRemove) {
        // remove instructor (in case of dups)
        divingInstructors = (divingInstructors ?? []).filter(
          (x) => x.staffId !== instructorStaffIdToRemove,
        );
      }
      if (sessionNumber === 1) {
        diveTourGroupSession1.divingInstructors = divingInstructors;
      } else {
        diveTourGroupSession2.divingInstructors = divingInstructors;
      }
      const diveMode =
        diveSessionGroupDiveModeBuilder.buildFromParticipantsDiveModes({
          diveModes: participants.map((p) => p.diveMode),
          hasDiveGuide: !!diveTourGroupSession1?.diveGuide,
          hasInstructor: !!diveTourGroupSession1?.instructor,
          debugContext: '3 removeParticipants',
        });
      // console.log('xxx onUpdateGroupInstructor_ExtraDiver diveMode:', diveMode);
      const patchOperations = jsonPatcher.compareObjects<DiveSessionGroup>(
        group,
        {
          ...group,
          diveTourGroupSession1,
          diveTourGroupSession2,
          diveMode,
        },
        {
          replaceDeleteByNullValue: true,
          attributesToReplaceFully: [
            'diveTourGroupSession1',
            'diveTourGroupSession2',
          ],
        },
      );
      // console.log('xxx patchOperations', patchOperations);
      if (patchOperations.length) {
        const localUpdateState = {
          ...updateState,
        };
        localUpdateState.diveSessionGroupsChanges =
          changeDescriptorManager.updateOne(
            {
              pk: group._id,
              patchOperations,
            },
            {
              changeDescriptors: localUpdateState.diveSessionGroupsChanges,
            },
          );
        setUpdateState(localUpdateState);
      }
    },
    [participants, setUpdateState, staffMembersFull, updateState],
  );

  const onUpdateGroupIsAutonomous = useCallback(
    ({
      group,
      isAutonomous,
      sessionNumber,
    }: {
      group: Pick<
        DiveSessionGroup,
        '_id' | 'diveTourGroupSession1' | 'diveTourGroupSession2' | 'diveMode'
      >;
      isAutonomous: boolean;
      sessionNumber: MultipleDiveSessionNumber;
    }) => {
      const diveTourGroupSession1: DiveSessionGroupSession1 =
        sessionNumber === 1
          ? {
              isAutonomous,
              diveGuide: undefined,
              instructor: undefined,
            }
          : group.diveTourGroupSession1;

      const diveTourGroupSession2: DiveSessionGroupSession2 =
        sessionNumber === 2
          ? {
              isAutonomous,
              diveGuide: undefined,
              instructor: undefined,
            }
          : group.diveTourGroupSession2;

      const diveMode: DiveMode = isAutonomous
        ? 'autonomous'
        : diveSessionGroupDiveModeBuilder.buildFromParticipantsDiveModes({
            diveModes: participants.map((p) => p.diveMode),
            hasDiveGuide: !!diveTourGroupSession1?.diveGuide,
            hasInstructor: !!diveTourGroupSession1?.instructor,
            debugContext: '3 removeParticipants',
          });
      // console.log('xxx onUpdateGroupIsAutonomous diveMode:', diveMode);
      const patchOperations = jsonPatcher.compareObjects<DiveSessionGroup>(
        group,
        {
          ...group,
          diveTourGroupSession1,
          diveTourGroupSession2,
          diveMode,
        },
        { replaceDeleteByNullValue: true },
      );
      if (patchOperations.length) {
        const localUpdateState = {
          ...updateState,
        };
        localUpdateState.diveSessionGroupsChanges =
          changeDescriptorManager.updateOne(
            {
              pk: group._id,
              patchOperations,
            },
            {
              changeDescriptors: localUpdateState.diveSessionGroupsChanges,
            },
          );
        setUpdateState(localUpdateState);
      }
    },
    [participants, setUpdateState, updateState],
  );

  const hasAnyAutonomous = useMemo(
    () =>
      participants.find((p) => diveModeAnalyser.isAutonomous(p)) !== undefined,
    [participants],
  );
  const groupGuides = useMemo(
    () => participants.filter((p) => diveModeAnalyser.isAvailableGuide(p)),
    [participants],
  );
  const groupGuidesOptions = useMemo(
    () => staffMemberOptionsBuilder.buildGroupGuidesOptions(groupGuides),
    [groupGuides],
  );
  const onUpdateGroupGuide = useCallback(
    ({
      group,
      guideDiverId,
      sessionNumber,
    }: {
      group: Pick<
        DiveSessionGroup,
        '_id' | 'diveTourGroupSession1' | 'diveTourGroupSession2' | 'diveMode'
      >;
      guideDiverId: string;
      sessionNumber: MultipleDiveSessionNumber;
    }) => {
      const localUpdateState = {
        ...updateState,
      };
      const guideDiver = groupGuides.find((x) => x._id === guideDiverId);

      const mainCertificationReference =
        (guideDiver?.diveMode === 'autoSupervised'
          ? guideDiver?.autoSupervisedDetails.certificationReference
          : guideDiver?.certificationReference) ??
        guideDiver?.diver?.divingCertification1?.mainCertificationReference;

      const diveGuide: AssignedDiveGuide = guideDiver
        ? {
            participantId: guideDiver?._id,
            mainCertificationReference,
          }
        : undefined;

      const diveTourGroupSession1: DiveSessionGroupSession1 =
        sessionNumber === 1
          ? {
              instructor: group.diveTourGroupSession1?.instructor,
              diveGuide,
            }
          : group.diveTourGroupSession1;
      const diveTourGroupSession2: DiveSessionGroupSession2 =
        sessionNumber === 2 ||
        (isMultiSessionConfigForStaff &&
          !group.diveTourGroupSession2?.diveGuide?.participantId) // copy from session 1 if session 2 is empty
          ? {
              instructor: group.diveTourGroupSession2?.instructor,
              diveGuide,
            }
          : group.diveTourGroupSession2;

      {
        const diveMode =
          diveSessionGroupDiveModeBuilder.buildFromParticipantsDiveModes({
            diveModes: participants.map((p) => p.diveMode),
            hasDiveGuide: !!diveTourGroupSession1?.diveGuide,
            hasInstructor: !!diveTourGroupSession1?.instructor,
            debugContext: '3 removeParticipants',
          });

        // console.log('xxx onUpdateGroupGuide diveMode:', diveMode);

        const patchOperations = jsonPatcher.compareObjects<DiveSessionGroup>(
          group,
          {
            ...group,
            diveTourGroupSession1,
            diveTourGroupSession2,
            diveMode,
          },
          { replaceDeleteByNullValue: true },
        );

        if (patchOperations.length) {
          localUpdateState.diveSessionGroupsChanges =
            changeDescriptorManager.updateOne(
              {
                pk: group._id,
                patchOperations,
              },
              {
                changeDescriptors: localUpdateState.diveSessionGroupsChanges,
              },
            );
        }
      }

      if (diveGuide) {
        const participant = participants.find(
          (x) => x._id === diveGuide.participantId,
        );
        if (participant) {
          const patchOperations = jsonPatcher.compareObjects(
            participant,
            { ...participant, diveSessionGroupDiveGuide: true },
            { replaceDeleteByNullValue: true },
          );
          if (patchOperations.length) {
            localUpdateState.clubParticipantsChanges =
              changeDescriptorManager.updateOne(
                {
                  pk: participant._id,
                  patchOperations,
                },
                {
                  changeDescriptors: localUpdateState.clubParticipantsChanges,
                },
              );
          }
        }
      }
      const previousDiveGuide = group.diveTourGroupSession1?.diveGuide;
      if (previousDiveGuide) {
        const participant = participants.find(
          (x) => x._id === previousDiveGuide.participantId,
        );
        if (participant) {
          const patchOperations = jsonPatcher.compareObjects(
            participant,
            { ...participant, diveSessionGroupDiveGuide: null },
            { replaceDeleteByNullValue: true },
          );
          if (patchOperations.length) {
            localUpdateState.clubParticipantsChanges =
              changeDescriptorManager.updateOne(
                {
                  pk: participant._id,
                  patchOperations,
                },
                {
                  changeDescriptors: localUpdateState.clubParticipantsChanges,
                },
              );
          }
        }
      }
      setUpdateState(localUpdateState);
    },
    [
      groupGuides,
      isMultiSessionConfigForStaff,
      participants,
      setUpdateState,
      updateState,
    ],
  );

  const displayAutonomousField =
    clubResume.clubSettings.general.sessions?.displayAutonomousField;

  const diveModeColor = useDiveModeColor(group?.diveMode);

  return {
    data: {
      divingInstructorsStaffOptions,
      hasAnyAutonomous,
      groupGuides,
      groupGuidesOptions,
      displayAutonomousField,
      diveModeColor,
    },
    actions: {
      onUpdateGroupInstructor_GP,
      onUpdateGroupInstructor_ExtraDiver,
      onUpdateGroupIsAutonomous,
      onUpdateGroupGuide,
    },
  };
}

export type DiveSessionDialogTab3EditableGroupLocalState = ReturnType<
  typeof useDiveSessionDialogTab3EditableGroupLocalState
>;
