import {
  AppEntityUpdatePatch,
  ClubDiver,
  ClubPurchasePayment,
} from '@mabadive/app-common-model';
import { jsonPatcher } from '@mabadive/app-common-services';
import React, { useCallback, useMemo } from 'react';
import { confirmDialog } from 'src/business/_core/modules/layout/components/ConfirmDialog/confirmDialog.service';
import {
  AppHeroIcons,
  EntitiesIcons,
} from 'src/business/_core/modules/layout/icons';
import { appLogger } from 'src/business/_core/modules/root/logger';
import {
  useAppSecurityUser,
  useAppSecurityUserClubAuthorizations,
} from 'src/business/auth/services';
import { AppHamburgerMenuItem } from 'src/business/club/modules/_common/ui';
import { ClubDialogsStateOld } from 'src/pages/_dialogs';
import { diverPurchasePackageBuilder } from '../../components/DiverPurchaseCommonEditorDialog';
import { AggregatedBookingPurchasePaymentWithDetails } from '../../models';
import { DiverBookingPageActions } from '../../useDiverBookingPageActions.hook';
import { DiverBookingPageGlobalState } from '../../useDiverBookingPageGlobalState.hook';

export function useDiverBookingPagePaymentTabMenuItems({
  diver,
  purchasePaymentWithDetails,
  globalState,
  dialogs,
  actions,
  isExpanded,
  setIsExpanded,
}: {
  diver: ClubDiver;
  purchasePaymentWithDetails: AggregatedBookingPurchasePaymentWithDetails;
  globalState: DiverBookingPageGlobalState;
  dialogs: ClubDialogsStateOld;
  actions: DiverBookingPageActions;
  isExpanded: boolean;
  setIsExpanded: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const user = useAppSecurityUser();
  const az = useAppSecurityUserClubAuthorizations();

  const purchasePayment: ClubPurchasePayment =
    purchasePaymentWithDetails.purchasePayment;
  const { aggregatedData } = globalState;

  const { deletePurchasePayment, deleteBookingDeposit } = actions;

  const { setBookingParticipantEditorState, setPaymentEditorDialogState } =
    dialogs;

  const deletePayment = useCallback(() => {
    const updatedClubPurchasePackages: AppEntityUpdatePatch[] =
      purchasePayment.purchasePaymentPackages
        .map((ppp) => {
          if (ppp.amount !== 0) {
            const original = aggregatedData.purchasePackages.find(
              (x) => x._id === ppp.purchasePackage._id,
            );

            if (!original) {
              appLogger.warn(
                'Original purchasePackage not found while deleting payment',
              );
              return undefined;
            }

            const updated = {
              ...original,
            };

            // update payed amount
            updated.payedAmount = original.payedAmount - ppp.amount;
            if (updated.payedAmount < 0) {
              updated.payedAmount = 0;
            }

            // update payed status
            updated.purchasePaymentStatus =
              diverPurchasePackageBuilder.buildPaymentStatus({
                price: updated.price,
                payedAmount: updated.payedAmount,
              });

            const patchOperations = jsonPatcher.compareObjects(
              original,
              updated,
              {},
            );
            if (patchOperations.length) {
              const patch: AppEntityUpdatePatch = {
                pk: original._id,
                patchOperations,
              };
              return patch;
            }
          }
        })
        .filter((x) => !!x);

    deletePurchasePayment({
      deletedPurchasePaymentId: purchasePayment._id,
      updatedClubPurchasePackages,
      deletedBookingDepositIds:
        purchasePaymentWithDetails.bookingDepositsPaymentDetails.map(
          (x) => x.bookingDeposit._id,
        ),
      deletedPaymentBookingDepositIds:
        purchasePaymentWithDetails.bookingDepositsPaymentDetails.map(
          (x) => x.paymentDetails?._id,
        ),
    });
  }, [
    aggregatedData.purchasePackages,
    deletePurchasePayment,
    purchasePayment._id,
    purchasePayment.purchasePaymentPackages,
    purchasePaymentWithDetails.bookingDepositsPaymentDetails,
  ]);

  const confirmDeletePayment = useCallback(async () => {
    if (purchasePaymentWithDetails.bookingDepositsPaymentDetails.length > 0) {
      if (
        await confirmDialog.confirmPromise({
          title: 'Supprimer un paiement et un acompte',
          message:
            'Êtes-vous sûr de vouloir supprimer ce paiement ainsi que l\'acompte associé?',
          type: 'remove',
        })
      ) {
        deletePayment();
      }
    } else {
      if (
        await confirmDialog.confirmPromise({
          title: 'Supprimer un paiement',
          message: 'Êtes-vous sûr de vouloir supprimer ce paiement?',
          type: 'remove',
        })
      ) {
        deletePayment();
      }
    }
  }, [deletePayment, purchasePaymentWithDetails.bookingDepositsPaymentDetails]);

  const hamburgerMenuItems: AppHamburgerMenuItem[] = useMemo(() => {
    const hamburgerMenuItems: AppHamburgerMenuItem[] = [];

    if (az?.edit.participant.paymentEdit) {
      hamburgerMenuItems.push({
        id: 'edit-payment',
        titleShort: 'Modifier',
        title: 'Modifier le paiement',
        description: 'Modifier les détails du paiement',
        icon: AppHeroIcons.actionEdit,
        onClick: () => {
          setPaymentEditorDialogState({
            isOpen: true,
            mode: 'edit',
            aggregatedData,
            purchasePayment,
            // dans un autre contexte, on met à jour 'bookingDepositsWithPayments' avant d'ouvrir le dialog, donc on ne le prend pas toujours dans aggregatedData
            bookingDepositsWithPayments:
              aggregatedData.bookingDepositsWithPayments,
          });
        },
      });
    }

    if (isExpanded) {
      hamburgerMenuItems.push({
        title: 'Masquer le détail',
        description: 'Masquer le détail de ce paiement',
        icon: AppHeroIcons.eyeOff,
        onClick: () => {
          setIsExpanded(!isExpanded);
        },
      });
    } else {
      hamburgerMenuItems.push({
        title: 'Afficher le détail',
        description: 'Afficher le détail de ce paiement',
        icon: AppHeroIcons.eye,
        onClick: () => {
          setIsExpanded(!isExpanded);
        },
      });
    }
    if (az?.edit.participant.paymentDelete) {
      hamburgerMenuItems.push({
        id: 'delete-payment',
        titleShort: 'Supprimer',
        title: 'Supprimer le paiement',
        description: 'Supprimer définitivement le paiement',
        icon: AppHeroIcons.actionDelete,
        severity: 'danger',
        disableMessage:
          purchasePaymentWithDetails.creditNotes.length > 0
            ? 'Supprimez les avoirs pour pouvoir supprimer le paiement'
            : undefined,
        onClick: () => {
          confirmDeletePayment();
        },
      });
    }
    if (az?.creditNote?.action?.create) {
      hamburgerMenuItems.push({
        id: 'create-credit-note',
        titleShort: 'Avoir',
        title: 'Créer un avoir',
        description: 'Créer un avoir pour ce paiement',
        icon: EntitiesIcons.creditNote,
        severity: 'info',
        onClick: () => {
          // TODO
          globalState.dialogsState.bookingCreditNoteEditDialog.openDialog({
            mode: 'create',
            defaultValue: {
              creditNote: {},
            },
            purchasePaymentWithDetails,
            aggregatedData: globalState.aggregatedData,
          });
        },
      });
    }
    return hamburgerMenuItems;
  }, [
    aggregatedData,
    az?.creditNote?.action?.create,
    az?.edit.participant.paymentDelete,
    az?.edit.participant.paymentEdit,
    confirmDeletePayment,
    globalState.aggregatedData,
    globalState.dialogsState.bookingCreditNoteEditDialog,
    isExpanded,
    purchasePayment,
    purchasePaymentWithDetails,
    setIsExpanded,
    setPaymentEditorDialogState,
  ]);

  return hamburgerMenuItems;
}
