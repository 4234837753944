/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useMemo, useState } from 'react';

import {
  AppTabsTailwind,
  AppTabTailwind,
} from '../../../../../../business/_core/modules/layout';
import { StaffMemberEditDialogLocalState } from '../useStaffMemberEditDialogLocalState.hook';
import { StaffMemberEditForm_Tab1_Profil } from './StaffMemberEditForm_Tab1_Profil';
import { StaffMemberEditForm_Tab2_Availabilities } from './StaffMemberEditForm_Tab2_Availabilities';
import { StaffMemberEditForm_Tab3_StaffDetails } from './StaffMemberEditForm_Tab3_StaffDetails';
import { StaffMemberEditForm_Tab4_Documents } from './StaffMemberEditForm_Tab4_Documents';

export type StaffMemberEdit_TabId =
  | 'profil'
  | 'availabilities'
  | 'staff'
  | 'documents';
export const StaffMemberEditForm = ({
  localState,
  className,
}: {
  localState: StaffMemberEditDialogLocalState;
  className?: string;
}) => {
  const [currentTabId, setCurrentTabId] =
    useState<StaffMemberEdit_TabId>('profil');

  const tabs = useMemo(() => {
    const tabs: AppTabTailwind<StaffMemberEdit_TabId>[] = [
      {
        id: 'profil',
        label: 'Profil',
      },
      {
        id: 'availabilities',
        label: 'Disponibilités',
      },
      {
        id: 'staff',
        label: 'Détails',
      },
    ];
    if (localState.state.mode === 'edit') {
      tabs.push({
        id: 'documents',
        label: 'Documents',
      });
    }
    return tabs;
  }, [localState.state.mode]);

  return (
    <div className={className}>
      {/* {appDocEditDialog.isOpen && (
          <AppPageContainer
            id="dialog-scrollable-container"
            className="bg-white relative overflow-y-auto"
          >
            <AppDocEditDialog {...appDocEditDialog} />
          </AppPageContainer>
        )} */}

      <AppTabsTailwind
        className="w-full mb-8"
        tabs={tabs}
        selected={currentTabId}
        theme="pills"
        breakpoint="sm"
        onChange={(tabId) => setCurrentTabId(tabId)}
      />
      {currentTabId === 'profil' && (
        <StaffMemberEditForm_Tab1_Profil localState={localState} />
      )}
      {currentTabId === 'availabilities' && (
        <StaffMemberEditForm_Tab2_Availabilities localState={localState} />
      )}
      {currentTabId === 'staff' && (
        <StaffMemberEditForm_Tab3_StaffDetails localState={localState} />
      )}
      {currentTabId === 'documents' && (
        <StaffMemberEditForm_Tab4_Documents localState={localState} />
      )}
    </div>
  );
};
