import { ClubParticipantNavigationOrigin } from '../../../../ClubParticipantNavigationContext';
import { DiverBookingPageTabId } from '../../components/DiverBookingPageTab';
import { DiverBookingPageConfig } from '../../models';
import { DiverBookingPageBookingTab_SubTabId } from '../../tabs/DiverBookingPageBookingTab/DiverBookingPageBookingTab_SubTab';

export const diverBookingPageUrlManager = {
  getQueryParameters,
  buildSearchParams,
};

export const DIVER_BOOKING_PAGE_QUERY_PARAMS = {
  tab: 't',
  origin: 'origin',
  diveSessionReference: 'diveSessionReference',
  action: 'action',
  tabBooking_tab: 'tb_t',
  tabBooking_showParticipantDetails: 'tb_spd',
};

function getQueryParameters(): Pick<
  DiverBookingPageConfig,
  | 'tab'
  | 'origin'
  | 'diveSessionReference'
  | 'action'
  | 'tabBooking_tab'
  | 'tabBooking_showParticipantDetails'
> {
  const params = new URLSearchParams(window.location.search);

  const tab = params.get(
    DIVER_BOOKING_PAGE_QUERY_PARAMS.tab,
  ) as DiverBookingPageTabId;

  const tabBooking_tab = params.get(
    DIVER_BOOKING_PAGE_QUERY_PARAMS.tabBooking_tab,
  ) as DiverBookingPageBookingTab_SubTabId;
  const tabBooking_showParticipantDetails = getBoolean(
    params.get(
      DIVER_BOOKING_PAGE_QUERY_PARAMS.tabBooking_showParticipantDetails,
    ),
  );

  const origin = params.get(
    DIVER_BOOKING_PAGE_QUERY_PARAMS.origin,
  ) as ClubParticipantNavigationOrigin;

  const diveSessionReference = params.get(
    DIVER_BOOKING_PAGE_QUERY_PARAMS.diveSessionReference,
  ) as string;

  const action = params.get(DIVER_BOOKING_PAGE_QUERY_PARAMS.action) as string;

  const config: Pick<
    DiverBookingPageConfig,
    | 'tab'
    | 'origin'
    | 'diveSessionReference'
    | 'action'
    | 'tabBooking_tab'
    | 'tabBooking_showParticipantDetails'
  > = {
    tab,
    tabBooking_tab,
    tabBooking_showParticipantDetails,
    origin,
    diveSessionReference,
    action,
  };
  return config;
}

function buildSearchParams(
  viewConfig: Partial<DiverBookingPageConfig>,
): string[] {
  if (!viewConfig) {
    return [];
  }
  const { tab, origin, diveSessionReference, action, tabBooking_tab } =
    viewConfig;

  const searchParams: string[] = [];

  if (tab) {
    searchParams.push(`${DIVER_BOOKING_PAGE_QUERY_PARAMS.tab}=${tab}`);
  }
  if (origin) {
    searchParams.push(`${DIVER_BOOKING_PAGE_QUERY_PARAMS.origin}=${origin}`);
  }
  if (diveSessionReference) {
    searchParams.push(
      `${DIVER_BOOKING_PAGE_QUERY_PARAMS.diveSessionReference}=${diveSessionReference}`,
    );
  }
  if (tabBooking_tab) {
    searchParams.push(
      `${DIVER_BOOKING_PAGE_QUERY_PARAMS.tabBooking_tab}=${tabBooking_tab}`,
    );
  }

  if (viewConfig.tabBooking_showParticipantDetails) {
    searchParams.push(
      `${DIVER_BOOKING_PAGE_QUERY_PARAMS.tabBooking_showParticipantDetails}=${
        viewConfig.tabBooking_showParticipantDetails ? 1 : 0
      }`,
    );
  }
  if (action) {
    searchParams.push(`${DIVER_BOOKING_PAGE_QUERY_PARAMS.action}=${action}`);
  }

  return searchParams;
}

function getBoolean(value: string): boolean {
  if (value === '1') {
    return true;
  }
  if (value === '0') {
    return false;
  }
  return undefined;
}
