import {
  BookingMember,
  ClubDiver,
  PARTICIPANT_COMMENT_TAG,
} from '@mabadive/app-common-model';
import {
  appLanguageFormater,
  dateService,
  nameFormatter,
  postalAddressFormatter,
  safetyCertificationFormatter,
} from '@mabadive/app-common-services';
import React from 'react';
import { useClubResume } from 'src/business/club/data/hooks';
import { CommentLabel } from 'src/business/club/modules/_common/form/components';
import { ResidentTypeLabelBadge } from 'src/business/club/modules/club-diver-participant/pages/DiverBookingPage/components/ResidentTypeLabelBadge';
import { BookingMemberCardAttributeName } from './BookingMemberCardAttributeName.type';
import { ClubDiverCardAttributeCertification } from './ClubDiverCardAttributeCertification';
import { ClubDiverCardAttributeInsurance } from './ClubDiverCardAttributeInsurance';
import { ClubDiverCardAttributeMedicalCertificateStatus } from './ClubDiverCardAttributeMedicalCertificateStatus';
import { ClubDiverCardAttributeMedicalFormStatus } from './ClubDiverCardAttributeMedicalFormStatus';
import { ClubDiverCardAttributeName } from './ClubDiverCardAttributeName.type';

export type ClubDiverCardDiver = Pick<
  ClubDiver,
  | 'mainCertificationReference'
  // | 'mainNitroxCertificationReference'
  | 'emailAddress'
  | 'homeAddress'
  | 'phoneNumber'
  | 'phoneNumber2'
  | 'size'
  | 'weight'
  | 'medicalCertificateDate'
  | 'medicalCertificateChecked'
  | 'emergencyContact'
  | 'comment'
  | 'birthdate'
  | 'firstName'
  | 'lastName'
  | 'orgsMembers'
  | 'bookingDeposits'
  | 'divingCertification1'
  | 'divingCertification2'
  | 'divingComment'
  | 'sanitaryPass'
  | 'residentType'
  | 'details'
  | 'defaultDiveConfig'
  | 'age'
  | 'languageCode'
>;

export const ClubDiverCardAttribute = ({
  diver,
  bookingMember,
  attributeName,
  className = '',
}: {
  diver: ClubDiverCardDiver;
  bookingMember?: BookingMember; // optional
  attributeName: ClubDiverCardAttributeName | BookingMemberCardAttributeName;
  className?: string;
}) => {
  const clubResume = useClubResume();
  const clubSettings = clubResume?.clubSettings;
  const customerSettings = clubResume?.clubSettings?.customer;

  return renderAttribute(attributeName);

  function renderAttribute(
    attributeName: ClubDiverCardAttributeName | BookingMemberCardAttributeName,
  ) {
    if (!diver) {
      return null;
    }
    switch (attributeName) {
      case 'fullName':
        return (
          <div className={`${className} max-w-full`}>
            <h3 className="data-label text-gray-600 font-bold max-w-full">
              Nom
            </h3>
            <div className="data-value text-app-blue max-w-full truncate">
              {nameFormatter.formatFullName(diver)}
            </div>
          </div>
        );
      case 'residentType':
        return (
          <div className={`${className} max-w-full`}>
            <h3 className="data-label text-gray-600 font-bold max-w-full">
              Résidence
            </h3>
            {/* <div className="data-value text-app-blue max-w-full truncate"> */}
            <ResidentTypeLabelBadge
              residentType={diver.residentType}
              className="text-xs"
            />
            {/* </div> */}
          </div>
        );
      case 'language':
        return (
          <div className={`${className} max-w-full`}>
            <h3 className="data-label text-gray-600 font-bold max-w-full">
              Langue
            </h3>
            <div className="data-value text-app-blue max-w-full truncate">
              {appLanguageFormater.formatLanguageLabel(diver.languageCode, {
                prependFlag: true,
              })}
            </div>
          </div>
        );
      case 'orgsMembers':
        return diver.orgsMembers?.length === 0 ? null : (
          <>
            {(diver.orgsMembers ?? []).map((orgMember, i) => (
              <div key={i} className={`${className} max-w-full`}>
                <h3 className="data-label text-gray-600 font-bold max-w-full">
                  N° {orgMember?.orgReference.toUpperCase()}
                </h3>
                <div className="data-value text-app-blue max-w-full truncate">
                  {orgMember?.orgDiverId}
                </div>
              </div>
            ))}
          </>
        );

      case 'mainCertificationReference':
        return (
          <div className={`${className} max-w-full`}>
            <h3 className="data-label text-gray-600 font-bold max-w-full">
              Niveau
            </h3>
            <div className="data-value text-app-blue max-w-full truncate">
              <ClubDiverCardAttributeCertification diver={diver} />
            </div>
          </div>
        );
      case 'freeDiveCertificationReference':
        return (
          <div className={`${className} max-w-full`}>
            <h3 className="data-label text-gray-600 font-bold max-w-full">
              Niveau apnée
            </h3>
            <div className="data-value text-app-blue max-w-full truncate">
              {
                diver?.details?.freeDiveCertification1
                  ?.freeDiveCertificationReference
              }
            </div>
          </div>
        );
      case 'safetyCertificationReference':
        return (
          <div className={`${className} max-w-full`}>
            <h3 className="data-label text-gray-600 font-bold max-w-full">
              Secourisme
            </h3>
            <div className="data-value text-app-blue max-w-full truncate">
              {safetyCertificationFormatter.formatDiverSafetyCertifications(
                diver,
                {
                  format: 'label',
                },
              )}
            </div>
          </div>
        );

      case 'age':
        return (
          <div className={`${className} max-w-full`}>
            <h3 className="data-label text-gray-600 font-bold max-w-full">
              Age
            </h3>
            <div className="data-value text-app-blue max-w-full truncate">
              {dateService.getAge(diver.birthdate) ?? diver.age} ans
            </div>
          </div>
        );
      case 'birthdate':
        return (
          <div className={`${className} max-w-full`}>
            <h3 className="data-label text-gray-600 font-bold max-w-full">
              Date de naissance
            </h3>
            <div className="data-value text-app-blue max-w-full truncate">
              {dateService.formatUTC(diver.birthdate, 'DD/MM/YYYY')}
            </div>
          </div>
        );
      case 'emailAddress':
        return (
          <div className={`${className} max-w-full`}>
            <h3 className="data-label text-gray-600 font-bold max-w-full">
              Adresse e-mail
            </h3>
            <div className="data-value text-app-blue max-w-full truncate">
              {diver.emailAddress}
            </div>
          </div>
        );
      case 'externalRef':
        return (
          <div className={`${className} max-w-full`}>
            <h3 className="data-label text-gray-600 font-bold max-w-full">
              {customerSettings?.general?.externalRefLabel ?? 'ID perso'}
            </h3>
            <div className="data-value text-app-blue max-w-full truncate">
              #{diver.details?.externalRef}
            </div>
          </div>
        );
      case 'customField1':
        return (
          <div className={`${className} max-w-full`}>
            <h3 className="data-label text-gray-600 font-bold max-w-full">
              {customerSettings?.general?.customField1Label}
            </h3>
            <div className="data-value text-app-blue max-w-full truncate">
              {diver.details?.customField1}
            </div>
          </div>
        );
      case 'customField2':
        return (
          <div className={`${className} max-w-full`}>
            <h3 className="data-label text-gray-600 font-bold max-w-full">
              {customerSettings?.general?.customField2Label}
            </h3>
            <div className="data-value text-app-blue max-w-full truncate">
              {diver.details?.customField2}
            </div>
          </div>
        );
      case 'customField3':
        return (
          <div className={`${className} max-w-full`}>
            <h3 className="data-label text-gray-600 font-bold max-w-full">
              {customerSettings?.general?.customField3Label}
            </h3>
            <div className="data-value text-app-blue max-w-full truncate">
              {diver.details?.customField3}
            </div>
          </div>
        );
      case 'customBoolean1':
        return (
          <div className={`${className} max-w-full`}>
            <h3 className="data-label text-gray-600 font-bold max-w-full">
              {customerSettings?.general?.customBoolean1Label}
            </h3>
            <div className="data-value text-app-blue max-w-full truncate">
              {diver.details?.customBoolean1 ? 'oui' : 'non'}
            </div>
          </div>
        );
      case 'customBoolean2':
        return (
          <div className={`${className} max-w-full`}>
            <h3 className="data-label text-gray-600 font-bold max-w-full">
              {customerSettings?.general?.customBoolean2Label}
            </h3>
            <div className="data-value text-app-blue max-w-full truncate">
              {diver.details?.customBoolean2 ? 'oui' : 'non'}
            </div>
          </div>
        );
      case 'customBoolean3':
        return (
          <div className={`${className} max-w-full`}>
            <h3 className="data-label text-gray-600 font-bold max-w-full">
              {customerSettings?.general?.customBoolean3Label}
            </h3>
            <div className="data-value text-app-blue max-w-full truncate">
              {diver.details?.customBoolean3 ? 'oui' : 'non'}
            </div>
          </div>
        );
      case 'identificationNumber':
        return (
          <div className={`${className} max-w-full`}>
            <h3 className="data-label text-gray-600 font-bold max-w-full">
              N° CNI ou Passeport
            </h3>
            <div className="data-value text-app-blue max-w-full truncate">
              {diver.details?.identificationNumber}
            </div>
          </div>
        );
      case 'isCustomer':
        return (
          <div className={`${className} max-w-full`}>
            <h3 className="data-label text-gray-600 font-bold max-w-full">
              Client non-plongeur
            </h3>
            <div className="data-value text-app-blue max-w-full truncate">
              <div className="text-xs text-status-success">OUI</div>
            </div>
          </div>
        );
      case 'isMember':
        return (
          <div className={`${className} max-w-full`}>
            <h3 className="data-label text-gray-600 font-bold max-w-full">
              Membre du club
            </h3>
            <div className="data-value text-app-blue max-w-full truncate">
              {diver.details?.membership?.memberRef ?? (
                <div className="text-xs text-status-success">OUI</div>
              )}
            </div>
          </div>
        );
      case 'memberStudent':
        return (
          <div className={`${className} max-w-full`}>
            <h3 className="data-label text-gray-600 font-bold max-w-full">
              En formation club
            </h3>
            <div className="data-value text-app-blue max-w-full truncate">
              {diver.details?.membership?.studentDescription ?? (
                <div className="text-xs text-status-success">OUI</div>
              )}
            </div>
          </div>
        );
      case 'membershipDate':
        return (
          <div className={`${className} max-w-full`}>
            <h3 className="data-label text-gray-600 font-bold max-w-full">
              Date d'adhésion
            </h3>
            <div className="data-value text-app-blue max-w-full truncate">
              {dateService.formatUTC(
                diver.details?.membership?.membershipDate,
                'DD/MM/YYYY',
              )}
            </div>
          </div>
        );

      case 'phoneNumber':
        return (
          <div className={`${className} max-w-full`}>
            <h3 className="data-label text-gray-600 font-bold max-w-full">
              Téléphone
            </h3>
            <div className="data-value text-app-blue max-w-full truncate">
              {diver.phoneNumber} {diver.phoneNumber2}
            </div>
          </div>
        );
      case 'medicalCertificateStatus':
        return (
          <div className={`${className} max-w-full`}>
            <h3 className="data-label text-gray-600 font-bold max-w-full">
              Certificat médical
            </h3>
            <div className="data-value text-app-blue max-w-full truncate">
              <ClubDiverCardAttributeMedicalCertificateStatus diver={diver} />
            </div>
          </div>
        );
      case 'medicalFormStatus':
        return (
          <div className={`${className} max-w-full`}>
            <h3 className="data-label text-gray-600 font-bold max-w-full">
              Formulaire médical
            </h3>
            <div className="data-value text-app-blue max-w-full truncate">
              <ClubDiverCardAttributeMedicalFormStatus
                bookingMember={bookingMember}
              />
            </div>
          </div>
        );
      case 'insurance':
        return (
          <div className={`${className} max-w-full`}>
            <h3 className="data-label text-gray-600 font-bold max-w-full">
              Assurance
            </h3>
            <div className="data-value text-app-blue max-w-full truncate">
              <ClubDiverCardAttributeInsurance bookingMember={bookingMember} />
            </div>
          </div>
        );
      case 'size':
        return (
          <div className={`${className} max-w-full`}>
            <h3 className="data-label text-gray-600 font-bold max-w-full">
              Taille
            </h3>
            <div className="data-value text-app-blue max-w-full truncate">
              {(diver.size / 100).toFixed(2)} m
            </div>
          </div>
        );
      case 'weight':
        return (
          <div className={`${className} max-w-full`}>
            <h3 className="data-label text-gray-600 font-bold max-w-full">
              Poids
            </h3>
            <div className="data-value text-app-blue max-w-full truncate">
              {diver.weight} Kg
            </div>
          </div>
        );
      case 'shoeSize':
        return (
          <div className={`${className} max-w-full`}>
            <h3 className="data-label text-gray-600 font-bold max-w-full">
              Pointure
            </h3>
            <div className="data-value text-app-blue max-w-full truncate">
              {diver.details?.measurements?.shoeSize}
            </div>
          </div>
        );
      case 'emergencyContact':
        return (
          <div className={`${className} max-w-full`}>
            <h3 className="data-label text-gray-600 font-bold max-w-full">
              Contact urgence
            </h3>
            <CommentLabel
              comment={diver.emergencyContact}
              chunkClassName="data-value text-app-blue"
            />
          </div>
        );
      case 'homeAddress':
        return (
          <div className={`${className} max-w-full`}>
            <h3 className="data-label text-gray-600 font-bold max-w-full">
              Adresse postale
            </h3>
            <CommentLabel
              comment={postalAddressFormatter.format(diver.homeAddress)}
              chunkClassName="data-value text-app-blue"
            />
          </div>
        );
      case 'comment':
        return (
          <div className={`${className} max-w-full`}>
            <h3 className="data-label text-gray-600 font-bold max-w-full">
              Commentaire général privé 🚫
            </h3>
            <CommentLabel
              tag={PARTICIPANT_COMMENT_TAG}
              comment={diver.comment}
              chunkClassName="data-value text-app-blue"
            />
          </div>
        );
      case 'divingComment':
        return (
          <div className={`${className} max-w-full`}>
            <h3 className="data-label text-gray-600 font-bold max-w-full">
              Remarques plongée
            </h3>
            <CommentLabel
              tag={PARTICIPANT_COMMENT_TAG}
              comment={diver.divingComment}
              chunkClassName="data-value text-app-blue"
            />
          </div>
        );
      default:
        return null;
    }
  }
};
