/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';

import { AppFormControlRHF, AppInputRHF } from '../../../../../lib/form';
import { ClubSettingsProductOfferCategoryDialogLocalState } from '../useClubSettingsProductOfferCategoryDialogLocalState.hook';

export type StaffMemberEdit_TabId =
  | 'profil'
  | 'availabilities'
  | 'staff'
  | 'documents';
export const ClubSettingsProductOfferCategoryEditForm = ({
  localState,
  className,
}: {
  localState: ClubSettingsProductOfferCategoryDialogLocalState;
  className?: string;
}) => {
  const { state } = localState;
  const { control } = state.form;
  return (
    <div className={className}>
      <div>
        {/* <h2 className="text-left text-sm sm:text-lg leading-6 py-2 font-medium text-app-blue uppercase">
          Catégorie
        </h2> */}
        <div className={'w-full flex flex-col gap-4'}>
          <AppFormControlRHF
            className={'w-full'}
            label="Titre"
            control={control}
            required={false}
            name={'offerCategory.title'}
            renderComponent={(props) => (
              <AppInputRHF {...props} fullWidth type="text" />
            )}
          />
          <AppFormControlRHF
            className="w-full"
            label="Commentaire"
            control={control}
            name="offerCategory.description"
            renderComponent={(props) => (
              <AppInputRHF
                {...props}
                fullWidth
                multiline
                rowsMax={15}
                rows={2}
              />
            )}
          />
        </div>
      </div>
    </div>
  );
};
