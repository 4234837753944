/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  AppInquiryFullGql_Customer,
  AppInquirySection,
} from '@mabadive/app-common-model';
import clsx from 'clsx';
import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { AppIconsInquiry } from '../../../../../../../../business/_core/modules/layout/icons';
import { ClubSettingsInquiryEditPageForm_TabPreviewLocalState } from '../useClubSettingsInquiryEditPageForm_TabPreviewLocalState.state';
import { DiveCenterBookingInquiryResponsePage_FormQuestion } from './DiveCenterBookingInquiryResponsePage_FormQuestion';
import { DiveCenterBookingInquiryResponsePage_FormModel } from './model';

export const DiveCenterBookingInquiryResponsePage_FormSection = ({
  inquiry,
  section,
  sectionIndex,
  localState,
  form,
  className,
}: {
  inquiry: AppInquiryFullGql_Customer;
  section: AppInquirySection;
  sectionIndex: number;
  localState: ClubSettingsInquiryEditPageForm_TabPreviewLocalState;
  form: UseFormReturn<DiveCenterBookingInquiryResponsePage_FormModel>;
  className?: string;
}) => {
  const { state, data } = localState;

  const inquiryType = inquiry.type;
  const InquiryIcon = AppIconsInquiry[inquiryType];

  const title = section.description?.main?.title?.trim();
  const subTitle = section.description?.main?.subTitle?.trim();
  return (
    <div className={clsx('grid gap-2', className)}>
      <div className={clsx('app-card rounded-t-lg grid gap-2')}>
        <div
          className="cursor-pointer flex gap-4 justify-between items-center rounded-t-lg text-white py-2 app-px-content"
          style={{
            backgroundColor: inquiry.settings.color,
          }}
        >
          <div className="text-lg font-medium">
            {title?.length > 0 ? title : `Section ${sectionIndex + 1}`}
          </div>
        </div>
        {subTitle?.length > 0 && (
          <div className="app-p-content text-base font-normal text-gray-800">
            {subTitle}
          </div>
        )}
      </div>
      <div className="grid gap-2">
        {section.questions.map((question, questionIndex) => (
          <DiveCenterBookingInquiryResponsePage_FormQuestion
            key={questionIndex}
            sectionIndex={sectionIndex}
            question={question}
            questionIndex={questionIndex}
            form={form}
            inquiry={inquiry}
            localState={localState}
          />
        ))}
      </div>
    </div>
  );
};
