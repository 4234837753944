import { Booking } from '@mabadive/app-common-model';
import { bookingMemberFullSorter } from '@mabadive/app-common-services';
import clsx from 'clsx';
import React, { useEffect, useMemo, useState } from 'react';
import {
  AppButton,
  AppTransition,
} from 'src/business/_core/modules/layout/components/_tailwind';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { AppInputBooleanSwitch } from '../../../../../../../../lib/form';
import { useClubSettings } from '../../../../../../data/hooks';
import { BookingResumeOnClickMemberAction } from '../../../_from-diver-app';
import {
  PRO_BookingMemberFull_WithDocs,
  PRO_BookingResume,
} from '../../../DiverBookingPage/models';
import { DiverBookingPageGlobalState } from '../../../DiverBookingPage/useDiverBookingPageGlobalState.hook';
import { DiverBookingSpace_BookingParticipantsListItem } from './DiverBookingSpace_BookingParticipantsListItem';

export function DiverBookingSpace_BookingParticipantsList({
  booking,
  aggregatedBookingResume,
  bookingMembersFull,
  globalState,
  onClickMember,
}: {
  booking: Booking;
  aggregatedBookingResume: PRO_BookingResume;
  bookingMembersFull: PRO_BookingMemberFull_WithDocs[];
  globalState: DiverBookingPageGlobalState;
  onClickMember?: (
    bookingMemberFull: PRO_BookingMemberFull_WithDocs,
    action: BookingResumeOnClickMemberAction,
  ) => void;
}) {
  const { bookingOpeningStates, setBookingOpeningStates } = globalState;

  const bookingId = booking._id;

  const { updateState } = globalState;

  const [isExpanded, setIsExpanded] = useState(
    updateState.filteredDiverId ? false : true,
  );

  useEffect(() => {
    if (updateState.filteredDiverId) {
      setIsExpanded(false);
    }
  }, [updateState.filteredDiverId]);

  const bookingMembersFullSorted = useMemo(
    () =>
      bookingMemberFullSorter.sort(bookingMembersFull, {
        bookingContactDiverId: booking?.bookingContactDiverId,
      }),
    [booking.bookingContactDiverId, bookingMembersFull],
  );
  const bookingMembersFullFilteredAndSorted = useMemo(
    () =>
      bookingMembersFullSorted.filter(
        (bookingMemberFull) =>
          bookingMemberFull.diver._id ===
            bookingMemberFull.booking.bookingContactDiverId ||
          bookingMemberFull.diver._id === updateState.filteredDiverId,
      ),
    [bookingMembersFullSorted, updateState.filteredDiverId],
  );
  const isUnique = bookingMembersFullSorted.length === 1;

  const displayedParticipants = isExpanded
    ? bookingMembersFullSorted
    : bookingMembersFullFilteredAndSorted;

  const hiddenParticipantsCount =
    bookingMembersFullSorted.length - displayedParticipants.length;

  const clubSettings = useClubSettings();
  const showProfilePhoto = useMemo(() => {
    if (clubSettings?.customer?.general?.profilePhoto) {
      // at least one profile with photo

      return (
        bookingMembersFullSorted.find(
          (x) => x.diver?.details?.profilePhoto?._id,
        ) !== undefined
      );
    }
    return false;
  }, [bookingMembersFullSorted, clubSettings?.customer?.general?.profilePhoto]);

  // const [showParticipantDetails, setShowParticipantDetails] = useState(
  //   displayedParticipants.length < 5,
  // );

  const showParticipantDetails =
    globalState.state.viewState.tabBooking_showParticipantDetails;

  return (
    <>
      <div className="my-2">
        <AppInputBooleanSwitch
          label="Afficher le détail"
          value={showParticipantDetails}
          onChange={() =>
            globalState.state.setViewState({
              ...globalState.state.viewState,
              tabBooking_showParticipantDetails: !showParticipantDetails,
            })
          }
        />
      </div>
      <AppTransition
        type="scale"
        show={true}
        as="div"
        className="min-w-full border border-gray-200 bg-white divide-y divide-gray-200 text-xs px-2"
      >
        {displayedParticipants.map((bookingMemberFull) => (
          <DiverBookingSpace_BookingParticipantsListItem
            key={`${bookingMemberFull.bookingMember._id}-${showParticipantDetails}`} // on force à regénérer le composant si on modifie l'affichage
            className="py-1"
            showParticipantDetails={showParticipantDetails}
            showProfilePhoto={showProfilePhoto}
            bookingMembersCount={bookingMembersFullSorted.length}
            bookingMemberFull={bookingMemberFull}
            aggregatedBookingResume={aggregatedBookingResume}
            booking={booking}
            globalState={globalState}
            onClick={(bookingMember, action) =>
              onClickMember ? onClickMember(bookingMember, action) : undefined
            }
          />
        ))}
      </AppTransition>
      {hiddenParticipantsCount > 0 && (
        <div className="w-full px-1 flex flex-col sm:flex-row gap-2">
          {bookingMembersFull.length > 5 && (
            <>
              {isExpanded ? (
                <AppButton
                  className={clsx(!updateState.filteredDiverId && 'hidden')}
                  fullWidth={true}
                  color="primary-outline-light"
                  onClick={() => setIsExpanded(!isExpanded)}
                  icon={AppHeroIcons.eyeOff}
                >
                  Masquer les participants
                </AppButton>
              ) : (
                <AppButton
                  fullWidth={true}
                  color="primary-outline-light"
                  onClick={() => setIsExpanded(!isExpanded)}
                  icon={AppHeroIcons.eye}
                >
                  Afficher les {hiddenParticipantsCount} autres participants
                </AppButton>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
}
