import clsx from 'clsx';
import React from 'react';
import {
  PRO_BookingMemberFull,
  PRO_BookingResume,
} from '../../DiverBookingPage/models';
import { ClubDiverProfilePicture } from './ClubDiverProfilePicture';
import { DiveCenterBookingMembersListDiffItemCard } from './DiveCenterBookingMembersListDiffItemCard';
import { DiveCenterBookingMemberDiff } from './model';

export function DiveCenterBookingMembersListDiffItem({
  aggregatedBookingResume,
  memberDiff,
  showProfilePhoto,
  mode,
  className,
}: {
  aggregatedBookingResume: PRO_BookingResume;
  memberDiff: DiveCenterBookingMemberDiff;
  showProfilePhoto: boolean;
  mode: 'original' | 'final';
  className?: string;
}) {
  const { status, original, final, changes } = memberDiff;

  const memberFocus: PRO_BookingMemberFull =
    mode === 'original' ? original : final;
  const memberOther: PRO_BookingMemberFull =
    mode === 'original' ? final : original;

  const unchangedClass = 'grayscale opacity-80';
  return (
    <li className={clsx('text-xs', className)}>
      <button className={'app-card w-full block cursor-default select-text'}>
        <div className={'w-full px-2 py-2 flex gap-2'}>
          {showProfilePhoto && (
            <ClubDiverProfilePicture
              className={clsx(
                'mt-1.5 size-[6.5rem] cursor-pointer',
                memberFocus?.diver?.details?.profilePhoto ===
                  memberOther?.diver?.details?.profilePhoto && unchangedClass,
              )}
              shape="square"
              diver={memberFocus.diver}
              // onClick={(e) => {
              //   e.stopPropagation();
              //   onClick(bookingMemberFull, 'edit');
              // }}
            />
          )}

          <DiveCenterBookingMembersListDiffItemCard
            className="flex-grow"
            aggregatedBookingResume={aggregatedBookingResume}
            memberDiff={memberDiff}
            mode={mode}
          />
        </div>
      </button>
    </li>
  );
}
