import {
  ClubResumeGql_pro,
  ClubSettingsCustomer,
  PRO_AppDocResume,
} from '@mabadive/app-common-model';
import { dataSorter, dateService } from '@mabadive/app-common-services';
import { AppHeroIcons } from '../../../../../../../../_core/modules/layout/icons';
import { PRO_BookingMemberFull_WithDocs } from '../../../../DiverBookingPage/models';
import {
  DiverBookingSpace_DocDataResume,
  DiverBookingSpace_DocDataResume_Status,
} from '../model/DiverBookingSpace_DocDataResume.type';

export const docDataResumeBuilder_Insurance = {
  buildInsurance,
};

function buildInsurance({
  bookingMemberFull,
  clubResume,
}: {
  bookingMemberFull: PRO_BookingMemberFull_WithDocs;
  clubResume: ClubResumeGql_pro;
}): DiverBookingSpace_DocDataResume {
  const customerSettings = clubResume.clubSettings?.customer;

  const docResume = getDocResume(bookingMemberFull);

  const { status, issueDate } = buildAttributes({
    docResume,
    customerSettings,
    bookingMemberFull,
  });

  const icon = AppHeroIcons.check;

  const docDataResume: DiverBookingSpace_DocDataResume = {
    title: 'Assurance',
    issueDate,
    status,
    appDoc: docResume,
    icon,
  };
  return docDataResume;
}

function getDocResume(bookingMemberFull: PRO_BookingMemberFull_WithDocs) {
  const docResumes = bookingMemberFull.docResumes.filter(
    (x) => x.docRef === 'insurance-proof',
  );
  if (docResumes.length > 1) {
    const sorted = dataSorter.sortMultiple(docResumes, {
      getSortAttributes: (x) => [
        {
          value: x.details?.docDate,
          asc: false,
        },
        {
          value:
            x.status === 'validated'
              ? 1
              : x.status === 'submitted'
              ? 2
              : x.status === 'rejected'
              ? 3
              : 4,
          asc: false,
        },
      ],
    });
    // on retourne le premier d'après les critères de tri
    return sorted?.[0];
  } else {
    // on retourne le premier, ou rien
    return docResumes?.[0];
  }
}
function buildAttributes({
  docResume,
  customerSettings,
  bookingMemberFull,
}: {
  docResume: PRO_AppDocResume;
  customerSettings: ClubSettingsCustomer;
  bookingMemberFull: PRO_BookingMemberFull_WithDocs;
}): {
  status: DiverBookingSpace_DocDataResume_Status;
  issueDate: Date;
  expirationDate?: Date;
} {
  if (docResume) {
    // on se base sur un doc
    let issueDate = docResume.details?.docDate;
    const expirationDate =
      bookingMemberFull.bookingMember.details?.insurance?.expirationDate;

    const docValidityInYears = docResume.details?.docValidityInYears ?? 1;

    let status: DiverBookingSpace_DocDataResume_Status;
    if (expirationDate && dateService.getAge(expirationDate) >= 0) {
      if (issueDate && dateService.getAge(issueDate) >= docValidityInYears) {
        status = 'expired';
      } else {
        status = docResume?.status;
      }
    }

    return {
      status,
      issueDate,
    };
  } else {
    const docValidityInYears = 1;
    const issueDate =
      bookingMemberFull.bookingMember.details?.insurance?.expirationDate;

    let status: DiverBookingSpace_DocDataResume_Status;
    if (issueDate && dateService.getAge(issueDate) >= docValidityInYears) {
      status = 'expired';
    } else {
      if (
        issueDate ||
        bookingMemberFull.bookingMember.details?.insurance?.hasInsurance
      ) {
        if (!bookingMemberFull.bookingMember.details?.insurance?.hasInsurance) {
          status = 'submitted';
        } else {
          status = 'validated';
        }
      } else {
        status = 'missing';
      }
    }
    return {
      status,
      issueDate,
    };
  }
}
