/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useMemo } from 'react';

import {
  ClubStaffMemberRole,
  STAFF_ROLE_TAGS,
} from '@mabadive/app-common-model';
import { equipmentFormatter } from '@mabadive/app-common-services';
import { useWatch } from 'react-hook-form';
import { useDiveCenterResume } from 'src/business/club/data/hooks';
import { ValueLabel } from 'src/business/club/modules/_common/form';
import {
  AppFormControlRHF,
  AppInputRHF,
  AppMultiCheckboxesRHF,
} from 'src/lib/form';
import { AppInputDatePickerMaterialRHF } from 'src/stories/components/04-form';
import { StaffMemberEditDialogLocalState } from '../useStaffMemberEditDialogLocalState.hook';
import { StaffMemberEquipmentFormGroup } from './StaffMemberEquipmentFormGroup';

export const StaffMemberEditForm_Tab1_Profil = ({
  localState,
  className,
}: {
  localState: StaffMemberEditDialogLocalState;
  className?: string;
}) => {
  const {
    state: { form },
  } = localState;
  const { control, setValue } = form;

  const diveCenterResume = useDiveCenterResume();
  const hasAnyStaffEquipmentEnabled =
    diveCenterResume.privateSettings?.equipment?.equipments?.find(
      (x) => x.enableStaffEquipment,
    ) !== undefined;

  const STAFF_ROLE_OPTIONS = useMemo(
    () =>
      STAFF_ROLE_TAGS.map((roleTag) => {
        const option: ValueLabel<ClubStaffMemberRole> = {
          label: roleTag.content.label,
          value: roleTag.reference,
        };
        return option;
      }),
    [],
  );

  const [birthdate] = useWatch({
    control,
    name: ['staffMember.profile.birthdate'],
  });

  return (
    <div className={className}>
      <div>
        {/* <h2 className="text-left text-sm sm:text-lg leading-6 py-2 font-medium text-app-blue uppercase">
          Profil
        </h2> */}
        <div
          className={
            'w-full flex flex-col sm:grid sm:grid-cols-2 md:grid-cols-4  gap-4'
          }
        >
          <AppFormControlRHF
            className={'md:col-span-2 w-full'}
            label="Nom"
            control={control}
            required={false}
            name={'staffMember.profile.lastName'}
            renderComponent={(props) => (
              <AppInputRHF {...props} fullWidth type="text" />
            )}
          />
          <AppFormControlRHF
            className={'md:col-span-2 w-full'}
            label="Prénom"
            control={control}
            name={'staffMember.profile.firstName'}
            renderComponent={(props) => (
              <AppInputRHF {...props} fullWidth type="text" />
            )}
          />
          <AppFormControlRHF
            className={'md:col-span-2 w-full'}
            label="Téléphone"
            control={control}
            name={'staffMember.profile.phoneNumber'}
            renderComponent={(props) => (
              <AppInputRHF {...props} fullWidth type="text" />
            )}
          />
          <AppFormControlRHF
            className={'md:col-span-2 w-full'}
            label="Adresse e-mail"
            control={control}
            validation={{
              email: true,
            }}
            name={'staffMember.profile.emailAddress'}
            renderComponent={(props) => (
              <AppInputRHF {...props} fullWidth type="text" />
            )}
          />
          <AppFormControlRHF
            className="md:col-span-2 w-full"
            label="Date de naissance"
            required={false}
            control={control}
            name={'staffMember.profile.birthdate'}
            renderComponent={(props) => (
              <AppInputDatePickerMaterialRHF
                {...props}
                required={false}
                className="w-full"
                initialView={birthdate ? 'weeks' : 'years'}
                maxYear={new Date().getFullYear()}
              />
            )}
          />
          <AppFormControlRHF
            className={'md:col-span-2 lg:col-span-6 w-full'}
            label="Permis bateau"
            control={control}
            name={'staffMember.profile.boatingLicence'}
            renderComponent={(props) => (
              <AppInputRHF {...props} fullWidth type="text" />
            )}
          />
        </div>
        <div className={'w-full'}>
          <AppFormControlRHF
            className={'w-full'}
            label="Commentaire général"
            control={control}
            name={'staffMember.profile.comment'}
            renderComponent={(props) => (
              <AppInputRHF
                {...props}
                fullWidth
                multiline
                rowsMax={15}
                rows={2}
              />
            )}
          />
        </div>
        <div className={'w-full'}>
          <AppFormControlRHF
            className="md:col-span-2 w-full"
            label={'Fonctions'}
            control={control}
            name={'staffMember.profile.roles'}
            renderComponent={(props) => (
              <AppMultiCheckboxesRHF
                {...props}
                className="flex flex-wrap w-full text-gray-600"
                options={STAFF_ROLE_OPTIONS}
              />
            )}
          />
        </div>
      </div>
      {hasAnyStaffEquipmentEnabled && (
        <>
          <h2 className="text-left text-sm sm:text-lg leading-6 py-2 font-medium text-app-blue uppercase">
            Équipement
          </h2>
          <StaffMemberEquipmentFormGroup
            className={'w-60'}
            classNameIfModel2={'sm:col-span-2'}
            type="tank"
            label={equipmentFormatter.formatEquipmentType('tank')}
            control={control}
            name="staffMember.equipment.tank"
            // onChange={(tankEquipment) => {
            //   const tank = tankEquipment?.selection?.model?.tank;
            // }}
          />
        </>
      )}
    </div>
  );
};
