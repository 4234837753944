import React from 'react';
import { AppMessageSingleLine } from '../../../../../../../../business/_core/modules/layout';
import { AppSignaturePad } from '../../../../../../../../lib/form';
import { ClubSettingsInquiryEditPageForm_TabPreviewLocalState } from '../useClubSettingsInquiryEditPageForm_TabPreviewLocalState.state';
import { DiveCenterBookingInquiryResponsePage_FormHeader } from './DiveCenterBookingInquiryResponsePage_FormHeader';
import { DiveCenterBookingInquiryResponsePage_FormSection } from './DiveCenterBookingInquiryResponsePage_FormSection';

export const DiveCenterBookingInquiryResponsePage_FillFormPanel = ({
  localState,
}: {
  localState: ClubSettingsInquiryEditPageForm_TabPreviewLocalState;
}) => {
  const {
    state: { form },
    data: { inquiry },
  } = localState;

  const displaySignaturePad =
    inquiry?.settings?.consents?.padSignature?.enabled;

  return (
    <div className="w-full">
      <div className="bg-gray-200 app-p-content">
        <div className="w-full grid gap-4">
          <DiveCenterBookingInquiryResponsePage_FormHeader
            className="w-full"
            inquiry={inquiry}
          />
          <div className="w-full grid gap-4 md:gap-8">
            {inquiry.sections.map((section, sectionIndex) => (
              <DiveCenterBookingInquiryResponsePage_FormSection
                key={sectionIndex}
                section={section}
                sectionIndex={sectionIndex}
                form={form}
                inquiry={inquiry}
                localState={localState}
              />
            ))}
          </div>
          {displaySignaturePad && (
            <div className="app-card app-p-content grid gap-2 h-[300px]">
              <AppMessageSingleLine type="info" className="font-bold">
                Signature électronique
              </AppMessageSingleLine>
              <p>
                Veuillez reproduire votre signature manuscrite dans le cadre
                ci-dessous.
              </p>
              <AppSignaturePad padClassName="max-h-[200px] max-w-[300px]" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
