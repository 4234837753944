import Tippy from '@tippyjs/react';
import clsx from 'clsx';
import React, { useCallback, useState } from 'react';
import { AppHeroIcons } from '../../../../business/_core/modules/layout/icons';
import {
  useClubResume,
  useDiveCenterResume,
} from '../../../../business/club/data/hooks';
import { DiveCenterProBadge } from '../../../../pages/_components/company';

export const AppFormLabel: React.FC<{
  id?: string;
  required?: boolean;
  label: React.ReactNode;
  labelFontSize?: string;
  requiredStyle?: 'show-optional-label';
  hasError?: boolean;
  disabled?: boolean;
  helpDescription?: string;
  className?: string;
  children?: React.ReactNode | React.ReactNode[];
  isDiveCenterSpecificFeature?: boolean;
  visibility?: 'super-admin';
}> = ({
  id,
  label,
  labelFontSize = 'text-xs',
  required,
  requiredStyle,
  hasError,
  disabled,
  helpDescription,
  className = '',
  children,
  isDiveCenterSpecificFeature,
  visibility,
}) => {
  // ATTENTION: sur le super admin: clubResume est null
  const clubResume = useClubResume();
  const diveCenterResume = useDiveCenterResume();
  const isMultiDiveCenters = clubResume?.diveCenters?.length > 1;

  const [isTooltipVisible, setIsTooltipVisible] = useState<boolean>(undefined);
  const toggleTooltip = useCallback(() => {
    if (helpDescription) {
      // une fois passé en mode "manuel" (activation au clic), il le reste
      setIsTooltipVisible(!isTooltipVisible);
    }
  }, [helpDescription, isTooltipVisible]);

  const labelComponent = (
    <label
      className={clsx(
        'inline-block font-normal uppercase text-left truncate',
        labelFontSize,
        hasError
          ? 'text-status-error'
          : disabled
          ? 'text-gray-400 italic'
          : 'text-gray-500 ',
      )}
      htmlFor={id}
    >
      {label}{' '}
      {required ? (
        <span className="text-red-400">*</span>
      ) : requiredStyle === 'show-optional-label' ? (
        <span className="text-gray-400 font-normal">({'facultatif'})</span>
      ) : null}
      {visibility === 'super-admin' && (
        <span className="font-bold text-purple-500">#</span>
      )}
      {helpDescription && (
        <AppHeroIcons.help className="inline-block ml-2 h-4 w-4 rounded-full bg-gray-500 text-white" />
      )}
    </label>
  );

  return (
    <div className={`focus:outline-none ${className}`}>
      <div className="flex justify-between gap-2 items-center">
        {label &&
          (helpDescription ? (
            <div
              className={clsx(helpDescription && 'cursor-pointer')}
              onClick={() => toggleTooltip()}
            >
              <Tippy
                visible={isTooltipVisible}
                disabled={!helpDescription?.trim()}
                delay={[100, 100]}
                placement="right"
                content={helpDescription}
                onClickOutside={() => setIsTooltipVisible(false)}
              >
                {labelComponent}
              </Tippy>
            </div>
          ) : (
            labelComponent
          ))}
        {isMultiDiveCenters && isDiveCenterSpecificFeature && (
          <DiveCenterProBadge
            className="inline-block mx-auto"
            diveCenter={diveCenterResume}
          />
        )}
      </div>
      {children}
    </div>
  );
};
