import React, { useState } from 'react';
import { DiverBookingSpace_BookingParticipantsList } from '../../../DiverBookingSpace/components/DiverBookingSpace_BookingParticipantsList';
import { DiverBookingCard_AddMemberOrSession_Buttons } from './DiverBookingCard_AddMemberOrSession_Buttons';
import { DiverBookingCard_LocalState } from './useDiverBookingCard_LocalState';

export function DiverBookingCard_TabParticipants({
  localState,
  className,
}: {
  localState: DiverBookingCard_LocalState;
  className?: string;
}) {
  const { state, actions, data } = localState;
  const {
    bookingTabModel,
    booking,
    onlineBookings,
    participantsBySessionsFuture,
    participantsBySessionsPast,
    bookingMembersFull,
  } = data;
  const { globalState, subTab, dialogs } = state;

  const [
    showMembersWithoutFutureParticipants,
    setShowMembersWithoutFutureParticipants,
  ] = useState(true);

  return (
    <div className={className}>
      <DiverBookingSpace_BookingParticipantsList
        booking={booking}
        aggregatedBookingResume={bookingTabModel.aggregatedBooking}
        bookingMembersFull={bookingMembersFull}
        globalState={globalState}
        onClickMember={(bookingMemberFull, action) => {
          actions.onClickMember({ bookingMemberFull, action });
        }}
      />
      <DiverBookingCard_AddMemberOrSession_Buttons
        className="my-4"
        actions={actions}
        bookingTabModel={bookingTabModel}
        onClickAddSession={() => {
          state.selectSubTab('booking-registration');
        }}
      />
      {/* <div className="my-8 text-lg text-gray-600 font-bold">
        Ancien affichage:{' '}
      </div>
      <BookingResumeMembersList
        booking={booking}
        aggregatedBookingResume={bookingTabModel.aggregatedBooking}
        bookingMembersFull={bookingMembersFull}
        globalState={globalState}
        onClickMember={(bookingMemberFull, action) => {
          actions.onClickMember({ bookingMemberFull, action });
        }}
      /> */}
    </div>
  );
}
