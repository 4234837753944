import {
  ClubResumeStaffMember,
  ClubStaffMemberDailyAvailabilities,
  ClubStaffMemberDailyAvailability,
  ClubStaffMemberProfile,
  WEEK_DAYS_ISO,
} from '@mabadive/app-common-model';
import { dateService, uuidGenerator } from '@mabadive/app-common-services';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { ClubResumeStaffMemberFormModel } from './model';

export function useStaffMemberFormLocalState({
  mode,
  staffMemberId,
  staffMembers,
}: {
  mode: 'create' | 'edit';
  staffMemberId?: string;
  staffMembers: ClubResumeStaffMember[];
}) {
  const staffMember: ClubResumeStaffMember = useMemo(
    () =>
      mode === 'edit'
        ? staffMembers.find((x) => x._id === staffMemberId)
        : undefined,
    [staffMembers, mode, staffMemberId],
  );

  const initialFormValue: Partial<ClubResumeStaffMemberFormModel> =
    useMemo(() => {
      if (mode === 'create') {
        const createInitialValue: Partial<ClubResumeStaffMemberFormModel> = {
          profile: {
            roles: [
              'diving-director',
              'scuba-diving-instructor',
              'surface-security',
            ],
          } as ClubStaffMemberProfile,
          availabilities: [
            {
              _id: uuidGenerator.random(),
              label: 'Planning par défaut',
              beginDate: dateService.add(
                dateService.getUTCDateSetTime(new Date(), 0),
                -1,
                'week',
              ),
              defaultWorkingDays: WEEK_DAYS_ISO.reduce((acc, isoWeekDay) => {
                const av: ClubStaffMemberDailyAvailability = {
                  availabilityPeriod: 'day',
                };
                acc[isoWeekDay] = av;
                return acc;
              }, {} as ClubStaffMemberDailyAvailabilities),
              jobType: 'regular',
              endDate: undefined,
            },
          ],
        };
        return createInitialValue;
      } else if (staffMember) {
        const editInitialValue: Partial<ClubResumeStaffMemberFormModel> = {
          ...staffMember,
        };
        return editInitialValue;
      }
    }, [mode, staffMember]);

  const form = useForm<ClubResumeStaffMemberFormModel>({
    defaultValues: initialFormValue, // will be updated once data is fetched
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;
  useEffect(() => {
    if (initialFormValue) {
      reset(initialFormValue, {});
    }
  }, [initialFormValue, reset]);

  return {
    mode,
    staffMember,
    form,
    initialFormValue,
  };
}
