import {
  PRO_AppDocResume,
  StaffMemberProAttachedData,
} from '@mabadive/app-common-model';
import { changeDescriptorAggregator } from '@mabadive/app-common-services';
import { appLogger } from 'src/business/_core/modules/root/logger';
import { StaffMemberEditDialog_UpdateState } from './_model';

export const staffmemberAggregatedDataBuilder = {
  aggregateAll,
};

function aggregateAll({
  loadedContent,
  updateState,
}: {
  loadedContent: StaffMemberProAttachedData;
  updateState: StaffMemberEditDialog_UpdateState;
}): {
  aggregatedData: StaffMemberProAttachedData;
} {
  if (!loadedContent) {
    const aggregatedData: StaffMemberProAttachedData = {
      docResumes: [],
    };
    return { aggregatedData };
  }

  const docResumes: PRO_AppDocResume[] =
    changeDescriptorAggregator.aggregateMany(updateState.docResumesChanges, {
      pk: '_id',
      initials: loadedContent.docResumes,
      ignoreErrors: true,
      appLogger,
      logPrefix: 'docResumes',
    });

  const aggregatedData: StaffMemberProAttachedData = {
    docResumes,
  };

  return { aggregatedData };
}
