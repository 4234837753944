/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { useRedirect } from 'src/business/_core/data/hooks';
import { useClubSettings } from 'src/business/club/data/hooks';
import {
  UseClubDialogsProps,
  useClubDialogsActionsPersist,
} from 'src/pages/_dialogs';
import {
  AppButton,
  AppMessageLight,
} from '../../../../../../../business/_core/modules/layout';
import { AppHeroIcons } from '../../../../../../../business/_core/modules/layout/icons';
import { AppTranslationContentPanel } from '../../../../../../../lib/form/components/AppBooklet/view/AppTranslationContentPanel';
import { ClubSettingsOnlineBookingProductsPageLocalState } from '../../useClubSettingsOnlineBookingProductsPageLocalState.hook';
import {
  ProProductsPage_CategoryViewPanelLocalState,
  useProProductsPage_CategoryViewPanelLocalState,
} from './useProProductsPage_CategoryViewPanelLocalState.hook';

export const ProProductsPage_CategoryViewPanel_TabCategoryDescription = ({
  localState: parentState,
}: {
  localState: ClubSettingsOnlineBookingProductsPageLocalState;
}) => {
  const actionsPersist: UseClubDialogsProps = useClubDialogsActionsPersist();
  const clubSettings = useClubSettings();
  const redirectTo = useRedirect();

  const generalSettings = clubSettings.general;

  const localState: ProProductsPage_CategoryViewPanelLocalState =
    useProProductsPage_CategoryViewPanelLocalState({
      parentState,
    });

  const { state, data, actions, dialogs } = localState;
  const { categoryBookletPage } = data;
  const content = categoryBookletPage?.mainContent?.content;

  return (
    <div className="grid gap-4">
      <div className="flex-grow max-w-md">
        {(content?.items ?? []).map((item, i) => (
          <AppTranslationContentPanel key={i} item={item} />
        ))}
      </div>
      <div>
        <AppButton
          className="whitespace-nowrap"
          color="gray-outline-light"
          size="normal"
          icon={AppHeroIcons.actionEdit}
          onClick={(e) => {
            e.stopPropagation();
            dialogs.categoryEditor.openEditCategory({
              editValue: {
                category: data.category,
                appBooklet: data.categoryBooklet,
                appBookletPage: data.categoryBookletPage,
              },
            });
          }}
        >
          Modifier la description
        </AppButton>
      </div>
      <AppMessageLight>
        <div>
          NOTE: fournissez ici une description générale de la catégorie, avec
          une ou plusieurs photos.
        </div>
      </AppMessageLight>
    </div>
  );
};
