import { DiveSession } from '@mabadive/app-common-model';
import { diveSessionTitleBuilder } from '@mabadive/app-common-services';
import React, { useMemo } from 'react';
import { IoStar } from 'react-icons/io5';
import { AppEmojiIcon } from 'src/lib/form/components/AppEmojiPicker';
import { DiveSiteIconDef, useDiveSiteIcon } from '../hooks';

export const ClubDiveSessionNameLight = function ({
  diveSession,
  truncateText,
  iconSize = 'normal',
  className,
}: {
  diveSession: Pick<
    DiveSession,
    | 'diveSiteId'
    | 'name'
    | 'status'
    | 'special'
    | 'diveTourSession2'
    | 'emojiId'
    | 'type'
    | 'theme'
  >;
  truncateText?: boolean;
  iconSize?: 'small' | 'normal';
  className?: string;
}) {
  const status = diveSession.status;

  const isMultiSession = !!diveSession.diveTourSession2;

  const sessionTitle = useMemo(
    () => diveSessionTitleBuilder.build(diveSession),
    [diveSession],
  );

  const sessionTitleClass = diveSession.special ? 'text-app-orange' : '';

  const sessionTitleIconClass = diveSession.special
    ? 'text-app-orange'
    : 'text-gray-500';

  const diveSiteIcon: DiveSiteIconDef = useDiveSiteIcon(
    diveSession.diveSiteId,
    diveSession?.diveTourSession2?.diveSiteId,
  );

  const displayAny = !!sessionTitle || !!diveSession?.emojiId;
  return displayAny ? (
    <div
      className={`flex gap-2 items-center
        ${className}
      `}
    >
      <div className="flex gap-1  items-center">
        {diveSession?.emojiId ? (
          <AppEmojiIcon
            className=""
            id={diveSession.emojiId}
            size={iconSize === 'small' ? 12 : 18}
          />
        ) : (
          <IoStar
            className={`${
              iconSize === 'small' ? 'w-3 h-3' : 'w-4 h-4'
            } ${sessionTitleIconClass}`}
          />
        )}

        {sessionTitle && (
          <div
            className={`${sessionTitleClass} ${truncateText ? 'truncate' : ''}`}
          >
            {sessionTitle}
          </div>
        )}
      </div>
    </div>
  ) : null;
};
