/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  DiveSessionResumeGroup,
  DiveSessionResumeParticipant,
} from '@mabadive/app-common-model';
import { DiveSessionParticipantsGroupsStats } from 'src/business/club/modules/club-dive-session/model';
import { diveSessionVirtualGroupsStatsBuilder } from './diveSessionVirtualGroupsStatsBuilder.service';

export const diveSessionRealGroupsStatsBuilder = {
  buildRealGroupsStats,
};

function buildRealGroupsStats({
  activeDivers,
  diveSessionGroups,
  clubReference,
  assignedFreeInstructorsCount,
}: {
  activeDivers: DiveSessionResumeParticipant[];
  diveSessionGroups: DiveSessionResumeGroup[];
  clubReference: string;
  assignedFreeInstructorsCount: number;
}): DiveSessionParticipantsGroupsStats {
  const groupIds = activeDivers.reduce((acc, participant) => {
    const groupId = participant.diveSessionGroupId;
    if (!acc.includes(groupId)) {
      acc.push(groupId);
    }
    return acc;
  }, [] as string[]);

  const realGroups = diveSessionGroups.filter((group) =>
    groupIds.includes(group._id),
  );

  const groupsStats: DiveSessionParticipantsGroupsStats = {
    type: 'real',
    autonomous: 0,
    firstDive: 0,
    supervised: 0,
    assignedFreeInstructors: assignedFreeInstructorsCount,
    training: 0,
    autonomousWarning: false,
    supervisedAll: 0, // supervised + firstDive + training
  };

  realGroups.forEach((group) => {
    switch (group.diveMode) {
      case 'autonomous': {
        groupsStats.autonomous++;
        break;
      }
      case 'first-dive': {
        groupsStats.firstDive++;
        break;
      }
      case 'supervised': {
        groupsStats.supervised++;
        break;
      }
      case 'training': {
        groupsStats.training++;
        break;
      }
    }
  });

  const unGrouppedParticipants = activeDivers.filter(
    (p) => !p.diveSessionGroupId,
  );

  if (unGrouppedParticipants.length) {
    const autonomousCount = unGrouppedParticipants.filter(
      (participant) => participant.diveMode === 'autonomous',
    ).length;

    const virtualGroupsStats =
      diveSessionVirtualGroupsStatsBuilder.buildVirtualGroupsStats({
        activeDivers: unGrouppedParticipants,
        autonomousCount,
        clubReference,
        assignedFreeInstructorsCount,
      });

    const firstDiveCount = unGrouppedParticipants.filter(
      (participant) => participant.diveMode === 'first-dive',
    ).length;

    groupsStats.type = 'mixed';
    groupsStats.autonomous =
      groupsStats.autonomous + virtualGroupsStats.autonomous;
    groupsStats.supervised =
      groupsStats.supervised + virtualGroupsStats.supervised;
    groupsStats.training = groupsStats.training + virtualGroupsStats.training;

    groupsStats.firstDive = groupsStats.firstDive + firstDiveCount;
  }

  if (groupsStats.autonomous === 1) {
    groupsStats.autonomousWarning = true;
  }

  groupsStats.supervisedAll =
    groupsStats.supervised +
    groupsStats.firstDive +
    groupsStats.training +
    groupsStats.assignedFreeInstructors;

  return groupsStats;
}
