/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  AppEntityUpdatePatch,
  ClubProductPackageMeta,
  ClubProductPackageOffer,
  ClubProductPackageOfferView,
  ClubPurchasePackage,
  ClubPurchasePackageCredits,
  ClubSettings,
} from '@mabadive/app-common-model';
import { repositoryEntityBuilder } from 'src/business/_core/data/repository/repositoryEntityBuilder.service';
import {
  DiverPurchaseCommonEditorDialogResult,
  DiverPurchaseStoreEditorDialogState,
} from '../../models';
import {
  creditsCountBuilder,
  diverPurchasePackageBuilder,
} from '../DiverPurchaseCommonEditorDialog';
import { DiverPurchaseStoreFormModel } from './components';
import { diverPurchaseStoreEditorDialogUpdator } from './diverPurchaseStoreEditorDialogUpdator.service';

export const diverPurchaseStoreEditorDialogUpdateBuilder = {
  buildUpdateResult,
};

function buildUpdateResult({
  clubReference,
  diveCenterId,
  diverId,
  initialPurchasePackage,
  selectedOffer,
  selectedOfferProductPackageMeta,
  formValue,
  isDirty,
  state,
  isPaymentEnabled,
  clubSettings,
}: {
  clubReference: string;
  diveCenterId: string;
  diverId: string;
  initialPurchasePackage: ClubPurchasePackage;
  selectedOffer: ClubProductPackageOfferView;
  selectedOfferProductPackageMeta: ClubProductPackageMeta;
  formValue: DiverPurchaseStoreFormModel;
  isDirty: boolean;
  state: DiverPurchaseStoreEditorDialogState;
  isPaymentEnabled: boolean;
  clubSettings: ClubSettings;
}): {
  hasChanges: boolean;
  result?: DiverPurchaseCommonEditorDialogResult;
} {
  const { creditsInitialCount, creditsAdditionalCount, consumedExternalCount } =
    formValue;

  const consumedAppCount = 0;
  const isUnitOffer = true;
  const { consumedTotalCount, creditsRemainingCount, creditsTotalCount } =
    creditsCountBuilder.updateCounts({
      creditsInitialCount,
      creditsAdditionalCount,
      consumedExternalCount,
      consumedAppCount,
      unitQuantityAvailableInPackage: 1,
    });
  const credits: ClubPurchasePackageCredits = {
    consumedAppCount,
    creditsInitialCount,
    creditsAdditionalCount,
    consumedExternalCount: consumedExternalCount ?? 0,
    creditsTotalCount,
    consumedTotalCount,
    creditsRemainingCount,
  };

  if (state.mode === 'create') {
    const {
      totalPrice,
      unitPrice,
      unitQuantity,
      discountAmount,
      totalPriceBeforeDiscount,
      totalPriceThirdPartyCollect,
    } = diverPurchasePackageBuilder.buildTotalPrice({
      isPaymentEnabled,
      isUnitOffer,
      unitPrice: formValue.unitPrice,
      unitQuantity: formValue.unitQuantity,
      discountAmount: formValue.discountAmount,
      extraCosts: formValue.extraCosts,
      totalPriceThirdPartyCollect: formValue.totalPriceThirdPartyCollect,
    });

    const productPackageOffer: ClubProductPackageOffer = {
      reference: selectedOffer.reference,
      clubReference,
      price: selectedOffer.price,
      productPackage: selectedOffer.productPackage,
      thirdPartyCollectPrice: selectedOffer.thirdPartyCollectPrice,
      agencyNonCommissionable: selectedOffer.agencyNonCommissionable,
    };

    const newPurchasePackage: ClubPurchasePackage = {
      ...repositoryEntityBuilder.buildDefaultAttributes(),
      clubReference,
      diveCenterId,
      diverId,
      diverIds: [diverId],
      productPackageOffer,
      productPackageType: 'store',
      credits,
      purchaseDate: formValue.purchaseDate,
      validityStatus: formValue.validityStatus,
      extraCosts: formValue.extraCosts,
      comment: formValue.comment,
      payedAmount: 0,
      purchasePaymentStatus: 'todo',
      isUnitOffer,
      price: totalPrice,
      unitPrice,
      totalPriceThirdPartyCollect,
      unitQuantity,
      discountAmount,
      totalPriceBeforeDiscount,
      billedByBookingAgencyId: formValue.billedByBookingAgencyId,
      bookingId: formValue.bookingId,
    };

    return {
      hasChanges: true,
      result: {
        newPurchasePackage,
      },
    };
  } else if (state.mode === 'edit') {
    // if (!isDirty) {
    // NOTE: on ne peut plus faire ça, car les updatedProducts ne sont pas gérés par le formulaire! TODO FIXME
    //   return {
    //     hasChanges: false,
    //   };
    // }
    const patchOperations =
      diverPurchaseStoreEditorDialogUpdator.buildUpdatePatches({
        clubReference,
        formValue,
        initialPurchasePackage,
        selectedOffer,
        selectedOfferProductPackageMeta,
        credits,
        isPaymentEnabled,
        associatedDiversIds: [diverId],
      });
    let updatedClubPurchasePackage: AppEntityUpdatePatch;
    if (patchOperations.length) {
      updatedClubPurchasePackage = {
        pk: initialPurchasePackage._id,
        patchOperations,
      };
    }

    if (updatedClubPurchasePackage) {
      return {
        hasChanges: true,
        result: {
          updatedClubPurchasePackage,
        },
      };
    }
  }

  return {
    hasChanges: false,
  };
}
