import React, { useMemo, useState } from 'react';
import { DiverBookingCardPastSessionsTable } from '../DiverBookingSessionsTable';
import { BookingResumeMembersList } from './BookingResumeMembersTable';
import { DiverBookingCardGlobalAddButtons } from './DiverBookingCardGlobalAddButtons';
import { DiverBookingPageBookingTabHeaderAutoFixSplitBookings } from './DiverBookingCardHeaderAutoFix';
import { DiverBookingCard_LocalState } from './useDiverBookingCard_LocalState';

export function DiverBookingCard_TabRegistrationPast({
  localState,
  className,
}: {
  localState: DiverBookingCard_LocalState;
  className?: string;
}) {
  const { state, actions, data } = localState;
  const {
    bookingTabModel,
    booking,
    onlineBookings,
    participantsBySessionsFuture,
    participantsBySessionsPast,
    bookingMembersFull,
  } = data;
  const { autoFixes, globalState, subTab, dialogs } = state;

  const [
    showMembersWithoutFutureParticipants,
    setShowMembersWithoutFutureParticipants,
  ] = useState(true);

  const filteredDiverId = globalState.updateState.filteredDiverId;
  const filteredDiversIds = useMemo(() => {
    if (filteredDiverId) {
      return [filteredDiverId];
    }
    // if (hideMembersWithoutFutureParticipants) {
    //   return futureParticipantsDiverIds;
    // }
    return []; // no filter
  }, [filteredDiverId]);

  return (
    <div className={className}>
      <BookingResumeMembersList
        booking={booking}
        aggregatedBookingResume={bookingTabModel.aggregatedBooking}
        bookingMembersFull={bookingMembersFull}
        globalState={globalState}
        onClickMember={(bookingMemberFull, action) => {
          actions.onClickMember({ bookingMemberFull, action });
        }}
      />
      {participantsBySessionsPast?.length > 0 && (
        <>
          <div>
            <div
              className="my-2 px-2 pt-2 sm:mt-6 pb-2 text-base  flex gap-2 flex-wrap cursor-pointer group hover:bg-gray-50"
              onClick={() => {
                state.selectSubTab('booking-registration');
                actions.scrollToTopWithDelay();
              }}
            >
              <h3 className="uppercase font-bold text-gray-500 group-hover:text-gray-600">
                Inscriptions à venir:
              </h3>
              <div className="font-bold text-app-primary group-hover:text-app-primary-dark">
                {participantsBySessionsFuture.length} sessions
              </div>
            </div>
            <h3 className="mx-2 mt-4 sm:mt-8 mb-4 text-base uppercase font-bold text-gray-500">
              Inscriptions passées:
            </h3>
            <DiverBookingCardPastSessionsTable
              className={'mt-2'}
              // table={table}
              participantsBySessions={participantsBySessionsPast}
              onClickSession={actions.onClickSession}
              onClickParticipant={actions.onClickParticipant}
              globalState={globalState}
              dialogs={dialogs}
              actions={actions}
              bookingTabModel={bookingTabModel}
              futureSessionsCount={state.futureSessionsCount}
              displayName={!state.isUniqueMember}
              displayLastName={!state.isSameLastName}
              displaySessionName={state.displaySessionName}
              displayDiveSite={state.displayDiveSite}
              filteredDiversIds={filteredDiversIds}
            />
          </div>
          {state.bookingViewAuth?.edit && (
            <DiverBookingCardGlobalAddButtons
              className="mt-4 sm:mt-8 mb-4"
              isReverseTheme={true}
              bookingTabModel={bookingTabModel}
              actions={actions}
            />
          )}
        </>
      )}
      {autoFixes.splitBookings && (
        <DiverBookingPageBookingTabHeaderAutoFixSplitBookings
          bookingTabModel={bookingTabModel}
          globalState={globalState}
          dialogs={dialogs}
          actions={actions}
          splitBookings={autoFixes.splitBookings}
          setIsExpanded={state.setIsExpanded}
        />
      )}
    </div>
  );
}
