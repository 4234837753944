import {
  ClubDiverFull,
  DIVE_CERTIFICATIONS_REFERENCE_MAP,
} from '@mabadive/app-common-model';
import {
  dateService,
  emailLinkBuilder,
  nameFormatter,
  phoneLinkBuilder,
  smsLinkBuilder,
} from '@mabadive/app-common-services';
import React, { useMemo } from 'react';
import { useRedirect } from 'src/business/_core/data/hooks';
import { AppButton } from 'src/business/_core/modules/layout';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { AppDateTimeLabel } from 'src/business/club/modules/_common/ui';

import { clubParticipantUrlBuilder } from 'src/business/club/modules/url-builders';
import { AppLinkStopPropagation } from 'src/lib/browser';

export function ClubDiversListTableRow({ diver }: { diver: ClubDiverFull }) {
  const url = useMemo(() => {
    const url = clubParticipantUrlBuilder.buildTabUrl({
      tabId: 'edit-participant',
      navigationContext: {
        origin: 'divers',
        diveSessionReference: undefined,
        diverId: diver._id,
      },
    });
    return url;
  }, [diver._id]);

  const mainCertification = useMemo(
    () =>
      !diver.mainCertificationReference
        ? undefined
        : DIVE_CERTIFICATIONS_REFERENCE_MAP[diver.mainCertificationReference],
    [diver.mainCertificationReference],
  );

  const redirectTo = useRedirect();

  const emailLink = useMemo(
    () =>
      emailLinkBuilder.buildLink({
        to: [diver.emailAddress],
        // subject: '',
        // body: '',
      }),
    [diver.emailAddress],
  );
  const phoneNumber = useMemo(
    () =>
      smsLinkBuilder.cleanPhoneNumber(diver.phoneNumber ?? diver.phoneNumber2),
    [diver.phoneNumber, diver.phoneNumber2],
  );
  const phoneLink = useMemo(
    () =>
      phoneLinkBuilder.buildLink({
        phoneNumber,
      }),
    [phoneNumber],
  );

  return (
    <tr
      className={'app-tr-highlight'}
      onClick={() => {
        // si on clique sur la ligne, mais en dehors d'un lien, on est quand même redirigé
        redirectTo(url);
      }}
    >
      <td className="px-2 py-1 text-gray-600 font-bold">
        <AppLinkStopPropagation className="block px-2 py-1" to={url}>
          <div className="leading-4">
            {nameFormatter.formatFullName(diver, {
              format: 'lastName-first',
            })}
          </div>
          {(diver.birthdate || mainCertification?.reference) && (
            <div className="text-xs font-normal md:hidden flex gap-1">
              {mainCertification?.reference && (
                <span className="font-bold text-app-primary">
                  {mainCertification?.reference}
                </span>
              )}
              {diver.birthdate &&
                dateService.formatUTC(diver.birthdate, 'DD/MM/YYYY')}
            </div>
          )}
        </AppLinkStopPropagation>
      </td>
      <td className="whitespace-nowrap text-sm font-bold text-gray-600 hidden md:table-cell">
        <AppLinkStopPropagation className="block px-2 py-1" to={url}>
          {mainCertification ? mainCertification.reference : null}
        </AppLinkStopPropagation>
      </td>
      <td className=" hidden md:table-cell">
        <AppLinkStopPropagation className="block px-2 py-1" to={url}>
          {diver.birthdate
            ? dateService.formatUTC(diver.birthdate, 'DD/MM/YYYY')
            : false}
        </AppLinkStopPropagation>
      </td>
      <td className="whitespace-nowrap  hidden lg:table-cell">
        <AppLinkStopPropagation className="block px-2 py-1" to={url}>
          <AppDateTimeLabel
            timezone="local"
            date={diver.divesMeta?.lastDiveTime}
            format="date"
          />
        </AppLinkStopPropagation>
      </td>
      <td className="whitespace-nowrap  hidden lg:table-cell">
        <AppLinkStopPropagation className="block px-2 py-1" to={url}>
          <AppDateTimeLabel
            timezone="local"
            date={diver.divesMeta?.nextDiveTime}
            format="date"
          />
        </AppLinkStopPropagation>
      </td>
      <td className=" truncate hidden xs:table-cell">
        <AppLinkStopPropagation className="block px-2 py-1" to={url}>
          {emailLink && (
            <div className="flex-1">
              <AppButton
                fullWidth={true}
                size="small"
                className="flex-1 truncate text-ellipsis"
                icon={AppHeroIcons.mail}
                color="mail-outline"
                href={emailLink}
                target="mabadive_mail_client"
                onClick={(e) => e.stopPropagation()}
              >
                <span className="hidden sm:inline break-all">
                  {diver.emailAddress}
                </span>
                <span className="whitespace-nowrap text-xs sm:text-sm sm:hidden -ml-1 sm:ml-0">
                  E-mail
                </span>
              </AppButton>
            </div>
          )}
        </AppLinkStopPropagation>
      </td>
      <td className="whitespace-nowrap text-sm hidden xs:table-cell">
        <AppLinkStopPropagation className="block px-2 py-1" to={url}>
          {phoneLink && (
            <div className="flex-1">
              <AppButton
                fullWidth={true}
                size="small"
                className="flex-1 truncate text-ellipsis"
                icon={AppHeroIcons.phone}
                color="phone-outline"
                href={phoneLink}
                target="mabadive_phone_client"
                onClick={(e) => e.stopPropagation()}
              >
                <span className="hidden sm:inline break-all">
                  {phoneLinkBuilder.formatPhoneNumber(phoneNumber)}
                </span>
                <span className="whitespace-nowrap text-xs sm:text-sm sm:hidden -ml-1 sm:ml-0">
                  Appeler
                </span>
              </AppButton>
            </div>
          )}
        </AppLinkStopPropagation>
      </td>
      {/* <td className={`whitespace-nowrap text-sm hidden lg:table-cell`}>
        <AppLinkStopPropagation className="block px-2 py-1" to={url}>
          <AppDateTimeLabel
            date={diver._createdAt}
            timezone="local"
            format="date-time"
          />
        </AppLinkStopPropagation>
      </td> */}
      <td className={'whitespace-nowrap text-sm hidden lg:table-cell'}>
        <AppLinkStopPropagation className="block px-2 py-1" to={url}>
          <AppDateTimeLabel
            timezone="utc"
            date={diver.divesMeta?.lastUpdatedDiveTime}
            format="date-time"
          />
        </AppLinkStopPropagation>
      </td>
    </tr>
  );
}
