/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  AppEntityUpdatePatch,
  BookingProduct,
  ClubProductPackageOffer,
  ClubProductPackageOfferView,
  ClubPurchasePackage,
  ClubPurchasePackageCredits,
  ClubSettings,
} from '@mabadive/app-common-model';
import { repositoryEntityBuilder } from 'src/business/_core/data/repository/repositoryEntityBuilder.service';
import {
  BillingTabDiveSessionBillingResume,
  DiverPurchaseCommonEditorDialogResult,
  DiverPurchaseTrainingEditorDialogState,
} from '../../models';
import { bookingPagePackageConsumedCounter } from '../../services/02.update-state/services/bookingPagePackageConsumedCounter.service';
import {
  creditsCountBuilder,
  diverPurchasePackageBuilder,
} from '../DiverPurchaseCommonEditorDialog';
import { DiverPurchaseTrainingFormModel } from './components';
import { diverPurchaseTrainingEditorDialogUpdator } from './diverPurchaseTrainingEditorDialogUpdator.service';

export const diverPurchaseTrainingEditorDialogUpdateBuilder = {
  buildUpdateResult,
};

function buildUpdateResult({
  clubReference,
  diveCenterId,
  diverId,
  initialPurchasePackage,
  selectedOffer,
  formValue,
  isDirty,
  state,
  updatedAssociatedBookingProductIds,
  initialDiveBookingProducts,
  isPaymentEnabled,
  clubSettings,
  billingResumes,
}: {
  clubReference: string;
  diveCenterId: string;
  diverId: string;
  initialPurchasePackage: ClubPurchasePackage;
  selectedOffer: ClubProductPackageOfferView;
  formValue: DiverPurchaseTrainingFormModel;
  isDirty: boolean;
  state: DiverPurchaseTrainingEditorDialogState;
  updatedAssociatedBookingProductIds: string[];
  initialDiveBookingProducts: BookingProduct[];
  isPaymentEnabled: boolean;
  clubSettings: ClubSettings;
  billingResumes: BillingTabDiveSessionBillingResume[];
}): {
  hasChanges: boolean;
  result?: DiverPurchaseCommonEditorDialogResult;
} {
  const isUnitOffer = false;
  const { creditsInitialCount, creditsAdditionalCount, consumedExternalCount } =
    formValue;
  // const consumedAppCount = updatedAssociatedBookingProductIds.length;

  const { associatedDiversIds, consumedAppCount } =
    bookingPagePackageConsumedCounter.buildConsumedAppCountOnBuildUpdateResume({
      clubSettings,
      billingResumes,
      assignedBookingProductsIds: updatedAssociatedBookingProductIds,
      diverId,
      countSuccessiveAsSingle: false, // pas pertinent pour une formation
    });

  const { consumedTotalCount, creditsRemainingCount, creditsTotalCount } =
    creditsCountBuilder.updateCounts({
      creditsInitialCount,
      creditsAdditionalCount,
      consumedExternalCount,
      consumedAppCount,
      unitQuantityAvailableInPackage: 1,
    });
  const credits: ClubPurchasePackageCredits = {
    consumedAppCount,
    creditsInitialCount,
    creditsAdditionalCount,
    consumedExternalCount: consumedExternalCount ?? 0,
    creditsTotalCount,
    consumedTotalCount,
    creditsRemainingCount,
  };

  if (state.mode === 'create') {
    const productPackageOfferReference = formValue.productPackageOfferReference;

    const {
      totalPrice,
      unitPrice,
      unitQuantity,
      discountAmount,
      totalPriceBeforeDiscount,
      totalPriceThirdPartyCollect,
    } = diverPurchasePackageBuilder.buildTotalPrice({
      isPaymentEnabled,
      isUnitOffer,
      unitPrice: formValue.unitPrice,
      // unitQuantity: formValue.unitQuantity,
      discountAmount: formValue.discountAmount,
      extraCosts: formValue.extraCosts,
      totalPriceThirdPartyCollect: formValue.totalPriceThirdPartyCollect,
    });

    const productPackageOffer: ClubProductPackageOffer = {
      reference: selectedOffer.reference,
      clubReference,
      price: isPaymentEnabled ? selectedOffer.price : undefined,
      productPackage: selectedOffer.productPackage,
      thirdPartyCollectPrice: selectedOffer.thirdPartyCollectPrice,
      agencyNonCommissionable: selectedOffer.agencyNonCommissionable,
    };

    const newPurchasePackage: ClubPurchasePackage = {
      ...repositoryEntityBuilder.buildDefaultAttributes(),
      clubReference,
      diveCenterId,
      diverId,
      diverIds: associatedDiversIds,
      productPackageOffer,
      productPackageType: 'training',
      credits,
      purchaseDate: formValue.purchaseDate,
      validityStatus: formValue.validityStatus,
      comment: formValue.comment,
      isUnitOffer,
      price: totalPrice,
      unitPrice,
      totalPriceThirdPartyCollect,
      unitQuantity,
      discountAmount,
      extraCosts: formValue.extraCosts,
      totalPriceBeforeDiscount,
      payedAmount: 0,
      purchasePaymentStatus: 'todo',
      billedByBookingAgencyId: formValue.billedByBookingAgencyId,
      bookingId: formValue.bookingId,
    };
    const updatedProducts: AppEntityUpdatePatch[] =
      diverPurchaseTrainingEditorDialogUpdator.buildUpdatedProductPatches({
        initialDiveBookingProducts,
        updatedAssociatedBookingProductIds,
        purchasePackageId: newPurchasePackage._id,
      });
    return {
      hasChanges: true,
      result: {
        newPurchasePackage,
        updatedProducts,
      },
    };
  } else if (state.mode === 'edit') {
    // if (!isDirty) {
    // NOTE: on ne peut plus faire ça, car les updatedProducts ne sont pas gérés par le formulaire! TODO FIXME
    //   return {
    //     hasChanges: false,
    //   };
    // }

    const patchOperations =
      diverPurchaseTrainingEditorDialogUpdator.buildUpdatePatches({
        clubReference,
        formValue,
        initialPurchasePackage,
        selectedOffer,
        isPaymentEnabled,
        credits,
        associatedDiversIds,
      });
    let updatedClubPurchasePackage: AppEntityUpdatePatch;
    if (patchOperations.length) {
      updatedClubPurchasePackage = {
        pk: initialPurchasePackage._id,
        patchOperations,
      };
    }

    const updatedProducts: AppEntityUpdatePatch[] =
      diverPurchaseTrainingEditorDialogUpdator.buildUpdatedProductPatches({
        initialDiveBookingProducts,
        updatedAssociatedBookingProductIds,
        purchasePackageId: initialPurchasePackage._id,
      });
    if (updatedClubPurchasePackage || updatedProducts.length) {
      return {
        hasChanges: true,
        result: {
          updatedClubPurchasePackage,
          updatedProducts,
        },
      };
    }
  }

  return {
    hasChanges: false,
  };
}
