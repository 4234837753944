import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useRedirect } from 'src/business/_core/data/hooks';
import { appRouteBuilder } from 'src/business/_core/modules/root/pages';
import {
  authenticationClient,
  useAppSecurityIsAuthenticated,
} from '../../services';

export type ResetPasswordFormData = {
  email: string;
};

const VALIDATION_EMAIL_PATTERN_ALLOW_TRAILING_LEADING_SPACES =
  /^\s*\S+@\S+\.\S+\s*$/; // https://stackoverflow.com/a/201447 + https://stackoverflow.com/a/22025206

export const ResetPasswordQueryPage = () => {
  const redirectTo = useRedirect();

  const [emailResetSent, setEmailResetSent] = useState<string>();

  const form = useForm<ResetPasswordFormData>({
    defaultValues: {},
    mode: 'onChange',
  });

  const { control, register, handleSubmit, reset, watch, trigger, formState } =
    form;

  const isAuthenticated = useAppSecurityIsAuthenticated();

  useEffect(() => {
    if (isAuthenticated) {
      appRouteBuilder
        .getDefaultRoute()
        .toPromise()
        .then((url) => {
          redirectTo(url);
        });
    }
  }, [isAuthenticated, redirectTo]);

  function onSubmit() {
    handleSubmit(
      (formValue: ResetPasswordFormData) => {
        authenticationClient
          .resetPasswordQueryPromise({ login: formValue.email })
          .then(
            () => {
              setEmailResetSent(formValue.email);
              reset();
            },
            (err) => {
              // TODO afficher un message d'erreur
              console.log('xxx err:', err);
            },
          );
      },
      (errors, event) => {
        console.log('xxx invalid form', errors, event);
      },
    )();
  }

  return (
    <div className="min-h-full mx-auto md:min-h-0 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div
        className={`sm:mx-auto sm:w-full ${
          emailResetSent ? 'sm:max-w-lg' : 'sm:max-w-md'
        }`}
      >
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900 uppercase">
          Espace PRO
        </h2>
        <h3 className="mt-2 text-center text-xl font-extrabold text-gray-600 uppercase">
          Mot de passe oublié
        </h3>
        <div className={'mt-8 sm:mx-auto w-full'}>
          {!emailResetSent ? (
            <>
              <form
                className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10 space-y-6"
                onSubmit={(e) => {
                  e.preventDefault();
                  onSubmit();
                }}
              >
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Identifiant
                  </label>
                  <div className="mt-1">
                    <input
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-app-blue focus:border-app-blue sm:text-sm"
                      type="text"
                      autoComplete="username"
                      {...register('email', {
                        // c'est le login, pas forcément un email
                        // pattern:
                        //   VALIDATION_EMAIL_PATTERN_ALLOW_TRAILING_LEADING_SPACES,
                      })}
                    />
                  </div>
                </div>

                <p className="py-2 text-sm text-gray-600">
                  Entrez votre adresse email pour ré-initialiser le mot de
                  passe.
                </p>

                <div>
                  <button
                    type="submit"
                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-app-blue hover:bg-app-blue3 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-app-blue"
                  >
                    Ré-initialiser
                  </button>
                </div>
              </form>
              <div className="mt-8 w-full">
                <div className="mt-2 text-center text-sm text-gray-600">
                  <div>{'Vous vous souvenez de votre mot de passe?'}</div>
                  <button
                    onClick={() => {
                      redirectTo(appRouteBuilder.getLoginUrl());
                    }}
                    className="mt-2 font-bold text-app-blue hover:text-app-blue "
                  >
                    Se connecter
                  </button>
                </div>
                <div className="mt-4 text-center text-sm text-gray-600">
                  <div>{'Vous n\'avez pas encore de compte?'}</div>
                  <button
                    onClick={() => {
                      redirectTo(appRouteBuilder.getSigningUrl());
                    }}
                    className="mt-2 font-bold text-app-blue hover:text-app-blue "
                  >
                    Créez votre compte PRO
                  </button>
                </div>
              </div>
            </>
          ) : (
            <div className="text-left text-sm text-gray-600">
              <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                <h2 className="font-bold text-base text-app-success">
                  Votre demande a été traitée avec succès !
                </h2>
                <p className="mt-2 text-base text-gray-800">
                  Un e-mail vous a été envoyé avec un lien pour ré-initialiser
                  votre mot de passe.
                </p>
                <p className="mt-2 text-base font-bold text-gray-600">
                  Veuillez consulter votre boite de réception.
                </p>
                <div className="mt-8 w-full">
                  <h3 className="font-bold text-sm text-gray-600">
                    Vous n’avez pas reçu l’e-mail de ré-initialisation ?
                  </h3>
                  <ul className="mt-1 ml-4 list-disc text-sm text-gray-600">
                    <li>Vérifiez votre courrier indésirable.</li>
                    <li>
                      Vérifiez que l’adresse e-mail que vous avez indiquée (
                      <b>{emailResetSent}</b>) correspond bien à l’identifiant
                      de votre compte.
                    </li>
                    <li>Vous n’avez pas encore de compte, créez en un !</li>
                  </ul>
                </div>
              </div>
              <div className="mt-8 text-center text-sm text-gray-600">
                <button
                  onClick={() => {
                    redirectTo(appRouteBuilder.getLoginUrl());
                  }}
                  className="mt-2 font-bold text-app-blue hover:text-app-blue "
                >
                  Retour
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
