import { dateService, nameFormatter } from '@mabadive/app-common-services';
import Tippy from '@tippyjs/react';
import clsx from 'clsx';
import React, { useCallback, useMemo } from 'react';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { useAppBookingSessionAuth } from 'src/business/auth/services';
import {
  AggregatedBookingSessionFull,
  PRO_BookingMemberFull_WithDocs,
  PRO_BookingParticipantFull,
} from '../../../models';
import { ParticipantCellBadge } from '../../DiverBookingSessionsTable';

export function BookingMassiveEditorSessionsTableParticipantCell({
  bookingParticipantFull: bookingParticipantFullSameBooking,
  bookingParticipantsFullAllBookings,
  bookingMemberFull,
  bookingSessionFull,
  displayName = false,
  displayLastName = false,
  theme,
  style,
  className,
  onClick,
  children,
}: {
  bookingParticipantsFullAllBookings: PRO_BookingParticipantFull[];
  bookingParticipantFull?: PRO_BookingParticipantFull;
  bookingMemberFull: PRO_BookingMemberFull_WithDocs;
  bookingSessionFull: AggregatedBookingSessionFull;
  displayName?: boolean;
  displayLastName?: boolean;
  theme: 'light' | 'normal';
  style?: 'normal' | 'highlight-light' | 'highlight-strong';
  children?: React.ReactNode | React.ReactNode[];
  className?: string;
  onClick?: (
    attrs: {
      bookingParticipantFull?: PRO_BookingParticipantFull;
      bookingSessionFull: AggregatedBookingSessionFull;
      bookingMemberFull: PRO_BookingMemberFull_WithDocs;
    },
    origin: 'cell' | 'checkbox',
  ) => void;
}) {
  const bookingParticipantFull: PRO_BookingParticipantFull = useMemo(() => {
    if (bookingParticipantFullSameBooking) {
      return bookingParticipantFullSameBooking;
    }

    // try to find this participant in an other booking!
    const otherBookingParticipantFull = bookingParticipantsFullAllBookings.find(
      (x) =>
        x.diver._id === bookingMemberFull.diver._id &&
        x.diveSession.reference === bookingSessionFull.diveSession.reference,
    );

    return otherBookingParticipantFull;
  }, [
    bookingParticipantFullSameBooking,
    bookingParticipantsFullAllBookings,
    bookingSessionFull.diveSession.reference,
    bookingMemberFull.diver._id,
  ]);

  const isPast = useMemo(
    () =>
      dateService.isPastUTC(bookingSessionFull.diveSession.time) &&
      !dateService.isTodayUTC(bookingSessionFull.diveSession.time),
    [bookingSessionFull.diveSession.time],
  );

  const bookingSessionAuth = useAppBookingSessionAuth({
    bookingSessionDiveCenterId:
      bookingSessionFull?.bookingSession?.diveCenterId,
  });

  const onClickInner = useCallback(
    (origin: 'cell' | 'checkbox') => {
      if (bookingSessionAuth.edit) {
        if (bookingParticipantFullSameBooking) {
          onClick(
            {
              bookingParticipantFull: bookingParticipantFullSameBooking,
              bookingSessionFull,
              bookingMemberFull,
            },
            origin,
          );
        } else if (bookingParticipantFull) {
          throw new Error('Invalid booking edition');
        } else {
          onClick(
            {
              bookingSessionFull,
              bookingMemberFull,
            },
            origin,
          );
        }
      }
    },
    [
      bookingMemberFull,
      bookingParticipantFull,
      bookingParticipantFullSameBooking,
      bookingSessionAuth.edit,
      bookingSessionFull,
      onClick,
    ],
  );

  const participant = bookingParticipantFull?.diveSessionParticipant;

  const diveSessionStatus = useMemo(
    () =>
      participant && participant.bookingState.value === 'cancelled'
        ? 'cancelled'
        : bookingSessionFull.diveSession.status,
    [bookingSessionFull.diveSession.status, participant],
  );

  const { firstName, lastName } = useMemo(
    () => nameFormatter.formatFullNameChunks(bookingMemberFull?.diver),
    [bookingMemberFull?.diver],
  );

  return (
    <Tippy
      delay={[1000, 100]}
      placement="top"
      content={nameFormatter.formatFullName(bookingMemberFull.diver, {
        format: 'firstName-first',
      })}
    >
      <div
        className={clsx(
          'h-[4.70rem] p-0.5 relative group flex flex-col gap-1 ',
          bookingParticipantFull &&
            !bookingParticipantFullSameBooking &&
            'opacity-50',
          !bookingSessionAuth.edit
            ? 'ring ring-gray-400 ring-opacity-20'
            : bookingParticipantFull?.entityState === 'created'
            ? 'ring ring-status-success hover:ring-status-success-dark'
            : !bookingParticipantFull &&
              bookingSessionFull.entityState === 'created'
            ? 'ring ring-status-info hover:ring-status-info-dark'
            : style === 'highlight-light'
            ? 'ring ring-status-info hover:ring-status-info-dark ring-opacity-30'
            : style === 'highlight-strong'
            ? 'ring ring-status-info hover:ring-status-info-dark'
            : 'ring ring-gray-400 hover:ring-opacity-100 ring-opacity-20',
          bookingSessionAuth.edit && 'cursor-pointer',
          className,
        )}
      >
        <div
          onClick={() => onClickInner('checkbox')}
          className={clsx(
            'flex-grow flex items-center gap-2 font-bold text-xs leading-4 sm:leading-4 pb-1 text-center',
            bookingParticipantFull ? 'text-gray-500' : 'text-gray-400',
            diveSessionStatus === 'cancelled' && 'line-through',
            bookingSessionAuth.edit && 'group-hover:text-gray-600',
          )}
        >
          {bookingSessionAuth.edit && (
            <input
              className="ml-0.5 text-gray-500 ring-1 ring-white"
              type="checkbox"
              readOnly={true}
              checked={!!bookingParticipantFull}
            />
          )}
          {displayName && (
            <div className="truncate">
              {firstName}
              &nbsp;
              {displayLastName && lastName}
            </div>
          )}
        </div>

        <div className="flex items-end gap-1 relative">
          {bookingParticipantFull ? (
            <ParticipantCellBadge
              onClick={() => onClickInner('cell')}
              participant={participant}
              diver={bookingParticipantFull.diver}
              diveSession={bookingSessionFull.diveSession}
              theme={theme}
              includeBookingStateTag={true}
              includeParticipantSessionNumber={true}
            />
          ) : (
            <div
              onClick={() => onClickInner('cell')}
              className={clsx(
                'w-full h-8 flex justify-center items-center rounded',
                bookingSessionAuth.edit && 'border',
                isPast
                  ? 'border-gray-300'
                  : `border-dive-mode-${
                      bookingMemberFull.diver.defaultDiveConfig?.diveMode ??
                      'observer'
                    }`,
              )}
            >
              <div
                className={clsx(
                  'inline-block w-5 h-5 rounded-xl',
                  !bookingSessionAuth.edit
                    ? 'bg-white'
                    : isPast
                    ? 'bg-gray-300 group-hover:bg-app-blue'
                    : bookingSessionFull.entityState === 'created'
                    ? 'bg-status-info-light opacity-50 group-hover:opacity-100 group-hover:bg-status-info-dark'
                    : 'bg-gray-400 group-hover:bg-app-blue',
                )}
              >
                <AppHeroIcons.actionAdd className="w-full h-full fill-current text-white" />
              </div>
            </div>
          )}
          {children && children}
        </div>
      </div>
    </Tippy>
  );
}
