/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useMemo } from 'react';
import { UseFormReturn, useFieldArray, useWatch } from 'react-hook-form';
import { AppInputRHF } from 'src/lib/form';
import { AppFormControlRHF } from 'src/lib/form/components/AppFormControl/AppFormControlRHF';

import {
  ClubPublicSettingsServices,
  ClubSettings,
  FIRST_DIVES,
  FIRST_DIVE_TRAININGS_REFERENCES,
} from '@mabadive/app-common-model';
import Tippy from '@tippyjs/react';
import clsx from 'clsx';
import { AppButton } from 'src/business/_core/modules/layout';
import {
  AppHeroIcons,
  AppIconsAction,
} from 'src/business/_core/modules/layout/icons';
import { useAppSecurityUserHasRole } from 'src/business/auth/services';
import { useClubSettings } from 'src/business/club/data/hooks';
import { AppSingleSelect2HeadlessUIRHF } from 'src/lib/form/components/AppSingleSelect/AppSingleSelect2HeadlessUIRHF';
import { ClubSettingsSection } from 'src/pages/SE-settings/_core';
import { useFirstDiveTrainingOptions } from 'src/pages/_components/options';
import { ClubSettingsServicesFormModel } from '../../../_model';
// const FIRST_DIVE_TRAINING_REFERENCES_OPTIONS =
//   FIRST_DIVE_TRAININGS_REFERENCES.map((reference) => {
//     const option: ValueLabel<FirstDiveTrainingReference> = {
//       label: diveServiceFirstDiveFormatter.formatLabel(reference),
//       value: reference,
//       disabled: reference === 'BPT',
//     };
//     return option;
//   });

const REF_INDEXES = FIRST_DIVE_TRAININGS_REFERENCES.map((ref, i) => {
  const firstDive = FIRST_DIVES.find((x) => x.reference === ref);
  return {
    index: i,
    originalRef: ref,
    labelShort: ref,
    labelLong: firstDive.label,
  };
});

export const ClubSettingsServicesFirstDiveFormNames = ({
  form,
}: {
  form: UseFormReturn<ClubSettingsServicesFormModel>;
}) => {
  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const isSuperAdmin = useAppSecurityUserHasRole('super-admin');

  const [
    clubSettingsFirstDiveEnabled,
    firstDiveTrainingReferences,
    firstDivesCustomize,
    firstDivesCustomTypes,
  ] = useWatch({
    control,
    name: [
      'clubSettings.firstDive.enabled',
      'clubSettings.firstDive.trainingReferences',
      'clubSettings.publicSettings.services.firstDives.customize',
      'clubSettings.publicSettings.services.firstDives.customTypes',
    ],
  });

  const clubSettingsInput = useClubSettings();

  const clubSettings = useMemo(() => {
    const services: ClubPublicSettingsServices =
      clubSettingsInput.publicSettings.services;
    const clubSettings: ClubSettings = {
      ...clubSettingsInput,
      firstDive: {
        ...clubSettingsInput.firstDive,
        trainingReferences: firstDiveTrainingReferences,
      },
      publicSettings: {
        ...clubSettingsInput.publicSettings,
        services: {
          ...services,
          firstDives: {
            ...services.firstDives,
            customize: firstDivesCustomize,
            customTypes: firstDivesCustomTypes,
          },
        },
      },
    };

    return clubSettings;
  }, [
    clubSettingsInput,
    firstDiveTrainingReferences,
    firstDivesCustomTypes,
    firstDivesCustomize,
  ]);

  const firstDiveTrainingOptions = useFirstDiveTrainingOptions(clubSettings, {
    scope: 'all',
  });

  const selectedFirstDiveTrainingOptions = useFirstDiveTrainingOptions(
    clubSettings,
    {
      scope: 'enabled',
    },
  );

  const customTypesFieldArray = useFieldArray({
    control,
    name: 'clubSettings.publicSettings.services.firstDives.customTypes',
  });

  const nextRefToAdd = useMemo(
    () =>
      firstDiveTrainingOptions.find(
        (x) => !firstDiveTrainingReferences.includes(x.value),
      )?.value,
    [firstDiveTrainingOptions, firstDiveTrainingReferences],
  );

  return (
    <ClubSettingsSection title="Types de baptêmes">
      <div className="grid gap-4">
        <div className="grid gap-2">
          {firstDivesCustomize && firstDivesCustomTypes && (
            <div>
              {customTypesFieldArray.fields.map((field, i) => (
                <div
                  key={field.originalRef}
                  className={clsx(
                    'flex gap-4',
                    firstDiveTrainingReferences.includes(field.originalRef)
                      ? ''
                      : 'hidden',
                  )}
                >
                  <AppFormControlRHF
                    label={`Diminutif BPT ${i + 1}`}
                    control={control}
                    required={true}
                    name={`clubSettings.publicSettings.services.firstDives.customTypes.${i}.labelShort`}
                    validation={{
                      rules: {
                        maxLength: 7,
                      },
                    }}
                    renderComponent={(props) => (
                      <AppInputRHF {...props} fullWidth type="text" />
                    )}
                  />
                  <AppFormControlRHF
                    label={'Nom'}
                    control={control}
                    required={true}
                    name={`clubSettings.publicSettings.services.firstDives.customTypes.${i}.labelLong`}
                    renderComponent={(props) => (
                      <AppInputRHF {...props} fullWidth type="text" />
                    )}
                  />
                  <AppFormControlRHF
                    label={'Durée (mn)'}
                    control={control}
                    required={false}
                    name={`clubSettings.publicSettings.services.firstDives.customTypes.${i}.durationInMn`}
                    validation={{
                      rules: {
                        min: 1,
                        max: 120,
                      },
                    }}
                    renderComponent={(props) => (
                      <AppInputRHF {...props} fullWidth type="number" />
                    )}
                  />
                  <div className="flex gap-2 md:gap-6">
                    <Tippy
                      delay={[1000, 100]}
                      placement="top"
                      content={'Supprimer'}
                    >
                      <AppIconsAction.delete
                        className={clsx(
                          'p-1 w-8 h-8 bg-red-200 hover:bg-red-600 rounded-full text-white cursor-pointer',
                          firstDiveTrainingReferences.length === 1 && 'hidden',
                        )}
                        onClick={() => {
                          setValue(
                            'clubSettings.firstDive.trainingReferences',
                            firstDiveTrainingReferences.filter(
                              (x) => !!x && x !== field.originalRef,
                            ),
                          );
                          // onClick('delete');
                        }}
                      />
                    </Tippy>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="flex flex-wrap gap-4 items-end">
          <AppFormControlRHF
            className="w-60"
            control={control}
            label={'Baptême par défaut'}
            name="clubSettings.publicSettings.services.firstDives.defaultFirstDiveTrainingReference"
            required={true}
            renderComponent={(props) => (
              <AppSingleSelect2HeadlessUIRHF
                {...props}
                disableClearButton={true}
                theme={'tailwind'}
                options={selectedFirstDiveTrainingOptions}
              />
            )}
          />
          {nextRefToAdd && (
            <AppButton
              color="primary-outline-light"
              size="normal"
              icon={AppHeroIcons.actionAdd}
              onClick={() => {
                //
                setValue(
                  'clubSettings.firstDive.trainingReferences',
                  firstDiveTrainingReferences.concat(nextRefToAdd),
                );
              }}
            >
              Ajouter un type de baptême
            </AppButton>
          )}
        </div>
      </div>
      {/* {clubSettingsFirstDiveEnabled && (
        <div className="ml-2">
          <AppFormControlRHF
            label={'Types de baptême'}
            control={control}
            name="clubSettings.firstDive.trainingReferences"
            renderComponent={(props) => (
              // <AppComboBoxMultipleRHF
              //     {...props}
              //   options={firstDiveTrainingOptions}
              // />
              <AppMultiCheckboxesRHF
                {...props}
                className="flex flex-wrap text-gray-600 text-app-xxs"
                options={firstDiveTrainingOptions}
                onChange={(trainingReferences) => {
                  updateCustomTypesIfNecessary({
                    firstDivesCustomize,
                    firstDivesCustomTypes,
                  });
                }}
              />
            )}
          />
        </div>
      )} */}
    </ClubSettingsSection>
  );
};
