import { useMemo } from 'react';
import { ConfigAssistantProgressStep } from './SE-99_config-assistant/model';

export function useConfigAssistantProgressStepsPercent({
  progressSteps,
}: {
  progressSteps: ConfigAssistantProgressStep[];
}): number {
  const progressStepsPercent = useMemo(() => {
    const enabledSteps = progressSteps.filter((step) => step.progress.enabled);
    if (enabledSteps.length === 0) return 0;
    const completedSteps = enabledSteps.filter(
      (step) => step.progress.configSet,
    );
    return Math.round((completedSteps.length / enabledSteps.length) * 100);
  }, [progressSteps]);
  return progressStepsPercent;
}
