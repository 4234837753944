import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import React from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { useClubResume, useClubSettings } from 'src/business/club/data/hooks';
import {
  AppFormControlRHF,
  AppInputBooleanRHF,
  AppInputBooleanSwitchRHF,
} from 'src/lib/form';
import { useMediaSizeTailwind } from '../../../../../../business/_core/modules/layout';
import { CustomerSpaceConfigFormModel } from '../model';

// const CLUB_SETTINGS_UI_PLANNING_SHOW_PASS_MODES_OPTIONS =
//   CLUB_SETTINGS_UI_PLANNING_SHOW_PASS_MODES.map((value) => {
//     const option: ValueLabel<ClubSettingsUIPlanningShowPassMode> = {
//       label: (value === 'consumed'
//         ? 'consommé (8/10)'
//         : 'restant (2)'
//       ).toUpperCase(),
//       value,
//     };
//     return option;
//   });

export function CustomerSpaceConfigFormCustomerUpdate_HomeAddress({
  form,
  displayExpertMode,
}: {
  form: UseFormReturn<CustomerSpaceConfigFormModel>;
  displayExpertMode: boolean;
}) {
  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const clubResume = useClubResume();
  const customerSettings = clubResume?.clubSettings?.customer;
  const clubReference = clubResume.reference;
  const formPrefix = 'clubSettings.publicSettings.customerSpace' as const;
  const clubSettings = useClubSettings();

  const [diversPostalAddressInfo] = useWatch({
    control,
    name: [
      `${formPrefix}.defaultBookingConfig.pendingList.diversPostalAddressInfo`,
    ],
  });

  const mediaSize = useMediaSizeTailwind();
  const isSmallWidth = mediaSize === 'xxs' || mediaSize === 'xs';

  return (
    <Disclosure defaultOpen={!isSmallWidth}>
      {({ open }) => (
        <div className={clsx(open && 'border-l-4 border-gray-200')}>
          <Disclosure.Button
            className={clsx(
              'flex w-full justify-between rounded-lg text-left text-sm font-medium',
              open &&
                'focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75',
            )}
          >
            <div onClick={(e) => e.stopPropagation()}>
              <AppFormControlRHF
                className="my-1 w-full xs:col-span-6 sm:col-span-6"
                control={control}
                name={`${formPrefix}.defaultBookingConfig.pendingList.diversPostalAddressInfo`}
                renderComponent={(props) => (
                  <AppInputBooleanSwitchRHF
                    className="w-full"
                    {...props}
                    label={'adresse postale'}
                    // label2={`Baptềme, explo...`}
                    theme="success"
                    onChange={(value) => {
                      if (value) {
                        setValue(
                          `${formPrefix}.defaultBookingConfig.customerUi.enableCustomerUpdate`,
                          value,
                        );
                      }
                    }}
                  />
                )}
              />
            </div>
            {diversPostalAddressInfo && (
              <div className="flex gap-2 text-gray-600 ">
                <span>Configuration avancée</span>
                <ChevronUpIcon
                  className={`${
                    open ? 'rotate-180 transform' : ''
                  } h-5 w-5 text-gray-500`}
                />
              </div>
            )}
          </Disclosure.Button>

          <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
            {diversPostalAddressInfo && (
              <div className="mb-3 w-full grid grid-cols-2 sm:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-x-4 gap-y-2">
                <div className="flex flex-col gap-2">
                  <AppFormControlRHF
                    control={control}
                    label="N° et rue"
                    name={`${formPrefix}.displayFields.diversPostalAddressInfo.line1.edit`}
                    renderComponent={(props) => (
                      <AppInputBooleanSwitchRHF {...props} />
                    )}
                  />
                  {displayExpertMode && (
                    <AppFormControlRHF
                      control={control}
                      // labelhasCertificationRequis?"
                      name={`${formPrefix}.displayFields.diversPostalAddressInfo.line1.required`}
                      renderComponent={(props) => (
                        <AppInputBooleanRHF
                          type="checkbox"
                          className="-ml-2 scale-125 block mx-auto mt-1"
                          label="Obligatoire"
                          {...props}
                        />
                      )}
                    />
                  )}
                </div>
                <div className="flex flex-col gap-2">
                  <AppFormControlRHF
                    className=""
                    control={control}
                    label="Code postal"
                    name={`${formPrefix}.displayFields.diversPostalAddressInfo.postalCode.edit`}
                    renderComponent={(props) => (
                      <AppInputBooleanSwitchRHF {...props} />
                    )}
                  />
                  {displayExpertMode && (
                    <AppFormControlRHF
                      control={control}
                      name={`${formPrefix}.displayFields.diversPostalAddressInfo.postalCode.required`}
                      renderComponent={(props) => (
                        <AppInputBooleanRHF
                          type="checkbox"
                          className="-ml-2 scale-125 block mx-auto mt-1"
                          label="Obligatoire"
                          {...props}
                        />
                      )}
                    />
                  )}
                </div>
                <div className="flex flex-col gap-2">
                  <AppFormControlRHF
                    control={control}
                    label="Ville"
                    name={`${formPrefix}.displayFields.diversPostalAddressInfo.city.edit`}
                    renderComponent={(props) => (
                      <AppInputBooleanSwitchRHF {...props} />
                    )}
                  />
                  {displayExpertMode && (
                    <AppFormControlRHF
                      control={control}
                      name={`${formPrefix}.displayFields.diversPostalAddressInfo.city.required`}
                      renderComponent={(props) => (
                        <AppInputBooleanRHF
                          type="checkbox"
                          className="-ml-2 scale-125 block mx-auto mt-1"
                          label="Obligatoire"
                          {...props}
                        />
                      )}
                    />
                  )}
                </div>
                <div className="flex flex-col gap-2">
                  <AppFormControlRHF
                    control={control}
                    label="Région"
                    name={`${formPrefix}.displayFields.diversPostalAddressInfo.state.edit`}
                    renderComponent={(props) => (
                      <AppInputBooleanSwitchRHF {...props} />
                    )}
                  />
                  {displayExpertMode && (
                    <AppFormControlRHF
                      control={control}
                      name={`${formPrefix}.displayFields.diversPostalAddressInfo.state.required`}
                      renderComponent={(props) => (
                        <AppInputBooleanRHF
                          type="checkbox"
                          className="-ml-2 scale-125 block mx-auto mt-1"
                          label="Obligatoire"
                          {...props}
                        />
                      )}
                    />
                  )}
                </div>
                <div className="flex flex-col gap-2">
                  <AppFormControlRHF
                    control={control}
                    label="Pays"
                    name={`${formPrefix}.displayFields.diversPostalAddressInfo.country.edit`}
                    renderComponent={(props) => (
                      <AppInputBooleanSwitchRHF {...props} />
                    )}
                  />
                  {displayExpertMode && (
                    <AppFormControlRHF
                      control={control}
                      name={`${formPrefix}.displayFields.diversPostalAddressInfo.country.required`}
                      renderComponent={(props) => (
                        <AppInputBooleanRHF
                          type="checkbox"
                          className="-ml-2 scale-125 block mx-auto mt-1"
                          label="Obligatoire"
                          {...props}
                        />
                      )}
                    />
                  )}
                </div>
              </div>
            )}
          </Disclosure.Panel>
        </div>
      )}
    </Disclosure>
  );
}
