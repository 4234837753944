// https://heroicons.com/ ?

import {
  BellAlertIcon,
  CogIcon,
  GiftTopIcon,
  HomeModernIcon,
  IdentificationIcon,
  InformationCircleIcon,
  KeyIcon,
  LightBulbIcon,
  LockClosedIcon,
  MapPinIcon,
  NoSymbolIcon,
  PhotoIcon,
  ShieldCheckIcon,
  ShieldExclamationIcon,
  ShoppingBagIcon,
  Squares2X2Icon,
  StarIcon,
  UserCircleIcon,
  WrenchScrewdriverIcon,
} from '@heroicons/react/24/outline';
// https://heroicons.com/ ?

import { FaPlane } from 'react-icons/fa';

// ATTENTION: ne as renommer les ref, car utilisé dans AppLibIconRef!

export const AppLibIcons = {
  home: CogIcon,
  home2: HomeModernIcon,
  identification: IdentificationIcon,
  plane: FaPlane,
  gift: GiftTopIcon,
  info: InformationCircleIcon,
  lock: LockClosedIcon,
  key: KeyIcon,
  light: LightBulbIcon,
  location: MapPinIcon,
  no: NoSymbolIcon,
  photo: PhotoIcon,
  check: ShieldCheckIcon,
  exclam: ShieldExclamationIcon,
  shop: ShoppingBagIcon,
  squares: Squares2X2Icon,
  user: UserCircleIcon,
  wrench: WrenchScrewdriverIcon,
  bell: BellAlertIcon,
  star: StarIcon,
};
