import React, { useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { EcommerceCategoryEditorPanelEditForm } from './EcommerceCategoryEditorPanelEditForm';
import { EcommerceCategoryEditorLocalState } from './useEcommerceCategoryEditorPanelLocalState.hook';

export const EcommerceCategoryEditorPanelCreateForm = ({
  localState,
}: {
  localState: EcommerceCategoryEditorLocalState;
}) => {
  const { state, actions } = localState;

  const [appBookletPage, categoryName] = useWatch({
    control: state.form.control,
    name: ['appBookletPage', 'category.name'],
  });
  const content = appBookletPage?.mainContent?.content;
  const category = useMemo(
    () => ({
      name: categoryName,
    }),
    [categoryName],
  );

  return (
    <div className={'grid grid-cols-1 gap-4'}>
      <div className="flex gap-8 items-start">
        <EcommerceCategoryEditorPanelEditForm localState={localState} />
      </div>
    </div>
  );
};
