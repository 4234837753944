/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ClubStaffMemberDailyAvailabilityPeriod,
  StaffMemberResumeSessionsDayModelSessionDetails,
} from '@mabadive/app-common-model';
import { dateService, diveSessionFilter } from '@mabadive/app-common-services';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { AppIcons } from '../../../../../../../../../../../_core/modules/layout/icons';
import { AvailabilityPeriodLabel } from './AvailabilityPeriodLabel';

export const DiveSessionStaffMemberSessionTimes = ({
  sessionsDetails: sessionsDetailsInput,
  fontSize,
  availabilityPeriod,
  showAvailabilityPeriods,
  focusSessionReference,
  focusActiveRoles,
  timesToDisplay,
  grayScalePastAndEmpty,
  grayScaleCancelled,
  isPast,
  onClickSession,
  className,
}: {
  fontSize: 'text-xs' | 'text-sm';
  focusSessionReference?: string;
  focusActiveRoles?: boolean;
  showAvailabilityPeriods?: ClubStaffMemberDailyAvailabilityPeriod[];
  availabilityPeriod?: ClubStaffMemberDailyAvailabilityPeriod;
  sessionsDetails: StaffMemberResumeSessionsDayModelSessionDetails[];
  timesToDisplay: 'assigned' | 'all';
  isPast: boolean;
  grayScalePastAndEmpty: boolean;
  grayScaleCancelled: boolean;
  onClickSession?: (
    sessionDetails: StaffMemberResumeSessionsDayModelSessionDetails,
    e?: React.MouseEvent<HTMLSpanElement, MouseEvent>,
  ) => void;
  className?: string;
}) => {
  const availabilityPeriodToShow = (showAvailabilityPeriods ?? []).includes(
    availabilityPeriod,
  )
    ? availabilityPeriod
    : undefined;

  const sessionsDetails: StaffMemberResumeSessionsDayModelSessionDetails[] =
    useMemo(() => {
      return diveSessionFilter.filterStaffSessionsDetails(
        sessionsDetailsInput,
        {
          isPast,
          filterCancelled: false,
          filterPastAndEmpty: false,
        },
      );
    }, [isPast, sessionsDetailsInput]);

  const sessionDetailsToDisplay = useMemo(
    () =>
      (sessionsDetails ?? []).filter((x) => {
        const isAssigned = isAssignedSession(x);
        if (isAssigned) {
          return true; // assigné: on affiche toujours
        }
        if (timesToDisplay === 'all' && x.session.status === 'on') {
          // session ouverte, ou option pour afficher les sessions fermées

          // si le participant est du matin, on n'affiche pas les horaires de l'après-midi!
          const isAvailableOnSession =
            x?.staffMemberFull?.sessionAssigmentResume?.isAvailableOnSession;
          return isAvailableOnSession;
        } else {
          return false;
        }
      }),
    [sessionsDetails, timesToDisplay],
  );

  return (
    <div
      className={clsx(
        'text-sm text-app-primary flex flex-wrap gap-1 items-start',
        fontSize,
        className,
      )}
    >
      {sessionDetailsToDisplay.map((sessionDetails, i) => {
        const enabledOpacity = !diveSessionFilter.isActiveForFilters(
          sessionDetails.session,
          {
            filterCancelled: grayScaleCancelled,
            filterPastAndEmpty: grayScalePastAndEmpty,
            isPast,
          },
        );

        const isSessionOn = sessionDetails.session.status === 'on';
        const isAssigned = isAssignedSession(sessionDetails);
        const isSessionFocus =
          sessionDetails.session.reference === focusSessionReference;

        const hasActiveRoles =
          sessionDetails.staffMemberFull.sessionAssigmentResume.activeRoles
            .length > 0;

        const isFocus = isSessionFocus || (focusActiveRoles && hasActiveRoles);
        const isActiveSession = isSessionFocus;
        return (
          <span
            key={i}
            className={clsx(
              onClickSession &&
                clsx(
                  'cursor-pointer',
                  enabledOpacity && 'grayscale',
                  isActiveSession
                    ? isSessionOn
                      ? isAssigned
                        ? 'hover:bg-status-success-dark'
                        : 'hover:bg-status-success-light'
                      : 'hover:bg-status-error-dark'
                    : focusActiveRoles
                    ? isSessionOn
                      ? isAssigned
                        ? 'hover:bg-blue-900 hover:text-white'
                        : 'hover:bg-blue-200 hover:text-gray-600'
                      : 'hover:bg-status-error-light'
                    : isSessionOn
                    ? 'hover:bg-green-300 hover:border-status-success-light hover:text-status-success'
                    : 'hover:bg-red-300',
                ),
              isSessionOn
                ? isFocus
                  ? isAssigned
                    ? focusActiveRoles
                      ? 'border border-transparent bg-blue-700 text-white px-0.5'
                      : 'border border-transparent bg-status-success text-white px-0.5'
                    : 'border border-transparent bg-gray-400 text-white px-0.5'
                  : focusActiveRoles
                  ? isAssigned
                    ? 'border border-transparent bg-blue-400 text-white px-0.5'
                    : 'border border-gray-300 bg-gray-50 text-gray-500 px-0.5'
                  : isAssigned
                  ? 'border border-status-success bg-green-50 text-status-success-dark px-0.5'
                  : 'border border-gray-300 bg-gray-50 text-gray-500 px-0.5'
                : isFocus
                ? 'border border-transparent bg-status-error text-white px-0.5'
                : 'border border-status-error-light bg-red-50 text-status-error px-0.5 line-through',
            )}
            onClick={
              onClickSession
                ? (e) => onClickSession(sessionDetails, e)
                : undefined
            }
          >
            <span className="font-medium">
              {dateService.formatUTC(sessionDetails.session.time, 'H')}
            </span>
            <span
              className={fontSize === 'text-sm' ? 'text-xs' : 'text-app-xxs'}
            >
              {dateService.formatUTC(sessionDetails.session.time, ':mm')}
            </span>
          </span>
        );
      })}
      {availabilityPeriodToShow && (
        <AvailabilityPeriodLabel
          className={'uppercase font-bold border border-red-400 px-1'}
          icon={AppIcons.status.danger}
          iconClassName="inline-block w-4 h-4 text-red-600"
          period={availabilityPeriodToShow}
        />
      )}
    </div>
  );
};

function isAssignedSession(x: StaffMemberResumeSessionsDayModelSessionDetails) {
  return (
    x.staffMemberFull.sessionAssigmentResume.staffMemberConfig?.assigned ===
      true ||
    x.staffMemberFull.sessionAssigmentResume.activeOrAssignedRoles.length > 0
  );
}
