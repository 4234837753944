/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC } from 'react';
import {
  AppButton,
  AppFormAutoSubmit,
  AppPageContainerWithFixedBars,
} from 'src/business/_core/modules/layout';

import { nameFormatter } from '@mabadive/app-common-services';
import { useWatch } from 'react-hook-form';
import { AppIcons } from 'src/business/_core/modules/layout/icons';
import { ClubDialogsProvider } from '../../../../_dialogs';
import { StaffMemberEditDialogActionsBar } from './StaffMemberEditDialogActionsBar';
import { StaffMemberEditDialogState, StaffMemberEditFormModel } from './_model';
import { StaffMemberEditForm } from './form';
import {
  StaffMemberEditDialogLocalState,
  useStaffMemberEditDialogLocalState,
} from './useStaffMemberEditDialogLocalState.hook';
import { useStaffMemberEditDialogSubmit } from './useStaffMemberEditDialogSubmit.hook';

export const StaffMemberEditDialog: FC<StaffMemberEditDialogState> = (
  inputState,
) => {
  const { isOpen, initialState, onCancel, onConfirm, openDialog, closeDialog } =
    inputState;

  const { defaultValue, mode } = initialState;

  const localState: StaffMemberEditDialogLocalState =
    useStaffMemberEditDialogLocalState({
      initialState,
    });

  const {
    state: { form, dialogsState },
  } = localState;

  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const formValue: StaffMemberEditFormModel = watch();

  const submitForm = useStaffMemberEditDialogSubmit({
    handleSubmit,
    localState,
    inputState,
  });

  const [firstName, lastName] = useWatch({
    name: ['staffMember.profile.firstName', 'staffMember.profile.lastName'],
    control,
  });

  const hasChanges = form.formState.isDirty;

  return !isOpen ? null : dialogsState.isAnyDialogOpen ? (
    <ClubDialogsProvider dialogsState={dialogsState}>
      {/* DIALOGS : nouveau (ne pas encapsuler l'un dans l'autre, sinon on a 2 divs imbriqués qui font bugger le dialog de paiement par exemple (il ne scroll plus)) */}
    </ClubDialogsProvider>
  ) : (
    <AppPageContainerWithFixedBars
      className="max-w-screen-2xl"
      contentClassName="bg-gray-50"
      footerBar={() => (
        <StaffMemberEditDialogActionsBar
          className="sm:hidden"
          onCancel={onCancel}
          submitForm={submitForm}
        />
      )}
    >
      <div className="app-card app-p-content">
        <AppFormAutoSubmit onSubmit={submitForm}>
          {/* <ClubSettingsSection
            className="app-card app-p-content"
            title="Moniteur"
          > */}
          <h2 className="text-left text-sm sm:text-lg leading-6 py-2 font-medium text-app-blue uppercase">
            {initialState.mode === 'create'
              ? 'Nouveau moniteur'
              : nameFormatter.formatFullName({
                  firstName,
                  lastName,
                })}
          </h2>
          <StaffMemberEditForm className="" localState={localState} />
          {/* </ClubSettingsSection> */}
          <div className="hidden sm:block sticky -bottom-8 pt-8">
            <div className="flex gap-4 justify-start">
              <AppButton
                size="normal"
                icon={AppIcons.action.cancel}
                tabIndex={500}
                color="gray-outline-light"
                onClick={() => {
                  onCancel();
                }}
              >
                Annuler
              </AppButton>
              <AppButton
                type="submit"
                size="normal"
                icon={AppIcons.action.save}
                color="primary-bg"
                onClick={() => {
                  submitForm();
                }}
              >
                Confirmer
              </AppButton>
            </div>
          </div>
        </AppFormAutoSubmit>
      </div>
    </AppPageContainerWithFixedBars>
  );
};
