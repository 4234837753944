import { Dialog, Transition } from '@headlessui/react';
import React, { FC, Fragment, useCallback, useEffect, useState } from 'react';
import { AppHeroIcons } from '../../../icons';
import { useMediaSizeTailwind } from '../../../services/useMediaSizeTailwind.hook';

export const AppDialogModalDeprecated: FC<{
  open?: boolean;
  onClickClose?: () => void;
  showCloseButton?: boolean;
  defaultOpen?: boolean;
  className?: string;
  fullWidth?: boolean;
  autoClose?: boolean;
  zIndex?: number;
  children?: React.ReactNode | React.ReactNode[];
}> = ({
  className,
  children,
  open,
  showCloseButton = true,
  defaultOpen = false,
  fullWidth = true,
  autoClose = true,
  zIndex,
  onClickClose,
}) => {
  const [isOpen, setIsOpen] = useState(open !== undefined ? open : defaultOpen);
  useEffect(() => {
    if (open !== undefined) {
      setIsOpen(open);
    }
  }, [open]);

  const closeDialog = useCallback(() => {
    const state: boolean = false;
    setIsOpen(state);
    onClickClose();
  }, [onClickClose]);
  const mediaSize = useMediaSizeTailwind();
  return (
    // https://tailwindui.com/components/application-ui/overlays/modals
    // https://headlessui.dev/react/dialog
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 overflow-y-auto"
        style={{ zIndex }}
        open={open}
        onClose={() => {
          if (autoClose) {
            // il y a un bug si le dialog contient un formulaire avec un composant
            // date ou select, donc il faut désactiver la fermeture automatique
            // (touche echap ou clic en dehors) si le formulaire est ouvert
            closeDialog();
          }
        }}
      >
        <div className="flex items-end justify-center min-h-[100dvh] text-center sm:block">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-[100dvh]"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              id="app-scrollable-dialog"
              className={`min-h-[600px] max-h-[100dvh] inline-block align-bottom  
              text-left overflow-auto shadow-xl transform transition-all
              ${
                fullWidth ||
                mediaSize === 'xxs' ||
                mediaSize === 'xs' ||
                mediaSize === 'sm'
                  ? 'h-[100dvh] w-full'
                  : 'sm:align-middle w-full sm:max-w-2xl md:max-w-4xl xl:max-w-5xl'
              }`}
            >
              <div
                className={`max-w-screen-2xl relative rounded-lg ${
                  fullWidth ? `h-full ${showCloseButton ? 'pt-14' : ''}` : ''
                } ${className}`}
              >
                {showCloseButton && (
                  <AppHeroIcons.actionClose
                    style={{ zIndex: zIndex ? zIndex + 10 : 10 }}
                    className="absolute top-2 right-2 cursor-pointer p-1 w-10 h-10 bg-gray-600 hover:bg-gray-800 text-gray-200 hover:text-white"
                    onClick={() => {
                      onClickClose ? onClickClose() : closeDialog();
                    }}
                  />
                )}
                <div
                  className="relative h-full py-0.5 px-0.5 sm:px-4 md:px-6"
                  style={{ zIndex }}
                >
                  {children}
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
