import { ClubDiverDefaultDiveConfig } from '@mabadive/app-common-model';
import { useMemo } from 'react';
import { DefaultDiveConfigCardAttributeName } from './model';

const ALL_ATTRIBUTES: DefaultDiveConfigCardAttributeName[] = [
  'diveType',
  'aptitude',
  'gaz',
  'targetDeep',
  'jacket',
  'wetsuit',
  'fins',
  'scubaRegulator',
  'divingBoots',
  'mask',
  'flashLight',
  'computer',
  'tank',
  'compass',
  'weighting',
  'rebreather',
  'underwaterScooter',
];

export function useDefaultDiveConfigCardAttributes({
  defaultDiveConfig,
  attributesToExclude,
}: {
  defaultDiveConfig: ClubDiverDefaultDiveConfig;
  attributesToExclude?: DefaultDiveConfigCardAttributeName[];
}): DefaultDiveConfigCardAttributeName[] {
  if (!attributesToExclude) {
    attributesToExclude = [];
  }

  return useMemo(
    () =>
      buildVisibleAttributes({
        defaultDiveConfig,
        allAttributes: ALL_ATTRIBUTES,
        attributesToExclude,
      }),
    [attributesToExclude, defaultDiveConfig],
  );
}

function buildVisibleAttributes({
  defaultDiveConfig,
  allAttributes,
  attributesToExclude,
}: {
  defaultDiveConfig: ClubDiverDefaultDiveConfig;
  allAttributes: DefaultDiveConfigCardAttributeName[];
  attributesToExclude: DefaultDiveConfigCardAttributeName[];
}) {
  const attributes: DefaultDiveConfigCardAttributeName[] = allAttributes.filter(
    (attr) =>
      isNotEmptyClubParticipantCardAttribute(defaultDiveConfig, attr) &&
      attributesToExclude.indexOf(attr) === -1,
  );

  return attributes;
}

function isNotEmptyClubParticipantCardAttribute(
  defaultDiveConfig: ClubDiverDefaultDiveConfig,
  attributeName: DefaultDiveConfigCardAttributeName,
) {
  if (!defaultDiveConfig) {
    return false;
  }
  if (attributeName === 'diveType') {
    return !!defaultDiveConfig?.diveMode;
  }
  if (attributeName === 'jacket') {
    return !!defaultDiveConfig?.equipment?.jacket;
  }
  if (attributeName === 'wetsuit') {
    return !!defaultDiveConfig?.equipment?.wetsuit;
  }
  if (attributeName === 'fins') {
    return !!defaultDiveConfig?.equipment?.fins;
  }
  if (attributeName === 'scubaRegulator') {
    return !!defaultDiveConfig?.equipment?.scubaRegulator;
  }
  if (attributeName === 'divingBoots') {
    return !!defaultDiveConfig?.equipment?.divingBoots;
  }
  if (attributeName === 'mask') {
    return !!defaultDiveConfig?.equipment?.mask;
  }
  if (attributeName === 'flashLight') {
    return !!defaultDiveConfig?.equipment?.flashLight;
  }
  if (attributeName === 'computer') {
    return !!defaultDiveConfig?.equipment?.computer;
  }
  if (attributeName === 'tank') {
    return !!defaultDiveConfig?.equipment?.tank;
  }
  if (attributeName === 'compass') {
    return !!defaultDiveConfig?.equipment?.compass;
  }
  if (attributeName === 'weighting') {
    return !!defaultDiveConfig?.equipment?.weighting;
  }
  if (attributeName === 'rebreather') {
    return !!defaultDiveConfig?.equipment?.rebreather;
  }
  if (attributeName === 'underwaterScooter') {
    return !!defaultDiveConfig?.equipment?.underwaterScooter;
  }
  if (attributeName === 'gaz') {
    return (
      !!defaultDiveConfig?.gaz?.gazDescription ||
      (!!defaultDiveConfig?.gaz?.gazType &&
        defaultDiveConfig?.gaz?.gazType !== 'air') ||
      !!defaultDiveConfig?.gaz?.gazQuantity
    );
  }
  if (attributeName === 'aptitude') {
    return !!defaultDiveConfig?.diveMode;
  }
  return !!defaultDiveConfig[attributeName];
}
