import {
  ClubStaffMember,
  ProMultiOperationPayload,
  ProMultiOperationPayloadAction,
} from '@mabadive/app-common-model';
import {
  commonEntityBuilder,
  jsonPatcher,
} from '@mabadive/app-common-services';
import { proMultiMutationsApiClient } from 'src/business/_core/data/app-operation';
import { useDiveCenterResume } from 'src/business/club/data/hooks';
import {
  ClubSettingsProductOfferCategoryDialogProps,
  ClubSettingsProductOfferCategoryEditFormModel,
} from './_model';

export function useClubSettingsProductOfferCategoryDialogActionPersist(): ClubSettingsProductOfferCategoryDialogProps {
  const diveCenterResume = useDiveCenterResume();
  const clubReference = diveCenterResume?.clubReference;
  const diveCenterId = diveCenterResume?._id;
  const action: ClubSettingsProductOfferCategoryDialogProps = {
    async onConfirm({ formValue }, initialState) {
      const { defaultValue } = initialState;

      const newValue: ClubSettingsProductOfferCategoryEditFormModel = {
        ...formValue,
      };

      if (initialState.mode === 'edit') {
        const patchOperations = jsonPatcher.compareObjects(
          defaultValue.offerCategory,
          newValue.offerCategory,
          {
            attributesToReplaceFully: [],
          },
        );
        if (patchOperations.length) {
          const payload: ProMultiOperationPayload =
            proMultiMutationsApiClient.createEmptyPayload({
              logContext: 'edit offer category',
              bookingIdsToClean: [],
            });

          const payloadAction: ProMultiOperationPayloadAction = {
            actionContext: 'ProductOfferCategoryDialog',
            actionSteps: [
              {
                label: 'Update offer category',
                date: new Date(),
              },
            ],
            clubProductPackageOfferCategory: {
              updated: [
                {
                  pk: defaultValue.offerCategory?._id,
                  patchOperations,
                },
              ],
            },
          };
          payload.actions.push(payloadAction);

          await proMultiMutationsApiClient.update(payload);
        } else {
          // nothing to do
        }
      } else if (initialState.mode === 'create') {
        const payload: ProMultiOperationPayload =
          proMultiMutationsApiClient.createEmptyPayload({
            logContext: 'create offer category',
            bookingIdsToClean: [],
          });

        const payloadAction: ProMultiOperationPayloadAction = {
          actionContext: 'ProductOfferCategoryDialog',
          actionSteps: [
            {
              label: 'Create offer category',
              date: new Date(),
            },
          ],

          clubProductPackageOfferCategory: {
            created: [
              commonEntityBuilder.buildEntity<ClubStaffMember>({
                ...newValue.offerCategory,
                clubReference,
                diveCenterId,
              }),
            ],
          },
        };
        payload.actions.push(payloadAction);

        await proMultiMutationsApiClient.update(payload);
      }
    },
  };
  return action;
}
