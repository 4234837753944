/* eslint-disable @typescript-eslint/no-unused-vars */
import clsx from 'clsx';
import React from 'react';
import { useAutoFocus } from 'src/business/_core/modules/layout';
import { AppFormControlV2, AppSingleAutocomplete2 } from 'src/lib/form';
import { AppSingleSelect2HeadlessUI } from 'src/lib/form/components/AppSingleSelect/AppSingleSelect2HeadlessUI';
import { useDiveCenterResume } from '../../../../business/club/data/hooks';
import { AppInputDatePickerMaterial } from '../../../../stories/components/04-form';
import { useBookingAgenciesOptions } from '../../../_components/options';
import { ClubPurchasesListPageLocalState } from '../useClubPurchasesListPageLocalState.hook';

export const ClubPurchasesListPageConfigBarExtraFilters = ({
  localState,
  className,
}: {
  localState: ClubPurchasesListPageLocalState;
  className?: string;
}) => {
  const { state, actions } = localState;
  const { viewParameters } = state;

  const autoFocus = useAutoFocus();
  const diveCenterResume = useDiveCenterResume();
  const generalSettings = diveCenterResume?.clubResume?.clubSettings?.general;
  const customerSettings = diveCenterResume?.clubResume?.clubSettings?.customer;
  const agenciesOptions = useBookingAgenciesOptions();

  return (
    <div className="flex flex-col gap-2">
      <input
        className="xs:hidden mx-1 w-full hover:border-app-blue rounded px-2"
        autoFocus={autoFocus}
        autoComplete="new-password"
        placeholder="Recherche par nom, prénom"
        type="text"
        defaultValue={viewParameters.searchTextContactName}
        onChange={(e) => {
          actions.setSearchTextContactName(e.target.value);
        }}
      />

      <input
        className="md:hidden mx-1 w-full hover:border-app-blue rounded px-2"
        autoFocus={autoFocus}
        autoComplete="new-password"
        placeholder="Recherche par prestation"
        type="text"
        defaultValue={viewParameters.searchTextOfferName}
        onChange={(e) => {
          actions.setSearchTextOfferName(e.target.value);
        }}
      />

      <div
        className={clsx(
          'grid xs:grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:flex lg:flex-wrap gap-x-2 gap-y-1',
          className,
        )}
      >
        <AppFormControlV2
          className="min-w-28 sm:col-span-1 bg-white px-2"
          label="Validité"
          renderComponent={() => (
            <AppSingleSelect2HeadlessUI
              className="w-full"
              theme={'material-ui'}
              disableClearButton={false}
              value={viewParameters.validityStatus}
              options={state.options.validityStatusOptions}
              onChange={(value) => actions.setValidityStatus(value)}
            />
          )}
        />
        <AppFormControlV2
          className="min-w-28 sm:col-span-1 bg-white px-2"
          label="Paiement"
          renderComponent={() => (
            <AppSingleSelect2HeadlessUI
              className="w-full"
              theme={'material-ui'}
              disableClearButton={false}
              value={viewParameters.paymentStatus}
              options={state.options.paymentStatusOptions}
              onChange={(value) => actions.setPaymentStatus(value)}
            />
          )}
        />
        <AppFormControlV2
          className="min-w-36 sm:col-span-1 bg-white px-2"
          label="Forfait / formation"
          renderComponent={() => (
            <AppSingleSelect2HeadlessUI
              className="w-full"
              theme={'material-ui'}
              disableClearButton={false}
              value={viewParameters.purchaseType}
              options={state.options.purchaseTypeOptions}
              onChange={(value) => actions.setPurchaseType(value)}
            />
          )}
        />
        {generalSettings?.agencies?.enabled &&
          generalSettings.agencies?.agencyPurchase && (
            <AppFormControlV2
              className="w-full lg:w-72 bg-white px-2"
              label="Agence"
              renderComponent={() => (
                <AppSingleAutocomplete2
                  className="w-full"
                  value={viewParameters.bookingAgencyId}
                  options={agenciesOptions}
                  onChange={(agencyId) => actions.setBookingAgencyId(agencyId)}
                />
              )}
            />
          )}
        <AppFormControlV2
          className="bg-white px-2"
          label="Début de début"
          renderComponent={() => (
            <AppInputDatePickerMaterial
              initialView={'weeks'}
              className="w-36"
              value={viewParameters.beginPurchaseDate}
              onChange={(beginPurchaseDate) =>
                actions.setBeginPurchaseDate(beginPurchaseDate)
              }
            />
          )}
        />
        <AppFormControlV2
          className="bg-white px-2"
          label="Fin de fin"
          renderComponent={() => (
            <AppInputDatePickerMaterial
              initialView={'weeks'}
              className="w-36"
              value={viewParameters.endPurchaseDate}
              onChange={(endPurchaseDate) =>
                actions.setEndPurchaseDate(endPurchaseDate)
              }
            />
          )}
        />
      </div>
    </div>
  );
};
