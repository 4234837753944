/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ClubProductPackageOffer_ServiceCategory,
  ClubProductPackageOfferCategory,
  ClubProductPackageOfferViewDisplayCategory,
  ClubProductPackageOfferViewDisplayGroup,
  DiveMode,
  DiveServiceOrganizationReference,
  RegionalSettings,
} from '@mabadive/app-common-model';
import { productPackageFormatter, search } from '@mabadive/app-common-services';
import { useCallback, useMemo, useState } from 'react';
import {
  useClubResume,
  useClubSettings,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import {
  clubSettingsProductOfferBuilder,
  ClubSettingsProductOffersDialogInitialStateMode,
  ClubSettingsProductOffersFiltersState,
  CreateOfferPayload,
  useClubSettingsProductOffersFiltersState,
} from 'src/pages/SE-settings/SE-02_account/ClubSettingsProductOffersDialog';
import {
  useAppCurrencyMain,
  useRegionalSettings,
} from 'src/pages/_components/options';
import {
  ClubDialogsState,
  useClubDialogs,
  useClubDialogsActionsPersist,
  UseClubDialogsProps,
} from 'src/pages/_dialogs';
import {
  ClubSettingsProductOfferCategoryDialogEditMode,
  ClubSettingsProductOfferCategoryEditFormModel,
} from '../../../../ClubSettingsProductOfferCategoryDialog';
import { ClubSettingsServicesPageLocalState } from '../../../useClubSettingsServicesPageLocalState.hook';
import {
  offersDisplayCategoryBuilder,
  offersDisplayGroupsBuilder,
} from '../services';

export function useClubSettingsServicesViewPanelOffersPricesLocalState({
  parentState,
  contextMode,
}: {
  parentState: ClubSettingsServicesPageLocalState;
  contextMode: ClubProductPackageOffer_ServiceCategory;
}) {
  const diveCenterResume = useDiveCenterResume();
  const publicSettings = useClubSettings()?.publicSettings;
  const clubResume = useClubResume();

  const clubSettings = clubResume?.clubSettings;
  const clubReference = clubResume.reference;

  const actionsPersist: UseClubDialogsProps = useClubDialogsActionsPersist();
  const dialogsState: ClubDialogsState = useClubDialogs(actionsPersist);

  const regionalSettings: RegionalSettings = useRegionalSettings({
    effectiveDate: new Date(),
  });

  const { state, dialogs, data } = parentState;

  const mainCurrency = useAppCurrencyMain();

  const contextDiveModes: DiveMode[] = useMemo(() => {
    if (contextMode === 'first-dive') {
      return ['first-dive'];
    } else if (contextMode === 'training') {
      return ['training', 'theoretical-training'];
    } else if (contextMode === 'explo') {
      return ['supervised', 'autonomous', 'autoSupervised', 'instructor'];
    } else if (contextMode === 'free-dive') {
      return ['free-dive', 'free-dive-auto'];
    } else if (contextMode === 'others') {
      return [
        'observer',
        'snorkeling',
        'snorkelingSupervised',
        'watchingTour',
        'unknown',
      ];
    }

    return [];
  }, [contextMode]);

  const [searchText, setSearchText] = useState('');

  const offersAllBeforeSearch = useMemo(() => {
    if (contextMode === 'extras') {
      return data.productPackageOffers.filter(
        (x) => x.productPackage.type === 'product',
      );
    }
    if (contextMode === 'store') {
      return data.productPackageOffers.filter(
        (x) => x.productPackage.type === 'store',
      );
    } else if (contextMode === 'training') {
      return data.productPackageOffers.filter(
        (x) =>
          x.productPackage.type === 'training' &&
          contextDiveModes.includes(x.productPackage?.diveAttributes?.diveMode),
      );
    } else {
      return data.productPackageOffers.filter(
        (x) =>
          x.productPackage.type === 'dive' &&
          contextDiveModes.includes(x.productPackage?.diveAttributes?.diveMode),
      );
    }
  }, [contextDiveModes, contextMode, data.productPackageOffers]);

  const offersAll = useMemo(() => {
    if (searchText?.trim()?.length > 0) {
      return search.filter(offersAllBeforeSearch, {
        searchText,
        getAttributes: (offer) => {
          const nameDetails = productPackageFormatter.formatNameDetails(
            offer.productPackage,
            {
              publicSettings,
              diveModesConf: clubSettings?.ui?.diveMode,
            },
          );
          return [
            nameDetails?.label,
            nameDetails?.name,
            nameDetails?.prefix,
            nameDetails?.suffix,
          ];
        },
      });
    }
    return offersAllBeforeSearch;
  }, [
    clubSettings?.ui?.diveMode,
    offersAllBeforeSearch,
    publicSettings,
    searchText,
  ]);

  const filtersState: ClubSettingsProductOffersFiltersState =
    useClubSettingsProductOffersFiltersState({
      contextMode,
      offers: offersAll,
    });

  const openOfferEditDialog = useCallback(
    ({
      mode,
      offers,
    }: {
      mode: ClubSettingsProductOffersDialogInitialStateMode;
      offers: CreateOfferPayload[];
    }) => {
      dialogs.clubSettingsOffers.openEdit({
        mode,
        contextMode,
        offers,
        initialFilters:
          mode === 'edit' ? filtersState.state.filters : undefined,
      });
    },
    [contextMode, dialogs.clubSettingsOffers, filtersState.state.filters],
  );

  const openOfferEditCategoryDialog = useCallback(
    ({
      mode,
      defaultValue,
      serviceCategory,
    }: {
      mode: ClubSettingsProductOfferCategoryDialogEditMode;
      defaultValue: ClubSettingsProductOfferCategoryEditFormModel;
      serviceCategory: ClubProductPackageOffer_ServiceCategory;
    }) => {
      dialogs.clubSettingsOffersStoreCategories.openEdit({
        mode,
        defaultValue,
        serviceCategory,
      });
    },
    [dialogs.clubSettingsOffersStoreCategories],
  );

  const trainingOrgsRefs =
    contextMode === 'training'
      ? (
          clubSettings.services.international
            .organizationReferences as DiveServiceOrganizationReference[]
        ).concat(clubSettings.services.french.organizationReferences)
      : [];

  const offersFiltered = filtersState.data.offersFiltered;

  const offersByDisplayGroups: ClubProductPackageOfferViewDisplayGroup[] =
    useMemo(() => {
      const offersByDisplayGroups: ClubProductPackageOfferViewDisplayGroup[] =
        offersDisplayGroupsBuilder.buildGroups({
          clubResume,
          offers: offersFiltered,
          contextMode,
        });
      return offersByDisplayGroups;
    }, [clubResume, contextMode, offersFiltered]);

  const offersByCategory: ClubProductPackageOfferViewDisplayCategory[] =
    useMemo(() => {
      const offersByDisplayCategory: ClubProductPackageOfferViewDisplayCategory[] =
        offersDisplayCategoryBuilder.buildGroups({
          clubSettings,
          offers: offersFiltered,
          productPackageOfferCategories:
            clubResume.productPackageOfferCategories,
          contextMode,
        });
      return offersByDisplayCategory;
    }, [
      clubResume.productPackageOfferCategories,
      clubSettings,
      contextMode,
      offersFiltered,
    ]);

  const createProduct = useCallback(
    async (
      offerCategory: Pick<ClubProductPackageOfferCategory, '_id' | 'title'>,
    ) => {
      const newOffer: CreateOfferPayload =
        clubSettingsProductOfferBuilder.buildNewOfferProductStore({
          clubReference,
          label: 'Nouveau produit',
          productType: 'sale',
          salesCriteria: {
            favorite: true,
            standalone: true,
            extraCostTraining: true,
          },
          productCategory: offerCategory,
        });
      openOfferEditDialog({
        mode: 'create',
        offers: [newOffer],
      });
    },
    [clubReference, openOfferEditDialog],
  );
  const createCategory = useCallback(
    async (title: string) => {
      openOfferEditCategoryDialog({
        mode: 'edit',
        defaultValue: {
          offerCategory: {
            title,
          },
        },
        serviceCategory: 'store',
      });
    },
    [openOfferEditCategoryDialog],
  );

  return {
    data: {
      clubReference,
      publicSettings,
      clubSettings,
      offersAll,
      offersAllBeforeSearch,
      offersFiltered,
      offersByCategory,
      offersByDisplayGroups,
      trainingOrgsRefs,
      offerCategories: clubResume.productPackageOfferCategories,
    },
    state: {
      ...parentState.state,
      // filters,
      // setFilters,
      // trainingCommercialCategoriesOptions,
      // organizationsOptions,
      filtersState,
      searchText,
      setSearchText,
    },
    actions: {
      // ...parentState.actions,
      openOfferEditDialog,
      openOfferEditCategoryDialog,
      createCategory,
      createProduct,
      // switchToCreateTrainingsMode,
    },
  };
}

export type ClubSettingsServicesViewPanelOffersPricesLocalState = ReturnType<
  typeof useClubSettingsServicesViewPanelOffersPricesLocalState
>;
