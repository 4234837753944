import {
  ClubSettingsPlanning,
  DiveSession,
  DiveSessionResumeFull,
  DiveSessionStatus,
} from '@mabadive/app-common-model';
import {
  commonEntityBuilder,
  virtualDiveSessionBuilder,
} from '@mabadive/app-common-services';
import {
  clubDiveSessionClientUpdator,
  clubMassiveUpdatorClient,
} from 'src/business/_core/data/app-operation';
import { confirmDialog } from 'src/business/_core/modules/layout/components/ConfirmDialog/confirmDialog.service';

export const diveSessionStatusUpdateManager = {
  cancelSessionWithConfirmDialog,
  createFromVirtualSession,
  openSessionWithConfirmDialog,
  deleteSessionWithConfirmDialog,
  closeSession,
  splitMultiSession,
};

async function splitMultiSession({ diveSessionId }: { diveSessionId: string }) {
  return await clubMassiveUpdatorClient.update({
    diveSessions: {
      splitMultipleSessionIds: [diveSessionId],
    },
  });
}
async function createFromVirtualSession({
  diveSessionReference,
  status,
  clubReference,
  diveCenterId,
  settingsPlanning,
}: {
  diveSessionReference: string;
  status?: DiveSessionStatus;
  clubReference: string;
  diveCenterId: string;
  settingsPlanning: ClubSettingsPlanning;
}) {
  const virtualSession: DiveSession = virtualDiveSessionBuilder.getByReference({
    diveSessionReference,
    clubReference,
    diveCenterId,
    settingsPlanning,
  });
  const diveSession = commonEntityBuilder.buildEntity<DiveSession>({
    ...virtualSession,
    isVirtual: false,
    status,
  });
  const payload = clubMassiveUpdatorClient.createEmptyPayload({
    logContext: 'create from virtual session',
    bookingIdsToClean: [],
  });
  payload.newDiveSessions.push(diveSession);
  return await clubMassiveUpdatorClient.update(payload);
}
async function cancelSessionWithConfirmDialog({
  diveSessionResume,
}: {
  diveSessionResume: DiveSessionResumeFull;
}): Promise<unknown> {
  if (diveSessionResume) {
    const hasAnyActiveParticipant =
      diveSessionResume.participants.find(
        (p) => p.bookingState?.value !== 'cancelled',
      ) !== undefined;
    if (
      !hasAnyActiveParticipant ||
      (await confirmDialog.confirmPromise({
        title: 'Annuler une session',
        message:
          'Cette session a des participants, êtes-vous sûr de vouloir l\'annuler?',
        type: 'noYesDanger',
      }))
    ) {
      return clubDiveSessionClientUpdator.updateDiveSessionStatus({
        diveSessionResume,
        status: 'cancelled',
        updateParticipants: true,
      });
    }
  }
}
async function deleteSessionWithConfirmDialog({
  diveSessionResume,
}: {
  diveSessionResume: DiveSessionResumeFull;
}): Promise<unknown> {
  if (diveSessionResume) {
    const hasAnyParticipant = diveSessionResume.participants.length !== 0;
    if (!hasAnyParticipant) {
      if (
        await confirmDialog.confirmPromise({
          title: 'Supprimer une session',
          message:
            'Êtes-vous sûr de vouloir supprimer définitivement cette session?',
          type: 'noYesDanger',
        })
      ) {
        return clubDiveSessionClientUpdator.deleteDiveSession({
          diveSessionId: diveSessionResume._id,
        });
      }
    }
  }
}

async function openSessionWithConfirmDialog({
  diveSessionResume,
}: {
  diveSessionResume: DiveSessionResumeFull;
}): Promise<unknown> {
  if (diveSessionResume) {
    const hasAnyInactiveParticipant =
      diveSessionResume.participants.find(
        (p) => p.bookingState?.value === 'cancelled',
      ) !== undefined;

    return clubDiveSessionClientUpdator.updateDiveSessionStatus({
      diveSessionResume,
      status: 'on',
      updateParticipants:
        hasAnyInactiveParticipant &&
        (await confirmDialog.confirmPromise({
          title: 'Ouvrir une session',
          message:
            'Cette session a des participants annulés, voulez-vous les ré-inscrire?',
          type: 'noYesInfo',
        })),
    });
  }
}

async function closeSession({
  diveSessionResume,
}: {
  diveSessionResume: DiveSessionResumeFull;
}): Promise<unknown> {
  if (diveSessionResume) {
    return clubDiveSessionClientUpdator.updateDiveSessionStatus({
      diveSessionResume,
      status: 'off',
      updateParticipants: false,
    });
  }
}
