/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  EcommerceCategoryGql_Company,
  EcommerceProductGql_Company,
} from '@mabadive/app-common-model';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { AppButton } from '../../../../../../../business/_core/modules/layout';
import {
  AppHeroIcons,
  AppIconsAction,
} from '../../../../../../../business/_core/modules/layout/icons';
import { ProProductsPage_CategoryViewPanel_ProductCoverCard_Cover } from './ProProductsPage_CategoryViewPanel_ProductCoverCard_Cover';
import { ProProductsPage_CategoryViewPanelLocalState } from './useProProductsPage_CategoryViewPanelLocalState.hook';
export const ProProductsPage_CategoryViewPanel_ProductCoverCard = ({
  category,
  product,
  localState,
  className,
}: {
  category: EcommerceCategoryGql_Company;
  product: EcommerceProductGql_Company;
  localState: ProProductsPage_CategoryViewPanelLocalState;
  className?: string;
}) => {
  const { state, parentState, data, actions, dialogs } = localState;

  const productArticles = useMemo(() => {
    return data.productArticles.filter((a) => a.productId === product?._id);
  }, [data.productArticles, product?._id]);

  const productBooklet = useMemo(() => {
    return data.booklets.find((x) => x._id === product.bookletId);
  }, [data.booklets, product.bookletId]);

  const productBookletPage = useMemo(() => {
    return data.bookletPages.find((x) => x._id === productBooklet?.rootPageId);
  }, [productBooklet?.rootPageId, data.bookletPages]);
  const productCategoryConfigs = useMemo(() => {
    return data.productCategoryConfigs.filter(
      (cf) => cf.productId === product?._id,
    );
  }, [data.productCategoryConfigs, product?._id]);

  const { categoryBookletPage } = data;

  return (
    <div
      className={clsx(
        'app-card app-card-highlight border-t-xl border-app-primary',
        className,
      )}
      onClick={(e) => {
        e.stopPropagation();
        // actions.selectCategoryProduct({
        //   categoryId: category._id,
        //   productId: product._id,
        // });
        dialogs.productEditor.openEditProduct({
          categoryBookletPage,
          editValue: {
            product,
            categoryConfigs: productCategoryConfigs,
            appBooklet: productBooklet,
            appBookletPage: productBookletPage,
            productArticlesFull: data.productArticlesFull.filter(
              (x) => x.productId === product?._id,
            ),
          },
        });
      }}
    >
      <ProProductsPage_CategoryViewPanel_ProductCoverCard_Cover
        className="h-full"
        productArticles={productArticles}
        product={product}
        productBookletPage={productBookletPage}
        localState={localState}
      >
        <div className="flex flex-col gap-2">
          <AppButton
            fullWidth
            color={
              productArticles.length === 0
                ? 'danger-outline-light'
                : 'primary-outline-light'
            }
            size="normal"
            icon={AppHeroIcons.actionAdd}
            onClick={(e) => {
              e.stopPropagation();
              dialogs.productArticleEditor.openCreateProductArticle({
                productBookletPage,
                defaultValue: {
                  productArticle: {
                    enabled: true,
                    name: product.name,
                    productId: product._id,
                  },
                },
                product,
              });
            }}
          >
            Ajouter un tarif
          </AppButton>
          <AppButton
            className=""
            fullWidth
            color="primary-outline-light"
            size="normal"
            icon={AppIconsAction.edit}
            // onClick={(e) => {
            //   e.stopPropagation();
            //   actions.selectCategory(category._id);
            // }}
          >
            Modifier la description
          </AppButton>
        </div>
      </ProProductsPage_CategoryViewPanel_ProductCoverCard_Cover>
    </div>
  );
};
