import { ClubDiver, PRO_AppDocResume } from '@mabadive/app-common-model';
import {
  emailLinkBuilder,
  nameFormatter,
  phoneLinkBuilder,
  smsLinkBuilder,
  whatzappLinkBuilder,
} from '@mabadive/app-common-services';
import React, { FC, useMemo } from 'react';
import { IoLogoWhatsapp } from 'react-icons/io';
import { useAppDevice } from 'src/business/_core/modules/layout';
import { AppButton } from 'src/business/_core/modules/layout/components/_tailwind';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { useClubResume } from 'src/business/club/data/hooks';
import { ClubDiverProfilePicture } from '../../../../_from-diver-app';
export const BookingParticipantEditorDialogHeader: FC<{
  diver: ClubDiver;
  docResumes?: PRO_AppDocResume[];
  className?: string;
}> = ({ diver, docResumes, className = '' }) => {
  const clubResume = useClubResume();
  const clubSettings = clubResume?.clubSettings;

  const { isMobile } = useAppDevice();

  const settings = clubSettings.communication?.customer;
  const channels = clubSettings.communication?.channels;

  const whatzappLink = useMemo(() => {
    if (
      settings?.showMessageButtons &&
      settings?.whatsAppButton?.enabled &&
      channels?.whatsApp?.enabled
    ) {
      return whatzappLinkBuilder.buildLink({
        message: `Bonjour ${diver?.firstName}, `,
        phoneNumber: diver?.phoneNumber,
        isMobile,
      });
    }
  }, [
    settings?.showMessageButtons,
    settings?.whatsAppButton?.enabled,
    channels?.whatsApp?.enabled,
    diver?.firstName,
    diver?.phoneNumber,
    isMobile,
  ]);

  const phoneNumber = useMemo(
    () =>
      smsLinkBuilder.cleanPhoneNumber(diver.phoneNumber ?? diver.phoneNumber2),
    [diver.phoneNumber, diver.phoneNumber2],
  );
  const phoneLink = useMemo(() => {
    if (
      settings?.showMessageButtons &&
      settings?.phoneButton?.enabled &&
      channels?.phone?.enabled
    ) {
      return smsLinkBuilder.buildLinkTel({
        phoneNumber,
      });
    }
  }, [
    settings?.showMessageButtons,
    settings?.phoneButton?.enabled,
    channels?.phone?.enabled,
    phoneNumber,
  ]);

  const emailAddress = useMemo(
    () => emailLinkBuilder.cleanEmailAddress(diver?.emailAddress),
    [diver?.emailAddress],
  );
  const emailLink = useMemo(() => {
    if (
      settings?.showMessageButtons &&
      settings?.emailButton?.enabled &&
      channels?.email?.enabled
    ) {
      return emailLinkBuilder.buildLink({
        to: [emailAddress],
      });
    }
  }, [
    settings?.showMessageButtons,
    settings?.emailButton?.enabled,
    channels?.email?.enabled,
    emailAddress,
  ]);

  const profilePhoto = diver.details?.profilePhoto;

  // const imageUrl = useAppDocImageUrl({
  //   appDocId: profilePhoto?._id,
  //   publicUrl: profilePhoto?.publicUrl,
  // });

  return !diver ? null : (
    <div
      className={`flex flex-col gap-2 sm:flex-row sm:gap-4 sm:justify-between sm:items-center ${className}`}
    >
      <h2 className="sm:py-auto flex-grow order-first flex items-center gap-2 text-left text-lg leading-6 font-medium text-gray-700">
        {profilePhoto?._id && (
          <ClubDiverProfilePicture
            className="-mt-2 -mb-2 size-10 md:size-14 lg:size-16 lg:-mt-4 lg:-mb-4"
            diver={diver}
            shape="square"
          />
        )}

        {nameFormatter.formatFullName(diver, {
          format: 'firstName-first',
        })}
      </h2>
      {settings?.showMessageButtons && (
        <div className="w-full sm:w-auto flex justify-between gap-2 sm:gap-4">
          {phoneLink && (
            <div className="flex-1">
              <AppButton
                fullWidth={true}
                className="truncate text-ellipsis whitespace-nowrap"
                icon={AppHeroIcons.phone}
                color="phone-outline"
                href={phoneLink}
              >
                <span className="text-xs sm:text-sm -ml-1 sm:ml-0">
                  {phoneLinkBuilder.formatPhoneNumber(phoneNumber)}
                </span>
              </AppButton>
            </div>
          )}
          {whatzappLink && (
            <div className="flex-1">
              <AppButton
                fullWidth={true}
                className="flex-1 truncate text-ellipsis"
                icon={() => <IoLogoWhatsapp />}
                color="whatsapp-outline"
                href={whatzappLink}
                target="_blank"
              >
                <span className="text-xs sm:text-sm -ml-1 sm:ml-0">
                  WhatsApp
                </span>
              </AppButton>
            </div>
          )}
          {emailLink && (
            <div className="flex-1">
              <AppButton
                fullWidth={true}
                className="flex-1 truncate text-ellipsis"
                icon={AppHeroIcons.mail}
                color="mail-outline"
                href={emailLink}
                target="mabadive_mail_client"
              >
                <span className="hidden sm:inline break-all">
                  {emailAddress}
                </span>
                <span className="whitespace-nowrap text-xs sm:text-sm sm:hidden -ml-1 sm:ml-0">
                  E-mail
                </span>
              </AppButton>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
