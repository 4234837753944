/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ClubResumeStaffMember,
  DiveCenterResume,
  DiveSession,
  DiveSessionGroupSession1,
  DiveSessionResumeGroup,
} from '@mabadive/app-common-model';
import { useMemo } from 'react';

export function useSessionInstructorStaffMembers({
  sessionNumber,
  diveSession,
  group,
  diveCenterResume,
  diveTourGroup,
}: {
  sessionNumber: number;
  diveSession: Pick<DiveSession, 'time' | 'status' | 'diveTourSession2'>;
  group: DiveSessionResumeGroup;
  diveCenterResume: DiveCenterResume;
  diveTourGroup: DiveSessionGroupSession1;
}): {
  mainInstructorStaffMember: ClubResumeStaffMember;
  otherInstructorsStaffMembers: ClubResumeStaffMember[];
} {
  // moniteur principal (GP)
  const mainInstructorStaffMember: ClubResumeStaffMember = useMemo(() => {
    if (
      sessionNumber === 2 &&
      diveSession.diveTourSession2?.sameStaffSession1
    ) {
      const staffId = group?.diveTourGroupSession1?.instructor?.staffId;
      return staffId
        ? diveCenterResume?.staffMembers.find((p) => p._id === staffId)
        : undefined;
    } else {
      const staffId = diveTourGroup?.instructor?.staffId;
      return staffId
        ? diveCenterResume?.staffMembers.find((p) => p._id === staffId)
        : undefined;
    }
  }, [
    sessionNumber,
    diveSession.diveTourSession2?.sameStaffSession1,
    group?.diveTourGroupSession1?.instructor?.staffId,
    diveCenterResume?.staffMembers,
    diveTourGroup?.instructor?.staffId,
  ]);

  // moniteurs additionnels (stagiaires, plongeurs)
  const otherInstructorsStaffMembers: ClubResumeStaffMember[] = useMemo(() => {
    const staffIds = (
      sessionNumber === 2 && !diveSession.diveTourSession2?.sameStaffSession1
        ? group?.diveTourGroupSession2?.divingInstructors ?? []
        : group?.diveTourGroupSession1?.divingInstructors ?? []
    )?.map((x) => x.staffId);
    return diveCenterResume?.staffMembers.filter((p) =>
      staffIds.includes(p._id),
    );
  }, [
    sessionNumber,
    diveSession.diveTourSession2?.sameStaffSession1,
    group?.diveTourGroupSession2?.divingInstructors,
    group?.diveTourGroupSession1?.divingInstructors,
    diveCenterResume?.staffMembers,
  ]);
  return { mainInstructorStaffMember, otherInstructorsStaffMembers };
}
