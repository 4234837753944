import { useMemo } from 'react';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import {
  useAppSecurityUser,
  useAppSecurityUserClubAuthorizations,
} from 'src/business/auth/services';
import { AppHamburgerMenuItem } from 'src/business/club/modules/_common/ui';
import { ClubSettingsServicesViewPanelOffersPricesLocalState } from './useClubSettingsServicesViewPanelOffersPricesLocalState.hook';
const exampleCategoriesTitle = [
  'Équipement',
  'Matériel technique',
  'Produits d’entretien',
  'Textiles et accessoires',
  'Souvenirs et goodies',
  'Services et formations',
  'Produits locaux',
  'Autre catégorie',
];
export function useOffersPricesActionsBar_Store_MenuItems({
  localState,
}: {
  localState: ClubSettingsServicesViewPanelOffersPricesLocalState;
}) {
  const user = useAppSecurityUser();
  const az = useAppSecurityUserClubAuthorizations();

  const { data, state, actions } = localState;
  const clubReference = data.clubReference;
  const offerCategories = data.offerCategories;

  const hamburgerMenuItems: AppHamburgerMenuItem[] = useMemo(() => {
    const hamburgerMenuItems: AppHamburgerMenuItem[] = [];

    hamburgerMenuItems.push({
      title: 'Nouvelle catégorie',
      icon: AppHeroIcons.actionAdd,
      onClick: () => {
        actions.createCategory('Nouvelle catégorie');
      },
    });

    for (const title of exampleCategoriesTitle) {
      hamburgerMenuItems.push({
        title: `Nouvelle catégorie: "${title}"`,
        icon: AppHeroIcons.actionAdd,
        onClick: () => {
          actions.createCategory(title);
        },
      });
    }

    return hamburgerMenuItems;
  }, [actions]);

  return hamburgerMenuItems;
}
