/* eslint-disable @typescript-eslint/no-unused-vars */
import { DiveSession } from '@mabadive/app-common-model';
import clsx from 'clsx';
import React from 'react';
import { ClubDialogsStateOld } from 'src/pages/_dialogs';
import {
  AppButton,
  AppMessageLight,
  AppVisible,
} from '../../../../../../_core/modules/layout';
import {
  AppHeroIcons,
  AppIcons,
} from '../../../../../../_core/modules/layout/icons';
import {
  DiverBookingSpace_BookingList_SwitchArchivedBookingVisibility,
  DiverBookingSpace_BookingListItem,
} from '../../DiverBookingSpace/components/DiverBookingSpace_BookingList';
import { DiverBookingPageAggregatedDataCore } from '../models';
import { DiverBookingPageLoadedContentFocus } from '../models/01.loaded-content';
import { DiverBookingPageUpdateState } from '../models/02.update-state';
import { BookingTabModel } from '../models/05.view-state';
import { DiverBookingPageActions } from '../useDiverBookingPageActions.hook';
import {
  DiverBookingPageGlobalState,
  DiverBookingPageSetUpdateStateFn,
} from '../useDiverBookingPageGlobalState.hook';
import {
  DiverBookingCard,
  DiverBookingPageBookingTabHeaderAutoFixCustomerUpdates,
} from './DiverBookingCard';
import {
  DiverBookingPageBookingCardLocalState,
  useDiverBookingPageBookingCardLocalState,
} from './useDiverBookingPageBookingCardLocalState.hook';

export const DiverBookingPageBookingCard = ({
  globalState,
  dialogs,
  actions,
  bookingTabModel,
  aggregatedData,
  bookingTabModels,
  focus,
  updateState,
  setUpdateState,
  openSessionDialog,
  openDiverSelector,
}: {
  globalState: DiverBookingPageGlobalState;
  dialogs: ClubDialogsStateOld;
  actions: DiverBookingPageActions;
  bookingTabModel: BookingTabModel;
  aggregatedData: DiverBookingPageAggregatedDataCore;
  bookingTabModels: BookingTabModel[];
  focus: DiverBookingPageLoadedContentFocus;
  updateState: DiverBookingPageUpdateState;
  setUpdateState: DiverBookingPageSetUpdateStateFn;
  openSessionDialog: ({ diveSession }: { diveSession: DiveSession }) => void;
  openDiverSelector: ({ bookingId }: { bookingId: string }) => void;
}) => {
  const localState: DiverBookingPageBookingCardLocalState =
    useDiverBookingPageBookingCardLocalState({
      globalState,
      dialogs,
      bookingTabModel,
      aggregatedData,
      bookingTabModels,
      updateState,
      setUpdateState,
      openSessionDialog,
      openDiverSelector,
    });

  const { state, actions: localActions } = localState;
  const bookingsArchivedCount =
    globalState.data?.aggregatedData?.bookingResumesLoaded.filter(
      (x) => !x.booking?.active,
    ).length;

  return (
    <>
      {state.autoFixes.bookingCustomerUpdates?.length > 0 &&
        !globalState.state.skipBookingCustomerUpdates && (
          <DiverBookingPageBookingTabHeaderAutoFixCustomerUpdates
            className="mx-4 mt-4 mb-8 border-4 border-gray-800 app-p-content"
            bookingTabModel={bookingTabModel}
            globalState={globalState}
            bookingCustomerUpdates={state.autoFixes.bookingCustomerUpdates}
            showTitle={true}
            onClickClose={() =>
              globalState.state.setSkipBookingCustomerUpdates(true)
            }
          />
        )}
      <div className="bg-white app-p-content grid gap-2">
        <DiverBookingCard
          className={clsx(
            'mb-6 pb-6 border-b-4 border-dotted border-gray-300',
            state.isHidden && 'hidden',
          )}
          localState={localState}
          focus={focus}
          bookingTabModel={bookingTabModel}
          globalState={globalState}
          dialogs={dialogs}
          actions={actions}
          onClickParticipant={(x) =>
            localActions.onClickParticipant({
              ...x,
              bookingTabModel,
            })
          }
          onClickMember={(x) => localActions.onClickMember(x)}
          onClickBooking={(bookingTabModel, action) =>
            localActions.onClickBooking({
              bookingTabModel,
              action,
            })
          }
          onClickSession={(x) => localActions.onClickSession(x)}
        />
        <div className="my-3 grid gap-4">
          <h3 className="uppercase text-gray-500">Autres réservations</h3>{' '}
          <div className="flex flex-col md:flex-row gap-4 justify-between">
            {((bookingsArchivedCount > 0 &&
              (bookingsArchivedCount > 1 ||
                bookingTabModel.aggregatedBooking.booking.active)) ||
              bookingTabModels.length > 1) && (
              <div
                className="text-gray-500 hover:text-gray-700 flex items-center font-bold cursor-pointer gap-2"
                onClick={() => actions.backToBookingList()}
              >
                <AppIcons.action.back className="inline-block w-4 h-4" /> Retour
                à la liste des réservations
              </div>
            )}
            {(bookingsArchivedCount > 1 ||
              bookingTabModel.aggregatedBooking.booking.active) && (
              <DiverBookingSpace_BookingList_SwitchArchivedBookingVisibility
                globalState={globalState}
              />
            )}
          </div>
          {bookingTabModels.length > 1 && (
            <div className="grid gap-4">
              {bookingTabModels
                .filter((x) => x !== bookingTabModel)
                .map((bookingTabModel, bookingIndex) => (
                  <DiverBookingSpace_BookingListItem
                    key={bookingTabModel.bookingId}
                    bookingTabModel={bookingTabModel}
                    globalState={globalState}
                    actions={actions}
                  />
                ))}
            </div>
          )}
          <AppVisible role="club-edit-participant">
            <AppMessageLight className="my-3">
              <div className="flex flex-col gap-4">
                <div className="flex flex-col gap-2">
                  <div>
                    Si le plongeur vient avec un autre groupe, vous pouvez créer
                    une nouvelle réservation.
                  </div>
                  <div>
                    N'oubliez pas d'archiver les anciennes réservations.
                  </div>
                </div>
                <div className="flex flex-col sm:flex-row justify-start gap-x-8 gap-y-4">
                  <AppButton
                    icon={AppHeroIcons.actionAdd}
                    color="primary-outline-light"
                    onClick={() => {
                      actions.createNewBooking({ onSuccess: 'openBooking' });
                      actions.scrollToTopWithDelay();
                    }}
                  >
                    <span>Créer une nouvelle réservation</span>
                  </AppButton>
                </div>
              </div>
            </AppMessageLight>
          </AppVisible>
        </div>
        {/* <AppVisible role="club-edit-participant">
        <AppMessage
          className="hidden sm:block mb-5"
          title={'Instruction'}
          message={
            <>
              <div>
                Sur cette page, vous pouvez gérer les réservations d'un ou
                plusieurs plongeurs.
              </div>
              <div className="mt-2 mb-1 font-bold">Principales actions:</div>
              <ul className="ml-3 list-disc">
                {subTab === 'booking-registration' && (
                  <>
                    <li>
                      Cliquer sur la <b>case plongée d'un plongeur</b>{' '}
                      l'inscrire ou modifier sa plongée (PA20, baptême, gaz,
                      matériel...)
                    </li>
                  </>
                )}
                {subTab === 'booking-participants-list' && (
                  <>
                    <li>
                      Cliquer sur le <b>nom d'un plongeur</b> pour modifier ses
                      caractéristiques (nom, niveau, téléphone, date de
                      naissance...)
                    </li>
                  </>
                )}
              </ul>
            </>
          }
        />
      </AppVisible> */}
      </div>
    </>
  );
};
