/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  AppEntityUpdatePatch,
  BookingCustomerUpdate,
  DiveCertificationReference,
  JsonPatchOperation,
} from '@mabadive/app-common-model';
import {
  changeDescriptorManager,
  jsonPatcher,
} from '@mabadive/app-common-services';
import React, { useCallback, useMemo } from 'react';
import { AppButton } from 'src/business/_core/modules/layout/components/_tailwind';
import {
  AppHeroIcons,
  AppIconsAction,
} from 'src/business/_core/modules/layout/icons';
import { appWebConfig } from 'src/business/_core/modules/root/config';
import {
  BookingTabModel,
  DiverBookingPageUpdateState,
  PRO_BookingMemberFull,
} from '../../../models';

import clsx from 'clsx';
import { confirmDialog } from 'src/business/_core/modules/layout/components/ConfirmDialog/confirmDialog.service';
import { DiverBookingPageGlobalState } from '../../../useDiverBookingPageGlobalState.hook';

const showComment = appWebConfig.envId === 'dev' && false;

export const DiverBookingPageBookingTabHeaderAutoFixCustomerUpdates_ActionsBar =
  ({
    globalState,
    bookingCustomerUpdates,
    bookingTabModel,
    bookingMembersFullWithAutoFix,
    onAcceptOrDeny,
    className,
  }: {
    globalState: DiverBookingPageGlobalState;
    bookingCustomerUpdates: BookingCustomerUpdate[];
    bookingTabModel: BookingTabModel;
    bookingMembersFullWithAutoFix: PRO_BookingMemberFull[];
    onAcceptOrDeny: () => void;
    className?: string;
  }) => {
    const { updateState, setUpdateState, loadedContent } = globalState;

    const booking = bookingTabModel.aggregatedBooking.booking;

    const updateStateWithAutoFixNow = useMemo(() => {
      let updateStateLocal: DiverBookingPageUpdateState = {
        ...updateState,
      };
      bookingCustomerUpdates.forEach((upd) => {
        if (upd.payload.updateState) {
          updateStateLocal.bookingMembersChanges =
            updateStateLocal.bookingMembersChanges.concat(
              upd.payload.updateState.bookingMembersChanges,
            );
          updateStateLocal.diversChanges =
            updateStateLocal.diversChanges.concat(
              upd.payload.updateState.diversChanges,
            );
        }

        const patchOperations = jsonPatcher.compareObjects(
          upd,
          {
            ...upd,
            status: 'ok',
            reviewDate: new Date(),
          },
          {},
        );
        if (patchOperations.length) {
          const patch: AppEntityUpdatePatch = {
            pk: upd._id,
            patchOperations,
          };
          updateStateLocal.bookingCustomerUpdatesChanges =
            changeDescriptorManager.updateOne(patch, {
              changeDescriptors:
                updateStateLocal.bookingCustomerUpdatesChanges ?? [],
            });
        }

        // updateStateLocal.bookingCustomerUpdatesChanges =
        //   changeDescriptorManager.deleteOne(upd._id, {
        //     changeDescriptors: updateStateLocal.bookingCustomerUpdatesChanges,
        //   });
      });

      bookingMembersFullWithAutoFix.forEach((bookingMemberFull) => {
        const { diver, bookingMember } = bookingMemberFull;
        const expectedDive = bookingMember?.details?.expectedDive;
        const defaultDiveConfig = diver.defaultDiveConfig
          ? { ...diver.defaultDiveConfig }
          : {};
        let defaultDiveConfigUpdated = false;
        if (!defaultDiveConfig?.diveMode && expectedDive?.diveMode) {
          defaultDiveConfig.diveMode = expectedDive?.diveMode;
          defaultDiveConfigUpdated = true;
        }
        if (
          defaultDiveConfig?.diveMode === 'autonomous' ||
          defaultDiveConfig?.diveMode === 'supervised' ||
          defaultDiveConfig?.diveMode === 'instructor'
        ) {
          // TOOD gérer spécifiquement autoSupervisedDetails
          if (
            !defaultDiveConfig?.certificationReference &&
            diver.mainCertificationReference
          ) {
            defaultDiveConfig.certificationReference =
              diver.mainCertificationReference as unknown as DiveCertificationReference;
            defaultDiveConfigUpdated = true;
          }
          if (
            !defaultDiveConfig?.certificationDeepDiver &&
            diver.divingCertification1?.specialties?.deepDiver
          ) {
            defaultDiveConfig.certificationDeepDiver =
              diver.divingCertification1?.specialties?.deepDiver;
            defaultDiveConfigUpdated = true;
          }
        }
        if (defaultDiveConfigUpdated) {
          const patchOperations = jsonPatcher.compareObjects(
            diver,
            {
              ...diver,
              defaultDiveConfig,
            },
            {},
          );
          if (patchOperations.length) {
            const patch: AppEntityUpdatePatch = {
              pk: diver._id,
              patchOperations,
            };
            updateStateLocal.diversChanges = changeDescriptorManager.updateOne(
              patch,
              {
                changeDescriptors: updateStateLocal.diversChanges,
              },
            );
          }
        }
      });

      return updateStateLocal;
    }, [bookingCustomerUpdates, bookingMembersFullWithAutoFix, updateState]);

    const acceptCustomerUpdates = useCallback(
      ({ disableCustomerUpdate }: { disableCustomerUpdate: boolean }) => {
        onAcceptOrDeny && onAcceptOrDeny();
        const updateStateLocal = {
          ...updateStateWithAutoFixNow,
        };
        if (
          disableCustomerUpdate &&
          booking.bookingCustomerConfig?.customerUi?.enableCustomerUpdate
        ) {
          // mark update as accepted
          const patchOperations: JsonPatchOperation = {
            op: 'replace',
            path: '/bookingCustomerConfig/customerUi/enableCustomerUpdate',
            value: false,
          };
          const patch: AppEntityUpdatePatch = {
            pk: booking._id,
            patchOperations: [patchOperations],
          };
          updateStateLocal.bookingsChanges = changeDescriptorManager.updateOne(
            patch,
            {
              changeDescriptors: updateStateLocal.bookingsChanges ?? [],
            },
          );
        }
        setUpdateState(updateStateLocal, {
          action: 'accept customer updates',
          meta: {
            disableCustomerUpdate,
            bookingId: booking._id,
          },
        });
      },
      [
        booking._id,
        booking.bookingCustomerConfig?.customerUi?.enableCustomerUpdate,
        onAcceptOrDeny,
        setUpdateState,
        updateStateWithAutoFixNow,
      ],
    );

    const denyCustomerUpdates = useCallback(() => {
      onAcceptOrDeny && onAcceptOrDeny();
      const updateStateLocal: DiverBookingPageUpdateState = {
        ...updateState,
      };

      bookingCustomerUpdates.forEach((upd) => {
        const patchOperations = jsonPatcher.compareObjects(
          upd,
          {
            ...upd,
            status: 'ko',
            reviewDate: new Date(),
          },
          {},
        );
        if (patchOperations.length) {
          const patch: AppEntityUpdatePatch = {
            pk: upd._id,
            patchOperations,
          };
          updateStateLocal.bookingCustomerUpdatesChanges =
            changeDescriptorManager.updateOne(patch, {
              changeDescriptors:
                updateStateLocal.bookingCustomerUpdatesChanges ?? [],
            });
        }
      });

      setUpdateState(updateStateLocal, {
        action: 'deny customer updates',
        meta: {
          bookingId: booking._id,
        },
      });
    }, [
      booking._id,
      bookingCustomerUpdates,
      onAcceptOrDeny,
      setUpdateState,
      updateState,
    ]);

    return (
      <div
        className={clsx(
          'flex gap-4 justify-between md:justify-start',
          className,
        )}
      >
        <AppButton
          className="font-bold"
          style={{ minWidth: '6rem' }}
          size="normal"
          color={'primary-bg'}
          icon={AppHeroIcons.actionConfirm}
          onClick={async () => {
            const disableCustomerUpdate = await confirmDialog.confirmPromise({
              title: 'Informations enregistrées',
              message: 'Le client a-t-il complété toutes les informations ?',
              type: 'noYesInfo',
            });

            acceptCustomerUpdates({
              disableCustomerUpdate,
            });
          }}
        >
          Accepter les modifications
        </AppButton>
        <AppButton
          className="font-bold"
          style={{ minWidth: '6rem' }}
          size="normal"
          color={'danger-outline-light'}
          icon={AppIconsAction.delete}
          onClick={async () => {
            if (
              await confirmDialog.confirmPromise({
                title: 'Supprimer les modifications',
                message:
                  'Les modifications effectuées par le client seront annulées',
                type: 'noYesDanger',
              })
            ) {
              denyCustomerUpdates();
            }
          }}
        >
          Supprimer les modifications
        </AppButton>
      </div>
    );
  };
