import React, { useCallback } from 'react';
import { DiverPurchaseStoreEditorDialogState } from '../../models';

export function useOpenCreatePurchaseStoreDialog({
  setPurchaseStoreEditorDialogState,
}: {
  setPurchaseStoreEditorDialogState: React.Dispatch<
    React.SetStateAction<DiverPurchaseStoreEditorDialogState>
  >;
}) {
  return useCallback(
    ({
      diver,
      aggregatedData,
    }: Pick<DiverPurchaseStoreEditorDialogState, 'diver' | 'aggregatedData'>) =>
      setPurchaseStoreEditorDialogState({
        isOpen: true,
        mode: 'create',
        diver,
        aggregatedData,
      }),
    [setPurchaseStoreEditorDialogState],
  );
}
