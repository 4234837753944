/* eslint-disable jsx-a11y/alt-text */
import {
  EcommerceProductArticleGql_Company,
  EcommerceProductGql_Company,
} from '@mabadive/app-common-model';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { useClubSettings } from '../../../../../../../business/club/data/hooks';
import { AppPriceLabel } from '../../../../../../../business/club/modules/_common/ui';
import { useAppCurrencyMain } from '../../../../../../_components/options';
import { ProProductsPage_CategoryViewPanelLocalState } from './useProProductsPage_CategoryViewPanelLocalState.hook';

export const ProProductsPage_CategoryViewPanel_ProductCoverCard_CoverResume = ({
  product,
  productArticles,
  localState,
  className,
}: {
  product: EcommerceProductGql_Company;
  productArticles?: EcommerceProductArticleGql_Company[];
  localState: ProProductsPage_CategoryViewPanelLocalState;
  className?: string;
}) => {
  const clubSettings = useClubSettings();
  const clubPublicSettings = clubSettings?.publicSettings;
  const mainCurrency = useAppCurrencyMain({});

  const { data, dialogs } = localState;

  const pricesBounds = useMemo(() => {
    const unitPrices = productArticles
      .reduce(
        (acc, p) => acc.concat(p.pricing.prices.map((pr) => pr.unitPrice)),
        [] as number[],
      )
      .filter((x) => x > 0);
    if (unitPrices.length > 0) {
      return {
        min: Math.min(...unitPrices),
        max: Math.max(...unitPrices),
      };
    }
    return {
      min: 0,
      max: 0,
    };
  }, [productArticles]);
  const productBooklet = useMemo(() => {
    return data.booklets.find((x) => x._id === product.bookletId);
  }, [data.booklets, product.bookletId]);

  const productBookletPage = useMemo(() => {
    return data.bookletPages.find((x) => x._id === productBooklet?.rootPageId);
  }, [data.bookletPages, productBooklet?.rootPageId]);
  return (
    <div className={clsx('text-sm font-normal text-gray-600', className)}>
      {productArticles.length > 0 && (
        <span>
          <b>
            {productArticles.length} tarif
            {productArticles.length > 1 ? 's' : ''}{' '}
          </b>
          {product.details.minAge > 0 && (
            <span> dès {product.details.minAge} ans</span>
          )}
        </span>
      )}

      {pricesBounds?.min > 0 && (
        <span className="">
          {pricesBounds?.min === pricesBounds?.max ? (
            <span>
              {' - '}
              <AppPriceLabel
                className="font-bold"
                amount={pricesBounds?.min}
                mainCurrency={mainCurrency}
              />
            </span>
          ) : (
            <span>
              {' '}
              à partir de{' '}
              <AppPriceLabel
                className="font-bold"
                amount={pricesBounds?.min}
                mainCurrency={mainCurrency}
              />
            </span>
          )}
        </span>
      )}
    </div>
  );
};
