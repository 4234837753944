import React, { useCallback, useMemo } from 'react';
import { ClubDialogsStateOld } from 'src/pages/_dialogs';
import { BillingTabParticipantPurchase } from '../../models';
import { DiverBookingPageBillingTabState } from '../../tabs/DiverBookingPageBillingTab/useDiverBookingPageBillingTabState.hook';
import { DiverBookingPageActions } from '../../useDiverBookingPageActions.hook';
import { DiverBookingPageGlobalState } from '../../useDiverBookingPageGlobalState.hook';
import { BillingParticipantPurchaseResumeTableRow1 } from './BillingParticipantPurchaseResumeTableRow1';
import { BillingParticipantPurchaseResumeTableRow2 } from './BillingParticipantPurchaseResumeTableRow2';

export function BillingParticipantPurchaseResumeTableRowGroup({
  participantPurchase,
  billingTabLocalState,
  globalState,
  dialogs,
  actions,
  className,
}: {
  participantPurchase: BillingTabParticipantPurchase;
  billingTabLocalState: DiverBookingPageBillingTabState;
  globalState: DiverBookingPageGlobalState;
  dialogs: ClubDialogsStateOld;
  actions: DiverBookingPageActions;
  className?: string;
}) {
  const {
    aggregatedData,
    model,
    selectedDiverId,
    setSelectedDiverId,
    diversFilterOptions,
    createPlan,
    createTraining,
    createFirstDive,
  } = billingTabLocalState;

  const { openPurchaseRefs, setOpenPurchaseRefs } = globalState;

  const purchasePackage =
    participantPurchase?.purchasePackageWithPayments?.purchasePackage;

  const isExpandable = purchasePackage?.productPackageType !== 'store';

  const isExpanded = useMemo(
    () => openPurchaseRefs.includes(participantPurchase.ref),
    [openPurchaseRefs, participantPurchase.ref],
  );

  const setIsExpanded = useCallback(() => {
    if (isExpanded) {
      setOpenPurchaseRefs(
        openPurchaseRefs.filter((ref) => ref !== participantPurchase.ref),
      );
    } else {
      setOpenPurchaseRefs(openPurchaseRefs.concat([participantPurchase.ref]));
    }
  }, [
    isExpanded,
    openPurchaseRefs,
    participantPurchase.ref,
    setOpenPurchaseRefs,
  ]);
  return (
    <>
      <BillingParticipantPurchaseResumeTableRow1
        className={className}
        participantPurchase={participantPurchase}
        billingTabLocalState={billingTabLocalState}
        globalState={globalState}
        dialogs={dialogs}
        actions={actions}
        isExpandable={isExpandable}
        isExpanded={isExpanded}
        setIsExpanded={setIsExpanded}
      />
      <BillingParticipantPurchaseResumeTableRow2
        className={className}
        participantPurchase={participantPurchase}
        billingTabLocalState={billingTabLocalState}
        globalState={globalState}
        dialogs={dialogs}
        actions={actions}
        isExpandable={isExpandable}
        isExpanded={isExpanded}
      />
    </>
  );
}
