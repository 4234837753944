import { Disclosure, Transition } from '@headlessui/react';
import React, { FC, useMemo } from 'react';
import { AppHeroIcons } from '../../../icons';

type AppDisclosure_RenderCustomIconFn = (attrs: {
  isOpen: boolean;
}) => React.ReactNode;

export const AppDisclosure: FC<{
  title: React.ReactNode;
  showExpandIcon?: boolean;
  renderExpandIcon?: AppDisclosure_RenderCustomIconFn;
  expandIconPosition?: 'left' | 'right' | 'both';
  isExpandable?: boolean;
  defaultExpanded?: boolean;
  setIsExpanded?: React.Dispatch<React.SetStateAction<boolean>>;
  className?: string;
  children?: React.ReactNode | React.ReactNode[];
}> = ({
  className,
  title,
  showExpandIcon = true,
  renderExpandIcon: renderExpandIconIput,
  expandIconPosition = 'right',
  isExpandable = true,
  children,
  defaultExpanded = false,
  setIsExpanded = () => {},
}) => {
  const renderExpandIcon: AppDisclosure_RenderCustomIconFn = useMemo(() => {
    if (renderExpandIconIput) {
      return renderExpandIconIput;
    }
    const fn: AppDisclosure_RenderCustomIconFn = ({
      isOpen,
    }: {
      isOpen: boolean;
    }) => {
      return isOpen ? (
        <AppHeroIcons.actionReduce className="w-4 h-4" />
      ) : (
        <AppHeroIcons.actionExtend className="w-4 h-4" />
      );
    };

    return fn;
  }, [renderExpandIconIput]);
  return isExpandable ? (
    // https://headlessui.dev/react/disclosure
    // NOTE: pas encore possible de controller ce composant: https://github.com/tailwindlabs/headlessui/discussions/362
    <Disclosure defaultOpen={defaultExpanded}>
      {({ open }) => (
        <>
          <Disclosure.Button
            // onChange={() => {
            //   console.log('xxx onchange');
            // }}
            className={`focus:outline-none ${className ?? ''}`}
            onClick={(e) => {
              e.stopPropagation();
              setIsExpanded(open);
            }}
          >
            {showExpandIcon &&
              (expandIconPosition === 'left' ||
                expandIconPosition === 'both') &&
              renderExpandIcon({ isOpen: open })}
            {title}
            {showExpandIcon &&
              (expandIconPosition === 'right' ||
                expandIconPosition === 'both') &&
              renderExpandIcon({ isOpen: open })}
          </Disclosure.Button>

          {/*
          Use the Transition + open render prop argument to add transitions.
        */}
          <Transition
            show={open}
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            {/*
            Don't forget to add `static` to your Disclosure.Panel!
          */}
            <Disclosure.Panel static>{children}</Disclosure.Panel>
          </Transition>
        </>
      )}
    </Disclosure>
  ) : (
    <>{title}</>
  );
};
