import { AppInquiryForClubResumeGql_Company } from '@mabadive/app-common-model';
import { inquiryFormatter } from '@mabadive/app-common-services';
import Tippy from '@tippyjs/react';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { AppIconsInquiry } from '../../../../../../../../../_core/modules/layout/icons';

export function BookingResumeMembersInquiryBadge({
  inquiry,
  status,
}: {
  inquiry: AppInquiryForClubResumeGql_Company;
  status: 'pending' | 'submitted' | 'ok';
  className?: string;
}) {
  const type = inquiry.type;
  const Icon = AppIconsInquiry[inquiry.type];

  const inquiryTypeLabel = useMemo(
    () => inquiryFormatter.formatInquiryType(inquiry.type),
    [inquiry.type],
  );

  return (
    <Tippy delay={[200, 50]} placement="top" content={inquiryTypeLabel}>
      <div
        className={clsx(
          'place-self-center inline-flex px-1 py-1 items-center gap-1 text-base font-bold uppercase rounded-lg',
          'border',
        )}
        style={
          status === 'ok'
            ? {
                color: inquiry.settings.color,
                borderColor: inquiry.settings.color,
              }
            : status === 'submitted'
            ? {
                color: '#9ca3af',
                borderColor: '#f87171',
              }
            : {
                color: '#9ca3af',
                borderColor: '#9ca3af',
              }
        }
      >
        {Icon && <Icon className="h-5 w-5" />}{' '}
        {inquiry.settings?.shortName && (
          <span>{inquiry.settings?.shortName}</span>
        )}
      </div>
    </Tippy>
  );
}
