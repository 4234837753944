/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import { useWatch } from 'react-hook-form';
import { useClubResume } from 'src/business/club/data/hooks';
import { AppInputBooleanRHF, AppInputRHF } from 'src/lib/form';
import { AppFormControlRHF } from 'src/lib/form/components/AppFormControl/AppFormControlRHF';
import { AppExpansionPanel } from '../../../../../business/_core/modules/layout';
import { ClubSettingsCustomerFormFieldsEditForm_Booking_CustomBooleans } from './ClubSettingsCustomerFormFieldsEditForm_Booking_CustomBooleans';
import { ClubSettingsCustomerFormFieldsEditForm_Booking_CustomFields } from './ClubSettingsCustomerFormFieldsEditForm_Booking_CustomFields';
import { ClubSettingsCustomerFormFieldsEditPageLocalState } from './useClubSettingsCustomerFormFieldsEditPageLocalState.hook';

export const ClubSettingsCustomerFormFieldsEditForm_Booking = ({
  localState,
  className,
}: {
  localState: ClubSettingsCustomerFormFieldsEditPageLocalState;
  className?: string;
}) => {
  const { form } = localState;
  const { control, setValue } = form;

  const clubResume = useClubResume();

  const [bookingExternalRef, bookingExternalRefLabel] = useWatch({
    control,
    name: [
      'clubSettings.customer.booking.externalRef',
      'clubSettings.customer.booking.externalRefLabel',
    ],
  });
  const [isExpanded, setIsExpanded] = useState(true);

  return (
    <AppExpansionPanel
      className={className}
      isExpandable={true}
      isExpanded={isExpanded}
      expandPanel={() => {
        return () => setIsExpanded(!isExpanded);
      }}
      panelId="archived-purchases"
      renderSummaryTitle={() => (
        <div className="flex justify-between items-center">
          <h2 className="text-left uppercase text-lg leading-6 font-medium text-gray-800">
            Réservation
          </h2>
          {!isExpanded && (
            <span className="hidden sm:flex text-xs text-gray-400">
              CLIQUER POUR CONFIGURER
            </span>
          )}
        </div>
      )}
      renderSummaryExtra={() => null}
      renderDetails={() => (
        <div className="flex flex-col gap-y-2">
          <div className="flex gap-4 flex-wrap">
            <AppFormControlRHF
              control={control}
              name="clubSettings.customer.booking.externalRef"
              renderComponent={(props) => (
                <AppInputBooleanRHF
                  {...props}
                  type="checkbox"
                  description={'ID perso'}
                  onChange={(value) => {
                    if (value && !bookingExternalRefLabel?.trim()?.length) {
                      setValue(
                        'clubSettings.customer.booking.externalRefLabel',
                        'N° résa',
                      );
                    }
                  }}
                />
              )}
            />
            {bookingExternalRef && (
              <AppFormControlRHF
                className="max-x-40"
                control={control}
                name="clubSettings.customer.booking.externalRefLabel"
                label="Nom du champ"
                renderComponent={(props) => <AppInputRHF {...props} />}
              />
            )}
          </div>
          <AppFormControlRHF
            control={control}
            name="clubSettings.customer.booking.agency"
            renderComponent={(props) => (
              <AppInputBooleanRHF
                {...props}
                type="checkbox"
                description={'Agence'}
              />
            )}
          />
          <ClubSettingsCustomerFormFieldsEditForm_Booking_CustomFields
            localState={localState}
          />
          <ClubSettingsCustomerFormFieldsEditForm_Booking_CustomBooleans
            localState={localState}
          />
        </div>
      )}
    />
  );
};
