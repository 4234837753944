/* eslint-disable @typescript-eslint/no-unused-vars */
import { useClubResume } from 'src/business/club/data/hooks';
import {
  ClubDialogsState,
  UseClubDialogsProps,
  useClubDialogs,
  useClubDialogsActionsPersist,
} from 'src/pages/_dialogs';

import {
  EcommerceProductArticleFullGql_Company,
  ProMultiOperationResult,
} from '@mabadive/app-common-model';
import { appUrlBuilderCustomerSpace } from '@mabadive/app-common-services';
import { useCallback, useMemo, useState } from 'react';
import { appWebConfig } from '../../../../../business/_core/modules/root/config';
import { ClubSettingsOnlineBookingProductsPageViewState } from './_model';
import { clubSettingsOnlineBookingProductsPageUrlManager } from './_services/clubSettingsOnlineBookingProductsPageUrlManager.service';
import { useProEcommerceDataLoadedContent } from './_services/data';
import {
  EcommerceProductArticleEditorDialogClient,
  useEcommerceProductArticleEditorDialogClient,
} from './components';
import {
  EcommerceCategoryEditorDialogClient,
  useEcommerceCategoryEditorCallbackPersist,
  useEcommerceCategoryEditorDialogClient,
} from './components/EcommerceCategoryEditor';
import { EcommerceCategoryEditorCallbackProps } from './components/EcommerceCategoryEditor/useEcommerceCategoryEditorPanelLocalState.hook';
import { useEcommerceProductArticleEditorCallbackPersist } from './components/EcommerceProductArticleEditor/useEcommerceProductArticleEditorCallbackPersist.hook';
import { EcommerceProductArticleEditorCallbackProps } from './components/EcommerceProductArticleEditor/useEcommerceProductArticleEditorPanelLocalState.hook';
import {
  EcommerceProductEditorDialogClient,
  useEcommerceProductEditorDialogClient,
} from './components/EcommerceProductEditor';
import { useEcommerceProductEditorCallbackPersist } from './components/EcommerceProductEditor/useEcommerceProductEditorCallbackPersist.hook';
import { EcommerceProductEditorCallbackProps } from './components/EcommerceProductEditor/useEcommerceProductEditorPanelLocalState.hook';
import { ProProductsPage_CategoryContentCard_TabId } from './panels/ProProductsPage_CategoryViewPanel/useProProductsPage_CategoryViewPanelLocalState.hook';
import { useClubSettingsOnlineBookingProductsPageUpdateURL } from './useClubSettingsOnlineBookingProductsPageUpdateURL.hook';

export const useClubSettingsOnlineBookingProductsPageLocalState = () => {
  const clubResume = useClubResume();
  const clubSettings = clubResume?.clubSettings;
  const clubPublicReference = clubResume.clubSettings?.clubPublicReference;

  const actionsPersist: UseClubDialogsProps = useClubDialogsActionsPersist({
    // appCompanyMessageTemplateEdit: {}, // ici on peut récupérer un callback si besoin
  });
  const dialogsState: ClubDialogsState = useClubDialogs(actionsPersist);

  const queryParams =
    clubSettingsOnlineBookingProductsPageUrlManager.getQueryParameters();

  const [viewState, setViewState] =
    useState<ClubSettingsOnlineBookingProductsPageViewState>({
      mode: queryParams.mode ?? 'categories-list',
      categoryId: queryParams.categoryId,
      productId: queryParams.productId,
      modeCategoryView: {
        tabId: 'products',
      },
    });

  const { loadedContent, loadableContent, refetch, updateCache } =
    useProEcommerceDataLoadedContent();

  const aggregatedContent = useMemo(() => {
    const productArticlesFull: EcommerceProductArticleFullGql_Company[] =
      !loadedContent?.productArticles
        ? undefined
        : loadedContent?.productArticles.map((productArticle) => {
            const booklet = loadedContent?.booklets.find(
              (b) => b._id === productArticle.bookletId,
            );
            const bookletPages = loadedContent?.bookletPages.filter(
              (p) => p.bookletId === productArticle.bookletId,
            );
            const productArticleFull: EcommerceProductArticleFullGql_Company = {
              ...productArticle,
              booklet: {
                ...booklet,
                pages: bookletPages,
              },
            };
            return productArticleFull;
          });

    return {
      productArticlesFull,
    };
  }, [
    loadedContent?.bookletPages,
    loadedContent?.booklets,
    loadedContent?.productArticles,
  ]);

  const categoryEditorDialog: EcommerceCategoryEditorDialogClient =
    useEcommerceCategoryEditorDialogClient();
  const productEditorDialog: EcommerceProductEditorDialogClient =
    useEcommerceProductEditorDialogClient();

  const productArticleEditorDialog: EcommerceProductArticleEditorDialogClient =
    useEcommerceProductArticleEditorDialogClient();

  useClubSettingsOnlineBookingProductsPageUpdateURL(viewState, {
    enableRouteParams: true,
  });

  const showCategories = useCallback(() => {
    setViewState({
      mode: 'categories-list',
    });
  }, []);
  const selectCategory = useCallback((categoryId: string) => {
    setViewState({
      mode: 'category-view',
      categoryId,
      modeCategoryView: {
        tabId: 'products',
      },
    });
  }, []);
  const selectCategoryViewTab = useCallback(
    (tabId: ProProductsPage_CategoryContentCard_TabId) => {
      setViewState({
        ...viewState,
        modeCategoryView: {
          ...(viewState.modeCategoryView ?? {}),
          tabId,
        },
      });
    },
    [viewState],
  );
  const selectCategoryProduct = useCallback(
    ({ productId, categoryId }: { productId: string; categoryId: string }) => {
      setViewState({
        mode: 'product-view',
        categoryId,
        productId,
      });
    },
    [],
  );

  const customerSpaceCurrentPageURL = useMemo(() => {
    return appUrlBuilderCustomerSpace.buildCustomerOnlineBookingURL({
      diverWebUrl: appWebConfig.applications.diverWebUrl,
      clubPublicReference,
      categoryId: viewState.categoryId,
      productId: viewState.productId,
    });
  }, [clubPublicReference, viewState.categoryId, viewState.productId]);

  const isAnyDialogOpen =
    categoryEditorDialog.isOpen ||
    productEditorDialog.isOpen ||
    productArticleEditorDialog.isOpen;

  const categoryEditorCallback: EcommerceCategoryEditorCallbackProps =
    useEcommerceCategoryEditorCallbackPersist({
      onSuccess: (result: ProMultiOperationResult) =>
        // mise à jour du cache
        updateCache.multiOperationResult(result),
      closeDialog: () => categoryEditorDialog.closeDialog(),
    });
  const productEditorCallback: EcommerceProductEditorCallbackProps =
    useEcommerceProductEditorCallbackPersist({
      onSuccess: (result: ProMultiOperationResult) =>
        // mise à jour du cache
        updateCache.multiOperationResult(result),
      closeDialog: () => productEditorDialog.closeDialog(),
    });
  const productArticleEditorCallback: EcommerceProductArticleEditorCallbackProps =
    useEcommerceProductArticleEditorCallbackPersist({
      onSuccess: (result: ProMultiOperationResult) =>
        // mise à jour du cache
        updateCache.multiOperationResult(result),
      closeDialog: () => productArticleEditorDialog.closeDialog(),
    });

  return {
    state: {
      loadableContent,
      viewState,
      customerSpaceCurrentPageURL,
    },
    data: {
      ...loadedContent,
      ...aggregatedContent,
      updateCache,
    },
    actions: {
      refetch,
      showCategories,
      selectCategory,
      selectCategoryProduct,
      categoryEditorCallback,
      productEditorCallback,
      productArticleEditorCallback,
      selectCategoryViewTab,
    },
    dialogs: {
      state: dialogsState,
      categoryEditor: categoryEditorDialog,
      productEditor: productEditorDialog,
      productArticleEditor: productArticleEditorDialog,
      isAnyDialogOpen,
    },
  };
};

export type ClubSettingsOnlineBookingProductsPageLocalState = ReturnType<
  typeof useClubSettingsOnlineBookingProductsPageLocalState
>;
