/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useMemo } from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { useAppSecurityUserHasRole } from 'src/business/auth/services';
import { useClubResume } from 'src/business/club/data/hooks';
import { AppFormControlRHF } from 'src/lib/form/components/AppFormControl/AppFormControlRHF';

import {
  ClubProductPackageOfferView,
  DiveTrainingReferenceFrench,
} from '@mabadive/app-common-model';
import {
  AppButton,
  AppMessageLight,
} from '../../../../../../../business/_core/modules/layout';
import { AppIconsAction } from '../../../../../../../business/_core/modules/layout/icons';
import { AppMultiCheckboxesTailwindRHF } from '../../../../../../../lib/form/components/AppMultiCheckboxes/AppMultiCheckboxesTailwindRHF';
import { useTrainingOptionsFromServices } from '../../../../../../_components/options';
import { ClubSettingsServicesFormModel } from '../../../_model';
import { useUsedTrainingReferencesFromOffers } from '../useUsedTrainingReferencesFromOffers.hook';

export const ClubSettingsServicesTrainingFormOrgs_TrainingActivation_Fr = ({
  form,
  existingOffers,
}: {
  form: UseFormReturn<ClubSettingsServicesFormModel>;
  existingOffers?: ClubProductPackageOfferView[]; // utilisé pour les formations
}) => {
  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const isSuperAdmin = useAppSecurityUserHasRole('super-admin');

  const clubResume = useClubResume();

  const {
    trainingReferencesFr: trainingReferencesFountInOffersFr,
    trainingReferencesInt: trainingReferencesFountInOffersInt,
  } = useUsedTrainingReferencesFromOffers(existingOffers);

  const [globalSettingsFrenchEnabled, trainingReferencesFrEnabled] = useWatch({
    control,
    name: [
      'clubSettings.services.french.enabled',
      'clubSettings.publicSettings.services.training.trainingReferencesFr',
    ],
  });

  const trainingOptionsFr = useTrainingOptionsFromServices(
    {
      defaultOrgGroup: 'french',
      french: {
        enabled: true,
        defaultOrganizationReference: 'anmp',
        organizationReferences: [],
      },
      international: {
        enabled: false,
        defaultOrganizationReference: 'padi',
        organizationReferences: [],
      },
      store: {
        enabled: false,
      },
    },
    {},
  );

  const missingTrainingReferencesFr = useMemo(
    () =>
      trainingReferencesFountInOffersFr.filter(
        (ref) =>
          !trainingReferencesFrEnabled?.includes(
            ref as DiveTrainingReferenceFrench,
          ),
      ),
    [trainingReferencesFountInOffersFr, trainingReferencesFrEnabled],
  );
  const unusedTrainingReferencesFr = useMemo(
    () =>
      trainingReferencesFrEnabled?.filter(
        (ref) => !trainingReferencesFountInOffersFr.includes(ref),
      ),
    [trainingReferencesFountInOffersFr, trainingReferencesFrEnabled],
  );

  return (
    <div className="grid gap-4">
      <AppFormControlRHF
        label={'Formations'}
        required={globalSettingsFrenchEnabled}
        control={control}
        name={
          'clubSettings.publicSettings.services.training.trainingReferencesFr'
        }
        renderComponent={(props) => (
          <AppMultiCheckboxesTailwindRHF
            {...props}
            options={trainingOptionsFr}
            direction="horizontal"
            labelPosition="right"
          />
        )}
      />

      {trainingReferencesFountInOffersFr?.length > 0 && (
        <div className="mt-2 grid gap-2">
          {missingTrainingReferencesFr?.length > 0 && (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <AppMessageLight type="danger">
              <div className="grid gap-2 items-start">
                <div className="text-gray-500">
                  {missingTrainingReferencesFr.length} prestations sont
                  désactivées alors qu'elles ont un tarif défini:{' '}
                  {missingTrainingReferencesFr.slice(0, 8).join(', ')}
                  ...
                </div>
                <AppButton
                  icon={AppIconsAction.confirm}
                  onClick={() => {
                    setValue(
                      'clubSettings.publicSettings.services.training.trainingReferencesFr',
                      trainingReferencesFrEnabled?.concat(
                        missingTrainingReferencesFr as DiveTrainingReferenceFrench[],
                      ),
                      {
                        shouldValidate: true,
                        shouldDirty: true,
                        shouldTouch: true,
                      },
                    );
                  }}
                  color={'primary-outline-light'}
                >
                  Cocher les formations manquantes
                </AppButton>
              </div>
            </AppMessageLight>
          )}
          {unusedTrainingReferencesFr?.length > 0 && (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <AppMessageLight type="info">
              <div className="grid gap-2 items-start">
                <div className="text-gray-500">
                  {unusedTrainingReferencesFr.length} prestations sont activées
                  alors qu'elles n'ont pas de tarif défini:{' '}
                  {unusedTrainingReferencesFr.slice(0, 8).join(', ')}
                  ...
                </div>
                <AppButton
                  icon={AppIconsAction.confirm}
                  onClick={() => {
                    setValue(
                      'clubSettings.publicSettings.services.training.trainingReferencesFr',
                      trainingReferencesFrEnabled?.filter(
                        (ref) => !unusedTrainingReferencesFr.includes(ref),
                      ),
                      {
                        shouldValidate: true,
                        shouldDirty: true,
                        shouldTouch: true,
                      },
                    );
                  }}
                  color={'primary-outline-light'}
                >
                  Décocher les formations inutilisées
                </AppButton>
              </div>
            </AppMessageLight>
          )}
        </div>
      )}
    </div>
  );
};
