/* eslint-disable @typescript-eslint/no-unused-vars */
import clsx from 'clsx';
import React, { useCallback, useState } from 'react';
import {
  FieldArrayWithId,
  UseFormReturn,
  useFieldArray,
} from 'react-hook-form';
import {
  useClubResume,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import { bookingJourneyStepBuilder } from 'src/business/club/modules/club-diver-participant/_services';
import { AppFormLabel } from 'src/lib/form';
import { BookingInitialPlanEditFormModel } from '../model';
import {
  BookingJourneyDetailsStepsFormStep,
  ClubSettingsEquipmentEditPageFormModelOnClickAction,
} from './BookingJourneyDetailsStepsFormStep';

export const BookingJourneyDetailsStepsForm = ({
  form,
  className,
}: {
  form: UseFormReturn<BookingInitialPlanEditFormModel, object>;
  className?: string;
}) => {
  const diveCenterResume = useDiveCenterResume();
  const diveCenterId = diveCenterResume?._id;

  const clubResume = useClubResume();
  const customerSettings = clubResume.clubSettings?.customer;
  const generalSettings = clubResume.clubSettings?.general;
  const isMultiDiveCenters = clubResume.diveCenters.length > 1;

  const enableAddButton =
    isMultiDiveCenters ||
    (generalSettings?.accommodations?.enabled &&
      customerSettings?.general?.accomodation);

  const {
    register,
    handleSubmit,
    watch,
    formState,
    control,
    setValue,
    reset,
    getValues,
  } = form;

  const modelsFieldArray = useFieldArray({
    control,
    name: 'bookingJourney.steps',
    keyName: '_id',
    shouldUnregister: false, // si true, le tableau est vidé si le composant est masqué (unmount)
  });

  const [modelAnimations, setModelAnimations] =
    useState<{
      update: Date;
      up?: number;
      down?: number;
      remove?: number;
      clone?: number;
    }>();

  const isRecentAnimationUpdate =
    new Date().getTime() - modelAnimations?.update?.getTime() < 1000;

  const onClick = useCallback(
    (
      field: FieldArrayWithId<
        BookingInitialPlanEditFormModel,
        'bookingJourney.steps',
        '_id'
      >,
      fieldIndex: number,
      action: ClubSettingsEquipmentEditPageFormModelOnClickAction,
    ) => {
      const from = fieldIndex;
      const update = new Date();
      switch (action) {
        case 'move-down': {
          modelsFieldArray.move(from, from + 1);
          setModelAnimations({ update, up: from + 1, down: from });
          break;
        }
        case 'move-up': {
          modelsFieldArray.move(from, from - 1);
          setModelAnimations({ update, up: from, down: from - 1 });
          break;
        }
        case 'create': {
          const previousStep = modelsFieldArray.fields[fieldIndex];
          const defaultDate = previousStep?.departure?.date;
          const step = bookingJourneyStepBuilder.buildDefaultJourneyStep({
            diveCenterId,
            arrivalDate: defaultDate,
            departureDate: defaultDate,
          });
          modelsFieldArray.insert(from + 1, step);
          break;
        }
        case 'delete': {
          // setModelAnimations({ update, remove: from });
          modelsFieldArray.remove(from);
          // setTimeout(() => modelsFieldArray.remove(from), 100);
          break;
        }
      }
    },
    [diveCenterId, modelsFieldArray],
  );

  return (
    <div className={clsx('w-full grid gap-2', className)}>
      {modelsFieldArray.fields.length > 1 && (
        <AppFormLabel label="Étapes du séjour" />
      )}
      <div className="w-full grid gap-4">
        {modelsFieldArray.fields.map((field, fieldIndex) => {
          return (
            <BookingJourneyDetailsStepsFormStep
              key={`${field._id}-${fieldIndex}`}
              enableAddButton={enableAddButton}
              enableDeleteButton={
                enableAddButton && modelsFieldArray.fields.length > 1
              }
              form={form}
              fieldArray={modelsFieldArray}
              fieldIndex={fieldIndex}
              className={clsx(
                modelsFieldArray.fields.length > 1 &&
                  'border-l-4 border-gray-600 pl-2',
                isRecentAnimationUpdate &&
                  modelAnimations?.up === fieldIndex &&
                  'animate-move-up',
                isRecentAnimationUpdate &&
                  modelAnimations?.down === fieldIndex &&
                  'animate-move-down',
                isRecentAnimationUpdate &&
                  modelAnimations?.clone === fieldIndex &&
                  'animate-create',
                isRecentAnimationUpdate &&
                  modelAnimations?.remove === fieldIndex &&
                  'animate-remove',
              )}
              onClick={(action) => onClick(field, fieldIndex, action)}
            />
          );
        })}
      </div>
    </div>
  );
};
