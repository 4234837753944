/* eslint-disable @typescript-eslint/no-unused-vars */
import { default as React } from 'react';
import { useAppSecurityUserClubAuthorizations } from 'src/business/auth/services';
import { useAppCurrencyMain } from 'src/pages/_components/options';
import {
  useClubResume,
  useDiveCenterResume,
} from '../../../../../business/club/data/hooks';

export const ClubSettingsCustomerViewCard_CustomerOrigins = () => {
  const mainCurrency = useAppCurrencyMain();
  const diveCenterResume = useDiveCenterResume();
  const clubResume = useClubResume();

  const clubSettings = clubResume?.clubSettings;
  const customerSettings = clubSettings?.customer;
  const diveCenterPublicSettings = diveCenterResume?.publicSettings;

  const az = useAppSecurityUserClubAuthorizations();

  return (
    <>
      <h2 className="py-2  text-left uppercase text-lg leading-6 font-medium text-gray-800">
        Origines clients
      </h2>
      <div className="px-4 text-base text-gray-600">
        {(clubSettings.customer?.general?.customerOriginOptions ?? [])
          .map((x) => x.label)
          .join(', ')}
      </div>
    </>
  );
};
