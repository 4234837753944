/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useMemo } from 'react';
import { FieldArrayWithId, UseFormReturn, useWatch } from 'react-hook-form';
import { AppSingleAutocomplete2RHF } from 'src/lib/form';
import { AppFormControlRHF } from 'src/lib/form/components/AppFormControl/AppFormControlRHF';

import {
  DIVE_SERVICE_OFFER_EQUIPMENTS,
  DiveServiceOfferEquipment,
} from '@mabadive/app-common-model';
import {
  diveModeAnalyser,
  offerEquipmentFormatter,
} from '@mabadive/app-common-services';
import { ValueLabel } from 'src/business/club/modules/_common/form';
import { ClubSettingsProductOffersFormModel } from '../../_model';

export const ClubSettingsProductOffersFormOffer_Explo_Equipment = ({
  form,
  offerField,
  offerFieldIndex,
}: {
  form: UseFormReturn<ClubSettingsProductOffersFormModel>;
  offerField: FieldArrayWithId<
    ClubSettingsProductOffersFormModel,
    'offers',
    'id'
  >;
  offerFieldIndex: number;
}) => {
  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const i = offerFieldIndex;

  const [diveMode] = useWatch({
    control,
    name: [`offers.${i}.productPackage.diveAttributes.diveMode`],
  });

  const equipmentsOptions = useMemo(
    () =>
      DIVE_SERVICE_OFFER_EQUIPMENTS.filter((x) => {
        if (diveMode === 'first-dive') {
          return x === 'not-equipped'; // éq. club
        }
        if (diveModeAnalyser.hasExploPricingEquipmentOption(diveMode)) {
          return true;
        }

        return false;
      }).map((equipment) => {
        const option: ValueLabel<DiveServiceOfferEquipment> = {
          label: offerEquipmentFormatter
            .formatOfferEquipment(equipment, { format: 'full' })
            .toUpperCase(),
          value: equipment,
        };
        return option;
      }),
    // .concat([
    //   {
    //     label: '',
    //     value: null,
    //   },
    // ])

    [diveMode],
  );

  return diveMode && equipmentsOptions.length > 1 ? (
    <AppFormControlRHF
      className={'w-full col-span-2'}
      label="Équipement"
      control={control}
      name={`offers.${i}.productPackage.diveAttributes.equipment`}
      required={false}
      renderComponent={(props) => (
        <AppSingleAutocomplete2RHF {...props} options={equipmentsOptions} />
      )}
    />
  ) : null;
};
