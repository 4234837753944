/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  BookingGroup,
  ClubDiver,
  DiveSessionResumeGroup,
  DiveSessionResumeParticipant,
} from '@mabadive/app-common-model';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { BookingGroupLabel } from 'src/business/club/modules/club-diver-participant/pages/DiverBookingPage/components/BookingLabelsComponents';
import { CalendarSessionWidthMode } from '../session-common';
import { CalendarParticipantCellDiveModeBadgeInner } from '../session-common/CalendarParticipantCell/CalendarParticipantCellDiveModeBadgeInner';

export const DiveSessionSimpleCardParticipantsGroupPanelParticipantsResume = ({
  group,
  participants,
  widthMode,
  isPast,
  label,
  diveModeColor,
  hoverable,
}: {
  group: DiveSessionResumeGroup;
  participants: DiveSessionResumeParticipant[];
  widthMode: CalendarSessionWidthMode;
  isPast: boolean;
  label: string;
  diveModeColor: string;
  hoverable: boolean;
}) => {
  const participantsCount = participants.length;
  const showWarning =
    group?.isVirtualGroup && group?.diveMode !== 'theoretical-training';

  const recentUpdate = false;

  // TODO, voir CalendarParticipantCellDiveModeBadge pour éventuellement gérer les cas particuliers (session ou participants annulés)
  const isSessionOpen = true;

  const groupsStats = useMemo(
    () =>
      participants.reduce(
        (acc, p) => {
          if (p.booking?.bookingGroup) {
            // check if group exists
            const g = acc.groups.find(
              (g) =>
                g.group.name === p.booking.bookingGroup.name &&
                g.group.type === p.booking.bookingGroup.type,
            );
            if (g) {
              g.count++;
            } else {
              acc.groups.push({ group: p.booking.bookingGroup, count: 1 });
            }
          } else {
            acc.noGroupCount++;
          }
          // idem with contact now:

          if (p.booking?.bookingContact) {
            const c = acc.contacts.find(
              (c) =>
                c.bookingContact.firstName ===
                  p.booking.bookingContact.firstName &&
                c.bookingContact.lastName === p.booking.bookingContact.lastName,
            );
            if (c) {
              c.count++;
            } else {
              acc.contacts.push({
                bookingContact: p.booking.bookingContact,
                count: 1,
              });
            }
          } else {
            acc.noContactCount++;
          }

          return acc;
        },
        { groups: [], noGroupCount: 0, contacts: [], noContactCount: 0 } as {
          groups: {
            group: BookingGroup;
            count: number;
          }[];
          noGroupCount: number;
          contacts: {
            bookingContact: Pick<ClubDiver, 'firstName' | 'lastName'>;
            count: number;
          }[];
          noContactCount: number;
        },
      ),
    [participants],
  );

  const commonGroup: BookingGroup = useMemo(() => {
    if (groupsStats.noGroupCount === 0 && groupsStats.groups.length === 1) {
      return groupsStats.groups[0].group;
    }
  }, [groupsStats.groups, groupsStats.noGroupCount]);

  const commonContact: Pick<ClubDiver, 'firstName' | 'lastName'> =
    useMemo(() => {
      if (
        groupsStats.noContactCount === 0 &&
        groupsStats.contacts.length === 1
      ) {
        return groupsStats.contacts[0].bookingContact;
      }
    }, [groupsStats.contacts, groupsStats.noContactCount]);

  return (
    <div className={clsx('overflow-hidden flex gap-px')}>
      <div
        className={clsx(
          'w-full group flex items-stretch leading-3 text-xs overflow-hidden',
          recentUpdate && 'animate-flash-red-fast',
          (widthMode === 'tiny' || widthMode === 'small') && 'flex-grow',
          widthMode === 'tiny' || widthMode === 'small'
            ? ''
            : widthMode === 'large'
            ? ''
            : 'h-[28px]',
          isPast && 'opacity-[75%]',
        )}
      >
        <CalendarParticipantCellDiveModeBadgeInner
          className="flex-shrink-0"
          isSessionOpen={isSessionOpen}
          diveTypeLabel={label}
          diveTypeTag={undefined}
          diveMode={group.diveMode}
          bookingStatus={'confirmed'}
          diveSessionStatus={'on'}
          extraBadgeLabel={undefined}
          clubReference={undefined}
          isPast={isPast}
          hoverable={hoverable}
          widthMode={widthMode}
          recentUpdate={recentUpdate}
          // className={className}
        />

        <div
          className={clsx(
            'overflow-hidden flex-shrink flex-grow px-px sm:pr-1 md:text-left font-medium text-gray-600 border-l-2 sm:border-l-4 ',
            showWarning
              ? 'border-red-400 sm:border-red-200'
              : 'border-transparent',
          )}
        >
          <div className="pl-0.5 flex gap-0.5 justify-center items-center">
            <span className="truncate">
              x<span className="">{participantsCount}</span>
            </span>
            {/* // si y'a un seul participant, on n'affiche pas le nom du responsable, sinon ça porte à confusion (si c'est une famille par exemple) */}
            {participantsCount > 1 &&
              (widthMode === 'large' || widthMode === 'medium') && (
                <>
                  {commonGroup?.name ||
                  (commonGroup?.type && commonGroup?.type !== 'individual') ? (
                    <BookingGroupLabel
                      className=""
                      bookingGroup={commonGroup}
                      preferLabelOnly={true}
                    />
                  ) : commonContact?.lastName ?? commonContact?.firstName ? (
                    <BookingGroupLabel
                      bookingGroup={{
                        type: 'individual',
                        name:
                          commonContact?.lastName ?? commonContact?.firstName,
                      }}
                      preferLabelOnly={true}
                    />
                  ) : null}
                </>
              )}
            {/* {(widthMode === 'large' || widthMode === 'medium') &&
            group?.booking?.bookingGroup?.name && (
              <span className="ml-1 truncate text-app-xxs">
                {group?.booking?.bookingGroup?.name?.toUpperCase()}
              </span>
            )} */}
          </div>
        </div>
      </div>
    </div>
  );
};
