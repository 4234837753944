/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  BOOKING_GROUP_TYPES,
  BOOKING_STATUSES,
  BookingGroupType,
  BookingStatus,
} from '@mabadive/app-common-model';
import { nameFormatter } from '@mabadive/app-common-services';
import React, { useMemo } from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { useAppSecurityUser } from 'src/business/auth/services';
import { useClubResume } from 'src/business/club/data/hooks';
import { ValueLabel } from 'src/business/club/modules/_common/form/components/ValueLabel.model';
import { AppInputBooleanSwitchRHF, AppInputRHF } from 'src/lib/form';
import { AppFormControlRHF } from 'src/lib/form/components/AppFormControl/AppFormControlRHF';
import { AppSingleAutocomplete2RHF } from 'src/lib/form/components/AppSingleAutocomplete';
import { AppSingleSelect2HeadlessUIRHF } from 'src/lib/form/components/AppSingleSelect/AppSingleSelect2HeadlessUIRHF';
import { useBookingAgenciesOptions } from 'src/pages/_components/options';
import {
  BookingGroupTypeLabel,
  BookingStatusLabel,
} from '../../components/BookingLabelsComponents';
import { PRO_BookingMemberFull } from '../../models';
import { useResidentTypesOptions } from '../BookingMemberDiverEditForm';
import { customerOriginOptionsBuilder } from '../BookingMemberDiverEditForm/customerOriginOptionsBuilder.service';
import { BookingJourneyDetailsStepsForm } from './Steps';
import { BookingInitialPlanEditFormModel } from './model';

const BOOKING_STATUS_OPTIONS: ValueLabel<BookingStatus, React.ReactNode>[] =
  BOOKING_STATUSES.map((value) => {
    const option: ValueLabel<BookingStatus, React.ReactNode> = {
      label: (
        <BookingStatusLabel
          booking={{
            active: true,
            bookingStatus: value,
          }}
        />
      ),
      value: value as BookingStatus,
    };
    // bookingStatusFormatter.formatStatus(value).toUpperCase()
    return option;
  });
export const BookingInitialPlanResumeEditFormRFH = ({
  form,
  bookingMembersFull,
}: {
  form: UseFormReturn<BookingInitialPlanEditFormModel>;
  bookingMembersFull?: PRO_BookingMemberFull[]; // edition only
}) => {
  const { register, handleSubmit, watch, formState, control, setValue } = form;

  const securityUser = useAppSecurityUser();

  const clubResume = useClubResume();
  const clubReference = clubResume.reference;

  const agenciesOptions = useBookingAgenciesOptions();

  const bookingGroupTypesOptions = useMemo(
    () =>
      BOOKING_GROUP_TYPES.map((type) => {
        const option: ValueLabel<BookingGroupType, React.ReactNode> = {
          value: type,
          label: (
            <BookingGroupTypeLabel
              className="px-1 py-px text-xs mr-2 sm:mr-4 md:mr-5"
              type={type}
              showIndividualWithoutLabel={true}
            />
          ),
        };
        return option;
      }),
    [],
  );
  const customerOriginOptions = useMemo(
    () =>
      customerOriginOptionsBuilder.buildOptions({
        clubSettings: clubResume?.clubSettings,
      }),
    [clubResume?.clubSettings],
  );

  const residentTypesOptions = useResidentTypesOptions();

  const [bookingGroupType, bookingContactDiverId] = useWatch({
    control,
    name: ['bookingGroup.type', 'bookingContactDiverId'],
  });

  const bookingContactOptions = useMemo(() => {
    return (bookingMembersFull ?? []).map((memberFull) => {
      const o: ValueLabel<string, string> = {
        value: memberFull.diver._id,
        label: nameFormatter.formatFullName(memberFull.diver),
      };
      return o;
    });
  }, [bookingMembersFull]);

  const customerSettings = clubResume.clubSettings?.customer;
  const generalSettings = clubResume.clubSettings?.general;

  const isMultiDiveCenters = clubResume.diveCenters.length > 1;

  const showBookingContact = useMemo(() => {
    if (bookingContactOptions?.length > 1) {
      return true;
    } else if (bookingContactOptions?.length === 1) {
      if (
        !bookingContactDiverId ||
        bookingContactOptions?.find((x) => x.value !== bookingContactDiverId)
      ) {
        return true;
      }
    }
    return false;
  }, [bookingContactOptions, bookingContactDiverId]);

  return (
    <>
      <div className={'flex flex-col gap-2 xs:gap-4 p-2'}>
        <div className={'flex flex-col gap-2 xs:grid xs:grid-cols-12 xs:gap-4'}>
          <AppFormControlRHF
            className={'w-full xs:col-span-6 sm:col-span-6 md:col-span-3'}
            label="Type de client"
            control={control}
            name={'bookingGroup.type'}
            renderComponent={(props) => (
              <AppSingleSelect2HeadlessUIRHF
                disableClearButton={true}
                theme={'tailwind'}
                className={'w-full'}
                options={bookingGroupTypesOptions}
                {...props}
              />
            )}
          />
          <AppFormControlRHF
            className={'w-full xs:col-span-6 sm:col-span-6 md:col-span-3'}
            label="Statut de la réservation"
            control={control}
            name={'bookingStatus'}
            renderComponent={(props) => (
              <AppSingleSelect2HeadlessUIRHF
                disableClearButton={true}
                theme={'tailwind'}
                className={'w-full'}
                options={BOOKING_STATUS_OPTIONS}
                {...props}
              />
            )}
          />
          <AppFormControlRHF
            className={'w-full xs:col-span-6 sm:col-span-6 md:col-span-3'}
            label={
              bookingGroupType === 'club'
                ? 'Nom club'
                : bookingGroupType === 'company'
                ? 'Nom entreprise'
                : 'Nom réservation'
            }
            control={control}
            name={'bookingGroup.name'}
            renderComponent={(props) => (
              <AppInputRHF {...props} fullWidth type="text" />
            )}
          />
          {showBookingContact && (
            <AppFormControlRHF
              className={'w-full xs:col-span-6 sm:col-span-6 md:col-span-3'}
              label="Contact réservation"
              control={control}
              name={'bookingContactDiverId'}
              renderComponent={(props) => (
                <AppSingleSelect2HeadlessUIRHF
                  disableClearButton={true}
                  theme={'tailwind'}
                  className={'w-full'}
                  options={bookingContactOptions}
                  {...props}
                />
              )}
            />
          )}
          {customerSettings?.general?.customerOrigin && (
            <AppFormControlRHF
              className={'w-full xs:col-span-6 sm:col-span-6 md:col-span-3'}
              label="Origine client"
              control={control}
              name={'bookingGroup.customerOriginRef'}
              renderComponent={(props) => (
                <AppSingleAutocomplete2RHF
                  className={'w-full'}
                  {...props}
                  options={customerOriginOptions}
                  onChange={(value) => {
                    const option = customerOriginOptions.find(
                      (x) => x.value === value,
                    );
                    setValue('bookingGroup.customerOriginLabel', option?.label);
                  }}
                />
              )}
            />
          )}
          {customerSettings?.general?.residentType && (
            <AppFormControlRHF
              className={'w-full xs:col-span-6 sm:col-span-6 md:col-span-3'}
              label="Résidence"
              control={control}
              name={'bookingGroup.residentType'}
              renderComponent={(props) => (
                <AppSingleSelect2HeadlessUIRHF
                  className={'w-full'}
                  theme="material-ui"
                  color="theme"
                  {...props}
                  options={residentTypesOptions}
                />
              )}
            />
          )}
          {customerSettings?.booking?.externalRef && (
            <AppFormControlRHF
              className={'w-full xs:col-span-6 sm:col-span-6 md:col-span-3'}
              label={customerSettings?.booking?.externalRefLabel ?? 'ID perso'}
              control={control}
              name={'details.externalRef' as const}
              renderComponent={(props) => <AppInputRHF {...props} fullWidth />}
            />
          )}
          {customerSettings?.booking?.customField1 && (
            <AppFormControlRHF
              className={'w-full xs:col-span-6 sm:col-span-6 md:col-span-3'}
              label={customerSettings?.booking?.customField1Label}
              control={control}
              name={'details.customField1' as const}
              renderComponent={(props) => <AppInputRHF {...props} fullWidth />}
            />
          )}
          {customerSettings?.booking?.customField2 && (
            <AppFormControlRHF
              className={'w-full xs:col-span-6 sm:col-span-6 md:col-span-3'}
              label={customerSettings?.booking?.customField2Label}
              control={control}
              name={'details.customField2' as const}
              renderComponent={(props) => <AppInputRHF {...props} fullWidth />}
            />
          )}
          {customerSettings?.booking?.customField3 && (
            <AppFormControlRHF
              className={'w-full xs:col-span-6 sm:col-span-6 md:col-span-3'}
              label={customerSettings?.booking?.customField3Label}
              control={control}
              name={'details.customField3' as const}
              renderComponent={(props) => <AppInputRHF {...props} fullWidth />}
            />
          )}
          {customerSettings?.booking?.customBoolean1 && (
            <AppFormControlRHF
              className={'w-full xs:col-span-6 sm:col-span-6 md:col-span-3'}
              label={customerSettings?.booking?.customBoolean1Label}
              control={control}
              name={'details.customBoolean1' as const}
              renderComponent={(props) => (
                <AppInputBooleanSwitchRHF {...props} />
              )}
            />
          )}
          {customerSettings?.booking?.customBoolean2 && (
            <AppFormControlRHF
              className={'w-full xs:col-span-6 sm:col-span-6 md:col-span-3'}
              label={customerSettings?.booking?.customBoolean2Label}
              control={control}
              name={'details.customBoolean2' as const}
              renderComponent={(props) => (
                <AppInputBooleanSwitchRHF {...props} />
              )}
            />
          )}
          {customerSettings?.booking?.customBoolean3 && (
            <AppFormControlRHF
              className={'w-full xs:col-span-6 sm:col-span-6 md:col-span-3'}
              label={customerSettings?.booking?.customBoolean3Label}
              control={control}
              name={'details.customBoolean3' as const}
              renderComponent={(props) => (
                <AppInputBooleanSwitchRHF {...props} />
              )}
            />
          )}
          {generalSettings?.agencies?.enabled &&
            customerSettings?.booking?.agency && (
              <AppFormControlRHF
                className={'w-full xs:col-span-6 sm:col-span-6 md:col-span-3'}
                label="Agence"
                control={control}
                name={'details.agencyId'}
                renderComponent={(props) => (
                  <AppSingleAutocomplete2RHF
                    className={'w-full'}
                    options={agenciesOptions}
                    {...props}
                  />
                )}
              />
            )}
          {isMultiDiveCenters && (
            <AppFormControlRHF
              className={'w-full xs:col-span-6 sm:col-span-6 md:col-span-3'}
              label="MULTI centres"
              helpDescription="Réservation partagée entre tous les centres"
              control={control}
              name={'isSharedBooking'}
              renderComponent={(props) => (
                <AppInputBooleanSwitchRHF className={'w-full'} {...props} />
              )}
            />
          )}
        </div>
        <BookingJourneyDetailsStepsForm form={form} />
        <AppFormControlRHF
          className="w-full xs:col-span-12"
          label="Commentaire privé 🚫"
          control={control}
          name={'bookingInitialPlan.privateComment'}
          renderComponent={(props) => (
            <AppInputRHF {...props} fullWidth multiline rowsMax={15} rows={2} />
          )}
        />
      </div>
    </>
  );
};
