/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ClubMassMailing_FetchPreviewCriteria,
  ClubMassMailing_FetchPreviewResult,
} from '@mabadive/app-common-model';
import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { apiClient } from 'src/_common-browser';

// cache de 24h
const expiration = 24 * 60 * 60 * 1000;

export function useClubMassMailing_FetchPreview(
  criteria: ClubMassMailing_FetchPreviewCriteria,
) {
  const query: UseQueryResult<ClubMassMailing_FetchPreviewResult, unknown> =
    useQuery({
      queryKey: ['POST', '/pro/mailing/preview', criteria],
      queryFn: async () => {
        const results = await apiClient
          .post<any>('/pro/mailing/preview', {
            authenticate: true,
            json: {
              criteria,
            },
          })
          .toPromise();
        return results;
      },
      gcTime: expiration, // on garde ça en cache
      staleTime: 10 * 1000, // on rafraichit toujours, après l'utilisation du cache (sauf si - de 10s)
      refetchOnWindowFocus: true,
      refetchInterval: undefined, // pas de refresh
      enabled: !!criteria,
    });
  const { isLoading: loadingInProgress, data, refetch } = query;

  return {
    data,
    refetch,
    loadingInProgress,
  };
}
