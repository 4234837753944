/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  CLUB_STAFF_MEMBER_JOB_TYPES,
  ClubStaffMemberJobType,
  ClubStaffMemberRole,
  DIVE_SERVICE_ORGANIZATION_REFERENCES,
  DiveServiceOrganizationReference,
  SCUBA_DIVING_INSTRUCTOR_DEGREE_TEMPLATES,
  SCUBA_DIVING_INSTRUCTOR_LEVELS,
  STAFF_ROLE_TAGS,
  ScubaDivingInstructorDegreeName,
  ScubaDivingInstructorLevel,
} from '@mabadive/app-common-model';
import {
  arrayBuilder,
  clubStaffMemberJobTypeFormatter,
} from '@mabadive/app-common-services';
import React, { useMemo } from 'react';
import { useFieldArray, useWatch } from 'react-hook-form';
import { ValueLabel } from 'src/business/club/modules/_common/form/components/ValueLabel.model';
import { AppInputBooleanRHF, AppInputRHF } from 'src/lib/form';
import { AppFormControlRHF_Deprecated } from 'src/lib/form/components/AppFormControl';
import { AppMultiCheckboxesRHF } from 'src/lib/form/components/AppMultiCheckboxes';
import { AppSingleAutocomplete2RHF } from 'src/lib/form/components/AppSingleAutocomplete';
import { AppSingleSelect2HeadlessUIRHF } from 'src/lib/form/components/AppSingleSelect/AppSingleSelect2HeadlessUIRHF';
import { AppInputDatePickerMaterialRHF } from 'src/stories/components/04-form';
import { StaffMemberFormLocalState } from '../model';

const DIVE_ORGANIZATION_OPTIONS = DIVE_SERVICE_ORGANIZATION_REFERENCES.map(
  (reference) => {
    const option: ValueLabel<DiveServiceOrganizationReference> = {
      label: reference.toUpperCase(),
      value: reference,
    };
    return option;
  },
);

const SCUBA_DIVING_INSTRUCTOR_LEVEL_OPTIONS =
  SCUBA_DIVING_INSTRUCTOR_LEVELS.map((level) => {
    const option: ValueLabel<ScubaDivingInstructorLevel> = {
      label: level,
      value: level,
    };
    return option;
  });

/**
 *
 * @@deprecated use StaffMemberEditForm (utilisé encore sur l'édition d'une session)
 */
export const StaffMemberEditFormDeprecated = ({
  localState,
}: {
  localState: StaffMemberFormLocalState;
}) => {
  console.log('xxx StaffMemberEditFormDeprecated:');
  const { form } = localState;
  const { control, setValue } = form;

  const STAFF_ROLE_OPTIONS = useMemo(
    () =>
      STAFF_ROLE_TAGS.map((roleTag) => {
        const option: ValueLabel<ClubStaffMemberRole> = {
          label: roleTag.content.label,
          value: roleTag.reference,
        };
        return option;
      }),
    [],
  );

  const STAFF_MEMBER_JOB_TYPES_OPTIONS = useMemo(
    () =>
      CLUB_STAFF_MEMBER_JOB_TYPES.map((jobType) => {
        const option: ValueLabel<ClubStaffMemberJobType> = {
          label: clubStaffMemberJobTypeFormatter.formatJobType(jobType),
          value: jobType,
        };
        return option;
      }),
    [],
  );

  const { fields: availabilitiesFields } = useFieldArray({
    control,
    name: 'availabilities',
  });

  const [
    birthdate,
    scubaDivingInstructorDegreeLevel,
    scubaDivingInstructorDegreeName,
  ] = useWatch({
    control,
    name: [
      'profile.birthdate',
      'scubaDivingInstructor.degree.level',
      'scubaDivingInstructor.degree.name',
    ],
  });

  const SCUBA_DIVING_INSTRUCTOR_DEGREE_TEMPLATE_OPTIONS = useMemo(() => {
    const templates = SCUBA_DIVING_INSTRUCTOR_DEGREE_TEMPLATES.filter(
      (x) =>
        !scubaDivingInstructorDegreeLevel ||
        scubaDivingInstructorDegreeLevel === x.level,
    ).map((x) => x.name);

    return arrayBuilder.filterDuplicated(templates).map((degreeName) => {
      const option: ValueLabel<ScubaDivingInstructorDegreeName> = {
        label: degreeName,
        value: degreeName,
      };
      return option;
    });
  }, [scubaDivingInstructorDegreeLevel]);

  return (
    <div className="px-5">
      <h2 className="text-left text-sm sm:text-lg leading-6 py-2 font-medium text-app-blue uppercase">
        Profil
      </h2>
      <div
        className={
          'w-full flex flex-col sm:grid sm:grid-cols-2 md:grid-cols-4  gap-4'
        }
      >
        <AppFormControlRHF_Deprecated
          className={'md:col-span-2 w-full'}
          label="Nom"
          control={control}
          required={false}
          name={'profile.lastName'}
          renderComponent={(props) => (
            <AppInputRHF {...props} fullWidth type="text" />
          )}
        />
        <AppFormControlRHF_Deprecated
          className={'md:col-span-2 w-full'}
          label="Prénom"
          control={control}
          name={'profile.firstName'}
          renderComponent={(props) => (
            <AppInputRHF {...props} fullWidth type="text" />
          )}
        />
        <AppFormControlRHF_Deprecated
          className={'md:col-span-2 w-full'}
          label="Téléphone"
          control={control}
          name={'profile.phoneNumber'}
          renderComponent={(props) => (
            <AppInputRHF {...props} fullWidth type="text" />
          )}
        />
        <AppFormControlRHF_Deprecated
          className={'md:col-span-2 w-full'}
          label="Adresse e-mail"
          control={control}
          validation={{
            email: true,
          }}
          name={'profile.emailAddress'}
          renderComponent={(props) => (
            <AppInputRHF {...props} fullWidth type="text" />
          )}
        />
        <AppFormControlRHF_Deprecated
          className="md:col-span-2 w-full"
          label="Date de naissance"
          required={false}
          control={control}
          name={'profile.birthdate'}
          renderComponent={(props) => (
            <AppInputDatePickerMaterialRHF
              {...props}
              required={false}
              className="w-full"
              initialView={birthdate ? 'weeks' : 'years'}
              maxYear={new Date().getFullYear()}
            />
          )}
        />
        <AppFormControlRHF_Deprecated
          className={'md:col-span-2 lg:col-span-6 w-full'}
          label="Permis bateau"
          control={control}
          name={'profile.boatingLicence'}
          renderComponent={(props) => (
            <AppInputRHF {...props} fullWidth type="text" />
          )}
        />
      </div>
      <div className={'w-full'}>
        <AppFormControlRHF_Deprecated
          className={'w-full'}
          label="Commentaire général"
          control={control}
          name={'profile.comment'}
          renderComponent={(props) => (
            <AppInputRHF {...props} fullWidth multiline rowsMax={15} rows={2} />
          )}
        />
      </div>
      <div className={'w-full'}>
        <AppFormControlRHF_Deprecated
          className="md:col-span-2 w-full"
          label={'Fonctions'}
          control={control}
          name={'profile.roles'}
          renderComponent={(props) => (
            <AppMultiCheckboxesRHF
              {...props}
              className="flex flex-wrap w-full text-gray-600"
              options={STAFF_ROLE_OPTIONS}
            />
          )}
        />
      </div>
      {/* <h2 className="text-left text-sm sm:text-lg leading-6 py-2 font-medium text-app-blue uppercase">
        Disponibilités
      </h2>
      {availabilitiesFields.map((field, fieldIndex) => {
        return (
          <div key={field._id}>
            <div className={'w-full flex flex-col sm:flex-row gap-4'}>
              <AppFormControlRHF_Deprecated
                className="w-full"
                label={'Emploi'}
                required={true}
                control={control}
                name={`availabilities.${fieldIndex}.jobType`}
                renderComponent={(props) => (
                  <AppSingleSelect2HeadlessUIRHF
                    {...props}
                    theme="material-ui"
                    disableClearButton={true}
                    className="w-full text-gray-600"
                    options={STAFF_MEMBER_JOB_TYPES_OPTIONS}
                  />
                )}
              />
              <AppFormControlRHF_Deprecated
                className="w-full"
                label="Date de début"
                control={control}
                required={true}
                name={`availabilities.${fieldIndex}.beginDate`}
                renderComponent={(props) => (
                  <AppInputDatePickerMaterialRHF
                    {...props}
                    initialView="weeks"
                    className="w-full"
                  />
                )}
              />
              <AppFormControlRHF_Deprecated
                className="w-full"
                label="Date de fin"
                control={control}
                required={false}
                name={`availabilities.${fieldIndex}.endDate`}
                renderComponent={(props) => (
                  <AppInputDatePickerMaterialRHF
                    {...props}
                    initialView="weeks"
                    className="w-full"
                  />
                )}
              />
            </div>
            <AppFormLabel
              className="mt-4"
              label={'Jours de travail réguliers (matin et après-midi)'}
            />
            <div className="w-[14rem] grid grid-cols-2 gap-2 md:hidden">
              {WEEK_DAYS_ISO.map((isoWeekDay) => (
                <React.Fragment key={isoWeekDay}>
                  <div className="text-left uppercase font-bold text-app-primary text-base">
                    {dateService.formatIsoDayIso(isoWeekDay, {
                      format: 'dddd',
                    })}
                  </div>
                  <StaffMemberEditFormDeprecatedAvailabilyDay
                    key={isoWeekDay}
                    isoWeekDay={isoWeekDay}
                    field={field}
                    fieldIndex={fieldIndex}
                    localState={localState}
                  />
                </React.Fragment>
              ))}
            </div>
            <div className="hidden md:grid md:grid-cols-7 gap-2">
              {WEEK_DAYS_ISO.map((isoWeekDay) => (
                <div
                  key={isoWeekDay}
                  className="text-center uppercase font-bold text-app-primary text-base"
                >
                  {dateService.formatIsoDayIso(isoWeekDay, {
                    format: 'dddd',
                  })}
                </div>
              ))}
              {WEEK_DAYS_ISO.map((isoWeekDay) => (
                <StaffMemberEditFormDeprecatedAvailabilyDay
                  key={isoWeekDay}
                  isoWeekDay={isoWeekDay}
                  field={field}
                  fieldIndex={fieldIndex}
                  localState={localState}
                />
              ))}
            </div>
          </div>
        );
      })} */}
      <h2 className="text-left text-sm sm:text-lg leading-6 py-2 font-medium text-app-blue uppercase">
        Moniteur de plongée
      </h2>
      <div
        className={
          'w-full flex flex-col sm:grid sm:grid-cols-2 md:grid-cols-4 gap-4'
        }
      >
        <AppFormControlRHF_Deprecated
          className="md:col-span-2 w-full"
          label="Niveau instructeur"
          control={control}
          name={'scubaDivingInstructor.degree.level'}
          renderComponent={(props) => (
            <AppSingleSelect2HeadlessUIRHF
              {...props}
              className="w-full text-app-xxs"
              disableClearButton={false}
              theme={'material-ui'}
              // value={diveSession.diveTourSession1?.divingDirector?.staffId}
              options={SCUBA_DIVING_INSTRUCTOR_LEVEL_OPTIONS}
            />
          )}
        />
        <AppFormControlRHF_Deprecated
          className="md:col-span-2 w-full"
          label="Diplôme"
          control={control}
          name={'scubaDivingInstructor.degree.name'}
          renderComponent={(props) => (
            <AppSingleAutocomplete2RHF
              {...props}
              className="w-full"
              options={SCUBA_DIVING_INSTRUCTOR_DEGREE_TEMPLATE_OPTIONS}
              onChange={(degreeName) => {
                if (degreeName) {
                  let template = SCUBA_DIVING_INSTRUCTOR_DEGREE_TEMPLATES.find(
                    (x) =>
                      x.name === degreeName &&
                      (!scubaDivingInstructorDegreeLevel ||
                        x.level === scubaDivingInstructorDegreeLevel),
                  );
                  if (!template) {
                    // incompatible template: find first compatible
                    template = SCUBA_DIVING_INSTRUCTOR_DEGREE_TEMPLATES.find(
                      (x) => x.name === degreeName,
                    );
                  }
                  if (template.level !== scubaDivingInstructorDegreeLevel) {
                    setValue(
                      'scubaDivingInstructor.degree.level',
                      template?.level,
                    );
                  }
                }
              }}
            />
          )}
        />
        <AppFormControlRHF_Deprecated
          className="md:col-span-2 w-full"
          // label={'Stagiaire'}
          control={control}
          name={'scubaDivingInstructor.degree.isTrainee'}
          renderComponent={(props) => (
            <AppInputBooleanRHF
              {...props}
              type="checkbox"
              description={'Stagiaire'}
            />
          )}
        />
        <AppFormControlRHF_Deprecated
          className="md:col-span-2 w-full"
          label="Date diplôme"
          control={control}
          required={false}
          name={'scubaDivingInstructor.degree.date'}
          renderComponent={(props) => (
            <AppInputDatePickerMaterialRHF
              {...props}
              initialView="years"
              className="w-full"
            />
          )}
        />
        <AppFormControlRHF_Deprecated
          className="md:col-span-2 w-full"
          label="Organisme"
          control={control}
          name={'scubaDivingInstructor.degree.organizationReference'}
          renderComponent={(props) => (
            <AppSingleAutocomplete2RHF
              {...props}
              className="w-full"
              options={DIVE_ORGANIZATION_OPTIONS}
            />
          )}
        />
        <AppFormControlRHF_Deprecated
          className={'md:col-span-2 w-full'}
          label="Numéro de licence"
          control={control}
          name={'scubaDivingInstructor.degree.organizationLicenceNumber'}
          renderComponent={(props) => (
            <AppInputRHF {...props} fullWidth type="text" />
          )}
        />
      </div>
      <div className={'w-full flex flex-col md:flex-row gap-4'}>
        <AppFormControlRHF_Deprecated
          className={'w-full'}
          label="Commentaire diplôme"
          control={control}
          name={'scubaDivingInstructor.degree.comment'}
          renderComponent={(props) => (
            <AppInputRHF {...props} fullWidth multiline rowsMax={15} rows={2} />
          )}
        />
        <AppFormControlRHF_Deprecated
          className={'w-full'}
          label="Autres qualifications"
          control={control}
          name={'scubaDivingInstructor.otherDegrees'}
          renderComponent={(props) => (
            <AppInputRHF {...props} fullWidth multiline rowsMax={15} rows={2} />
          )}
        />
      </div>
      <div
        className={
          'w-full flex flex-col sm:grid sm:grid-cols-2 md:grid-cols-4 gap-4'
        }
      >
        <AppFormControlRHF_Deprecated
          className={'md:col-span-2 w-full'}
          label="N° carte professionelle"
          control={control}
          name={'scubaDivingInstructor.businessCardNumber'}
          renderComponent={(props) => (
            <AppInputRHF {...props} fullWidth type="text" />
          )}
        />
        <AppFormControlRHF_Deprecated
          className="md:col-span-2 w-full"
          label="Date carte professionelle"
          control={control}
          required={false}
          name={'scubaDivingInstructor.businessCardDate'}
          renderComponent={(props) => (
            <AppInputDatePickerMaterialRHF
              {...props}
              initialView="weeks"
              className="w-full"
            />
          )}
        />
        <AppFormControlRHF_Deprecated
          className="md:col-span-2 w-full"
          label="Certificat médical"
          control={control}
          required={false}
          name={'scubaDivingInstructor.medicalCertificateDate'}
          renderComponent={(props) => (
            <AppInputDatePickerMaterialRHF
              {...props}
              initialView="weeks"
              className="w-full"
            />
          )}
        />
      </div>
    </div>
  );
};
