/* eslint-disable @typescript-eslint/no-unused-vars */
import clsx from 'clsx';
import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import {
  useAppSecurityUser,
  useAppSecurityUserHasRole,
} from 'src/business/auth/services';
import {
  useClubResume,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import { ValueLabel } from 'src/business/club/modules/_common/form';
import { AppInputRHF, AppSingleAutocomplete2RHF } from 'src/lib/form';
import { AppFormControlRHF } from 'src/lib/form/components/AppFormControl/AppFormControlRHF';
import { AppSingleSelect2HeadlessUIRHF } from 'src/lib/form/components/AppSingleSelect/AppSingleSelect2HeadlessUIRHF';
import {
  useAppCurrencyMain,
  useAppPaymentMethodsOptions,
  useBookingAgenciesOptions,
  useBookingOptions,
  useDiversOptions,
} from 'src/pages/_components/options';
import { AppInputDatePickerMaterialRHF } from 'src/stories/components/04-form';
import {
  BookingCreditNoteEditDialogInitialState,
  BookingCreditNoteEditFormModel,
} from '../model';

export const BookingCreditNoteEditForm = ({
  form,
  initialState,
  className,
}: {
  form: UseFormReturn<BookingCreditNoteEditFormModel>;
  initialState: BookingCreditNoteEditDialogInitialState;
  className?: string;
}) => {
  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const securityUser = useAppSecurityUser();

  const { mode } = initialState;

  const isSuperAdmin = useAppSecurityUserHasRole('super-admin');

  const mainCurrency = useAppCurrencyMain();
  const clubResume = useClubResume();
  const diveCenterResume = useDiveCenterResume();
  const diveCenterId = diveCenterResume._id;
  const customerSettings = clubResume?.clubSettings?.customer;
  const clubReference = clubResume.reference;
  const clubSettings = clubResume.clubSettings;

  const divers = initialState.aggregatedData.divers;

  const bookingOptions: ValueLabel<string, React.ReactNode>[] =
    useBookingOptions({
      aggregatedData: initialState?.aggregatedData,
      onlyActive: mode === 'create',
      initialBookingId: initialState?.defaultValue?.creditNote?.bookingId,
      diveCenterId,
    });
  // TODO faire comme pour useBookingOptions
  const diversOptions: ValueLabel<string>[] = useDiversOptions(divers);

  // const [bookingAgencyId] = useWatch({
  //   control,
  //   name: ['creditNote.bookingAgencyId'],
  // });

  const agenciesOptions = useBookingAgenciesOptions();

  const paymentMethodOptions =
    useAppPaymentMethodsOptions('credit-note+online');

  return (
    <div className={clsx('grid gap-4', className)}>
      <h3 className="mt-5 mb-2 uppercase text-base sm:text-lg text-app-blue font-bold">
        Avoir
      </h3>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-x-4 gap-y-2">
        <AppFormControlRHF
          className="flex-grow"
          label={`Montant en ${mainCurrency.label}`}
          control={control}
          name="creditNote.amount"
          required={true}
          renderComponent={(props) => (
            <AppInputRHF {...props} fullWidth type="number" />
          )}
        />
        <AppFormControlRHF
          className="w-full"
          label="Moyen de paiement (remboursement)"
          control={control}
          name="creditNote.paymentMethod"
          required={false}
          renderComponent={(props) => (
            <AppSingleAutocomplete2RHF
              {...props}
              options={paymentMethodOptions}
            />
          )}
        />
        <AppFormControlRHF
          label={'Référence'}
          control={control}
          name="creditNote.reference"
          required={false}
          renderComponent={(props) => <AppInputRHF {...props} fullWidth />}
        />
        <AppFormControlRHF
          className="w-full"
          label="Date"
          control={control}
          name="creditNote.creationDate"
          required={true}
          renderComponent={(props) => (
            <AppInputDatePickerMaterialRHF className="w-full" {...props} />
          )}
        />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-x-4 gap-y-2">
        <AppFormControlRHF
          className="w-full"
          label="Réservation"
          control={control}
          name="creditNote.bookingId"
          required={true}
          renderComponent={(props) => (
            <AppSingleSelect2HeadlessUIRHF
              theme={'material-ui'}
              {...props}
              options={bookingOptions}
            />
          )}
        />
        {diversOptions.length > 1 && (
          <AppFormControlRHF
            className="w-full"
            label="Client"
            control={control}
            name="creditNote.diverId"
            required={true}
            renderComponent={(props) => (
              <AppSingleAutocomplete2RHF {...props} options={diversOptions} />
            )}
          />
        )}
      </div>
      <div className="grid gap-4 lg:grid-cols-2">
        <AppFormControlRHF
          className="w-full"
          label="Commentaire privé 🚫"
          control={control}
          name="creditNote.details.privateComment"
          renderComponent={(props) => (
            <AppInputRHF {...props} fullWidth multiline rowsMax={15} rows={2} />
          )}
        />
        {/* <AppFormControlRHF
          className="w-full"
          label="Commentaire pour le client 👨‍🦰👩‍🦰"
          control={control}
          name="creditNote.details.customerComment"
          renderComponent={(props) => (
            <AppInputRHF {...props} fullWidth multiline rowsMax={15} rows={2} />
          )}
        /> */}
      </div>
    </div>
  );
};
