import {
  CLUB_DIVER_TECHNICAL_LEVELS,
  ClubDiverTechnicalLevel,
} from '@mabadive/app-common-model';
import { diverTechnicalLevelFormatter } from '@mabadive/app-common-services';
import { useMemo } from 'react';
import { ValueLabel } from 'src/business/club/modules/_common/form/components/ValueLabel.model';

export function useDiverTechnicalLevelOptions(): ValueLabel<
  ClubDiverTechnicalLevel,
  string
>[] {
  // const clubSettings = useClubSettings();
  return useMemo(
    () =>
      CLUB_DIVER_TECHNICAL_LEVELS.map((technicalLevel) => ({
        value: technicalLevel,
        label: diverTechnicalLevelFormatter.formatTechnicalLevel(
          technicalLevel,
          {
            format: 'long',
          },
        ),
      })),
    [],
  );
}
