import { ClubDiverDefaultDiveConfig } from '@mabadive/app-common-model';
import {
  diveSessionServiceFormatter,
  participantAptitudeBuilder,
} from '@mabadive/app-common-services';
import React, { useMemo } from 'react';
import { useGlobalClasses } from 'src/AppTheme';
import { useClubResume, useClubSettings } from 'src/business/club/data/hooks';
import {
  ParticipantGaz,
  ParticipantTargetDeep,
} from 'src/business/club/modules/club-planning-light/components/ClubPlanningLightSession/participant';
import { ClubParticipantCardAttributeEquipment } from '../ClubParticipantCard/ClubParticipantCardAttributeEquipment';
import { DefaultDiveConfigCardAttributeName } from './model';

const FORMAT = 'short';

export const DefaultDiveConfigCardAttribute = ({
  defaultDiveConfig,
  attributeName,
  className = '',
}: {
  defaultDiveConfig?: ClubDiverDefaultDiveConfig;
  attributeName: DefaultDiveConfigCardAttributeName;
  className?: string;
}) => {
  const globalClasses = useGlobalClasses();

  const clubResume = useClubResume();
  const clubReference = clubResume?.reference;

  const aptitude = useMemo(
    () =>
      attributeName === 'aptitude'
        ? participantAptitudeBuilder.formatAptitude(
            {
              diver: undefined,
              participant: defaultDiveConfig,
            },
            {
              clubReference,
            },
          )
        : '',
    [attributeName, clubReference, defaultDiveConfig],
  );

  const clubSettings = useClubSettings();
  const clubPublicSettings = clubSettings?.publicSettings;

  return !defaultDiveConfig ? null : renderAttribute(attributeName);

  function renderAttribute(attributeName: DefaultDiveConfigCardAttributeName) {
    switch (attributeName) {
      case 'diveType':
        return (
          <div className={`${className} max-w-full`}>
            <h3 className="data-label text-gray-600 font-bold max-w-full">
              Type
            </h3>
            <div className="data-value text-app-blue max-w-full flex items-center gap-1 truncate">
              <span>
                {diveSessionServiceFormatter.formatServiceFromParticipant(
                  { diver: undefined, participant: defaultDiveConfig },
                  {
                    format: 'short-ref',
                    clubReference,
                    clubPublicSettings,
                    diveModesConf: clubSettings?.ui?.diveMode,
                  },
                )}
              </span>
            </div>
          </div>
        );
      case 'aptitude':
        return !aptitude?.length ? null : (
          <div className={`${className} max-w-full`}>
            <h3 className="data-label text-gray-600 font-bold max-w-full">
              Aptitude
            </h3>
            <div className="data-value text-app-blue max-w-full truncate">
              {aptitude}
            </div>
          </div>
        );
      case 'targetDeep':
        return (
          <div className={`${className} max-w-full`}>
            <h3 className="data-label text-gray-600 font-bold max-w-full">
              Prof. cible
            </h3>
            <div className="data-value max-w-full truncate">
              <ParticipantTargetDeep
                targetDeep={defaultDiveConfig?.targetDeep}
              />
            </div>
          </div>
        );
      case 'gaz':
        return (
          <div className={`${className} max-w-full`}>
            <h3 className="data-label text-gray-600 font-bold max-w-full">
              Gaz
            </h3>
            <div className="data-value max-w-full truncate">
              <ParticipantGaz
                className="text-xs"
                gaz={defaultDiveConfig?.gaz}
                showDetails={true}
                showGazQuantity={true}
                showAir={true}
              />
            </div>
          </div>
        );
      case 'jacket':
        return (
          <ClubParticipantCardAttributeEquipment
            className={`${className} max-w-full`}
            equipmentType={attributeName}
            equipment={defaultDiveConfig?.equipment?.jacket}
            format={FORMAT}
          />
        );
      case 'weighting':
        return (
          <ClubParticipantCardAttributeEquipment
            className={`${className} max-w-full`}
            equipmentType={attributeName}
            equipment={defaultDiveConfig?.equipment?.weighting}
            format={FORMAT}
          />
        );
      case 'wetsuit':
        return (
          <ClubParticipantCardAttributeEquipment
            className={`${className} max-w-full`}
            equipmentType={attributeName}
            equipment={defaultDiveConfig?.equipment?.wetsuit}
            format={FORMAT}
          />
        );
      case 'fins':
        return (
          <ClubParticipantCardAttributeEquipment
            className={`${className} max-w-full`}
            equipmentType={attributeName}
            equipment={defaultDiveConfig?.equipment?.fins}
            format={FORMAT}
          />
        );
      case 'scubaRegulator':
        return (
          <ClubParticipantCardAttributeEquipment
            className={`${className} max-w-full`}
            equipmentType={attributeName}
            equipment={defaultDiveConfig?.equipment?.scubaRegulator}
            format={FORMAT}
          />
        );
      case 'divingBoots':
        return (
          <ClubParticipantCardAttributeEquipment
            className={`${className} max-w-full`}
            equipmentType={attributeName}
            equipment={defaultDiveConfig?.equipment?.divingBoots}
            format={FORMAT}
          />
        );
      case 'mask':
        return (
          <ClubParticipantCardAttributeEquipment
            className={`${className} max-w-full`}
            equipmentType={attributeName}
            equipment={defaultDiveConfig?.equipment?.mask}
            format={FORMAT}
          />
        );
      case 'flashLight':
        return (
          <ClubParticipantCardAttributeEquipment
            className={`${className} max-w-full`}
            equipmentType={attributeName}
            equipment={defaultDiveConfig?.equipment?.flashLight}
            format={FORMAT}
          />
        );
      case 'computer':
        return (
          <ClubParticipantCardAttributeEquipment
            className={`${className} max-w-full`}
            equipmentType={attributeName}
            equipment={defaultDiveConfig?.equipment?.computer}
            format={FORMAT}
          />
        );
      case 'tank':
        return (
          <ClubParticipantCardAttributeEquipment
            className={`${className} max-w-full`}
            equipmentType={attributeName}
            equipment={defaultDiveConfig?.equipment?.tank}
            format={FORMAT}
          />
        );
      case 'compass':
        return (
          <ClubParticipantCardAttributeEquipment
            className={`${className} max-w-full`}
            equipmentType={attributeName}
            equipment={defaultDiveConfig?.equipment?.compass}
            format={FORMAT}
          />
        );
      case 'rebreather':
        return (
          <ClubParticipantCardAttributeEquipment
            className={`${className} max-w-full`}
            equipmentType={attributeName}
            equipment={defaultDiveConfig?.equipment?.rebreather}
            format={FORMAT}
          />
        );
      case 'underwaterScooter':
        return (
          <ClubParticipantCardAttributeEquipment
            className={`${className} max-w-full`}
            equipmentType={attributeName}
            equipment={defaultDiveConfig?.equipment?.underwaterScooter}
            format={FORMAT}
          />
        );
      default:
        return null;
    }
  }
};
