import {
  ClubDiveSite,
  DiveSession,
  DiveSessionTimeDayPeriod,
} from '@mabadive/app-common-model';
import {
  clubDiveSessionThemeBuilder,
  diveSessionStatusFormatter,
  diveSessionTitleBuilder,
} from '@mabadive/app-common-services';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { IoStar } from 'react-icons/io5';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { CalendarSessionWidthMode } from 'src/business/club/modules/club-planning-light/components/ClubPlanningLightSession/session-common';
import { AppEmojiIcon } from 'src/lib/form/components/AppEmojiPicker';
import { DiveSiteIconDef, useDiveSiteIcon } from '../hooks';

export const ClubDiveSessionName = function ({
  diveSession,
  diveSite,
  diveSite2,
  truncateText,
  skipCancelStatusLabel,
  widthMode,
  className,
}: {
  diveSession: Pick<
    DiveSession,
    | 'name'
    | 'status'
    | 'special'
    | 'diveTourSession2'
    | 'time'
    | 'emojiId'
    | 'details'
  >;
  diveSite: Pick<ClubDiveSite, '_id' | 'name' | 'emojiId'>;
  diveSite2: Pick<ClubDiveSite, '_id' | 'name' | 'emojiId'>;
  truncateText?: boolean;
  skipCancelStatusLabel?: boolean;
  className?: string;
  widthMode: CalendarSessionWidthMode;
}) {
  const timeDayPeriod: DiveSessionTimeDayPeriod = useMemo(
    () => clubDiveSessionThemeBuilder.buildTimeDayPeriod(diveSession.time),
    [diveSession.time],
  );
  const status = diveSession.status;
  const statusClass =
    status === 'on'
      ? diveSession.details?.isFull
        ? 'font-bold bg-white text-status-error border border-status-error px-0.5'
        : ''
      : status === 'off'
      ? 'px-1 bg-gray-500 text-white'
      : status === 'deleted'
      ? 'px-1 bg-gray-500 text-white'
      : status === 'cancelled'
      ? 'px-1 bg-status-error text-white'
      : '';
  const isMultiSession = !!diveSession.diveTourSession2;

  const nameClass = diveSession.special ? 'text-app-orange' : '';

  const nameIconClass = diveSession.special
    ? 'text-app-orange'
    : 'text-gray-500';

  const diveSiteClasses = diveSession.special ? 'text-app-orange' : '';
  const diveSiteIconClass = diveSession.special
    ? 'text-app-orange'
    : 'text-gray-500';

  const displayStatus =
    (diveSession.status !== 'on' || diveSession.details?.isFull) &&
    !skipCancelStatusLabel;
  const displayDiveSite1 = !!diveSite;
  const displayDiveSite2 = isMultiSession && !!diveSite2;
  const displayDiveSiteAny = displayDiveSite1 || displayDiveSite2;

  const sessionTitle = useMemo(
    () => diveSessionTitleBuilder.build(diveSession),
    [diveSession],
  );
  const displayName = !!sessionTitle || !!diveSession.emojiId;

  const displayMultiSessionDefaultName = isMultiSession && !displayDiveSiteAny;

  const diveSite1Icon: DiveSiteIconDef = useDiveSiteIcon(diveSite?._id);
  const diveSite2Icon: DiveSiteIconDef = useDiveSiteIcon(diveSite2?._id);

  const displayAny =
    displayStatus ||
    diveSite1Icon ||
    diveSite2Icon ||
    displayDiveSiteAny ||
    displayName ||
    displayMultiSessionDefaultName;
  return displayAny ? (
    <div className={className}>
      {displayName && (
        <div className={`flex items-center overflow-hidden ${nameClass}`}>
          <span className={'ml-0.5 sm:ml-1'}>
            {diveSession.emojiId ? (
              <AppEmojiIcon id={diveSession.emojiId} size={14} />
            ) : (
              <IoStar className={`w-3 h-3 ${nameIconClass}`} />
            )}
          </span>
          {sessionTitle && (
            <div
              className={`ml-0.5 sm:ml-1  ${truncateText ? 'truncate' : ''}`}
            >
              {sessionTitle.toUpperCase()}
            </div>
          )}
        </div>
      )}
      {displayMultiSessionDefaultName && (
        <div className={'flex items-center overflow-hidden text-gray-600'}>
          <span className={'ml-0.5 sm:ml-1'}>
            <IoStar className={`w-3 h-3 ${nameIconClass}`} />
          </span>
          <div className={`ml-0.5 sm:ml-1  ${truncateText ? 'truncate' : ''}`}>
            {'DOUBLE-BLOCK'.toUpperCase()}
          </div>
        </div>
      )}
      {displayDiveSite1 && (
        <div className={`flex items-center overflow-hidden ${diveSiteClasses}`}>
          <span className={'ml-0.5 sm:ml-1 flex items-center gap-1'}>
            {widthMode !== 'tiny' && (
              <AppHeroIcons.diveSite
                className={`w-3 h-3 ${diveSiteIconClass}`}
              />
            )}
            <AppEmojiIcon id={diveSite.emojiId} size={14} />
            {!diveSite.emojiId && diveSite1Icon && (
              <diveSite1Icon.icon
                className={clsx('w-3 h-3', diveSite1Icon.colorClass)}
              />
            )}
            {displayDiveSite2 && <span>N°1</span>}
          </span>
          <div
            className={`ml-0.5 sm:ml-1 overflow-hidden ${
              truncateText ? 'truncate' : ''
            }`}
          >
            {diveSite.name.toUpperCase()}
          </div>
        </div>
      )}
      {displayDiveSite2 && (
        <div className={`flex items-center overflow-hidden ${diveSiteClasses}`}>
          <span className={'ml-0.5 sm:ml-1 flex items-center gap-1'}>
            {widthMode !== 'tiny' && (
              <AppHeroIcons.diveSite
                className={`w-3 h-3 ${diveSiteIconClass}`}
              />
            )}
            <AppEmojiIcon id={diveSite2.emojiId} size={14} />
            {!diveSite2.emojiId && diveSite2Icon && (
              <diveSite2Icon.icon
                className={clsx('w-3 h-3', diveSite2Icon.colorClass)}
              />
            )}
            {displayDiveSite1 && <span>N°2</span>}
          </span>
          <div
            className={`ml-0.5 sm:ml-1 overflow-hidden ${
              truncateText ? 'truncate' : ''
            }`}
          >
            {diveSite2.name.toUpperCase()}
          </div>
        </div>
      )}
      {displayStatus && (
        <div className={`inline-block leading-3 ml-0.5 sm:ml-1 ${statusClass}`}>
          {diveSessionStatusFormatter
            .formatStatus(diveSession?.status, {
              isFull: diveSession.details?.isFull,
            })
            ?.toUpperCase()}
        </div>
      )}
    </div>
  ) : null;
};
