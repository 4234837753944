/* eslint-disable @typescript-eslint/no-unused-vars */
import clsx from 'clsx';
import { default as React } from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { useAppSecurityUserHasRole } from 'src/business/auth/services';
import { useClubResume } from 'src/business/club/data/hooks';
import { AppFormControlRHF, AppInputRHF } from 'src/lib/form';
import { DiveCenterEditFormModel } from '../model';

export const ClubSettingsForm_SocialNetworks = ({
  form,
  className = '',
}: {
  form: UseFormReturn<DiveCenterEditFormModel>;
  className?: string;
}) => {
  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const isSuperAdmin = useAppSecurityUserHasRole('super-admin');

  const clubResume = useClubResume();

  const [isIndividualContractor] = useWatch({
    control,
    name: ['clubSettings.publicData.legalInformation.isIndividualContractor'],
  });

  return (
    <div className={clsx('grid gap-2', className)}>
      <h4 className="font-bold text-app-secondary uppercase">
        Réseaux sociaux
      </h4>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-4 gap-y-2">
        <AppFormControlRHF
          label={'Site Internet'}
          control={control}
          name="clubSettings.publicData.links.webSite.url"
          renderComponent={(props) => (
            <AppInputRHF {...props} fullWidth type="text" />
          )}
        />
        <AppFormControlRHF
          label={'Facebook'}
          control={control}
          name="clubSettings.publicData.links.facebook.url"
          renderComponent={(props) => (
            <AppInputRHF {...props} fullWidth type="text" />
          )}
        />
        <AppFormControlRHF
          label={'Instagram'}
          control={control}
          name="clubSettings.publicData.links.instagram.url"
          renderComponent={(props) => (
            <AppInputRHF {...props} fullWidth type="text" />
          )}
        />
        <AppFormControlRHF
          label={'Trip Advisor'}
          control={control}
          name="clubSettings.publicData.links.tripAdvisor.url"
          renderComponent={(props) => (
            <AppInputRHF {...props} fullWidth type="text" />
          )}
        />
        <AppFormControlRHF
          label={'Youtube'}
          control={control}
          name="clubSettings.publicData.links.youtube.url"
          renderComponent={(props) => (
            <AppInputRHF {...props} fullWidth type="text" />
          )}
        />
      </div>
    </div>
  );
};
