/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ClubProductPackageOfferCategoryView,
  ClubProductPackageOfferView,
  ClubProductPackageOfferViewDisplayGroup,
} from '@mabadive/app-common-model';
import { dataSorter } from '@mabadive/app-common-services';

export const offersDisplayGroupsBuilder_Store = {
  buildGroups_Store,
};

function buildGroups_Store({
  categories,
  offers,
}: {
  categories: ClubProductPackageOfferCategoryView[];
  offers: ClubProductPackageOfferView[];
}) {
  const offersByDisplayGroups: ClubProductPackageOfferViewDisplayGroup[] = [];

  const { productCategoryOffers, remainingOffers } = offers.reduce(
    (acc, offer) => {
      const offerCategory: ClubProductPackageOfferCategoryView =
        categories.find((x) => offer.categoryId === x._id);

      if (offerCategory) {
        // Ajouter l'offre au groupe correspondant
        if (!acc.productCategoryOffers[offerCategory?._id]) {
          acc.productCategoryOffers[offerCategory?._id] = {
            offerCategory,
            offers: [],
          };
        }
        acc.productCategoryOffers[offerCategory?._id].offers.push(offer);
      } else {
        // Ajouter l'offre aux "remainingOffers" si elle n'appartient à aucun type personnalisé
        acc.remainingOffers.push(offer);
      }

      return acc;
    },
    {
      productCategoryOffers: {} as Record<
        string,
        {
          offerCategory: ClubProductPackageOfferCategoryView;
          offers: ClubProductPackageOfferView[];
        }
      >,
      remainingOffers: [] as ClubProductPackageOfferView[],
    },
  );

  // trier les clés de productCategoryOffers par label
  // TODO gérer un sort index
  const sortedProductCategoryOffers = dataSorter.sortMultiple(
    Object.entries(productCategoryOffers),
    {
      getSortAttributes: (x) => {
        const category = x[1]?.offerCategory; //  categories.find((c) => c._id === x[0]);
        return [
          {
            value: category?.title,
          },
          {
            value: category?._id,
          },
        ];
      },
    },
  );

  // Transformer productCategoryOffers en un tableau d'objets avec un titre et des offres
  for (const [
    offerCategoryId,
    { offerCategory, offers },
  ] of sortedProductCategoryOffers) {
    offersByDisplayGroups.push({
      title: offerCategory.title,
      offers,
    });
  }

  // Ajouter les offres restantes dans un groupe "Autres"
  if (remainingOffers.length > 0) {
    offersByDisplayGroups.push({
      title: 'Autres',
      offers: remainingOffers,
    });
  }
  return offersByDisplayGroups;
}
