/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ClubProductPackageOffer_ServiceCategory,
  ClubProductPackageOfferViewDisplayCategory,
} from '@mabadive/app-common-model';
import React from 'react';
import { AppButtonMultiActions } from '../../../../../../../business/_core/modules/layout';
import { AppHamburgerMenuItem } from '../../../../../../../business/club/modules/_common/ui';
import { ClubSettingsServicesViewPanelOffersPricesList } from '../../../_common/offers-prices/view';
import { ClubSettingsServicesViewPanelOffersPricesLocalState } from '../../../_common/offers-prices/view/useClubSettingsServicesViewPanelOffersPricesLocalState.hook';
import { useClubSettingsServicesStoreViewPanel_CategoryGroupMenuItems } from './useClubSettingsServicesStoreViewPanel_CategoryGroupMenuItems.hook';

export const ClubSettingsServicesStoreViewPanel_CategoryGroup = ({
  localState,
  categoryGroup,
  className,
}: {
  localState: ClubSettingsServicesViewPanelOffersPricesLocalState;
  categoryGroup: ClubProductPackageOfferViewDisplayCategory;
  className?: string;
}) => {
  const contextMode: ClubProductPackageOffer_ServiceCategory = 'store';

  const { data, state, actions } = localState;
  const offersByDisplayGroups = data.offersByDisplayGroups;
  const offersByCategory = data.offersByCategory;

  const hamburgerMenuItems: AppHamburgerMenuItem[] =
    useClubSettingsServicesStoreViewPanel_CategoryGroupMenuItems({
      categoryGroup,
      localState,
    });
  return (
    <div className={className}>
      <div className="my-2 flex gap-4 justify-between">
        <div className="">
          <div className="uppercase font-bold text-gray-600">
            {categoryGroup.category?.title ?? 'Autres'}
          </div>
          {categoryGroup.category?.description?.trim()?.length > 0 && (
            <div className="text-sm text-gray-500">
              {categoryGroup.category?.description
                ?.trim()
                ?.split('\n')
                .map((value, i) => (
                  <div key={i} className={''}>
                    {value}
                  </div>
                ))}
            </div>
          )}
        </div>
        <div className="text-gray-600 self-end sm:self-start">
          <AppButtonMultiActions items={hamburgerMenuItems} />
        </div>
      </div>

      <ClubSettingsServicesViewPanelOffersPricesList
        localState={localState}
        contextMode={contextMode}
        offers={categoryGroup.offers}
      />
    </div>
  );
};
