/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { useRedirect } from 'src/business/_core/data/hooks';
import { useClubSettings } from 'src/business/club/data/hooks';
import {
  UseClubDialogsProps,
  useClubDialogsActionsPersist,
} from 'src/pages/_dialogs';
import { AppMessageLight } from '../../../../../../../business/_core/modules/layout';
import { ProProductsPage_CategoryContentCardProduct } from '../../components';
import { ProProductsPage_CategoryViewPanelLocalState } from './useProProductsPage_CategoryViewPanelLocalState.hook';

export const ProProductsPage_CategoryViewPanel_TabArticles = ({
  localState,
}: {
  localState: ProProductsPage_CategoryViewPanelLocalState;
}) => {
  const actionsPersist: UseClubDialogsProps = useClubDialogsActionsPersist();
  const clubSettings = useClubSettings();
  const redirectTo = useRedirect();

  const generalSettings = clubSettings.general;

  const { state, data, actions, dialogs } = localState;
  const { category, products } = data;

  return (
    <div className="grid gap-4">
      {/* <h3 className="text-lg text-app-primary font-bold leading-4 uppercase">
        Tarifs par produit :
      </h3> */}
      {products.map((product) => (
        <ProProductsPage_CategoryContentCardProduct
          key={product._id}
          category={category}
          product={product}
          localState={localState.parentState}
        />
      ))}
      <AppMessageLight>
        NOTE: Pour chaque produit, créez un ou plusieurs tarifs.
      </AppMessageLight>
    </div>
  );
};
