/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  AppDocUploadContext,
  BookingParticipantEditorFormModel,
} from '@mabadive/app-common-model';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { useAppSecurityUser } from 'src/business/auth/services';
import { useClubResume } from 'src/business/club/data/hooks';
import {
  AppInputBooleanRHF,
  AppInputBooleanSwitchRHF,
  AppInputImageRHF,
  AppInputRHF,
} from 'src/lib/form';
import { AppFormControlRHF } from 'src/lib/form/components/AppFormControl/AppFormControlRHF';
import { AppSingleAutocomplete2RHF } from 'src/lib/form/components/AppSingleAutocomplete';
import { AppSingleSelect2HeadlessUIRHF } from 'src/lib/form/components/AppSingleSelect/AppSingleSelect2HeadlessUIRHF';
import { useLanguagesOptions } from 'src/pages/_components/options';
import { AppInputDatePickerMaterialRHF } from 'src/stories/components/04-form';
import { customerOriginOptionsBuilder } from '../customerOriginOptionsBuilder.service';
import { useResidentTypesOptions } from '../useResidentTypesOptions.hook';
import { BookingMemberGeneralEditFormAddress } from './BookingMemberGeneralEditFormAddress';

export const BookingMemberGeneralEditFormFieldsAll = ({
  form,
  appDocUploadContext,
  className = '',
}: {
  form: UseFormReturn<BookingParticipantEditorFormModel>;
  appDocUploadContext: AppDocUploadContext;
  className?: string;
}) => {
  const securityUser = useAppSecurityUser();
  const clubResume = useClubResume();
  const customerSettings = clubResume.clubSettings?.customer;
  const residentTypesOptions = useResidentTypesOptions();
  const customerOriginOptions = useMemo(
    () =>
      customerOriginOptionsBuilder.buildOptions({
        clubSettings: clubResume?.clubSettings,
      }),
    [clubResume?.clubSettings],
  );
  const { control, setValue } = form;

  const newsletterEnabled = customerSettings?.general?.newsletter;

  const birthdate = useWatch({
    control,
    name: 'diver.birthdate',
  });

  const displayAge = customerSettings?.general?.age && !birthdate;

  const languagesOptions = useLanguagesOptions({
    scope: 'all',
  });

  const profilePhotoUploadContext = useMemo(() => {
    const context: AppDocUploadContext = {
      ...appDocUploadContext,
      docRef: 'personal-photo',
      status: 'validated',
    };
    return context;
  }, [appDocUploadContext]);

  return (
    <div className={clsx('w-full flex flex-col gap-4', className)}>
      <div
        className={
          'w-full flex flex-col sm:grid sm:grid-cols-2 md:grid-cols-4 gap-4'
        }
      >
        <AppFormControlRHF
          className={'md:col-span-2 w-full'}
          label="Prénom"
          control={control}
          required={false}
          name={'diver.firstName'}
          renderComponent={(props) => (
            <AppInputRHF {...props} fullWidth type="text" />
          )}
        />

        <div className="md:col-span-2 w-full flex flex-col xs:flex-row gap-4">
          <AppFormControlRHF
            className={'w-full'}
            label="Nom"
            control={control}
            name={'diver.lastName'}
            renderComponent={(props) => (
              <AppInputRHF {...props} fullWidth type="text" />
            )}
          />
          {customerSettings?.general?.profilePhoto && (
            <AppInputImageRHF
              previewImageClassName="h-[30px]"
              label="Photo"
              height={400}
              width={400}
              control={control}
              setValue={setValue}
              appDocUploadContext={profilePhotoUploadContext}
              name="diver.details.profilePhoto"
            />
          )}
        </div>
        <div className={'md:col-span-2 w-full flex gap-4'}>
          <AppFormControlRHF
            className={
              customerSettings?.general?.phoneNumber2 ? 'w-1/2' : 'w-full'
            }
            label="Téléphone"
            control={control}
            name={'diver.phoneNumber'}
            renderComponent={(props) => (
              <AppInputRHF {...props} fullWidth type="text" />
            )}
          />
          {customerSettings?.general?.phoneNumber2 && (
            <AppFormControlRHF
              className={'w-1/2'}
              label="Téléphone 2"
              control={control}
              name={'diver.phoneNumber2'}
              renderComponent={(props) => (
                <AppInputRHF {...props} fullWidth type="text" />
              )}
            />
          )}
        </div>
        <div className="md:col-span-2 flex gap-2">
          <AppFormControlRHF
            className={'w-full'}
            label="Adresse e-mail"
            control={control}
            validation={{
              email: true,
            }}
            name={'diver.emailAddress'}
            renderComponent={(props) => (
              <AppInputRHF {...props} fullWidth type="text" />
            )}
          />
          {newsletterEnabled && (
            <AppFormControlRHF
              label="Newsletter"
              control={control}
              name={'diver.details.newsletter.registrationActive' as const}
              renderComponent={(props) => (
                <AppInputBooleanRHF
                  className="scale-125 block ml-0.5 mt-1 mb-1"
                  {...props}
                  type="checkbox"
                />
              )}
            />
          )}
        </div>
        <AppFormControlRHF
          className={clsx('w-full', !displayAge && 'md:col-span-2')}
          label="Date de naissance"
          control={control}
          name={'diver.birthdate'}
          renderComponent={(props) => (
            <AppInputDatePickerMaterialRHF
              initialView={birthdate ? 'weeks' : 'years'}
              maxYear={new Date().getFullYear()}
              {...props}
              className="w-full"
            />
          )}
        />
        {displayAge && (
          <AppFormControlRHF
            className={'w-full'}
            label="Âge"
            control={control}
            name={'diver.age'}
            renderComponent={(props) => (
              <AppInputRHF {...props} fullWidth type="number" />
            )}
          />
        )}

        {
          <div className="md:col-span-2 flex gap-2">
            {customerSettings?.general?.size && (
              <AppFormControlRHF
                className={'w-full'}
                label="Taille (cm)"
                control={control}
                name={'diver.size'}
                renderComponent={(props) => (
                  <AppInputRHF {...props} fullWidth type="number" />
                )}
              />
            )}
            {customerSettings?.general?.weight && (
              <AppFormControlRHF
                className={'w-full'}
                label="Poids (Kg)"
                control={control}
                name={'diver.weight'}
                renderComponent={(props) => (
                  <AppInputRHF {...props} fullWidth type="number" />
                )}
              />
            )}
            {customerSettings?.general?.shoeSize && (
              <AppFormControlRHF
                className={'w-full'}
                label="Pointure"
                control={control}
                name={'diver.details.measurements.shoeSize'}
                renderComponent={(props) => (
                  <AppInputRHF {...props} fullWidth type="number" />
                )}
              />
            )}
          </div>
        }

        {customerSettings?.general?.customerOrigin && (
          <AppFormControlRHF
            className={'md:col-span-2 w-full'}
            label="Origine"
            control={control}
            name={'diver.details.customerOriginRef'}
            renderComponent={(props) => (
              <AppSingleAutocomplete2RHF
                className={'w-full'}
                {...props}
                options={customerOriginOptions}
                onChange={(value) => {
                  const option = customerOriginOptions.find(
                    (x) => x.value === value,
                  );
                  setValue('diver.details.customerOriginLabel', option?.label);
                }}
              />
            )}
          />
        )}
        {
          <div className="md:col-span-2 flex gap-2">
            {customerSettings?.general?.residentType && (
              <AppFormControlRHF
                className={'w-full'}
                label="Résidence"
                control={control}
                name={'diver.residentType'}
                renderComponent={(props) => (
                  <AppSingleSelect2HeadlessUIRHF
                    className={'w-full'}
                    theme="material-ui"
                    color="theme"
                    {...props}
                    options={residentTypesOptions}
                  />
                )}
              />
            )}
            {customerSettings?.general?.sanitaryPass && (
              <AppFormControlRHF
                className={'w-1/2'}
                label="Pass"
                control={control}
                name={'diver.sanitaryPass'}
                renderComponent={(props) => (
                  <AppInputBooleanRHF
                    className="scale-125 block ml-0.5 mt-1 mb-1"
                    {...props}
                    type="checkbox"
                  />
                )}
              />
            )}
            {customerSettings?.general?.languageCode && (
              <AppFormControlRHF
                className={'md:col-span-2 w-full'}
                label="Langue"
                control={control}
                name={'diver.languageCode'}
                renderComponent={(props) => (
                  <AppSingleAutocomplete2RHF
                    {...props}
                    options={languagesOptions}
                    fullWidth
                  />
                )}
              />
            )}
          </div>
        }
        {customerSettings?.general?.externalRef && (
          <AppFormControlRHF
            className={'md:col-span-2 flex gap-2'}
            label={customerSettings?.general?.externalRefLabel ?? 'ID perso'}
            control={control}
            name={'diver.details.externalRef' as const}
            renderComponent={(props) => <AppInputRHF {...props} fullWidth />}
          />
        )}
        {customerSettings?.general?.identificationNumber && (
          <AppFormControlRHF
            className={'md:col-span-2 flex gap-2'}
            label="N° CNI ou Passeport"
            control={control}
            name={'diver.details.identificationNumber' as const}
            renderComponent={(props) => <AppInputRHF {...props} fullWidth />}
          />
        )}
        {customerSettings?.general?.customField1 && (
          <AppFormControlRHF
            className={'md:col-span-2 flex gap-2'}
            label={customerSettings?.general?.customField1Label}
            control={control}
            name={'diver.details.customField1' as const}
            renderComponent={(props) => <AppInputRHF {...props} fullWidth />}
          />
        )}
        {customerSettings?.general?.customField2 && (
          <AppFormControlRHF
            className={'md:col-span-2 flex gap-2'}
            label={customerSettings?.general?.customField2Label}
            control={control}
            name={'diver.details.customField2' as const}
            renderComponent={(props) => <AppInputRHF {...props} fullWidth />}
          />
        )}
        {customerSettings?.general?.customField3 && (
          <AppFormControlRHF
            className={'md:col-span-2 flex gap-2'}
            label={customerSettings?.general?.customField3Label}
            control={control}
            name={'diver.details.customField3' as const}
            renderComponent={(props) => <AppInputRHF {...props} fullWidth />}
          />
        )}
        {customerSettings?.general?.customBoolean1 && (
          <AppFormControlRHF
            className={'md:col-span-2 flex gap-2'}
            label={customerSettings?.general?.customBoolean1Label}
            control={control}
            name={'diver.details.customBoolean1' as const}
            renderComponent={(props) => <AppInputBooleanSwitchRHF {...props} />}
          />
        )}
        {customerSettings?.general?.customBoolean2 && (
          <AppFormControlRHF
            className={'md:col-span-2 flex gap-2'}
            label={customerSettings?.general?.customBoolean2Label}
            control={control}
            name={'diver.details.customBoolean2' as const}
            renderComponent={(props) => <AppInputBooleanSwitchRHF {...props} />}
          />
        )}
        {customerSettings?.general?.customBoolean3 && (
          <AppFormControlRHF
            className={'md:col-span-2 flex gap-2'}
            label={customerSettings?.general?.customBoolean3Label}
            control={control}
            name={'diver.details.customBoolean3' as const}
            renderComponent={(props) => <AppInputBooleanSwitchRHF {...props} />}
          />
        )}
      </div>
      {<BookingMemberGeneralEditFormAddress form={form} />}
      <div
        className={
          'w-full flex flex-col sm:grid sm:grid-cols-2 md:grid-cols-4 gap-4'
        }
      >
        {customerSettings?.general?.isMember && (
          <AppFormControlRHF
            label="Membre du club"
            control={control}
            name={'diver.details.membership.isMember' as const}
            renderComponent={(props) => (
              <AppInputBooleanRHF
                className="scale-125 block ml-0.5 mt-1 mb-1"
                {...props}
                type="checkbox"
              />
            )}
          />
        )}
        {customerSettings?.general?.memberRef && (
          <AppFormControlRHF
            label="Numéro de membre"
            control={control}
            name={'diver.details.membership.memberRef' as const}
            renderComponent={(props) => <AppInputRHF {...props} fullWidth />}
          />
        )}
        {customerSettings?.general?.membershipDate && (
          <AppFormControlRHF
            label="Date d'adhésion"
            control={control}
            name={'diver.details.membership.membershipDate' as const}
            renderComponent={(props) => (
              <AppInputDatePickerMaterialRHF
                initialView={'weeks'}
                maxYear={new Date().getFullYear()}
                {...props}
                className="w-full"
              />
            )}
          />
        )}
        {customerSettings?.general?.isStudent && (
          <AppFormControlRHF
            label="En formation club"
            control={control}
            name={'diver.details.membership.isStudent' as const}
            renderComponent={(props) => (
              <AppInputBooleanRHF
                className="scale-125 block ml-0.5 mt-1 mb-1"
                {...props}
                type="checkbox"
              />
            )}
          />
        )}
        {customerSettings?.general?.studentDescription && (
          <AppFormControlRHF
            label="Détails formation club"
            control={control}
            name={'diver.details.membership.studentDescription' as const}
            renderComponent={(props) => <AppInputRHF {...props} fullWidth />}
          />
        )}
        {customerSettings?.general?.isCustomer && (
          <AppFormControlRHF
            label="Client non-plongeur"
            control={control}
            name={'diver.details.isCustomer' as const}
            renderComponent={(props) => (
              <AppInputBooleanRHF
                className="scale-125 block ml-0.5 mt-1 mb-1"
                {...props}
                type="checkbox"
              />
            )}
          />
        )}
      </div>
      <div
        className={
          'w-full flex flex-col sm:grid sm:grid-cols-2 md:grid-cols-4 gap-4'
        }
      >
        {
          <AppFormControlRHF
            className={'md:col-span-4 w-full'}
            label="Personne à contacter en cas d'urgence"
            control={control}
            name={'diver.emergencyContact'}
            renderComponent={(props) => (
              <AppInputRHF
                {...props}
                fullWidth
                type="text"
                multiline
                rowsMax={15}
                rows={2}
              />
            )}
          />
        }
        <AppFormControlRHF
          className={'md:col-span-4 w-full'}
          label="Commentaire général privé 🚫"
          control={control}
          name={'diver.comment'}
          renderComponent={(props) => (
            <AppInputRHF {...props} fullWidth multiline rowsMax={15} rows={2} />
          )}
        />
      </div>
    </div>
  );
};
