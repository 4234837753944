/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  AppInquiryFullGql_Customer,
  AppInquiryResponse_Question,
  AppInquiryResponse_Section,
  ClubDiverConsentAcceptance,
} from '@mabadive/app-common-model';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { ClubSettingsInquiryEditPagePanelLocalState } from '../../ClubSettingsInquiryEditPagePanel';
import { AppInquiryFormModel } from '../model';
import { DiveCenterBookingInquiryResponsePage_FormModel } from './DiveCenterBookingInquiryResponsePage_FillFormPanel/model';

export function useClubSettingsInquiryEditPageForm_TabPreviewLocalState({
  parentState,
}: {
  parentState: ClubSettingsInquiryEditPagePanelLocalState;
}) {
  const inquiryEditForm: AppInquiryFormModel = useMemo(
    () => parentState.state.form.getValues(),
    [parentState.state.form],
  );

  const inquiry: AppInquiryFullGql_Customer = useMemo(() => {
    const inquiry: AppInquiryFullGql_Customer = {
      _id: null,
      clubReference: null,
      name: inquiryEditForm.name,
      type: inquiryEditForm.type,
      settings: inquiryEditForm.settings,
      sections: inquiryEditForm.sections,
    };
    return inquiry;
  }, [
    inquiryEditForm.name,
    inquiryEditForm.sections,
    inquiryEditForm.settings,
    inquiryEditForm.type,
  ]);

  const defaultConsent_GDPR: ClubDiverConsentAcceptance = null;
  const defaultConsent_ValidInfo: ClubDiverConsentAcceptance = null;

  const initialFormValue: DiveCenterBookingInquiryResponsePage_FormModel =
    useMemo(() => {
      return buildDefaultFormValue({
        inquiry,
      });
    }, [inquiry]);

  const form = useForm<DiveCenterBookingInquiryResponsePage_FormModel>({
    values: initialFormValue, // se met à jour si initialFormValue change
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  return {
    state: {
      ...parentState.state,
      form,
    },
    data: {
      inquiry,
    },
  };
}

export type ClubSettingsInquiryEditPageForm_TabPreviewLocalState = ReturnType<
  typeof useClubSettingsInquiryEditPageForm_TabPreviewLocalState
>;

function buildDefaultFormValue({
  inquiry,
}: {
  inquiry: AppInquiryFullGql_Customer;
}) {
  const value: DiveCenterBookingInquiryResponsePage_FormModel = {
    inquiryDetails: {
      name: inquiry?.name,
      type: inquiry?.type,
      settings: inquiry?.settings,
    },
    consents: null, // pas utilisé en preview
    responseDetails: {
      creationDate: new Date(),
      languageCode: 'fr', // TODO gérer la localisation
      sections: (inquiry?.sections ?? []).map((s) => {
        const formSection: AppInquiryResponse_Section = {
          sectionId: s.sectionId,
          description: s.description,
          questions: s.questions.map((q) => {
            const formQuestion: AppInquiryResponse_Question = {
              questionId: q.questionId,
              description: q.description,
              answer: {
                format: q.answerDetails.format,
                formatOptions: q.answerDetails.formatOptions,
              },
            };
            return formQuestion;
          }),
        };
        return formSection;
      }),
    },
  };
  return value;
}
