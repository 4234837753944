import {
  changeDescriptorManager,
  diverBookingCreator,
  diverBookingMemberCreator,
} from '@mabadive/app-common-services';
import { useCallback } from 'react';
import { useDiveCenterResume } from 'src/business/club/data/hooks';
import { BookingTabModel, DiverBookingPageUpdateState } from '../../../models';
import { DiverBookingPageSetUpdateStateFn } from '../../../useDiverBookingPageGlobalState.hook';

export function useDiverBookingPageDuplicateBooking({
  clubReference,
  updateState,
  setUpdateState,
}: {
  clubReference: string;
  updateState: DiverBookingPageUpdateState;
  setUpdateState: DiverBookingPageSetUpdateStateFn;
}) {
  const diveCenterResume = useDiveCenterResume();
  return useCallback(
    ({ bookingSource }: { bookingSource: BookingTabModel }) => {
      let updateStateLocal = updateState;

      const bookingDiveCenterId = bookingSource.bookingDiveCenterId;

      const sourceMembers = bookingSource.aggregatedBooking.bookingMembersFull;

      // create booking
      const newBooking = diverBookingCreator.createBooking({
        diveCenterId: bookingDiveCenterId,
        clubReference,
        bookingContactDiverId:
          bookingSource.aggregatedBooking.booking.bookingContactDiverId,
        isSharedBooking:
          bookingSource.aggregatedBooking.booking.isSharedBooking,
        publicSettings:
          diveCenterResume?.clubResume.clubSettings.publicSettings,
      });
      const bookingsChanges = changeDescriptorManager.createOne(newBooking, {
        changeDescriptors: updateStateLocal.bookingsChanges,
      });
      updateStateLocal = {
        ...updateStateLocal,
        bookingsChanges,
      };

      // create members
      const newBookingMembers = sourceMembers.map((sourceMember) =>
        diverBookingMemberCreator.createBookingMemberFromSourceMember({
          sourceMember: sourceMember.bookingMember,
          bookingId: newBooking._id,
          clubReference,
          diveCenterId: bookingDiveCenterId,
        }),
      );

      const bookingMembersChanges = changeDescriptorManager.createMany(
        newBookingMembers,
        {
          changeDescriptors: updateStateLocal.bookingMembersChanges,
        },
      );
      const diversChanges = changeDescriptorManager.addManyOriginals(
        sourceMembers.map((m) => m.diver),
        {
          changeDescriptors: updateStateLocal.diversChanges,
        },
      );

      updateStateLocal = {
        ...updateStateLocal,
        bookingMembersChanges,
        diversChanges,
      };

      setUpdateState(updateStateLocal, {
        action: 'DiverBookingPageDuplicateBooking',
      });
      return {
        booking: newBooking,
        bookingMembers: newBookingMembers,
      };
    },
    [
      clubReference,
      diveCenterResume?.clubResume.clubSettings.publicSettings,
      setUpdateState,
      updateState,
    ],
  );
}
