import {
  dateService,
  diveSessionTitleBuilder,
} from '@mabadive/app-common-services';
import clsx from 'clsx';
import React, { useMemo, useState } from 'react';
import {
  AppButton,
  AppMessage,
} from 'src/business/_core/modules/layout/components/_tailwind';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { usePageWidth } from 'src/business/_core/modules/layout/services/usePageWidth.hook';
import {
  AggregatedBookingSessionFull,
  BookingResumeParticipantForSessionWithSession,
} from '../../../models';
import { BookingMassiveEditorActions } from '../useBookingMassiveEditorDialogActions.hook';
import { BookingMassiveEditorInnerLocalState } from '../useBookingMassiveEditorInnerLocalState.hook';
import { BookingMassiveEditorSessionsTableDay } from './BookingMassiveEditorSessionsTableDay';
import { BookingMassiveEditorSessionsTableMember } from './BookingMassiveEditorSessionsTableMember';

export function BookingMassiveEditorSessionsTable({
  context,
  localState,
  className,
  displayName,
  displayLastName,
  globalButtons,
  filteredDiversIds,
  onClickSession,
  actions,
}: {
  context: 'massive-booking-dialog' | 'booking-card';
  localState: BookingMassiveEditorInnerLocalState;
  displayName?: boolean;
  displayLastName?: boolean;
  actions: Pick<
    BookingMassiveEditorActions,
    | 'onClickRegisterAll'
    | 'onClickDeleteAllDailyParticipants'
    | 'onClickParticipant'
    | 'onClickRegisterAllDailyParticipants'
    | 'onUpdateMemberDiveMode'
    | 'onClickEditMember'
  >;
  filteredDiversIds?: string[];
  globalButtons: React.ReactNode | React.ReactNode[];
  onClickSession?: (bookingSessionFull: AggregatedBookingSessionFull) => void;
  className?: string;
}) {
  const { aggregatedData, participantsBySessions } = localState.data;

  const pageWidth = usePageWidth({ ignoreMenu: false });

  const pageWidthDefaultMaxVisibleSessionsCount = useMemo(() => {
    const chunks = Math.floor(pageWidth / 100);
    return chunks * 2 - 1; // 2 rows (-1 because "+session" button)
  }, [pageWidth]);

  const futureSessionsCount = useMemo(
    () =>
      participantsBySessions.filter(
        (x) => !dateService.isPastUTC(x.bookingSessionFull.diveSession.time),
      ).length,
    [participantsBySessions],
  );

  const bookingViewAuth = localState.state.bookingViewAuth;

  const defaultMaxVisibleSessionsCount = useMemo(() => {
    const max = Math.max(
      pageWidthDefaultMaxVisibleSessionsCount,
      futureSessionsCount +
        (futureSessionsCount !== participantsBySessions.length ? 1 : 0),
    );
    if (max === participantsBySessions.length - 1) {
      // only one session remaining: display it!
      return max + 1;
    }
    return max;
  }, [
    futureSessionsCount,
    pageWidthDefaultMaxVisibleSessionsCount,
    participantsBySessions.length,
  ]);

  const [displayAllParticipants, setDisplayAllParticipants] = useState(
    participantsBySessions.length <= defaultMaxVisibleSessionsCount,
  );

  const visibleParticipantsBySessions = useMemo(
    () =>
      displayAllParticipants
        ? participantsBySessions
        : participantsBySessions.slice(0, defaultMaxVisibleSessionsCount),
    [
      defaultMaxVisibleSessionsCount,
      displayAllParticipants,
      participantsBySessions,
    ],
  );

  const hiddenSessionsCount =
    participantsBySessions.length - visibleParticipantsBySessions.length;

  const registeredParticipants = useMemo(
    () =>
      participantsBySessions.reduce(
        (acc, x) =>
          acc.concat(
            x.participants.filter(
              ({ bookingParticipantFullAnyBooking }) =>
                !!bookingParticipantFullAnyBooking,
            ),
          ),
        [] as BookingResumeParticipantForSessionWithSession[],
      ),
    [participantsBySessions],
  );
  const unregisteredParticipantsCount = useMemo(
    () =>
      participantsBySessions.reduce(
        (count, x) =>
          count +
          x.participants.filter(
            ({ bookingParticipantFullAnyBooking, bookingMemberFull }) =>
              !bookingParticipantFullAnyBooking &&
              bookingMemberFull?.diver?.defaultDiveConfig?.diveMode,
          ).length,
        0,
      ),
    [participantsBySessions],
  );

  const showCheckAllButton = useMemo(() => {
    if (context === 'massive-booking-dialog') {
      return (
        bookingViewAuth.edit &&
        (participantsBySessions.length > 1 ||
          unregisteredParticipantsCount + registeredParticipants.length > 1) &&
        unregisteredParticipantsCount + registeredParticipants.length > 0
      );
    }
    return true;
  }, [
    bookingViewAuth.edit,
    context,
    participantsBySessions.length,
    registeredParticipants.length,
    unregisteredParticipantsCount,
  ]);

  const displaySessionName = useMemo(
    () =>
      aggregatedData.aggregatedBooking.bookingSessionsFull.find(
        (x) => diveSessionTitleBuilder.build(x.diveSession) !== undefined,
      ) !== undefined,
    [aggregatedData.aggregatedBooking.bookingSessionsFull],
  );
  const displayDiveSite = useMemo(
    () =>
      aggregatedData.aggregatedBooking.bookingSessionsFull.find(
        (x) =>
          !!x.diveSession.diveSiteId ||
          !!x.diveSession.diveTourSession2?.diveSiteId,
      ) !== undefined,
    [aggregatedData.aggregatedBooking.bookingSessionsFull],
  );
  const bookingMembersToDisplay = useMemo(
    () =>
      filteredDiversIds?.length > 0
        ? aggregatedData.bookingMembersFull.filter((x) =>
            filteredDiversIds.includes(x.diver?._id),
          )
        : aggregatedData.bookingMembersFull,
    [aggregatedData.bookingMembersFull, filteredDiversIds],
  );
  return (
    <>
      <div
        className={clsx(
          'flex flex-row flex-wrap items-end justify-start justify-items-end max-w-full',
          'divide-y divide-gray-100',
          className,
        )}
      >
        <div
          className={clsx(
            'mb-2 flex flex-col-reverse sm:flex-col justify-between max-w-full sm:mr-1',
            !bookingViewAuth.edit
              ? ''
              : showCheckAllButton
              ? 'w-full sm:w-[21rem]'
              : 'w-full sm:w-60',
          )}
        >
          <div
            className={
              context === 'massive-booking-dialog' ? '' : 'hidden sm:block'
            }
          >
            {globalButtons}
          </div>
          {/* <div className='flex-grow'></div> */}
          <div className="flex flex-col gap-2">
            {bookingMembersToDisplay.map((bookingMemberFull) => (
              <BookingMassiveEditorSessionsTableMember
                key={bookingMemberFull.bookingMember._id}
                localState={localState}
                actions={actions}
                bookingMemberFull={bookingMemberFull}
              />
            ))}
          </div>
        </div>

        {visibleParticipantsBySessions.map(
          ({ bookingSessionFull, participants }, j) => (
            <BookingMassiveEditorSessionsTableDay
              className="my-2"
              key={j}
              localState={localState}
              filteredDiversIds={filteredDiversIds}
              bookingSessionFull={bookingSessionFull}
              participants={participants}
              actions={actions}
              displayName={displayName}
              displayLastName={displayLastName}
              displaySessionName={displaySessionName}
              displayDiveSite={displayDiveSite}
              onClickSession={onClickSession}
            />
          ),
        )}
      </div>
      {hiddenSessionsCount > 0 && (
        <AppMessage
          className="my-5"
          type="info"
          title={`${defaultMaxVisibleSessionsCount}/${participantsBySessions.length} plongées affichées`}
          // message={
          //   <h3 className="text-sm sm:text-base">
          //     Seules les {defaultMaxVisibleSessionsCount} premières plongées
          //     sont affichées ({hiddenSessionsCount} masquées).
          //   </h3>
          // }
        >
          <AppButton
            color="primary-outline-light"
            icon={AppHeroIcons.eye}
            onClick={() => setDisplayAllParticipants(true)}
          >
            Afficher les {hiddenSessionsCount} autres plongées
          </AppButton>
        </AppMessage>
      )}
    </>
  );
}
