/* eslint-disable @typescript-eslint/no-unused-vars */
import { DiveSessionTimeDayPeriod } from '@mabadive/app-common-model';
import { diveSessionTitleBuilder } from '@mabadive/app-common-services';
import React, { useMemo } from 'react';
import { useClubResume } from 'src/business/club/data/hooks';
import { ClubBoatLabel } from 'src/pages/_components/company';
import { ClubSettingsPlanningViewWeekDiveTourName } from './ClubSettingsPlanningViewWeekDiveTourName';
import { ClubSettingsPlanningViewDayDiveTourModel } from './model';

export const ClubSettingsPlanningViewWeekDiveTourHeader = ({
  diveTour,
  onClick = () => {},
  className,
}: {
  diveTour: ClubSettingsPlanningViewDayDiveTourModel;
  onClick?: () => void;
  className?: string;
}) => {
  const clubResume = useClubResume();

  const timeDayPeriod: DiveSessionTimeDayPeriod =
    diveTour.session1.timeDayPeriod;

  const diveSite = diveTour.session1.diveSite;
  const hasSession2 = diveTour.session2;
  const diveSite2 = diveTour.session2?.diveSite;

  const boats = diveTour.session1.boats;

  const sessionTitle = useMemo(
    () =>
      diveSessionTitleBuilder.build({
        name: diveTour.session1.name,
        theme: diveTour.theme,
        type: diveTour.type,
        special: diveTour.session1.special,
      }),
    [
      diveTour.session1.name,
      diveTour.session1.special,
      diveTour.theme,
      diveTour.type,
    ],
  );

  const hasAnySessionName: boolean =
    !diveTour.isOpen || !!diveSite || !!sessionTitle || !!diveSite2;

  const hasAnyBoatName: boolean = diveTour.isOpen && boats.length !== 0;

  const hasAnyTitle = hasAnySessionName || hasAnyBoatName;
  return (
    <div
      className={`relative bg-day-period-${timeDayPeriod}-light bg-opacity-80 group-hover:bg-opacity-100 text-gray-600 group-hover:text-gray-700 text-app-xxs block font-bold
       ${className ?? ''}
       `}
      onClick={onClick}
    >
      {hasSession2 && (
        <span
          className={`absolute top-1 right-1 rounded-lg bg-day-period-${timeDayPeriod}-dark text-sm text-white px-1 align-middle`}
        >
          x{2}
        </span>
      )}
      {hasAnyBoatName && (
        <div
          className={'z-boats flex items-center gap-1 sm:gap-2 overflow-hidden'}
        >
          {boats.map((boat) => (
            <div
              key={boat._id}
              className={'flex items-center gap-0.5 sm:gap-1 truncate'}
            >
              <ClubBoatLabel
                className="ml-0.5 sm:ml-1 "
                clubBoat={boat}
                iconSize="w-3 h-3"
              />
            </div>
          ))}
        </div>
      )}
      {hasAnySessionName && (
        <ClubSettingsPlanningViewWeekDiveTourName
          className={'md:block font-bold'}
          diveTour={diveTour}
        />
      )}
      {!hasAnyTitle && <div className="">&nbsp;</div>}
    </div>
  );
};
