/* eslint-disable @typescript-eslint/no-unused-vars */
import clsx from 'clsx';
import React, { PropsWithChildren, useMemo } from 'react';
import { AppPageContainer } from 'src/business/_core/modules/layout';
import { AppDocEditDialog } from 'src/business/club/modules/app-doc/AppDocEditDialog';
import { BookingCreditNoteEditDialog } from 'src/business/club/modules/club-diver-participant/pages/DiverBookingPage/components/BookingCreditNoteEditDialog';
import { BookingDepositEditDialog } from 'src/business/club/modules/club-diver-participant/pages/DiverBookingPage/components/BookingDepositEditDialog';
import { ClubPlanningDailyStaffDialog } from 'src/business/club/modules/club-diver-participant/pages/DiverBookingPage/components/ClubPlanningDailyStaffDialog';
import { DiveSessionEditorMassiveDialog } from 'src/business/club/modules/club-diver-participant/pages/DiverBookingPage/components/DiveSessionEditorMassiveDialog';
import { CreateMessageToCustomersDialog } from 'src/business/club/modules/club-messaging/components/CreateMessageToCustomersDialog';
import { StaffMemberEditDialog } from 'src/pages/SE-settings/SE-01_dive-center/staff-members/StaffMemberEditDialog';
import { DiveCenterEditDialog } from 'src/pages/SE-settings/SE-02_account/ClubSettingsAccountCompanyInfoPage/DiveCenterEditDialog';
import { ClubSettingsProductOffersDialog } from 'src/pages/SE-settings/SE-02_account/ClubSettingsProductOffersDialog';
import { ClubSettingsServicesDialog } from 'src/pages/SE-settings/SE-02_account/ClubSettingsServices/dialog';
import { ClubSettingsCommunicationDialog } from 'src/pages/SE-settings/SE-03-customers/communication/dialog';
import { AppCompanyMessageTemplateEditDialog } from 'src/pages/SE-settings/SE-03-customers/communication/tabs/02.message-templates/AppCompanyMessageTemplateEditDialog';
import { CustomerSpaceConfigDialog } from 'src/pages/SE-settings/SE-03-customers/customer-space/CustomerSpaceConfigDialog';
import { ClubSettingsGeneralDialog } from 'src/pages/SE-settings/SE-04_other-settings/ClubSettingsGeneral/dialog/ClubSettingsGeneralDialog';
import { DailyExportConfigDialog } from 'src/pages/SE-settings/SE-07_exports';
import { ClubSettingsProductOfferCategoryDialog } from '../../SE-settings/SE-02_account/ClubSettingsProductOfferCategoryDialog';
import { ClubSettingsOnlineBookingDialog } from '../../SE-settings/SE-03-customers/online-booking/ClubSettingsOnlineBookingConfigPage/dialog';
import { ClubDialogsState } from './model';
/**
 *
 * Dialogs de l'espace Club
 *
 * TODO: migrer progressivement tous dialogs ici
 */
export const ClubDialogsProvider = ({
  dialogsState,
  children,
}: PropsWithChildren<{
  dialogsState: ClubDialogsState;
}>) => {
  const {
    isAnyDialogOpen: isAnyDialogOpenClubDialog,
    dialogs,
    diveCenterEditDialog,
    customerSpaceConfigDialog,
    clubSettingsCommunicationDialog,
    clubSettingsOnlineBookingDialog,
    clubSettingsGeneralDialog,
    clubSettingsServicesDialog,
    clubSettingsProductOffersDialog,
    staffMemberEditDialog,
    productOfferCategoryDialog,
    bookingDepositEditDialog,
    createMessageToCustomersDialog,
    bookingCreditNoteEditDialog,
    diveSessionEditorMassiveDialog,
    dailyExportConfigDialog,
    clubPlanningDailyStaffDialog,
    appCompanyMessageTemplateEditDialog,
    appDocEditDialog,
  } = dialogsState;

  const isAnyDialogOpen = useMemo(() => {
    if (isAnyDialogOpenClubDialog) {
      return true;
    }
    return false;
  }, [isAnyDialogOpenClubDialog]);

  return (
    <>
      <>
        {/* DIALOGS */}
        {diveCenterEditDialog.isOpen && (
          <AppPageContainer
            id="dialog-scrollable-container"
            className="bg-white relative overflow-y-auto"
          >
            <DiveCenterEditDialog {...diveCenterEditDialog} />
          </AppPageContainer>
        )}
        {customerSpaceConfigDialog.isOpen && (
          <AppPageContainer
            id="dialog-scrollable-container"
            className="bg-white relative overflow-y-auto"
          >
            <CustomerSpaceConfigDialog {...customerSpaceConfigDialog} />
          </AppPageContainer>
        )}
        {clubSettingsCommunicationDialog.isOpen && (
          <AppPageContainer
            id="dialog-scrollable-container"
            className="bg-white relative overflow-y-auto"
          >
            <ClubSettingsCommunicationDialog
              {...clubSettingsCommunicationDialog}
            />
          </AppPageContainer>
        )}{' '}
        {clubSettingsOnlineBookingDialog.isOpen && (
          <AppPageContainer
            id="dialog-scrollable-container"
            className={clsx(
              'bg-white relative overflow-y-auto',
              // on peut ouvrir ce dialog depuis l'autre, il faudrait gérer une pipe first-in/last-out  pour éviter ce hack
              appCompanyMessageTemplateEditDialog.isOpen && 'hidden',
            )}
          >
            <ClubSettingsOnlineBookingDialog
              {...clubSettingsOnlineBookingDialog}
              dialogsState={dialogsState}
            />
          </AppPageContainer>
        )}
        {clubSettingsGeneralDialog.isOpen && (
          <AppPageContainer
            id="dialog-scrollable-container"
            className="bg-white relative overflow-y-auto"
          >
            <ClubSettingsGeneralDialog {...clubSettingsGeneralDialog} />
          </AppPageContainer>
        )}{' '}
        {clubSettingsServicesDialog.isOpen && (
          <AppPageContainer
            id="dialog-scrollable-container"
            className="bg-white relative overflow-y-auto"
          >
            <ClubSettingsServicesDialog {...clubSettingsServicesDialog} />
          </AppPageContainer>
        )}
        {clubSettingsProductOffersDialog.isOpen && (
          <AppPageContainer
            id="dialog-scrollable-container"
            className="bg-white relative overflow-y-auto"
          >
            <ClubSettingsProductOffersDialog
              {...clubSettingsProductOffersDialog}
            />
          </AppPageContainer>
        )}
        {staffMemberEditDialog.isOpen && (
          <AppPageContainer
            id="dialog-scrollable-container"
            className="bg-white relative overflow-y-auto"
          >
            <StaffMemberEditDialog {...staffMemberEditDialog} />
          </AppPageContainer>
        )}
        {productOfferCategoryDialog.isOpen && (
          <AppPageContainer
            id="dialog-scrollable-container"
            className="bg-white relative overflow-y-auto"
          >
            <ClubSettingsProductOfferCategoryDialog
              {...productOfferCategoryDialog}
            />
          </AppPageContainer>
        )}
        {bookingDepositEditDialog.isOpen && (
          <AppPageContainer
            id="dialog-scrollable-container"
            className="bg-white relative overflow-y-auto"
          >
            <BookingDepositEditDialog {...bookingDepositEditDialog} />
          </AppPageContainer>
        )}{' '}
        {createMessageToCustomersDialog.isOpen && (
          <AppPageContainer
            id="dialog-scrollable-container"
            className="bg-white relative overflow-y-auto"
          >
            <CreateMessageToCustomersDialog
              {...createMessageToCustomersDialog}
            />
          </AppPageContainer>
        )}
        {bookingCreditNoteEditDialog.isOpen && (
          <AppPageContainer
            id="dialog-scrollable-container"
            className="bg-white relative overflow-y-auto"
          >
            <BookingCreditNoteEditDialog {...bookingCreditNoteEditDialog} />
          </AppPageContainer>
        )}
        {!appDocEditDialog.isOpen && diveSessionEditorMassiveDialog.isOpen && (
          <AppPageContainer
            id="dialog-scrollable-container"
            className="bg-white relative overflow-y-auto"
          >
            <DiveSessionEditorMassiveDialog
              {...diveSessionEditorMassiveDialog}
            />
          </AppPageContainer>
        )}
        {dailyExportConfigDialog.isOpen && (
          <AppPageContainer
            id="dialog-scrollable-container"
            className="bg-white relative overflow-y-auto"
          >
            <DailyExportConfigDialog {...dailyExportConfigDialog} />
          </AppPageContainer>
        )}
        {clubPlanningDailyStaffDialog.isOpen && (
          <AppPageContainer
            id="dialog-scrollable-container"
            className="bg-white relative overflow-y-auto"
          >
            <ClubPlanningDailyStaffDialog {...clubPlanningDailyStaffDialog} />
          </AppPageContainer>
        )}
        {appCompanyMessageTemplateEditDialog.isOpen && (
          <AppPageContainer
            id="dialog-scrollable-container"
            className="bg-white relative overflow-y-auto"
          >
            <AppCompanyMessageTemplateEditDialog
              {...appCompanyMessageTemplateEditDialog}
            />
          </AppPageContainer>
        )}
        {appDocEditDialog.isOpen && (
          <AppPageContainer
            id="dialog-scrollable-container"
            className="bg-white relative overflow-y-auto"
          >
            <AppDocEditDialog {...appDocEditDialog} />
          </AppPageContainer>
        )}
      </>
      <div className={`h-full w-full ${isAnyDialogOpen ? 'hidden' : ''}`}>
        {children}
      </div>
    </>
  );
};
