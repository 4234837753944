import { default as React, useMemo, useState } from 'react';
import { AppInputBooleanSwitch } from '../../../../../../../../lib/form';
import { DiverBookingCardPastSessionsTable } from '../DiverBookingSessionsTable';
import { BookingResumeMembersList } from './BookingResumeMembersTable';
import { DiverBookingCard_AddMemberOrSession_Buttons } from './DiverBookingCard_AddMemberOrSession_Buttons';
import { DiverBookingCardFutureSessionsTable } from './DiverBookingCardFutureSessionsTable';
import { DiverBookingPageBookingTabHeaderAutoFixSplitBookings } from './DiverBookingCardHeaderAutoFix';
import { DiverBookingCardOnlineBooking } from './DiverBookingCardOnlineBooking';
import { DiverBookingCard_LocalState } from './useDiverBookingCard_LocalState';

export function DiverBookingCard_TabRegistrationFuture({
  localState,
  className,
}: {
  localState: DiverBookingCard_LocalState;
  className?: string;
}) {
  const { state, actions, data } = localState;
  const {
    bookingTabModel,
    booking,
    onlineBookings,
    participantsBySessionsFuture,
    participantsBySessionsPast,
    bookingMembersFull,
  } = data;
  const { globalState, subTab, dialogs } = state;

  const futureParticipantsDiverIds = useMemo(
    () =>
      participantsBySessionsFuture.reduce((acc, { participants }) => {
        participants.forEach((participant) => {
          const diverId = participant.bookingMemberFull?.diver?._id;
          if (participant.bookingParticipantFull && !acc.includes(diverId)) {
            acc.push(diverId);
          }
        });
        return acc;
      }, []),
    [participantsBySessionsFuture],
  );

  const bookingMembersWithoutFutureParticipants = useMemo(
    () =>
      bookingMembersFull.filter((bookingMemberFull) =>
        futureParticipantsDiverIds.includes(bookingMemberFull.diver._id),
      ),
    [bookingMembersFull, futureParticipantsDiverIds],
  );

  const enableHideMembersWithoutFutureParticipants =
    bookingMembersFull.length > 2 &&
    bookingMembersWithoutFutureParticipants.length > 1 &&
    bookingMembersFull.length !==
      bookingMembersWithoutFutureParticipants.length;

  const [
    hideMembersWithoutFutureParticipantsInner,
    setHideMembersWithoutFutureParticipants,
  ] = useState(false);

  const hideMembersWithoutFutureParticipants =
    enableHideMembersWithoutFutureParticipants &&
    hideMembersWithoutFutureParticipantsInner;

  const bookingMembersToDisplay = hideMembersWithoutFutureParticipants
    ? bookingMembersWithoutFutureParticipants
    : bookingMembersFull;

  const filteredDiverId = globalState.updateState.filteredDiverId;
  const filteredDiversIds = useMemo(() => {
    if (filteredDiverId) {
      return [filteredDiverId];
    }
    if (hideMembersWithoutFutureParticipants) {
      return futureParticipantsDiverIds;
    }
    return []; // no filter
  }, [
    filteredDiverId,
    futureParticipantsDiverIds,
    hideMembersWithoutFutureParticipants,
  ]);
  const [showPastSessions, setShowPastSessions] = useState(true);

  return (
    <div className={className}>
      <BookingResumeMembersList
        booking={booking}
        aggregatedBookingResume={bookingTabModel.aggregatedBooking}
        bookingMembersFull={bookingMembersToDisplay}
        globalState={globalState}
        onClickMember={(bookingMemberFull, action) => {
          actions.onClickMember({ bookingMemberFull, action });
        }}
      />
      {enableHideMembersWithoutFutureParticipants && (
        <div className="my-2">
          <AppInputBooleanSwitch
            label="Masquer les participants sans inscription future"
            value={hideMembersWithoutFutureParticipants}
            onChange={() =>
              setHideMembersWithoutFutureParticipants(
                !hideMembersWithoutFutureParticipants,
              )
            }
          />
        </div>
      )}
      <DiverBookingCardFutureSessionsTable
        className="mt-2"
        globalState={globalState}
        dialogs={dialogs}
        actions={actions}
        bookingTabModel={bookingTabModel}
        onClickMember={actions.onClickMember}
        onClickSession={actions.onClickSession}
        filteredDiversIds={filteredDiversIds}
      />
      {onlineBookings.length > 0 && (
        // réservations en ligne
        <div className="mt-4 grid gap-2">
          {onlineBookings.map((onlineBooking) => (
            <DiverBookingCardOnlineBooking
              key={onlineBooking._id}
              onlineBooking={onlineBooking}
              globalState={globalState}
              dialogs={dialogs}
              actions={actions}
              bookingTabModel={bookingTabModel}
            />
          ))}
        </div>
      )}

      <DiverBookingCard_AddMemberOrSession_Buttons
        className="my-4"
        actions={actions}
        bookingTabModel={bookingTabModel}
      />
      {participantsBySessionsPast.length > 0 && (
        <div
          className="my-2 px-2 pt-2 sm:mt-6 pb-2 text-base flex flex-col gap-2"
          // onClick={() => {
          //   state.selectSubTab('booking-registration-past');
          //   actions.scrollToTopWithDelay();
          // }}
        >
          <h3 className="uppercase font-bold text-gray-500 group-hover:text-gray-600">
            Inscriptions passées:
          </h3>

          <div className="mb-1">
            <AppInputBooleanSwitch
              label={`Afficher les sessions passées (${participantsBySessionsPast.length})`}
              value={showPastSessions}
              onChange={() => setShowPastSessions(!showPastSessions)}
            />
          </div>
          {showPastSessions && (
            <DiverBookingCardPastSessionsTable
              className={'mt-2'}
              // table={table}
              participantsBySessions={participantsBySessionsPast}
              onClickSession={actions.onClickSession}
              onClickParticipant={actions.onClickParticipant}
              globalState={globalState}
              dialogs={dialogs}
              actions={actions}
              bookingTabModel={bookingTabModel}
              futureSessionsCount={state.futureSessionsCount}
              displayName={!state.isUniqueMember}
              displayLastName={!state.isSameLastName}
              displaySessionName={state.displaySessionName}
              displayDiveSite={state.displayDiveSite}
              filteredDiversIds={filteredDiversIds}
            />
          )}
        </div>
      )}

      {localState.state.autoFixes.splitBookings && (
        <DiverBookingPageBookingTabHeaderAutoFixSplitBookings
          bookingTabModel={bookingTabModel}
          globalState={globalState}
          dialogs={dialogs}
          actions={actions}
          splitBookings={localState.state.autoFixes.splitBookings}
          setIsExpanded={state.setIsExpanded}
        />
      )}
    </div>
  );
}
