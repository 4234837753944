import { OnlineBooking } from '@mabadive/app-common-model';
import { diveSessionTitleBuilder } from '@mabadive/app-common-services';
import { useCallback, useMemo, useState } from 'react';
import { AppTabTailwind } from 'src/business/_core/modules/layout/components/_tailwind';
import { useAppBookingViewAuth } from 'src/business/auth/services';
import { AppHamburgerMenuItem } from 'src/business/club/modules/_common/ui';
import { ClubDialogsStateOld } from 'src/pages/_dialogs';
import {
  AppIconsAction,
  EntitiesIcons,
} from '../../../../../../../_core/modules/layout/icons';
import {
  BookingResumeOnClickMemberAction,
  DiverBookingCardBookingAction,
} from '../../../_from-diver-app';
import {
  AggregatedBookingSessionFull,
  BookingTabModel,
  DiverBookingPageLoadedContentFocus,
  PRO_BookingMemberFull_WithDocs,
  PRO_BookingParticipantFull,
} from '../../models';
import { DiverBookingPageBookingTab_SubTabId } from '../../tabs/DiverBookingPageBookingTab/DiverBookingPageBookingTab_SubTab';
import { DiverBookingPageActions } from '../../useDiverBookingPageActions.hook';
import { DiverBookingPageGlobalState } from '../../useDiverBookingPageGlobalState.hook';
import { DiverBookingPageBookingCardLocalState } from '../useDiverBookingPageBookingCardLocalState.hook';

export function useDiverBookingCard_LocalState({
  globalState,
  parentState,
  dialogs,
  actions,
  bookingTabModel,
  focus,
  onClickParticipant,
  onClickMember,
  onClickSession,
  onClickBooking,
  extraMenuItems = [],
  className,
}: {
  globalState: DiverBookingPageGlobalState;
  parentState: DiverBookingPageBookingCardLocalState;
  dialogs: ClubDialogsStateOld;
  actions: DiverBookingPageActions;
  bookingTabModel: BookingTabModel;
  focus: DiverBookingPageLoadedContentFocus;
  onClickParticipant: (props: {
    bookingSessionFull: AggregatedBookingSessionFull;
    bookingParticipantFull: PRO_BookingParticipantFull;
    bookingMemberFull: PRO_BookingMemberFull_WithDocs;
  }) => void;

  onClickSession: (bookingSessionFull: AggregatedBookingSessionFull) => void;
  onClickBooking: (
    bookingTabModel: BookingTabModel,
    action: DiverBookingCardBookingAction,
  ) => void;
  onClickMember: (attrs: {
    bookingMemberFull: PRO_BookingMemberFull_WithDocs;
    action: BookingResumeOnClickMemberAction;
  }) => void;
  extraMenuItems?: AppHamburgerMenuItem[];
  className?: string;
}) {
  const { openSessionSelector } = actions;

  const booking = bookingTabModel.aggregatedBooking.booking;

  const { bookingMembersFull, bookingParticipantsFull, bookingSessionsFull } =
    bookingTabModel.aggregatedBooking;

  const { isUniqueMember, isSameLastName, futureSessionsCount } =
    bookingTabModel.meta;

  const displaySessionName = useMemo(
    () =>
      bookingTabModel.aggregatedBooking.bookingSessionsFull.find(
        (x) => diveSessionTitleBuilder.build(x.diveSession) !== undefined,
      ) !== undefined,
    [bookingTabModel.aggregatedBooking.bookingSessionsFull],
  );
  const displayDiveSite = useMemo(
    () =>
      bookingTabModel.aggregatedBooking.bookingSessionsFull.find(
        (x) =>
          !!x.diveSession.diveSiteId ||
          !!x.diveSession.diveTourSession2?.diveSiteId,
      ) !== undefined,
    [bookingTabModel.aggregatedBooking.bookingSessionsFull],
  );

  const { state } = globalState;

  const [isExpanded, setIsExpanded] = useState(true); // il n'y en a qu'une, toujours étendue par défaut (ce paramètre va disparaitre plus tard)

  const bookingViewAuth = useAppBookingViewAuth({
    bookingDiveCenterId: bookingTabModel.bookingDiveCenterId,
    isSharedBooking: bookingTabModel.isSharedBooking,
  });

  const onlineBookings: OnlineBooking[] =
    bookingTabModel?.aggregatedBooking?.onlineBookings ?? [];
  const tabBooking_bookingId = state.viewState?.tabBooking_bookingId;

  const selectSubTab = useCallback(
    (tabBooking_tab: DiverBookingPageBookingTab_SubTabId) => {
      state.setViewState({
        ...state.viewState,
        tabBooking_tab,
        tabBooking_bookingId,
      });
    },
    [state, tabBooking_bookingId],
  );

  const autoFixes = parentState.state.autoFixes;

  const subTabs = useMemo(() => {
    const subTabs: AppTabTailwind<DiverBookingPageBookingTab_SubTabId>[] = [
      {
        id: 'booking-participants-list',
        label: 'Participants',
        icon: EntitiesIcons.participants,
        // checkAuth?: (props: SettingsCategoryPageCheckAuthArgs) => boolean;
      },
      {
        id: 'booking-registration',
        label: 'Inscriptions',
        icon: EntitiesIcons.booking,
        // checkAuth?: (props: SettingsCategoryPageCheckAuthArgs) => boolean;
      },
      // {
      //   id: 'booking-registration-past',
      //   label: 'Inscriptions passées',
      //   icon: EntitiesIcons.booking,
      //   // checkAuth?: (props: SettingsCategoryPageCheckAuthArgs) => boolean;
      // },
      // {
      //   id: 'booking-list',
      //   label: 'Réservations',
      //   // checkAuth?: (props: SettingsCategoryPageCheckAuthArgs) => boolean;
      // },
    ];
    if (
      autoFixes?.bookingCustomerUpdates?.length > 0 &&
      globalState.state.skipBookingCustomerUpdates
    ) {
      subTabs.push({
        id: 'booking-customer-updates',
        label: 'Mise à jour client',
        icon: AppIconsAction.refresh,
        notificationStatus: 'danger',
        notificationCount: 1,
        // style: "danger"
      });
    }
    return subTabs;
  }, [
    autoFixes?.bookingCustomerUpdates?.length,
    globalState.state.skipBookingCustomerUpdates,
  ]);

  const subTab = useMemo(() => {
    const subTab = state.viewState?.tabBooking_tab ?? 'booking-registration';
    return (subTabs.find((x) => x.id === subTab) ?? subTabs[0])?.id;
  }, [subTabs, state.viewState?.tabBooking_tab]);

  useMemo(
    () => {
      if (
        autoFixes?.bookingCustomerUpdates?.length > 0 &&
        globalState.state.skipBookingCustomerUpdates &&
        state.viewState?.tabBooking_bookingId !== 'booking-customer-updates'
      ) {
        selectSubTab('booking-customer-updates');
      }
    },
    // juste 1 fois, au chargement
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const participantsBySessionsFuture =
    bookingTabModel.participantsBySessionsFuture;
  const participantsBySessionsPast = bookingTabModel.participantsBySessionsPast;

  return {
    data: {
      bookingTabModel,
      booking,
      onlineBookings,
      participantsBySessionsFuture,
      participantsBySessionsPast,
      bookingMembersFull,
    },
    state: {
      ...parentState.state,
      globalState,
      isExpanded,
      isUniqueMember,
      isSameLastName,
      futureSessionsCount,
      displaySessionName,
      displayDiveSite,
      bookingViewAuth,
      subTab,
      subTabs,
      dialogs,
      setIsExpanded,
      selectSubTab,
    },
    actions: {
      ...actions,
      openSessionSelector,
      onClickBooking,
      onClickSession,
      onClickMember,
      onClickParticipant,
    },
  };
}

export type DiverBookingCard_LocalState = ReturnType<
  typeof useDiverBookingCard_LocalState
>;
