import {
  ClubPublicSettingsServices_Training,
  ClubSettingsServices,
  DIVE_TRAININGS,
  DiveTrainingReference,
} from '@mabadive/app-common-model';
import {
  diveServiceOrganizationFilter,
  diveServiceTrainingFormatter,
} from '@mabadive/app-common-services';
import { useMemo } from 'react';
import { ValueLabel } from 'src/business/club/modules/_common/form/components/ValueLabel.model';

export function useTrainingOptionsFromServices(
  services: ClubSettingsServices,
  {
    limitToEnabledTrainings,
  }: {
    limitToEnabledTrainings?: ClubPublicSettingsServices_Training;
  },
): ValueLabel<DiveTrainingReference, string>[] {
  const enabledReferences = (
    limitToEnabledTrainings?.trainingReferencesFr ??
    ([] as DiveTrainingReference[])
  ).concat(limitToEnabledTrainings?.trainingReferencesInt ?? []);
  return useMemo(() => {
    const options = DIVE_TRAININGS.filter((training) => {
      const isGroupEnabled =
        training.diveMode === 'training' &&
        diveServiceOrganizationFilter.isOrgGroupEnabled(
          training.organization.reference,
          {
            services,
          },
        );
      if (!isGroupEnabled) {
        return false;
      }
      if (limitToEnabledTrainings) {
        return enabledReferences.includes(training.reference);
      }
      return true;
    }).map((training) => ({
      value: training.reference,
      label: diveServiceTrainingFormatter.formatTraining(training, {
        format: 'ref-name',
      }),
    }));
    // une même formation peut être dans INT ou dans FR, dans ce cas on filtre les doublons sur les options
    const isUnique = (
      item: ValueLabel<DiveTrainingReference, string>,
      index: number,
      array: ValueLabel<DiveTrainingReference, string>[],
    ) => {
      return array.findIndex((opt) => opt.value === item.value) === index;
    };
    const uniqueOptions: ValueLabel<DiveTrainingReference, string>[] =
      options.filter((option, index, array) => isUnique(option, index, array));
    return uniqueOptions;
  }, [enabledReferences, limitToEnabledTrainings, services]);
}
