/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useMemo } from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { AppFormControlRHF } from 'src/lib/form/components/AppFormControl/AppFormControlRHF';

import {
  ClubProductPackageOfferView,
  DiveTrainingReferenceInternational,
} from '@mabadive/app-common-model';
import {
  AppButton,
  AppMessageLight,
} from '../../../../../../../business/_core/modules/layout';
import { AppIconsAction } from '../../../../../../../business/_core/modules/layout/icons';
import { AppMultiCheckboxesTailwindRHF } from '../../../../../../../lib/form/components/AppMultiCheckboxes/AppMultiCheckboxesTailwindRHF';
import { useTrainingOptionsFromServices } from '../../../../../../_components/options';
import { ClubSettingsServicesFormModel } from '../../../_model';
import { useUsedTrainingReferencesFromOffers } from '../useUsedTrainingReferencesFromOffers.hook';

export const ClubSettingsServicesTrainingFormOrgs_TrainingActivation_Int = ({
  form,
  existingOffers,
}: {
  form: UseFormReturn<ClubSettingsServicesFormModel>;
  existingOffers?: ClubProductPackageOfferView[]; // utilisé pour les formations
}) => {
  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const { trainingReferencesInt: trainingReferencesFountInOffersInt } =
    useUsedTrainingReferencesFromOffers(existingOffers);

  const [globalSettingsFrenchEnabled, trainingReferencesIntEnabled] = useWatch({
    control,
    name: [
      'clubSettings.services.french.enabled',
      'clubSettings.publicSettings.services.training.trainingReferencesInt',
    ],
  });
  const trainingOptionsInt = useTrainingOptionsFromServices(
    {
      defaultOrgGroup: 'international',
      french: {
        enabled: false,
        defaultOrganizationReference: 'anmp',
        organizationReferences: [],
      },
      international: {
        enabled: true,
        defaultOrganizationReference: 'padi',
        organizationReferences: [],
      },
      store: {
        enabled: false,
      },
    },
    {},
  );

  const missingTrainingReferencesInt = useMemo(
    () =>
      trainingReferencesFountInOffersInt.filter(
        (ref) =>
          !trainingReferencesIntEnabled?.includes(
            ref as DiveTrainingReferenceInternational,
          ),
      ),
    [trainingReferencesFountInOffersInt, trainingReferencesIntEnabled],
  );
  const unusedTrainingReferencesInt = useMemo(
    () =>
      trainingReferencesIntEnabled?.filter(
        (ref) => !trainingReferencesFountInOffersInt.includes(ref),
      ),
    [trainingReferencesFountInOffersInt, trainingReferencesIntEnabled],
  );

  return (
    <div className="grid gap-4">
      <AppFormControlRHF
        label={'Formations'}
        required={globalSettingsFrenchEnabled}
        control={control}
        name={
          'clubSettings.publicSettings.services.training.trainingReferencesInt'
        }
        renderComponent={(props) => (
          <AppMultiCheckboxesTailwindRHF
            {...props}
            options={trainingOptionsInt}
            direction="horizontal"
            labelPosition="right"
          />
        )}
      />
      {trainingReferencesFountInOffersInt.length > 0 && (
        <div className="mt-2 grid gap-2">
          {missingTrainingReferencesInt?.length > 0 && (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <AppMessageLight type="danger">
              <div className="grid gap-2 items-start">
                <div className="text-gray-500">
                  {missingTrainingReferencesInt?.length} prestations sont
                  désactivées alors qu'elles ont un tarif défini:{' '}
                  {missingTrainingReferencesInt?.slice(0, 8).join(', ')}
                  ...
                </div>
                <AppButton
                  icon={AppIconsAction.confirm}
                  onClick={() => {
                    setValue(
                      'clubSettings.publicSettings.services.training.trainingReferencesInt',
                      trainingReferencesIntEnabled?.concat(
                        missingTrainingReferencesInt as DiveTrainingReferenceInternational[],
                      ),
                      {
                        shouldValidate: true,
                        shouldDirty: true,
                        shouldTouch: true,
                      },
                    );
                  }}
                  color={'primary-outline-light'}
                >
                  Cocher les formations manquantes
                </AppButton>
              </div>
            </AppMessageLight>
          )}
          {unusedTrainingReferencesInt?.length > 0 && (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <AppMessageLight type="info">
              <div className="grid gap-2 items-start">
                <div className="text-gray-500">
                  {unusedTrainingReferencesInt?.length} prestations sont
                  activées alors qu'elles n'ont pas de tarif défini:{' '}
                  {unusedTrainingReferencesInt?.slice(0, 8).join(', ')}
                  ...
                </div>
                <AppButton
                  icon={AppIconsAction.confirm}
                  onClick={() => {
                    setValue(
                      'clubSettings.publicSettings.services.training.trainingReferencesInt',
                      trainingReferencesIntEnabled?.filter(
                        (ref) => !unusedTrainingReferencesInt?.includes(ref),
                      ),
                      {
                        shouldValidate: true,
                        shouldDirty: true,
                        shouldTouch: true,
                      },
                    );
                  }}
                  color={'primary-outline-light'}
                >
                  Décocher les formations inutilisées
                </AppButton>
              </div>
            </AppMessageLight>
          )}
        </div>
      )}
    </div>
  );
};
