/* eslint-disable jsx-a11y/alt-text */
import {
  AppBookletPageGql_Company,
  EcommerceProductArticleGql_Company,
  EcommerceProductGql_Company,
} from '@mabadive/app-common-model';
import clsx from 'clsx';
import React from 'react';
import { useClubSettings } from '../../../../../../../business/club/data/hooks';
import { ProProductsPage_CategoryViewPanel_ProductCoverCard_CoverResume } from './ProProductsPage_CategoryViewPanel_ProductCoverCard_CoverResume';
import { ProProductsPage_CategoryViewPanelLocalState } from './useProProductsPage_CategoryViewPanelLocalState.hook';

export const ProProductsPage_CategoryViewPanel_ProductCoverCard_Cover = ({
  product,
  productArticles,
  productBookletPage,
  localState,
  children,
  className,
}: {
  product: EcommerceProductGql_Company;
  productArticles?: EcommerceProductArticleGql_Company[];
  productBookletPage: Partial<AppBookletPageGql_Company>;
  localState: ProProductsPage_CategoryViewPanelLocalState;
  children?: React.ReactNode | React.ReactNode[];
  className?: string;
}) => {
  const clubSettings = useClubSettings();
  const clubPublicSettings = clubSettings?.publicSettings;
  const appUiTheme = clubPublicSettings?.appUiTheme;

  const cover = productBookletPage?.mainContent?.cover;

  return (
    product &&
    cover && (
      <div className={clsx('w-full flex flex-col overflow-y-auto', className)}>
        {cover.image && (
          <img
            className="relative object-center w-full rounded-t-xl"
            width={1000} // TODO à stocker dans l'image
            height={400} // TODO à stocker dans l'image
            src={cover.image.publicUrl}
          />
        )}
        <div className="h-full py-2 px-4 flex-grow flex flex-col gap-2 md:gap-4 xl:gap-6 2xl:gap-8 justify-between">
          <div>
            <div
              className={clsx(
                'text-base font-bold w-full text-left',
                // displayWidth === 'mobile'
                //   ? 'text-md'
                //   : 'text-md lg:text-lg',
              )}
              style={{ color: appUiTheme.title1.textColor }}
            >
              {cover?.title?.toUpperCase()}
            </div>
            {cover?.subTitle && (
              <div
                className={clsx('text-sm font-medium w-full text-left')}
                style={{ color: appUiTheme.title2.textColor }}
              >
                {cover?.subTitle}
              </div>
            )}
            <ProProductsPage_CategoryViewPanel_ProductCoverCard_CoverResume
              className="my-4"
              productArticles={productArticles}
              product={product}
              localState={localState}
            />
          </div>
          {children && children}
        </div>
      </div>
    )
  );
};
