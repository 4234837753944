/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  AppDocUploadContext,
  AppEntityUpdateDescriptor,
  BookingParticipantEditorFormModel,
  PRO_AppDocResume,
} from '@mabadive/app-common-model';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useDebouncedValue } from 'rooks';
import { AppPageBlock } from 'src/business/_core/modules/layout';
import { confirmDialog } from 'src/business/_core/modules/layout/components/ConfirmDialog/confirmDialog.service';
import { useDiveCenterResume } from 'src/business/club/data/hooks';
import { useClubDiverCardAttributes } from 'src/business/club/modules/club-divers/components/ClubDiverCard';
import { ClubDialogsStateOld } from 'src/pages/_dialogs';
import { AppDocViewPanelDiver } from '../../../../app-doc';
import { BookingParticipantEditorQuickEditActions } from '../components/BookingParticipantDialog/BookingParticipantEditorDialog/BookingParticipantEditorQuickEditActions';
import {
  BookingParticipantEditorDialogEditPanel1,
  BookingParticipantEditorDialogEditPanel2,
  BookingParticipantEditorDialogEditPanel3,
} from '../components/BookingParticipantDialog/BookingParticipantEditorDialog/panels';
import { BookingParticipantEditorDialogEditPanel4 } from '../components/BookingParticipantDialog/BookingParticipantEditorDialog/panels/BookingParticipantEditorDialogEditPanel4';
import {
  bookingParticipantEditorDialogChangesBuilder,
  participantEditorFormInitialValueBuilder,
} from '../components/BookingParticipantDialog/BookingParticipantEditorDialog/services';
import {
  BookingParticipantEditorDialogHeader,
  useClubParticipantCardAttributes,
} from '../components/BookingParticipantDialog/_common';
import { useDefaultDiveConfigCardAttributes } from '../components/BookingParticipantDialog/_common/DefaultDiveConfigCard';
import { buildParticipantEditorEditOpenInputState } from '../components/useDiverBookingPageBookingCardLocalState.hook';
import {
  BookingMemberDiverEditFormRHF,
  BookingMemberGeneralEditFormRHF,
  BookingParticipantEditFormRHF,
} from '../forms';
import { BookingParticipantDefaultParamsEditFormRHF } from '../forms/BookingParticipantDefaultParamsEditForm';
import { diverBookingPageUpdateStateManager } from '../services';
import {
  BookingParticipantEditorDialogStateMode,
  BookingParticipantEditorParticipant,
  ParticipantFormExpansionPanelId,
} from './../models';
import { DiverBookingPageActions } from './../useDiverBookingPageActions.hook';
import { DiverBookingPageGlobalState } from './../useDiverBookingPageGlobalState.hook';

export const DiverBookingPageDiverSheet = ({
  globalState,
  dialogs,
  actions,
}: {
  globalState: DiverBookingPageGlobalState;
  dialogs: ClubDialogsStateOld;
  actions: DiverBookingPageActions;
}) => {
  const diveCenterResume = useDiveCenterResume();
  const diveCenterId = diveCenterResume?._id;
  const clubReference = diveCenterResume?.clubReference;
  const clubSettings = diveCenterResume?.clubResume?.clubSettings;

  const { aggregatedData, focus, setUpdateState, updateState } = globalState;
  const { clubDiver, diveSessionReference } = focus;

  const defaultExpandedPanel: ParticipantFormExpansionPanelId = undefined;

  const [expandedPanel, setExpandedPanel] =
    useState<ParticipantFormExpansionPanelId>(defaultExpandedPanel);

  const expandPanelToogle = useCallback(
    (panelId: ParticipantFormExpansionPanelId) => {
      setExpandedPanel(expandedPanel === panelId ? undefined : panelId);
    },
    [expandedPanel],
  );

  const participantData: BookingParticipantEditorParticipant = useMemo(() => {
    const bookingParticipantFull = aggregatedData.bookingParticipantsFull.find(
      (x) =>
        x.diver._id === clubDiver?._id &&
        x.diveSession.reference === diveSessionReference,
    );
    if (bookingParticipantFull) {
      return buildParticipantEditorEditOpenInputState({
        bookingParticipantFull,
      });
    } else {
      const bookingMember = aggregatedData.bookingMembers.find(
        (x) => x.diverId === clubDiver?._id,
      );
      const diver = aggregatedData.divers.find((x) => x._id === clubDiver?._id);

      const docResumes = aggregatedData.docResumes.filter(
        (x) => x.clubDiverId === clubDiver?._id,
      );
      const inquiryResponses = aggregatedData.inquiryResponses.filter(
        (x) => x.diverId === clubDiver?._id,
      );
      if (!bookingMember) {
        // édition d'un nouveau plongeur, sans booking
        const participant: BookingParticipantEditorParticipant = {
          bookingId: undefined,
          diver,
          bookingMember: undefined,
          bookingSessionId: undefined,
          diveSession: undefined,
          docResumes,
          inquiryResponses,
        };
        return participant;
      }

      const participant: BookingParticipantEditorParticipant = {
        bookingId: bookingMember.bookingId,
        diver,
        bookingMember: bookingMember,
        bookingSessionId: undefined,
        diveSession: undefined,
        docResumes,
        inquiryResponses,
      };
      return participant;
    }
  }, [aggregatedData, clubDiver, diveSessionReference]);

  const diver = participantData?.diver;
  const clubParticipant = participantData?.clubParticipant;
  const diveSession = participantData?.diveSession;
  const docResumes = participantData?.docResumes;

  const mode: Extract<
    BookingParticipantEditorDialogStateMode,
    'edit-diver' | 'edit-participant'
  > = useMemo(
    () => (!!clubParticipant ? 'edit-participant' : 'edit-diver'),
    [clubParticipant],
  );

  const initialFormValue = useMemo(
    () =>
      participantEditorFormInitialValueBuilder.buildInitialFormValue({
        mode: mode,
        participantData: participantData,
        diveCenterResume,
      }),
    [mode, participantData, diveCenterResume],
  );
  const { generalAttributes, diverAttributes } = useClubDiverCardAttributes({
    diver: participantData.diver,
    bookingMember: participantData.bookingMember,
  });

  const { primary: participantCardDiveAttributes } =
    useClubParticipantCardAttributes({
      participant: clubParticipant,
      displayMode: 'edit-participant-form-dive-parameters',
    });

  const { primary: participantCardMoreAttributes } =
    useClubParticipantCardAttributes({
      participant: clubParticipant,
      displayMode: 'edit-participant-form-more-parameters',
    });

  const form = useForm<BookingParticipantEditorFormModel>({
    values: initialFormValue,
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const { register, handleSubmit, watch, formState, control, setValue } = form;

  const formValueBeforeDebounce = useWatch({
    control,
  }) as BookingParticipantEditorFormModel;

  const toDebounce = useMemo(
    () => ({
      formValue: formValueBeforeDebounce,
      hasFormChanges: formState.isDirty,
    }),
    [formState.isDirty, formValueBeforeDebounce],
  );

  const [{ formValue, hasFormChanges }] = useDebouncedValue(toDebounce, 100);

  const [docResumesChanges, setDocResumesChanges] = useState<
    AppEntityUpdateDescriptor<PRO_AppDocResume>[] // pas encore utilisé dans ce contexte
  >([]);

  useEffect(() => {
    if (
      globalState.loadableContent?.contentState === 'full' &&
      (formValue?.diver as any)?._id
    ) {
      // pour fixer un bug, on vérifie que (formValue?.diver as any)?._id est présent
      // TODO: cette logique est pourrie, il faudra revoir ça

      // seulement si le contenu est chargé
      const { result, hasChanges } =
        bookingParticipantEditorDialogChangesBuilder.buildChangesResult({
          participantData,
          mode,
          formValue,
          hasFormChanges,
          clubReference,
          diveCenterId,
          initialFormValue,
          docResumesChanges,
        });

      if (hasChanges) {
        actions.updateDiverAndParticipant({
          result,
          optimizePatches: true,
          applyDefaultConfigChanges: false,
          bookingParticipants: [], // pas nécessaire si applyDefaultConfigChanges=false
          logContext: `automatic update from diver booking page (mode=${mode})`,
        });
      }
    }
    // only trigger if form changes!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValue, hasFormChanges, globalState.loadableContent?.contentState]);

  const defaultDiveConfigCardDiveAttributes =
    useDefaultDiveConfigCardAttributes({
      defaultDiveConfig: diver?.defaultDiveConfig,
    });
  const appDocUploadContext: AppDocUploadContext = useMemo(() => {
    const context: AppDocUploadContext = {
      clubDiverId: diver?._id,
      bookingId: undefined,
      bookingMemberId: undefined,
      appBookletPageId: undefined,
      fileType: 'image',
      scope: 'diver',
      isPublic: false,
    };
    return context;
  }, [diver]);

  return (
    <AppPageBlock className="app-p-content">
      {clubDiver && (
        <div>
          <BookingParticipantEditorDialogHeader
            diver={participantData?.diver}
            docResumes={participantData?.docResumes}
          />
          <div className="block overflow-hidden bg-white mb-5">
            <BookingParticipantEditorDialogEditPanel1
              className="mt-1"
              diver={clubDiver}
              // bookingMember={bookingMember}
              diverCardAttributes={generalAttributes}
              expandedPanel={expandedPanel}
              expandPanelToogle={expandPanelToogle}
              renderDetails={() => (
                <BookingMemberGeneralEditFormRHF
                  form={form}
                  appDocUploadContext={appDocUploadContext}
                />
              )}
            />

            <BookingParticipantEditorDialogEditPanel2
              className="mt-1"
              diver={clubDiver}
              bookingMember={participantData.bookingMember}
              diverCardAttributes={diverAttributes}
              expandedPanel={expandedPanel}
              expandPanelToogle={expandPanelToogle}
              renderDetails={() => (
                <BookingMemberDiverEditFormRHF
                  isDiverWithMember={true}
                  mode="edit"
                  className="pb-28"
                  form={form}
                />
              )}
            />
            {mode === 'edit-participant' && (
              <BookingParticipantEditorDialogEditPanel3
                className="mt-1"
                diver={diver}
                participant={clubParticipant}
                diveSession={diveSession}
                participantCardMoreAttributes={participantCardMoreAttributes}
                participantCardDiveAttributes={participantCardDiveAttributes}
                expandedPanel={expandedPanel}
                expandPanelToogle={expandPanelToogle}
                renderDetails={() => (
                  <BookingParticipantEditFormRHF
                    maxWidthMd={false}
                    form={form}
                  />
                )}
                renderQuickActions={() => (
                  <BookingParticipantEditorQuickEditActions
                    participant={clubParticipant}
                    mode={'edit-participant'}
                    setValue={setValue}
                    onClickDelete={() => {
                      confirmDialog
                        .confirm({
                          isTailwind: true,
                          title: 'Supprimer un participant',
                          message:
                            'Êtes-vous sûr de vouloir supprimer ce participant?',
                          type: 'remove',
                        })
                        .subscribe((confirmed) => {
                          if (confirmed) {
                            setUpdateState(
                              diverBookingPageUpdateStateManager.deleteParticipantFromState(
                                {
                                  updateState,
                                  bookingProductId:
                                    participantData.bookingProduct?._id,
                                  clubParticipantId:
                                    participantData.clubParticipant?._id,
                                  bookingSessionParticipantId:
                                    participantData?.bookingSessionParticipant
                                      ?._id,
                                },
                              ),
                              {
                                action: 'delete participant',
                                meta: {
                                  bookingProductId:
                                    participantData.bookingProduct?._id,
                                  clubParticipantId:
                                    participantData.clubParticipant?._id,
                                  bookingSessionParticipantId:
                                    participantData?.bookingSessionParticipant
                                      ?._id,
                                },
                              },
                            );
                          }
                        });
                    }}
                  />
                )}
              />
            )}
            {mode === 'edit-diver' && (
              // NOTE 06/11/2023 : j'ajoute ce cadre qui manque quand on ouvrir une résa,
              // mais je ne suis pas sûr si il faut aussi l'ouvrir dans le context "edit-participant"
              // donc dans le doute je le mets juste là
              <BookingParticipantEditorDialogEditPanel4
                className="mt-1"
                diver={diver}
                defaultDiveConfigCardDiveAttributes={
                  defaultDiveConfigCardDiveAttributes
                }
                expandedPanel={expandedPanel}
                expandPanelToogle={expandPanelToogle}
                renderDetails={() => (
                  <BookingParticipantDefaultParamsEditFormRHF
                    className="pb-28"
                    maxWidthMd={false}
                    form={form}
                  />
                )}
              />
            )}
            <AppDocViewPanelDiver
              className="px-4"
              bookingId={participantData?.bookingId}
              bookingMemberId={participantData?.bookingMember?._id}
              clubDiverId={participantData?.diver?._id}
              docResumes={participantData?.docResumes}
              dialogsState={globalState.dialogsState}
            />
          </div>
        </div>
      )}
    </AppPageBlock>
  );
};
