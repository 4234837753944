import { ProMultiOperationPayload } from '@mabadive/app-common-model';
import clsx from 'clsx';
import React, { useMemo, useState } from 'react';
import { clubMassiveUpdatorClient } from 'src/business/_core/data/app-operation';
import { confirmDialog } from 'src/business/_core/modules/layout/components/ConfirmDialog/confirmDialog.service';
import { AppButton } from 'src/business/_core/modules/layout/components/_tailwind';
import {
  AppHeroIcons,
  AppIconsAction,
} from 'src/business/_core/modules/layout/icons';
import {
  useAppBookingViewAuth,
  useAppSecurityUserClubAuthorizations,
} from 'src/business/auth/services';
import { useClubResume } from 'src/business/club/data/hooks';
import { DiverBookingCardBookingAction } from '../../../_from-diver-app';
import { BookingTabModel } from '../../models';
import { DiverBookingPageActions } from '../../useDiverBookingPageActions.hook';
import { DiverBookingPageGlobalState } from '../../useDiverBookingPageGlobalState.hook';

export function DiverBookingCardMainButtonsBar({
  globalState,
  actions,
  bookingTabModel,
  onClickBooking,
  setIsExpanded,
  className,
}: {
  globalState: DiverBookingPageGlobalState;
  actions: DiverBookingPageActions;
  bookingTabModel: BookingTabModel;
  onClickBooking: (
    bookingTabModel: BookingTabModel,
    action: DiverBookingCardBookingAction,
  ) => void;
  setIsExpanded: (isOpen: boolean) => void;

  className?: string;
}) {
  const clubResume = useClubResume();
  const clubSettings = clubResume?.clubSettings;

  const { booking, bookingContact } = bookingTabModel.aggregatedBooking;

  const clubPublicReference = clubResume.clubSettings?.clubPublicReference;
  const customerSpaceEnabled =
    clubPublicReference &&
    clubResume.clubSettings?.publicSettings?.customerSpace?.enabled &&
    booking?._id;

  const az = useAppSecurityUserClubAuthorizations();

  const sendMessageEnabled =
    clubSettings.communication?.booking?.enableMessage &&
    az.communication?.action?.sendBookingMessage;

  const { bookingMembersFull } = bookingTabModel.aggregatedBooking;

  const { futureSessionsCount } = bookingTabModel.meta;

  /***
   * FIXME déplacé dans le menu, à supprimer si personne ne se plaint
   * @deprecated
   */
  const showMassiveDialogButton =
    false && // désactivé, en attendant la suppression
    ((bookingMembersFull.length > 1 && futureSessionsCount > 0) ||
      (bookingMembersFull.length > 0 && futureSessionsCount > 1));

  const bookingViewAuth = useAppBookingViewAuth({
    bookingDiveCenterId: bookingTabModel.bookingDiveCenterId,
    isSharedBooking: bookingTabModel.isSharedBooking,
  });

  const showArchiveBookingButton = useMemo(() => {
    if (
      bookingTabModel.aggregatedBooking.booking.active &&
      bookingTabModel.participantsBySessionsPast?.length > 0 &&
      bookingTabModel.participantsBySessionsFuture?.length === 0
    ) {
      return true;
    }
    return false;
  }, [
    bookingTabModel.aggregatedBooking.booking.active,
    bookingTabModel.participantsBySessionsFuture?.length,
    bookingTabModel.participantsBySessionsPast?.length,
  ]);

  const showUnArchiveBookingButton = useMemo(() => {
    if (!bookingTabModel.aggregatedBooking.booking.active) {
      return true;
    }
    return false;
  }, [bookingTabModel.aggregatedBooking.booking.active]);

  const { aggregatedData, updateState, setUpdateState, dialogsState } =
    globalState;

  const [cb, setCb] = useState<() => void>();

  return bookingViewAuth.edit ? (
    <div className={clsx('flex items-start gap-1 sm:gap-2', className)}>
      {/* <AppButton
        fullWidth={true}
          className="xs:max-w-[8rem] sm:max-w-[12rem]"
        color="primary-outline-light"
        icon={AppHeroIcons.actionEdit}
        onClick={() => {
          actions.dialogs.setBookingGroupAndJourneyEditDialogState({
            isOpen: true,
            booking,
            bookingMembersFull,
          });
        }}
      >
        Modifier la réservation
      </AppButton> */}
      {customerSpaceEnabled && (
        <AppButton
          fullWidth={true}
          className="xs:max-w-[8rem] sm:max-w-[12rem] whitespace-nowrap"
          color="danger-outline-light"
          icon={AppHeroIcons.link}
          onClick={async () => {
            actions.persistAndOpenCustomerSpace(bookingTabModel);
          }}
        >
          Espace client
        </AppButton>
      )}
      {sendMessageEnabled && (
        <AppButton
          fullWidth={true}
          className="xs:max-w-[8rem] sm:max-w-[12rem]"
          color="primary-outline-light"
          icon={AppHeroIcons.sms}
          onClick={() => {
            actions.persistAndOpenCreateMessage(bookingTabModel);
          }}
        >
          MESSAGE
        </AppButton>
      )}
      {booking.active && booking.bookingStatus === 'pending' && (
        <AppButton
          fullWidth={true}
          className="xs:max-w-[8rem] sm:max-w-[12rem] whitespace-nowrap"
          color="success-outline-light"
          icon={AppHeroIcons.check}
          onClick={() => {
            onClickBooking(bookingTabModel, 'confirm-booking');
          }}
        >
          <span>
            Confirmer<span className="hidden sm:inline"> la réservation</span>
          </span>
        </AppButton>
      )}
      {clubSettings?.communication?.booking?.confirmation?.manual &&
        !bookingTabModel?.aggregatedBooking?.isNewBooking && ( // TODO mutualiser le fonctionnement avec celui de l'espace client
          <AppButton
            fullWidth={true}
            className=" xs:max-w-[12rem]"
            color="danger-outline-light"
            icon={AppHeroIcons.mail}
            onClick={async () => {
              const confirm = await confirmDialog.confirmPromise({
                title: 'Envoyer une confirmation',
                message:
                  'Êtes-vous sûr de vouloir enregistrer les changements et envoyer un e-mail de confirmation au client?',
                type: 'noYesInfo',
              });
              if (confirm) {
                const payload: ProMultiOperationPayload = {
                  logContext: 'save booking before customer space confirmation',
                  bookings: {
                    confirmationEmail: {
                      bookingId: booking?._id,
                    },
                  },
                };

                return clubMassiveUpdatorClient.update(payload);
              }
            }}
          >
            Email de confirmation
          </AppButton>
        )}
      {showMassiveDialogButton && (
        <AppButton
          fullWidth={true}
          className=" xs:max-w-[12rem]"
          color="primary-outline-light"
          onClick={() => {
            actions.dialogs.setBookingMassiveEditorDialogState({
              isOpen: true,
              bookingId: booking._id,
              loadedContent: globalState.loadedContent,
              updateState: globalState.updateState,
            });
          }}
          icon={AppHeroIcons.actionEditBulk}
        >
          Inscription en masse
        </AppButton>
      )}
      {showArchiveBookingButton && (
        <div>
          <AppButton
            className="whitespace-nowrap"
            color="primary-outline-light"
            icon={AppIconsAction.archive}
            onClick={() => {
              onClickBooking(bookingTabModel, 'archive-booking');
              setIsExpanded(false);
            }}
          >
            Archiver
          </AppButton>
        </div>
      )}
      {showUnArchiveBookingButton && (
        <div>
          <AppButton
            className="whitespace-nowrap"
            color="primary-outline-light"
            icon={AppIconsAction.archive}
            onClick={() => {
              onClickBooking(bookingTabModel, 'unarchive-booking');
              setIsExpanded(true);
            }}
          >
            Ré-activer
          </AppButton>
        </div>
      )}
    </div>
  ) : null;
}
