/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { FieldArrayWithId, UseFormReturn, useWatch } from 'react-hook-form';
import { AppSingleAutocomplete2RHF } from 'src/lib/form';
import { AppFormControlRHF } from 'src/lib/form/components/AppFormControl/AppFormControlRHF';

import {
  ClubParticipantSpecialDiveType,
  PARTICIPANT_SPECIAL_DIVE_TYPES,
} from '@mabadive/app-common-model';
import { participantSpecialDiveTypeFormatter } from '@mabadive/app-common-services';
import { ValueLabel } from 'src/business/club/modules/_common/form';
import { ClubSettingsProductOffersFormModel } from '../../_model';

const PARTICIPANT_SPECIAL_DIVE_TYPES_OPTIONS: ValueLabel<ClubParticipantSpecialDiveType>[] =
  PARTICIPANT_SPECIAL_DIVE_TYPES.map((status) => ({
    value: status,
    label: participantSpecialDiveTypeFormatter.formatSpecialDiveType(status, {
      format: 'long',
    }),
  }));

export const ClubSettingsProductOffersFormOffer_Explo_Common_Special_Dive = ({
  form,
  offerField,
  offerFieldIndex,
}: {
  form: UseFormReturn<ClubSettingsProductOffersFormModel>;
  offerField: FieldArrayWithId<
    ClubSettingsProductOffersFormModel,
    'offers',
    'id'
  >;
  offerFieldIndex: number;
}) => {
  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const i = offerFieldIndex;

  const [diveMode] = useWatch({
    control,
    name: [`offers.${i}.productPackage.diveAttributes.diveMode`],
  });

  return diveMode &&
    ['autonomous', 'supervised', 'instructor'].includes(diveMode) ? (
    <AppFormControlRHF
      className={'w-full col-span-2'}
      label="Plongée spéciale"
      control={control}
      name={`offers.${i}.productPackage.diveAttributes.specialDiveType`}
      renderComponent={(props) => (
        <AppSingleAutocomplete2RHF
          {...props}
          options={PARTICIPANT_SPECIAL_DIVE_TYPES_OPTIONS}
        />
      )}
    />
  ) : null;
};
