import {
  ClubPurchasePackagePaymentStatus,
  DiveServicePurchaseStatus,
} from '@mabadive/app-common-model';
import { PurchaseResumeCollectionCriteriaPurchaseType } from '../../../../business/_core/data/store-repository';
import { PurchaseListPageViewParameters } from '../model/PurchaseListPageViewParameters.type';

export const purchasesListPageUrlManager = {
  getQueryParameters,
  buildSearchParams,
};

export const QUERY_PARAMS = {
  searchTextContactName: 'st-cn', // Short for "search text contact name"
  searchTextOfferName: 'st-on', // Short for "search text offer name"
  validityStatus: 'vs', // Short for "validity status"
  paymentStatus: 'ps', // Short for "payment status"
  purchaseType: 'pt', // Short for "purchase type"
  bookingAgencyId: 'ba-id', // Short for "booking agency ID"
  beginPurchaseDate: 'bpd', // Short for "begin purchase date"
  endPurchaseDate: 'epd', // Short for "end purchase date"
};

function getQueryParameters(): PurchaseListPageViewParameters {
  const params = new URLSearchParams(window.location.search);

  const searchTextContactName = params.get(QUERY_PARAMS.searchTextContactName);
  const searchTextOfferName = params.get(QUERY_PARAMS.searchTextOfferName);
  const validityStatus = params.get(
    QUERY_PARAMS.validityStatus,
  ) as DiveServicePurchaseStatus;
  const paymentStatus = params.get(QUERY_PARAMS.paymentStatus) as
    | ClubPurchasePackagePaymentStatus
    | 'pending';
  const purchaseType = params.get(QUERY_PARAMS.purchaseType) as
    | PurchaseResumeCollectionCriteriaPurchaseType
    | 'all';
  const bookingAgencyId = params.get(QUERY_PARAMS.bookingAgencyId);

  const beginDateString = params.get(QUERY_PARAMS.beginPurchaseDate);
  const endDateString = params.get(QUERY_PARAMS.endPurchaseDate);
  const beginPurchaseDate = beginDateString
    ? new Date(parseInt(beginDateString, 10))
    : undefined;
  const endPurchaseDate = endDateString
    ? new Date(parseInt(endDateString, 10))
    : undefined;

  const viewParameters: PurchaseListPageViewParameters = {
    searchTextContactName,
    searchTextOfferName,
    validityStatus,
    paymentStatus,
    purchaseType,
    bookingAgencyId,
    beginPurchaseDate,
    endPurchaseDate,
  };

  return viewParameters;
}

function buildSearchParams(
  viewParameters: Partial<PurchaseListPageViewParameters>,
): string[] {
  const searchParams: string[] = [];

  if (viewParameters.searchTextContactName) {
    searchParams.push(
      `${QUERY_PARAMS.searchTextContactName}=${viewParameters.searchTextContactName}`,
    );
  }

  if (viewParameters.searchTextOfferName) {
    searchParams.push(
      `${QUERY_PARAMS.searchTextOfferName}=${viewParameters.searchTextOfferName}`,
    );
  }

  if (viewParameters.validityStatus) {
    searchParams.push(
      `${QUERY_PARAMS.validityStatus}=${viewParameters.validityStatus}`,
    );
  }

  if (viewParameters.paymentStatus) {
    searchParams.push(
      `${QUERY_PARAMS.paymentStatus}=${viewParameters.paymentStatus}`,
    );
  }

  if (viewParameters.purchaseType && viewParameters.purchaseType !== 'all') {
    searchParams.push(
      `${QUERY_PARAMS.purchaseType}=${viewParameters.purchaseType}`,
    );
  }

  if (viewParameters.bookingAgencyId) {
    searchParams.push(
      `${QUERY_PARAMS.bookingAgencyId}=${viewParameters.bookingAgencyId}`,
    );
  }

  if (viewParameters.beginPurchaseDate) {
    searchParams.push(
      `${
        QUERY_PARAMS.beginPurchaseDate
      }=${viewParameters.beginPurchaseDate.getTime()}`,
    );
  }

  if (viewParameters.endPurchaseDate) {
    searchParams.push(
      `${
        QUERY_PARAMS.endPurchaseDate
      }=${viewParameters.endPurchaseDate.getTime()}`,
    );
  }

  return searchParams;
}

function getBoolean(value: string): boolean {
  if (value === '1') {
    return true;
  }
  if (value === '0') {
    return false;
  }
  return undefined;
}
