/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import {
  AppBreadcrumbBar,
  AppLoaderSpinner,
  AppMessageLight,
  AppPageContainer,
  AppPageContentContainer,
} from 'src/business/_core/modules/layout';
import { AppIconsSettings } from 'src/business/_core/modules/layout/icons';
import { useAppSecurityUserHasRole } from '../../../business/auth/services';
import { AppInputBooleanSwitch } from '../../../lib/form';
import { useConfigAssistantProgressStepsPercent } from '../useConfigAssistantProgressSteps.hook';
import { ClubSettings_ConfigAssistantPage_SettingItem } from './ClubSettings_ConfigAssistantPage_SettingItem';
import { ConfigAssistantProgressBar } from './ConfigAssistantProgressBar';
import {
  ClubSettings_ConfigAssistantPageLocalState,
  useClubSettings_ConfigAssistantPageLocalState,
} from './useClubSettingsAccountDisplayPageLocalState.hook';

export type ClubSettings_ConfigAssistant_ConfigVM = {
  items: {};
};

export const ClubSettings_ConfigAssistantPage = (props: {}) => {
  const localState: ClubSettings_ConfigAssistantPageLocalState =
    useClubSettings_ConfigAssistantPageLocalState();

  const progressSteps = localState.state?.progressSteps;
  const displayExpertMode = localState.state?.displayExpertMode;
  const setDisplayExpertMode = localState.state?.setDisplayExpertMode;
  const operationInProgress = localState.state?.operationInProgress;

  const isSuperAdmin = useAppSecurityUserHasRole('super-admin');

  const progressStepsPercent = useConfigAssistantProgressStepsPercent({
    progressSteps,
  });

  return (
    <AppPageContainer className={'bg-gray-50'}>
      <AppBreadcrumbBar
        color={AppIconsSettings.other.color}
        items={[
          {
            icon: AppIconsSettings.general,
            label: 'Paramètres',
            url: '/club/settings',
          },
          {
            icon: AppIconsSettings.other.main,
            label: 'Configuration',
            url: '/club/settings',
          },
          {
            label: 'Affichage',
          },
        ]}
      ></AppBreadcrumbBar>
      <AppPageContentContainer
        paddingBottom={false}
        className="bg-gray-50 app-p-content"
      >
        <div className="grid gap-4">
          <div className="hidden md:block app-card app-p-content mb-4">
            <h2 className="text-app-primary font-medium">
              Configuration: {progressStepsPercent}%
              {operationInProgress && (
                <AppLoaderSpinner className="ml-2 inline-block w-6 h-6" />
              )}
            </h2>
            <div className="flex gap-x-8 gap-y-4 flex-wrap items-center">
              <ConfigAssistantProgressBar
                className="my-4"
                progressSteps={localState.state.progressSteps}
              />
            </div>
          </div>
          {progressSteps?.length > 0 && (
            <div>
              <AppMessageLight className={'my-4 hidden md:flex'} type="info">
                <p>
                  Cliquez sur chaque catégorie pour la configurer, puis cocher
                  la case "Terminé" quand c'est fait.
                </p>
              </AppMessageLight>
              <ul className="grid gap-4">
                {progressSteps.map((progressStep, index) => (
                  <ClubSettings_ConfigAssistantPage_SettingItem
                    className="app-card"
                    key={index}
                    as="li"
                    localState={localState}
                    progressStep={progressStep}
                  />
                ))}
              </ul>
            </div>
          )}
          {isSuperAdmin && (
            <div className="w-full flex justify-end">
              <AppInputBooleanSwitch
                label="Mode admin"
                value={displayExpertMode}
                onChange={() => setDisplayExpertMode(!displayExpertMode)}
              />
            </div>
          )}
        </div>
      </AppPageContentContainer>
    </AppPageContainer>
  );
};
