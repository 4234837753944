/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  EcommerceProductArticleGql_Company,
  EcommerceProductGql_Company,
} from '@mabadive/app-common-model';
import clsx from 'clsx';
import { default as React, useMemo } from 'react';
import { AppButton } from '../../../../../../business/_core/modules/layout';
import { AppHeroIcons } from '../../../../../../business/_core/modules/layout/icons';
import { ClubSettingsOnlineBookingProductsPageLocalState } from '../useClubSettingsOnlineBookingProductsPageLocalState.hook';
import { ProProductsPage_CategoryContentCardProductArticlesTable } from './ProProductsPage_CategoryContentCardProductArticlesTable';

export const ProProductsPage_CategoryContentCardProductArticlesArea = ({
  product,
  localState,
  className,
}: {
  product: EcommerceProductGql_Company;
  localState: ClubSettingsOnlineBookingProductsPageLocalState;
  className?: string;
}) => {
  const { state, dialogs, data, actions } = localState;

  const productArticles: EcommerceProductArticleGql_Company[] = useMemo(() => {
    return data.productArticles.filter((a) => a.productId === product._id);
  }, [data.productArticles, product._id]);

  const productBooklet = useMemo(() => {
    return data.booklets.find((x) => x._id === product.bookletId);
  }, [data.booklets, product.bookletId]);

  const productBookletPage = useMemo(() => {
    return data.bookletPages.find((x) => x._id === productBooklet?.rootPageId);
  }, [data.bookletPages, productBooklet?.rootPageId]);

  return (
    <div className={clsx('grid gap-4', className)}>
      <h4 className=" text-gray-500 text-base font-bold leading-3 uppercase">
        Tarifs
      </h4>
      {productArticles.length === 0 ? (
        <div className="text-sm font-bold text-app-red text-left uppercase">
          Veuillez ajouter un tarif pour ce produit
        </div>
      ) : (
        <ProProductsPage_CategoryContentCardProductArticlesTable
          localState={localState}
          product={product}
          onClickProductArticle={({
            productBookletPage,
            productArticle,
            appBooklet,
            appBookletPage,
          }) => {
            dialogs.productArticleEditor.openEditProductArticle({
              productBookletPage,
              editValue: {
                productArticle,
                appBooklet,
                appBookletPage,
              },
              product,
            });
          }}
        />
      )}
      <div className="my-4">
        <AppButton
          className=""
          color="primary-outline-light"
          size="normal"
          icon={AppHeroIcons.actionAdd}
          onClick={(e) => {
            e.stopPropagation();
            dialogs.productArticleEditor.openCreateProductArticle({
              productBookletPage,
              defaultValue: {
                productArticle: {
                  enabled: true,
                  name: product.name,
                  productId: product._id,
                },
              },
              product,
            });
          }}
        >
          Ajouter un tarif
        </AppButton>
      </div>
    </div>
  );
};
