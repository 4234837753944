/* eslint-disable @typescript-eslint/no-unused-vars */
import { BookingParticipantEditorFormModel } from '@mabadive/app-common-model';
import React, { useMemo } from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { useAppSecurityUser } from 'src/business/auth/services';
import { useClubResume } from 'src/business/club/data/hooks';
import { AppInputBooleanRHF, AppInputRHF } from 'src/lib/form';
import { AppFormControlRHF_Deprecated } from 'src/lib/form/components/AppFormControl/AppFormControlRHF_Deprecated';
import { AppSingleAutocomplete2RHF } from 'src/lib/form/components/AppSingleAutocomplete';
import { AppSingleSelect2HeadlessUIRHF } from 'src/lib/form/components/AppSingleSelect/AppSingleSelect2HeadlessUIRHF';
import { useLanguagesOptions } from 'src/pages/_components/options';
import { AppInputDatePickerMaterialRHF } from 'src/stories/components/04-form';
import { BookingMemberDiverEditFormRHF } from './BookingMemberDiverEditFormRHF';
import { BookingMemberGeneralEditFormAddress } from './BookingMemberGeneralEditForm';
import { customerOriginOptionsBuilder } from './customerOriginOptionsBuilder.service';
import { mainCertificationOptionBuilder } from './mainCertificationOptionBuilder.service';
import { useResidentTypesOptions } from './useResidentTypesOptions.hook';

export const MAIN_CERTIFICATION_OPTIONS =
  mainCertificationOptionBuilder.buildOptions();

export type BookingMemberDiverEditFormRHFFieldsToDisplay =
  | 'name-only'
  | 'frequent-only'
  | 'all';

export const BookingMemberCreateFormRHF = ({
  form,
  fieldsToDisplay = 'all',
  isDiverWithMember,
  className = '',
}: {
  form: UseFormReturn<BookingParticipantEditorFormModel>;
  fieldsToDisplay?: BookingMemberDiverEditFormRHFFieldsToDisplay;
  isDiverWithMember: boolean;
  className?: string;
}) => {
  const securityUser = useAppSecurityUser();

  const clubResume = useClubResume();

  const customerSettings = clubResume.clubSettings?.customer;
  const residentTypesOptions = useResidentTypesOptions();
  const { control, setValue } = form;

  const birthdate = useWatch({
    control,
    name: 'diver.birthdate',
  });

  const customerOriginOptions = useMemo(
    () =>
      customerOriginOptionsBuilder.buildOptions({
        clubSettings: clubResume?.clubSettings,
      }),
    [clubResume?.clubSettings],
  );

  const languagesOptions = useLanguagesOptions({
    scope: 'all',
  });

  return (
    <div className={className}>
      <div
        className={
          'w-full flex flex-col sm:grid sm:grid-cols-2 md:grid-cols-4 gap-4'
        }
      >
        <AppFormControlRHF_Deprecated
          className={'md:col-span-2 w-full'}
          label="Prénom"
          control={control}
          required={false}
          name={'diver.firstName'}
          renderComponent={(props) => (
            <AppInputRHF {...props} fullWidth type="text" />
          )}
        />
        <AppFormControlRHF_Deprecated
          className={'md:col-span-2 w-full'}
          label="Nom"
          control={control}
          name={'diver.lastName'}
          renderComponent={(props) => (
            <AppInputRHF {...props} fullWidth type="text" />
          )}
        />
        {(fieldsToDisplay === 'all' || fieldsToDisplay === 'frequent-only') && (
          <>
            <div className={'md:col-span-2 w-full flex gap-4'}>
              <AppFormControlRHF_Deprecated
                className={
                  customerSettings?.general?.phoneNumber2 ? 'w-1/2' : 'w-full'
                }
                label="Téléphone"
                control={control}
                name={'diver.phoneNumber'}
                renderComponent={(props) => (
                  <AppInputRHF {...props} fullWidth type="text" />
                )}
              />
              {customerSettings?.general?.phoneNumber2 && (
                <AppFormControlRHF_Deprecated
                  className={'w-1/2'}
                  label="Téléphone 2"
                  control={control}
                  name={'diver.phoneNumber2'}
                  renderComponent={(props) => (
                    <AppInputRHF {...props} fullWidth type="text" />
                  )}
                />
              )}
            </div>
          </>
        )}
        {(fieldsToDisplay === 'all' || fieldsToDisplay === 'frequent-only') && (
          <>
            <AppFormControlRHF_Deprecated
              className={'md:col-span-2 w-full'}
              label="Adresse e-mail"
              control={control}
              validation={{
                email: true,
              }}
              name={'diver.emailAddress'}
              renderComponent={(props) => (
                <AppInputRHF {...props} fullWidth type="text" />
              )}
            />
            <AppFormControlRHF_Deprecated
              className={`${
                customerSettings?.general?.age && !birthdate
                  ? ''
                  : 'md:col-span-2'
              } w-full`}
              label="Date de naissance"
              control={control}
              name={'diver.birthdate'}
              renderComponent={(props) => (
                <AppInputDatePickerMaterialRHF
                  initialView={birthdate ? 'weeks' : 'years'}
                  maxYear={new Date().getFullYear()}
                  {...props}
                  className="w-full"
                />
              )}
            />
            {customerSettings?.general?.age && !birthdate && (
              <AppFormControlRHF_Deprecated
                className={'w-full'}
                label="Âge"
                control={control}
                name={'diver.age'}
                renderComponent={(props) => (
                  <AppInputRHF {...props} fullWidth type="number" />
                )}
              />
            )}

            <div className="md:col-span-2 flex gap-2">
              {customerSettings?.general?.size && (
                <AppFormControlRHF_Deprecated
                  className={'w-full'}
                  label="Taille (cm)"
                  control={control}
                  name={'diver.size'}
                  renderComponent={(props) => (
                    <AppInputRHF {...props} fullWidth type="number" />
                  )}
                />
              )}
              {customerSettings?.general?.weight && (
                <AppFormControlRHF_Deprecated
                  className={'w-full'}
                  label="Poids (Kg)"
                  control={control}
                  name={'diver.weight'}
                  renderComponent={(props) => (
                    <AppInputRHF {...props} fullWidth type="number" />
                  )}
                />
              )}
              {customerSettings?.general?.shoeSize && (
                <AppFormControlRHF_Deprecated
                  className={'w-full'}
                  label="Pointure"
                  control={control}
                  name={'diver.details.measurements.shoeSize'}
                  renderComponent={(props) => (
                    <AppInputRHF {...props} fullWidth type="number" />
                  )}
                />
              )}
            </div>
            {customerSettings?.general?.customerOrigin && (
              <AppFormControlRHF_Deprecated
                className={'md:col-span-2 w-full'}
                label="Origine"
                control={control}
                name={'diver.details.customerOriginRef'}
                renderComponent={(props) => (
                  <AppSingleAutocomplete2RHF
                    className={'w-full'}
                    {...props}
                    options={customerOriginOptions}
                    onChange={(value) => {
                      const option = customerOriginOptions.find(
                        (x) => x.value === value,
                      );
                      setValue(
                        'diver.details.customerOriginLabel',
                        option?.label,
                      );
                    }}
                  />
                )}
              />
            )}
            {customerSettings?.general?.languageCode && (
              <AppFormControlRHF_Deprecated
                className={'md:col-span-2 w-full'}
                label="Langue"
                control={control}
                name={'diver.languageCode'}
                renderComponent={(props) => (
                  <AppSingleAutocomplete2RHF
                    {...props}
                    options={languagesOptions}
                    fullWidth
                  />
                )}
              />
            )}
          </>
        )}
      </div>
      {(fieldsToDisplay === 'all' || fieldsToDisplay === 'frequent-only') && (
        <BookingMemberDiverEditFormRHF
          isDiverWithMember={isDiverWithMember}
          mode="create"
          fieldsToDisplay={fieldsToDisplay}
          form={form}
        />
      )}
      <div
        className={
          'mt-2 w-full flex flex-col sm:grid sm:grid-cols-2 md:grid-cols-4 gap-4'
        }
      >
        {fieldsToDisplay === 'all' && (
          <>
            <div className="md:col-span-2 flex gap-2">
              {customerSettings?.general?.residentType && (
                <AppFormControlRHF_Deprecated
                  className={'w-full'}
                  label="Résidence"
                  control={control}
                  name={'diver.residentType'}
                  renderComponent={(props) => (
                    <AppSingleSelect2HeadlessUIRHF
                      className={'w-full'}
                      theme="material-ui"
                      color="theme"
                      {...props}
                      options={residentTypesOptions}
                    />
                  )}
                />
              )}
              {customerSettings?.general?.sanitaryPass && (
                <AppFormControlRHF_Deprecated
                  className={'w-1/2'}
                  label="Pass"
                  control={control}
                  name={'diver.sanitaryPass'}
                  renderComponent={(props) => (
                    <AppInputBooleanRHF
                      className="scale-125 block ml-0.5 mt-1 mb-1"
                      {...props}
                      type="checkbox"
                    />
                  )}
                />
              )}
            </div>
            <BookingMemberGeneralEditFormAddress form={form} />
            <AppFormControlRHF_Deprecated
              className={'md:col-span-4 w-full'}
              label="Personne à contacter en cas d'urgence"
              control={control}
              name={'diver.emergencyContact'}
              renderComponent={(props) => (
                <AppInputRHF
                  {...props}
                  fullWidth
                  type="text"
                  multiline
                  rowsMax={15}
                  rows={2}
                />
              )}
            />{' '}
          </>
        )}
        {(fieldsToDisplay === 'all' || fieldsToDisplay === 'frequent-only') && (
          <>
            <AppFormControlRHF_Deprecated
              className={'md:col-span-4 w-full'}
              label="Commentaire général privé 🚫"
              control={control}
              name={'diver.comment'}
              renderComponent={(props) => (
                <AppInputRHF
                  {...props}
                  fullWidth
                  multiline
                  rowsMax={15}
                  rows={2}
                />
              )}
            />
          </>
        )}
      </div>
    </div>
  );
};
