/* eslint-disable @typescript-eslint/no-unused-vars */
import { StaffMemberProAttachedData } from '@mabadive/app-common-model';
import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { apiClient } from 'src/_common-browser';

export function useStaffMemberAttachedData({
  staffMemberId,
}: {
  staffMemberId: string;
}) {
  const query: UseQueryResult<StaffMemberProAttachedData, unknown> = useQuery({
    queryKey: ['/pro/staff-member/attached-data', { staffMemberId }],
    queryFn: async () => {
      const results = await apiClient
        .post<any>('/pro/staff-member/attached-data', {
          authenticate: true,
          json: {
            staffMemberId,
          },
        })
        .toPromise();
      return results;
    },
    gcTime: 1000 * 60 * 60 * 24 * 2, // on garde ça en cache 2 jours
    staleTime: 0, // mais au chargement, on rafraichi systématiquement les données
    refetchOnWindowFocus: false, // pas de refresh si la fenêtre reprend le focus
    // refetchInterval: 2 * 60 * 1000, // et toutes les 2 minutes => pas de rafraichissement, car ça pose des problèmes
    enabled: !!staffMemberId,
    retry: 60, // en cas d'erreur, on ré-essaie 60 fois, chaque 2 secondes
    retryDelay: 1000 * 2,
  });
  const { isLoading: loadingInProgress, data, refetch } = query;

  return {
    data,
    refetch,
    loadingInProgress,
  };
}
