/* eslint-disable @typescript-eslint/no-unused-vars */
import clsx from 'clsx';
import { default as React } from 'react';
import { useAppSecurityUserClubAuthorizations } from 'src/business/auth/services';
import { useAppCurrencyMain } from 'src/pages/_components/options';
import {
  useClubResume,
  useDiveCenterResume,
} from '../../../../../business/club/data/hooks';
import { ResidentTypeLabelBadge } from '../../../../../business/club/modules/club-diver-participant/pages/DiverBookingPage/components/ResidentTypeLabelBadge';

export const ClubSettingsCustomerViewCard_ResidentTypes = () => {
  const mainCurrency = useAppCurrencyMain();
  const diveCenterResume = useDiveCenterResume();
  const clubResume = useClubResume();

  const clubSettings = clubResume?.clubSettings;
  const customerSettings = clubSettings?.customer;
  const diveCenterPublicSettings = diveCenterResume?.publicSettings;

  const az = useAppSecurityUserClubAuthorizations();

  return (
    <>
      <h2 className="py-2  text-left uppercase text-lg leading-6 font-medium text-gray-800">
        Résidence (catégorie de client)
      </h2>
      <div className="px-4 text-base text-gray-600 grid grid-cols-2 gap-2">
        {(clubSettings.customer.general?.residentTypeOptions ?? []).map((x) => (
          <ResidentTypeLabelBadge
            className={clsx(!x.enabled && 'grayscale line-through')} // gray et rayé if disabled
            key={x.ref}
            residentType={x.ref}
            iconSize="size-5"
          />
        ))}
      </div>
    </>
  );
};
