/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useMemo, useState } from 'react';
import { useWatch } from 'react-hook-form';
import {
  AppMessageLight,
  AppTabTailwind,
  AppTabsTailwind,
} from 'src/business/_core/modules/layout';
import {
  useAppSecurityUser,
  useAppSecurityUserHasRole,
} from 'src/business/auth/services';
import {
  useClubResume,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import {
  AppInputBooleanRHF,
  AppInputBooleanSwitchRHF,
  AppInputRHF,
} from 'src/lib/form';
import { AppFormControlRHF_Deprecated } from 'src/lib/form/components/AppFormControl/AppFormControlRHF_Deprecated';
import { CustomerSpaceConfigDialogInitialState } from '../model';
import { CustomerSpaceConfigDialogLocalState } from '../useCustomerSpaceConfigDialogLocalState.hook';
import { CustomerSpaceConfigFormCustomerUpdate } from './CustomerSpaceConfigFormCustomerUpdate';
import { CustomerSpaceConfigFormTabCreateAccount } from './CustomerSpaceConfigFormTabCreateAccount';
import { CustomerSpaceConfigFormTabHomePage } from './CustomerSpaceConfigFormTabHomePage';

type CustomerSpaceConfigTabId =
  | 'tab-display'
  | 'tab-home'
  | 'tab-features'
  | 'tab-consent'
  | 'tab-customer-update'
  | 'tab-create-account';
export const CustomerSpaceConfigForm = ({
  localState,
  initialState,
}: {
  localState: CustomerSpaceConfigDialogLocalState;
  initialState: CustomerSpaceConfigDialogInitialState;
}) => {
  const { form } = localState;
  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const securityUser = useAppSecurityUser();

  const isSuperAdmin = useAppSecurityUserHasRole('super-admin');

  const clubResume = useClubResume();
  const customerSettings = clubResume?.clubSettings?.customer;
  const clubReference = clubResume.reference;
  const diveCenterResume = useDiveCenterResume();
  const clubSettings = clubResume?.clubSettings;
  const publicData = clubResume.clubSettings?.publicData;
  const publicDataBrand = diveCenterResume?.publicData?.brand;

  const isMultiDiveCenters = clubResume.diveCenters.length > 1;

  const formPrefix = 'clubSettings.publicSettings.customerSpace' as const;

  const [
    customerSpaceEnabled,
    clubPublicReference,
    insuranceEnabled,
    insuranceCustomized,
    newsletterEnabled,
    newsletterCustomized,
    gdprEnabled,
    gdprCustomized,
    divesFutureEnabled,
    divesPastEnabled,
  ] = useWatch({
    control,
    name: [
      `${formPrefix}.enabled`,
      'clubSettings.clubPublicReference',
      `${formPrefix}.features.insurance.enabled`,
      `${formPrefix}.features.insurance.customize`,
      `${formPrefix}.features.newsletter.enabled`,
      `${formPrefix}.features.newsletter.customize`,
      `${formPrefix}.consents.gdpr.enabled`,
      `${formPrefix}.consents.gdpr.customize`,
      `${formPrefix}.display.divesFuture.enabled`,
      `${formPrefix}.display.divesPast.enabled`,
    ],
  });

  const [tab, setTab] = useState<CustomerSpaceConfigTabId>('tab-home');
  const tabs = useMemo(() => {
    const tabs: AppTabTailwind<CustomerSpaceConfigTabId>[] = [
      {
        id: 'tab-home',
        label: 'Page d\'accueil',
      },

      {
        id: 'tab-customer-update',
        label: 'Infos à renseigner',
      },
      {
        id: 'tab-features',
        label: 'Options',
      },
      {
        id: 'tab-consent',
        label: 'Consentement',
      },
      {
        id: 'tab-display',
        label: 'Affichage',
      },
      {
        id: 'tab-create-account',
        label: 'Lien d\'inscription',
      },
    ];
    return tabs;
  }, []);

  return (
    <div className="grid gap-4">
      <h3 className="mb-2 uppercase text-base sm:text-lg text-app-blue font-bold">
        Espace client
      </h3>
      <AppFormControlRHF_Deprecated
        className=""
        label={'Espace client'}
        control={control}
        name={`${formPrefix}.enabled`}
        renderComponent={(props) => (
          <AppInputBooleanSwitchRHF {...props} label={'Activé'} />
        )}
      />
      {customerSpaceEnabled && (
        <div className="grid grid-cols-1 gap-x-4 gap-y-2 md:gap-y-4">
          <AppTabsTailwind
            className="my-2 w-full"
            tabs={tabs}
            selected={tab}
            theme="underline"
            breakpoint="sm"
            onChange={(tabId) => setTab(tabId)}
          />
          {tab === 'tab-display' && (
            <>
              <div>
                <AppFormControlRHF_Deprecated
                  label={'Onglet "Participants"'}
                  control={control}
                  name={`${formPrefix}.display.participants.enabled`}
                  renderComponent={(props) => (
                    <AppInputBooleanSwitchRHF {...props} label={'Activé'} />
                  )}
                />
              </div>
              <div>
                <AppFormControlRHF_Deprecated
                  label={'Onglet "Plongées (à venir)"'}
                  control={control}
                  name={`${formPrefix}.display.divesFuture.enabled`}
                  renderComponent={(props) => (
                    <AppInputBooleanSwitchRHF {...props} label={'Activé'} />
                  )}
                />
                {divesFutureEnabled && (
                  <div className="flex flex-wrap gap-4 lg:gap-x-8 justify-between lg:justify-start">
                    <AppFormControlRHF_Deprecated
                      label={'Heure'}
                      control={control}
                      name={`${formPrefix}.display.divesFuture.config.showTime`}
                      renderComponent={(props) => (
                        <AppInputBooleanRHF
                          {...props}
                          type="checkbox"
                          description={'Activé'}
                        />
                      )}
                    />
                    <AppFormControlRHF_Deprecated
                      label={'Bateau'}
                      control={control}
                      name={`${formPrefix}.display.divesFuture.config.showBoat`}
                      renderComponent={(props) => (
                        <AppInputBooleanRHF
                          {...props}
                          type="checkbox"
                          description={'Activé'}
                        />
                      )}
                    />
                    <AppFormControlRHF_Deprecated
                      label={'Site'}
                      control={control}
                      name={`${formPrefix}.display.divesFuture.config.showDiveSite`}
                      renderComponent={(props) => (
                        <AppInputBooleanRHF
                          {...props}
                          type="checkbox"
                          description={'Activé'}
                        />
                      )}
                    />
                  </div>
                )}
              </div>
              <div>
                <AppFormControlRHF_Deprecated
                  label={'Onglet "Historique (plongées passées)"'}
                  control={control}
                  name={`${formPrefix}.display.divesPast.enabled`}
                  renderComponent={(props) => (
                    <AppInputBooleanSwitchRHF {...props} label={'Activé'} />
                  )}
                />
                {divesPastEnabled && (
                  <div className="flex flex-wrap gap-4 lg:gap-x-8 justify-between lg:justify-start">
                    <AppFormControlRHF_Deprecated
                      label={'Heure'}
                      control={control}
                      name={`${formPrefix}.display.divesPast.config.showTime`}
                      renderComponent={(props) => (
                        <AppInputBooleanRHF
                          {...props}
                          type="checkbox"
                          description={'Activé'}
                        />
                      )}
                    />
                    <AppFormControlRHF_Deprecated
                      label={'Bateau'}
                      control={control}
                      name={`${formPrefix}.display.divesPast.config.showBoat`}
                      renderComponent={(props) => (
                        <AppInputBooleanRHF
                          {...props}
                          type="checkbox"
                          description={'Activé'}
                        />
                      )}
                    />
                    <AppFormControlRHF_Deprecated
                      label={'Site'}
                      control={control}
                      name={`${formPrefix}.display.divesPast.config.showDiveSite`}
                      renderComponent={(props) => (
                        <AppInputBooleanRHF
                          {...props}
                          type="checkbox"
                          description={'Activé'}
                        />
                      )}
                    />
                  </div>
                )}
              </div>
              <AppMessageLight>
                Paramètres par défaut, personnalisable sur chaque réservation.
              </AppMessageLight>
            </>
          )}
          {tab === 'tab-home' && (
            <CustomerSpaceConfigFormTabHomePage localState={localState} />
          )}
          {tab === 'tab-customer-update' && (
            <>
              <CustomerSpaceConfigFormCustomerUpdate form={form} />
              <AppMessageLight>
                Paramètres par défaut, personnalisable sur chaque réservation.
              </AppMessageLight>
            </>
          )}
          {tab === 'tab-create-account' && (
            <CustomerSpaceConfigFormTabCreateAccount localState={localState} />
          )}
          {tab === 'tab-features' && (
            <>
              <div className="flex flex-wrap gap-4 lg:gap-x-8 justify-between lg:justify-start">
                <AppFormControlRHF_Deprecated
                  label={'Demander les infos de l\'assurance'}
                  control={control}
                  name={`${formPrefix}.features.insurance.enabled`}
                  renderComponent={(props) => (
                    <AppInputBooleanSwitchRHF {...props} label={'Activé'} />
                  )}
                />

                {insuranceEnabled && (
                  <>
                    <AppFormControlRHF_Deprecated
                      label={'Personnaliser'}
                      control={control}
                      name={`${formPrefix}.features.insurance.customize`}
                      renderComponent={(props) => (
                        <AppInputBooleanRHF
                          {...props}
                          type="checkbox"
                          description={'Activé'}
                        />
                      )}
                    />
                  </>
                )}
              </div>
              {insuranceEnabled && insuranceCustomized && (
                <>
                  <AppFormControlRHF_Deprecated
                    label={'Message d\'information assurance'}
                    control={control}
                    name={`${formPrefix}.features.insurance.message`}
                    required={insuranceCustomized}
                    renderComponent={(props) => (
                      <AppInputRHF
                        {...props}
                        fullWidth
                        type="text"
                        multiline
                        rowsMin={3}
                        rowsMax={15}
                        rows={2}
                      />
                    )}
                  />
                </>
              )}
              <div className="flex flex-wrap gap-4 lg:gap-x-8 justify-between lg:justify-start">
                <AppFormControlRHF_Deprecated
                  label={'Proposer de s\'inscrire à la newsletter'}
                  control={control}
                  name={`${formPrefix}.features.newsletter.enabled`}
                  renderComponent={(props) => (
                    <AppInputBooleanSwitchRHF {...props} label={'Activé'} />
                  )}
                />

                {newsletterEnabled && (
                  <>
                    <AppFormControlRHF_Deprecated
                      label={'Personnaliser'}
                      control={control}
                      name={`${formPrefix}.features.newsletter.customize`}
                      renderComponent={(props) => (
                        <AppInputBooleanRHF
                          {...props}
                          type="checkbox"
                          description={'Activé'}
                        />
                      )}
                    />
                  </>
                )}
              </div>
              {newsletterEnabled && newsletterCustomized && (
                <>
                  <AppFormControlRHF_Deprecated
                    label={'Message personnalisé newsletter'}
                    control={control}
                    name={`${formPrefix}.features.newsletter.message`}
                    renderComponent={(props) => (
                      <AppInputRHF
                        {...props}
                        fullWidth
                        type="text"
                        multiline
                        rowsMin={3}
                        rowsMax={15}
                        rows={2}
                      />
                    )}
                  />
                </>
              )}
            </>
          )}
          {tab === 'tab-consent' && (
            <>
              <div className="flex flex-wrap gap-4 lg:gap-x-8 justify-between lg:justify-start">
                {isSuperAdmin && (
                  <AppFormControlRHF_Deprecated
                    label={'Demander une signature manuscrite'}
                    visibility={'super-admin'}
                    helpDescription={
                      'Le client devra reproduire sa signature manuscrite avec le doigt ou la souris'
                    }
                    control={control}
                    name={`${formPrefix}.consents.padSignature.enabled`}
                    renderComponent={(props) => (
                      <AppInputBooleanSwitchRHF {...props} label={'Activé'} />
                    )}
                  />
                )}
                <AppFormControlRHF_Deprecated
                  label={'Exiger l\'acceptation du RGPD'}
                  control={control}
                  name={`${formPrefix}.consents.gdpr.enabled`}
                  renderComponent={(props) => (
                    <AppInputBooleanSwitchRHF {...props} label={'Activé'} />
                  )}
                />

                {gdprEnabled && (
                  <>
                    <AppFormControlRHF_Deprecated
                      label={'Personnaliser'}
                      control={control}
                      name={`${formPrefix}.consents.gdpr.customize`}
                      renderComponent={(props) => (
                        <AppInputBooleanRHF
                          {...props}
                          type="checkbox"
                          description={'Activé'}
                        />
                      )}
                    />
                  </>
                )}
              </div>
              {gdprEnabled && gdprCustomized && (
                <>
                  <AppFormControlRHF_Deprecated
                    label={'Message personnalisé RGPD'}
                    control={control}
                    name={`${formPrefix}.consents.gdpr.consentAgreement.message`}
                    renderComponent={(props) => (
                      <AppInputRHF
                        {...props}
                        fullWidth
                        type="text"
                        multiline
                        rowsMin={3}
                        rowsMax={15}
                        rows={2}
                      />
                    )}
                  />
                </>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};
