/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useMemo, useState } from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import {
  AppInputBooleanSwitch,
  AppInputBooleanSwitchRHF,
  AppMultiCheckboxesRHF,
  AppSingleAutocomplete2RHF,
} from 'src/lib/form';
import { AppFormControlRHF } from 'src/lib/form/components/AppFormControl/AppFormControlRHF';

import {
  ClubProductPackageOfferView,
  ClubServicesGlobalSettingsOrgGroup,
  DIVE_SERVICE_ORGANIZATIONS,
  DiveServiceOrganizationReferenceFrench,
  DiveServiceOrganizationReferenceInternational,
} from '@mabadive/app-common-model';
import { ValueLabel } from 'src/business/club/modules/_common/form';
import { ClubSettingsSection } from 'src/pages/SE-settings/_core';
import { ClubSettingsServicesFormModel } from '../../../_model';
import { ClubSettingsServicesTrainingFormOrgs_TrainingActivation_Fr } from './ClubSettingsServicesTrainingFormOrgs_TrainingActivation_Fr';
import { ClubSettingsServicesTrainingFormOrgs_TrainingActivation_Int } from './ClubSettingsServicesTrainingFormOrgs_TrainingActivation_Int';

const FRENCH_ORGANIZATION_REFERENCES_OPTIONS =
  DIVE_SERVICE_ORGANIZATIONS.filter(
    (x) => x.orgGroupReferencev2 === 'french',
  ).map(({ label, reference }) => {
    const option: ValueLabel<DiveServiceOrganizationReferenceFrench> = {
      label,
      value: reference as DiveServiceOrganizationReferenceFrench,
    };
    return option;
  });

const INTERNATIONAL_ORGANIZATION_REFERENCES_OPTIONS =
  DIVE_SERVICE_ORGANIZATIONS.filter(
    (x) => x.orgGroupReferencev2 === 'international',
  ).map(({ label, reference }) => {
    const option: ValueLabel<DiveServiceOrganizationReferenceInternational> = {
      label,
      value: reference as DiveServiceOrganizationReferenceInternational,
    };
    return option;
  });

export const ClubSettingsServicesTrainingFormOrgs = ({
  form,
  existingOffers,
}: {
  form: UseFormReturn<ClubSettingsServicesFormModel>;
  existingOffers?: ClubProductPackageOfferView[]; // utilisé pour les formations
}) => {
  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const [displayExpertMode, setDisplayExpertMode] = useState(false);

  const [
    globalSettingsFrenchEnabled,
    globalSettingsFrenchOrganizationReferences,
    globalSettingsInternationalEnabled,
    globalSettingsInternationalOrganizationReferences,
  ] = useWatch({
    control,
    name: [
      'clubSettings.services.french.enabled',
      'clubSettings.services.french.organizationReferences',
      'clubSettings.services.international.enabled',
      'clubSettings.services.international.organizationReferences',
    ],
  });
  const globalSettingsFrenchDefaultOrganizationReferenceOptions = useMemo(
    () =>
      FRENCH_ORGANIZATION_REFERENCES_OPTIONS.filter((x) =>
        globalSettingsFrenchOrganizationReferences.includes(x.value),
      ),
    [globalSettingsFrenchOrganizationReferences],
  );
  const globalSettingsInternationalDefaultOrganizationReferenceOptions =
    useMemo(
      () =>
        INTERNATIONAL_ORGANIZATION_REFERENCES_OPTIONS.filter((x) =>
          globalSettingsInternationalOrganizationReferences.includes(x.value),
        ),
      [globalSettingsInternationalOrganizationReferences],
    );

  const defaultOrgGroupOptions = useMemo(() => {
    const options: ValueLabel<ClubServicesGlobalSettingsOrgGroup>[] = [];

    if (globalSettingsFrenchEnabled) {
      options.push({
        label: 'Français',
        value: 'french',
      });
    }
    if (globalSettingsInternationalEnabled) {
      options.push({
        label: 'International',
        value: 'international',
      });
    }

    return options;
  }, [globalSettingsFrenchEnabled, globalSettingsInternationalEnabled]);

  return (
    <ClubSettingsSection title="Organismes">
      <div className="ml-2 grid gap-8">
        <div className="grid gap-2">
          <AppFormControlRHF
            className="w-full"
            label="Plongée système français"
            control={control}
            name={'clubSettings.services.french.enabled'}
            required={false}
            renderComponent={(props) => <AppInputBooleanSwitchRHF {...props} />}
          />

          {globalSettingsFrenchEnabled && (
            <div className="grid gap-4">
              <div className="sm:flex gap-4">
                <AppFormControlRHF
                  label={'Organismes certificateurs'}
                  required={globalSettingsFrenchEnabled}
                  control={control}
                  name={'clubSettings.services.french.organizationReferences'}
                  renderComponent={(props) => (
                    <AppMultiCheckboxesRHF
                      {...props}
                      options={FRENCH_ORGANIZATION_REFERENCES_OPTIONS}
                    />
                  )}
                />
                <AppFormControlRHF
                  label={'Organisme par défaut'}
                  required={globalSettingsFrenchEnabled}
                  control={control}
                  name={
                    'clubSettings.services.french.defaultOrganizationReference'
                  }
                  renderComponent={(props) => (
                    <AppSingleAutocomplete2RHF
                      {...props}
                      options={
                        globalSettingsFrenchDefaultOrganizationReferenceOptions
                      }
                    />
                  )}
                />
              </div>
              {displayExpertMode && (
                <ClubSettingsServicesTrainingFormOrgs_TrainingActivation_Fr
                  form={form}
                  existingOffers={existingOffers}
                />
              )}
            </div>
          )}
        </div>
        <div className="grid gap-2">
          <AppFormControlRHF
            className="w-full"
            label="Plongée système international"
            control={control}
            name={'clubSettings.services.international.enabled'}
            required={false}
            renderComponent={(props) => <AppInputBooleanSwitchRHF {...props} />}
          />

          {globalSettingsInternationalEnabled && (
            <div className="sm:flex gap-4">
              <AppFormControlRHF
                label={'Organismes certificateurs'}
                required={globalSettingsInternationalEnabled}
                control={control}
                name={
                  'clubSettings.services.international.organizationReferences'
                }
                renderComponent={(props) => (
                  <AppMultiCheckboxesRHF
                    {...props}
                    options={INTERNATIONAL_ORGANIZATION_REFERENCES_OPTIONS}
                  />
                )}
              />
              <AppFormControlRHF
                label={'Organisme par défaut'}
                required={globalSettingsInternationalEnabled}
                control={control}
                name={
                  'clubSettings.services.international.defaultOrganizationReference'
                }
                renderComponent={(props) => (
                  <AppSingleAutocomplete2RHF
                    {...props}
                    options={
                      globalSettingsInternationalDefaultOrganizationReferenceOptions
                    }
                  />
                )}
              />
            </div>
          )}

          {displayExpertMode && (
            <ClubSettingsServicesTrainingFormOrgs_TrainingActivation_Int
              form={form}
              existingOffers={existingOffers}
            />
          )}
        </div>
        <div className="flex gap-4 flew-wrap">
          <AppFormControlRHF
            label={'Organisme par défaut'}
            required={true}
            control={control}
            name="clubSettings.services.defaultOrgGroup"
            renderComponent={(props) => (
              <AppSingleAutocomplete2RHF
                {...props}
                options={defaultOrgGroupOptions}
              />
            )}
          />

          <AppFormControlRHF
            className="w-full"
            label="Afficher le niveau du plongeur en formation sur le planning"
            control={control}
            name={'clubSettings.ui.planning.displayMainLevelForTraining'}
            required={false}
            renderComponent={(props) => <AppInputBooleanSwitchRHF {...props} />}
          />
        </div>
        <div className="my-2 flex justify-end">
          <AppInputBooleanSwitch
            label="Mode expert"
            value={displayExpertMode}
            onChange={() => setDisplayExpertMode(!displayExpertMode)}
          />
        </div>
      </div>
    </ClubSettingsSection>
  );
};
