import {
  appDocStatusFormatter,
  dateService,
} from '@mabadive/app-common-services';
import clsx from 'clsx';
import React from 'react';
import {
  AppDropzoneFileTypeIcon,
  AppIconsStatus,
} from '../../../../../../../_core/modules/layout/icons';
import { DiverBookingSpace_DocDataResume } from './model/DiverBookingSpace_DocDataResume.type';

export function DiverBookingSpace_DocDataResumeBadge({
  docDataResume,
  onClick,
  className,
}: {
  docDataResume: DiverBookingSpace_DocDataResume;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  className?: string;
}) {
  const status = docDataResume.status;

  const DocIcon = AppDropzoneFileTypeIcon[docDataResume?.appDoc?.fileType];

  return docDataResume ? (
    <div
      className={clsx(className, onClick ? 'cursor-pointer' : '')}
      onClick={onClick}
    >
      <div className="w-28 flex flex-col gap-y-1">
        <h4 className="text-xs uppercase font-medium text-gray-600 truncate text-center">
          {docDataResume.title}
        </h4>
        <div className="border border-gray-100 bg-gray-50 flex flex-col gap-y-0.5 justify-around relative">
          {DocIcon && (
            <DocIcon className="size-6 absolute top-1 left-1 text-gray-800" />
          )}
          {status === 'missing' ? (
            <div className="flex-grow flex justify-center items-center">
              <AppIconsStatus.none className="size-12 text-gray-200" />
            </div>
          ) : (
            <div className="flex-grow flex flex-col gap-0.5 justify-center items-center">
              <div className="flex-grow flex justify-center items-center">
                <docDataResume.icon
                  className={clsx(
                    'size-12',
                    status === 'submitted'
                      ? 'text-status-warn'
                      : status === 'validated'
                      ? 'text-gray-600'
                      : status === 'rejected'
                      ? 'text-status-error'
                      : status === 'expired'
                      ? 'text-status-error'
                      : 'text-status-warn', // 'draft'
                  )}
                />
              </div>
              <div
                className={clsx(
                  'text-sm uppercase',
                  status === 'submitted'
                    ? 'text-status-warn font-medium'
                    : status === 'validated'
                    ? 'text-gray-600'
                    : status === 'rejected'
                    ? 'text-status-error font-medium'
                    : status === 'expired'
                    ? 'text-status-error font-medium'
                    : 'text-status-warn', // 'draft'
                )}
              >
                {status === 'expired'
                  ? 'Expiré'
                  : appDocStatusFormatter.formatAppDocStatus(status)}
              </div>
              {docDataResume.issueDate && (
                <div
                  className={clsx(
                    'text-sm',
                    status === 'expired'
                      ? 'text-status-error font-medium'
                      : 'text-gray-600',
                  )}
                >
                  {dateService.formatLocal(
                    docDataResume.issueDate,
                    'DD/MM/YYYY',
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  ) : null;
}
