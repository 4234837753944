/* eslint-disable @typescript-eslint/no-unused-vars */
import { RegionalSettings } from '@mabadive/app-common-model';
import { diveModeFormatter } from '@mabadive/app-common-services';
import React from 'react';
import {
  useClubResume,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import { AppFeatureBadge } from 'src/business/club/modules/_common/ui/AppFeatureBadge';
import { ClubSettingsSection } from 'src/pages/SE-settings/_core';
import {
  useAppCurrencyMain,
  useRegionalSettings,
} from 'src/pages/_components/options';
import {
  ClubDialogsState,
  UseClubDialogsProps,
  useClubDialogs,
  useClubDialogsActionsPersist,
} from 'src/pages/_dialogs';
import { ClubSettingsServicesActivitiesViewPanelDiveMode } from './ClubSettingsServicesActivitiesViewPanelDiveMode';

export const ClubSettingsServicesActivitiesViewPanelOther = () => {
  const diveCenterResume = useDiveCenterResume();
  const clubResume = useClubResume();

  const clubSettings = clubResume?.clubSettings;

  const actionsPersist: UseClubDialogsProps = useClubDialogsActionsPersist();
  const dialogsState: ClubDialogsState = useClubDialogs(actionsPersist);

  const regionalSettings: RegionalSettings = useRegionalSettings({
    effectiveDate: new Date(),
  });

  const mainCurrency = useAppCurrencyMain();

  return (
    <ClubSettingsSection title={'Autres prestations'}>
      <div className="relative grid gap-2 font-bold text-gray-600">
        <AppFeatureBadge
          enabled={clubSettings.observer.enabled}
          label={diveModeFormatter.formatDiveMode('observer', {
            format: 'short-ref-label',
            diveModesConf: clubSettings?.ui?.diveMode,
          })}
          icon={
            <ClubSettingsServicesActivitiesViewPanelDiveMode diveMode="observer" />
          }
        />
        <AppFeatureBadge
          enabled={clubSettings.snorkeling.enabled}
          label={diveModeFormatter.formatDiveMode('snorkeling', {
            format: 'short-ref-label',
            diveModesConf: clubSettings?.ui?.diveMode,
          })}
          icon={
            <ClubSettingsServicesActivitiesViewPanelDiveMode diveMode="snorkeling" />
          }
        />
        <AppFeatureBadge
          enabled={clubSettings.snorkelingSupervised.enabled}
          label={diveModeFormatter.formatDiveMode('snorkelingSupervised', {
            format: 'short-ref-label',
            diveModesConf: clubSettings?.ui?.diveMode,
          })}
          icon={
            <ClubSettingsServicesActivitiesViewPanelDiveMode diveMode="snorkelingSupervised" />
          }
        />
        <AppFeatureBadge
          enabled={clubSettings.watchingTour.enabled}
          label={diveModeFormatter.formatDiveMode('watchingTour', {
            format: 'short-ref-label',
            diveModesConf: clubSettings?.ui?.diveMode,
          })}
          icon={
            <ClubSettingsServicesActivitiesViewPanelDiveMode diveMode="watchingTour" />
          }
        />
        <AppFeatureBadge
          enabled={clubSettings.services?.store?.enabled}
          label={'Boutique'}
        />
      </div>
    </ClubSettingsSection>
  );
};
