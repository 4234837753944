/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  CLUB_STAFF_MEMBER_DAILY_AVAILABILITY_PERIODS,
  CLUB_STAFF_MEMBER_DAILY_AVAILABILITY_PERIODS_AVAILABLE,
  ClubResumeStaffMember,
  ClubStaffMemberDailyAvailabilityPeriod,
  ClubStaffMemberRole,
  DiveSessionStaffMemberTableModelStaffMember,
  StaffMemberResumeSessionsDayModel,
  StaffMemberResumeSessionsModel,
} from '@mabadive/app-common-model';
import {
  commonDiveSessionBuilder,
  dateService,
  nameFormatter,
} from '@mabadive/app-common-services';
import React, { useMemo } from 'react';
import { StaffLevelBadge } from 'src/business/_core/modules/layout';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import {
  AppInputBooleanSwitch,
  AppSingleSelect2HeadlessUI,
} from 'src/lib/form';
import { useDailyAvailabilityPeriodOptions } from '../../../../../../../../../../pages/_components/options';
import {
  DiveSessionStaffMemberSessionTimes,
  DiveSessionStaffMembersTableRowStaffRoles,
  DiveSessionStaffMembersTableSession,
  DiveSessionStaffRoleModel,
} from '../DiveSessionDialogTab3Groups/components';

export function DiveSessionDialogTab2StaffMembersListTableRowStaff({
  diveSessionReference,
  isMultiSessionConfigForStaff,
  staffMembersResumeSessions,
  staffFirstNameBefore,
  staffMemberFull,
  availableRoles,
  onClickEdit,
  onUpdateSessionStaffConfig,
  updateDayConfig,
  date,
  className = '',
}: {
  diveSessionReference: string;
  isMultiSessionConfigForStaff: boolean;
  staffMembersResumeSessions: StaffMemberResumeSessionsModel[];
  staffFirstNameBefore: boolean;
  staffMemberFull: DiveSessionStaffMemberTableModelStaffMember;
  availableRoles: ClubStaffMemberRole[];
  onClickEdit: () => any;
  onUpdateSessionStaffConfig: ({
    availabilityPeriod,
    isAvailableOnSession,
    toogleRole,
  }: {
    availabilityPeriod: ClubStaffMemberDailyAvailabilityPeriod;
    isAvailableOnSession: boolean;
    toogleRole?: DiveSessionStaffRoleModel;
  }) => any;
  updateDayConfig: (params: {
    staffMember: ClubResumeStaffMember;
    staffMemberResumeSessionsDay: StaffMemberResumeSessionsDayModel;
    availabilityPeriod: ClubStaffMemberDailyAvailabilityPeriod;
  }) => void;
  date: Date;
  className?: string;
}) {
  const { staffMember, session1, session2, meta, dailyAvailabilityResume } =
    staffMemberFull;

  const staffMemberResumeSessions: StaffMemberResumeSessionsModel =
    useMemo(() => {
      return staffMembersResumeSessions.find(
        (x) => x.staffMember._id === staffMember._id,
      );
    }, [staffMember._id, staffMembersResumeSessions]);

  const staffMemberResumeSessionsDay: StaffMemberResumeSessionsDayModel =
    useMemo(() => {
      if (staffMemberResumeSessions) {
        const dayReference = commonDiveSessionBuilder.buildDayReference(date);
        return staffMemberResumeSessions.days.find(
          (x) => x.dayReference === dayReference,
        );
      }
    }, [date, staffMemberResumeSessions]);
  const isPast = !dateService.isTodayUTC(date) && dateService.isPastUTC(date);

  const hasAnyDailyActiveRoles =
    staffMemberResumeSessionsDay?.dailyActiveRoles.length > 0;

  const availabilityPeriodOptions = useDailyAvailabilityPeriodOptions({
    periods: hasAnyDailyActiveRoles
      ? CLUB_STAFF_MEMBER_DAILY_AVAILABILITY_PERIODS_AVAILABLE
      : CLUB_STAFF_MEMBER_DAILY_AVAILABILITY_PERIODS,
  });

  return (
    <tbody className={`cursor-pointer ${className}`}>
      <tr
        className={`${
          meta.active
            ? 'text-app-blue'
            : meta.available
            ? 'text-gray-600'
            : 'text-gray-400'
        }`}
        onClick={onClickEdit}
      >
        <th
          scope="col"
          className="pr-1 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
        >
          <AppHeroIcons.actionEditAlt className="w-8 h-6" />
        </th>
        <td className={'px-2 py-1 whitespace-nowrap text-sm'}>
          <div
            className={'flex whitespace-nowrap flex-wrap items-center gap-1'}
          >
            <StaffLevelBadge
              className="rounded px-0.5 w-9 text-xs flex-shrink-0"
              staffMember={staffMember}
              date={date}
            />
            <span className={'truncate font-bold flex-shrink'}>
              {nameFormatter.formatFullName(staffMember.profile, {
                format: staffFirstNameBefore
                  ? 'firstName-first'
                  : 'lastName-first',
              })}
            </span>
          </div>
        </td>
        {onUpdateSessionStaffConfig && (
          <>
            <th
              scope="col"
              className="hidden px-4 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {/* HIDDEN */}
            </th>
            <th
              scope="col"
              className="w-[8rem] py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {/* <AppInputBooleanSwitch
                className={clsx(meta.active && 'hidden')}
                disabled={meta.active}
                value={meta.available}
                // readOnly={true}
                onChange={undefined}
              /> */}
              {/* NOTE:
              // AVANT on assignait à la session, via ce bouton
              // maintenant ce bouton ne modifie que la dispo Journée
              // il faut donner un rôle si on veut assigner juste à la session */}
              {!dailyAvailabilityResume.isAvailableToday ? (
                <AppInputBooleanSwitch
                  theme={'info'}
                  value={dailyAvailabilityResume.isAvailableToday}
                  label={'Absent'}
                  onChange={() => {
                    if (!dailyAvailabilityResume.isAvailableToday) {
                      updateDayConfig({
                        staffMemberResumeSessionsDay,
                        availabilityPeriod: 'day',
                        staffMember: staffMember,
                      });
                    }
                  }}
                />
              ) : (
                <div onClick={(e) => e.stopPropagation()}>
                  <AppSingleSelect2HeadlessUI
                    theme={'tailwind'}
                    value={dailyAvailabilityResume?.availabilityPeriod}
                    options={availabilityPeriodOptions}
                    disableClearButton={true}
                    optionsStyle={{}}
                    className="text-app-primary-light"
                    onChange={(availabilityPeriod) => {
                      updateDayConfig({
                        staffMemberResumeSessionsDay,
                        availabilityPeriod,
                        staffMember: staffMember,
                      });
                      // if (!meta.active) {
                      //   onUpdateSessionStaffConfig({
                      //     availabilityPeriod,
                      //     isAvailableOnSession: undefined,
                      //     toogleRole: undefined,
                      //   });
                      // }
                    }}
                  />
                </div>
              )}
            </th>
          </>
        )}
        <td
          className={
            'px-4 py-1 whitespace-nowrap text-sm hidden sm:table-cell text-center'
          }
          onClick={(e) => {
            onUpdateSessionStaffConfig && e.stopPropagation();
          }}
        >
          {staffMemberResumeSessionsDay && (
            <DiveSessionStaffMemberSessionTimes
              className={''}
              fontSize={'text-xs'}
              sessionsDetails={staffMemberResumeSessionsDay?.sessionsDetails}
              focusSessionReference={diveSessionReference}
              timesToDisplay={'assigned'}
              // TODO vérifier dans le contexte si besoin de filtrer (dans le doute, on ne filtre pas au moment de l'introduction de ce paramètre)
              grayScaleCancelled={false}
              grayScalePastAndEmpty={false}
              isPast={isPast}
            />
          )}
        </td>
        <td
          className={
            'px-4 py-1 whitespace-nowrap text-sm hidden sm:table-cell text-center'
          }
          onClick={(e) => {
            onUpdateSessionStaffConfig && e.stopPropagation();
          }}
        >
          <DiveSessionStaffMembersTableRowStaffRoles
            className="w-full"
            isMultiSessionConfigForStaff={isMultiSessionConfigForStaff}
            staffMemberFull={staffMemberFull}
            availableRoles={availableRoles}
            onClick={
              onUpdateSessionStaffConfig
                ? ({ role }) => {
                    if (onUpdateSessionStaffConfig) {
                      onUpdateSessionStaffConfig({
                        availabilityPeriod:
                          meta.available ||
                          !role.assigned ||
                          dailyAvailabilityResume.isAvailableToday
                            ? dailyAvailabilityResume.availabilityPeriod
                            : 'none',
                        isAvailableOnSession: meta.available || !role.assigned,
                        toogleRole: role,
                      });
                    }
                  }
                : undefined
            }
          />
        </td>
        <td
          className={'px-4 py-1 whitespace-nowrap hidden sm:table-cell text-xs'}
          onClick={onClickEdit}
        >
          <DiveSessionStaffMembersTableSession
            showRoles={false}
            session={session1}
          />
        </td>
        {isMultiSessionConfigForStaff && (
          <td
            className={
              'px-4 py-1 whitespace-nowrap hidden sm:table-cell text-xs'
            }
            onClick={onClickEdit}
          >
            <DiveSessionStaffMembersTableSession
              showRoles={false}
              session={session2}
            />
          </td>
        )}
      </tr>
      <tr className={'sm:hidden'}>
        <td
          colSpan={5}
          className={'px-2 py-1 whitespace-nowraptext-xs'}
          onClick={onClickEdit}
        >
          <div className="flex gap-2">
            <DiveSessionStaffMembersTableRowStaffRoles
              className="w-full"
              isMultiSessionConfigForStaff={isMultiSessionConfigForStaff}
              staffMemberFull={staffMemberFull}
              availableRoles={availableRoles}
              onClick={
                onUpdateSessionStaffConfig
                  ? ({ role }) => {
                      if (onUpdateSessionStaffConfig) {
                        onUpdateSessionStaffConfig({
                          availabilityPeriod:
                            meta.available ||
                            !role.assigned ||
                            dailyAvailabilityResume.isAvailableToday
                              ? 'day'
                              : 'none',
                          isAvailableOnSession:
                            meta.available || !role.assigned,
                          toogleRole: role,
                        });
                      }
                    }
                  : undefined
              }
            />
            <div className="flex justify-between">
              <DiveSessionStaffMembersTableSession
                showRoles={false}
                session={session1}
              />
              {isMultiSessionConfigForStaff && (
                <DiveSessionStaffMembersTableSession
                  showRoles={false}
                  session={session2}
                />
              )}
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  );
}
