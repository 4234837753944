// ISO 3166-1 codes - https://github.com/umpirsky/country-list

import { ClubSettings } from '@mabadive/app-common-model';
import { ValueLabel } from 'src/business/club/modules/_common/form/components/ValueLabel.model';

export const customerOriginOptionsBuilder = {
  buildOptions,
};
function buildOptions({
  clubSettings,
}: {
  clubSettings: ClubSettings;
}): ValueLabel<string, string>[] {
  const customerOriginValues =
    clubSettings?.customer?.general?.customerOriginOptions ?? [];
  // clubReference === 'seacretdive-port-louis-98825'
  //   ? CUSTOMER_ORIGIN_SD_VALUES
  //   : CUSTOMER_ORIGIN_COMMON_VALUES;

  return customerOriginValues.map((x) => {
    const { ref: value, label } = x;
    const o: ValueLabel<string, string> = {
      value,
      label,
    };
    return o;
  });
}
