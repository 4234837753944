import clsx from 'clsx';
import React, { useMemo } from 'react';
import {
  AppGenericStatus,
  AppGenericStatusIcon,
  appGenericStatusStyleBuilder,
} from 'src/business/_core/modules/layout/components/_tailwind';
import { BillingTabParticipantPurchaseDivesCounts } from '../../models';

export function BillingParticipantPurchaseResumeDivesCount({
  divesCounts,
  isVirtual,
  opacity,
  className,
}: {
  divesCounts: BillingTabParticipantPurchaseDivesCounts;
  isVirtual: boolean;
  opacity?: 60;
  className?: string;
}) {
  const totalConsumedCount = useMemo(
    () =>
      divesCounts.assigned +
      // divesCounts.toAssign +
      divesCounts.consumedExternalCount,
    [divesCounts.assigned, divesCounts.consumedExternalCount],
  );
  const badgeStatus: AppGenericStatus = useMemo(() => {
    if (totalConsumedCount === divesCounts.total) {
      return 'info';
    }
    if (totalConsumedCount > divesCounts.total) {
      return 'error';
    }
    return 'active';
  }, [divesCounts.total, totalConsumedCount]);

  const badgeStatusClass: string = useMemo(
    () => appGenericStatusStyleBuilder.build(badgeStatus),
    [badgeStatus],
  );

  return (
    <div
      className={clsx(
        opacity === 60 && 'text-opacity-60',
        badgeStatusClass,
        className,
      )}
    >
      {!!divesCounts.total && (
        <span className={'font-bold'}>
          {totalConsumedCount}/{divesCounts.total}
        </span>
      )}
      {!isVirtual && (
        <AppGenericStatusIcon
          status={badgeStatus}
          className={'ml-1 inline w-4 h-4'}
        />
      )}
    </div>
  );
}
