import { nameFormatter } from '@mabadive/app-common-services';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { MdCallMerge } from 'react-icons/md';
import { DiveCenterProBadge } from '../../../../../../../../pages/_components/company';
import { AppButtonMultiActions } from '../../../../../../../_core/modules/layout';
import { useAppBookingViewAuth } from '../../../../../../../auth/services';
import { useClubResume } from '../../../../../../data/hooks';
import { AppHamburgerMenuItem } from '../../../../../_common/ui';
import {
  AgencyLabel,
  BookingGroupLabel,
  BookingStatusLabel,
} from '../../../DiverBookingPage/components/BookingLabelsComponents';
import { useDisplayBookingGroup } from '../../../DiverBookingPage/components/BookingLabelsComponents/useDisplayBookingGroup.hook';
import {
  DiverBookingCardJourneySteps,
  DiverBookingTitle,
} from '../../../DiverBookingPage/components/DiverBookingCard';
import { BookingTabModel } from '../../../DiverBookingPage/models';
import { bookingPageUpdateStateBookingMerger } from '../../../DiverBookingPage/services/02.update-state/services';
import { DiverBookingPageActions } from '../../../DiverBookingPage/useDiverBookingPageActions.hook';
import { DiverBookingPageGlobalState } from '../../../DiverBookingPage/useDiverBookingPageGlobalState.hook';
import { useDiverBookingSpace_BookingListItemMenu } from './useDiverBookingSpace_BookingListItemMenu.hook';

export function DiverBookingSpace_BookingListItem({
  globalState,
  actions,
  bookingTabModel,
  className,
}: {
  globalState: DiverBookingPageGlobalState;
  actions: DiverBookingPageActions;
  bookingTabModel: BookingTabModel;
  className?: string;
}) {
  const extraMenuItems = useExtraMenuItems({ globalState, bookingTabModel });

  const partiticipantsCount = useMemo(
    () =>
      bookingTabModel.participantsBySessions.reduce((acc, ps) => {
        return (
          acc + ps.participants.filter((x) => !!x.bookingParticipantFull).length
        );
      }, 0),
    [bookingTabModel.participantsBySessions],
  );
  const clubResume = useClubResume();
  const customerSettings = clubResume?.clubSettings?.customer;
  const isMultiDiveCenters = clubResume.diveCenters.length > 1;
  const bookingDiveCenter = useMemo(
    () =>
      clubResume.diveCenters.find(
        (dc) => dc._id === bookingTabModel.bookingDiveCenterId,
      ),
    [bookingTabModel.bookingDiveCenterId, clubResume.diveCenters],
  );

  const booking = bookingTabModel.aggregatedBooking.booking;

  const { bookingMembersFull } = bookingTabModel.aggregatedBooking;

  const { isUniqueMember } = bookingTabModel.meta;

  const menuItems = useDiverBookingSpace_BookingListItemMenu({
    globalState,
    booking,
    actions,
    bookingTabModel,
    extraMenuItems,
  });

  const datesRange = bookingTabModel.meta.datesRange;

  const bookingViewAuth = useAppBookingViewAuth({
    bookingDiveCenterId: bookingTabModel.bookingDiveCenterId,
    isSharedBooking: bookingTabModel.isSharedBooking,
  });
  const stepsToDisplay = useMemo(() => {
    return (booking?.bookingJourney?.steps ?? []).filter(
      (s) => !!s, // TODO filtrer par diveCenterId, sauf si on est admin?
    );
  }, [booking?.bookingJourney]);

  const { isVisible: bookingGroupBookingGroup } = useDisplayBookingGroup({
    type: booking.bookingGroup?.type,
    label: booking.bookingGroup?.name?.toUpperCase(),
  });

  const participantsFirstNames = useMemo(
    () =>
      bookingMembersFull
        .map((bm, memberIndex) => {
          let name = nameFormatter.formatFirstName(bm.diver);
          // if (!name && bm.diver.fakeName) {
          //   name = `n°${memberIndex + 1}`;
          // }
          // if (!name) {
          //   name = `n°${memberIndex + 1}`;
          // }
          return name;
        })
        .filter((x) => !!x),
    [bookingMembersFull],
  );

  return (
    <div
      className={clsx(
        'app-card app-p-content hover:app-card-highlight',
        className,
      )}
    >
      <div
        className={`flex flex-nowrap justify-between items-start ${
          className ?? ''
        }`}
      >
        <div
          className={clsx(
            'flex-grow grid gap-1',
            bookingViewAuth.edit && 'cursor-pointer',
          )}
          onClick={() => {
            actions.openBooking(bookingTabModel.bookingId);
            // setTimeout(() => window.scrollTo(0, 0), 5000);
          }}
        >
          <h2 className="text-base sm:text-lg leading-6 pt-0 mt-0 flex items-center">
            <DiverBookingTitle
              className="font-medium text-gray-700"
              datesRangeClassName="text-sm sm:text-base"
              bookingNumber={bookingTabModel.bookingNumber}
              datesRange={datesRange}
            ></DiverBookingTitle>
          </h2>

          <div className="grid gap-y-2">
            {(bookingGroupBookingGroup || !!booking.details?.agencyId) && (
              <div className="flex gap-2 flex-nowrap items-start">
                <BookingGroupLabel
                  className=""
                  bookingGroup={booking.bookingGroup}
                />

                <AgencyLabel
                  className="text-xs"
                  agencyId={booking.details?.agencyId}
                />
              </div>
            )}
            <div className="w-full flex gap-2 flex-nowrap items-center">
              {isMultiDiveCenters && (
                <DiveCenterProBadge
                  className="my-px"
                  diveCenter={
                    booking.isSharedBooking ? null : bookingDiveCenter
                  }
                />
              )}
              <BookingStatusLabel booking={booking} />
              {booking.details?.externalRef?.trim()?.length > 0 && (
                <div className="text-xs text-app-dark-orange font-bold">
                  #{booking.details?.externalRef}
                </div>
              )}
              <span className="text-xs text-app-blue whitespace-nowrap ">
                {partiticipantsCount} PLONGÉE(S)
              </span>
              {!isUniqueMember && (
                <span className="text-xs whitespace-nowrap">
                  <span className="text-gray-600 ">
                    {bookingMembersFull.length} PARTICIPANT(S)
                  </span>
                  <span className="hidden text-gray-600 md:inline text-xs">
                    {': '}
                    {participantsFirstNames.join(', ')}
                  </span>
                </span>
              )}
            </div>
          </div>
          {stepsToDisplay.length > 0 && (
            <div className="flex flex-col gap-1">
              {stepsToDisplay.map((step) => (
                <DiverBookingCardJourneySteps key={step._id} step={step} />
              ))}
            </div>
          )}
          <div>
            <div className="flex-grow flex gap-2 flex-nowrap items-center">
              {booking.details?.customField1?.trim()?.length > 0 && (
                <div className="text-xs">
                  <h3 className="data-label text-gray-600 font-bold max-w-full">
                    {customerSettings?.booking?.customField1Label}
                  </h3>
                  <div className="data-value text-app-blue max-w-full truncate">
                    {booking.details?.customField1}
                  </div>
                </div>
              )}
              {booking.details?.customField2?.trim()?.length > 0 && (
                <div className="text-xs">
                  <h3 className="data-label text-gray-600 font-bold max-w-full">
                    {customerSettings?.booking?.customField2Label}
                  </h3>
                  <div className="data-value text-app-blue max-w-full truncate">
                    {booking.details?.customField2}
                  </div>
                </div>
              )}
              {booking.details?.customField3?.trim()?.length > 0 && (
                <div className="text-xs">
                  <h3 className="data-label text-gray-600 font-bold max-w-full">
                    {customerSettings?.booking?.customField3Label}
                  </h3>
                  <div className="data-value text-app-blue max-w-full truncate">
                    {booking.details?.customField3}
                  </div>
                </div>
              )}{' '}
              {booking.details?.customBoolean1 === true && (
                <div className="text-xs">
                  <h3 className="data-label text-gray-600 font-bold max-w-full">
                    {customerSettings?.booking?.customBoolean1Label}
                  </h3>
                  <div className="data-value text-app-blue max-w-full truncate">
                    {booking.details?.customBoolean1 ? 'oui' : 'non'}
                  </div>
                </div>
              )}
              {booking.details?.customBoolean2 === true && (
                <div className="text-xs">
                  <h3 className="data-label text-gray-600 font-bold max-w-full">
                    {customerSettings?.booking?.customBoolean2Label}
                  </h3>
                  <div className="data-value text-app-blue max-w-full truncate">
                    {booking.details?.customBoolean2 ? 'oui' : 'non'}
                  </div>
                </div>
              )}
              {booking.details?.customBoolean3 === true && (
                <div className="text-xs">
                  <h3 className="data-label text-gray-600 font-bold max-w-full">
                    {customerSettings?.booking?.customBoolean3Label}
                  </h3>
                  <div className="data-value text-app-blue max-w-full truncate">
                    {booking.details?.customBoolean3 ? 'oui' : 'non'}
                  </div>
                </div>
              )}
            </div>
          </div>

          {booking.bookingInitialPlan?.privateComment && (
            <div className="text-xs text-gray-600 italic">
              {booking.bookingInitialPlan?.privateComment}
            </div>
          )}
        </div>
        {bookingViewAuth.edit && (
          <div className="sm:ml-3 md:ml-4 flex items-center">
            {menuItems?.length > 0 && (
              <AppButtonMultiActions items={menuItems} />
            )}
            {/* <AppHamburgerMenu
            buttonClassName="mr-2 bg-status-warn hover:bg-status-warn-dark text-gray-200 hover:text-white"
            position="left-start"
            items={hamburgerMenuItems}
            buttonIcon={AppHeroIcons.actionMenu}
          >
            <h2 className="text-center text-lg leading-6 p-2 font-medium text-gray-800">
              Réservation
            </h2>
          </AppHamburgerMenu> */}
          </div>
        )}
      </div>
    </div>
  );
}
function useExtraMenuItems({
  globalState,
  bookingTabModel,
}: {
  globalState: DiverBookingPageGlobalState;
  bookingTabModel: BookingTabModel;
}) {
  const { diveCenterId, az, clubReference, updateState, setUpdateState } =
    globalState.state;
  const { bookingTabModels, aggregatedData } = globalState.data;
  const mergeableBookings: BookingTabModel[] = useMemo(
    () =>
      (bookingTabModels ?? []).filter(
        (x) =>
          x.bookingId !== bookingTabModel.bookingId &&
          x.bookingDiveCenterId === diveCenterId,
      ),
    [bookingTabModels, bookingTabModel.bookingId, diveCenterId],
  );
  const extraMenuItems = useMemo(() => {
    const hamburgerMenuItems: AppHamburgerMenuItem[] = [];
    if (az.edit.participant.booking) {
      mergeableBookings.forEach((bookingTarget) => {
        hamburgerMenuItems.push({
          title: `Fusionner avec la réservation N°${bookingTarget.bookingNumber}`, // en mode xs, la description n'apparait pas
          description:
            'Les plongeurs et plongées des 2 réservations seront regroupées',
          icon: MdCallMerge,
          onClick: () => {
            const bookingSource = bookingTabModel;

            // merge bookings
            const updateStateLocal =
              bookingPageUpdateStateBookingMerger.mergeBookings({
                clubReference,
                aggregatedData,
                updateState,
                bookingSources: [bookingSource],
                bookingTarget,
              });

            setUpdateState(updateStateLocal, {
              action: 'Fusionner avec la réservation',
            });
          },
        });
      });
    }
    return hamburgerMenuItems;
  }, [
    az.edit.participant.booking,
    mergeableBookings,
    bookingTabModel,
    clubReference,
    aggregatedData,
    updateState,
    setUpdateState,
  ]);
  return extraMenuItems;
}
