/* eslint-disable @typescript-eslint/no-unused-vars */
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import {
  AppButton,
  AppMessageSingleLine,
} from 'src/business/_core/modules/layout';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { useAppSecurityUserClubAuthorizations } from 'src/business/auth/services';
import { useDiveCenterResume } from 'src/business/club/data/hooks';
import { ValueLabel } from 'src/business/club/modules/_common/form';
import { AppFormControlV2 } from 'src/lib/form';
import { AppSingleSelect2HeadlessUI } from 'src/lib/form/components/AppSingleSelect/AppSingleSelect2HeadlessUI';
import { useBookingOptions } from 'src/pages/_components/options';
import { BookingTabModel } from '../../models';
import { defaultBookingBuilder } from '../../services';
import { DiverBookingPageActions } from '../../useDiverBookingPageActions.hook';
import { DiverBookingPageGlobalState } from '../../useDiverBookingPageGlobalState.hook';
export const DiverBookingPageMessagingTabCreateMessageArea = ({
  globalState,
  actions,
}: {
  globalState: DiverBookingPageGlobalState;
  actions: DiverBookingPageActions;
}) => {
  const diveCenterResume = useDiveCenterResume();
  const clubSettings = diveCenterResume.clubResume.clubSettings;
  const diveCenterId = diveCenterResume._id;
  const az = useAppSecurityUserClubAuthorizations();

  const {
    data,
    aggregatedData,
    updateState,
    setUpdateState,
    focus,
    navigationContext,
    loadedContent,
    includeArchivedBookings,
    linkedData,
  } = globalState;

  const sendMessageEnabled =
    clubSettings.communication?.booking?.enableMessage &&
    az.communication?.action?.sendBookingMessage;

  const bookingTabModels: BookingTabModel[] = data.bookingTabModels;

  const defaultBookingId = defaultBookingBuilder.getDefaultBookingId(
    aggregatedData.bookingResumesVisible,
    {
      diveCenterId,
      focusDiverId: aggregatedData.focus?.clubDiver._id,
    },
  );

  const bookingOptions: ValueLabel<string, React.ReactNode>[] =
    useBookingOptions({
      aggregatedData,
      onlyActive: true,
      initialBookingId: defaultBookingId,
      diveCenterId,
    });

  const [selectedBookingId, setSelectedBookingId] = useState(defaultBookingId);

  useEffect(() => {
    if (defaultBookingId && !selectedBookingId) {
      setSelectedBookingId(defaultBookingId);
    }
  }, [defaultBookingId, selectedBookingId]);

  return (
    <div className={clsx('my-2 p-1 flex flex-wrap gap-1 sm:gap-2')}>
      {sendMessageEnabled && (
        <>
          {bookingOptions.length > 0 ? (
            <div className="flex gap-4 flex-wrap items-start">
              {selectedBookingId && (
                <AppButton
                  className="min-w-48"
                  color="primary-outline-light"
                  icon={AppHeroIcons.sms}
                  onClick={async () => {
                    // TODO: on pourrait soit sélectionner une réservation, soit un plongeur...
                    const bookingTabModel =
                      bookingTabModels.find(
                        (x) => x.bookingId === selectedBookingId,
                      ) ?? bookingTabModels?.[0];
                    await actions.persistAndOpenCreateMessage(bookingTabModel);
                  }}
                >
                  Envoyer un MESSAGE
                </AppButton>
              )}
              {bookingOptions.length > 1 && (
                <AppFormControlV2
                  label="Réservation"
                  renderComponent={() => (
                    <AppSingleSelect2HeadlessUI
                      className="min-w-48"
                      theme={'material-ui'}
                      options={bookingOptions}
                      value={selectedBookingId}
                      onChange={(value) => setSelectedBookingId(value)}
                    />
                  )}
                />
              )}
            </div>
          ) : (
            <div className="flex flex-col gap-4">
              <AppMessageSingleLine>
                Pour envoyer un message, créez d'abord une réservation
              </AppMessageSingleLine>
              <AppButton
                icon={AppHeroIcons.actionAdd}
                color="primary-bg"
                onClick={() =>
                  actions.createNewBooking({ onSuccess: 'openBooking' })
                }
              >
                <span>Créer une réservation</span>
              </AppButton>
            </div>
          )}
        </>
      )}
    </div>
  );
};
