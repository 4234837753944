/* eslint-disable @typescript-eslint/no-unused-vars */
import clsx from 'clsx';
import React, { useCallback } from 'react';
import { useMediaSizeTailwind } from 'src/business/_core/modules/layout';
import { AppFormControlV2, AppToogleButtonGroup } from 'src/lib/form';
import { AppSingleSelect2HeadlessUI } from 'src/lib/form/components/AppSingleSelect/AppSingleSelect2HeadlessUI';
import { AppHeroIcons } from '../../../../../_core/modules/layout/icons';
import { ValueLabel } from '../../../_common/form';
import {
  PlanningStaffEditMode,
  PlanningViewConfig,
} from '../../../club-planning/_model';
import { SetPlanningConfigOptions } from '../../hooks';

const options: ValueLabel<PlanningStaffEditMode, string>[] = [
  {
    value: 'presence',
    label: 'PRÉSENCE',
    icon: AppHeroIcons.displayModeIcon,
  },
  {
    value: 'times-roles',
    label: 'HORAIRES',
    icon: AppHeroIcons.displayModeIcon,
  },
];

export const ClubPlanningStaffEditModeToggleButton = ({
  planningConfig,
  setPlanningConfig,
  className,
}: {
  planningConfig: PlanningViewConfig;
  setPlanningConfig: (
    config: PlanningViewConfig,
    options: SetPlanningConfigOptions,
  ) => void;
  className?: string;
}) => {
  const mediaSize = useMediaSizeTailwind();

  const selected =
    planningConfig?.displayModeStaffPresenceOptions?.editMode ?? 'presence';

  const onChange = useCallback(
    (value: PlanningStaffEditMode) => {
      setPlanningConfig(
        {
          ...planningConfig,
          displayModeStaffPresenceOptions: {
            ...(planningConfig.displayModeStaffPresenceOptions ?? {}),
            editMode: value,
          },
        },
        {
          origin: '[ClubPlanningStaffEditModeToggleButton] onChange (week)',
        },
      );
    },
    [planningConfig, setPlanningConfig],
  );

  return options.length === 0 ? null : (
    <AppFormControlV2
      label={'Mode d\'édition'}
      helpDescription={
        selected === 'presence'
          ? 'Passez en mode "Horaires" pour affecter rapidement les moniteurs aux sessions'
          : 'Passez en mode "Présence" pour indiquer les jours de disponibilités'
      }
      renderComponent={() => (
        <div className="mt-1">
          <AppSingleSelect2HeadlessUI
            className={clsx('w-32 sm:hidden', className)}
            color={'green'}
            theme="tailwind"
            disableClearButton={true}
            showIcons={false}
            options={options}
            value={selected}
            onChange={onChange}
          />
          <AppToogleButtonGroup
            className={clsx('hidden sm:flex', className)}
            color={'green'}
            theme="strong"
            items={options}
            selected={selected}
            onChanges={onChange}
          />
        </div>
      )}
    />
  );
};
