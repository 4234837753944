/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { useWatch } from 'react-hook-form';
import { useClubResume } from 'src/business/club/data/hooks';
import { AppInputBooleanRHF, AppInputRHF } from 'src/lib/form';
import { AppFormControlRHF } from 'src/lib/form/components/AppFormControl/AppFormControlRHF';
import { ClubSettingsCustomerFormFieldsEditPageLocalState } from './useClubSettingsCustomerFormFieldsEditPageLocalState.hook';

export const ClubSettingsCustomerFormFieldsEditForm_CustomerContact_CustomFields =
  ({
    localState,
  }: {
    localState: ClubSettingsCustomerFormFieldsEditPageLocalState;
  }) => {
    const { form } = localState;
    const { control, setValue } = form;

    const clubResume = useClubResume();
    const generalSettings = clubResume.clubSettings?.general;

    const [generalCustomField1, generalCustomField2, generalCustomField3] =
      useWatch({
        control,
        name: [
          'clubSettings.customer.general.customField1',
          'clubSettings.customer.general.customField2',
          'clubSettings.customer.general.customField3',
        ],
      });

    return (
      <>
        <div className="flex gap-4 flex-wrap">
          <AppFormControlRHF
            control={control}
            name="clubSettings.customer.general.customField1"
            renderComponent={(props) => (
              <AppInputBooleanRHF
                {...props}
                type="checkbox"
                label={'Champ personnalisé N°1'}
              />
            )}
          />
          {generalCustomField1 && (
            <AppFormControlRHF
              className="max-x-40"
              control={control}
              name="clubSettings.customer.general.customField1Label"
              label="Nom du champ"
              required={true}
              renderComponent={(props) => <AppInputRHF {...props} />}
            />
          )}
        </div>
        {(generalCustomField1 || generalCustomField2) && (
          <div className="flex gap-4 flex-wrap">
            <AppFormControlRHF
              control={control}
              name="clubSettings.customer.general.customField2"
              renderComponent={(props) => (
                <AppInputBooleanRHF
                  {...props}
                  type="checkbox"
                  label={'Champ personnalisé N°2'}
                />
              )}
            />
            {generalCustomField2 && (
              <AppFormControlRHF
                className="max-x-40"
                control={control}
                name="clubSettings.customer.general.customField2Label"
                label="Nom du champ"
                required={true}
                renderComponent={(props) => <AppInputRHF {...props} />}
              />
            )}
          </div>
        )}
        {((generalCustomField1 && generalCustomField2) ||
          generalCustomField3) && (
          <div className="flex gap-4 flex-wrap">
            <AppFormControlRHF
              control={control}
              name="clubSettings.customer.general.customField3"
              renderComponent={(props) => (
                <AppInputBooleanRHF
                  {...props}
                  type="checkbox"
                  label={'Champ personnalisé N°3'}
                />
              )}
            />
            {generalCustomField3 && (
              <AppFormControlRHF
                className="max-x-40"
                control={control}
                name="clubSettings.customer.general.customField3Label"
                label="Nom du champ"
                required={true}
                renderComponent={(props) => <AppInputRHF {...props} />}
              />
            )}
          </div>
        )}
      </>
    );
  };
