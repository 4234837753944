/* eslint-disable @typescript-eslint/no-unused-vars */
import { RegionalSettings } from '@mabadive/app-common-model';
import { diveModeFormatter } from '@mabadive/app-common-services';
import React from 'react';
import {
  useClubResume,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import { AppFeatureBadge } from 'src/business/club/modules/_common/ui/AppFeatureBadge';
import { ClubSettingsSection } from 'src/pages/SE-settings/_core';
import {
  useAppCurrencyMain,
  useRegionalSettings,
} from 'src/pages/_components/options';
import {
  ClubDialogsState,
  UseClubDialogsProps,
  useClubDialogs,
  useClubDialogsActionsPersist,
} from 'src/pages/_dialogs';
import { ClubSettingsServicesActivitiesViewPanelDiveMode } from './ClubSettingsServicesActivitiesViewPanelDiveMode';

export const ClubSettingsServicesActivitiesViewPanelTraining = () => {
  const diveCenterResume = useDiveCenterResume();
  const clubResume = useClubResume();

  const clubSettings = clubResume?.clubSettings;

  const actionsPersist: UseClubDialogsProps = useClubDialogsActionsPersist();
  const dialogsState: ClubDialogsState = useClubDialogs(actionsPersist);

  const regionalSettings: RegionalSettings = useRegionalSettings({
    effectiveDate: new Date(),
  });

  const mainCurrency = useAppCurrencyMain();

  return (
    <ClubSettingsSection title={'Formation'}>
      <div className="flex flex-col lg:flex-row items-start gap-x-8 gap-y-2">
        <div className="relative grid gap-x-4 gap-y-2 font-bold text-gray-600">
          <AppFeatureBadge
            enabled={clubSettings.training.enabled}
            label={diveModeFormatter.formatDiveMode('training', {
              format: 'short-ref-label',
              diveModesConf: clubSettings?.ui?.diveMode,
            })}
            icon={
              <ClubSettingsServicesActivitiesViewPanelDiveMode diveMode="training" />
            }
          />
          <AppFeatureBadge
            enabled={clubSettings.theoreticalTraining.enabled}
            label={diveModeFormatter.formatDiveMode('theoretical-training', {
              format: 'short-ref-label',
              diveModesConf: clubSettings?.ui?.diveMode,
            })}
            icon={
              <ClubSettingsServicesActivitiesViewPanelDiveMode diveMode="theoretical-training" />
            }
          />
        </div>
      </div>
    </ClubSettingsSection>
  );
};
