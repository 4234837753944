/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ClubSettings,
  ClubSettings_ConfigAssistant_Progress,
  ClubSettings_ConfigAssistant_ProgressStepKey,
  ClubSettings_ConfigAssistant_ProgresSteps,
} from '@mabadive/app-common-model';
import { jsonPatcher } from '@mabadive/app-common-services';
import { useCallback, useState } from 'react';
import { proMultiMutationsApiClient } from 'src/business/_core/data/app-operation';
import { useClubResume } from 'src/business/club/data/hooks';
import { ConfigAssistantProgressStep } from './model';
import { useConfigAssistantProgressSteps } from './useConfigAssistantProgressSteps.hook';

export function useClubSettings_ConfigAssistantPageLocalState() {
  const clubResume = useClubResume();

  const [displayExpertMode, setDisplayExpertMode] = useState(false);

  const progressSteps: ConfigAssistantProgressStep[] =
    useConfigAssistantProgressSteps({
      showDisabledSteps: displayExpertMode,
    });

  const [operationInProgress, setOperationInProgress] =
    useState<boolean>(false);

  const updateSettingsConfigAssistant = useCallback(
    async (
      progressKey: ClubSettings_ConfigAssistant_ProgressStepKey,
      progress: ClubSettings_ConfigAssistant_Progress,
    ) => {
      const initialValue: ClubSettings = clubResume.clubSettings;
      const finalSteps: ClubSettings_ConfigAssistant_ProgresSteps = {
        ...(initialValue.configAssistant?.steps ?? ({} as any)),
        [progressKey]: progress,
      };
      const finalValue: ClubSettings = {
        ...initialValue,
        configAssistant: {
          ...(initialValue.configAssistant ?? ({} as any)),
          steps: finalSteps,
        },
      };

      const patchOperations = jsonPatcher.compareObjects(
        initialValue,
        finalValue,
        {
          attributesToReplaceFully: ['configAssistant'],
        },
      );
      if (patchOperations.length) {
        const updatedClubSettings = {
          pk: initialValue._id,
          patchOperations,
        };
        try {
          setOperationInProgress(true);
          await proMultiMutationsApiClient.update({
            updatedClubSettings,
          });
        } finally {
          setOperationInProgress(null);
        }
      }
    },
    [clubResume.clubSettings],
  );

  return {
    state: {
      progressSteps,
      operationInProgress,
      displayExpertMode,
      setDisplayExpertMode,
    },
    actions: {
      updateSettingsConfigAssistant,
    },
  };
}

export type ClubSettings_ConfigAssistantPageLocalState = ReturnType<
  typeof useClubSettings_ConfigAssistantPageLocalState
>;
