/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  clubPurchasePackagePaymentStatusFormatter,
  dateService,
  diveServicePurchaseStatusFormatter,
} from '@mabadive/app-common-services';
import clsx from 'clsx';
import React from 'react';
import { AgencyLabel } from '../../../../business/club/modules/club-diver-participant/pages/DiverBookingPage/components/BookingLabelsComponents';
import { PURCHASE_TYPE_OPTIONS } from '../useClubPurchasesListPageFilters.hook';
import { ClubPurchasesListPageLocalState } from '../useClubPurchasesListPageLocalState.hook';

export const ClubPurchasesListPageConfigBarFiltersResume = ({
  localState,
  className,
}: {
  localState: ClubPurchasesListPageLocalState;
  className?: string;
}) => {
  const { state, actions } = localState;
  const { viewParameters } = state;

  return state.hasAnyExtraViewParameter ? (
    <div
      className={clsx(
        'flex justify-start flex-wrap gap-x-4 text-xs text-gray-400 uppercase',
        className,
      )}
    >
      {viewParameters.searchTextContactName?.trim()?.length !== 0 && (
        <div className={clsx('flex xs:hidden gap-1')}>
          <div className="font-bold whitespace-nowrap">Nom:</div>
          <div className="whitespace-nowrap">
            {viewParameters.searchTextContactName?.trim()}
          </div>
        </div>
      )}
      {viewParameters.searchTextOfferName?.trim()?.length !== 0 && (
        <div className={clsx('flex md:hidden gap-1')}>
          <div className="font-bold whitespace-nowrap">Prestation:</div>
          <div className="whitespace-nowrap">
            {viewParameters.searchTextOfferName?.trim()}
          </div>
        </div>
      )}
      {viewParameters.validityStatus && (
        <div className={clsx('flex gap-1')}>
          <div className="font-bold whitespace-nowrap">Validité:</div>
          {diveServicePurchaseStatusFormatter
            .formatStatus(viewParameters.validityStatus)
            .toUpperCase()}
        </div>
      )}
      {viewParameters.paymentStatus && (
        <div className={clsx('flex gap-1')}>
          <div className="font-bold whitespace-nowrap">Paiement:</div>
          {viewParameters.paymentStatus === 'pending'
            ? clubPurchasePackagePaymentStatusFormatter
                .formatStatus('done', true)
                .toUpperCase()
            : clubPurchasePackagePaymentStatusFormatter
                .formatStatus(viewParameters.paymentStatus, undefined)
                .toUpperCase()}
        </div>
      )}{' '}
      {viewParameters.purchaseType && (
        <div className={clsx('flex gap-1')}>
          <div className="font-bold whitespace-nowrap">
            Forfait / formation:
          </div>
          {PURCHASE_TYPE_OPTIONS.find(
            (x) => x.value === viewParameters.purchaseType,
          )?.label.toUpperCase()}
        </div>
      )}
      {viewParameters.bookingAgencyId && (
        <div className={clsx('flex gap-1')}>
          <div className="font-bold whitespace-nowrap">Agence:</div>
          <AgencyLabel agencyId={viewParameters.bookingAgencyId} />
        </div>
      )}
      {viewParameters.beginPurchaseDate && (
        <div className={clsx('flex gap-1')}>
          <div className="font-bold whitespace-nowrap">Début:</div>
          {dateService.formatUTC(
            viewParameters.beginPurchaseDate,
            'DD/MM/YYYY',
          )}
        </div>
      )}
      {viewParameters.endPurchaseDate && (
        <div className={clsx('flex gap-1')}>
          <div className="font-bold whitespace-nowrap">Fin:</div>
          {dateService.formatUTC(viewParameters.endPurchaseDate, 'DD/MM/YYYY')}
        </div>
      )}
    </div>
  ) : null;
};
