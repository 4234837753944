/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  CompanyInquiryWithResponse,
  DiveMode,
} from '@mabadive/app-common-model';
import { dateService, diveModeFormatter } from '@mabadive/app-common-services';
import React, { useMemo } from 'react';
import { FieldArrayWithId } from 'react-hook-form';
import { useClubResume, useClubSettings } from 'src/business/club/data/hooks';
import { AppInputBooleanSwitchRHF } from '../../../../../../../../../../lib/form';
import { DiveCenterBookingMemberNameLabel } from '../../../../../_from-diver-app';
import { BookingCustomerConfigEditFormModel } from '../../_model';
import { BookingCustomerConfigEditDialogLocalState } from '../../useBookingCustomerConfigEditDialogLocalState.hook';
import { proInquiriesByMemberBuilder } from './proInquiriesByMemberBuilder';

export const BookingCustomerConfigEditForm_Inquiries_TableRow = ({
  localState,
  bookingMembersConfigsField,
  bookingMembersConfigsFieldIndex,
}: {
  localState: BookingCustomerConfigEditDialogLocalState;
  bookingMembersConfigsField: FieldArrayWithId<
    BookingCustomerConfigEditFormModel,
    'bookingMembersConfigs',
    'id'
  >;
  bookingMembersConfigsFieldIndex: number;
}) => {
  const { state, data, actions } = localState;
  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    state.form;

  const clubResume = useClubResume();
  const customerSettings = clubResume?.clubSettings?.customer;
  const clubReference = clubResume.reference;
  const bookingMemberFull = bookingMembersConfigsField.bookingMemberFull;
  const diver = bookingMemberFull.diver;

  const memberBookingParticipantsFull = useMemo(
    () =>
      data?.bookingParticipantsFull.filter(
        (bpf) => bpf.diver?._id === diver?._id,
      ),
    [data?.bookingParticipantsFull, diver?._id],
  );
  const nextDiveModes = useMemo(() => {
    // only next
    const nextMemberBookingParticipantsFull =
      memberBookingParticipantsFull?.filter(
        (bpf) =>
          dateService.isTodayUTC(bpf?.diveSession?.time) ||
          dateService.isFutureDayUTC(bpf?.diveSession?.time),
      );
    const diveModes = nextMemberBookingParticipantsFull?.reduce((acc, bpf) => {
      if (!acc.includes(bpf?.diveSessionParticipant?.diveMode)) {
        acc.push(bpf?.diveSessionParticipant?.diveMode);
      }
      return acc;
    }, [] as DiveMode[]);
    const nextDives = [...new Set(diveModes)];
    return nextDives;
  }, [memberBookingParticipantsFull]);

  const clubSettings = useClubSettings();

  // FIXME: ça ne marche pas, car memberBookingParticipantsFull ne contient pas les réponses
  const enabledInquiriesWithResponse: CompanyInquiryWithResponse[] =
    useMemo(() => {
      // traitement similaire côté plongeur dans cuAppInquiriesByMemberBuilder
      return proInquiriesByMemberBuilder.buildCustomerInquiryWithResponse({
        memberBookingParticipantsFull,
        activeInquiries: data.enabledInquiries,
      });
    }, [memberBookingParticipantsFull, data.enabledInquiries]);

  return (
    <tr>
      <th className="py-1 px-2 text-left text-sm">
        <DiveCenterBookingMemberNameLabel
          bookingMemberFull={bookingMemberFull}
          isUniqueMember={data.bookingMembersFull?.length === 1}
        />
      </th>
      {/* <th className="px-2 text-left">{nameFormatter.formatFullName(diver)}</th> */}
      <td className="px-2">
        <div>
          {nextDiveModes?.length === 0 && (
            <div className="uppercase text-gray-500">aucune</div>
          )}
          {nextDiveModes.map((diveMode) => (
            <div
              className="uppercase text-sm font-medium text-app-primary-dark"
              key={diveMode}
            >
              {diveModeFormatter.formatDiveMode(diveMode, {
                format: 'short-label',
                diveModesConf: clubSettings?.ui?.diveMode,
              })}
            </div>
          ))}
        </div>
      </td>
      {/* // TODO activation globale (masquer et synchroniser auto si 1 seul sondage) */}
      {enabledInquiriesWithResponse.map((x, inquiryIndex) => {
        return (
          <td className="px-2" key={x.inquiry._id}>
            <AppInputBooleanSwitchRHF
              control={control}
              name={`bookingMembersConfigs.${bookingMembersConfigsFieldIndex}.config.inquiry.inquiries.${inquiryIndex}.enabled`}
            />
          </td>
        );
      })}
    </tr>
  );
};
