import clsx from 'clsx';
import React from 'react';
import { useAppBookingViewAuth } from 'src/business/auth/services';
import { BookingTabModel } from '../../models';
import { DiverBookingPageActions } from '../../useDiverBookingPageActions.hook';
import { BookingMassiveEditorSessionsTableRegisterAddMemberButton } from '../BookingMassiveEditorDialog/BookingMassiveEditorSessionsTable/BookingMassiveEditorSessionsTableRegisterAddMemberButton';
import { BookingMassiveEditorSessionsTableRegisterAddSessionButton } from '../BookingMassiveEditorDialog/BookingMassiveEditorSessionsTable/BookingMassiveEditorSessionsTableRegisterAddSessionButton';

export function DiverBookingCard_AddMemberOrSession_Buttons({
  actions,
  bookingTabModel,
  onClickAddSession,
  className,
}: {
  actions: DiverBookingPageActions;
  bookingTabModel: BookingTabModel;
  onClickAddSession?: () => void;

  className?: string;
}) {
  const bookingViewAuth = useAppBookingViewAuth({
    bookingDiveCenterId: bookingTabModel.bookingDiveCenterId,
    isSharedBooking: bookingTabModel.isSharedBooking,
  });

  return bookingViewAuth.edit ? (
    <div
      className={clsx(
        'grid xs:grid-cols-2 md:flex md:flex-row items-start gap-4 md:gap-8 text-sm',
        className,
      )}
    >
      <>
        {/* <h3 className="font-bold">
                Sélectionner une ou plusieurs sessions
              </h3> */}
        <div className=" md:min-w-64 flex flex-col gap-2">
          <div className="uppercase text-gray-500">Ajouter une plongée:</div>
          <BookingMassiveEditorSessionsTableRegisterAddSessionButton
            className="w-full max-w-[20rem]"
            isReverseTheme={true}
            onClickAddSession={() => {
              onClickAddSession && onClickAddSession();
              actions.openSessionSelector({
                bookingId: bookingTabModel.aggregatedBooking.booking._id,
              });
            }}
          />
        </div>
        <div className=" md:min-w-64 flex flex-col gap-2">
          <div className="uppercase text-gray-500">Ajouter un participant:</div>
          <BookingMassiveEditorSessionsTableRegisterAddMemberButton
            className="flex-grow max-w-[20rem]"
            isReverseTheme={true}
            onClickAddMember={() =>
              actions.openDiverSelector({
                bookingId: bookingTabModel.bookingId,
              })
            }
          />
        </div>
      </>
    </div>
  ) : null;
}
