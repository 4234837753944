import React, { useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { EcommerceProductArticleEditorLocalState } from '../useEcommerceProductArticleEditorPanelLocalState.hook';
import { EcommerceProductArticleEditorFormContent } from './EcommerceProductArticleEditorFormContent';

export const EcommerceProductArticleEditorFormContentWithPreview = ({
  localState,
}: {
  localState: EcommerceProductArticleEditorLocalState;
}) => {
  const { state, actions } = localState;

  const [appBookletPage, productName] = useWatch({
    control: state.form.control,
    name: ['appBookletPage', 'productArticle.name'],
  });
  const content = appBookletPage?.mainContent?.content;
  const productArticle = useMemo(
    () => ({
      name: productName,
    }),
    [productName],
  );
  return (
    <div className={'grid grid-cols-1 gap-4'}>
      <div className="flex gap-8 items-start">
        <EcommerceProductArticleEditorFormContent
          className="w-full"
          localState={localState}
        />
        {/* <AppPhonePanel className="hidden md:flex" label={'APERÇU'}>
          <div className="h-[600px] max-h-[90lvh bg-gray-100">
            <div className="m-4 app-card rounded-t-xl flex flex-col gap-4">
              <EcommerceProductArticlePreviewContent
                displayWidth={'mobile'}
                productArticle={productArticle}
                appBookletPage={appBookletPage}
              >
                <div className="max-w-sm">
                  {(content?.items ?? []).map((item, i) => (
                    <AppTranslationContentPanel key={i} item={item} />
                  ))}
                </div>
              </EcommerceProductArticlePreviewContent>
            </div>
          </div>
        </AppPhonePanel> */}
      </div>
    </div>
  );
};
