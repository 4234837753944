/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { useWatch } from 'react-hook-form';
import { useClubResume } from 'src/business/club/data/hooks';
import { AppInputBooleanRHF, AppInputRHF } from 'src/lib/form';
import { AppFormControlRHF } from 'src/lib/form/components/AppFormControl/AppFormControlRHF';
import { ClubSettingsCustomerFormFieldsEditPageLocalState } from './useClubSettingsCustomerFormFieldsEditPageLocalState.hook';

export const ClubSettingsCustomerFormFieldsEditForm_Booking_CustomFields = ({
  localState,
}: {
  localState: ClubSettingsCustomerFormFieldsEditPageLocalState;
}) => {
  const { form } = localState;
  const { control, setValue } = form;

  const clubResume = useClubResume();

  const [bookingCustomField1, bookingCustomField2, bookingCustomField3] =
    useWatch({
      control,
      name: [
        'clubSettings.customer.booking.customField1',
        'clubSettings.customer.booking.customField2',
        'clubSettings.customer.booking.customField3',
      ],
    });

  return (
    <>
      <div className="flex gap-4 flex-wrap">
        <AppFormControlRHF
          control={control}
          name="clubSettings.customer.booking.customField1"
          renderComponent={(props) => (
            <AppInputBooleanRHF
              {...props}
              type="checkbox"
              description={'Champ personnalisé N°1'}
            />
          )}
        />
        {bookingCustomField1 && (
          <AppFormControlRHF
            className="max-x-40"
            control={control}
            name="clubSettings.customer.booking.customField1Label"
            label="Nom du champ"
            required={true}
            renderComponent={(props) => <AppInputRHF {...props} />}
          />
        )}
      </div>
      {(bookingCustomField1 || bookingCustomField2) && (
        <div className="flex gap-4 flex-wrap">
          <AppFormControlRHF
            control={control}
            name="clubSettings.customer.booking.customField2"
            renderComponent={(props) => (
              <AppInputBooleanRHF
                {...props}
                type="checkbox"
                description={'Champ personnalisé N°2'}
              />
            )}
          />
          {bookingCustomField2 && (
            <AppFormControlRHF
              className="max-x-40"
              control={control}
              name="clubSettings.customer.booking.customField2Label"
              label="Nom du champ"
              required={true}
              renderComponent={(props) => <AppInputRHF {...props} />}
            />
          )}
        </div>
      )}
      {((bookingCustomField1 && bookingCustomField2) ||
        bookingCustomField3) && (
        <div className="flex gap-4 flex-wrap">
          <AppFormControlRHF
            control={control}
            name="clubSettings.customer.booking.customField3"
            renderComponent={(props) => (
              <AppInputBooleanRHF
                {...props}
                type="checkbox"
                description={'Champ personnalisé N°3'}
              />
            )}
          />
          {bookingCustomField3 && (
            <AppFormControlRHF
              className="max-x-40"
              control={control}
              name="clubSettings.customer.booking.customField3Label"
              label="Nom du champ"
              required={true}
              renderComponent={(props) => <AppInputRHF {...props} />}
            />
          )}
        </div>
      )}
    </>
  );
};
