/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ClubResumeStaffMember,
  ClubSettingsUIPlanning,
  DiveSession,
  DiveSessionResumeGroup,
  DiveSessionResumeParticipant,
  MultipleDiveSessionNumber,
} from '@mabadive/app-common-model';
import { dateService } from '@mabadive/app-common-services';
import clsx from 'clsx';
import React, { Fragment, useMemo, useState } from 'react';
import { AppTransition } from 'src/business/_core/modules/layout';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import {
  useClubResume,
  useDiveCenterResume,
  useDiveModeColor,
} from 'src/business/club/data/hooks';
import { useParticipantsLevels } from '../../../ClubPlanningLightPanelViewPeriodMonth/useParticipantsLevels.hook';
import {
  CalendarParticipantCell,
  CalendarSessionWidthMode,
} from '../session-common';
import { CalendarStaffInstructorCell } from '../session-common/CalendarParticipantCell/CalendarStaffInstructorCell';
import { DiveSessionSimpleCardParticipantsGroupDetails } from './DiveSessionSimpleCardParticipantsGroupDetails';
import { DiveSessionSimpleCardParticipantsGroupNoParticipant } from './DiveSessionSimpleCardParticipantsGroupNoParticipant';
import { DiveSessionSimpleCardParticipantsGroupPanelParticipantsResume } from './DiveSessionSimpleCardParticipantsGroupPanelParticipantsResume';
import { useSessionInstructorStaffMembers } from './useSessionInstructorStaffMembers.hook';

export const DiveSessionSimpleCardParticipantsGroupPanel = ({
  settingsUIPlanning,
  diveSession,
  isPast,
  group,
  groupOdd,
  participants,
  onClickParticipant,
  onClickGroupDetails,
  anchorLinkParticipant,
  widthMode,
  showGroupParticipantsResume,
  isExpandable,
  className,
}: {
  settingsUIPlanning: ClubSettingsUIPlanning;
  diveSession: Pick<
    DiveSession,
    'reference' | 'time' | 'status' | 'diveTourSession2'
  >;
  isPast: boolean;
  group?: DiveSessionResumeGroup; // optionnal (empty if participant not grouped)
  groupOdd: boolean;
  participants: DiveSessionResumeParticipant[];
  onClickParticipant: (
    participant: DiveSessionResumeParticipant,
    diveSession: Pick<
      DiveSession,
      'reference' | 'time' | 'status' | 'diveTourSession2'
    >,
  ) => void;
  onClickGroupDetails: (args: {
    group?: DiveSessionResumeGroup;
    diveSession: Pick<
      DiveSession,
      'reference' | 'time' | 'status' | 'diveTourSession2'
    >;
    participants: DiveSessionResumeParticipant[];
  }) => void;
  anchorLinkParticipant: (participant: DiveSessionResumeParticipant) => string;
  widthMode: CalendarSessionWidthMode;
  showGroupParticipantsResume?: boolean;
  isExpandable?: boolean;
  className?: string;
}) => {
  const clubResume = useClubResume();

  const diveCenterResume = useDiveCenterResume();
  const diveCenterId = diveCenterResume?._id;
  const clubReference = diveCenterResume?.clubReference;

  const diveSessionReference = diveSession.reference;

  // moniteurs additionnels (stagiaires, plongeurs)
  const divingInstructorsStaffMembers: ClubResumeStaffMember[] = useMemo(() => {
    const staffIds = (
      group?.diveTourGroupSession1?.divingInstructors ?? []
    )?.map((x) => x.staffId);
    return diveCenterResume?.staffMembers.filter((p) =>
      staffIds.includes(p._id),
    );
  }, [
    group?.diveTourGroupSession1?.divingInstructors,
    diveCenterResume?.staffMembers,
  ]);

  const diveModeColor = useDiveModeColor(group?.diveMode) ?? '#bbb';

  const includePayment = useMemo(() => {
    if (settingsUIPlanning.showPaymentFlagPastOnly) {
      const time = diveSession.time;
      const isFutureDay = dateService.isFutureDayUTC(time);
      return !isFutureDay;
    }
    return true;
  }, [settingsUIPlanning.showPaymentFlagPastOnly, diveSession.time]);

  const localSettingsUIPlanning: ClubSettingsUIPlanning = useMemo(() => {
    if (widthMode === 'large') {
      const showPayment = settingsUIPlanning.showPaymentFlag;
      return {
        ...settingsUIPlanning,
        showStaff: true,
        showPaymentFlagPastOnly: false,
        showPaymentFlagNotPurchased: showPayment,
        showPaymentFlagNotPurchasedPass: showPayment,
        showPaymentFlagTraining: settingsUIPlanning.showPaymentFlagTraining,
        showPaymentFlagPayed: showPayment,
        showPaymentFlagNotPayed: showPayment,
      };
    }
    return settingsUIPlanning;
  }, [settingsUIPlanning, widthMode]);

  const sessionsCount = diveSession.diveTourSession2 ? 2 : 1;

  const showDetailsSession2 = widthMode === 'large' && sessionsCount > 1;

  const isMultiSessionConfigForParticipants = !!diveSession.diveTourSession2;

  const { isGroupVisibleInSession1, isGroupVisibleInSession2 } = useMemo(() => {
    const isAnyParticipantSession1 = // !!instructorStaffMember ||
      participants.find((participantResume) =>
        isParticipantInSession({
          isMultiSessionConfigForParticipants,
          participantResume,
          sessionNumber: 1,
        }),
      ) !== undefined;
    const isAnyParticipantSession2 =
      participants.find((participantResume) =>
        isParticipantInSession({
          isMultiSessionConfigForParticipants,
          participantResume,
          sessionNumber: 2,
        }),
      ) !== undefined;
    return {
      isGroupVisibleInSession1:
        isAnyParticipantSession1 || !isAnyParticipantSession2,
      isGroupVisibleInSession2:
        isAnyParticipantSession2 || !isAnyParticipantSession1,
    };
  }, [isMultiSessionConfigForParticipants, participants]);

  const showAllParticipants = settingsUIPlanning.showParticipants !== false;

  // si 1 seul participant, on n'affiche pas le résumé x1, mais directement le nom du participant!
  showGroupParticipantsResume =
    showGroupParticipantsResume && participants.length > 1;

  const [isExpanded, setIsExpanded] = useState(!showGroupParticipantsResume);
  const { levelsMap, commonLevel } = useParticipantsLevels({
    diveMode: group?.diveMode,
    participants,
  });

  const rootStyle = useMemo(() => {
    const style: React.CSSProperties = {};
    if (diveModeColor) {
      style.borderColor = diveModeColor;
    }
    if (!group?.diveMode && widthMode !== 'large') {
      style.borderLeftStyle = 'dotted';
    }
    return style;
  }, [diveModeColor, group?.diveMode, widthMode]);

  const {
    mainInstructorStaffMember,
  }: {
    mainInstructorStaffMember: ClubResumeStaffMember;
    otherInstructorsStaffMembers: ClubResumeStaffMember[];
  } = useSessionInstructorStaffMembers({
    sessionNumber: 1,
    diveSession,
    group,
    diveCenterResume,
    diveTourGroup: group?.diveTourGroupSession1,
  });

  const staffFirstNameBefore = localSettingsUIPlanning.staffFirstNameBefore;
  return (
    <div
      className={clsx(
        'overflow-hidden bg-white mx-0 pl-px ',
        `group-odd-${groupOdd} group-size-${participants.length}`,
        widthMode === 'large' ? 'border-2 border-l-8' : 'border-l-4',
        !group?.diveMode && 'border-l-dotted',
        className,
      )}
      style={rootStyle}
    >
      {showGroupParticipantsResume && (
        <div
          className={clsx(
            'my-0.5 w-full',
            isExpandable && 'cursor-pointer',
            widthMode === 'tiny' || widthMode === 'small'
              ? 'border-b border-l border-r'
              : 'border-b border-t border-r ',
            isExpandable &&
              'hover:bg-gray-50 hover:border-app-orange3 hover:text-gray-800',
          )}
          style={diveModeColor ? { borderColor: diveModeColor } : undefined}
          onClick={() => isExpandable && setIsExpanded(!isExpanded)}
        >
          <div className="flex gap-0.5 flex-row font-bold justify-center items-center lg:text-lg">
            {/* {isExpandable && (
            <div>
              {isExpanded ? (
                <AppHeroIcons.actionReduce className="hidden sm:flex h-2 w-2 sm:h-2.5 sm:w-2.5 md:h-2.5 md:w-2.5 lg:h-5 lg:w-5 text-gray-400 group-hover:text-gray-600" />
              ) : (
                <AppHeroIcons.actionExtend className="hidden sm:flex h-2 w-2 sm:h-2.5 sm:w-2.5 md:h-2.5 md:w-2.5 lg:h-5 lg:w-5 text-gray-400 group-hover:text-gray-600" />
              )}
            </div>
          )} */}
            <div
              className={clsx('flex-grow text-center')}
              style={diveModeColor ? { color: diveModeColor } : undefined}
            >
              <DiveSessionSimpleCardParticipantsGroupPanelParticipantsResume
                group={group}
                participants={participants}
                label={commonLevel}
                diveModeColor={diveModeColor}
                widthMode={widthMode}
                hoverable={isExpandable}
                isPast={isPast}
              />
            </div>
            {isExpandable && (
              <div>
                {isExpanded ? (
                  <AppHeroIcons.actionReduce className="hidden sm:flex h-2 w-2 sm:h-2.5 sm:w-2.5 md:h-2.5 md:w-2.5 lg:h-5 lg:w-5 text-gray-400 group-hover:text-gray-600" />
                ) : (
                  <AppHeroIcons.actionExtend className="hidden sm:flex h-2 w-2 sm:h-2.5 sm:w-2.5 md:h-2.5 md:w-2.5 lg:h-5 lg:w-5 text-gray-400 group-hover:text-gray-600" />
                )}
              </div>
            )}
          </div>
          {localSettingsUIPlanning.showStaff &&
            mainInstructorStaffMember &&
            widthMode !== 'large' && (
              <CalendarStaffInstructorCell
                className={'w-full mt-px'}
                staffFirstNameBefore={staffFirstNameBefore}
                staffMember={mainInstructorStaffMember}
                diveSession={diveSession}
                isPast={isPast}
                widthMode={widthMode}
              />
            )}
        </div>
      )}

      <AppTransition type="scale" show={isExpanded} className={''}>
        <div className={''}>
          <div
            className={clsx(
              'w-full flex gap-4',
              onClickGroupDetails && 'cursor-pointer',
            )}
            onClick={() =>
              onClickGroupDetails &&
              onClickGroupDetails({
                group,
                diveSession,
                participants,
              })
            }
          >
            {widthMode === 'large' ? (
              <div className="w-full flex gap-4">
                <div
                  className={clsx(
                    showDetailsSession2 ? 'w-1/2' : 'w-full',
                    showDetailsSession2 &&
                      !isGroupVisibleInSession1 &&
                      'invisible',
                  )}
                >
                  <DiveSessionSimpleCardParticipantsGroupDetails
                    group={group}
                    participants={participants}
                    sessionNumber={1}
                    staffFirstNameBefore={staffFirstNameBefore}
                    diveSession={diveSession}
                    hideMainInstructorStaffMember={false}
                  />
                </div>
                {showDetailsSession2 && (
                  <div
                    className={clsx(
                      'w-1/2',
                      !isGroupVisibleInSession2 && 'invisible',
                    )}
                  >
                    <DiveSessionSimpleCardParticipantsGroupDetails
                      group={group}
                      participants={participants}
                      sessionNumber={2}
                      staffFirstNameBefore={staffFirstNameBefore}
                      diveSession={diveSession}
                      hideMainInstructorStaffMember={false}
                    />
                  </div>
                )}
              </div>
            ) : (
              <div className={'w-full'}>
                {localSettingsUIPlanning.showStaff && (
                  <>
                    {mainInstructorStaffMember &&
                      !showGroupParticipantsResume && (
                        <CalendarStaffInstructorCell
                          className={'w-full mt-px'}
                          staffFirstNameBefore={staffFirstNameBefore}
                          staffMember={mainInstructorStaffMember}
                          diveSession={diveSession}
                          isPast={isPast}
                          widthMode={widthMode}
                        />
                      )}
                    {divingInstructorsStaffMembers.map(
                      (instructorStaffMember, i) => (
                        <CalendarStaffInstructorCell
                          key={`instructor-${i}-${instructorStaffMember._id}`}
                          className={'w-full mt-px'}
                          staffFirstNameBefore={staffFirstNameBefore}
                          staffMember={instructorStaffMember}
                          diveSession={diveSession}
                          isPast={isPast}
                          widthMode={widthMode}
                        />
                      ),
                    )}
                  </>
                )}
              </div>
            )}
          </div>
          {showAllParticipants && (
            <>
              {participants.length === 0 && (
                <div className="w-full flex gap-4">
                  <div
                    className={clsx(
                      showDetailsSession2 ? 'w-1/2' : 'w-full',
                      showDetailsSession2 &&
                        !isGroupVisibleInSession1 &&
                        'invisible',
                    )}
                  >
                    <DiveSessionSimpleCardParticipantsGroupNoParticipant
                      widthMode={widthMode}
                    />
                  </div>
                  {showDetailsSession2 && (
                    <div
                      className={clsx(
                        'w-1/2',
                        !isGroupVisibleInSession2 && 'invisible',
                      )}
                    >
                      <DiveSessionSimpleCardParticipantsGroupNoParticipant
                        widthMode={widthMode}
                      />
                    </div>
                  )}
                </div>
              )}
              {participants.map((participantResume, participantIndex) => (
                <Fragment
                  key={`gr-p${participantIndex}-s${diveSessionReference}-u${
                    participantResume ? participantResume._updatedAt : ''
                  }`}
                >
                  <div
                    className={clsx(
                      'mt-px',
                      onClickParticipant && 'cursor-pointer',
                    )}
                  >
                    <div className="w-full flex gap-4">
                      <CalendarParticipantCell
                        multipleSessionsGroup={!showDetailsSession2}
                        multipleSessionNumber={1}
                        key={`s1-gr-p${participantIndex}-s${diveSessionReference}-u${
                          participantResume ? participantResume._updatedAt : ''
                        }`}
                        className={clsx(
                          'participant-cell',
                          `is-first-${participantIndex === 0}`,
                          `is-last-${
                            participantIndex + 1 === participants.length
                          }`,
                          showDetailsSession2 &&
                            !isParticipantInSession({
                              isMultiSessionConfigForParticipants,
                              participantResume,
                              sessionNumber: 1,
                            }) &&
                            'invisible',
                        )}
                        settingsUIPlanning={localSettingsUIPlanning}
                        isPast={isPast}
                        club={clubResume}
                        includeComment={true}
                        booking={participantResume.booking}
                        diver={participantResume.diver}
                        participant={participantResume}
                        diveSession={diveSession}
                        includePayment={includePayment}
                        onClick={
                          !onClickParticipant
                            ? undefined
                            : () =>
                                onClickParticipant(
                                  participantResume,
                                  diveSession,
                                )
                        }
                        anchorLink={anchorLinkParticipant}
                        widthMode={widthMode}
                      />
                      {showDetailsSession2 && (
                        <CalendarParticipantCell
                          booking={participantResume.booking}
                          multipleSessionsGroup={false}
                          multipleSessionNumber={2}
                          key={`s2-gr-p${participantIndex}-s${diveSessionReference}-u${
                            participantResume
                              ? participantResume._updatedAt
                              : ''
                          }`}
                          className={clsx(
                            'participant-cell',
                            `is-first-${participantIndex === 0}`,
                            `is-last-${
                              participantIndex + 1 === participants.length
                            }`,
                            !isParticipantInSession({
                              isMultiSessionConfigForParticipants,
                              participantResume,
                              sessionNumber: 2,
                            }) && 'invisible',
                          )}
                          settingsUIPlanning={localSettingsUIPlanning}
                          isPast={isPast}
                          club={clubResume}
                          includeComment={true}
                          diver={participantResume.diver}
                          participant={participantResume}
                          diveSession={diveSession}
                          includePayment={includePayment}
                          onClick={
                            !onClickParticipant
                              ? undefined
                              : () =>
                                  onClickParticipant(
                                    participantResume,
                                    diveSession,
                                  )
                          }
                          anchorLink={anchorLinkParticipant}
                          widthMode={widthMode}
                        />
                      )}
                    </div>
                  </div>
                </Fragment>
              ))}
            </>
          )}
        </div>
      </AppTransition>
    </div>
  );
};
function isParticipantInSession({
  isMultiSessionConfigForParticipants,
  participantResume,
  sessionNumber,
}: {
  isMultiSessionConfigForParticipants: boolean;
  participantResume: DiveSessionResumeParticipant;
  sessionNumber: MultipleDiveSessionNumber;
}): boolean {
  return (
    !isMultiSessionConfigForParticipants ||
    !participantResume.details?.multiSessionsPresenceNumbers ||
    participantResume.details?.multiSessionsPresenceNumbers.includes(
      sessionNumber,
    )
  );
}
