/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  AppDocUploadContext,
  BookingParticipantEditorFormModel,
} from '@mabadive/app-common-model';
import clsx from 'clsx';
import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { ClubDiverSimilarSearchCandidatesState } from 'src/business/club/modules/club-diver-participant/ClubDiverSimilarSearch';
import { BookingMemberGeneralEditFormFieldsAll } from './BookingMemberGeneralEditFormFieldsAll';
import { BookingMemberGeneralEditFormFieldsFrequentOnly } from './BookingMemberGeneralEditFormFieldsFrequentOnly';

export type BookingMemberGeneralEditFormRHFFieldsToDisplay =
  | 'frequent-only'
  | 'all';

export const BookingMemberGeneralEditFormRHF = ({
  form,
  fieldsToDisplay = 'all',
  similarSearchCandidatesState,
  appDocUploadContext,
  className = '',
}: {
  form: UseFormReturn<BookingParticipantEditorFormModel>;
  fieldsToDisplay?: BookingMemberGeneralEditFormRHFFieldsToDisplay;
  similarSearchCandidatesState?: ClubDiverSimilarSearchCandidatesState;
  appDocUploadContext: AppDocUploadContext;
  className?: string;
}) => {
  return (
    <div className={clsx('w-full', className)}>
      {fieldsToDisplay === 'all' ? (
        <BookingMemberGeneralEditFormFieldsAll
          form={form}
          appDocUploadContext={appDocUploadContext}
        />
      ) : (
        <BookingMemberGeneralEditFormFieldsFrequentOnly
          form={form}
          similarSearchCandidatesState={similarSearchCandidatesState}
        />
      )}
    </div>
  );
};
