/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ClubDiver,
  ClubProductPackageOfferView,
  ClubPurchasePackage,
  ClubPurchasePackageCredits,
  DiveServicePurchaseStatus,
} from '@mabadive/app-common-model';
import { productPackageFormatter } from '@mabadive/app-common-services';
import React, { useMemo, useState } from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { useAppSecurityUser } from 'src/business/auth/services';
import { useClubResume } from 'src/business/club/data/hooks';
import { ValueLabel } from 'src/business/club/modules/_common/form/components/ValueLabel.model';
import { AppPriceLabel } from 'src/business/club/modules/_common/ui';
import { AppInputRHF, AppSingleSelect2HeadlessUIRHF } from 'src/lib/form';
import { AppFormControlRHF } from 'src/lib/form/components/AppFormControl/AppFormControlRHF';
import { AppSingleAutocomplete2RHF } from 'src/lib/form/components/AppSingleAutocomplete';
import {
  useAppCurrencyMain,
  useBookingAgenciesOptions,
  useDiveServicePurchaseStatusesOptions,
} from 'src/pages/_components/options';
import { AppInputDatePickerMaterialRHF } from 'src/stories/components/04-form';
import { AppLibIcons } from '../../../../../../../../../_core/modules/layout/icons/lib';
import { DiverBookingPageAggregatedData } from '../../../../models';
import { BillingParticipantPurchaseResumeCreditsCount } from '../../../BillingParticipantPurchaseResumeTable/BillingParticipantPurchaseResumeCreditsCount';
import {
  creditsCountBuilder,
  diverPurchasePackageBuilder,
} from '../../../DiverPurchaseCommonEditorDialog';
import { DiverPurchaseCommonFormBookingAndAgency } from '../../../DiverPurchaseCommonEditorDialog/components';
import { DiverPurchasePriceDetails } from '../../../DiverPurchaseCommonEditorDialog/model';
import {
  DiverPurchaseCommonLocalStateActions,
  useDiverPurchaseCommonLocalStateActions,
} from '../../../DiverPurchaseCommonEditorDialog/useDiverPurchaseCommonLocalStateActions.service';
import { DiverPurchaseStoreFormModel } from './DiverPurchaseStoreFormModel.type';

export const DiverPurchaseStoreFormRHF = ({
  aggregatedData,
  diver,
  productPackageOffers,
  isCreation,
  form,
  purchasePackage,
  initialBookingId,
}: {
  aggregatedData?: DiverBookingPageAggregatedData;
  diver: Pick<ClubDiver, 'residentType'>;
  productPackageOffers: ClubProductPackageOfferView[];
  isCreation: boolean;
  form: UseFormReturn<DiverPurchaseStoreFormModel>;
  purchasePackage?: ClubPurchasePackage;
  initialBookingId: string;
}) => {
  const { control } = form;

  const securityUser = useAppSecurityUser();
  const clubResume = useClubResume();
  const publicSettings = clubResume.clubSettings.publicSettings;

  const clubSettings = clubResume.clubSettings;
  const agenciesOptions = useBookingAgenciesOptions();
  const generalSettings = clubResume.clubSettings.general;

  const mainCurrency = useAppCurrencyMain();

  const diveServicePurchaseStatusesOptions =
    useDiveServicePurchaseStatusesOptions();

  const offersOptions = useMemo(() => {
    const options = productPackageOffers.map((offer) => {
      const option: ValueLabel<string> = {
        label: productPackageFormatter.formatNameString(offer?.productPackage, {
          publicSettings,
          options: {
            showResidentType: !diver.residentType, // on affiche le type de résidence seulement si il n'est pas précisé pour le plongeur
          },
          diveModesConf: clubSettings?.ui?.diveMode,
        }),
        value: offer.reference,
      };
      return option;
    });
    return options;
  }, [
    clubSettings?.ui?.diveMode,
    diver.residentType,
    productPackageOffers,
    publicSettings,
  ]);

  const [unitPrice, unitQuantity, discountAmount, extraCosts] = useWatch({
    control,
    name: ['unitPrice', 'unitQuantity', 'discountAmount', 'extraCosts'],
  });

  const isPaymentEnabled = useMemo(
    () => securityUser?.roles.includes('club-edit-participant-payment'),
    [securityUser.roles],
  );

  const priceDetails: DiverPurchasePriceDetails = useMemo(() => {
    // on utilise openExtraCostsFormPanel pour être sûr de mettre à jour le total à la fermeture du dialog (sinon, y'a un coup de retard quand on édite!)
    return diverPurchasePackageBuilder.buildTotalPrice({
      isPaymentEnabled,
      isUnitOffer: true,
      unitPrice,
      unitQuantity,
      discountAmount,
      extraCosts,
    });
  }, [discountAmount, extraCosts, isPaymentEnabled, unitPrice, unitQuantity]);
  const { totalPrice, totalPriceExtraCosts } = priceDetails;

  const commonActions: DiverPurchaseCommonLocalStateActions =
    useDiverPurchaseCommonLocalStateActions({
      form,
      priceDetails,
    });

  const [productPackageOffer, setProductPackageOffer] =
    useState<ClubProductPackageOfferView>(purchasePackage?.productPackageOffer);
  const nonDivePlan =
    productPackageOffer?.productPackage?.productAttributes?.nonDivePlan;

  const [
    creditsInitialCount,
    creditsAdditionalCount,
    consumedExternalCount,
    validityStatus,
  ] = useWatch({
    control,
    name: [
      'creditsInitialCount',
      'creditsAdditionalCount',
      'consumedExternalCount',
      'validityStatus',
    ],
  });
  const credits: ClubPurchasePackageCredits = useMemo(() => {
    const consumedAppCount = 0;
    const { consumedTotalCount, creditsRemainingCount, creditsTotalCount } =
      creditsCountBuilder.updateCounts({
        creditsInitialCount,
        creditsAdditionalCount,
        consumedExternalCount,
        consumedAppCount,
        unitQuantityAvailableInPackage: 1,
      });
    const credits: ClubPurchasePackageCredits = {
      consumedAppCount,
      creditsInitialCount,
      creditsAdditionalCount,
      consumedExternalCount: consumedExternalCount ?? 0,
      creditsTotalCount,
      consumedTotalCount,
      creditsRemainingCount,
    };
    return credits;
  }, [consumedExternalCount, creditsAdditionalCount, creditsInitialCount]);

  return (
    <div className="grid gap-4">
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
        {isCreation || !productPackageOffer ? (
          <AppFormControlRHF
            className="w-full sm:col-span-2 xl:col-span-1"
            label="Produit"
            control={control}
            name="productPackageOfferReference"
            required={true}
            renderComponent={(props) => (
              <AppSingleAutocomplete2RHF
                {...props}
                options={offersOptions}
                onChange={(selectedOfferReference) => {
                  const selectedOffer: ClubProductPackageOfferView =
                    productPackageOffers.find(
                      (offer) => offer.reference === selectedOfferReference,
                    );
                  setProductPackageOffer(selectedOffer);
                  if (
                    selectedOffer?.price !== null &&
                    selectedOffer?.price !== unitPrice
                  ) {
                    // pour l'instant, un seul tarif supporté
                    form.setValue('unitPrice', selectedOffer?.price, {
                      shouldValidate: true,
                    });
                  }
                  const nonDivePlan =
                    selectedOffer?.productPackage?.productAttributes
                      ?.nonDivePlan;
                  const creditsInitialCount = nonDivePlan?.enabled
                    ? parseInt(nonDivePlan.quantity as unknown as string, 10)
                    : 1;
                  const consumedExternalCount = 1; // on initialise toujours le forfait à 1
                  form.setValue('creditsInitialCount', creditsInitialCount, {
                    shouldDirty: true,
                    shouldTouch: true,
                    shouldValidate: false,
                  });
                  form.setValue(
                    'consumedExternalCount',
                    consumedExternalCount,
                    {
                      shouldDirty: true,
                      shouldTouch: true,
                      shouldValidate: false,
                    },
                  );
                  onCreditsCountChanges({
                    validityStatus,
                    creditsInitialCount,
                    consumedExternalCount,
                    form,
                  });
                }}
              />
            )}
          />
        ) : (
          <div className="flex items-center gap-x-0.5 text-app-primary font-bold">
            <AppLibIcons.shop className="size-4 text-app-secondary mr-1" />
            {productPackageFormatter.formatNameString(
              productPackageOffer?.productPackage,
              {
                publicSettings,
                diveModesConf: clubSettings?.ui?.diveMode,
              },
            )}
          </div>
        )}
        <AppFormControlRHF
          className="w-full"
          label="Date d'achat"
          control={control}
          name="purchaseDate"
          renderComponent={(props) => (
            <AppInputDatePickerMaterialRHF className="w-full" {...props} />
          )}
        />
        {!nonDivePlan?.enabled && (
          <AppFormControlRHF
            className="w-full"
            label="Statut"
            control={control}
            name="validityStatus"
            required={true}
            renderComponent={(props) => (
              <AppSingleSelect2HeadlessUIRHF
                theme="material-ui"
                {...props}
                options={diveServicePurchaseStatusesOptions}
                disableClearButton={true}
              />
            )}
          />
        )}
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-4">
        <AppFormControlRHF
          className="w-full"
          label={'Quantité'}
          control={control}
          name="unitQuantity"
          required={true}
          renderComponent={(props) => (
            <AppInputRHF {...props} fullWidth type="number" />
          )}
        />
        <AppFormControlRHF
          className="w-full"
          label={`Prix unitaire (${mainCurrency.sign})`}
          control={control}
          name="unitPrice"
          renderComponent={(props) => (
            <AppInputRHF {...props} fullWidth type="number" />
          )}
        />

        {generalSettings?.billing?.thirdPartyCollectEnabled && (
          <AppFormControlRHF
            control={control}
            label="Total tiers"
            name={'totalPriceThirdPartyCollect' as any}
            renderComponent={(props) => (
              <AppInputRHF {...props} type="number" />
            )}
          />
        )}
        <div className="grid grid-cols-2 gap-2">
          <AppFormControlRHF
            className="w-full"
            label={`Remise (${mainCurrency.sign})`}
            control={control}
            name="discountAmount"
            renderComponent={(props) => (
              <AppInputRHF
                {...props}
                fullWidth
                type="number"
                onChange={commonActions.onChangeDiscountAmount}
              />
            )}
          />
          <AppFormControlRHF
            className="w-full"
            label={'Remise (%)'}
            control={control}
            name="discountPercentage"
            renderComponent={(props) => (
              <AppInputRHF
                {...props}
                fullWidth
                type="number"
                onChange={commonActions.onChangeDiscountPercentage}
              />
            )}
          />
        </div>
      </div>
      {nonDivePlan?.enabled && (
        <div className="flex gap-4 items-center">
          <AppFormControlRHF
            label="Crédits consommés"
            control={control}
            name="consumedExternalCount"
            required={true}
            renderComponent={(props) => (
              <AppInputRHF
                {...props}
                className="w-14"
                type="number"
                showIncrementButtons={true}
                onChange={(consumedExternalCount) => {
                  onCreditsCountChanges({
                    validityStatus,
                    creditsInitialCount,
                    consumedExternalCount: consumedExternalCount as number,
                    form,
                  });
                }}
              />
            )}
          />
          <AppFormControlRHF
            label={'TOTAL Crédits'}
            control={control}
            name="creditsInitialCount"
            required={true}
            renderComponent={(props) => (
              <AppInputRHF
                {...props}
                className="w-24"
                type="number"
                showIncrementButtons={false} // sinon on risque de confondre!
                onChange={(creditsInitialCount) => {
                  onCreditsCountChanges({
                    validityStatus,
                    creditsInitialCount: creditsInitialCount as number,
                    consumedExternalCount,
                    form,
                  });
                }}
              />
            )}
          />
          <AppFormControlRHF
            label="Statut"
            control={control}
            name="validityStatus"
            required={true}
            renderComponent={(props) => (
              <AppSingleSelect2HeadlessUIRHF
                theme="material-ui"
                {...props}
                options={diveServicePurchaseStatusesOptions}
                disableClearButton={true}
              />
            )}
          />
        </div>
      )}

      <div className={'flex gap-4'}>
        <div className="grid gap-2">
          <div className="font-bold text-app-primary uppercase text-xs leading-3">
            Prix total
          </div>
          {totalPrice > 0 && (
            <div className="font-bold text-app-primary text-xl">
              <AppPriceLabel amount={totalPrice} mainCurrency={mainCurrency} />
            </div>
          )}
        </div>
        {nonDivePlan?.enabled && (
          <div className="grid gap-2">
            <div className="font-bold text-app-primary uppercase text-xs leading-3">
              Forfait
            </div>
            <div className="font-bold text-app-primary text-xl">
              <BillingParticipantPurchaseResumeCreditsCount
                className="text-xl"
                credits={credits}
                isVirtual={false}
              />
            </div>
          </div>
        )}
      </div>

      <DiverPurchaseCommonFormBookingAndAgency
        isCreation={isCreation}
        form={form}
        initialBookingId={initialBookingId}
        aggregatedData={aggregatedData}
      />
      <AppFormControlRHF
        className="flex-grow w-full"
        label="Commentaire privé 🚫"
        control={control}
        name="comment"
        renderComponent={(props) => (
          <AppInputRHF {...props} fullWidth multiline rowsMax={15} rows={2} />
        )}
      />
    </div>
  );
};
function onCreditsCountChanges({
  validityStatus,
  creditsInitialCount,
  consumedExternalCount,
  form,
}: {
  validityStatus: DiveServicePurchaseStatus;
  creditsInitialCount: number;
  consumedExternalCount: number;
  form: UseFormReturn<DiverPurchaseStoreFormModel>;
}) {
  if (validityStatus !== 'obsolete') {
    if (
      creditsInitialCount > 0 &&
      (consumedExternalCount as number) >= creditsInitialCount
    ) {
      form.setValue('validityStatus', 'completed');
    } else {
      form.setValue('validityStatus', 'active');
    }
  }
}
