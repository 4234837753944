import {
  ClubSettings_ConfigAssistant_Progress,
  ClubSettings_ConfigAssistant_ProgressStepKey,
} from '@mabadive/app-common-model';
import { useMemo } from 'react';
import { useClubResume } from '../../../business/club/data/hooks';
import { useSettingsCategories } from '../SE-00_home/useSettingsCategories.hook';
import {
  SETTINGS_DIVE_CENTER_PAGE_BOATS,
  SETTINGS_DIVE_CENTER_PAGE_DIVE_SITES,
  SETTINGS_DIVE_CENTER_PAGE_PLANNING,
  SETTINGS_DIVE_CENTER_PAGE_STAFF,
} from '../SE-01_dive-center/SETTINGS_DIVE_CENTER.const';
import {
  SETTINGS_ACCOUNT_PAGE_COMPANY_INFO,
  SETTINGS_ACCOUNT_PAGE_SERVICES,
  SETTINGS_DIVE_CENTER_PAGE_EQUIPMENTS,
  SETTINGS_SECURITY_PAGE_USER_ACCOUNTS,
} from '../SE-02_account/SETTINGS_ACCOUNT.const';
import { ConfigAssistantProgressStep } from './model';

export function useConfigAssistantProgressSteps({
  showDisabledSteps = false,
}: {
  showDisabledSteps?: boolean;
}): ConfigAssistantProgressStep[] {
  const clubResume = useClubResume();
  const configAssistantSettings = clubResume?.clubSettings?.configAssistant;

  const settingsCategories = useSettingsCategories();

  return useMemo(() => {
    const progressSteps: ConfigAssistantProgressStep[] = [];

    for (const category of settingsCategories) {
      for (const page of category.pages) {
        let progressKey: ClubSettings_ConfigAssistant_ProgressStepKey =
          getProgressKeyFromPageId(page.id);
        if (progressKey) {
          let progress: ClubSettings_ConfigAssistant_Progress =
            configAssistantSettings?.steps?.[progressKey];

          if (!progress) {
            progress = {
              enabled: false,
            };
          }
          if (showDisabledSteps || progress.enabled) {
            const progressStep: ConfigAssistantProgressStep = {
              page,
              category,
              progressKey,
              progress,
            };
            progressSteps.push(progressStep);
          }
        }
      }
    }

    return progressSteps;
  }, [settingsCategories, configAssistantSettings?.steps, showDisabledSteps]);
}

function getProgressKeyFromPageId(
  pageId: string,
): ClubSettings_ConfigAssistant_ProgressStepKey {
  switch (pageId) {
    // Centre de plongée
    case SETTINGS_DIVE_CENTER_PAGE_PLANNING.id:
      return 'defaultPlanning';
    case SETTINGS_DIVE_CENTER_PAGE_STAFF.id:
      return 'staff';
    case SETTINGS_DIVE_CENTER_PAGE_BOATS.id:
      return 'equipments';
    case SETTINGS_DIVE_CENTER_PAGE_DIVE_SITES.id:
      return 'diveSites';

    // Mon compte
    case SETTINGS_ACCOUNT_PAGE_COMPANY_INFO.id:
      return 'companyInfo';
    case SETTINGS_ACCOUNT_PAGE_SERVICES.id:
      return 'services';
    case SETTINGS_SECURITY_PAGE_USER_ACCOUNTS.id:
      return 'userAccounts';
    case SETTINGS_DIVE_CENTER_PAGE_EQUIPMENTS.id:
      return 'equipments';

    default:
      return null;
  }
}
