import { ClubDiver } from '@mabadive/app-common-model';
import { dateService, nameFormatter } from '@mabadive/app-common-services';
import clsx from 'clsx';
import React, { useMemo, useState } from 'react';
import { useAppDocImageUrl } from './useAppDocImageUrl.hook';

export type AppProfilePictureShape = 'circle' | 'square';
interface AppProfilePictureProps {
  imageUrl?: string;
  label?: React.ReactNode | React.ReactNode[];
  subLabel?: React.ReactNode | React.ReactNode[];
  altText?: string;
  shape?: AppProfilePictureShape;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  className?: string;
}

export const AppProfilePicture: React.FC<AppProfilePictureProps> = ({
  imageUrl,
  label,
  subLabel,
  altText,
  shape = 'circle',
  onClick,
  className,
}) => {
  return (
    <div
      className={clsx(
        'flex-0 flex-shrink-0 overflow-hidden flex items-center justify-center',
        shape === 'circle' ? 'p-0.5 rounded-full' : 'p-px',
        imageUrl ? 'bg-gray-500' : 'bg-gray-200',
        className,
      )}
      onClick={onClick}
    >
      {imageUrl ? (
        <img
          src={imageUrl}
          alt={altText || 'Profile picture'}
          className={clsx(
            'w-full h-full object-cover ',
            shape === 'circle' && 'rounded-full',
          )}
        />
      ) : label ? (
        <span
          className={clsx(
            'inline-flex flex-col gap-0.5 h-full w-full items-center justify-center  bg-gray-400',
            shape === 'circle' && 'rounded-full',
          )}
        >
          <span className="text-xl font-medium leading-none text-white">
            {label}
          </span>
          {subLabel && (
            <span className="text-sm font-medium leading-none text-gray-100">
              {subLabel}
            </span>
          )}
        </span>
      ) : (
        <span
          className={clsx(
            'inline-block h-full w-full overflow-hidden  bg-gray-100',
            shape === 'circle' && 'rounded-full',
          )}
        >
          <svg
            fill="currentColor"
            viewBox="0 0 24 24"
            className="h-full w-full text-gray-300"
          >
            <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
          </svg>
        </span>
      )}
    </div>
  );
};

export function ClubDiverProfilePicture({
  diver,
  shape,
  onClick,
  className,
}: {
  diver: Pick<
    ClubDiver,
    | 'birthdate'
    | 'age'
    | 'firstName'
    | 'lastName'
    | 'fakeName'
    | 'mainCertificationReference'
    | 'details'
  >;
  shape?: AppProfilePictureShape;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  className?: string;
}) {
  const imageUrl = useAppDocImageUrl({
    appDocId: diver.details?.profilePhoto?._id,
    publicUrl: diver.details?.profilePhoto?.publicUrl,
  });
  const age = useMemo(
    () => (diver.birthdate ? dateService.getAge(diver.birthdate) : diver.age),
    [diver.age, diver.birthdate],
  );

  const fullName = useMemo(() => nameFormatter.formatFullName(diver), [diver]);
  const initials = useMemo(() => nameFormatter.formatInitials(diver), [diver]);

  const [triggerFetchIndex, setTriggerFetchIndex] = useState(0);

  return (
    <AppProfilePicture
      className={className}
      altText={fullName}
      // label={initials}
      // label={diver.mainCertificationReference}
      // subLabel={diver.}
      imageUrl={imageUrl}
      shape={shape}
      onClick={onClick}
    />
  );
}
