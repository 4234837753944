/* eslint-disable @typescript-eslint/no-unused-vars */
import { ClubBoatDepartureType } from '@mabadive/app-common-model';
import { CLUB_RESSOURCES_TYPES } from '@mabadive/app-common-services';
import React from 'react';
import { useWatch } from 'react-hook-form';
import { AppMessageLight } from 'src/business/_core/modules/layout';
import { AppIconsBoatDeparture } from 'src/business/_core/modules/layout/icons';
import { ValueLabel } from 'src/business/club/modules/_common/form/components/ValueLabel.model';
import {
  AppInputBooleanSwitchRHF,
  AppInputColorRHF,
  AppInputRHF,
  AppToogleButtonGroupRHF,
} from 'src/lib/form';
import { AppFormControlRHF } from 'src/lib/form/components/AppFormControl';
import { AppSingleSelect2HeadlessUIRHF } from 'src/lib/form/components/AppSingleSelect/AppSingleSelect2HeadlessUIRHF';
import { ClubBoatLabel } from 'src/pages/_components/company';
import { useClubBoatDepartureTypesOptions } from 'src/pages/_components/options';
import { ClubBoatFormLocalState } from '../model';

export const ClubBoatEditForm = ({
  localState,
}: {
  localState: ClubBoatFormLocalState;
}) => {
  const { form, showIsDefault } = localState;
  const { control, setValue } = form;

  const [name, departureType] = useWatch({
    control,
    name: ['name', 'departureType'],
  });

  const clubBoatDepartureTypeOptions: ValueLabel<ClubBoatDepartureType>[] =
    useClubBoatDepartureTypesOptions().map((x) => ({
      ...x,
      icon: AppIconsBoatDeparture[x.value],
    }));

  return !departureType ? (
    <div>
      <h2 className="text-left text-sm leading-6 py-2 font-medium text-gray-600 uppercase">
        Quel type de ressource souhaitez-vous créer ?
      </h2>
      <AppFormControlRHF
        className={'md:col-span-4 w-full'}
        control={control}
        name="departureType"
        renderComponent={(props) => (
          <AppToogleButtonGroupRHF
            {...props}
            color="green"
            theme="strong"
            customClassName="grid sm:grid-cols-2"
            items={clubBoatDepartureTypeOptions}
            onChanges={(departureType) => {
              if (departureType) {
                const ressourceType = CLUB_RESSOURCES_TYPES.find(
                  (x) => x.departureType === departureType,
                );
                setValue('name', ressourceType?.defaultLabel);
                setValue('details.shortName', ressourceType?.defaultLabelShort);
                setValue('color', ressourceType?.defaultColor);
                if (departureType !== 'boat') {
                  setValue('isSpecial', true);
                }
              }
            }}
          />
        )}
      />
    </div>
  ) : (
    <>
      <h2 className="text-left text-sm sm:text-lg leading-6 py-2 font-medium text-app-blue uppercase">
        <div className="grid xs:grid-cols-2">
          <ClubBoatLabel format="long" clubBoat={form.getValues()} />
          <ClubBoatLabel format="short" clubBoat={form.getValues()} />
        </div>
      </h2>
      <div className={'w-full flex flex-col xs:grid xs:grid-cols-12 gap-4'}>
        <AppFormControlRHF
          className={'col-span-6 md:col-span-3 w-full'}
          label="Nom"
          control={control}
          name={'name'}
          required={true}
          renderComponent={(props) => (
            <AppInputRHF {...props} fullWidth type="text" />
          )}
        />
        <AppFormControlRHF
          className={'col-span-6 md:col-span-3 w-full'}
          label="Diminutif"
          control={control}
          name={'details.shortName'}
          required={true}
          validation={{
            rules: {
              maxLength: 3,
            },
          }}
          renderComponent={(props) => (
            <AppInputRHF {...props} fullWidth type="text" />
          )}
        />
        <AppFormControlRHF
          className={'col-span-6 md:col-span-3 w-full'}
          label={'Couleur'}
          required={true}
          control={control}
          name={'color'}
          renderComponent={(props) => (
            <AppInputColorRHF
              {...props}
              required={false}
              className={'my-2 text-center text-xl font-bold'}
            />
          )}
        />
        <AppFormControlRHF
          className={'col-span-6 md:col-span-3 w-full'}
          control={control}
          label="Surligner texte"
          name="isSpecial"
          renderComponent={(props) => (
            <AppInputBooleanSwitchRHF className="w-full" {...props} />
          )}
        />
        {departureType === 'boat' && (
          <>
            <AppFormControlRHF
              className={'col-span-6 md:col-span-3 w-full'}
              label="Immatriculation"
              control={control}
              name={'immatriculation'}
              renderComponent={(props) => (
                <AppInputRHF {...props} fullWidth type="text" />
              )}
            />
            <AppFormControlRHF
              className={'col-span-6 md:col-span-3 w-full'}
              label="Modèle"
              control={control}
              name={'type'}
              renderComponent={(props) => (
                <AppInputRHF {...props} fullWidth type="text" />
              )}
            />
          </>
        )}
        <AppFormControlRHF
          className={'col-span-6 md:col-span-3 w-full'}
          label={
            departureType === 'boat' ? 'Nb passagers max' : 'Nb personnes max'
          }
          control={control}
          name={'maxPassengers'}
          renderComponent={(props) => (
            <AppInputRHF {...props} fullWidth type="number" />
          )}
        />
        <AppFormControlRHF
          className={'col-span-6 md:col-span-3 w-full'}
          label="Nb blocs max"
          control={control}
          name={'maxTanks'}
          renderComponent={(props) => (
            <AppInputRHF {...props} fullWidth type="number" />
          )}
        />
        <AppFormControlRHF
          className={'col-span-6 md:col-span-3 w-full'}
          label="Type de ressource"
          control={control}
          name={'departureType'}
          required={false}
          renderComponent={(props) => (
            <AppSingleSelect2HeadlessUIRHF
              {...props}
              disableClearButton={true}
              theme={'tailwind'}
              options={clubBoatDepartureTypeOptions}
            />
          )}
        />
        {showIsDefault && (
          <AppFormControlRHF
            className={'col-span-6 md:col-span-3 w-full'}
            control={control}
            label="Ressource par défaut"
            name="isDefault"
            renderComponent={(props) => (
              <AppInputBooleanSwitchRHF className="w-full" {...props} />
            )}
          />
        )}
        <AppFormControlRHF
          className={'col-span-12 w-full'}
          label="Description"
          control={control}
          name={'description'}
          renderComponent={(props) => (
            <AppInputRHF {...props} fullWidth rows={3} />
          )}
        />
      </div>
      {departureType === 'boat' && (
        <AppMessageLight className="my-4" type="info">
          <div>
            Le nom et l'immatriculation du bateau apparaissent sur la fiche de
            sécurité.
          </div>
          {showIsDefault && (
            <div>
              Le bateau <b>par défaut</b> est assigné par défaut aux nouvelles
              sorties.
            </div>
          )}
        </AppMessageLight>
      )}
    </>
  );
};
