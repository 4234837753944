/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import {
  FieldArrayWithId,
  UseFieldArrayReturn,
  UseFormReturn,
} from 'react-hook-form';
import { AppInputRHF, AppSingleAutocomplete2RHF } from 'src/lib/form';
import { AppFormControlRHF } from 'src/lib/form/components/AppFormControl/AppFormControlRHF';

import { FirstDiveTrainingReference } from '@mabadive/app-common-model';
import { useAppSecurityUserHasRole } from 'src/business/auth/services';
import { useClubResume, useClubSettings } from 'src/business/club/data/hooks';
import { ValueLabel } from 'src/business/club/modules/_common/form';
import { useFirstDiveTrainingOptions } from 'src/pages/_components/options';
import { ClubSettingsProductOffersFormModel } from '../../_model';
import { ClubSettingsProductOffersFormOfferCustomName } from '../ClubSettingsProductOffersFormOfferCustomName';
import {
  ClubSettingsProductOffersFormOfferCommon_Others_TO_REFACTOR,
  ClubSettingsProductOffersFormOfferCommon_Tarif,
} from '../ClubSettingsProductOffersFormOffer_Common';
import { useClubSettingsProductOffersFormOfferNameDetails } from '../useClubSettingsProductOffersFormOfferNameDetails.hook';

export const ClubSettingsProductOffersFormOffer_FirstDive = ({
  form,
  offerField,
  offerFieldIndex,
  offersFieldArray,
}: {
  form: UseFormReturn<ClubSettingsProductOffersFormModel>;
  offerField: FieldArrayWithId<
    ClubSettingsProductOffersFormModel,
    'offers',
    'id'
  >;
  offerFieldIndex: number;
  offersFieldArray: UseFieldArrayReturn<
    ClubSettingsProductOffersFormModel,
    'offers',
    'id'
  >;
}) => {
  const clubResume = useClubResume();
  const clubSettings = useClubSettings();
  const generalSettings = clubResume.clubSettings.general;
  const publicSettings = clubResume.clubSettings.publicSettings;
  const services = clubResume.clubSettings.services;

  const isSuperAdmin = useAppSecurityUserHasRole('super-admin');

  const isProduct = offerField.productPackage.type === 'product';

  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const i = offerFieldIndex;

  const showDetails = offersFieldArray.fields.length === 1;

  const nameDetails = useClubSettingsProductOffersFormOfferNameDetails({
    form,
    offerField,
    offersFieldArray,
  });

  const firstDiveTrainingOptions: ValueLabel<FirstDiveTrainingReference>[] =
    useFirstDiveTrainingOptions(clubSettings, {
      scope: 'enabled',
    });

  return (
    <div className="grid gap-2 ">
      <div className="grid gap-2 grid-cols-2 sm:grid-cols-4 2xl:grid-cols-8">
        {firstDiveTrainingOptions.length > 1 && (
          <AppFormControlRHF
            className="w-full col-span-2"
            label="Type de baptême"
            control={control}
            name={`offers.${i}.productPackage.diveAttributes.firstDiveTrainingReference`}
            required={false}
            renderComponent={(props) => (
              <AppSingleAutocomplete2RHF
                {...props}
                options={firstDiveTrainingOptions}
              />
            )}
          />
        )}

        <ClubSettingsProductOffersFormOfferCommon_Tarif
          form={form}
          offerFieldIndex={offerFieldIndex}
        />
        <ClubSettingsProductOffersFormOfferCommon_Others_TO_REFACTOR
          form={form}
          offerField={offerField}
          offerFieldIndex={offerFieldIndex}
          offersFieldArray={offersFieldArray}
        />
      </div>

      {showDetails && (
        <ClubSettingsProductOffersFormOfferCustomName
          form={form}
          nameDetails={nameDetails}
          i={i}
        />
      )}
      <div className="">
        <AppFormControlRHF
          className="w-full"
          label="Commentaire"
          control={control}
          name={`offers.${i}.productPackage.comment`}
          renderComponent={(props) => (
            <AppInputRHF
              className="w-full"
              {...props}
              placeholder="Commentaire"
              type="text"
              multiline={true}
              rowsMin={2}
            />
          )}
        />
      </div>
    </div>
  );
};
