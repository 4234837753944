import {
  AppInquiryQuestion,
  AppInquirySection,
  AppInquirySettings,
  AppInquirySettings_ResponsesProcessing,
  AppInquiryType,
  DIVE_MODES,
} from '@mabadive/app-common-model';
import { uuidGenerator } from '@mabadive/app-common-services';

const defaultResponsesProcessing: AppInquirySettings_ResponsesProcessing = {
  unexpectedAnswerColor: '#c22828',
  unexpectedAnswerAutoDeny: true,
  expectedAnswerColor: '#0a9107',
  expectedAnswerAutoValidate: true,
};

export const inqueryDefaultBuilder = {
  buildFromType: ({
    inquiryType,
  }: {
    inquiryType: AppInquiryType;
  }): {
    sections: AppInquirySection[];
    settings: Pick<
      AppInquirySettings,
      | 'autoActivation'
      | 'responsesProcessing'
      | 'shortName'
      | 'color'
      | 'docValidityInYears'
      | 'consents'
    >;
  } => {
    let sections: AppInquirySection[];
    let settings: Pick<
      AppInquirySettings,
      | 'autoActivation'
      | 'responsesProcessing'
      | 'shortName'
      | 'color'
      | 'docValidityInYears'
      | 'consents'
    >;
    switch (inquiryType) {
      case 'medical-form': {
        sections = medicalInquiryForm.map((s) => buildSectionIds(s));
        settings = {
          shortName: 'FM',
          color: '#16a34a',
          autoActivation: {
            futurDiveModesEnabled: true,
            diveModes: [...DIVE_MODES],
          },
          responsesProcessing: {
            ...defaultResponsesProcessing,
            unexpectedAnswerMessageToDisplay:
              "En complément de ce questionnaire, veuillez nous fournir la copie d'un certificat médical de moins d'1 an.",
          },
          docValidityInYears: 1,
          consents: {
            gdpr: {
              enabled: true,
            },
            padSignature: {
              enabled: true,
            },
          },
        };
        break;
      }
      case 'parental-consent':
        sections = parentalAuthorizationForm.map((s) => buildSectionIds(s));
        settings = {
          shortName: 'AP',
          color: '#2563eb',
          autoActivation: {
            age: {
              min: 0,
              max: 17,
            },
            futurDiveModesEnabled: true,
            diveModes: [...DIVE_MODES],
          },
          responsesProcessing: {
            ...defaultResponsesProcessing,
          },
          docValidityInYears: 1,
          consents: {
            gdpr: {
              enabled: true,
            },
            padSignature: {
              enabled: true,
            },
          },
        };
        break;
    }
    return { sections, settings };
  },
};

function buildSectionIds(section: AppInquirySection): AppInquirySection {
  return {
    ...section,
    sectionId: uuidGenerator.random(),
    questions: section.questions.map(buildQuestionId),
  };
}
function buildQuestionId(question: AppInquiryQuestion): AppInquiryQuestion {
  return {
    ...question,
    questionId: uuidGenerator.random(),
  };
}

const medicalInquiryForm: AppInquirySection[] = [
  {
    sectionId: 'medical-history',
    sortIndex: 0,
    description: {
      main: { title: 'Antécédents Médicaux', languageCode: 'fr', items: [] },
    },
    questions: [
      {
        questionId: 'cardiac-history',
        sortIndex: 0,
        description: {
          main: {
            title: 'Avez-vous des antécédents de problèmes cardiaques?',
            languageCode: 'fr',
            items: [],
          },
        },
        answerDetails: {
          format: 'yes-no',
          required: true,
          formatOptions: {
            yesNo: {
              expectedValue: false,
            },
          },
        },
      },
      {
        questionId: 'asthma-history',
        sortIndex: 1,
        description: {
          main: {
            title:
              "Souffrez-vous d'asthme ou de problèmes respiratoires chroniques?",
            languageCode: 'fr',
            items: [],
          },
        },
        answerDetails: {
          format: 'yes-no',
          required: true,
          formatOptions: {
            yesNo: {
              expectedValue: false,
            },
          },
        },
      },
      {
        questionId: 'epilepsy-history',
        sortIndex: 2,
        description: {
          main: {
            title: "Avez-vous déjà eu des crises d'épilepsie?",
            languageCode: 'fr',
            items: [],
          },
        },
        answerDetails: {
          format: 'yes-no',
          required: true,
          formatOptions: {
            yesNo: {
              expectedValue: false,
            },
          },
        },
      },
    ],
  },
  {
    sectionId: 'current-health',
    sortIndex: 1,
    description: {
      main: {
        title: 'Problèmes de Santé Actuels',
        languageCode: 'fr',
        items: [],
      },
    },
    questions: [
      {
        questionId: 'chest-pain',
        sortIndex: 0,
        description: {
          main: {
            title: 'Ressentez-vous actuellement des douleurs à la poitrine?',
            languageCode: 'fr',
            items: [],
          },
        },
        answerDetails: {
          format: 'yes-no',
          required: true,
          formatOptions: {
            yesNo: {
              expectedValue: false,
            },
          },
        },
      },
      {
        questionId: 'ear-sinus-problems',
        sortIndex: 1,
        description: {
          main: {
            title: "Avez-vous des problèmes d'oreilles ou de sinus?",
            languageCode: 'fr',
            items: [],
          },
        },
        answerDetails: {
          format: 'yes-no',
          required: true,
          formatOptions: {
            yesNo: {
              expectedValue: false,
            },
          },
        },
      },
      {
        questionId: 'medication',
        sortIndex: 2,
        description: {
          main: {
            title: 'Prenez-vous des médicaments régulièrement?',
            languageCode: 'fr',
            items: [],
          },
        },
        answerDetails: {
          format: 'yes-no',
          required: true,
          formatOptions: {
            yesNo: {
              expectedValue: false,
            },
          },
        },
      },
    ],
  },
  {
    sectionId: 'specific-conditions',
    sortIndex: 2,
    description: {
      main: { title: 'Conditions Spécifiques', languageCode: 'fr', items: [] },
    },
    questions: [
      {
        questionId: 'pregnancy',
        sortIndex: 0,
        description: {
          main: {
            title: "Êtes-vous enceinte ou pensez-vous l'être?",
            languageCode: 'fr',
            items: [],
          },
        },
        answerDetails: {
          format: 'yes-no',
          required: true,
          formatOptions: {
            yesNo: {
              expectedValue: false,
            },
          },
        },
      },
      {
        questionId: 'recent-surgery',
        sortIndex: 1,
        description: {
          main: {
            title: 'Avez-vous été opéré récemment (moins de 6 mois)?',
            languageCode: 'fr',
            items: [],
          },
        },
        answerDetails: {
          format: 'yes-no',
          required: true,
          formatOptions: {
            yesNo: {
              expectedValue: false,
            },
          },
        },
      },
      {
        questionId: 'allergies',
        sortIndex: 2,
        description: {
          main: {
            title:
              "Avez-vous des allergies connues aux médicaments ou aux piqûres d'insectes?",
            languageCode: 'fr',
            items: [],
          },
        },
        answerDetails: {
          format: 'yes-no',
          required: true,
          formatOptions: {
            yesNo: {
              expectedValue: false,
            },
          },
        },
      },
    ],
  },
];

const parentalAuthorizationForm: AppInquirySection[] = [
  {
    sectionId: 'child-info',
    sortIndex: 0,
    description: {
      main: {
        title: "Informations de l'Enfant",
        languageCode: 'fr',
        items: [],
      },
    },
    questions: [
      {
        questionId: 'child-name',
        sortIndex: 0,
        description: {
          main: {
            title: "Nom et Prénom de l'enfant",
            languageCode: 'fr',
            items: [],
          },
        },
        answerDetails: { format: 'text-short', required: true },
      },
      {
        questionId: 'child-dob',
        sortIndex: 1,
        description: {
          main: { title: 'Date de naissance', languageCode: 'fr', items: [] },
        },
        answerDetails: {
          format: 'date',
          required: true,
          formatOptions: {
            date: {
              type: 'birthdate',
            },
          },
        },
      },
      {
        questionId: 'child-chronic-illness',
        sortIndex: 2,
        description: {
          main: {
            title:
              "L'enfant souffre-t-il d'une maladie chronique (asthme, diabète, etc.)?",
            languageCode: 'fr',
            items: [],
          },
        },
        answerDetails: {
          format: 'yes-no',
          required: true,
          formatOptions: {
            yesNo: {
              expectedValue: false,
            },
          },
        },
      },
    ],
  },
  {
    sectionId: 'parent-consent',
    sortIndex: 1,
    description: {
      main: { title: 'Consentement Parental', languageCode: 'fr', items: [] },
    },
    questions: [
      {
        questionId: 'consent-dive',
        sortIndex: 0,
        description: {
          main: {
            title:
              "J'autorise mon enfant à participer à un baptême de plongée.",
            languageCode: 'fr',
            items: [],
          },
        },
        answerDetails: { format: 'agreement', required: true },
      },
      {
        questionId: 'acknowledge-risks',
        sortIndex: 1,
        description: {
          main: {
            title:
              "J'atteste avoir pris connaissance des contre-indications médicales à la plongée.",
            languageCode: 'fr',
            items: [],
          },
        },
        answerDetails: { format: 'agreement', required: true },
      },
      {
        questionId: 'emergency-consent',
        sortIndex: 2,
        description: {
          main: {
            title:
              "En cas d'urgence, j'autorise le personnel à prodiguer les premiers soins nécessaires à mon enfant.",
            languageCode: 'fr',
            items: [],
          },
        },
        answerDetails: { format: 'agreement', required: true },
      },
    ],
  },
  {
    sectionId: 'emergency-contact',
    sortIndex: 2,
    description: {
      main: { title: "Contact d'Urgence", languageCode: 'fr', items: [] },
    },
    questions: [
      {
        questionId: 'parent-name',
        sortIndex: 0,
        description: {
          main: {
            title: 'Nom et Prénom du parent/tuteur',
            languageCode: 'fr',
            items: [],
          },
        },
        answerDetails: { format: 'text-short', required: true },
      },
      {
        questionId: 'parent-phone',
        sortIndex: 1,
        description: {
          main: { title: 'Numéro de téléphone', languageCode: 'fr', items: [] },
        },
        answerDetails: {
          format: 'text-short',
          required: true,
          formatOptions: {
            textShort: {
              format: 'phone',
            },
          },
        },
      },
      {
        questionId: 'parent-email',
        sortIndex: 2,
        description: {
          main: { title: 'Adresse e-mail', languageCode: 'fr', items: [] },
        },
        answerDetails: {
          format: 'text-short',
          required: true,
          formatOptions: {
            textShort: {
              format: 'email',
            },
          },
        },
      },
    ],
  },
];
