import { Booking } from '@mabadive/app-common-model';
import { useMemo } from 'react';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { useAppSecurityUserClubAuthorizations } from 'src/business/auth/services';
import { AppHamburgerMenuItem } from 'src/business/club/modules/_common/ui';
import { useClubResume } from '../../../../../../data/hooks';
import { BookingTabModel } from '../../../DiverBookingPage/models';
import {
  useDiverBookingPageDeleteBooking,
  useDiverBookingPageDeleteMember,
  useDiverBookingPageDuplicateBooking,
  useDiverBookingPageExtractMemberToNewBooking,
  useDiverBookingPageUpdateBooking,
} from '../../../DiverBookingPage/services';
import { DiverBookingPageActions } from '../../../DiverBookingPage/useDiverBookingPageActions.hook';
import { DiverBookingPageGlobalState } from '../../../DiverBookingPage/useDiverBookingPageGlobalState.hook';

export function useDiverBookingSpace_BookingListItemMenu({
  globalState,
  booking,
  actions,
  bookingTabModel,
  extraMenuItems,
}: {
  globalState: DiverBookingPageGlobalState;
  booking: Booking;
  actions: DiverBookingPageActions;
  bookingTabModel: BookingTabModel;
  extraMenuItems: AppHamburgerMenuItem[];
}) {
  const { data, state } = globalState;
  const { aggregatedData } = data;
  const { clubReference, diveCenterId, updateState, setUpdateState } = state;

  const { openDiverSelector, openSessionSelector, openBookingMassiveEditor } =
    actions;
  const az = useAppSecurityUserClubAuthorizations();

  const clubResume = useClubResume();
  const clubSettings = clubResume?.clubSettings;

  const clubPublicReference = clubResume.clubSettings?.clubPublicReference;
  const customerSpaceEnabled =
    clubPublicReference &&
    clubResume.clubSettings?.publicSettings?.customerSpace?.enabled &&
    booking?._id;
  const sendMessageEnabled =
    clubSettings.communication?.booking?.enableMessage &&
    az.communication?.action?.sendBookingMessage;

  const extractMemberToNewBooking =
    useDiverBookingPageExtractMemberToNewBooking({
      aggregatedData,
      clubReference,
      diveCenterId,
      updateState,
      setUpdateState,
    });

  const deleteBooking = useDiverBookingPageDeleteBooking({
    clubReference,
    updateState,
    setUpdateState,
  });

  const deleteMember = useDiverBookingPageDeleteMember({
    updateState,
    setUpdateState,
  });
  const duplicateBooking = useDiverBookingPageDuplicateBooking({
    clubReference,
    updateState,
    setUpdateState,
  });

  const updateBooking = useDiverBookingPageUpdateBooking({
    updateState,
    setUpdateState,
  });

  return useMemo(() => {
    const hamburgerMenuItems: AppHamburgerMenuItem[] = [];
    hamburgerMenuItems.push({
      icon: AppHeroIcons.actionOpen,
      severity: 'info',
      title: 'Ouvrir',
      description: 'Ouvrir les détails de la réservation',
      onClick: () => {
        actions.openBooking(bookingTabModel.bookingId);
      },
    });

    if (sendMessageEnabled) {
      hamburgerMenuItems.push({
        icon: AppHeroIcons.sms,
        severity: 'info',
        title: 'Envoyer un MESSAGE',
        onClick: () => {
          actions.persistAndOpenCreateMessage(bookingTabModel);
        },
      });
    }

    if (az.edit.participant.booking) {
      hamburgerMenuItems.push({
        icon: AppHeroIcons.actionClone,
        severity: 'info',
        title: 'Dupliquer la réservation',
        description: 'Créer une nouvelle réservation avec les mêmes plongeurs',
        onClick: () => {
          const newBooking = duplicateBooking({
            bookingSource: bookingTabModel,
          });
          actions.openBooking(newBooking.booking._id);
        },
      });
      if (booking.active) {
        hamburgerMenuItems.push({
          icon: AppHeroIcons.actionArchive,
          severity: 'warn',
          title: 'Archiver la réservation',
          description: 'Vous ne pourrez plus ajouter de plongeur',
          onClick: () => {
            updateBooking({
              booking: bookingTabModel,
              attributesToUpdate: {
                active: false,
              },
            });
          },
        });
      } else {
        hamburgerMenuItems.push({
          icon: AppHeroIcons.actionArchive,
          severity: 'info',
          title: 'Ré-activer la réservation',
          description: 'Vous pourrez de nouveau ajouter des plongeurs',
          onClick: () => {
            updateBooking({
              booking: bookingTabModel,
              attributesToUpdate: {
                active: true,
              },
            });
          },
        });
      }
      hamburgerMenuItems.push({
        icon: AppHeroIcons.actionRemove,
        severity: 'danger',
        title: 'Supprimer cette réservation et ses plongées',
        description: 'ATTENTION: la suppression est définitive',
        onClick: () => {
          deleteBooking({
            booking: bookingTabModel,
          });
        },
      });
    }

    return hamburgerMenuItems.concat(extraMenuItems);
  }, [
    sendMessageEnabled,
    az.edit.participant.booking,
    extraMenuItems,
    actions,
    bookingTabModel,
    booking.active,
    duplicateBooking,
    updateBooking,
    deleteBooking,
  ]);
}
