import {
  ClubDiver,
  ClubPurchasePaymentPackage,
  CreditNote,
} from '@mabadive/app-common-model';
import {
  dataSorter,
  dateService,
  nameFormatter,
  paymentMethodFormatter,
  purchasePaymentStateFormatter,
} from '@mabadive/app-common-services';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import {
  AppButtonMultiActions,
  AppTransition,
} from 'src/business/_core/modules/layout';
import { AppIcons } from 'src/business/_core/modules/layout/icons';
import {
  useClubResume,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import { AppMultilineComment } from 'src/business/club/modules/_common/form';
import { AppMultilineCommentIcons } from 'src/business/club/modules/_common/form/components/AppMultilineCommentIcons';
import {
  AppHamburgerMenuItem,
  AppPriceLabel,
} from 'src/business/club/modules/_common/ui';
import { AgencyLabel } from 'src/business/club/modules/club-diver-participant/pages/DiverBookingPage/components/BookingLabelsComponents';
import { DiverBookingTitle } from 'src/business/club/modules/club-diver-participant/pages/DiverBookingPage/components/DiverBookingCard';
import {
  AggregatedBookingPurchasePaymentDepositDetails,
  AggregatedBookingPurchasePaymentWithDetails,
} from 'src/business/club/modules/club-diver-participant/pages/DiverBookingPage/models';
import { useAppCurrency } from 'src/pages/_components/options';
import { DiveCenterProBadge } from '../../DiveCenterProBadge';
import { PurchasePaymentCardCreditNote } from './PurchasePaymentCardCreditNote';
import { PurchasePaymentCardProduct_BookingDeposit } from './PurchasePaymentCardProduct_BookingDeposit';
import { PurchasePaymentCardProduct_PurchasePackage } from './PurchasePaymentCardProduct_PurchasePackage';
import { PurchasePaymentDueDate } from './PurchasePaymentDueDate';
import { PurchasePaymentOnlineDetails } from './PurchasePaymentOnlineDetails';
import { PurchasePaymentCardCreditNoteActions } from './model';

export const PurchasePaymentCard = ({
  creditNoteActions,
  hamburgerMenuItems = [],
  purchasePaymentWithDetails,
  creditNotes = [],
  bookingDepositsPaymentDetails: bookingDepositsPaymentDetailsInput = [],
  loadedDivers,
  isExpanded,
  setIsExpanded,
  className,
}: {
  hamburgerMenuItems?: AppHamburgerMenuItem[];
  purchasePaymentWithDetails: AggregatedBookingPurchasePaymentWithDetails;
  creditNoteActions?: PurchasePaymentCardCreditNoteActions;
  creditNotes?: CreditNote[];
  bookingDepositsPaymentDetails?: AggregatedBookingPurchasePaymentDepositDetails[];
  loadedDivers: Pick<ClubDiver, '_id' | 'lastName' | 'firstName'>[];
  isExpanded: boolean;
  setIsExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  className?: string;
}) => {
  const diveCenterResume = useDiveCenterResume();
  const clubSettings = diveCenterResume?.clubResume?.clubSettings;

  const purchasePayment = purchasePaymentWithDetails?.purchasePayment;

  const diver = purchasePaymentWithDetails?.diver;
  const bookingResume = purchasePaymentWithDetails?.bookingResume;

  const paymentPackages = useMemo(() => {
    return dataSorter.sortMultiple(purchasePayment.purchasePaymentPackages, {
      getSortAttributes: (x: ClubPurchasePaymentPackage) => [
        {
          value: x.purchasePackage.purchaseDate,
          type: 'default',
        },
        {
          value: x.purchaseProductPackage?.reference,
          type: 'full-text',
        },
        {
          value: x.purchasePackage._id,
          type: 'full-text',
        },
      ],
      asc: true,
    });
  }, [purchasePayment.purchasePaymentPackages]);

  const bookingDepositsPaymentDetails = useMemo(() => {
    return dataSorter.sortMultiple(bookingDepositsPaymentDetailsInput, {
      getSortAttributes: (
        x: AggregatedBookingPurchasePaymentDepositDetails,
      ) => [
        {
          value: x.bookingDeposit.depositDate,
          type: 'default',
        },
        {
          value: x.bookingDeposit._createdAt,
          type: 'default',
        },
        {
          value: x.bookingDeposit._id,
          type: 'full-text',
        },
      ],
      asc: true,
    });
  }, [bookingDepositsPaymentDetailsInput]);

  const paymentCurrency = useAppCurrency(purchasePayment.usedCurrencyIsoCode);

  const paymentOrCreationDate =
    purchasePayment.paymentDate ?? purchasePayment.creationDate;

  const statusColors: {
    textLight: string;
    text: string;
    textDark: string;
  } = useMemo(() => {
    if (purchasePayment.paymentState === 'pending') {
      return {
        textLight: 'text-status-warn-light',
        text: 'text-status-warn',
        textDark: 'text-status-warn-dark',
      };
    } else if (purchasePayment.paymentState === 'authorized') {
      return {
        textLight: 'text-status-info-light',
        text: 'text-status-info',
        textDark: 'text-status-info-dark',
      };
    } else if (purchasePayment.paymentState === 'validated') {
      return {
        textLight: 'text-status-success-light',
        text: 'text-status-success',
        textDark: 'text-status-success-dark',
      };
    } else {
      return {
        textLight: 'text-gray-400',
        text: 'text-gray-500',
        textDark: 'text-gray-600',
      };
    }
  }, [purchasePayment.paymentState]);

  const clubResume = useClubResume();
  const isMultiDiveCenters = clubResume.diveCenters.length > 1;

  const paymentDiveCenter = useMemo(
    () =>
      clubResume.diveCenters.find(
        (dc) => dc._id === purchasePayment?.diveCenterId,
      ),
    [clubResume.diveCenters, purchasePayment?.diveCenterId],
  );

  return (
    <div
      className={clsx('cursor-pointer text-gray-600', className)}
      onClick={() => setIsExpanded(!isExpanded)}
    >
      <div className="grid gap-2">
        <div className="flex gap-2">
          <div className="hidden md:block">
            {purchasePayment.paymentState === 'pending' && (
              <AppIcons.ui.statusPending
                className={`h-10 w-10 ${statusColors.textLight}`}
              />
            )}
            {purchasePayment.paymentState === 'authorized' && (
              <AppIcons.ui.statusAuthorized
                className={`h-10 w-10 ${statusColors.textLight}`}
              />
            )}
            {purchasePayment.paymentState === 'validated' && (
              <AppIcons.ui.statusValidated
                className={`h-10 w-10 ${statusColors.textLight}`}
              />
            )}
          </div>
          <div className="w-full flex gap-4 justify-between">
            <div className="w-full flex gap-4 flex-wrap justify-start">
              <div className="flex flex-wrap gap-x-4 min-w-[50%] lg:min-w-[40%]">
                <div className="text-xs md:text-sm font-medium">
                  <div className="flex gap-2 items-center">
                    <AppPriceLabel
                      className={statusColors.textLight}
                      amount={purchasePayment.usedCurrencyAmount}
                      mainCurrency={paymentCurrency}
                    />
                    {purchasePayment?.paymentState === 'pending' && (
                      <AppIcons.ui.statusPendingSolid className="w-4 h-4 bg-app-red text-white" />
                    )}
                    {/* {purchasePayment?._id} */}
                  </div>

                  <div className="text-gray-500 uppercase text-xs">
                    <div className={statusColors.textDark}>
                      {purchasePaymentStateFormatter.formatPaymentState(
                        purchasePayment.paymentState,
                      )}
                    </div>
                  </div>
                </div>
                <div
                  className={clsx(
                    'text-xs md:text-sm font-normal  text-gray-500',
                    // !isExpanded && 'hidden xs:block ',
                  )}
                >
                  <div className="grid gap-1 text-xs ">
                    <div className="font-medium uppercase">
                      {paymentMethodFormatter.formatPaymentMethod(
                        purchasePayment.paymentMethod,
                      )}
                      <span className="font-normal italic">
                        {' '}
                        {purchasePayment.paymentReference}
                      </span>
                    </div>
                    {purchasePayment.paymentLink && (
                      <a
                        className="underline text-app-primary text-xs font-medium"
                        href={purchasePayment.paymentLink}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {purchasePayment.paymentLink}
                      </a>
                    )}
                    <div
                      className={clsx(
                        'text-gray-500',
                        !isExpanded && 'hidden xs:block ',
                      )}
                    >
                      {dateService.formatUTC(
                        paymentOrCreationDate,
                        'DD/MM/YYYY',
                      )}
                    </div>
                  </div>
                  <div className="text-gray-500 uppercase text-xs">
                    <PurchasePaymentDueDate purchasePayment={purchasePayment} />
                  </div>
                </div>
                {!isExpanded && (
                  <div className="hidden ml-2 lg:flex gap-1 items-center text-gray-400 uppercase text-xs">
                    {isExpanded ? (
                      <>
                        <AppIcons.action.extend className="cursor-pointer mr-1 h-5 w-5 lg:h-5 lg:w-5 text-gray-400 group-hover:text-gray-600" />
                      </>
                    ) : (
                      <>
                        <AppIcons.action.reduce className="cursor-pointer mr-1 h-5 w-5 lg:h-5 lg:w-5 text-gray-400 group-hover:text-gray-600" />
                      </>
                    )}
                    détails
                  </div>
                )}
              </div>
              <div className="text-xs md:text-sm font-medium text-gray-500">
                {purchasePayment?.payedToBookingAgencyId && (
                  <AgencyLabel
                    agencyId={purchasePayment?.payedToBookingAgencyId}
                    className="text-xs font-bold text-gray-600"
                  />
                )}
                {diver && (
                  <div className="">
                    {nameFormatter.formatFullName(diver, {
                      format: 'firstName-first',
                    })}
                  </div>
                )}
                {bookingResume && isExpanded && (
                  <DiverBookingTitle
                    className="text-xs xl:text-sm font-normal text-gray-500"
                    datesRange={bookingResume.datesRange}
                    bookingGroup={bookingResume.booking?.bookingGroup}
                  ></DiverBookingTitle>
                )}
                {(isMultiDiveCenters || !isExpanded) && (
                  <div className="flex gap-2 items-center">
                    {isMultiDiveCenters && (
                      <DiveCenterProBadge
                        className="inline-block"
                        diveCenter={paymentDiveCenter}
                      />
                    )}
                    {!isExpanded && (
                      <AppMultilineCommentIcons
                        className="block opacity-50"
                        showPrivate={purchasePayment.comment?.trim().length > 0}
                        showpublic={
                          purchasePayment.customerComment?.trim().length > 0
                        }
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="text-gray-600">
              <AppButtonMultiActions items={hamburgerMenuItems} />
            </div>
          </div>
        </div>
        {creditNotes.map((creditNote) => (
          <PurchasePaymentCardCreditNote
            key={creditNote._id}
            className="border border-gray-200 p-1"
            creditNote={creditNote}
            purchasePaymentWithDetails={purchasePaymentWithDetails}
            actions={creditNoteActions}
          />
        ))}
        <AppTransition type="scale" show={isExpanded}>
          <div className="grid gap-2">
            {(purchasePayment.comment?.trim()?.length > 0 ||
              purchasePayment.customerComment?.trim()?.length > 0) && (
              <div className="border-t border-gray-100 pt-2 flex gap-2 md:flex-row md:flex-wrap md:gap-4">
                <AppMultilineComment
                  type="private"
                  comment={purchasePayment.comment}
                />
                <AppMultilineComment
                  type="public"
                  comment={purchasePayment.customerComment}
                />
              </div>
            )}
            {paymentPackages.length > 0 && (
              <ul className="border-t border-gray-100 pt-2 grid md:grid-cols-2 xl:grid-cols-3">
                {paymentPackages.map((purchasePaymentPackage, i) => (
                  <PurchasePaymentCardProduct_PurchasePackage
                    key={i}
                    purchasePaymentPackage={purchasePaymentPackage}
                    clubSettings={clubSettings}
                    paymentCurrency={paymentCurrency}
                    loadedDivers={loadedDivers}
                  />
                ))}
              </ul>
            )}
            {bookingDepositsPaymentDetails.length > 0 && (
              <ul className="border-t border-gray-100 pt-2 grid md:grid-cols-2 xl:grid-cols-3">
                {bookingDepositsPaymentDetails.map(
                  (bookingDepositPaymentDetails, i) => (
                    <PurchasePaymentCardProduct_BookingDeposit
                      key={i}
                      bookingDepositPaymentDetails={
                        bookingDepositPaymentDetails
                      }
                      clubSettings={clubSettings}
                      paymentCurrency={paymentCurrency}
                      loadedDivers={loadedDivers}
                    />
                  ),
                )}
              </ul>
            )}
            <PurchasePaymentOnlineDetails
              className="border-t border-gray-100 pt-2 "
              purchasePayment={purchasePayment}
            />
          </div>
        </AppTransition>
      </div>
    </div>
  );
};
