/* eslint-disable @typescript-eslint/no-unused-vars */
import { RegionalSettings } from '@mabadive/app-common-model';
import React from 'react';
import { useAppSecurityUserHasRole } from 'src/business/auth/services';
import {
  useClubResume,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import { AppFeatureBadge } from 'src/business/club/modules/_common/ui/AppFeatureBadge';
import { ClubSettingsSection } from 'src/pages/SE-settings/_core';
import {
  useAppCurrencyMain,
  useRegionalSettings,
} from 'src/pages/_components/options';
import {
  ClubDialogsState,
  UseClubDialogsProps,
  useClubDialogs,
  useClubDialogsActionsPersist,
} from 'src/pages/_dialogs';

export const ClubSettingsOnlineBookingMainViewPanel_General = () => {
  const diveCenterResume = useDiveCenterResume();
  const clubResume = useClubResume();

  const clubSettings = clubResume?.clubSettings;

  const actionsPersist: UseClubDialogsProps = useClubDialogsActionsPersist();
  const dialogsState: ClubDialogsState = useClubDialogs(actionsPersist);

  const regionalSettings: RegionalSettings = useRegionalSettings({
    effectiveDate: new Date(),
  });

  const mainCurrency = useAppCurrencyMain();
  const isSuperAdmin = useAppSecurityUserHasRole('super-admin');

  return (
    <ClubSettingsSection
      title="Configuration générale"
      comment="Ajouter un bouton sur votre site ou affichez un QR Code dans vos locaux"
    >
      <AppFeatureBadge
        enabled={clubSettings.publicSettings?.customerSpace?.enabled}
        label="Espace client"
      />
      <AppFeatureBadge
        enabled={clubSettings.general?.onlineBooking?.enabled}
        label="Vente / réservation en ligne"
      />
      {clubSettings.general?.onlineBooking?.enabled && (
        <>
          <AppFeatureBadge
            enabled={
              clubSettings.publicSettings?.customerSpace?.onlineBooking
                ?.enableCatalog
            }
            label="Activer le catalogue"
          />{' '}
          <AppFeatureBadge
            enabled={
              clubSettings.publicSettings?.customerSpace?.onlineBooking
                ?.showCatalogButtonFromHome
            }
            label={'Afficher bouton "Découvrir nos activités"'}
            label2={
              'Ce bouton s\'affiche sur la page d\'accueil de l\'espace client, quand on se déconnecte.'
            }
          />{' '}
          <AppFeatureBadge
            enabled={
              clubSettings.publicSettings?.customerSpace?.onlineBooking
                ?.enableBooking
            }
            label="Activer la réservation en ligne"
            label2='Affiche le bouton "Réserver" sur les fiches produit'
          />
          <AppFeatureBadge
            enabled={clubSettings.general?.onlineBooking?.enableProOrdersList}
            label="Afficher la liste des commandes en ligne sur l'espace PRO"
          />
        </>
      )}
      <AppFeatureBadge
        enabled={clubSettings.general?.deposits?.enabled}
        label="Acomptes"
      />
    </ClubSettingsSection>
  );
};
