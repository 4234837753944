import React, { useMemo } from 'react';
import { PRO_BookingResume } from 'src/business/club/modules/club-diver-participant/pages/DiverBookingPage/models';
import { useClubSettings } from '../../../../../data/hooks';
import { DiveCenterBookingMembersListDiffItem } from './DiveCenterBookingMembersListDiffItem';
import { DiveCenterBookingMemberDiff } from './model';

// Note: composant copié et adapté à partir de "DiveCenterBookingMembersList" sur l'espace plongeur
export function DiveCenterBookingMembersDiffList({
  aggregatedBookingResume,
  membersDiff,
  className,
}: {
  aggregatedBookingResume: PRO_BookingResume;
  membersDiff: DiveCenterBookingMemberDiff[];
  className?: string;
}) {
  const clubSettings = useClubSettings();
  const showProfilePhoto = useMemo(() => {
    if (clubSettings?.customer?.general?.profilePhoto) {
      // at least one profile with photo

      return (
        membersDiff.find(
          (x) =>
            x.original?.diver?.details?.profilePhoto?._id ||
            x.final?.diver?.details?.profilePhoto?._id,
        ) !== undefined
      );
    }
    return false;
  }, [clubSettings?.customer?.general?.profilePhoto, membersDiff]);

  return (
    <>
      <div className={`overflow-hidden text-xs ${className}`}>
        <ul className="divide-y divide-gray-200">
          {membersDiff.map((memberDiff, participantsIndex) => (
            <div
              className="my-4 grid lg:grid-cols-2 gap-4"
              key={`${memberDiff.original?.diver?._id}-${memberDiff.final?.diver?._id}`}
            >
              <DiveCenterBookingMembersListDiffItem
                className="hidden lg:block"
                mode="original"
                showProfilePhoto={showProfilePhoto}
                aggregatedBookingResume={aggregatedBookingResume}
                memberDiff={memberDiff}
              />
              <DiveCenterBookingMembersListDiffItem
                mode="final"
                showProfilePhoto={showProfilePhoto}
                aggregatedBookingResume={aggregatedBookingResume}
                memberDiff={memberDiff}
              />
            </div>
          ))}
        </ul>
      </div>
    </>
  );
}
