/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { useClubResume } from 'src/business/club/data/hooks';
import { AppMessageLight } from '../../../../../../../business/_core/modules/layout';
import { ClubSettingsInquiryEditPagePanelLocalState } from '../../ClubSettingsInquiryEditPagePanel';
import { AppInquiryFormModel } from '../model';
import { DiveCenterBookingInquiryResponsePage_FillFormPanel } from './DiveCenterBookingInquiryResponsePage_FillFormPanel';
import {
  ClubSettingsInquiryEditPageForm_TabPreviewLocalState,
  useClubSettingsInquiryEditPageForm_TabPreviewLocalState,
} from './useClubSettingsInquiryEditPageForm_TabPreviewLocalState.state';

export const ClubSettingsInquiryEditPageForm_TabPreview = ({
  localState: parentState,
  form,
  className,
}: {
  localState: ClubSettingsInquiryEditPagePanelLocalState;
  form: UseFormReturn<AppInquiryFormModel>;
  className?: string;
}) => {
  const { state, data } = parentState;

  const clubResume = useClubResume();
  const isMultiDiveCenters = clubResume.diveCenters.length > 1;

  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const [color, inquiryType, enabled, autoActivationEnabled] = useWatch({
    control,
    name: [
      'settings.color',
      'type',
      'settings.enabled',
      'settings.autoActivation.enabled',
    ],
  });

  const localState: ClubSettingsInquiryEditPageForm_TabPreviewLocalState =
    useClubSettingsInquiryEditPageForm_TabPreviewLocalState({ parentState });

  return (
    <div className="grid gap-4">
      <AppMessageLight>
        Voici un aperçu du formulaire que pourront remplir vos clients.
      </AppMessageLight>
      <DiveCenterBookingInquiryResponsePage_FillFormPanel
        localState={localState}
      />
    </div>
  );
};
