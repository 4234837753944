/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import {
  useClubResume,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import { AppFormControlRHF_Deprecated } from 'src/lib/form/components/AppFormControl/AppFormControlRHF_Deprecated';

import { ClubOfferState } from '@mabadive/app-common-model';
import { AppIconsSocialMedia } from 'src/business/_core/modules/layout/icons';
import { AppInputBooleanSwitchRHF, AppInputRHF } from 'src/lib/form';
import { ClubSettingsSection } from 'src/pages/SE-settings/_core';
import {
  ClubSettingsCommunicationDialogInitialState,
  ClubSettingsCommunicationFormModel,
} from '../../../_model';
import { ClubSettingsCommunicationMain_EmailAutoMessage } from '../_common';

export const ClubSettingsCommunicationMainForm_Channels = ({
  form,
  initialState,
}: {
  form: UseFormReturn<ClubSettingsCommunicationFormModel>;
  initialState: ClubSettingsCommunicationDialogInitialState;
}) => {
  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const clubResume = useClubResume();

  const diveCenterResume = useDiveCenterResume();
  const defaultEmailAddress =
    diveCenterResume?.publicData?.contact?.emailAddress;

  const defaultFromName = diveCenterResume?.publicData?.brand?.name;

  const [autoModeEnabled, autoModeFromName, autoModeReplyToAddress] = useWatch({
    control,
    name: [
      'clubSettings.communication.channels.email.directMode.directManualEnabled',
      'clubSettings.communication.channels.email.directMode.fromName',
      'clubSettings.communication.channels.email.directMode.replyToAddress',
    ],
  });

  const trustedStates: ClubOfferState[] = ['trial', 'active', 'demo'];
  const isTrustedClub = trustedStates.includes(clubResume?.offer?.state);

  return (
    <ClubSettingsSection
      title="Moyens de communication"
      comment="De quelle manière souhaitez-vous communiquer avec vos clients"
    >
      <div className="grid gap-y-4">
        <div className="flex flex-wrap gap-x-4 gap-y-2">
          <AppFormControlRHF_Deprecated
            control={control}
            name={'clubSettings.communication.channels.email.enabled'}
            renderComponent={(props) => (
              <AppInputBooleanSwitchRHF
                {...props}
                label={'Email'}
                icon={
                  <AppIconsSocialMedia.mail className="h-6 w-6 text-app-mail" />
                }
              />
            )}
          />
          <AppFormControlRHF_Deprecated
            control={control}
            name={'clubSettings.communication.channels.sms.enabled'}
            renderComponent={(props) => (
              <AppInputBooleanSwitchRHF
                {...props}
                label={'SMS'}
                icon={
                  <AppIconsSocialMedia.sms className="h-6 w-6 text-app-sms" />
                }
              />
            )}
          />
          <AppFormControlRHF_Deprecated
            control={control}
            name={'clubSettings.communication.channels.phone.enabled'}
            renderComponent={(props) => (
              <AppInputBooleanSwitchRHF
                {...props}
                label={'Téléphone (appel)'}
                icon={
                  <AppIconsSocialMedia.phone className="h-6 w-6 text-app-sms" />
                }
              />
            )}
          />
          <AppFormControlRHF_Deprecated
            control={control}
            name={'clubSettings.communication.channels.whatsApp.enabled'}
            renderComponent={(props) => (
              <AppInputBooleanSwitchRHF
                {...props}
                label={'WhatsApp'}
                icon={
                  <AppIconsSocialMedia.whatsapp className="h-5 w-5 text-app-whatsapp" />
                }
              />
            )}
          />
        </div>
        <div className="flex flex-wrap gap-x-4 gap-y-2">
          <AppFormControlRHF_Deprecated
            control={control}
            label={'Envoi direct ⚡'}
            name={
              'clubSettings.communication.channels.email.directMode.directManualEnabled'
            }
            disabled={!isTrustedClub}
            helpDescription={
              isTrustedClub
                ? undefined
                : 'Fonctionnalité désactivée sur les comptes de démo'
            }
            renderComponent={(props) => (
              <AppInputBooleanSwitchRHF
                {...props}
                label="email"
                icon={
                  <AppIconsSocialMedia.mail className="h-6 w-6 text-app-mail" />
                }
                onChange={(autoModeEnabled) => {
                  setValue(
                    'clubSettings.communication.booking.email.mode',
                    autoModeEnabled ? 'auto' : 'manual',
                  );
                  setValue(
                    'clubSettings.communication.session.email.mode',
                    autoModeEnabled ? 'auto' : 'manual',
                  );
                  if (autoModeEnabled) {
                    if (defaultFromName && !autoModeFromName) {
                      setValue(
                        'clubSettings.communication.channels.email.directMode.fromName',
                        defaultFromName,
                        {
                          shouldDirty: true,
                          shouldTouch: true,
                          shouldValidate: true,
                        },
                      );
                    }
                    if (defaultEmailAddress && !autoModeReplyToAddress) {
                      setValue(
                        'clubSettings.communication.channels.email.directMode.replyToAddress',
                        defaultEmailAddress,
                        {
                          shouldDirty: true,
                          shouldTouch: true,
                          shouldValidate: true,
                        },
                      );
                    }
                  }
                }}
              />
            )}
          />
          {autoModeEnabled && (
            <>
              <AppFormControlRHF_Deprecated
                control={control}
                className="w-80"
                name={
                  'clubSettings.communication.channels.email.directMode.fromName'
                }
                label={'Nom de l\'expéditeur'}
                required={autoModeEnabled}
                helpDescription="Quel est le nom de l'expéditeur dans l'email reçu par les clients"
                renderComponent={(props) => (
                  <AppInputRHF className="w-full" type="text" {...props} />
                )}
              />
              <AppFormControlRHF_Deprecated
                control={control}
                className="w-80"
                name={
                  'clubSettings.communication.channels.email.directMode.replyToAddress'
                }
                label={'Adresse de réponse'}
                required={autoModeEnabled}
                helpDescription="A quelle adresse les clients peuvent-ils répondre aux e-mails?"
                validation={{
                  email: true,
                }}
                renderComponent={(props) => (
                  <AppInputRHF className="w-full" type="text" {...props} />
                )}
              />
              <AppFormControlRHF_Deprecated
                control={control}
                className="w-80"
                name={
                  'clubSettings.communication.channels.email.directMode.copyToAddress'
                }
                label={'Recevoir une copie à'}
                required={false}
                helpDescription="Recevoir une copie de chaque mail à cette adresse"
                validation={{
                  email: true,
                }}
                renderComponent={(props) => (
                  <AppInputRHF className="w-full" type="text" {...props} />
                )}
              />
            </>
          )}
        </div>
        <ClubSettingsCommunicationMain_EmailAutoMessage />
      </div>
    </ClubSettingsSection>
  );
};
