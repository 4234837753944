import React, { useMemo, useState } from 'react';
import {
  AppButton,
  AppMessage,
} from 'src/business/_core/modules/layout/components/_tailwind';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { usePageWidth } from 'src/business/_core/modules/layout/services/usePageWidth.hook';
import { ClubDialogsStateOld } from 'src/pages/_dialogs';
import {
  AggregatedBookingSessionFull,
  BookingResumeParticipantsBySession,
  BookingTabModel,
  PRO_BookingMemberFull_WithDocs,
  PRO_BookingParticipantFull,
} from '../../models';
import { DiverBookingPageActions } from '../../useDiverBookingPageActions.hook';
import { DiverBookingPageGlobalState } from '../../useDiverBookingPageGlobalState.hook';
import { DiverBookingCardPastSessionsTableDay } from './DiverBookingCardPastSessionsTableDay';

export function DiverBookingCardPastSessionsTable({
  globalState,
  dialogs,
  actions,
  bookingTabModel,
  participantsBySessions,
  futureSessionsCount,
  onClickSession,
  onClickParticipant,
  filteredDiversIds,
  className,
  displayName,
  displayLastName,
  displaySessionName,
  displayDiveSite,
}: {
  globalState: DiverBookingPageGlobalState;
  dialogs: ClubDialogsStateOld;
  actions: DiverBookingPageActions;
  bookingTabModel: BookingTabModel;
  displayName?: boolean;
  displayLastName?: boolean;
  displaySessionName?: boolean;
  displayDiveSite?: boolean;
  participantsBySessions: BookingResumeParticipantsBySession[];
  futureSessionsCount: number;
  onClickSession: (bookingSessionFull: AggregatedBookingSessionFull) => void;
  onClickParticipant: (props: {
    // attention: il peut s'agir d'un autre booking que le booking affiché ici!
    bookingSessionFull: AggregatedBookingSessionFull;
    bookingParticipantFull: PRO_BookingParticipantFull;
    bookingMemberFull: PRO_BookingMemberFull_WithDocs;
  }) => void;
  filteredDiversIds: string[];

  className?: string;
}) {
  const pageWidth = usePageWidth({ ignoreMenu: false });

  const pageWidthDefaultMaxVisibleSessionsCount = useMemo(() => {
    const chunks = Math.floor(pageWidth / 100);
    return chunks * 2 - 1; // 2 rows (-1 because "+session" button)
  }, [pageWidth]);

  const defaultMaxVisibleSessionsCount = useMemo(() => {
    const max = Math.max(
      3,
      Math.min(
        pageWidthDefaultMaxVisibleSessionsCount,
        futureSessionsCount +
          (futureSessionsCount !== participantsBySessions.length ? 3 : 0),
      ),
    );
    if (max === participantsBySessions.length - 1) {
      // only one session remaining: display it!
      return max + 1;
    }
    return max;
  }, [
    futureSessionsCount,
    pageWidthDefaultMaxVisibleSessionsCount,
    participantsBySessions.length,
  ]);

  const [displayAllParticipants, setDisplayAllParticipants] = useState(
    participantsBySessions.length <= defaultMaxVisibleSessionsCount,
  );

  const visibleParticipantsBySessions = useMemo(
    () =>
      displayAllParticipants
        ? participantsBySessions
        : participantsBySessions.slice(0, defaultMaxVisibleSessionsCount),
    [
      defaultMaxVisibleSessionsCount,
      displayAllParticipants,
      participantsBySessions,
    ],
  );

  const hiddenSessionsCount =
    participantsBySessions.length - visibleParticipantsBySessions.length;

  // NOTE: min-h-[250px] permet d'éviter un bug quand on ouvre le menu d'une journée

  return (
    <>
      <div
        className={`flex flex-row-reverse flex-wrap justify-end items-start justify-items-start max-w-full divide-y divide-gray-100 ${
          className ?? ''
        }`}
      >
        {visibleParticipantsBySessions.map(
          ({ bookingSessionFull, participants }, j) => (
            <DiverBookingCardPastSessionsTableDay
              className="my-2"
              key={j}
              globalState={globalState}
              dialogs={dialogs}
              actions={actions}
              bookingTabModel={bookingTabModel}
              bookingSessionFull={bookingSessionFull}
              participants={participants}
              filteredDiversIds={filteredDiversIds}
              onClickSession={onClickSession}
              onClickParticipant={onClickParticipant}
              displayName={displayName}
              displayLastName={displayLastName}
              displaySessionName={displaySessionName}
              displayDiveSite={displayDiveSite}
            />
          ),
        )}
      </div>

      {hiddenSessionsCount > 0 && (
        <AppMessage
          className="my-5"
          type="info"
          title={`${defaultMaxVisibleSessionsCount}/${participantsBySessions.length} plongées affichées`}
          // message={
          //   <h3 className="text-sm sm:text-base">
          //     Seules les {defaultMaxVisibleSessionsCount} premières plongées
          //     sont affichées ({hiddenSessionsCount} masquées).
          //   </h3>
          // }
        >
          <AppButton
            color="primary-outline-light"
            icon={AppHeroIcons.eye}
            onClick={() => setDisplayAllParticipants(true)}
          >
            Afficher les {hiddenSessionsCount} autres plongées
          </AppButton>
        </AppMessage>
      )}
    </>
  );
}
