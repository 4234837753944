import { ValueLabel } from '../../../../../club/modules/_common/form';
import { AppLibIconRef } from './AppLibIconRef.type';
import { AppLibIcons } from './AppLibIcons.type';

export const APP_LIB_ICONS_OPTIONS: ValueLabel<AppLibIconRef>[] = [
  {
    value: 'home',
    icon: AppLibIcons.home,
    label: 'Maison',
  },
  {
    value: 'home2',
    icon: AppLibIcons.home2,
    label: 'Maison Moderne',
  },
  {
    value: 'identification',
    icon: AppLibIcons.identification,
    label: 'Identification',
  },
  {
    value: 'plane',
    icon: AppLibIcons.plane,
    label: 'Avion',
  },
  {
    value: 'gift',
    icon: AppLibIcons.gift,
    label: 'Cadeau',
  },
  {
    value: 'info',
    icon: AppLibIcons.info,
    label: 'Information',
  },
  {
    value: 'lock',
    icon: AppLibIcons.lock,
    label: 'Verrou',
  },
  {
    value: 'key',
    icon: AppLibIcons.key,
    label: 'Clé',
  },
  {
    value: 'light',
    icon: AppLibIcons.light,
    label: 'Ampoule',
  },
  {
    value: 'location',
    icon: AppLibIcons.location,
    label: 'Localisation',
  },
  {
    value: 'no',
    icon: AppLibIcons.no,
    label: 'Interdiction',
  },
  {
    value: 'photo',
    icon: AppLibIcons.photo,
    label: 'Photo',
  },
  {
    value: 'check',
    icon: AppLibIcons.check,
    label: 'Validation',
  },
  {
    value: 'exclam',
    icon: AppLibIcons.exclam,
    label: 'Attention',
  },
  {
    value: 'shop',
    icon: AppLibIcons.shop,
    label: 'Boutique',
  },
  {
    value: 'squares',
    icon: AppLibIcons.squares,
    label: 'Carrés',
  },
  {
    value: 'user',
    icon: AppLibIcons.user,
    label: 'Utilisateur',
  },
  {
    value: 'wrench',
    icon: AppLibIcons.wrench,
    label: 'Outils',
  },
  {
    value: 'bell',
    icon: AppLibIcons.bell,
    label: 'Notification',
  },
  {
    value: 'star',
    icon: AppLibIcons.star,
    label: 'Étoile',
  },
];
