/* eslint-disable @typescript-eslint/no-unused-vars */
import clsx from 'clsx';
import React from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { useClubResume } from 'src/business/club/data/hooks';
import {
  AppFormControlRHF,
  AppInputBooleanSwitchRHF,
  AppInputRHF,
} from 'src/lib/form';
import { AppMessageLight } from '../../../../../../business/_core/modules/layout';
import { useAppSecurityUserHasRole } from '../../../../../../business/auth/services';
import { AppMultiCheckboxesTailwindRHF } from '../../../../../../lib/form/components/AppMultiCheckboxes/AppMultiCheckboxesTailwindRHF';
import { useDiveModesOptions } from '../../../../../_components/options';
import { ClubSettingsInquiryEditPagePanelLocalState } from '../ClubSettingsInquiryEditPagePanel/useClubSettingsInquiryEditPagePanelLocalState.hook';
import { AppInquiryFormModel } from './model';

export const ClubSettingsInquiryEditPageForm_TabActivationSettings = ({
  localState,
  form,
  className,
}: {
  localState: ClubSettingsInquiryEditPagePanelLocalState;
  form: UseFormReturn<AppInquiryFormModel>;
  className?: string;
}) => {
  const { state, data } = localState;

  const clubResume = useClubResume();
  const isMultiDiveCenters = clubResume.diveCenters.length > 1;

  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const [
    color,
    inquiryType,
    enabled,
    autoActivationEnabled,
    futurDiveModesEnabled,
  ] = useWatch({
    control,
    name: [
      'settings.color',
      'type',
      'settings.enabled',
      'settings.autoActivation.enabled',
      'settings.autoActivation.futurDiveModesEnabled',
    ],
  });

  const diveModesOptions = useDiveModesOptions({
    type: 'all',
    format: 'long-label',
  });

  const isSuperAdmin = useAppSecurityUserHasRole('super-admin');

  return (
    <div
      className={clsx('app-card p-4 border-t-8', className)}
      style={{
        borderColor: color,
      }}
    >
      <div className="grid gap-4">
        <div
          className={clsx('w-full grid sm:grid-cols-2 md:grid-cols-4 gap-4')}
        >
          <div className="md:col-span-2 2xl:col-span-1 flex gap-4 justify-between">
            {enabled && (
              <AppFormControlRHF
                className={'w-full'}
                label="Activation automatique"
                control={control}
                name={'settings.autoActivation.enabled'}
                renderComponent={(props) => (
                  <AppInputBooleanSwitchRHF
                    {...props}
                    className="mt-1"
                    label={'Activé'}
                    labelToggleFalse="Désactivé"
                  />
                )}
              />
            )}
          </div>

          {enabled && autoActivationEnabled && (
            <>
              <div className="md:col-span-2 2xl:col-span-1 grid grid-cols-2 gap-4">
                <AppFormControlRHF
                  className={'w-full'}
                  label="Âge mini"
                  control={control}
                  validation={{
                    rules: {
                      min: 0,
                    },
                  }}
                  name={'settings.autoActivation.age.min'}
                  required={false}
                  renderComponent={(props) => (
                    <AppInputRHF {...props} fullWidth type="number" />
                  )}
                />
                <AppFormControlRHF
                  className={'w-full'}
                  label="Âge maxi"
                  control={control}
                  validation={{
                    rules: {
                      min: 0,
                    },
                  }}
                  name={'settings.autoActivation.age.max'}
                  required={false}
                  renderComponent={(props) => (
                    <AppInputRHF {...props} fullWidth type="number" />
                  )}
                />
              </div>
            </>
          )}
        </div>
        {enabled && autoActivationEnabled && (
          <>
            <div className="grid gap-2">
              <h2 className="flex gap-2">
                <AppInputBooleanSwitchRHF
                  control={control}
                  name={'settings.autoActivation.futurDiveModesEnabled'}
                  theme="secondary"
                  label={
                    <span
                      className={clsx(
                        'text-app-secondary',
                        !futurDiveModesEnabled && 'opacity-50',
                      )}
                    >
                      Plongée à venir
                    </span>
                  }
                />
              </h2>
              {futurDiveModesEnabled && (
                <div className="border-l border-app-secondary pl-4 grid gap-2">
                  <div className="text-app-secondary-dark mb-2">
                    Activer uniquement pour les prestations suivantes:
                  </div>
                  <AppFormControlRHF
                    className={'md:col-span-3 2xl:col-span-2 w-full'}
                    label={'Prestations à venir'}
                    control={control}
                    name={'settings.autoActivation.diveModes'}
                    renderComponent={(props) => (
                      <AppMultiCheckboxesTailwindRHF
                        {...props}
                        // gridLayoutModel={'grid-cols-2 => xl:grid-cols-6'}
                        options={diveModesOptions}
                        labelPosition="right"
                      />
                    )}
                  />
                </div>
              )}
            </div>
          </>
        )}
        {/* {enabled && autoActivationEnabled && (
          <AppFormControlRHF
            className={'w-full'}
            label="Activer sur les profils sans prestation"
            control={control}
            name={'settings.autoActivation.diverWithoutAnyDiveEnabled'}
            renderComponent={(props) => (
              <AppInputBooleanSwitchRHF
                {...props}
                className="mt-1"
                label={'Activé'}
                labelToggleFalse="Désactivé"
              />
            )}
          />
        )} */}
        <AppMessageLight type="info" className="mt-4 mb-2 text-sm md:text-base">
          <div className="grid gap-2">
            <p>
              L'activation automatique permet d'activer automatiquement les
              questionnaires suivant l'âge ou les prestations à venir des
              participants.
            </p>
            <div>
              <p className="font-bold">Exemple:</p>
              <ul className="ml-4 list-disc">
                <li>
                  les enfants de moins de 12 ans qui effectuent un baptême
                </li>
                <li>les plongeurs en formation</li>
              </ul>
            </div>
            <p>
              Vous pouvez également activer ou désactiver chaque formulaire
              manuellement pour chaque participant
            </p>
          </div>
        </AppMessageLight>
      </div>
    </div>
  );
};
