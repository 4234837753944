import {
  AppCompanyMessageContext,
  AppMessageParamsRecipientType,
} from '@mabadive/app-common-model';
import { dateService } from '@mabadive/app-common-services';
import { useMemo } from 'react';
import {
  UseAppMessageParamsArgs,
  useAppMessageParams,
} from './useAppMessageParams.hook';

export function useAppMessageParamsFake({
  context,
  recipientType,
}: {
  context: AppCompanyMessageContext | 'none';
  recipientType: AppMessageParamsRecipientType;
}): { [key: string]: string } {
  const args: UseAppMessageParamsArgs = useMemo(() => {
    const args: UseAppMessageParamsArgs = {
      context,
      recipientType,
    };
    if (context === 'booking') {
      args.bookingContext = {
        customerSpaceAuthenticationUrl: 'https://diver.mabadive.com/xxx',
      };
      const arrivalDate = dateService.add(
        dateService.getUTCDateSetTime(new Date()),
        -3,
        'day',
      );
      args.bookingContext.bookingJourney = {
        steps: [
          {
            arrival: {
              date: arrivalDate,
            },
            departure: {
              date: dateService.add(arrivalDate, 7, 'day'),
            },
          },
        ],
      };
      if (recipientType === 'personal') {
        args.bookingContext.diver = {
          firstName: 'Sophie',
          lastName: 'Dupont',
        };
      }
    }
    if (context === 'session') {
      args.sessionContext = {
        diveSession: {
          time: dateService.getUTCDateSetTime(new Date(), 8),
        },
      };
      if (recipientType === 'personal') {
        args.sessionContext.diver = {
          firstName: 'Sophie',
          lastName: 'Dupont',
        };
      }
    }
    return args;
  }, [context, recipientType]);

  return useAppMessageParams(args);
}
