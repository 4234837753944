/* eslint-disable @typescript-eslint/no-unused-vars */
import { ClubResume } from '@mabadive/app-common-model';
import {
  clubProductPackageOffer_ServiceCategoryOrganizer,
  clubProductPackageOfferViewSorter,
} from '@mabadive/app-common-services';
import { useMemo } from 'react';
import { useClubResume, useClubSettings } from 'src/business/club/data/hooks';

export function useDiverPurchaseStorePackageOffers() {
  const clubResume: ClubResume = useClubResume();
  const publicSettings = clubResume.clubSettings.publicSettings;
  const clubSettings = useClubSettings();
  return useMemo(() => {
    const productPackageOffers = clubProductPackageOfferViewSorter.sortOffersBy(
      clubProductPackageOffer_ServiceCategoryOrganizer.groupOffersByServiceCategory(
        clubResume.productPackageOffers,
      ).store,
      {
        attributeName: 'name',
        publicSettings,
        diveModesConf: clubSettings?.ui?.diveMode,
      },
    );

    return productPackageOffers;
  }, [clubResume, clubSettings?.ui?.diveMode, publicSettings]);
}
