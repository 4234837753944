/* eslint-disable @typescript-eslint/no-unused-vars */
import clsx from 'clsx';
import React, { FC } from 'react';

export const AppButtonsBar: FC<{
  noBackground?: boolean;
  hasChanges?: boolean;
  className?: string;
  classNameJustify?: string;
  children?: React.ReactNode | React.ReactNode[];
  maxScreenClassName?: string;
}> = ({
  noBackground,
  hasChanges,
  children,
  classNameJustify,
  className = '',
  maxScreenClassName = 'max-w-screen-2xl',
}) => {
  return (
    <div className={clsx(maxScreenClassName, 'w-full bg-white', className)}>
      <div
        className={clsx(
          'w-full flex  items-center gap-4 app-px-content py-1 md:py-2',
          noBackground
            ? ''
            : hasChanges
            ? 'bg-status-info-light'
            : 'bg-status-info',
          classNameJustify ?? 'justify-around',
        )}
      >
        {children}
      </div>
    </div>
  );
};
