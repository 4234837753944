/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import {
  FieldArrayWithId,
  UseFieldArrayReturn,
  UseFormReturn,
} from 'react-hook-form';
import { AppInputRHF } from 'src/lib/form';
import { AppFormControlRHF } from 'src/lib/form/components/AppFormControl/AppFormControlRHF';

import { useAppSecurityUserHasRole } from 'src/business/auth/services';
import { useClubResume } from 'src/business/club/data/hooks';
import { ClubSettingsProductOffersFormModel } from '../../_model';
import { ClubSettingsProductOffersDialogLocalState } from '../../useClubSettingsProductOffersDialogLocalState.hook';
import { ClubSettingsProductOffersFormOfferCustomName } from '../ClubSettingsProductOffersFormOfferCustomName';
import {
  ClubSettingsProductOffersFormOfferCommon_Others_TO_REFACTOR,
  ClubSettingsProductOffersFormOfferCommon_Tarif,
} from '../ClubSettingsProductOffersFormOffer_Common';
import { ClubSettingsProductOffersFormOfferCommon_DivesCount } from '../ClubSettingsProductOffersFormOffer_Common/ClubSettingsProductOffersFormOfferCommon_DivesCount';
import { useClubSettingsProductOffersFormOfferNameDetails } from '../useClubSettingsProductOffersFormOfferNameDetails.hook';
import { ClubSettingsProductOffersFormOffer_Training_Category_SA } from './ClubSettingsProductOffersFormOffer_Training_Category';
import { ClubSettingsProductOffersFormOffer_Training_Reference } from './ClubSettingsProductOffersFormOffer_Training_Reference';
export const ClubSettingsProductOffersFormOffer_Training = ({
  form,
  offerField,
  offerFieldIndex,
  offersFieldArray,
  localState,
}: {
  form: UseFormReturn<ClubSettingsProductOffersFormModel>;
  offerField: FieldArrayWithId<
    ClubSettingsProductOffersFormModel,
    'offers',
    'id'
  >;
  offerFieldIndex: number;
  offersFieldArray: UseFieldArrayReturn<
    ClubSettingsProductOffersFormModel,
    'offers',
    'id'
  >;
  localState: ClubSettingsProductOffersDialogLocalState;
}) => {
  const isAdminMode = localState.state.isAdminMode;
  const isCreateMode = localState.state.isCreateMode;

  const clubResume = useClubResume();

  const isSuperAdmin = useAppSecurityUserHasRole('super-admin');

  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const i = offerFieldIndex;

  const showDetails = offersFieldArray.fields.length === 1;

  const nameDetails = useClubSettingsProductOffersFormOfferNameDetails({
    form,
    offerField,
    offersFieldArray,
  });

  return isCreateMode ? (
    <div>
      <div className="grid gap-2 grid-cols-2 sm:grid-cols-4 md:grid-cols-8">
        <ClubSettingsProductOffersFormOffer_Training_Reference
          form={form}
          offerField={offerField}
          offersFieldArray={offersFieldArray}
        />
        <ClubSettingsProductOffersFormOfferCommon_DivesCount
          form={form}
          offerField={offerField}
          offerFieldIndex={i}
          offersFieldArray={offersFieldArray}
        />
        <ClubSettingsProductOffersFormOfferCommon_Tarif
          form={form}
          offerFieldIndex={offerFieldIndex}
        />
        <AppFormControlRHF
          className="w-full col-span-2 md:col-span-4"
          label="Libellé additionnel"
          control={control}
          name={`offers.${i}.productPackage.label`}
          renderComponent={(props) => <AppInputRHF {...props} fullWidth />}
        />
        <ClubSettingsProductOffersFormOffer_Training_Category_SA
          form={form}
          offerField={offerField}
          offersFieldArray={offersFieldArray}
          localState={localState}
        />
      </div>
    </div>
  ) : (
    <div className="grid gap-2 ">
      <div className="grid gap-2 grid-cols-2 sm:grid-cols-4 2xl:grid-cols-8">
        <ClubSettingsProductOffersFormOffer_Training_Category_SA
          form={form}
          offerField={offerField}
          offersFieldArray={offersFieldArray}
          localState={localState}
        />
        <ClubSettingsProductOffersFormOffer_Training_Reference
          form={form}
          offerField={offerField}
          offersFieldArray={offersFieldArray}
        />
        <AppFormControlRHF
          label="Libellé additionnel"
          control={control}
          name={`offers.${i}.productPackage.label`}
          renderComponent={(props) => <AppInputRHF {...props} fullWidth />}
        />

        <ClubSettingsProductOffersFormOfferCommon_Tarif
          form={form}
          offerFieldIndex={offerFieldIndex}
        />
        <ClubSettingsProductOffersFormOfferCommon_Others_TO_REFACTOR
          form={form}
          offerField={offerField}
          offerFieldIndex={offerFieldIndex}
          offersFieldArray={offersFieldArray}
        />
      </div>

      {showDetails && (
        <ClubSettingsProductOffersFormOfferCustomName
          form={form}
          nameDetails={nameDetails}
          i={i}
        />
      )}
      <div className="">
        <AppFormControlRHF
          className="w-full"
          label="Commentaire"
          control={control}
          name={`offers.${i}.productPackage.comment`}
          renderComponent={(props) => (
            <AppInputRHF
              className="w-full"
              {...props}
              placeholder="Commentaire"
              type="text"
              multiline={true}
              rowsMin={2}
            />
          )}
        />
      </div>
    </div>
  );
};
