/* eslint-disable @typescript-eslint/no-unused-vars */
import { DiveGazType } from '@mabadive/app-common-model';
import React from 'react';
import { useAppSecurityUserClubAuthorizations } from 'src/business/auth/services';
import { useClubResume } from 'src/business/club/data/hooks';
import { ValueLabel } from 'src/business/club/modules/_common/form/components/ValueLabel.model';
import { useGazTypeOptions } from 'src/pages/_components/options';
import { ClubSettingsCustomerFormFieldsEditFormCustomerOriginOptions } from './ClubSettingsCustomerFormFieldsEditFormCustomerOriginOptions';
import { ClubSettingsCustomerFormFieldsEditFormResidentTypeOptions } from './ClubSettingsCustomerFormFieldsEditFormResidentTypeOptions';
import { ClubSettingsCustomerFormFieldsEditForm_Booking } from './ClubSettingsCustomerFormFieldsEditForm_Booking';
import { ClubSettingsCustomerFormFieldsEditForm_CustomerContact } from './ClubSettingsCustomerFormFieldsEditForm_CustomerContact';
import { ClubSettingsCustomerFormFieldsEditForm_CustomerMember } from './ClubSettingsCustomerFormFieldsEditForm_CustomerMember';
import { ClubSettingsCustomerFormFieldsEditForm_Equipment } from './ClubSettingsCustomerFormFieldsEditForm_Equipment';
import { ClubSettingsCustomerFormFieldsEditForm_Participant } from './ClubSettingsCustomerFormFieldsEditForm_Participant';
import { ClubSettingsCustomerFormFieldsEditPageLocalState } from './useClubSettingsCustomerFormFieldsEditPageLocalState.hook';

export const ClubSettingsCustomerFormFieldsEditForm = ({
  localState,
  className,
}: {
  localState: ClubSettingsCustomerFormFieldsEditPageLocalState;
  className?: string;
}) => {
  const { form } = localState;
  const { control, setValue } = form;

  const clubResume = useClubResume();
  const generalSettings = clubResume.clubSettings?.general;
  const customerSettings = clubResume?.clubSettings?.customer;

  const diveGazTypeOptions: ValueLabel<DiveGazType>[] = useGazTypeOptions();

  const az = useAppSecurityUserClubAuthorizations();

  return (
    <div className={className}>
      <div className="grid xl:grid-cols-2 gap-4 xl:justify-start xl:items-start">
        <div className="w-full grid gap-4 items-start">
          <ClubSettingsCustomerFormFieldsEditForm_CustomerContact
            localState={localState}
          />
          <ClubSettingsCustomerFormFieldsEditForm_CustomerMember
            localState={localState}
          />
          <ClubSettingsCustomerFormFieldsEditFormCustomerOriginOptions
            form={form}
          />
        </div>
        <div className="w-full grid gap-4 items-start">
          <ClubSettingsCustomerFormFieldsEditForm_Participant
            localState={localState}
          />
          <ClubSettingsCustomerFormFieldsEditForm_Booking
            localState={localState}
          />
          <ClubSettingsCustomerFormFieldsEditForm_Equipment
            localState={localState}
          />
          <ClubSettingsCustomerFormFieldsEditFormResidentTypeOptions
            form={form}
          />
        </div>
      </div>
    </div>
  );
};
