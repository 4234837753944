/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { ClubEcommerceProductCategoryConfig } from '@mabadive/app-common-model';
import React from 'react';
import { useRedirect } from 'src/business/_core/data/hooks';
import { useClubSettings } from 'src/business/club/data/hooks';
import {
  UseClubDialogsProps,
  useClubDialogsActionsPersist,
} from 'src/pages/_dialogs';
import {
  AppButton,
  AppMessageLight,
} from '../../../../../../../business/_core/modules/layout';
import { AppHeroIcons } from '../../../../../../../business/_core/modules/layout/icons';
import { ProProductsPage_CategoryViewPanel_ProductCoverCard } from './ProProductsPage_CategoryViewPanel_ProductCoverCard';
import { ProProductsPage_CategoryViewPanelLocalState } from './useProProductsPage_CategoryViewPanelLocalState.hook';

export const ProProductsPage_CategoryViewPanel_TabProducts = ({
  localState,
}: {
  localState: ProProductsPage_CategoryViewPanelLocalState;
}) => {
  const actionsPersist: UseClubDialogsProps = useClubDialogsActionsPersist();
  const clubSettings = useClubSettings();
  const redirectTo = useRedirect();

  const generalSettings = clubSettings.general;

  const { state, data, actions, dialogs } = localState;
  const { category, products, categoryBookletPage } = data;

  return (
    <div className="grid gap-4">
      <h3 className="text-lg text-app-primary font-bold leading-4 uppercase">
        {products.length > 0
          ? 'Choisissez un produit :'
          : 'Ajoutez un produit :'}
      </h3>
      {products.length > 0 && (
        <div className="grid xs:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
          {products.map((product) => (
            <ProProductsPage_CategoryViewPanel_ProductCoverCard
              key={product._id}
              category={category}
              product={product}
              localState={localState}
            />
          ))}
        </div>
      )}
      <div className="my-4">
        <AppButton
          className="self-start"
          color="primary-outline-light"
          size="normal"
          icon={AppHeroIcons.actionAdd}
          onClick={(e) => {
            e.stopPropagation();
            const categoryConfig: Partial<ClubEcommerceProductCategoryConfig> =
              {
                categoryId: category._id,
                sortIndex: state.nextIndex,
              };
            dialogs.productEditor.openCreateProduct({
              categoryBookletPage,
              defaultValue: {
                product: {
                  enabled: true,
                },
                categoryConfigs: [categoryConfig],
              },
            });
          }}
        >
          Ajouter un produit
        </AppButton>
      </div>
      <AppMessageLight>
        NOTE: Regroupez les articles similaires par catégorie, puis par produit.
      </AppMessageLight>
    </div>
  );
};
