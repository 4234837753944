import { Booking, DiveMode } from '@mabadive/app-common-model';
import {
  appInquiryFilter,
  dateService,
  phoneLinkBuilder,
  smsLinkBuilder,
} from '@mabadive/app-common-services';
import React, { useMemo } from 'react';
import {
  AppButton,
  AppButtonMultiActions,
} from '../../../../../../../../_core/modules/layout';
import { AppHeroIcons } from '../../../../../../../../_core/modules/layout/icons';
import { useClubResume } from '../../../../../../../data/hooks';
import {
  BookingResumeOnClickMemberAction,
  DiveCenterBookingMemberNameLabel,
} from '../../../../_from-diver-app';
import {
  PRO_BookingMemberFull_WithDocs,
  PRO_BookingResume,
} from '../../../models';
import { DiverBookingPageGlobalState } from '../../../useDiverBookingPageGlobalState.hook';
import { BookingResumeMembersListItemDetails } from './BookingResumeMembersListItemDetails';
import { useBookingResumeMemberHamburgerMenuItems } from './useBookingResumeMemberHamburgerMenuItems.hook';

export function BookingResumeMembersListItem({
  bookingMembersCount,
  booking,
  aggregatedBookingResume,
  bookingMemberFull,
  globalState,
  onClick,
  className,
}: {
  bookingMembersCount: number;
  booking: Booking;
  aggregatedBookingResume: PRO_BookingResume;
  bookingMemberFull: PRO_BookingMemberFull_WithDocs;
  globalState: DiverBookingPageGlobalState;
  onClick?: (
    bookingMemberFull: PRO_BookingMemberFull_WithDocs,
    action: BookingResumeOnClickMemberAction,
  ) => void;
  className?: string;
}) {
  const { bookingOpeningStates, setBookingOpeningStates } = globalState;

  const bookingId = booking._id;

  const diver = bookingMemberFull?.diver;

  // bookingMemberFull?.booking?.s

  const phoneNumberClean = useMemo(
    () => smsLinkBuilder.cleanPhoneNumber(diver?.phoneNumber),
    [diver?.phoneNumber],
  );
  const emailAddress = useMemo(
    () => diver?.emailAddress?.trim(),
    [diver?.emailAddress],
  );

  const clubResume = useClubResume();

  const nextDiveModes = useMemo(() => {
    const memberBookingParticipantsFull =
      aggregatedBookingResume?.bookingParticipantsFull.filter(
        (bpf) => bpf.diver?._id === diver?._id,
      );
    // only next
    const nextMemberBookingParticipantsFull =
      memberBookingParticipantsFull?.filter(
        (bpf) =>
          dateService.isTodayUTC(bpf?.diveSession?.time) ||
          dateService.isFutureDayUTC(bpf?.diveSession?.time),
      );
    const diveModes = nextMemberBookingParticipantsFull?.reduce((acc, bpf) => {
      if (!acc.includes(bpf?.diveSessionParticipant?.diveMode)) {
        acc.push(bpf?.diveSessionParticipant?.diveMode);
      }
      return acc;
    }, [] as DiveMode[]);
    const nextDives = [...new Set(diveModes)];
    return nextDives;
  }, [aggregatedBookingResume?.bookingParticipantsFull, diver?._id]);

  const enabledInquiries = useMemo(
    () =>
      (clubResume.inquiries ?? []).filter(
        (inquiry) => inquiry.settings?.enabled,
      ),
    [clubResume.inquiries],
  );

  const DISABLE_INQUIRIES = false;
  const activeInquiries = useMemo(
    () =>
      DISABLE_INQUIRIES
        ? []
        : appInquiryFilter.findActiveInquiries(enabledInquiries, {
            nextDiveModes,
            diver,
            bookingMemberConfigInquiry:
              bookingMemberFull?.bookingMember?.config?.inquiry,
          }),
    [
      DISABLE_INQUIRIES,
      enabledInquiries,
      nextDiveModes,
      diver,
      bookingMemberFull?.bookingMember?.config?.inquiry,
    ],
  );

  // console.log('xxx activeInquiries:', activeInquiries);

  const hamburgerMenuItems = useBookingResumeMemberHamburgerMenuItems({
    onClick,
    bookingMemberFull,
    bookingMembersCount,
  });

  return (
    <div
      className={`${className} ${onClick ? 'cursor-pointer' : ''}`}
      onClick={() => {
        onClick(bookingMemberFull, 'edit');
      }}
    >
      <div className="w-full flex gap-x-2 justify-between items-start">
        <div className="w-full flex gap-y-1 gap-x-2 justify-between">
          <div
            className={'flex flex-col gap-x-2 flex-wrap sm:flex-row sm:gap-x-4'}
          >
            <DiveCenterBookingMemberNameLabel
              bookingMemberFull={bookingMemberFull}
              isUniqueMember={
                aggregatedBookingResume.bookingMembers.length === 1
              }
              className={'sm:min-w-[15rem]'}
            />

            <BookingResumeMembersListItemDetails
              className="hidden sm:flex"
              aggregatedBookingResume={aggregatedBookingResume}
              bookingMemberFull={bookingMemberFull}
            />
          </div>

          <div>
            <div
              className={
                'hidden xs:flex xs:flex-col sm:gap-1 md:flex-row md:gap-2'
              }
            >
              {phoneNumberClean && (
                <AppButton
                  fullWidth={true}
                  className="text-xs whitespace-nowrap"
                  icon={AppHeroIcons.phone}
                  color={'phone-outline'}
                  href={`tel:${phoneNumberClean}`}
                  size="small"
                  onClick={(e) => e.stopPropagation()}
                >
                  <span className="">
                    {phoneLinkBuilder.formatPhoneNumber(phoneNumberClean)}
                  </span>
                </AppButton>
              )}
              {emailAddress && (
                <AppButton
                  className="text-xs whitespace-nowrap"
                  color={'mail-outline'}
                  icon={AppHeroIcons.contact}
                  href={`mailto:${emailAddress}`}
                  size="small"
                  onClick={(e) => e.stopPropagation()}
                  target="mabadive_mail_client"
                >
                  <span className="">{'E-MAIL'}</span>
                </AppButton>
              )}
            </div>
          </div>
        </div>
        {hamburgerMenuItems?.length !== 0 && (
          <AppButtonMultiActions items={hamburgerMenuItems} />
        )}
      </div>
    </div>
  );
}
