/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useMemo } from 'react';
import {
  FieldArrayWithId,
  UseFieldArrayReturn,
  UseFormReturn,
} from 'react-hook-form';
import { AppInputRHF, AppSingleAutocomplete2RHF } from 'src/lib/form';
import { AppFormControlRHF } from 'src/lib/form/components/AppFormControl/AppFormControlRHF';

import {
  ClubProductPackageAttributeProductType,
  PRODUCT_TYPES,
} from '@mabadive/app-common-model';
import { productTypeFormatter } from '@mabadive/app-common-services';
import { useAppSecurityUserHasRole } from 'src/business/auth/services';
import { useClubResume, useClubSettings } from 'src/business/club/data/hooks';
import { ValueLabel } from 'src/business/club/modules/_common/form';
import { ClubSettingsProductOffersFormModel } from '../_model';

export const ClubSettingsProductOffersFormOfferProduct = ({
  form,
  offerField,
  offersFieldArray,
}: {
  form: UseFormReturn<ClubSettingsProductOffersFormModel>;
  offerField: FieldArrayWithId<
    ClubSettingsProductOffersFormModel,
    'offers',
    'id'
  >;
  offersFieldArray: UseFieldArrayReturn<
    ClubSettingsProductOffersFormModel,
    'offers',
    'id'
  >;
}) => {
  const clubResume = useClubResume();
  const clubSettings = useClubSettings();
  const generalSettings = clubResume.clubSettings.general;
  const publicSettings = clubResume.clubSettings.publicSettings;
  const services = clubResume.clubSettings.services;

  const isSuperAdmin = useAppSecurityUserHasRole('super-admin');

  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const i = offersFieldArray.fields.findIndex(
    (x) => x.reference === offerField.reference,
  );

  const showDetails = offersFieldArray.fields.length === 1;

  const productTypeOptions = useMemo(
    () =>
      PRODUCT_TYPES.map((productType) => {
        const option: ValueLabel<ClubProductPackageAttributeProductType> = {
          label: productTypeFormatter.format(productType).toUpperCase(),
          value: productType,
        };
        return option;
      }),
    [],
  );

  return (
    <>
      <AppFormControlRHF
        className="w-full col-span-2"
        label="Libellé"
        control={control}
        name={`offers.${i}.productPackage.label`}
        renderComponent={(props) => (
          <AppInputRHF className="w-full" {...props} fullWidth />
        )}
      />
      <AppFormControlRHF
        className="w-full"
        label="Type de produit"
        control={control}
        name={`offers.${i}.productPackage.productType`}
        required={true}
        renderComponent={(props) => (
          <AppSingleAutocomplete2RHF {...props} options={productTypeOptions} />
        )}
      />
    </>
  );
};
