import { AppDocUploadContext } from '@mabadive/app-common-model';
import React, { FC, useMemo } from 'react';
import { confirmDialog } from 'src/business/_core/modules/layout/components/ConfirmDialog/confirmDialog.service';
import { useClubDiverCardAttributes } from 'src/business/club/modules/club-divers/components/ClubDiverCard';
import {
  BookingMemberDiverEditFormRHF,
  BookingMemberGeneralEditFormRHF,
  BookingParticipantEditFormRHF,
} from '../../../forms';
import { BookingParticipantEditorQuickEditActions } from '../BookingParticipantEditorDialog/BookingParticipantEditorQuickEditActions';
import {
  BookingParticipantEditorDialogEditPanel1,
  BookingParticipantEditorDialogEditPanel2,
  BookingParticipantEditorDialogEditPanel3,
} from '../BookingParticipantEditorDialog/panels';
import { useClubParticipantCardAttributes } from '../_common';
import { BookingParticipantViewDialogLocalState } from './useBookingParticipantViewDialogLocalState.hook';

export const BookingParticipantViewDialogFormPanel: FC<{
  localState: BookingParticipantViewDialogLocalState;
}> = ({ localState }) => {
  const {
    form,
    aggregatedData,
    isPersistInProgress,
    expandedPanel,
    expandPanelToogle,
    setIsConfirmDialogOpen,
    setIsOpen,
    onClickMove,
    onConfirmDeleteParticipant,
  } = localState;
  const { participantData } = aggregatedData;
  const { setValue } = form;

  const { generalAttributes, diverAttributes } = useClubDiverCardAttributes({
    diver: participantData.diver,
    bookingMember: participantData.bookingMember,
  });

  const { primary: participantCardDiveAttributes } =
    useClubParticipantCardAttributes({
      participant: participantData.clubParticipant,
      displayMode: 'edit-participant-form-dive-parameters',
    });

  const { primary: participantCardMoreAttributes } =
    useClubParticipantCardAttributes({
      participant: participantData.clubParticipant,
      displayMode: 'edit-participant-form-more-parameters',
    });

  const mode: 'view' | 'loading' | 'edit' = useMemo(() => {
    if (expandedPanel) {
      return aggregatedData.state === 'full' ? 'edit' : 'loading';
    }
    return 'view';
  }, [aggregatedData.state, expandedPanel]);
  const appDocUploadContext: AppDocUploadContext = useMemo(() => {
    const context: AppDocUploadContext = {
      clubDiverId: participantData?.diver?._id,
      bookingId: participantData?.bookingMember?.bookingId,
      bookingMemberId: participantData?.bookingMember?._id,
      appBookletPageId: undefined,
      fileType: 'image',
      scope: 'diver',
      isPublic: false,
    };
    return context;
  }, [
    participantData?.diver?._id,
    participantData?.bookingMember?.bookingId,
    participantData?.bookingMember?._id,
  ]);
  return (
    <>
      <BookingParticipantEditorDialogEditPanel1
        className="mt-1"
        diver={participantData.diver}
        bookingMember={participantData.bookingMember}
        diverCardAttributes={generalAttributes}
        expandedPanel={expandedPanel}
        expandPanelToogle={expandPanelToogle}
        renderDetails={() => {
          return mode === 'edit' ? (
            <BookingMemberGeneralEditFormRHF
              form={form}
              appDocUploadContext={appDocUploadContext}
            />
          ) : (
            <div>Chargement en cours...</div>
          );
        }}
      />
      <BookingParticipantEditorDialogEditPanel2
        className="mt-1"
        diver={participantData.diver}
        bookingMember={participantData.bookingMember}
        diverCardAttributes={diverAttributes}
        expandedPanel={expandedPanel}
        expandPanelToogle={expandPanelToogle}
        renderDetails={() => {
          return mode === 'edit' ? (
            <BookingMemberDiverEditFormRHF
              isDiverWithMember={true}
              mode="edit"
              className="pb-28"
              form={form}
            />
          ) : (
            <div>Chargement en cours...</div>
          );
        }}
      />
      <BookingParticipantEditorDialogEditPanel3
        className="mt-1"
        diver={participantData.diver}
        participant={participantData.clubParticipant}
        diveSession={participantData.diveSession}
        participantCardMoreAttributes={participantCardMoreAttributes}
        participantCardDiveAttributes={participantCardDiveAttributes}
        expandedPanel={expandedPanel}
        expandPanelToogle={expandPanelToogle}
        renderDetails={() => {
          return mode === 'edit' ? (
            <BookingParticipantEditFormRHF maxWidthMd={true} form={form} />
          ) : (
            <div>Chargement en cours...</div>
          );
        }}
        renderQuickActions={() => (
          <BookingParticipantEditorQuickEditActions
            onClickMove={onClickMove}
            participant={participantData.clubParticipant}
            mode={'edit-participant'}
            setValue={setValue}
            onClickDelete={() => {
              setIsConfirmDialogOpen(true);
              confirmDialog
                .confirm({
                  isTailwind: true,
                  title: 'Supprimer un participant',
                  message: 'Êtes-vous sûr de vouloir supprimer ce participant?',
                  type: 'remove',
                })
                .subscribe((confirmed) => {
                  setIsConfirmDialogOpen(false);
                  if (confirmed) {
                    if (!isPersistInProgress) {
                      onConfirmDeleteParticipant();
                      setIsOpen(false);
                    }
                  }
                });
            }}
          />
        )}
      />
      {/* ICI, pas simple d'afficher un nouveau dialog, car on est déjà sur un ancien dialog */}
      {/* on pourrait cependant afficher les documents, en les chargeant à la volé à l'ouverture (dans useLoadableBookingParticipantEditor) */}
      {/* <AppDocViewPanelDiver
        bookingId={participantData?.bookingId}
        bookingMemberId={participantData?.bookingMember?._id}
        clubDiverId={participantData?.diver?._id}
        docResumes={participantData?.docResumes}
        dialogsState={localState.dialogsState}
      /> */}
    </>
  );
};
