/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  EcommerceCategoryGql_Company,
  EcommerceProductArticleGql_Company,
  EcommerceProductGql_Company,
} from '@mabadive/app-common-model';
import clsx from 'clsx';
import { default as React, useMemo } from 'react';
import { useRedirect } from 'src/business/_core/data/hooks';
import { useAppTheme, useClubSettings } from 'src/business/club/data/hooks';
import {
  UseClubDialogsProps,
  useClubDialogsActionsPersist,
} from 'src/pages/_dialogs';
import { ClubSettingsOnlineBookingProductsPageLocalState } from '../useClubSettingsOnlineBookingProductsPageLocalState.hook';
import { ProProductsPage_CategoryContentCardProductArticlesArea } from './ProProductsPage_CategoryContentCardProductArticlesArea';

export const ProProductsPage_CategoryContentCardProduct = ({
  category,
  product,
  localState,
  className,
}: {
  category: EcommerceCategoryGql_Company;
  product: EcommerceProductGql_Company;
  localState: ClubSettingsOnlineBookingProductsPageLocalState;
  className?: string;
}) => {
  const actionsPersist: UseClubDialogsProps = useClubDialogsActionsPersist();
  const clubSettings = useClubSettings();
  const redirectTo = useRedirect();

  const generalSettings = clubSettings.general;

  const { state, dialogs, data, actions } = localState;

  const productArticles: EcommerceProductArticleGql_Company[] = useMemo(() => {
    return data.productArticles.filter((a) => a.productId === product._id);
  }, [data.productArticles, product._id]);

  const productBooklet = useMemo(() => {
    return data.booklets.find((x) => x._id === product.bookletId);
  }, [data.booklets, product.bookletId]);

  const productBookletPage = useMemo(() => {
    return data.bookletPages.find((x) => x._id === productBooklet?.rootPageId);
  }, [data.bookletPages, productBooklet?.rootPageId]);

  const theme = useAppTheme();

  const cover = productBookletPage?.mainContent?.cover;
  return (
    <div key={product._id} className={clsx('grid gap-4', className)}>
      {/* <ProProductsPage_CategoryProductCoverCard
        category={category}
        product={product}
        localState={localState}
      /> */}
      <div>
        <div
          className={clsx(
            'text-base font-bold w-full text-left',
            // displayWidth === 'mobile'
            //   ? 'text-md'
            //   : 'text-md lg:text-lg',
          )}
          style={{ color: theme.title1.textColor }}
        >
          {cover?.title?.toUpperCase()}
        </div>
        {cover?.subTitle && (
          <div
            className={clsx('text-sm font-medium w-full text-left')}
            style={{ color: theme.title2.textColor }}
          >
            {cover?.subTitle}
          </div>
        )}
      </div>
      <ProProductsPage_CategoryContentCardProductArticlesArea
        product={product}
        localState={localState}
      />
    </div>
  );
};
