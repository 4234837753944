import React from 'react';
import { Switch } from 'react-router-dom';
import { AppRoute } from 'src/business/_core/modules/router/AppRoute/AppRoute';
import useRouter from 'use-react-router';
import { ClubSettingsHomePage } from './SE-00_home';
import { ClubSettingsDiveCenterRoutes } from './SE-01_dive-center';
import { ClubSettingsAccountRoutes } from './SE-02_account';
import { ClubSettingsCustomersRoutes } from './SE-03-customers';
import { ClubSettingsOtherRoutes } from './SE-04_other-settings';
import { ClubSettings_ConfigAssistantPage } from './SE-99_config-assistant';

export const ClubSettingsRoutes = React.memo(function ClubRootSpaceMemo() {
  const { match } = useRouter();
  return (
    <Switch>
      <AppRoute path={`${match.url}`} exact component={ClubSettingsHomePage} />
      <AppRoute
        path={`${match.url}/dive-center`}
        component={ClubSettingsDiveCenterRoutes}
      />{' '}
      <AppRoute
        path={`${match.url}/account`}
        component={ClubSettingsAccountRoutes}
      />
      <AppRoute
        path={`${match.url}/customers`}
        component={ClubSettingsCustomersRoutes}
      />{' '}
      <AppRoute
        path={`${match.url}/other`}
        component={ClubSettingsOtherRoutes}
      />
      <AppRoute
        path={`${match.url}/assistant`}
        component={ClubSettings_ConfigAssistantPage}
        checkAuth={({ az }) => az?.edit?.settings?.global}
      />
      {/* default route */}
      <AppRoute
        path={`${match.url}/`}
        exact={false}
        redirectToUrl="/club/settings"
      />
    </Switch>
  );
});
