import {
  DIVE_TRAININGS,
  DiveTrainingReference,
} from '@mabadive/app-common-model';
import { diveServiceTrainingFormatter } from '@mabadive/app-common-services';
import { useMemo } from 'react';
import { ValueLabel } from 'src/business/club/modules/_common/form/components/ValueLabel.model';
import { useClubSettings } from '../../../business/club/data/hooks';

export function useTrainingOptionsAll(): ValueLabel<
  DiveTrainingReference,
  string
>[] {
  const clubSettings = useClubSettings();

  return useMemo(() => {
    const options = DIVE_TRAININGS.map((training) => ({
      value: training.reference,
      label: diveServiceTrainingFormatter.formatTraining(training, {
        format: 'ref-name',
      }),
    }));
    // une même formation peut être dans INT ou dans FR, dans ce cas on filtre les doublons sur les options
    const isUnique = (
      item: ValueLabel<DiveTrainingReference, string>,
      index: number,
      array: ValueLabel<DiveTrainingReference, string>[],
    ) => {
      return array.findIndex((opt) => opt.value === item.value) === index;
    };
    const uniqueOptions: ValueLabel<DiveTrainingReference, string>[] =
      options.filter((option, index, array) => isUnique(option, index, array));
    return uniqueOptions;
  }, []);
}
