/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ClubProductPackageOfferView,
  ClubProductPackageOfferViewDisplayGroup,
  ClubSettings,
  DiveServiceOrganizationReference,
} from '@mabadive/app-common-model';

export const offersDisplayGroupsBuilder_Training = {
  buildGroups_Training,
};

function buildGroups_Training({
  clubSettings,
  offers,
}: {
  clubSettings: ClubSettings;
  offers: ClubProductPackageOfferView[];
}) {
  const offersByDisplayGroups: ClubProductPackageOfferViewDisplayGroup[] = [];

  const { organizationOffers, remainingOffers } = offers.reduce(
    (acc, offer) => {
      const organizationReference =
        offer.productPackage?.productAttributes?.defaultOrganizationReference;

      if (organizationReference) {
        // Ajouter l'offre au groupe correspondant
        if (!acc.organizationOffers[organizationReference]) {
          acc.organizationOffers[organizationReference] = [];
        }
        acc.organizationOffers[organizationReference].push(offer);
      } else {
        // Ajouter l'offre aux "remainingOffers" si elle n'appartient à aucun type personnalisé
        acc.remainingOffers.push(offer);
      }

      return acc;
    },
    {
      organizationOffers: {} as Record<
        DiveServiceOrganizationReference,
        ClubProductPackageOfferView[]
      >,
      remainingOffers: [] as ClubProductPackageOfferView[],
    },
  );

  // Transformer organizationOffers en un tableau d'objets avec un titre et des offres
  for (const [organizationReference, offers] of Object.entries(
    organizationOffers,
  )) {
    offersByDisplayGroups.push({
      title: organizationReference,
      offers,
    });
  }

  // Ajouter les offres restantes dans un groupe "Autres"
  if (remainingOffers.length > 0) {
    offersByDisplayGroups.push({
      title: 'Autres',
      offers: remainingOffers,
    });
  }
  return offersByDisplayGroups;
}
