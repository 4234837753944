import React, { useMemo } from 'react';

import {
  ClubEcommerceProductArticlePrice,
  ClubEcommerceProductArticlePricing,
  ClubProductPackageOffer_ServiceCategory,
  ClubProductPackageOfferView,
} from '@mabadive/app-common-model';
import {
  diveModeGroupFormatter,
  productPackageFormatter,
  uuidGenerator,
} from '@mabadive/app-common-services';
import {
  AppButton,
  AppMessageLight,
} from '../../../../../../../business/_core/modules/layout';
import {
  useClubResume,
  useClubSettings,
} from '../../../../../../../business/club/data/hooks';
import { clubSettingsAccountUrlBuilder } from '../../../../../SE-02_account/clubSettingsAccountUrlBuilder.service';
import { SETTINGS_ACCOUNT_PAGE_SERVICES } from '../../../../../SE-02_account/SETTINGS_ACCOUNT.const';
import { EcommerceProductArticleEditorPanelCreateFormOfferItem } from './EcommerceProductArticleEditorPanelCreateFormOfferItem';
import { EcommerceProductArticleEditorPanelEditForm } from './EcommerceProductArticleEditorPanelEditForm';
import { EcommerceProductArticleEditorLocalState } from './useEcommerceProductArticleEditorPanelLocalState.hook';

export const EcommerceProductArticleEditorPanelCreateForm = ({
  localState,
}: {
  localState: EcommerceProductArticleEditorLocalState;
}) => {
  const clubResume = useClubResume();
  const publicSettings = clubResume.clubSettings.publicSettings;
  const clubSettings = useClubSettings();

  const { data, state, actions } = localState;
  const form = state.form;

  const [viewState, setViewState] = React.useState<{
    step: 'select-offer' | 'configure-article';
    offer?: ClubProductPackageOfferView;
  }>({
    step: 'select-offer',
  });

  const serviceCategory: ClubProductPackageOffer_ServiceCategory =
    useMemo(() => {
      switch (data.diveModeGroup.diveModeGroup) {
        case 'dive-discover': // plongée bouteille - découverte
          return 'first-dive';
        case 'dive-training': // plongée bouteille - formation
          return 'training';
        case 'dive-explo': // plongée bouteille - exploration
        case 'dive-autosupervised': // plongée bouteille - clubs autonomes
          return 'explo';
        case 'free-dive': // apnée
          return 'free-dive';
        case 'store':
          return 'store';
        case 'observer': // accompagnateur
        case 'watchingTour': // excursion d'observation
        case 'snorkeling': // plongée bouteille - PMT
        default:
          return 'others';
      }
    }, [data.diveModeGroup]);

  const servicePricingPageUrl = useMemo(
    () =>
      serviceCategory
        ? clubSettingsAccountUrlBuilder.servicePricing(serviceCategory)
        : null,
    [serviceCategory],
  );

  return (
    <div className="max-w-6xl">
      {viewState.step === 'select-offer' && (
        <>
          {data.offers.length > 0 ? (
            <div className="grid gap-4">
              <div>
                Choisir le tarif{' '}
                {data.diveModeGroup && (
                  <span className="font-bold">
                    {diveModeGroupFormatter.formatDiveModeGroup(
                      data.diveModeGroup.diveModeGroup,
                      { format: 'long' },
                    )}
                  </span>
                )}{' '}
                correspondant
              </div>
              <div className="w-full bg-gray-50 p-1 max-h-[70vh] grid gap-y-1 gap-x-1 md:grid-cols-2 lg:grid-cols-4">
                {data.offers.map((offer) => (
                  <EcommerceProductArticleEditorPanelCreateFormOfferItem
                    key={offer.reference}
                    offer={offer}
                    onClick={() => {
                      const nameDetails =
                        productPackageFormatter.formatNameDetails(
                          offer.productPackage,
                          {
                            publicSettings,
                            diveModesConf: clubSettings?.ui?.diveMode,
                          },
                        );
                      const name = [nameDetails.name, nameDetails.label]
                        .filter(Boolean)
                        .join(' - ');
                      form.setValue(
                        'productArticle.productPackageOfferId',
                        offer._id,
                      );
                      const diveMode =
                        offer.productPackage?.diveAttributes?.diveMode;
                      form.setValue('productArticle.diveMode', diveMode);
                      if (diveMode === 'first-dive') {
                        form.setValue(
                          'productArticle.details.firstDiveReference',
                          offer.productPackage?.diveAttributes
                            ?.firstDiveTrainingReference,
                        );
                      } else if (diveMode === 'training') {
                        form.setValue(
                          'productArticle.details.trainingReference',
                          offer.productPackage?.trainingAttributes
                            ?.diveTrainingReference,
                        );
                      }
                      // TODO 'theoretical-training' // 02/07/2024 : on devrait pouvoir créer des formations purement théoriques!

                      if (offer.price > 0) {
                        const price: ClubEcommerceProductArticlePrice = {
                          _id: uuidGenerator.random(),
                          unitPrice: offer.price,
                          label: 'Tarif normal',
                        };
                        const pricing: ClubEcommerceProductArticlePricing = {
                          defaultPriceId: price._id,
                          prices: [price],
                        };
                        form.setValue('productArticle.pricing', pricing);
                      }

                      form.setValue('productArticle.name', name);
                      form.setValue(
                        'appBookletPage.mainContent.cover.title',
                        name,
                      );
                      form.setValue(
                        'appBookletPage.mainContent.content.title',
                        name,
                      );

                      setViewState({
                        step: 'configure-article',
                        offer,
                      });
                    }}
                  />
                ))}
              </div>
              <AppMessageLight>
                <div>
                  Pour créer un nouveau produit, rendez-vous sur la page de
                  configuration des prestations et tarifs.
                </div>
                <div className="my-4">
                  <AppButton
                    href={servicePricingPageUrl}
                    color={'gray-outline-light'}
                  >
                    Prestations et tarifs
                  </AppButton>
                </div>
              </AppMessageLight>
            </div>
          ) : (
            <AppMessageLight>
              Aucun produit{' '}
              {data.diveModeGroup && (
                <span className="font-bold">
                  {diveModeGroupFormatter.formatDiveModeGroup(
                    data.diveModeGroup.diveModeGroup,
                    { format: 'long' },
                  )}
                </span>
              )}{' '}
              n'existe. Veuillez les créer dans la section{' '}
              <AppButton
                href={SETTINGS_ACCOUNT_PAGE_SERVICES.url}
                color={'gray-outline-light'}
              >
                Prestations et produits.
              </AppButton>
            </AppMessageLight>
          )}
        </>
      )}
      {viewState.step === 'configure-article' && (
        <>
          <div className="flex gap-8 items-start">
            <EcommerceProductArticleEditorPanelEditForm
              localState={localState}
            />
            {/* <AppPhonePanel className="hidden md:flex" label={'APERÇU'}>
              <div className="h-[600px] max-h-[90lvh bg-gray-100">
                <div className="m-4 app-card rounded-t-xl flex flex-col gap-4">
                  <ProEcommerceProductArticlePreviewContent
                    displayWidth={'mobile'}
                    productArticle={productArticle}
                    appBookletPage={appBookletPage}
                  >
                    <div className="max-w-sm">
                      {(content?.items ?? []).map((item, i) => (
                        <AppTranslationContentPanel key={i} item={item} />
                      ))}
                    </div>
                  </ProEcommerceProductArticlePreviewContent>
                </div>
              </div>
            </AppPhonePanel> */}
          </div>
        </>
      )}
    </div>
  );
};
