/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ClubResumeStaffMember,
  DiveSession,
  DiveSessionResumeGroup,
  DiveSessionResumeParticipant,
  MultipleDiveSessionNumber,
} from '@mabadive/app-common-model';
import { nameFormatter } from '@mabadive/app-common-services';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { useDiveCenterResume } from 'src/business/club/data/hooks';
import { CalendarStaffInstructorCell } from '../session-common';
import { useSessionInstructorStaffMembers } from './useSessionInstructorStaffMembers.hook';

export const DiveSessionSimpleCardParticipantsGroupDetails = ({
  diveSession,
  group,
  participants,
  sessionNumber,
  staffFirstNameBefore,
  hideMainInstructorStaffMember,
  className,
}: {
  diveSession: Pick<DiveSession, 'time' | 'status' | 'diveTourSession2'>;
  group: DiveSessionResumeGroup;
  participants: DiveSessionResumeParticipant[];
  sessionNumber: MultipleDiveSessionNumber;
  staffFirstNameBefore: boolean;
  hideMainInstructorStaffMember: boolean;
  className?: string;
}) => {
  const diveTourGroup =
    sessionNumber === 1
      ? group?.diveTourGroupSession1
      : group?.diveTourGroupSession2;
  const guideParticipant = useMemo(
    () =>
      diveTourGroup?.diveGuide?.participantId
        ? participants.find(
            (p) => p._id === diveTourGroup?.diveGuide?.participantId,
          )
        : undefined,
    [diveTourGroup?.diveGuide?.participantId, participants],
  );
  const diveCenterResume = useDiveCenterResume();
  const diveCenterId = diveCenterResume?._id;
  const clubReference = diveCenterResume?.clubReference;

  const {
    mainInstructorStaffMember,
    otherInstructorsStaffMembers,
  }: {
    mainInstructorStaffMember: ClubResumeStaffMember;
    otherInstructorsStaffMembers: ClubResumeStaffMember[];
  } = useSessionInstructorStaffMembers({
    sessionNumber,
    diveSession,
    group,
    diveCenterResume,
    diveTourGroup,
  });
  const sessionsCount = diveSession.diveTourSession2 ? 2 : 1;

  const showSession2 = sessionsCount > 1;

  const displayAutonomousField =
    diveCenterResume.clubResume.clubSettings.general.sessions
      ?.displayAutonomousField;
  const isAutonomousGroup: boolean = useMemo(() => {
    if (
      diveTourGroup?.isAutonomous === true
      // ||
      // diveTourGroup?.isAutonomous === false
    ) {
      // le bouton "isAutonomous" a été forcé, il est prioritaire
      return diveTourGroup?.isAutonomous; // ATTENTION spécifique top-dive (et bientôt torra?), c'est un champ manuel, pas utilisé pour la plupart des clubs!!! => @see displayAutonomousField
    }
    return group?.diveMode === 'autonomous';
  }, [diveTourGroup?.isAutonomous, group?.diveMode]);

  return (
    <div className={clsx(className)}>
      {!group && (
        <>
          <div className="text-xs text-app-secondary font-bold uppercase">
            HORS PALANQUÉES
          </div>
        </>
      )}
      {group &&
        !isAutonomousGroup &&
        !guideParticipant &&
        !mainInstructorStaffMember && (
          <>
            <div className="text-xs text-app-secondary font-bold uppercase">
              À ENCADRER{' '}
              {showSession2 && (
                <span>
                  plongée N°{sessionNumber}/{sessionsCount}
                </span>
              )}
            </div>
          </>
        )}
      {isAutonomousGroup && (
        <>
          <div className="text-xs text-app-secondary font-bold uppercase">
            AUTONOMES{' '}
            {displayAutonomousField &&
            !group?.diveTourGroupSession1?.isAutonomous
              ? '??? '
              : ''}
            {showSession2 && (
              <span>
                plongée N°{sessionNumber}/{sessionsCount}
              </span>
            )}
          </div>
        </>
      )}
      {guideParticipant && (
        <>
          <div className="text-xs text-app-secondary font-bold uppercase">
            Guide de palanquée{' '}
            {showSession2 && !isAutonomousGroup && (
              <span>
                plongée N°{sessionNumber}/{sessionsCount}
              </span>
            )}
          </div>
          <div className="mb-0.5 sm:mb-1 font-bold">
            {nameFormatter.formatFullName(guideParticipant.diver, {
              format: staffFirstNameBefore
                ? 'firstName-first'
                : 'lastName-first',
            })}
            {diveTourGroup?.diveGuide?.mainCertificationReference &&
              ` (${diveTourGroup?.diveGuide?.mainCertificationReference})`}
          </div>
        </>
      )}
      {((mainInstructorStaffMember && !hideMainInstructorStaffMember) ||
        otherInstructorsStaffMembers.length > 0) && (
        <>
          <div className="text-xs text-app-secondary font-bold uppercase">
            {/* Moniteur{' '} */}
            {showSession2 && (
              <span>
                plongée N°{sessionNumber}/{sessionsCount}
              </span>
            )}
          </div>
          <div className="mb-0.5 sm:mb-1 font-bold">
            {mainInstructorStaffMember && !hideMainInstructorStaffMember && (
              <CalendarStaffInstructorCell
                staffFirstNameBefore={staffFirstNameBefore}
                staffMember={mainInstructorStaffMember}
                diveSession={diveSession}
                isPast={false}
                widthMode={'large'}
              />
            )}
            {otherInstructorsStaffMembers.map((instructorStaffMember, i) => (
              <CalendarStaffInstructorCell
                key={`instructor-${i}-${instructorStaffMember._id}`}
                staffFirstNameBefore={staffFirstNameBefore}
                staffMember={instructorStaffMember}
                diveSession={diveSession}
                isPast={false}
                widthMode={'large'}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
};
