import React from 'react';
import { Switch } from 'react-router-dom';
import { of } from 'rxjs';
import { AppRoute } from 'src/business/_core/modules/router/AppRoute/AppRoute';
import { AppAuth } from 'src/business/auth/services';
import useRouter from 'use-react-router';
import { ClubMassMailingPage } from './COM-01_mass-mail';

export const ClubCommunicationRoutes = React.memo(function ClubRootSpaceMemo() {
  const { match } = useRouter();
  return (
    <Switch>
      <AppRoute
        path={`${match.url}/mailing`}
        exact
        component={ClubMassMailingPage}
        checkAuth={({ auth, az }) => az?.communication?.action?.sendMassMailing}
      />

      {/* default route */}
      <AppRoute
        path={`${match.url}/`}
        redirectTo={({ appAuth }) =>
          of(redirectToDefaultRoute({ appAuth, matchUrl: match.url }))
        }
      />
    </Switch>
  );
});

function redirectToDefaultRoute({
  appAuth,
  matchUrl,
}: {
  appAuth: AppAuth;
  matchUrl: string;
}): string {
  return '/';
}
