import {
  ClubResumeGql_pro,
  ClubSettingsCustomer,
  PRO_AppDocResume,
} from '@mabadive/app-common-model';
import { certificationFormatter } from '@mabadive/app-common-services';
import {
  AppHeroIcons,
  AppIcon,
} from '../../../../../../../../_core/modules/layout/icons';
import { PRO_BookingMemberFull_WithDocs } from '../../../../DiverBookingPage/models';
import {
  DiverBookingSpace_DocDataResume,
  DiverBookingSpace_DocDataResume_Status,
} from '../model/DiverBookingSpace_DocDataResume.type';

export const docDataResumeBuilder_MainCertification = {
  buildMainCertification,
};
function buildMainCertification({
  bookingMemberFull,
  clubResume,
}: {
  bookingMemberFull: PRO_BookingMemberFull_WithDocs;
  clubResume: ClubResumeGql_pro;
}): DiverBookingSpace_DocDataResume {
  const customerSettings = clubResume.clubSettings?.customer;

  const docResume = getDocResume(bookingMemberFull);

  const { status, issueDate } = buildAttributes({
    docResume,
    customerSettings,
    bookingMemberFull,
  });

  const icon: AppIcon = AppHeroIcons.documentCheck;

  const docDataResume: DiverBookingSpace_DocDataResume = {
    title: bookingMemberFull?.diver?.divingCertification1
      ?.mainCertificationReference
      ? certificationFormatter.formatCertificationReference(
          bookingMemberFull?.diver?.divingCertification1
            ?.mainCertificationReference,
          {
            format: 'name',
          },
        )
      : 'Niveau',
    issueDate,
    status,
    appDoc: docResume,
    icon,
  };
  return docDataResume;
}

function getDocResume(bookingMemberFull: PRO_BookingMemberFull_WithDocs) {
  if (
    bookingMemberFull?.diver?.divingCertification1?.mainCertificationDoc?._id
  ) {
    return bookingMemberFull.docResumes?.find(
      (x) =>
        x._id ===
        bookingMemberFull?.diver?.divingCertification1?.mainCertificationDoc
          ?._id,
    );
  }
}

function buildAttributes({
  docResume,
  customerSettings,
  bookingMemberFull,
}: {
  docResume: PRO_AppDocResume;
  customerSettings: ClubSettingsCustomer;
  bookingMemberFull: PRO_BookingMemberFull_WithDocs;
}): {
  status: DiverBookingSpace_DocDataResume_Status;
  issueDate: Date;
} {
  if (docResume) {
    // on se base sur un doc
    let issueDate = docResume.details?.docDate;

    let status: DiverBookingSpace_DocDataResume_Status = docResume?.status;

    return {
      status,
      issueDate,
    };
  } else if (
    bookingMemberFull?.diver?.divingCertification1?.mainCertificationReference
  ) {
    return {
      status: 'validated',
      issueDate: undefined,
    };
  } else {
    return {
      status: 'missing',
      issueDate: undefined,
    };
  }
}
