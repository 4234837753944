/* eslint-disable @typescript-eslint/no-unused-vars */
import { default as React } from 'react';
import { useAppSecurityUserClubAuthorizations } from 'src/business/auth/services';
import { AppFeatureListItem } from 'src/business/club/modules/_common/ui';
import { useAppCurrencyMain } from 'src/pages/_components/options';
import {
  useClubResume,
  useDiveCenterResume,
} from '../../../../../business/club/data/hooks';

export const ClubSettingsCustomerViewCard_CustomerContact = () => {
  const mainCurrency = useAppCurrencyMain();
  const diveCenterResume = useDiveCenterResume();
  const clubResume = useClubResume();

  const clubSettings = clubResume?.clubSettings;
  const customerSettings = clubSettings?.customer;

  const az = useAppSecurityUserClubAuthorizations();

  return (
    <>
      <h2 className="py-2  text-left uppercase text-lg leading-6 font-medium text-gray-800">
        Client - coordonnées
      </h2>
      <ul className="relative grid gap-2 p-2 sm:px-4 font-bold text-gray-600">
        <AppFeatureListItem
          enabled={true}
          label="Champs toujours affichés (nom, prénom, email...)"
        />
        <AppFeatureListItem
          enabled={customerSettings?.general?.nickName}
          label="Surnom"
        />
        <AppFeatureListItem
          enabled={customerSettings?.general?.profilePhoto}
          label="Photo de profil"
        />
        <AppFeatureListItem
          enabled={customerSettings?.general?.languageCode}
          label="Langue"
        />
        <AppFeatureListItem
          enabled={customerSettings?.general?.homeAddress}
          label="Adresse postale"
        />
        <AppFeatureListItem
          enabled={customerSettings?.general?.externalRef}
          label={`ID perso${
            customerSettings?.general?.externalRefLabel
              ? `: "${customerSettings?.general?.externalRefLabel}"`
              : ''
          }`}
        />
        <AppFeatureListItem
          enabled={customerSettings?.general?.identificationNumber}
          label="N° CNI ou Passeport"
        />
        <AppFeatureListItem
          enabled={customerSettings?.general?.residentType}
          label="Résidence (résident ou vacancier)"
        />
        <AppFeatureListItem
          enabled={customerSettings?.general?.accomodation}
          label="Hébergement"
        />
        <AppFeatureListItem
          enabled={customerSettings?.general?.pickup}
          label="Pickup"
        />
        <AppFeatureListItem
          enabled={customerSettings?.general?.customerOrigin}
          label="Origine client (Internet, bouche à oreille...)"
        />
        <AppFeatureListItem
          enabled={customerSettings?.general?.sanitaryPass}
          label="Pass sanitaire (oui/non)"
        />
        <AppFeatureListItem
          enabled={customerSettings?.general?.newsletter}
          label="Inscription à la newsletter (oui/non)"
        />
        <AppFeatureListItem
          enabled={customerSettings?.general?.phoneNumber2}
          label="Second numéro de téléphone"
        />
        <AppFeatureListItem enabled={true} label="Date de naissance" />
        <AppFeatureListItem
          enabled={customerSettings?.general?.age}
          label="Âge"
        />
        <AppFeatureListItem
          enabled={customerSettings?.general?.size}
          label="Taille (cm)"
        />
        <AppFeatureListItem
          enabled={customerSettings?.general?.weight}
          label="Poids (Kg)"
        />
        <AppFeatureListItem
          enabled={customerSettings?.general?.shoeSize}
          label="Pointure"
        />
        <AppFeatureListItem
          enabled={customerSettings?.general?.isCustomer}
          label="Client non-plongeur"
        />
        <AppFeatureListItem
          enabled={customerSettings?.general?.customField1}
          label={
            customerSettings?.general?.customField1
              ? `Champ personnalisé N°1: "${customerSettings?.general?.customField1Label}"`
              : 'Champ personnalisé N°1'
          }
        />
        {customerSettings?.general?.customField2 && (
          <AppFeatureListItem
            enabled={customerSettings?.general?.customField2}
            label={
              customerSettings?.general?.customField2
                ? `Champ personnalisé N°2: "${customerSettings?.general?.customField2Label}"`
                : 'Champ personnalisé N°2'
            }
          />
        )}
        {customerSettings?.general?.customField3 && (
          <AppFeatureListItem
            enabled={customerSettings?.general?.customField3}
            label={
              customerSettings?.general?.customField3
                ? `Champ personnalisé N°3: "${customerSettings?.general?.customField3Label}"`
                : 'Champ personnalisé N°3'
            }
          />
        )}
        <AppFeatureListItem
          enabled={customerSettings?.general?.customBoolean1}
          label={
            customerSettings?.general?.customBoolean1
              ? `Case à cocher perso N°1: "${customerSettings?.general?.customBoolean1Label}"`
              : 'Case à cocher perso N°1'
          }
        />
        {customerSettings?.general?.customBoolean2 && (
          <AppFeatureListItem
            enabled={customerSettings?.general?.customBoolean2}
            label={
              customerSettings?.general?.customBoolean2
                ? `Case à cocher perso N°2: "${customerSettings?.general?.customBoolean2Label}"`
                : 'Case à cocher perso N°2'
            }
          />
        )}
        {customerSettings?.general?.customBoolean3 && (
          <AppFeatureListItem
            enabled={customerSettings?.general?.customBoolean3}
            label={
              customerSettings?.general?.customBoolean3
                ? `Case à cocher perso N°3: "${customerSettings?.general?.customBoolean3Label}"`
                : 'Case à cocher perso N°3'
            }
          />
        )}
      </ul>
    </>
  );
};
