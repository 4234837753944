/* eslint-disable @typescript-eslint/no-unused-vars */
import { ClubProductPackageOffer_ServiceCategory } from '@mabadive/app-common-model';
import React from 'react';
import { ClubSettingsSection } from '../../../../../_core';
import { ClubSettingsProductOffersFiltersArea } from '../../../../ClubSettingsProductOffersDialog/form/ClubSettingsProductOffersFiltersArea';
import { ClubSettingsServicesPageBasePanel } from '../../../_common';
import { ClubSettingsServicesViewPanelOffersPricesActionsBar } from '../../../_common/offers-prices/view/ClubSettingsServicesViewPanelOffersPricesActionsBar';
import {
  ClubSettingsServicesViewPanelOffersPricesLocalState,
  useClubSettingsServicesViewPanelOffersPricesLocalState,
} from '../../../_common/offers-prices/view/useClubSettingsServicesViewPanelOffersPricesLocalState.hook';
import { ClubSettingsServicesPageLocalState } from '../../../useClubSettingsServicesPageLocalState.hook';
import { ClubSettingsServicesStoreViewPanel_CategoryGroup } from './ClubSettingsServicesStoreViewPanel_CategoryGroup';

export const ClubSettingsServicesStoreViewPanel = ({
  localState: parentState,
}: {
  localState: ClubSettingsServicesPageLocalState;
}) => {
  const contextMode: ClubProductPackageOffer_ServiceCategory = 'store';
  const localState: ClubSettingsServicesViewPanelOffersPricesLocalState =
    useClubSettingsServicesViewPanelOffersPricesLocalState({
      parentState,
      contextMode,
    });

  const { data, state, actions } = localState;
  const offersByDisplayGroups = data.offersByDisplayGroups;
  const offersByCategory = data.offersByCategory;

  // const hamburgerMenuItems: AppHamburgerMenuItem[] =
  //   useClubSettingsProductOffersFormOfferMenuItems({
  //     contextMode,
  //     localState,
  //     offer,
  //   });

  return (
    <ClubSettingsServicesPageBasePanel localState={parentState}>
      <div className="grid gap-4 md:gap-6">
        <ClubSettingsSection
          title="Tarifs"
          comment={
            data.offersAllBeforeSearch.length === 0
              ? 'Définissez vos tarifs'
              : undefined
          }
          // onClick={
          //   state.editEnabled
          //     ? () =>
          //         actions.openOfferEditDialog({
          //           mode: 'edit',
          //           offers: data.offersAll,
          //         })
          //     : undefined
          // }
          search={
            data.offersAllBeforeSearch.length === 0 ? null : (
              <input
                className="w-90 max-w-full hover:border-app-blue rounded px-2"
                // autoFocus={autoFocus}
                autoComplete="new-password"
                placeholder="Recherche"
                type="text"
                defaultValue={state.searchText}
                onChange={(e) => {
                  state.setSearchText(e.target.value);
                }}
              />
            )
          }
          actions={
            <ClubSettingsServicesViewPanelOffersPricesActionsBar
              localState={localState}
              contextMode={contextMode}
            />
          }
        >
          <ClubSettingsProductOffersFiltersArea
            className="my-2"
            filtersState={localState.state.filtersState}
          />

          {/* <div className="mt-4 flex gap-4">
        {data.offersAll.length > 0 && (
          <AppButton
            color={'primary-outline-light'}
            size="normal"
            icon={AppIcons.action?.edit}
            onClick={(e) => {
              e.stopPropagation();
              actions.switchToEditMode(data.offersAll);
            }}
          >
            Modifier tout
          </AppButton>
        )}
      </div> */}
          {offersByCategory.map((categoryGroup, index) => (
            <ClubSettingsServicesStoreViewPanel_CategoryGroup
              key={index}
              categoryGroup={categoryGroup}
              localState={localState}
            />
          ))}

          <div className="min-h-[200px]"></div>
        </ClubSettingsSection>
        {/* <AppButton
          className="my-4"
          size="normal"
          icon={AppHeroIcons.actionAdd}
          color="primary-outline-light"
          onClick={() => {
            // modelsFieldArray.append({
            //   ref: '',
            //   label: '',
            // } as any);
          }}
        >
          Ajouter une catégorie
        </AppButton> */}
      </div>
    </ClubSettingsServicesPageBasePanel>
  );
};
