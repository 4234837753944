/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/no-unused-vars */
import clsx from 'clsx';
import React from 'react';
import { useRedirect } from 'src/business/_core/data/hooks';
import {
  AppBreadcrumbBar,
  AppButton,
  AppDocumentationLink,
  AppLoadableContentContainer,
  AppPageContainer,
  AppPageContentContainer,
  AppTabsTailwind,
} from 'src/business/_core/modules/layout';
import {
  AppHeroIcons,
  AppIcons,
  AppIconsSettings,
} from 'src/business/_core/modules/layout/icons';
import { useClubSettings } from 'src/business/club/data/hooks';
import {
  UseClubDialogsProps,
  useClubDialogsActionsPersist,
} from 'src/pages/_dialogs';
import { SETTINGS_CUSTOMERS_PAGE_ONLINE_BOOKING } from '../../../../SETTINGS_CUSTOMERS.const';
import {
  EcommerceCategoryEditorTitle,
  EcommerceCustomerCurrentPageLink,
} from '../../components';
import { ClubSettingsOnlineBookingProductsPageLocalState } from '../../useClubSettingsOnlineBookingProductsPageLocalState.hook';
import { ProProductsPage_CategoryViewPanel_TabArticles } from './ProProductsPage_CategoryViewPanel_TabArticles';
import { ProProductsPage_CategoryViewPanel_TabCategoryDescription } from './ProProductsPage_CategoryViewPanel_TabCategoryDescription';
import { ProProductsPage_CategoryViewPanel_TabProducts } from './ProProductsPage_CategoryViewPanel_TabProducts';
import {
  ProProductsPage_CategoryViewPanelLocalState,
  useProProductsPage_CategoryViewPanelLocalState,
} from './useProProductsPage_CategoryViewPanelLocalState.hook';

export const ProProductsPage_CategoryViewPanel = ({
  localState: parentState,
}: {
  localState: ClubSettingsOnlineBookingProductsPageLocalState;
}) => {
  const actionsPersist: UseClubDialogsProps = useClubDialogsActionsPersist();
  const clubSettings = useClubSettings();
  const redirectTo = useRedirect();

  const generalSettings = clubSettings.general;

  const localState: ProProductsPage_CategoryViewPanelLocalState =
    useProProductsPage_CategoryViewPanelLocalState({
      parentState,
    });

  const { state, data, actions, dialogs } = localState;
  const { category, categoryBooklet, categoryBookletPage } = data;

  return (
    <>
      {/* {!!operationInProgressMessage && (
        <div className={'app-card app-p-content--lg'}>
          <LoadingProgressBar>{operationInProgressMessage}</LoadingProgressBar>
        </div>
      )} */}
      <AppPageContainer className={'bg-white'}>
        <AppBreadcrumbBar
          color={AppIconsSettings.customers.color}
          items={[
            {
              icon: AppIconsSettings.general,
              label: 'Paramètres',
              url: '/club/settings',
            },
            {
              icon: AppIconsSettings.customers.onlineBooking,
              label: 'Vente en ligne',
              url: SETTINGS_CUSTOMERS_PAGE_ONLINE_BOOKING.url,
            },
            {
              label: 'Catégories',
              url: SETTINGS_CUSTOMERS_PAGE_ONLINE_BOOKING.url,
            },
          ]}
        >
          <AppDocumentationLink
            className="block h-9 w-9 md:h-11 md:w-11"
            url="https://docs.mabadive.com/docs/vente-en-ligne/presentation_vente-en-ligne"
          />
        </AppBreadcrumbBar>
        <AppPageContentContainer
          paddingBottom={false}
          className="bg-gray-50 app-p-content"
        >
          <AppLoadableContentContainer {...state.loadableContent}>
            {/* <a
              onClick={() => {
                actions.showCategories();
              }}
              className="text-sm uppercase text-gray-600 py-4 cursor-pointer hover:underline"
            >
              ← Retour aux catégories
            </a> */}
            <AppBreadcrumbBar
              className="px-4"
              classNameInner="max-w-6xl mx-auto"
              firstItemClassName="ml-0"
              color={'#1a7498'}
              // color={data.appUiTheme?.text?.textColor ?? '#444556'}
              items={[
                {
                  icon: AppIcons.ecommerce.onlineBooking,
                  label: 'Catalogue',
                  onClick: () => actions.showCategories(),
                },
                {
                  label: category?.name,
                },
              ]}
            />
            <div className="mt-4 grid gap-4 sm:gap-8 lg:gap-16">
              <div
                className={clsx(
                  'app-card app-p-content rounded-t-xl app-card-highlight grid gap-4 relative',
                )}
                // onClick={(e) => {
                //   actions.selectCategory(category?._id);
                // }}
              >
                <div className="flex justify-between gap-4 items-start">
                  <EcommerceCategoryEditorTitle
                    className="flex-grow"
                    displayWidth="screen"
                    category={category}
                    categoryBookletPage={categoryBookletPage}
                  />
                  <div className="grid gap-4">
                    {state.enableCatalog && (
                      <div className="">
                        <AppButton
                          className="whitespace-nowrap"
                          fullWidth={false}
                          color="primary-bg"
                          icon={AppHeroIcons.linkExternal}
                          href={state.customerSpaceCurrentPageURL}
                          target="mabadive_diver_app"
                        >
                          <span className="">Ouvrir l'espace client</span>
                        </AppButton>
                      </div>
                    )}
                  </div>
                </div>
                <AppTabsTailwind
                  className="my-2 w-full"
                  tabs={state.tabs}
                  selected={state.tabId}
                  theme="underline"
                  breakpoint="sm"
                  onChange={(tabId) => state.setTabId(tabId)}
                />
                {state.tabId === 'products' && (
                  <ProProductsPage_CategoryViewPanel_TabProducts
                    localState={localState}
                  />
                )}
                {state.tabId === 'articles' && (
                  <ProProductsPage_CategoryViewPanel_TabArticles
                    localState={localState}
                  />
                )}
                {state.tabId === 'content' && (
                  <ProProductsPage_CategoryViewPanel_TabCategoryDescription
                    localState={localState}
                  />
                )}
              </div>
              <div className="app-card app-p-content grid gap-4">
                <EcommerceCustomerCurrentPageLink localState={parentState} />
              </div>
            </div>
          </AppLoadableContentContainer>
        </AppPageContentContainer>
      </AppPageContainer>
    </>
  );
};
