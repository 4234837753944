/* eslint-disable @typescript-eslint/no-unused-vars */
import { RegionalSettings } from '@mabadive/app-common-model';
import { diveModeFormatter } from '@mabadive/app-common-services';
import React from 'react';
import {
  useClubResume,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import { AppFeatureBadge } from 'src/business/club/modules/_common/ui/AppFeatureBadge';
import { ClubSettingsSection } from 'src/pages/SE-settings/_core';
import {
  useAppCurrencyMain,
  useRegionalSettings,
} from 'src/pages/_components/options';
import {
  ClubDialogsState,
  UseClubDialogsProps,
  useClubDialogs,
  useClubDialogsActionsPersist,
} from 'src/pages/_dialogs';
import { ClubSettingsServicesActivitiesViewPanelDiveMode } from './ClubSettingsServicesActivitiesViewPanelDiveMode';

export const ClubSettingsServicesActivitiesViewPanelFreeDive = () => {
  const diveCenterResume = useDiveCenterResume();
  const clubResume = useClubResume();

  const clubSettings = clubResume?.clubSettings;

  const actionsPersist: UseClubDialogsProps = useClubDialogsActionsPersist();
  const dialogsState: ClubDialogsState = useClubDialogs(actionsPersist);

  const regionalSettings: RegionalSettings = useRegionalSettings({
    effectiveDate: new Date(),
  });

  const mainCurrency = useAppCurrencyMain();

  return (
    <ClubSettingsSection title={'Apnée'}>
      <div className="relative grid gap-2 font-bold text-gray-600">
        <AppFeatureBadge
          enabled={clubSettings.freeDive.enabled}
          label={diveModeFormatter.formatDiveMode('free-dive', {
            format: 'short-ref-label',
            diveModesConf: clubSettings?.ui?.diveMode,
          })}
          icon={
            <ClubSettingsServicesActivitiesViewPanelDiveMode diveMode="free-dive" />
          }
        />
        <AppFeatureBadge
          enabled={clubSettings.ui?.diveMode?.['free-dive-auto']?.enabled}
          label={diveModeFormatter.formatDiveMode('free-dive-auto', {
            format: 'short-ref-label',
            diveModesConf: clubSettings?.ui?.diveMode,
          })}
          icon={
            <ClubSettingsServicesActivitiesViewPanelDiveMode diveMode="free-dive-auto" />
          }
        />
        {/* <AppFeatureBadge géré comme des formations de plongée pour le moment
          enabled={clubSettings.ui?.diveMode?.['free-dive-training']?.enabled}
          label={diveModeFormatter.formatDiveMode('free-dive-training', {
            format: 'short-ref-label',
            diveModesConf: clubSettings?.ui?.diveMode,
          })}
          icon={
            <ClubSettingsServicesActivitiesViewPanelDiveMode diveMode="free-dive-training" />
          }
        /> */}
      </div>
    </ClubSettingsSection>
  );
};
