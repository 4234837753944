import { AppIconsSettings } from 'src/business/_core/modules/layout/icons';
import { SettingsCategory, SettingsCategoryPage } from '../_core';

export const SETTINGS_OTHER_PAGE_FORM_FIELDS: SettingsCategoryPage = {
  id: 'form-fields',
  label: 'Champs de formulaire',
  icon: AppIconsSettings.customers.formFields,
  url: '/club/settings/other/form-fields',
  roles: ['club-view-settings-global'],
};

export const SETTINGS_OTHER_PAGE_ACCOMODATIONS: SettingsCategoryPage = {
  id: 'accommodations',
  label: 'Hébergements',
  icon: AppIconsSettings.customers.accomodation,
  url: '/club/settings/other/accomodations',
  checkAuth: ({ az, clubSettings }) =>
    az?.edit?.settings?.accomodation &&
    clubSettings?.general?.accommodations?.enabled,
};

export const SETTINGS_OTHER_PAGE_AGENCIES: SettingsCategoryPage = {
  id: 'agencies',
  label: 'Agences',
  icon: AppIconsSettings.customers.agencies,
  url: '/club/settings/other/agencies',
  checkAuth: ({ az, clubSettings }) =>
    az?.edit?.settings?.agencies && clubSettings?.general?.agencies?.enabled,
};

export const SETTINGS_OTHER_GENERAL: SettingsCategoryPage = {
  id: 'general',
  label: 'Configuration générale',
  icon: AppIconsSettings.other.general,
  url: '/club/settings/other/company-general',
  roles: ['club-view-settings'],
};
export const SETTINGS_OTHER_DISPLAY: SettingsCategoryPage = {
  id: 'display',
  label: 'Préférences d\'affichage',
  icon: AppIconsSettings.other.display,
  url: '/club/settings/other/display',
  roles: ['club-edit-settings'],
};
export const SETTINGS_OTHER_DATA_MANAGEMENT: SettingsCategoryPage = {
  id: 'data-management',
  label: 'Gestion des données',
  icon: AppIconsSettings.other.data,
  url: '/club/settings/other/data-management',
  checkAuth: ({ auth, az, clubSettings }) => az?.edit?.settings?.archiveData,
};

export const SETTINGS_OTHER: SettingsCategory = {
  id: 'other-settings',
  label: 'Configuration',
  color: AppIconsSettings.other.color,
  icon: AppIconsSettings.other.main,
  pages: [
    SETTINGS_OTHER_GENERAL,
    SETTINGS_OTHER_DISPLAY,
    SETTINGS_OTHER_DATA_MANAGEMENT,
    SETTINGS_OTHER_PAGE_FORM_FIELDS,
    SETTINGS_OTHER_PAGE_ACCOMODATIONS,
    SETTINGS_OTHER_PAGE_AGENCIES,
    // SETTINGS_ACCOUNT_PAGE_PROFILE, SETTINGS_ACCOUNT_PAGE_SUBSCRIPTION
  ],
};
