/* eslint-disable @typescript-eslint/no-unused-vars */
import clsx from 'clsx';
import React from 'react';
import { apiDownloader } from 'src/_common-browser';
import {
  AppButton,
  AppVisible,
  useAutoFocus,
} from 'src/business/_core/modules/layout';
import { AppIconsAction } from 'src/business/_core/modules/layout/icons';
import {
  AppFormControlV2,
  AppInputBooleanSwitch,
  AppSingleAutocomplete2,
} from 'src/lib/form';
import { ClubDiversListPageLocalState } from '../useClubDiversListPageLocalState.hook';

export const ClubDiversListPageConfigBarExtraFilters = ({
  localState,
  className,
}: {
  localState: ClubDiversListPageLocalState;
  className?: string;
}) => {
  const { filters } = localState;

  const autoFocus = useAutoFocus();
  const {
    searchText,
    setSearchText,
    mainCertificationReference,
    setMainCertificationReference,
    diveCertificationsOptions,
    hasDiveToday,
    setHasDiveToday,
    hasDiveTomorrow,
    setHasDiveTomorrow,
  } = filters;
  return (
    <div className={clsx('flex flex-col gap-2', className)}>
      <input
        className="xs:hidden mx-1 w-full hover:border-app-blue rounded px-2"
        autoFocus={autoFocus}
        autoComplete="new-password"
        placeholder="Recherche par nom, prénom..."
        type="text"
        defaultValue={searchText}
        onChange={(e) => {
          setSearchText(e.target.value);
        }}
      />

      <div className="w-full grid grid-cols-2 sm:grid-cols-3 md:grid-cols-6 gap-x-2 gap-y-2">
        <AppFormControlV2
          className="col-span-2 sm:col-span-3 w-full max-w-72 bg-white px-2"
          label="Niveau"
          renderComponent={() => (
            <AppSingleAutocomplete2
              className="text-app-xxs"
              fullWidth={true}
              value={mainCertificationReference}
              options={diveCertificationsOptions}
              onChange={(value) => {
                setMainCertificationReference(value);
              }}
            />
          )}
        />
        <AppFormControlV2
          className="col-span-2 xs:col-span-1 w-full bg-white px-2"
          label="Plonge demain"
          renderComponent={() => (
            <AppInputBooleanSwitch
              label={''}
              value={hasDiveTomorrow}
              onChange={(hasDiveTomorrow) =>
                setHasDiveTomorrow(hasDiveTomorrow)
              }
            />
          )}
        />
        <AppFormControlV2
          className="col-span-2 xs:col-span-1 w-full bg-white px-2"
          label="Aujourd'hui"
          renderComponent={() => (
            <AppInputBooleanSwitch
              label={''}
              value={hasDiveToday}
              onChange={(hasDiveToday) => setHasDiveToday(hasDiveToday)}
            />
          )}
        />
        <AppVisible role="club-download-divers">
          <div className="col-span-2 xs:col-span-1 flex justify-end items-end">
            <AppButton
              icon={AppIconsAction.download}
              color="primary-outline-light"
              onClick={() =>
                apiDownloader.downloadDiversSheet({
                  criteria: null,
                })
              }
            >
              <span className="">Télécharger</span>
            </AppButton>
          </div>
        </AppVisible>
      </div>
    </div>
  );
};
