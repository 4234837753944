import { useMemo } from 'react';
import { useAppDevice } from 'src/business/_core/modules/layout';
import {
  AppHeroIcons,
  AppIconsAction,
} from 'src/business/_core/modules/layout/icons';
import {
  useAppSecurityUserClubAuthorizations,
  useAppSecurityUserHasRole,
} from 'src/business/auth/services';
import {
  useClubResume,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import { AppHamburgerMenuItem } from 'src/business/club/modules/_common/ui';
import { DiveSessionSecuritySheetCardMenuActionContext } from './DiveSessionSecuritySheetCardMenuActionContext.type';
import { DiveSessionSecuritySheetCardMenuActionsDef } from './DiveSessionSecuritySheetCardMenuActionsDef.type';

export const useDiveSessionSecuritySheetCardMenu = ({
  menuActions: menuActionsDef,
  menuContext,
}: {
  menuActions: DiveSessionSecuritySheetCardMenuActionsDef;
  menuContext: DiveSessionSecuritySheetCardMenuActionContext;
}): AppHamburgerMenuItem[] => {
  const clubResume = useClubResume();
  const clubSettings = clubResume?.clubSettings;
  const diveCenterResume = useDiveCenterResume();

  const az = useAppSecurityUserClubAuthorizations();

  const { isMobile, deviceOS } = useAppDevice();

  const isEditSecuritySheetRole = useAppSecurityUserHasRole(
    'club-edit-planning-security-sheet',
  );
  const isEditSessionRole = useAppSecurityUserHasRole(
    'club-edit-planning-session',
  );
  const isEditSettingsDiveCenterRole = useAppSecurityUserHasRole(
    'club-edit-settings-dive-center',
  );

  const hamburgerMenuItemsBuilder = useMemo(() => {
    const menuActions = menuActionsDef ? menuActionsDef(menuContext) ?? {} : {};
    const hamburgerMenuItems: AppHamburgerMenuItem[] = [];
   
    if (isEditSessionRole && menuActions['edit-session-dialog']) {
      hamburgerMenuItems.push({
        id: 'configure-session',
        title: 'Configurer la session',
        description: 'Heure, site de plongée, bateau...',
        icon: AppHeroIcons.actionSettings,
        ...menuActions['edit-session-dialog'],
      });
    }
    if (isEditSecuritySheetRole && menuActions['edit-groups']) {
      hamburgerMenuItems.push({
        id: 'configure-groups',
        title: 'Configurer les palanquées',
        description: 'Configurer palanquées et encadrants',
        icon: AppHeroIcons.group,
        ...menuActions['edit-groups'],
      });
    }
    if (
      isEditSessionRole &&
      menuActions['massive-session-move-divers-dialog']
    ) {
      hamburgerMenuItems.push({
        id: 'massive-session-move-divers',
        title: 'Déplacer des participants',
        description: 'Déplacement rapide de participants ou palanquées...',
        icon: AppHeroIcons.actionEditBulk,
        ...menuActions['massive-session-move-divers-dialog'],
      });
    }
    if (
      isEditSessionRole &&
      menuActions['massive-session-copy-divers-dialog']
    ) {
      hamburgerMenuItems.push({
        id: 'massive-session-copy-divers',
        title: 'Copier des participants',
        description: 'Copie rapide de participants ou palanquées...',
        icon: AppHeroIcons.actionEditBulk,
        ...menuActions['massive-session-copy-divers-dialog'],
      });
    }
    if (isEditSettingsDiveCenterRole && menuActions['edit-staff-members']) {
      hamburgerMenuItems.push({
        id: 'configure-staff-members',
        title: 'Configurer les moniteurs',
        description: 'Configurer les moniteurs et encadrants',
        icon: AppHeroIcons.actionEditAlt,
        ...menuActions['edit-staff-members'],
      });
    }
    if (menuActions['print-security-sheet']) {
      hamburgerMenuItems.push({
        id: 'print-security-sheet',
        title: 'Imprimer la fiche de sécurité',
        description: 'Exportez la fiche de sécurité au format Excel',
        icon: AppHeroIcons.actionPrint,
        ...menuActions['print-security-sheet'],
      });
    }

    if (menuActions['export-daily-resume']) {
      // TODO à revoir: ce systeme ne permet pas de gérer de multiples menus ici
      hamburgerMenuItems.push({
        ...menuActions['export-daily-resume'],
      });
    }

    if (isEditSessionRole && menuActions['duplicate-session-dialog']) {
      hamburgerMenuItems.push({
        id: 'duplicate-session',
        title: 'Dupliquer la session',
        description: 'bateau, site, plongeurs, palanquées...',
        icon: AppHeroIcons.actionClone,
        ...menuActions['duplicate-session-dialog'],
      });
    }
    if (isEditSessionRole && menuActions['close-session']) {
      hamburgerMenuItems.push({
        title: 'Fermer la session',
        description: 'Fermer la session si elle n\'est pas encore confirmée',
        icon: AppHeroIcons.actionLock,
        severity: 'info',
        ...menuActions['close-session'],
      });
    }
    if (isEditSessionRole && menuActions['cancel-session']) {
      hamburgerMenuItems.push({
        title: 'Annuler la session',
        description: 'Annuler la session et toutes les plongées',
        icon: AppHeroIcons.actionCancel,
        severity: 'danger',
        ...menuActions['cancel-session'],
      });
    }
    if (isEditSessionRole && menuActions['delete-session']) {
      hamburgerMenuItems.push({
        title: 'Supprimer cette session',
        description: 'Supprimer définitivement cette session',
        icon: AppHeroIcons.actionRemove,
        severity: 'danger',
        ...menuActions['delete-session'],
      });
    }
    if (isEditSessionRole && menuActions['split-multiple-session']) {
      hamburgerMenuItems.push({
        title: 'Diviser en 2 sorties simples',
        description: 'Diviser la double-blocs en 2 sorties simples',
        icon: AppIconsAction.generate,
        severity: 'warn',
        ...menuActions['split-multiple-session'],
      });
    }
    if (isEditSessionRole && menuActions['open-session']) {
      hamburgerMenuItems.push({
        title: 'Ouvrir la session',
        description: 'Puis inscrivez-y des participants',
        icon: AppHeroIcons.actionOpen,
        severity: 'danger',
        ...menuActions['open-session'],
      });
    }
    if (isEditSessionRole && menuActions['create-session-dialog']) {
      hamburgerMenuItems.push({
        id: 'create-session',
        title: 'Ajouter une session',
        description: 'Créer une nouvelle session',
        icon: AppHeroIcons.actionAdd,
        ...menuActions['create-session-dialog'],
      });
    }
    if (
      menuActions['send-session-message'] &&
      clubSettings.communication?.session?.enableMessage &&
      az.communication?.action?.sendSessionMessage
    ) {
      hamburgerMenuItems.push({
        id: 'send-session-message',
        title: 'Envoyer un MESSAGE',
        description: 'Envoyer un message aux participants',
        icon: AppHeroIcons.sms,
        ...menuActions['send-session-message'],
      });
    }

    return hamburgerMenuItems;
  }, [
    az.communication?.action?.sendSessionMessage,
    clubSettings.communication?.session?.enableMessage,
    isEditSecuritySheetRole,
    isEditSessionRole,
    isEditSettingsDiveCenterRole,
    menuActionsDef,
    menuContext,
  ]);

  return hamburgerMenuItemsBuilder;
};
