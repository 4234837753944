/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ClubProductPackageOfferView,
  ClubPurchasePackage,
  ClubPurchasePackageMeta,
  ClubSettings,
} from '@mabadive/app-common-model';
import { dateService } from '@mabadive/app-common-services';
import { useMemo } from 'react';
import { useClubResume } from 'src/business/club/data/hooks';
import { DiverBookingPageAggregatedData } from '../../models';
import { defaultBookingBuilder } from '../../services';
import { DiverPurchaseStoreFormModel } from './components';

export function useDiverPurchaseStoreEditorDialogInitialValue({
  diveCenterId,
  aggregatedData,
  initialPurchasePackage,
  initialPurchasePackageMeta,
  productPackageOffers,
  mode,
  clubSettings,
}: {
  diveCenterId: string;
  aggregatedData: DiverBookingPageAggregatedData;
  initialPurchasePackage?: ClubPurchasePackage;
  initialPurchasePackageMeta: ClubPurchasePackageMeta;
  productPackageOffers: ClubProductPackageOfferView[];
  mode: 'create' | 'edit';
  clubSettings: ClubSettings;
}): DiverPurchaseStoreFormModel {
  const clubResume = useClubResume();

  const initialFormValue: DiverPurchaseStoreFormModel = useMemo(() => {
    if (mode === 'create') {
      const initialBookingId = defaultBookingBuilder.getDefaultBookingId(
        aggregatedData.bookingResumesVisible,
        {
          diveCenterId,
          focusDiverId: aggregatedData.focus?.clubDiver._id,
        },
      );

      const iv: DiverPurchaseStoreFormModel = {
        productPackageOfferReference: undefined,
        unitPrice: undefined,
        totalPriceThirdPartyCollect: 0,
        creditsInitialCount: 1,
        unitQuantity: 1,
        extraCosts: [],
        purchaseDate: dateService.getUTCDateWithoutTimeFromLocalTime(
          new Date(),
        ),
        validityStatus: 'completed',
        creditsAdditionalCount: 0,
        consumedExternalCount: 0,
        bookingId: initialBookingId,
        billedByBookingAgencyId: undefined,
      };
      return iv;
    } else {
      const purchasePackage = initialPurchasePackage;
      const meta = initialPurchasePackageMeta;
      const initialCredits = purchasePackage.credits;
      if (purchasePackage) {
        let productPackageOfferReference =
          purchasePackage.productPackageOffer.reference;

        const iv: DiverPurchaseStoreFormModel = {
          productPackageOfferReference,
          purchaseDate: purchasePackage.purchaseDate,
          unitPrice: purchasePackage.unitPrice,
          totalPriceThirdPartyCollect:
            purchasePackage.totalPriceThirdPartyCollect,
          extraCosts: purchasePackage.extraCosts ?? [],
          discountAmount: purchasePackage.discountAmount,
          unitQuantity: purchasePackage.unitQuantity,
          comment: purchasePackage.comment,
          validityStatus: purchasePackage.validityStatus,
          creditsAdditionalCount: initialCredits.creditsAdditionalCount,
          creditsInitialCount: initialCredits.creditsInitialCount,
          consumedExternalCount: initialCredits.consumedExternalCount,
          bookingId: purchasePackage.bookingId,
          billedByBookingAgencyId: purchasePackage.billedByBookingAgencyId,
        };
        return iv;
      }
    }
  }, [
    mode,
    initialPurchasePackage,
    aggregatedData.bookingResumesVisible,
    aggregatedData.focus?.clubDiver._id,
    diveCenterId,
    initialPurchasePackageMeta,
  ]);

  return initialFormValue;
}
