/* eslint-disable @typescript-eslint/no-unused-vars */
import { default as React } from 'react';
import { useAppSecurityUserClubAuthorizations } from 'src/business/auth/services';
import { AppFeatureListItem } from 'src/business/club/modules/_common/ui';
import { useAppCurrencyMain } from 'src/pages/_components/options';
import {
  useClubResume,
  useDiveCenterResume,
} from '../../../../../business/club/data/hooks';

export const ClubSettingsCustomerViewCard_Booking = () => {
  const mainCurrency = useAppCurrencyMain();
  const diveCenterResume = useDiveCenterResume();
  const clubResume = useClubResume();

  const clubSettings = clubResume?.clubSettings;
  const customerSettings = clubSettings?.customer;

  const az = useAppSecurityUserClubAuthorizations();

  return (
    <>
      <h2 className="py-2  text-left uppercase text-lg leading-6 font-medium text-gray-800">
        Réservation
      </h2>
      <ul className="relative grid gap-2 p-2 sm:px-4 font-bold text-gray-600">
        <AppFeatureListItem
          enabled={customerSettings?.booking?.externalRef}
          label={`ID perso${
            customerSettings?.booking?.externalRefLabel
              ? `: "${customerSettings?.booking?.externalRefLabel}"`
              : ''
          }`}
        />

        <AppFeatureListItem
          enabled={customerSettings?.booking?.agency}
          label="Agence"
        />
        <AppFeatureListItem
          enabled={customerSettings?.booking?.customField1}
          label={
            customerSettings?.booking?.customField1
              ? `Champ personnalisé N°1: "${customerSettings?.booking?.customField1Label}"`
              : 'Champ personnalisé N°1'
          }
        />
        {customerSettings?.booking?.customField2 && (
          <AppFeatureListItem
            enabled={customerSettings?.booking?.customField2}
            label={
              customerSettings?.booking?.customField2
                ? `Champ personnalisé N°2: "${customerSettings?.booking?.customField2Label}"`
                : 'Champ personnalisé N°2'
            }
          />
        )}
        {customerSettings?.booking?.customField3 && (
          <AppFeatureListItem
            enabled={customerSettings?.booking?.customField3}
            label={
              customerSettings?.booking?.customField3
                ? `Champ personnalisé N°3: "${customerSettings?.booking?.customField3Label}"`
                : 'Champ personnalisé N°3'
            }
          />
        )}
        <AppFeatureListItem
          enabled={customerSettings?.booking?.customBoolean1}
          label={
            customerSettings?.booking?.customBoolean1
              ? `Case à cocher perso N°1: "${customerSettings?.booking?.customBoolean1Label}"`
              : 'Case à cocher perso N°1'
          }
        />
        {customerSettings?.booking?.customBoolean2 && (
          <AppFeatureListItem
            enabled={customerSettings?.booking?.customBoolean2}
            label={
              customerSettings?.booking?.customBoolean2
                ? `Case à cocher perso N°2: "${customerSettings?.booking?.customBoolean2Label}"`
                : 'Case à cocher perso N°2'
            }
          />
        )}
        {customerSettings?.booking?.customBoolean3 && (
          <AppFeatureListItem
            enabled={customerSettings?.booking?.customBoolean3}
            label={
              customerSettings?.booking?.customBoolean3
                ? `Case à cocher perso N°3: "${customerSettings?.booking?.customBoolean3Label}"`
                : 'Case à cocher perso N°3'
            }
          />
        )}
      </ul>
    </>
  );
};
