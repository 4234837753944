import { Switch } from '@headlessui/react';
import clsx from 'clsx';
import React from 'react';

export type AppInputBooleanSwitchProps = {
  label?: string | React.ReactNode;
  labelToggleFalse?: string | React.ReactNode;
  label2?: string | React.ReactNode;
  icon?: React.ReactNode;
  labelSrOnly?: string;
  className?: string;
  value?: boolean;
  disabled?: boolean;
  theme?: 'info' | 'warn' | 'danger' | 'success' | 'secondary';
  onChange?: (value: boolean) => void;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
};
export function AppInputBooleanSwitch({
  label,
  label2,
  icon,
  labelToggleFalse,
  labelSrOnly,
  className,
  value,
  disabled,
  theme,
  onChange,
  onClick,
}: AppInputBooleanSwitchProps) {
  return (
    <Switch.Group
      as="div"
      className={clsx(
        'flex items-center',
        disabled ? 'cursor-not-allowed' : 'cursor-pointer',
        className,
      )}
    >
      <Switch
        disabled={disabled}
        checked={value}
        onChange={onChange}
        onClick={onClick}
        // className={clsx(
        //   value ? 'bg-app-primary' : 'bg-gray-200',
        //   'relative inline-flex items-center flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-100 focus:outline-none',
        // )}
        className={clsx(
          disabled ? 'cursor-not-allowed' : 'cursor-pointer',
          value
            ? theme === 'secondary'
              ? disabled
                ? 'bg-app-secondary/50'
                : 'bg-app-secondary'
              : theme === 'danger'
              ? disabled
                ? 'bg-status-error/50'
                : 'bg-status-error'
              : theme === 'warn'
              ? disabled
                ? 'bg-status-warn/50'
                : 'bg-status-warn'
              : theme === 'success'
              ? disabled
                ? 'bg-status-success/50'
                : 'bg-status-success'
              : disabled
              ? 'bg-status-info/50'
              : 'bg-status-info'
            : 'bg-gray-200',
          'relative inline-flex items-center flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none',
        )}
      >
        <span className="sr-only">{labelSrOnly}</span>
        <span
          className={clsx(
            value ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none relative inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-100',
          )}
        >
          <span
            className={clsx(
              value
                ? 'opacity-0 ease-out duration-100'
                : 'opacity-100 ease-in duration-100',
              'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity',
            )}
            aria-hidden="true"
          >
            <svg
              className="h-3 w-3 text-gray-400"
              fill="none"
              viewBox="0 0 12 12"
            >
              <path
                d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                stroke="currentColor"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <span
            className={clsx(
              value
                ? 'opacity-100 ease-in duration-100'
                : 'opacity-0 ease-out duration-100',
              'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity',
            )}
            aria-hidden="true"
          >
            <svg
              className={clsx(
                'h-3 w-3 ',
                theme === 'secondary'
                  ? 'text-app-secondary'
                  : theme === 'danger'
                  ? 'text-status-error'
                  : theme === 'warn'
                  ? 'text-status-warn'
                  : theme === 'success'
                  ? 'text-status-success'
                  : 'text-status-info',
                disabled && 'opacity-50',
              )}
              fill="currentColor"
              viewBox="0 0 12 12"
            >
              <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
            </svg>
          </span>
        </span>
      </Switch>
      {(icon || label || (!value && labelToggleFalse) || label2) && (
        <Switch.Label
          as="span"
          className="pl-1 lg:pl-2 flex items-center gap-1"
        >
          {icon && (
            <span className={value ? '' : 'grayscale opacity-50'}>{icon}</span>
          )}
          <div>
            {(label || (!value && labelToggleFalse)) && (
              <div
                className={`text-xs uppercase font-bold ${
                  value ? 'text-gray-600' : 'text-gray-400'
                }`}
              >
                {!value ? labelToggleFalse ?? label : label}
              </div>
            )}
            {label2 && (
              <div
                className={`italic text-xs ${
                  value ? 'text-gray-600' : 'text-gray-400'
                }`}
              >
                {label2}
              </div>
            )}
          </div>
        </Switch.Label>
      )}
    </Switch.Group>
  );
}
