import React, { useMemo } from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { AppPriceLabel } from 'src/business/club/modules/_common/ui/AppPriceLabel';
import { diverPurchasePackageBuilder } from 'src/business/club/modules/club-diver-participant/pages/DiverBookingPage/components/DiverPurchaseCommonEditorDialog';
import {
  AppFormControlRHF_Deprecated,
  AppFormControlV2,
} from 'src/lib/form/components/AppFormControl';
import { AppInputRHF } from 'src/lib/form/components/AppInputRHF/AppInputRHF';
import { useAppCurrencyMain } from 'src/pages/_components/options';
import { AppPackageExtraCostFormModel } from './model';

export const AppPackageExtraCostFormEditExtraCost = ({
  form,
  extraCostIndex,
  onClickDelete,
  className,
}: {
  form: UseFormReturn<AppPackageExtraCostFormModel, any>;
  extraCostIndex: number;
  onClickDelete: () => any;
  className?: string;
}) => {
  const { control, register, handleSubmit, reset, watch, trigger, formState } =
    form;
  const mainCurrency = useAppCurrencyMain();

  const extraCostControlBaseName = `extraCosts[${extraCostIndex}]`;

  const [multiplier, unitPrice, unitQuantity, extraCostUnitDive] = useWatch({
    control,
    name: [
      `${extraCostControlBaseName}.multiplier`,
      `${extraCostControlBaseName}.unitPrice`,
      `${extraCostControlBaseName}.unitQuantity`,
      `${extraCostControlBaseName}.extraCostUnitDive`,
    ] as any[],
  });

  const { unitTotalPrice, totalPrice } = useMemo(
    () =>
      diverPurchasePackageBuilder.buildExtraCostTotals({
        multiplier,
        unitPrice,
        unitQuantity,
      }),
    [multiplier, unitPrice, unitQuantity],
  );

  return (
    <div className="relative">
      <div
        className="absolute -top-10 cursor-pointer right-0 p-2 rounded-full bg-red-200 hover:bg-red-400 text-white"
        onClick={(e) => {
          if (onClickDelete) {
            e.stopPropagation();
            onClickDelete();
          }
        }}
      >
        <AppHeroIcons.actionDelete className="w-6 h-6" />
      </div>
      <div className="flex flex-wrap gap-4">
        <AppFormControlRHF_Deprecated
          className={'w-full sm:w-42 md:w-48 lg:w-64'}
          label={'Nom'}
          required={true}
          control={control}
          name={`${extraCostControlBaseName}.label` as any}
          renderComponent={(props) => (
            <>
              <AppInputRHF {...props} fullWidth type="text" />
            </>
          )}
        />
        <AppFormControlRHF_Deprecated
          className={'w-full sm:w-20 lg:w-32'}
          label={`Prix - ${mainCurrency.sign}`}
          required={true}
          control={control}
          name={`${extraCostControlBaseName}.unitPrice` as any}
          renderComponent={(props) => (
            <>
              <AppInputRHF {...props} fullWidth type="number" />
            </>
          )}
        />
        <AppFormControlRHF_Deprecated
          className={'w-full sm:w-20 lg:w-32'}
          label={'Quantité'}
          required={true}
          control={control}
          name={`${extraCostControlBaseName}.unitQuantity` as any}
          renderComponent={(props) => (
            <AppInputRHF {...props} fullWidth type="number" />
          )}
        />
        <AppFormControlV2
          className={`w-full sm:w-20 ${
            extraCostUnitDive ? '' : 'hidden sm:flex sm:invisible'
          }`}
          label={'Prix'}
          renderComponent={() => (
            <AppPriceLabel
              className="text-gray-500 font-bold"
              amount={unitTotalPrice}
              mainCurrency={mainCurrency}
            />
          )}
        />
        <AppFormControlRHF_Deprecated
          className={`w-full sm:w-24 lg:w-32 ${
            extraCostUnitDive ? '' : 'hidden sm:flex sm:invisible'
          }`}
          label={'Nb plongées'}
          required={true}
          control={control}
          name={`${extraCostControlBaseName}.multiplier` as any}
          renderComponent={(props) => (
            <>
              <AppInputRHF {...props} fullWidth type="number" />
            </>
          )}
        />
        <AppFormControlV2
          className={'w-full sm:w-20'}
          label={'Prix Total'}
          renderComponent={() => (
            <AppPriceLabel
              className="text-app-primary font-bold"
              amount={totalPrice}
              mainCurrency={mainCurrency}
            />
          )}
        />
      </div>
      <AppFormControlRHF_Deprecated
        className={'w-full'}
        label="Commentaire privé 🚫"
        control={control}
        name={`${extraCostControlBaseName}.comment` as any}
        renderComponent={(props) => <AppInputRHF {...props} fullWidth />}
      />
    </div>
  );
};
