/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { useAppSecurityUserHasRole } from 'src/business/auth/services';
import { AppInputBooleanSwitchRHF } from 'src/lib/form';
import { AppFormControlRHF } from 'src/lib/form/components/AppFormControl/AppFormControlRHF';
import { AppSingleSelect2HeadlessUIRHF } from 'src/lib/form/components/AppSingleSelect/AppSingleSelect2HeadlessUIRHF';
import { ClubSettingsSection } from 'src/pages/SE-settings/_core';
import {
  useAppPaymentPlatformCaptureMethodsOptions,
  useAppPaymentPlatformEnvOptions,
  useAppPaymentPlatformOptions,
} from 'src/pages/_components/options';
import { ClubSettingsGeneralFormModel } from '../../../_model';

export const ClubSettingsGeneralPaymentsEditFormPlatform = ({
  form,
}: {
  form: UseFormReturn<ClubSettingsGeneralFormModel>;
}) => {
  const {
    register,
    handleSubmit,
    watch,
    formState,
    control,
    setValue,
    reset,
    setFocus,
  } = form;

  const paymentPlatformOptions = useAppPaymentPlatformOptions({
    format: 'full-technical',
    filter: 'all',
  });
  const paymentCaptureMethodOptions =
    useAppPaymentPlatformCaptureMethodsOptions();

  const paymentPlatformEnvOptions = useAppPaymentPlatformEnvOptions();

  const [
    paymentsEnabled,
    stripeEnabled,
    paymentsOnlinePlatformsEnabled,
    paymentsOnlinePlatformId,
    onlineBookingEnabled,
  ] = useWatch({
    control,
    name: [
      'clubSettings.general.payments.enabled',
      'clubSettings.general.payments.online.stripe.enabled',
      'clubSettings.general.payments.online.platformsEnabled',
      'clubSettings.general.payments.online.platformId',
      'clubSettings.general.onlineBooking.enabled',
    ],
  });

  const isSuperAdmin = useAppSecurityUserHasRole('super-admin');

  return paymentsEnabled && isSuperAdmin ? (
    <ClubSettingsSection title="Paiement en ligne">
      <div className="mt-2 grid gap-x-4 gap-y-2 sm:flex sm:flex-wrap">
        <AppFormControlRHF
          label="Plateformes de paiement en ligne"
          control={control}
          name={'clubSettings.general.payments.online.platformsEnabled'}
          required={false}
          renderComponent={(props) => <AppInputBooleanSwitchRHF {...props} />}
        />
        {paymentsOnlinePlatformsEnabled && (
          <>
            <AppFormControlRHF
              label="Paiement en ligne Stripe"
              control={control}
              name={'clubSettings.general.payments.online.stripe.enabled'}
              required={false}
              renderComponent={(props) => (
                <AppInputBooleanSwitchRHF {...props} />
              )}
            />
            {stripeEnabled && (
              <AppFormControlRHF
                className="w-32"
                label="Environnement"
                control={control}
                name={'clubSettings.general.payments.online.stripe.env'}
                required={true}
                renderComponent={(props) => (
                  <AppSingleSelect2HeadlessUIRHF
                    theme="material-ui"
                    disableClearButton={true}
                    {...props}
                    options={paymentPlatformEnvOptions}
                  />
                )}
              />
            )}
          </>
        )}
        {isSuperAdmin && (
          <AppFormControlRHF
            className="w-80"
            label="Compte Stripe"
            control={control}
            name={'clubSettings.general.payments.online.platformId'}
            required={false}
            renderComponent={(props) => (
              <AppSingleSelect2HeadlessUIRHF
                theme="material-ui"
                disableClearButton={true}
                {...props}
                options={paymentPlatformOptions}
              />
            )}
          />
        )}
      </div>
      <div className="mt-2 grid gap-x-4 gap-y-2 sm:flex sm:flex-wrap">
        {paymentsOnlinePlatformId && (
          <>
            <AppFormControlRHF
              label="Créer un paiement en ligne"
              control={control}
              name={
                'clubSettings.general.payments.online.createPayment.enabled'
              }
              required={false}
              renderComponent={(props) => (
                <AppInputBooleanSwitchRHF {...props} />
              )}
            />
            <AppFormControlRHF
              className="w-80"
              label="Méthode de capture par défaut"
              control={control}
              name={
                'clubSettings.general.payments.online.createPayment.defaultCaptureMethod'
              }
              required={false}
              renderComponent={(props) => (
                <AppSingleSelect2HeadlessUIRHF
                  theme="material-ui"
                  disableClearButton={true}
                  {...props}
                  options={paymentCaptureMethodOptions}
                />
              )}
            />
          </>
        )}
      </div>
      {isSuperAdmin && (
        <div className="mt-2 grid gap-x-4 gap-y-2 sm:flex sm:flex-wrap">
          <AppFormControlRHF
            className=""
            label={'Vente / réservation en ligne'}
            control={control}
            name={'clubSettings.general.onlineBooking.enabled'}
            renderComponent={(props) => (
              <AppInputBooleanSwitchRHF
                {...props}
                label={'Activé'}
                onChange={(enabled) => {
                  if (!enabled) {
                    // on désactive aussi l'espace client
                    setValue(
                      'clubSettings.publicSettings.customerSpace.onlineBooking.enableCatalog',
                      enabled,
                    );
                  }
                }}
              />
            )}
          />
          {onlineBookingEnabled && (
            <AppFormControlRHF
              className=""
              label={'Visible sur l\'espace client'}
              control={control}
              name={
                'clubSettings.publicSettings.customerSpace.onlineBooking.enableCatalog'
              }
              renderComponent={(props) => (
                <AppInputBooleanSwitchRHF {...props} label={'Activé'} />
              )}
            />
          )}
        </div>
      )}
    </ClubSettingsSection>
  ) : null;
};
