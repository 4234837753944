/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ClubSettings_ConfigAssistant_Progress,
  ClubSettings_ConfigAssistant_ProgressStepKey,
} from '@mabadive/app-common-model';
import clsx from 'clsx';
import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { AppIconsUI } from 'src/business/_core/modules/layout/icons';
import {
  useClubResume,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import { DiveCenterProBadge } from 'src/pages/_components/company';
import {
  AppFormControlV2,
  AppInput,
  AppInputBooleanSwitch,
} from '../../../lib/form';
import { SettingsCategory, SettingsCategoryPage } from '../_core';
import { ClubSettingsCategoryCardPageBadge } from '../SE-00_home/componentts/ClubSettingsCategoryCardPageBadge';
import { ConfigAssistantProgressStep } from './model';
import { ClubSettings_ConfigAssistantPageLocalState } from './useClubSettingsAccountDisplayPageLocalState.hook';
export const ClubSettings_ConfigAssistantPage_SettingItem = ({
  localState,
  progressStep,
  as = 'div',
  className,
}: {
  localState: ClubSettings_ConfigAssistantPageLocalState;
  progressStep: ConfigAssistantProgressStep;
  as?: React.ElementType;
  className?: string;
}) => {
  const page: SettingsCategoryPage = progressStep.page;
  const category: SettingsCategory = progressStep.category;
  const progressKey: ClubSettings_ConfigAssistant_ProgressStepKey =
    progressStep.progressKey;
  const progress: ClubSettings_ConfigAssistant_Progress = progressStep.progress;

  const { actions, state } = localState;

  const displayExpertMode = localState.state?.displayExpertMode;

  const Icon = page.icon ?? category.icon;

  const clubResume = useClubResume();
  const diveCenterResume = useDiveCenterResume();
  const isMultiDiveCenters = clubResume.diveCenters.length > 1;

  const [customerHelpMessage, setCustomerHelpMessage] = useState(
    progress.customerHelpMessage,
  );
  const debounceTimeout = useRef<NodeJS.Timeout | null>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setCustomerHelpMessage(value);

    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout(async () => {
      await actions.updateSettingsConfigAssistant(progressKey, {
        ...progress,
        customerHelpMessage: value,
      });
    }, 2000); // Délai de 2 secondes
  };

  return React.createElement(
    as,
    {
      className: clsx('group/page cursor-pointer', className),
    },
    <>
      <Link to={page.url} className="block hover:bg-gray-50">
        <div className="flex items-center px-4 py-4 sm:px-6">
          <div className="flex min-w-0 flex-1 items-center truncate">
            <div className="flex-shrink-0">
              <Icon
                className={clsx(
                  'h-8 w-8  md:h-9 md:w-9 lg:h-10 lg:w-10 2xl:h-12 2xl:w-12 opacity-80 group-hover/page:opacity-100',
                )}
                style={{
                  color: category.color,
                }}
              />
            </div>
            <div className="flex-grow px-4 flex gap-2 justify-between text-left">
              <div>
                <p
                  className="truncate text-base font-bold uppercase opacity-80 group-hover/page:opacity-100"
                  style={{
                    color: category.color,
                  }}
                >
                  {page.label}
                </p>
                {page?.description && (
                  <p
                    className="mt-2 flex items-center text-sm"
                    style={{
                      color: category.color,
                    }}
                  >
                    {/* <AppHeroIcons.comment
                      className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                      aria-hidden="true"
                    /> */}
                    <span className="truncate">{page?.description}</span>
                  </p>
                )}
              </div>
              <div className="hidden md:block">
                <div>
                  {isMultiDiveCenters && page?.isDiveCenterSpecificConfig && (
                    <DiveCenterProBadge
                      className=""
                      diveCenter={diveCenterResume}
                    />
                  )}
                  {page.badge && (
                    <ClubSettingsCategoryCardPageBadge
                      category={category}
                      page={page}
                      badge={page.badge}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div>
            <AppIconsUI.navRight
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </div>
        </div>
        <div
          className="app-p-content"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <div className="flex gap-8 justify-between">
            <div className="flex gap-8 flex-wrap">
              <AppInputBooleanSwitch
                value={progressStep.progress.configSet}
                label="Terminé"
                disabled={progressStep.progress.configChecked}
                onChange={async () => {
                  await actions.updateSettingsConfigAssistant(progressKey, {
                    ...progress,
                    configSet: !progress.configSet,
                  });
                }}
              />
              {displayExpertMode && (
                <div className="flex gap-8 flex-wrap">
                  {' '}
                  <AppInputBooleanSwitch
                    value={progressStep.progress.configChecked}
                    label="Vérifié"
                    theme="success"
                    onChange={async () => {
                      await actions.updateSettingsConfigAssistant(progressKey, {
                        ...progress,
                        configChecked: !progress.configChecked,
                      });
                    }}
                  />
                  <AppInputBooleanSwitch
                    value={progressStep.progress.enabled}
                    label="Activé"
                    onChange={async () => {
                      await actions.updateSettingsConfigAssistant(progressKey, {
                        ...progress,
                        enabled: !progress.enabled,
                      });
                    }}
                  />
                </div>
              )}
              {(displayExpertMode || !progressStep.progress.configChecked) && (
                <AppInputBooleanSwitch
                  value={progressStep.progress.customerHelpRequired}
                  label="J'ai besoin d'aide"
                  disabled={
                    progressStep.progress.configChecked && !displayExpertMode
                  }
                  onChange={async () => {
                    await actions.updateSettingsConfigAssistant(progressKey, {
                      ...progress,
                      customerHelpRequired: !progress.customerHelpRequired,
                    });
                  }}
                />
              )}
            </div>
            {displayExpertMode && (
              <div className="flex gap-8 flex-wrap">
                <AppInputBooleanSwitch
                  value={progressStep.progress.enabled}
                  label="Activé"
                  onChange={async () => {
                    await actions.updateSettingsConfigAssistant(progressKey, {
                      ...progress,
                      enabled: !progress.enabled,
                    });
                  }}
                />
              </div>
            )}
          </div>
          {(displayExpertMode || !progressStep.progress.configChecked) &&
            progressStep.progress.customerHelpRequired && (
              <AppFormControlV2
                className="mt-4 w-full"
                label="Comment peut-on vous aider?"
                renderComponent={() => (
                  <AppInput
                    type="text"
                    value={customerHelpMessage}
                    onChange={handleChange}
                  />
                )}
              />
            )}
        </div>
      </Link>
    </>,
  );
};
