/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useMemo } from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { useAppSecurityUserHasRole } from 'src/business/auth/services';
import {
  useClubResume,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';

import { AppCompanyMessageTemplate } from '@mabadive/app-common-model';
import { clubDefaultMessageTemplatesBuilder } from '@mabadive/app-common-services';
import { AppButtonMultiActions } from '../../../../../../../../business/_core/modules/layout';
import {
  AppHeroIcons,
  AppIconsAction,
  AppIconsSocialMedia,
} from '../../../../../../../../business/_core/modules/layout/icons';
import { AppHamburgerMenuItem } from '../../../../../../../../business/club/modules/_common/ui';
import {
  AppFormControlRHF,
  AppInputBooleanSwitchRHF,
  AppInputRHF,
  AppSingleSelect2HeadlessUIRHF,
} from '../../../../../../../../lib/form';
import { useAppCompanyMessageTemplatesOptions } from '../../../../../../../_components/options';
import { ClubDialogsState } from '../../../../../../../_dialogs';
import { ClubSettingsOnlineBookingFormModel } from '../../../_model';

export const ClubSettingsOnlineBookingEmailsForm_EmailOption = ({
  form,
  dialogsState,
  optionName,
  label,
}: {
  form: UseFormReturn<ClubSettingsOnlineBookingFormModel>;
  dialogsState: ClubDialogsState;
  optionName:
    | 'onCustomerSubmitBookingWithoutPendingDeposit'
    | 'onCustomerSubmitBookingWithPendingDeposit'
    | 'onProConfirmBooking';
  label: string;
}) => {
  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const isSuperAdmin = useAppSecurityUserHasRole('super-admin');

  const clubResume = useClubResume();
  const clubSettings = clubResume?.clubSettings;
  const diveCenterResume = useDiveCenterResume();

  const { messageTemplatesOptions, messageTemplates } =
    useAppCompanyMessageTemplatesOptions({
      messageContext: 'booking',
      scope: 'all',
    });

  const baseFormName =
    `clubSettings.general.onlineBooking.emails.${optionName}` as const;

  const [emailEnabled, messageTemplateId] = useWatch({
    control,
    name: [`${baseFormName}.enabled`, `${baseFormName}.messageTemplateId`],
  });
  const messageTemplate = useMemo(
    () => (messageTemplates ?? []).find((x) => x._id === messageTemplateId),
    [messageTemplateId, messageTemplates],
  );
  const editEnabled = useAppSecurityUserHasRole('club-edit-settings-general');

  const openMessageTemplateCreateDialog = useCallback(
    (messageTemplate: AppCompanyMessageTemplate) => {
      dialogsState.appCompanyMessageTemplateEditDialog.openDialog({
        mode: 'create',
        defaultValue: {
          messageTemplate,
          clubSettings,
        },
      });
    },
    [clubSettings, dialogsState.appCompanyMessageTemplateEditDialog],
  );
  const openMessageTemplateEditDialog = useCallback(
    (messageTemplate: AppCompanyMessageTemplate) => {
      dialogsState.appCompanyMessageTemplateEditDialog.openDialog({
        mode: 'edit',
        defaultValue: {
          messageTemplate,
          clubSettings,
        },
      });
    },
    [clubSettings, dialogsState.appCompanyMessageTemplateEditDialog],
  );
  const hamburgerMenuItems: AppHamburgerMenuItem[] = useMemo(() => {
    const hamburgerMenuItems: AppHamburgerMenuItem[] = [];

    if (editEnabled && messageTemplate) {
      hamburgerMenuItems.push({
        id: 'edit-message',
        titleShort: 'Modifier',
        title: 'Modifier',
        icon: AppHeroIcons.actionEdit,
        onClick: () => {
          openMessageTemplateEditDialog(messageTemplate);
        },
      });
    }
    if (editEnabled) {
      const newMessageTemplate =
        clubDefaultMessageTemplatesBuilder.buildNewMessageTemplate({
          clubReference: clubResume.reference,
          diveCenterId: diveCenterResume._id,
          context: 'booking',
        });
      hamburgerMenuItems.push({
        id: 'create-message',
        titleShort: 'Nouveau',
        title: 'Nouveau',
        icon: AppIconsAction.add,
        onClick: () => {
          openMessageTemplateCreateDialog(newMessageTemplate);
        },
      });
    }
    return hamburgerMenuItems;
  }, [
    editEnabled,
    messageTemplate,
    openMessageTemplateEditDialog,
    clubResume.reference,
    diveCenterResume._id,
    openMessageTemplateCreateDialog,
  ]);

  return (
    <div className="grid gap-2">
      <h3 className="text-base text-app-primary font-bold uppercase">
        <AppIconsSocialMedia.mail className="inline-block w-6 h-6 mr-2" />
        {label}
      </h3>
      <div className="flex gap-4 flex-wrap items-start">
        <AppFormControlRHF
          label={'Envoi de l\'email'}
          disabled={!isSuperAdmin}
          control={control}
          name={`${baseFormName}.enabled`}
          renderComponent={(props) => (
            <AppInputBooleanSwitchRHF {...props} label={'Email activé'} />
          )}
        />

        {emailEnabled && (
          <>
            <AppFormControlRHF
              label="Message à envoyer"
              control={control}
              name={`${baseFormName}.messageTemplateId`}
              required={true}
              renderComponent={(props) => (
                <div className="flex gap-2">
                  <AppSingleSelect2HeadlessUIRHF
                    {...props}
                    className="w-[28rem]"
                    theme="tailwind"
                    disableClearButton={true}
                    options={messageTemplatesOptions}
                  />
                  {editEnabled && (
                    <div className="text-gray-600">
                      <AppButtonMultiActions items={hamburgerMenuItems} />
                    </div>
                  )}
                </div>
              )}
            />
            {emailEnabled && (
              <AppFormControlRHF
                control={control}
                className="w-80"
                name={`${baseFormName}.copyToAddress`}
                label={'Recevoir une copie à'}
                required={false}
                helpDescription="Recevoir une copie de chaque mail à cette adresse"
                validation={{
                  email: true,
                }}
                renderComponent={(props) => (
                  <AppInputRHF className="w-full" type="text" {...props} />
                )}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};
