/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ClubPublicSettings,
  ClubPublicSettingsCustomerSpace,
  ClubSettings,
} from '@mabadive/app-common-model';
import { jsonPatcher } from '@mabadive/app-common-services';
import React, { useCallback, useState } from 'react';
import { AppButton, AppLoaderSpinner } from 'src/business/_core/modules/layout';
import { useClubSettings } from 'src/business/club/data/hooks';
import { ClubPublicSettingsCustomerSpaceOnlineBooking } from '../../../../../../../../../mabadive-libs/app-common-libs/app-common-model/src/entities/company/settings/club-settings/ClubPublicSettingsCustomerSpaceOnlineBooking.type';
import { proMultiMutationsApiClient } from '../../../../../../business/_core/data/app-operation';
import { AppHeroIcons } from '../../../../../../business/_core/modules/layout/icons';
import { appClipboardCopier } from '../../../../../../business/club/modules/club-diver-participant/pages/DiverBookingPage/components/BookingCustomerConfigEditDialog/_services';
import {
  AppFormControlV2,
  AppInputBooleanSwitch,
} from '../../../../../../lib/form';
import { ClubSettingsOnlineBookingProductsPageLocalState } from '../useClubSettingsOnlineBookingProductsPageLocalState.hook';

export const EcommerceCustomerCurrentPageLink = ({
  localState,
  className,
}: {
  localState: ClubSettingsOnlineBookingProductsPageLocalState;
  className?: string;
}) => {
  const clubSettings = useClubSettings();

  const customerSpaceCurrentPageURL =
    localState.state.customerSpaceCurrentPageURL;

  const enableCatalog =
    clubSettings?.publicSettings?.customerSpace?.onlineBooking?.enableCatalog;

  const [
    operationInProgressToogleVisibility,
    setOperationInProgressToogleVisibility,
  ] = useState<boolean>(false);

  const persistToogleCustomerOption = useCallback(
    async (
      onlineBooking: Partial<ClubPublicSettingsCustomerSpaceOnlineBooking>,
    ) => {
      const initialValue: ClubSettings = clubSettings;
      const finalValue: ClubSettings = {
        ...initialValue,
        publicSettings: {
          ...(initialValue.publicSettings ?? ({} as ClubPublicSettings)),
          customerSpace: {
            ...(initialValue.publicSettings?.customerSpace ??
              ({} as ClubPublicSettingsCustomerSpace)),
            onlineBooking: {
              ...(initialValue.publicSettings?.customerSpace?.onlineBooking ??
                ({} as ClubPublicSettingsCustomerSpaceOnlineBooking)),
              ...onlineBooking,
            },
          },
        },
      };

      const patchOperations = jsonPatcher.compareObjects(
        initialValue,
        finalValue,
        {
          attributesToReplaceFully: [],
        },
      );
      if (patchOperations.length) {
        const updatedClubSettings = {
          pk: initialValue._id,
          patchOperations,
        };
        try {
          setOperationInProgressToogleVisibility(true);
          await proMultiMutationsApiClient.update({
            updatedClubSettings,
          });
        } finally {
          setOperationInProgressToogleVisibility(null);
        }
      }
    },
    [clubSettings],
  );

  return (
    <div className={className}>
      <div className="grid gap-2">
        <div className="flex gap-4 flex-wrap items-end">
          <AppFormControlV2
            labelClassName="text-gray-600"
            label={'Afficher le catalogue des activités'}
            renderComponent={() => (
              <AppInputBooleanSwitch
                label={'Activé'}
                labelToggleFalse={'Désactivé'}
                value={enableCatalog}
                onChange={(enableCatalog) => {
                  persistToogleCustomerOption({
                    enableCatalog,
                  });
                }}
              />
            )}
          />
          {operationInProgressToogleVisibility && (
            <AppLoaderSpinner className="inline-block w-6 h-6" />
          )}
        </div>
        {enableCatalog && (
          <div className="grid gap-2">
            <div
              className={
                'my-2 flex gap-2 items-end justify-between sm:justify-start flex-wrap'
              }
            >
              <div className="">
                <label
                  className="relative text-sm font-medium text-gray-600"
                  // style={{ top: '-16px', bottom: '-16px' }}
                >
                  Lien vers cette page sur l'espace client.
                </label>
                <div className="border border-gray-200 bg-gray-50 p-1 whitespace-nowrap overflow-x-auto">
                  <a
                    className="w-full text-app-xxs sm:text-xs text-app-blue underline"
                    href={customerSpaceCurrentPageURL}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {customerSpaceCurrentPageURL}
                  </a>
                </div>
              </div>
              <div className="flex gap-2">
                {navigator?.clipboard && (
                  <AppButton
                    fullWidth={false}
                    color="primary-outline-light"
                    icon={AppHeroIcons.copyClipboard}
                    onClick={async () => {
                      appClipboardCopier.copyToClipboard(
                        customerSpaceCurrentPageURL,
                      );
                    }}
                  >
                    <span className="hidden sm:inline">copier</span>
                  </AppButton>
                )}
                <AppButton
                  fullWidth={false}
                  color="primary-outline-light"
                  icon={AppHeroIcons.linkExternal}
                  href={customerSpaceCurrentPageURL}
                  target="mabadive_diver_app"
                >
                  <span className="hidden sm:inline">ouvrir</span>
                </AppButton>
              </div>
            </div>
          </div>
        )}
        {!enableCatalog && (
          <p className="text-gray-500">
            Le catalogue est désactivé sur l'espace client.
          </p>
        )}
      </div>
    </div>
  );
};
