/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ClubProductPackageOffer_ServiceCategory,
  ClubProductPackageOfferCategoryView,
  ClubProductPackageOfferView,
  ClubProductPackageOfferViewDisplayCategory,
  ClubSettings,
} from '@mabadive/app-common-model';
import { dataSorter } from '@mabadive/app-common-services';

export const offersDisplayCategoryBuilder = {
  buildGroups,
};
function buildGroups({
  clubSettings,
  offers,
  productPackageOfferCategories,
  contextMode,
}: {
  clubSettings: ClubSettings;
  offers: ClubProductPackageOfferView[];
  productPackageOfferCategories: ClubProductPackageOfferCategoryView[];
  contextMode: ClubProductPackageOffer_ServiceCategory;
}) {
  const offersByDisplayCategory: ClubProductPackageOfferViewDisplayCategory[] =
    productPackageOfferCategories.reduce((acc, offerCategory) => {
      const offersByDisplayCategory: ClubProductPackageOfferViewDisplayCategory =
        {
          category: offerCategory,
          offers: [],
        };
      acc.push(offersByDisplayCategory);
      return acc;
    }, [] as ClubProductPackageOfferViewDisplayCategory[]);

  const remainingOffers: ClubProductPackageOfferView[] = [];
  for (const offer of offers) {
    const offerCategory = offersByDisplayCategory.find(
      (category) => category.category._id === offer.categoryId,
    );
    if (offerCategory) {
      offerCategory.offers.push(offer);
    } else {
      remainingOffers.push(offer);
    }
  }

  const sortedOffersByDisplayCategory = dataSorter.sortMultiple(
    offersByDisplayCategory,
    {
      getSortAttributes: (
        offerCategory: ClubProductPackageOfferViewDisplayCategory,
      ) => {
        return [
          {
            value: offerCategory?.offers?.length === 0 ? 2 : 1,
            type: 'default',
          },
          {
            value: offerCategory?.category?.title,
            type: 'default',
            asc: true,
          },
          {
            value: offerCategory?.offers?.length,
            type: 'default',
            asc: false,
          },
        ];
      },
    },
  );

  // Ajouter les offres restantes dans un groupe "Autres"
  if (remainingOffers.length > 0) {
    sortedOffersByDisplayCategory.push({
      category: null,
      offers: remainingOffers,
    });
  }
  return sortedOffersByDisplayCategory;
}
