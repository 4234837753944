/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useMemo } from 'react';

import {
  DIVE_SERVICE_ORGANIZATION_REFERENCES,
  DiveServiceOrganizationReference,
  SCUBA_DIVING_INSTRUCTOR_DEGREE_TEMPLATES,
  SCUBA_DIVING_INSTRUCTOR_LEVELS,
  ScubaDivingInstructorDegreeName,
  ScubaDivingInstructorLevel,
} from '@mabadive/app-common-model';
import { arrayBuilder } from '@mabadive/app-common-services';
import { useWatch } from 'react-hook-form';
import { ValueLabel } from 'src/business/club/modules/_common/form';
import {
  AppFormControlRHF,
  AppInputBooleanRHF,
  AppInputRHF,
  AppSingleAutocomplete2RHF,
} from 'src/lib/form';
import { AppSingleSelect2HeadlessUIRHF } from 'src/lib/form/components/AppSingleSelect/AppSingleSelect2HeadlessUIRHF';
import { AppInputDatePickerMaterialRHF } from 'src/stories/components/04-form';
import { StaffMemberEditDialogLocalState } from '../useStaffMemberEditDialogLocalState.hook';

const DIVE_ORGANIZATION_OPTIONS = DIVE_SERVICE_ORGANIZATION_REFERENCES.map(
  (reference) => {
    const option: ValueLabel<DiveServiceOrganizationReference> = {
      label: reference.toUpperCase(),
      value: reference,
    };
    return option;
  },
);

const SCUBA_DIVING_INSTRUCTOR_LEVEL_OPTIONS =
  SCUBA_DIVING_INSTRUCTOR_LEVELS.map((level) => {
    const option: ValueLabel<ScubaDivingInstructorLevel> = {
      label: level,
      value: level,
    };
    return option;
  });

export const StaffMemberEditForm_Tab3_StaffDetails = ({
  localState,
  className,
}: {
  localState: StaffMemberEditDialogLocalState;
  className?: string;
}) => {
  const {
    state: { form },
  } = localState;
  const { control, setValue } = form;

  const [scubaDivingInstructorDegreeLevel] = useWatch({
    control,
    name: ['staffMember.scubaDivingInstructor.degree.level'],
  });

  const SCUBA_DIVING_INSTRUCTOR_DEGREE_TEMPLATE_OPTIONS = useMemo(() => {
    const templates = SCUBA_DIVING_INSTRUCTOR_DEGREE_TEMPLATES.filter(
      (x) =>
        !scubaDivingInstructorDegreeLevel ||
        scubaDivingInstructorDegreeLevel === x.level,
    ).map((x) => x.name);

    return arrayBuilder.filterDuplicated(templates).map((degreeName) => {
      const option: ValueLabel<ScubaDivingInstructorDegreeName> = {
        label: degreeName,
        value: degreeName,
      };
      return option;
    });
  }, [scubaDivingInstructorDegreeLevel]);

  return (
    <div className={className}>
      {/* <h2 className="text-left text-sm sm:text-lg leading-6 py-2 font-medium text-app-blue uppercase">
        Instructeur de plongée
      </h2> */}
      <div
        className={
          'w-full flex flex-col sm:grid sm:grid-cols-2 md:grid-cols-4 gap-4'
        }
      >
        <AppFormControlRHF
          className="md:col-span-2 w-full"
          label="Niveau instructeur"
          control={control}
          name={'staffMember.scubaDivingInstructor.degree.level'}
          renderComponent={(props) => (
            <AppSingleSelect2HeadlessUIRHF
              {...props}
              className="w-full text-app-xxs"
              disableClearButton={false}
              theme={'material-ui'}
              // value={diveSession.diveTourSession1?.divingDirector?.staffId}
              options={SCUBA_DIVING_INSTRUCTOR_LEVEL_OPTIONS}
            />
          )}
        />
        <AppFormControlRHF
          className="md:col-span-2 w-full"
          label="Diplôme"
          control={control}
          name={'staffMember.scubaDivingInstructor.degree.name'}
          renderComponent={(props) => (
            <AppSingleAutocomplete2RHF
              {...props}
              className="w-full"
              options={SCUBA_DIVING_INSTRUCTOR_DEGREE_TEMPLATE_OPTIONS}
              onChange={(degreeName) => {
                if (degreeName) {
                  let template = SCUBA_DIVING_INSTRUCTOR_DEGREE_TEMPLATES.find(
                    (x) =>
                      x.name === degreeName &&
                      (!scubaDivingInstructorDegreeLevel ||
                        x.level === scubaDivingInstructorDegreeLevel),
                  );
                  if (!template) {
                    // incompatible template: find first compatible
                    template = SCUBA_DIVING_INSTRUCTOR_DEGREE_TEMPLATES.find(
                      (x) => x.name === degreeName,
                    );
                  }
                  if (template.level !== scubaDivingInstructorDegreeLevel) {
                    setValue(
                      'staffMember.scubaDivingInstructor.degree.level',
                      template?.level,
                    );
                  }
                }
              }}
            />
          )}
        />
        <AppFormControlRHF
          className="md:col-span-2 w-full"
          // label={'Stagiaire'}
          control={control}
          name={'staffMember.scubaDivingInstructor.degree.isTrainee'}
          renderComponent={(props) => (
            <AppInputBooleanRHF
              {...props}
              type="checkbox"
              description={'Stagiaire'}
            />
          )}
        />
        <AppFormControlRHF
          className="md:col-span-2 w-full"
          label="Date diplôme"
          control={control}
          required={false}
          name={'staffMember.scubaDivingInstructor.degree.date'}
          renderComponent={(props) => (
            <AppInputDatePickerMaterialRHF
              {...props}
              initialView="years"
              className="w-full"
            />
          )}
        />
        <AppFormControlRHF
          className="md:col-span-2 w-full"
          label="Organisme"
          control={control}
          name={
            'staffMember.scubaDivingInstructor.degree.organizationReference'
          }
          renderComponent={(props) => (
            <AppSingleAutocomplete2RHF
              {...props}
              className="w-full"
              options={DIVE_ORGANIZATION_OPTIONS}
            />
          )}
        />
        <AppFormControlRHF
          className={'md:col-span-2 w-full'}
          label="Numéro de licence"
          control={control}
          name={
            'staffMember.scubaDivingInstructor.degree.organizationLicenceNumber'
          }
          renderComponent={(props) => (
            <AppInputRHF {...props} fullWidth type="text" />
          )}
        />
      </div>
      <div className={'w-full flex flex-col md:flex-row gap-4'}>
        <AppFormControlRHF
          className={'w-full'}
          label="Commentaire diplôme"
          control={control}
          name={'staffMember.scubaDivingInstructor.degree.comment'}
          renderComponent={(props) => (
            <AppInputRHF {...props} fullWidth multiline rowsMax={15} rows={2} />
          )}
        />
        <AppFormControlRHF
          className={'w-full'}
          label="Autres qualifications"
          control={control}
          name={'staffMember.scubaDivingInstructor.otherDegrees'}
          renderComponent={(props) => (
            <AppInputRHF {...props} fullWidth multiline rowsMax={15} rows={2} />
          )}
        />
      </div>
      <div
        className={
          'w-full flex flex-col sm:grid sm:grid-cols-2 md:grid-cols-4 gap-4'
        }
      >
        <AppFormControlRHF
          className={'md:col-span-2 w-full'}
          label="N° carte professionelle"
          control={control}
          name={'staffMember.scubaDivingInstructor.businessCardNumber'}
          renderComponent={(props) => (
            <AppInputRHF {...props} fullWidth type="text" />
          )}
        />
        <AppFormControlRHF
          className="md:col-span-2 w-full"
          label="Date carte professionelle"
          control={control}
          required={false}
          name={'staffMember.scubaDivingInstructor.businessCardDate'}
          renderComponent={(props) => (
            <AppInputDatePickerMaterialRHF
              {...props}
              initialView="weeks"
              className="w-full"
            />
          )}
        />
        <AppFormControlRHF
          className="md:col-span-2 w-full"
          label="Certificat médical"
          control={control}
          required={false}
          name={'staffMember.scubaDivingInstructor.medicalCertificateDate'}
          renderComponent={(props) => (
            <AppInputDatePickerMaterialRHF
              {...props}
              initialView="weeks"
              className="w-full"
            />
          )}
        />
      </div>
    </div>
  );
};
