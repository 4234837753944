import React from 'react';
import {
  AppTabsTailwind,
  AppTransition,
} from 'src/business/_core/modules/layout/components/_tailwind';
import { AppHamburgerMenuItem } from 'src/business/club/modules/_common/ui';
import { ClubDialogsStateOld } from 'src/pages/_dialogs';
import {
  BookingResumeOnClickMemberAction,
  DiverBookingCardBookingAction,
} from '../../../_from-diver-app';
import {
  AggregatedBookingSessionFull,
  BookingTabModel,
  DiverBookingPageLoadedContentFocus,
  PRO_BookingMemberFull_WithDocs,
  PRO_BookingParticipantFull,
} from '../../models';
import { DiverBookingPageActions } from '../../useDiverBookingPageActions.hook';
import { DiverBookingPageGlobalState } from '../../useDiverBookingPageGlobalState.hook';
import { DiverBookingPageBookingCardLocalState } from '../useDiverBookingPageBookingCardLocalState.hook';
import { DiverBookingCard_TabParticipants } from './DiverBookingCard_TabParticipants';
import { DiverBookingCard_TabRegistrationFuture } from './DiverBookingCard_TabRegistrationFuture';
import { DiverBookingCard_TabRegistrationPast } from './DiverBookingCard_TabRegistrationPast';
import { DiverBookingCardHeader } from './DiverBookingCardHeader';
import { DiverBookingPageBookingTabHeaderAutoFixCustomerUpdates } from './DiverBookingCardHeaderAutoFix';
import { DiverBookingCardMainButtonsBar } from './DiverBookingCardMainButtonsBar';
import {
  DiverBookingCard_LocalState,
  useDiverBookingCard_LocalState,
} from './useDiverBookingCard_LocalState';

export function DiverBookingCard({
  localState: parentState,
  globalState,
  dialogs,
  actions,
  bookingTabModel,
  focus,
  onClickParticipant,
  onClickMember,
  onClickSession,
  onClickBooking,
  extraMenuItems = [],
  className,
}: {
  localState: DiverBookingPageBookingCardLocalState;
  globalState: DiverBookingPageGlobalState;
  dialogs: ClubDialogsStateOld;
  actions: DiverBookingPageActions;
  bookingTabModel: BookingTabModel;
  focus: DiverBookingPageLoadedContentFocus;
  onClickParticipant: (props: {
    bookingSessionFull: AggregatedBookingSessionFull;
    bookingParticipantFull: PRO_BookingParticipantFull;
    bookingMemberFull: PRO_BookingMemberFull_WithDocs;
  }) => void;

  onClickSession: (bookingSessionFull: AggregatedBookingSessionFull) => void;
  onClickBooking: (
    bookingTabModel: BookingTabModel,
    action: DiverBookingCardBookingAction,
  ) => void;
  onClickMember: (attrs: {
    bookingMemberFull: PRO_BookingMemberFull_WithDocs;
    action: BookingResumeOnClickMemberAction;
  }) => void;
  extraMenuItems?: AppHamburgerMenuItem[];
  className?: string;
}) {
  const localState: DiverBookingCard_LocalState =
    useDiverBookingCard_LocalState({
      globalState,
      dialogs,
      actions,
      bookingTabModel,
      focus,
      onClickParticipant,
      onClickMember,
      onClickSession,
      onClickBooking,
      extraMenuItems,
      parentState,
    });

  const { state, data } = localState;

  const { subTab } = state;

  return (
    <div className={`bg-white ${className ?? ''}`}>
      <DiverBookingCardHeader
        globalState={globalState}
        focus={focus}
        bookingTabModel={bookingTabModel}
        onClickMember={onClickMember}
        onClickBooking={onClickBooking}
        extraMenuItems={extraMenuItems}
        isExpanded={state.isExpanded}
        setIsExpanded={state.setIsExpanded}
        actions={actions}
        dialogs={dialogs}
      />
      <AppTransition type="scale" show={state.isExpanded}>
        <DiverBookingCardMainButtonsBar
          className="my-2 p-1 hidden md:flex flex-wrap lg:hidden"
          globalState={globalState}
          actions={actions}
          bookingTabModel={bookingTabModel}
          onClickBooking={onClickBooking}
          setIsExpanded={state.setIsExpanded}
        />
        <AppTabsTailwind
          className="my-2 w-full"
          tabs={state.subTabs}
          selected={subTab}
          theme="underline"
          breakpoint="xs"
          onChange={(tabId) => state.selectSubTab(tabId)}
        />
        {subTab === 'booking-participants-list' && (
          <DiverBookingCard_TabParticipants localState={localState} />
        )}
        {subTab === 'booking-registration' && (
          <DiverBookingCard_TabRegistrationFuture localState={localState} />
        )}{' '}
        {subTab === 'booking-registration-past' && (
          <DiverBookingCard_TabRegistrationPast localState={localState} />
        )}{' '}
        {subTab === 'booking-customer-updates' &&
          state.autoFixes.bookingCustomerUpdates?.length > 0 && (
            <DiverBookingPageBookingTabHeaderAutoFixCustomerUpdates
              bookingTabModel={bookingTabModel}
              globalState={globalState}
              bookingCustomerUpdates={state.autoFixes.bookingCustomerUpdates}
              onAcceptOrDeny={() =>
                state.selectSubTab('booking-participants-list')
              }
            />
          )}
      </AppTransition>
    </div>
  );
}
