/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ClubSettingsUIPlanning,
  StaffMemberResumeSessionsModel,
} from '@mabadive/app-common-model';
import { dateService } from '@mabadive/app-common-services';
import React from 'react';
import { DiveSessionStaffMemberSessionTimes } from '../../club-diver-participant/pages/DiverBookingPage/components/DiveSessionEditorDialog/tabs/DiveSessionDialogTab3Groups/components';
import { ClubPlanningLightPanelViewPeriodDayStaffMember } from './ClubPlanningLightPanelViewPeriodDayStaff';

export const ClubPlanningLightPanelStaffDailyTable = ({
  date,
  dayReference,
  planningConfig,
  availableStaff,
}: {
  date: Date;
  dayReference: string;
  planningConfig: Pick<
    ClubSettingsUIPlanning,
    | 'staffFirstNameBefore'
    | 'showStaffCountsPerDay'
    | 'showStaffCountsPerDayDP'
    | 'showStaffCountsPerDayGP'
    | 'showStaffCountsPerDaySS'
  >;
  availableStaff: StaffMemberResumeSessionsModel[];
}) => {
  const isPast = !dateService.isTodayUTC(date) && dateService.isPastUTC(date);

  return (
    <table>
      <tbody>
        {availableStaff.map((staffMemberResumeSessions) => {
          const staffMemberResumeSessionsDay =
            staffMemberResumeSessions.days.find(
              (x) => x.dayReference === dayReference,
            );
          return (
            <tr key={staffMemberResumeSessions.staffMember._id}>
              <td className="truncate">
                <ClubPlanningLightPanelViewPeriodDayStaffMember
                  className=" "
                  staffFirstNameBefore={planningConfig.staffFirstNameBefore}
                  staffMember={staffMemberResumeSessions.staffMember}
                  date={date}
                  activeToday={staffMemberResumeSessions.activeOnAnySession}
                  availableOrActiveToday={
                    staffMemberResumeSessions.availableInPeriod
                  }
                  onClickEdit={undefined}
                />
              </td>
              <td className="pl-2">
                {staffMemberResumeSessionsDay.availableOrActiveToday && (
                  <DiveSessionStaffMemberSessionTimes
                    className={'w-full'}
                    fontSize={'text-xs'}
                    sessionsDetails={
                      staffMemberResumeSessionsDay.sessionsDetails
                    }
                    onClickSession={undefined}
                    timesToDisplay={'assigned'}
                    // TODO vérifier dans le contexte si besoin de filtrer (dans le doute, on ne filtre pas au moment de l'introduction de ce paramètre)
                    grayScaleCancelled={false}
                    grayScalePastAndEmpty={false}
                    isPast={isPast}
                  />
                )}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
