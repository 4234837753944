/* eslint-disable @typescript-eslint/no-unused-vars */
import { default as React } from 'react';
import { useAppSecurityUserClubAuthorizations } from 'src/business/auth/services';
import { useAppCurrencyMain } from 'src/pages/_components/options';
import {
  useClubResume,
  useDiveCenterResume,
} from '../../../../../business/club/data/hooks';
import { ClubSettingsCustomerViewCard_Booking } from './ClubSettingsCustomerViewCard_Booking';
import { ClubSettingsCustomerViewCard_CustomerContact } from './ClubSettingsCustomerViewCard_CustomerContact';
import { ClubSettingsCustomerViewCard_CustomerMember } from './ClubSettingsCustomerViewCard_CustomerMember';
import { ClubSettingsCustomerViewCard_CustomerOrigins } from './ClubSettingsCustomerViewCard_CustomerOrigins';
import { ClubSettingsCustomerViewCard_Equipment } from './ClubSettingsCustomerViewCard_Equipment';
import { ClubSettingsCustomerViewCard_Participant } from './ClubSettingsCustomerViewCard_Participant';
import { ClubSettingsCustomerViewCard_ResidentTypes } from './ClubSettingsCustomerViewCard_ResidentTypes';

export const ClubSettingsCustomerViewCard = ({
  className,
}: {
  className?: string;
}) => {
  const mainCurrency = useAppCurrencyMain();
  const diveCenterResume = useDiveCenterResume();
  const clubResume = useClubResume();

  const clubSettings = clubResume?.clubSettings;
  const customerSettings = clubSettings?.customer;

  const az = useAppSecurityUserClubAuthorizations();

  return (
    <div className={className}>
      <div className="grid xl:grid-cols-2 gap-4 xl:justify-start xl:items-start">
        <div className="w-full grid gap-4 items-start">
          <ClubSettingsCustomerViewCard_CustomerContact />
          <ClubSettingsCustomerViewCard_CustomerMember />
          <ClubSettingsCustomerViewCard_CustomerOrigins />
        </div>
        <div className="w-full grid gap-4 items-start">
          <ClubSettingsCustomerViewCard_Participant />
          <ClubSettingsCustomerViewCard_Booking />
          <ClubSettingsCustomerViewCard_Equipment />
          <ClubSettingsCustomerViewCard_ResidentTypes />
        </div>
      </div>
    </div>
  );
};
