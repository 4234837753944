/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import {
  AppFormControlRHF,
  AppInputBooleanSwitchRHF,
  AppInputRHF,
} from 'src/lib/form';

import { useAppSecurityUserHasRole } from 'src/business/auth/services';
import { useClubResume, useClubSettings } from 'src/business/club/data/hooks';
import { useAppCurrencyMain } from 'src/pages/_components/options';
import { ClubSettingsProductOffersFormModel } from '../../_model';

export const ClubSettingsProductOffersFormOfferCommon_Tarif = ({
  form,
  offerFieldIndex: i,
  inputClassName = 'w-full',
}: {
  form: UseFormReturn<ClubSettingsProductOffersFormModel>;
  offerFieldIndex: number;
  inputClassName?: string;
}) => {
  const clubResume = useClubResume();
  const clubSettings = useClubSettings();
  const generalSettings = clubResume.clubSettings.general;
  const publicSettings = clubResume.clubSettings.publicSettings;
  const services = clubResume.clubSettings.services;

  const isSuperAdmin = useAppSecurityUserHasRole('super-admin');

  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const appCurrency = useAppCurrencyMain();

  return (
    <>
      <AppFormControlRHF
        className={inputClassName}
        control={control}
        label={`Tarif (${appCurrency.sign})`}
        name={`offers.${i}.price`}
        renderComponent={(props) => (
          <AppInputRHF className="w-full" {...props} type="number" />
        )}
      />
      {generalSettings?.billing?.thirdPartyCollectEnabled && (
        <AppFormControlRHF
          className={inputClassName}
          control={control}
          label="Tarif (part tiers)"
          name={`offers.${i}.thirdPartyCollectPrice`}
          renderComponent={(props) => (
            <AppInputRHF className="w-full" {...props} type="number" />
          )}
        />
      )}
      {generalSettings?.agencies?.enabled &&
        clubSettings.general.agencies.agencyNonCommissionableEnabled && (
          <AppFormControlRHF
            label="Non commissionnable"
            control={control}
            required={false}
            name={`offers.${i}.agencyNonCommissionable`}
            renderComponent={(props) => <AppInputBooleanSwitchRHF {...props} />}
          />
        )}
    </>
  );
};
