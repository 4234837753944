import { AppEntityUpdatePatch } from '@mabadive/app-common-model';
import { changeDescriptorManager } from '@mabadive/app-common-services';
import { useCallback } from 'react';
import { DiverBookingPageUpdateState } from '../../../models';
import { DiverBookingPageSetUpdateStateFn } from '../../../useDiverBookingPageGlobalState.hook';
export function useDiverBookingPageUpdatePurchasePackage({
  setUpdateState,
  updateState,
}: {
  setUpdateState: DiverBookingPageSetUpdateStateFn;
  updateState: DiverBookingPageUpdateState;
}) {
  return useCallback(
    ({
      updatedClubPurchasePackage,
      updatedProducts,
    }: {
      updatedClubPurchasePackage: AppEntityUpdatePatch;
      updatedProducts: AppEntityUpdatePatch[];
    }) => {
      let updateStateLocal = updateState;

      if (updatedClubPurchasePackage || updatedProducts?.length) {
        const purchasePackagesChanges = updatedClubPurchasePackage
          ? changeDescriptorManager.updateOne(updatedClubPurchasePackage, {
              changeDescriptors: updateStateLocal.purchasePackagesChanges,
            })
          : updateStateLocal.purchasePackagesChanges;

        const bookingProductsChanges =
          updatedProducts?.length > 0
            ? changeDescriptorManager.updateMany(updatedProducts, {
                changeDescriptors: updateStateLocal.bookingProductsChanges,
              })
            : updateStateLocal.bookingProductsChanges ?? [];

        updateStateLocal = {
          ...updateStateLocal,
          purchasePackagesChanges,
          bookingProductsChanges,
        };
        setUpdateState(updateStateLocal, {
          action: 'updatePurchasePackage',
        });
      }
    },
    [setUpdateState, updateState],
  );
}
