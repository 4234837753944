/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useMemo } from 'react';
import {
  FieldArrayWithId,
  UseFieldArrayReturn,
  UseFormReturn,
  useWatch,
} from 'react-hook-form';
import {
  AppInputBooleanSwitchRHF,
  AppInputRHF,
  AppSingleAutocomplete2RHF,
} from 'src/lib/form';
import { AppFormControlRHF } from 'src/lib/form/components/AppFormControl/AppFormControlRHF';

import { useAppSecurityUserHasRole } from 'src/business/auth/services';
import { useClubResume, useClubSettings } from 'src/business/club/data/hooks';
import { ValueLabel } from '../../../../../../business/club/modules/_common/form';
import { ClubSettingsProductOffersFormModel } from '../../_model';
import { ClubSettingsProductOffersFormOfferCommon_Tarif } from '../ClubSettingsProductOffersFormOffer_Common';

export const ClubSettingsProductOffersFormOffer_Store = ({
  form,
  offerField,
  offerFieldIndex,
  offersFieldArray,
}: {
  form: UseFormReturn<ClubSettingsProductOffersFormModel>;
  offerField: FieldArrayWithId<
    ClubSettingsProductOffersFormModel,
    'offers',
    'id'
  >;
  offerFieldIndex: number;
  offersFieldArray: UseFieldArrayReturn<
    ClubSettingsProductOffersFormModel,
    'offers',
    'id'
  >;
}) => {
  const clubResume = useClubResume();
  const clubSettings = useClubSettings();
  const generalSettings = clubResume.clubSettings.general;
  const publicSettings = clubResume.clubSettings.publicSettings;
  const services = clubResume.clubSettings.services;

  const isSuperAdmin = useAppSecurityUserHasRole('super-admin');

  const isProduct = offerField.productPackage.type === 'product';

  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const i = offerFieldIndex;

  const showDetails = offersFieldArray.fields.length === 1;
  const productTypeOptions = useMemo(
    () =>
      clubResume.productPackageOfferCategories.map((productCategory) => {
        const option: ValueLabel<string> = {
          value: productCategory?._id,
          label: productCategory?.title?.toUpperCase(),
        };
        return option;
      }),
    [clubResume.productPackageOfferCategories],
  );

  const [nonDivePlanEnabled] = useWatch({
    control,
    name: [`offers.${i}.productPackage.productAttributes.nonDivePlan.enabled`],
  });
  return (
    <div className="grid gap-2 ">
      <div className="flex gap-2 flex-wrap">
        <AppFormControlRHF
          className="w-full xs:w-80"
          label="Libellé"
          control={control}
          name={`offers.${i}.productPackage.label`}
          renderComponent={(props) => (
            <AppInputRHF className="w-full" {...props} fullWidth />
          )}
        />
        <ClubSettingsProductOffersFormOfferCommon_Tarif
          inputClassName="w-full xs:w-40"
          form={form}
          offerFieldIndex={offerFieldIndex}
        />
        <AppFormControlRHF
          className="col-span-2 w-full sm:w-80"
          label="Catégorie de produit"
          control={control}
          name={`offers.${i}.categoryId`}
          required={true}
          renderComponent={(props) => (
            <AppSingleAutocomplete2RHF
              {...props}
              options={productTypeOptions}
              disableClearable={true}
            />
          )}
        />
        <AppFormControlRHF
          label="Forfait ?"
          control={control}
          name={`offers.${i}.productPackage.productAttributes.nonDivePlan.enabled`}
          required={false}
          renderComponent={(props) => (
            <AppInputBooleanSwitchRHF
              {...props}
              onChange={(enabled) => {
                setValue(
                  `offers.${i}.productPackage.productAttributes.nonDivePlan.quantity`,
                  enabled ? 1 : null,
                );
              }}
            />
          )}
        />
        {nonDivePlanEnabled && (
          <>
            <AppFormControlRHF
              className="w-full xs:w-40"
              label="Quantité disponible"
              control={control}
              name={`offers.${i}.productPackage.productAttributes.nonDivePlan.quantity`}
              required={true}
              validation={{
                rules: {
                  min: 1,
                },
              }}
              renderComponent={(props) => (
                <AppInputRHF
                  {...props}
                  placeholder={'0'}
                  type="number"
                  showIncrementButtons={true}
                />
              )}
            />
          </>
        )}
      </div>

      <div className="">
        <AppFormControlRHF
          className="w-full"
          label="Commentaire"
          control={control}
          name={`offers.${i}.productPackage.comment`}
          renderComponent={(props) => (
            <AppInputRHF
              className="w-full"
              {...props}
              placeholder="Commentaire"
              type="text"
              multiline={true}
              rowsMin={2}
            />
          )}
        />
      </div>
    </div>
  );
};
