/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ClubPublicSettingsServicesCustomTypes,
  ClubSettings,
  RegionalSettings,
} from '@mabadive/app-common-model';
import { diveServiceFirstDiveFormatter } from '@mabadive/app-common-services';
import React, { useMemo } from 'react';
import { AppButton } from 'src/business/_core/modules/layout';
import { AppIcons } from 'src/business/_core/modules/layout/icons';
import {
  useClubResume,
  useClubSettings,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import { ClubSettingsSection } from 'src/pages/SE-settings/_core';
import { useRegionalSettings } from 'src/pages/_components/options';
import {
  ClubDialogsState,
  UseClubDialogsProps,
  useClubDialogs,
  useClubDialogsActionsPersist,
} from 'src/pages/_dialogs';
import { ClubSettingsServicesPageLocalState } from '../../../useClubSettingsServicesPageLocalState.hook';
import { ClubSettingsServicesActivitiesViewPanelDiveMode } from '../../01.activities/view/ClubSettingsServicesActivitiesViewPanelDiveMode';

export const ClubSettingsServicesFirstDiveViewPanelCustomTypes = ({
  localState,
  showEditButton,
}: {
  localState: ClubSettingsServicesPageLocalState;
  showEditButton?: boolean;
}) => {
  const diveCenterResume = useDiveCenterResume();
  const publicSettings = useClubSettings()?.publicSettings;
  const clubResume = useClubResume();

  const clubSettings = clubResume?.clubSettings;

  const actionsPersist: UseClubDialogsProps = useClubDialogsActionsPersist();
  const dialogsState: ClubDialogsState = useClubDialogs(actionsPersist);

  const regionalSettings: RegionalSettings = useRegionalSettings({
    effectiveDate: new Date(),
  });

  const { state, dialogs } = localState;

  const switchToEditMode = () =>
    dialogs.clubSettingsServices.open('first-dive', {
      tabFirstDiveEditMode: 'custom-names',
    });

  const customTypes = useMemo(
    () => buildFirstDiveCustomTypes(clubSettings),
    [clubSettings],
  );

  return (
    <ClubSettingsSection
      title={'Types de baptêmes'}
      comment="Définissez les types de baptêmes que vous proposez"
      onClick={state.editEnabled ? switchToEditMode : undefined}
      actions={
        <>
          {showEditButton && state.editEnabled && (
            <div className="">
              <AppButton
                color={'primary-outline-light'}
                size="normal"
                icon={AppIcons.action?.edit}
                onClick={() => {
                  switchToEditMode();
                }}
              >
                Modifier
              </AppButton>
            </div>
          )}
        </>
      }
    >
      {customTypes?.length > 0 ? (
        <table className="app-table max-w-lg text-gray-600">
          <thead>
            <tr>
              <th>Type</th>
              <th>Nom</th>
              <th>Duree</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {customTypes.map((customType) => (
              <tr
                key={customType.originalRef}
                className="uppercase font-bold text-gray-600"
              >
                <td className="flex gap-1 items-center">
                  <ClubSettingsServicesActivitiesViewPanelDiveMode diveMode="first-dive" />
                  {diveServiceFirstDiveFormatter.format(
                    customType.originalRef,
                    {
                      publicSettings,
                      format: 'ref',
                    },
                  )}
                </td>
                <td className="font-normal">
                  {diveServiceFirstDiveFormatter.format(
                    customType.originalRef,
                    {
                      publicSettings,
                      format: 'name',
                    },
                  )}
                </td>
                <td className="font-normal">
                  {customType.durationInMn > 0 &&
                    `${customType.durationInMn} mn`}
                </td>
                <td className="font-normal">
                  {clubSettings.publicSettings.services.firstDives
                    .defaultFirstDiveTrainingReference ===
                    customType.originalRef && (
                    <span className="font-normal text-app-primary">
                      par défaut
                    </span>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div>
          {clubSettings.firstDive.trainingReferences.map(
            (trainingReference) => (
              <div
                key={trainingReference}
                className="uppercase font-bold text-gray-600"
              >
                {diveServiceFirstDiveFormatter.format(trainingReference, {
                  publicSettings,
                  format: 'ref',
                })}{' '}
                -{' '}
                {diveServiceFirstDiveFormatter.format(trainingReference, {
                  publicSettings,
                  format: 'name',
                })}
                {clubSettings.publicSettings.services.firstDives
                  .defaultFirstDiveTrainingReference === trainingReference && (
                  <span className="font-normal text-app-primary">
                    {' '}
                    (par défaut)
                  </span>
                )}
              </div>
            ),
          )}
        </div>
      )}
    </ClubSettingsSection>
  );
};

export function buildFirstDiveCustomTypes(
  clubSettings: ClubSettings,
): ClubPublicSettingsServicesCustomTypes[] {
  return (
    clubSettings.publicSettings.services.firstDives?.customTypes ?? []
  ).filter((x) =>
    clubSettings.firstDive.trainingReferences.includes(x.originalRef),
  );
}
