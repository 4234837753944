/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ClubParticipantSpecialDiveType,
  ClubProductPackageOfferView,
  ClubProductPackageOfferViewDisplayGroup,
  ClubSettings,
  DiveMode,
} from '@mabadive/app-common-model';
import {
  diveModeFormatter,
  participantSpecialDiveTypeFormatter,
} from '@mabadive/app-common-services';

export const offersDisplayGroupsBuilder_Explo = {
  buildGroups_Explo,
};

function buildGroups_Explo({
  clubSettings,
  offers,
}: {
  clubSettings: ClubSettings;
  offers: ClubProductPackageOfferView[];
}) {
  const offersByDisplayGroups: ClubProductPackageOfferViewDisplayGroup[] = [];

  const {
    specialDiveTypeOffers,
    successiveDivesOffers_single,
    remainingOffers,
    packOffers,
    singleOffers,
    diveModeOffers,
  } = offers.reduce(
    (acc, offer) => {
      const specialDiveType =
        offer.productPackage?.diveAttributes?.specialDiveType;
      const successiveDivesCount =
        offer.productPackage?.diveAttributes?.successiveDivesCount;
      const divePriceType = offer.productPackage?.diveAttributes?.divePriceType;
      const diveMode = offer.productPackage?.diveAttributes?.diveMode;

      if (specialDiveType) {
        // Ajouter l'offre au groupe correspondant
        if (!acc.specialDiveTypeOffers[specialDiveType]) {
          acc.specialDiveTypeOffers[specialDiveType] = [];
        }
        acc.specialDiveTypeOffers[specialDiveType].push(offer);
      } else if (diveMode === 'autoSupervised') {
        // Ajouter l'offre au groupe correspondant
        if (!acc.diveModeOffers[diveMode]) {
          acc.diveModeOffers[diveMode] = [];
        }
        acc.diveModeOffers[diveMode].push(offer);
      } else if (successiveDivesCount > 1) {
        // 2-tank
        if (!acc.successiveDivesOffers_single[successiveDivesCount]) {
          acc.successiveDivesOffers_single[successiveDivesCount] = [];
        }
        acc.successiveDivesOffers_single[successiveDivesCount].push(offer);
      } else if (divePriceType === 'package') {
        // forfait
        acc.packOffers.push(offer);
      } else if (divePriceType === 'single') {
        // explo simple
        acc.singleOffers.push(offer);
      } else {
        // Ajouter l'offre aux "remainingOffers" si elle n'appartient à aucun type personnalisé
        acc.remainingOffers.push(offer);
      }

      return acc;
    },
    {
      specialDiveTypeOffers: {} as Record<
        ClubParticipantSpecialDiveType,
        ClubProductPackageOfferView[]
      >,
      diveModeOffers: {} as Record<DiveMode, ClubProductPackageOfferView[]>,
      successiveDivesOffers_single: {} as Record<
        2 | 3,
        ClubProductPackageOfferView[]
      >,
      singleOffers: [] as ClubProductPackageOfferView[],
      packOffers: [] as ClubProductPackageOfferView[],
      // successiveDivesOffers_pack: {} as Record<
      //   2 | 3,
      //   ClubProductPackageOfferView[]
      // >,
      // groupedOffers: {} as Record<string, ClubProductPackageOfferView[]>,
      remainingOffers: [] as ClubProductPackageOfferView[],
    },
  );

  // Transformer singleOffers en un tableau d'objets avec un titre et des offres
  if (singleOffers.length > 0) {
    offersByDisplayGroups.push({
      title: 'Explo',
      offers: singleOffers,
    });
  }

  // Transformer packOffers en un tableau d'objets avec un titre et des offres
  if (packOffers.length > 0) {
    offersByDisplayGroups.push({
      title: 'Forfait',
      offers: packOffers,
    });
  }

  // Transformer successiveDivesOffers_single en un tableau d'objets avec un titre et des offres
  for (const [successiveDivesCount, offers] of Object.entries(
    successiveDivesOffers_single,
  )) {
    offersByDisplayGroups.push({
      title: `${successiveDivesCount}-tanks`,
      offers,
    });
  }

  // Transformer specialDiveTypeOffers en un tableau d'objets avec un titre et des offres
  for (const [specialDiveType, offers] of Object.entries(
    specialDiveTypeOffers,
  )) {
    offersByDisplayGroups.push({
      title: participantSpecialDiveTypeFormatter.formatSpecialDiveType(
        specialDiveType as ClubParticipantSpecialDiveType,
        {
          format: 'long',
        },
      ),
      offers,
    });
  }

  // Transformer diveModeOffers en un tableau d'objets avec un titre et des offres
  for (const [diveMode, offers] of Object.entries(diveModeOffers)) {
    offersByDisplayGroups.push({
      title: diveModeFormatter.formatDiveMode(diveMode as DiveMode, {
        diveModesConf: clubSettings?.ui?.diveMode,
        format: 'long-label',
      }),
      offers,
    });
  }

  // Ajouter les offres restantes dans un groupe "Autres"
  if (remainingOffers.length > 0) {
    offersByDisplayGroups.push({
      title: 'Autres',
      offers: remainingOffers,
    });
  }
  return offersByDisplayGroups;
}
