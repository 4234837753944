import { ClubDiverResidentType } from '@mabadive/app-common-model';
import clsx from 'clsx';
import React from 'react';
import { AppLibIcons } from '../../../../../../../_core/modules/layout/icons/lib';
import { useClubSettings } from '../../../../../../data/hooks';

export function ResidentTypeLabelBadge({
  residentType,
  iconSize = 'size-4',
  className,
}: {
  residentType: ClubDiverResidentType;
  iconSize?: 'size-4' | 'size-5';
  className?: string;
}) {
  const clubSettings = useClubSettings();
  const residentTypeOption =
    clubSettings?.customer?.general?.residentTypeOptions?.find(
      (option) => option.ref === residentType,
    );

  const Icon = AppLibIcons[residentTypeOption?.libIconRef] ?? AppLibIcons.home;

  return !residentType ? null : (
    <div
      className={clsx('flex items-center font-bold gap-1', className)}
      style={{ color: residentTypeOption?.color }}
    >
      {Icon && <Icon className={iconSize} />}

      {residentTypeOption?.label?.toUpperCase()}
    </div>
  );
}
