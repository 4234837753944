import { ClubProductPackageOffer_ServiceCategory } from '@mabadive/app-common-model';

export const clubSettingsServicesPageTabLabelFormatter = {
  format,
  getTabFromServiceCategory,
};

function getTabFromServiceCategory(
  serviceCategory: ClubProductPackageOffer_ServiceCategory,
): ClubSettingsServicesPageTabId {
  switch (serviceCategory) {
    case 'first-dive':
      return 'first-dive';
    case 'training':
      return 'training';
    case 'explo':
      return 'explo';
    case 'free-dive':
      return 'free-dive';
    case 'others':
      return 'other';
    case 'extras':
      return 'extra';
    case 'store':
      return 'store';
  }
}

export type ClubSettingsServicesPageTabId =
  | 'activities'
  | 'first-dive'
  | 'training'
  | 'explo'
  | 'free-dive'
  | 'other'
  | 'extra'
  | 'store';

export type ClubSettingsServicesPageTabFirstDiveEditMode = 'custom-names';

function format(tabId: ClubSettingsServicesPageTabId): string {
  switch (tabId) {
    case 'activities':
      return 'Prestations';
    case 'first-dive':
      return 'Baptêmes';
    case 'training':
      return 'Formations';
    case 'explo':
      return 'Explorations';
    case 'free-dive':
      return 'Apnée';
    case 'other':
      return 'Autres';
    case 'extra':
      return 'Suppléments';
    case 'store':
      return 'Boutique';
  }
}
