import {
  AppCompanyMessageContext,
  BookingJourneyStep,
  ClubDiver,
} from '@mabadive/app-common-model';
import { dateService, nameFormatter } from '@mabadive/app-common-services';
import { useMemo } from 'react';
import { useDiveCenterResume } from 'src/business/club/data/hooks';

export type AppMessageParamsContextDiver = {
  diver?: Pick<ClubDiver, 'firstName' | 'lastName'>; // si message individuel (ou copie aux autres participants)
};
export type AppMessageParamsContextSession = {
  diver?: Pick<ClubDiver, 'firstName' | 'lastName'>; // si message individuel (ou copie aux autres participants)
  diveSession?: {
    time: Date;
  };
};

export type AppMessageParamsContextBooking = {
  diver?: Pick<ClubDiver, 'firstName' | 'lastName'>; // si message individuel (ou copie aux autres participants)
  customerSpaceAuthenticationUrl?: string;
  bookingJourney?: {
    steps: Pick<BookingJourneyStep, 'arrival' | 'departure'>[];
  };
};

// NOTE: on pourrait envoyer un message personnel avec copie au reste du groupe (pour une réservation, pas pour une session, sauf session privée)
export type AppMessageParamsRecipientType = 'personal' | 'group';

export type UseAppMessageParamsArgs = {
  context: AppCompanyMessageContext | 'none';
  sessionContext?: AppMessageParamsContextSession;
  diverContext?: AppMessageParamsContextDiver;
  bookingContext?: AppMessageParamsContextBooking;
  recipientType: AppMessageParamsRecipientType;
};

export function useAppMessageParams({
  context,
  sessionContext,
  diverContext,
  bookingContext,
  recipientType,
}: UseAppMessageParamsArgs): { [key: string]: string } {
  const diveCenterResume = useDiveCenterResume();

  return useMemo(() => {
    const CENTRE_NOM = diveCenterResume?.publicData?.brand?.name;
    const CENTRE_LIEU = diveCenterResume?.publicData?.brand?.locationResume;
    const CENTRE_TELEPHONE = diveCenterResume?.publicData?.contact?.phoneNumber;

    const params: { [key: string]: string } = {
      CENTRE_NOM,
      CENTRE_LIEU,
      CENTRE_TELEPHONE,
    };

    if (context === 'booking' && bookingContext) {
      const { diver, customerSpaceAuthenticationUrl, bookingJourney } =
        bookingContext;
      if (diver) {
        params.PARTICIPANT_NOM = nameFormatter.formatLastName(diver);
        params.PARTICIPANT_PRENOM = nameFormatter.formatFirstName(diver);
      } else {
        // ignore parameters
        params.PARTICIPANT_NOM = '';
        params.PARTICIPANT_PRENOM = '';
      }
      params.ESPACE_CLIENT_LIEN_RESA = customerSpaceAuthenticationUrl;
      const beginDate = bookingJourney?.steps?.[0]?.arrival?.date;
      if (beginDate) {
        params.DATE_DEBUT_SEJOUR = dateService.formatUTC(
          beginDate,
          'DD/MM/YYYY',
        );
      }
      const endDate =
        bookingJourney?.steps?.[bookingJourney?.steps?.length - 1]?.departure
          ?.date;
      if (endDate) {
        params.DATE_FIN_SEJOUR = dateService.formatUTC(endDate, 'DD/MM/YYYY');
      }
    }
    if (context === 'session') {
      const { diver, diveSession } = sessionContext;
      if (diveSession?.time) {
        params.SESSION_HEURE = dateService.formatUTC(
          diveSession?.time,
          'HH:mm',
        );
        params.SESSION_DATE = dateService.formatUTC(
          diveSession?.time,
          'DD/MM/YYYY',
        );
      }
      if (diver) {
        params.PARTICIPANT_NOM = nameFormatter.formatLastName(diver);
        params.PARTICIPANT_PRENOM = nameFormatter.formatFirstName(diver);
      } else {
        // ignore parameters
        params.PARTICIPANT_NOM = '';
        params.PARTICIPANT_PRENOM = '';
      }
    }

    return params;
  }, [
    diveCenterResume?.publicData?.brand?.name,
    diveCenterResume?.publicData?.brand?.locationResume,
    diveCenterResume?.publicData?.contact?.phoneNumber,
    context,
    bookingContext,
    sessionContext,
  ]);
}
