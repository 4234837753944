/* eslint-disable @typescript-eslint/no-unused-vars */
import { DiveGazType } from '@mabadive/app-common-model';
import React, { useState } from 'react';
import { useClubResume } from 'src/business/club/data/hooks';
import { ValueLabel } from 'src/business/club/modules/_common/form/components/ValueLabel.model';
import { AppInputBooleanRHF, AppSingleAutocomplete2RHF } from 'src/lib/form';
import { AppFormControlRHF } from 'src/lib/form/components/AppFormControl/AppFormControlRHF';
import { useGazTypeOptions } from 'src/pages/_components/options';
import { AppExpansionPanel } from '../../../../../business/_core/modules/layout';
import { ClubSettingsCustomerFormFieldsEditPageLocalState } from './useClubSettingsCustomerFormFieldsEditPageLocalState.hook';

export const ClubSettingsCustomerFormFieldsEditForm_Participant = ({
  localState,
  className,
}: {
  localState: ClubSettingsCustomerFormFieldsEditPageLocalState;
  className?: string;
}) => {
  const { form } = localState;
  const { control, setValue } = form;

  const clubResume = useClubResume();

  const diveGazTypeOptions: ValueLabel<DiveGazType>[] = useGazTypeOptions();

  const [isExpanded, setIsExpanded] = useState(true);
  return (
    <AppExpansionPanel
      className={className}
      isExpandable={true}
      isExpanded={isExpanded}
      expandPanel={() => {
        return () => setIsExpanded(!isExpanded);
      }}
      panelId="archived-purchases"
      renderSummaryTitle={() => (
        <div className="w-full flex justify-between items-center">
          <h2 className="text-left uppercase text-lg leading-6 font-medium text-gray-800">
            Plongée
          </h2>
          {!isExpanded && (
            <span className="hidden sm:flex text-xs text-gray-400">
              CLIQUER POUR CONFIGURER
            </span>
          )}
        </div>
      )}
      renderSummaryExtra={() => null}
      renderDetails={() => (
        <div className="w-full flex flex-col gap-y-2">
          <AppFormControlRHF
            control={control}
            name="clubSettings.customer.diving.medicalCertificateDate"
            renderComponent={(props) => (
              <AppInputBooleanRHF
                {...props}
                type="checkbox"
                description={'Certificat médical (date)'}
              />
            )}
          />
          <AppFormControlRHF
            control={control}
            name="clubSettings.customer.diving.medicalCertificateChecked"
            renderComponent={(props) => (
              <AppInputBooleanRHF
                {...props}
                type="checkbox"
                description={'Certificat médical vérifié (oui/non)'}
              />
            )}
          />
          <AppFormControlRHF
            control={control}
            name="clubSettings.customer.diving.medicalFormDate"
            renderComponent={(props) => (
              <AppInputBooleanRHF
                {...props}
                type="checkbox"
                description={'Formulaire médical (date)'}
              />
            )}
          />
          <AppFormControlRHF
            control={control}
            name="clubSettings.customer.diving.medicalFormChecked"
            renderComponent={(props) => (
              <AppInputBooleanRHF
                {...props}
                type="checkbox"
                description={'Formulaire médical vérifié (oui/non)'}
              />
            )}
          />
          <AppFormControlRHF
            control={control}
            name="clubSettings.customer.diving.insurance"
            renderComponent={(props) => (
              <AppInputBooleanRHF
                {...props}
                type="checkbox"
                description={
                  "Assurance (oui/non, nom/numéro, date d'expiration)"
                }
              />
            )}
          />
          <AppFormControlRHF
            control={control}
            name="clubSettings.customer.diving.freeDiveCertificationReference"
            renderComponent={(props) => (
              <AppInputBooleanRHF
                {...props}
                type="checkbox"
                description={"Niveau d'apnée (A1, A2, A3, A4)"}
              />
            )}
          />
          <AppFormControlRHF
            control={control}
            name="clubSettings.customer.diving.divingTechnicalLevel"
            renderComponent={(props) => (
              <AppInputBooleanRHF
                {...props}
                type="checkbox"
                description={'Niveau technique (débutant, confirmé...)'}
              />
            )}
          />
          <AppFormControlRHF
            control={control}
            name="clubSettings.customer.diving.photoInterest"
            renderComponent={(props) => (
              <AppInputBooleanRHF
                {...props}
                type="checkbox"
                description={'Photo / vidéo sous-marine (oui/non)'}
              />
            )}
          />
          <AppFormControlRHF
            control={control}
            name="clubSettings.customer.diving.safety"
            renderComponent={(props) => (
              <AppInputBooleanRHF
                {...props}
                type="checkbox"
                description={'Niveau secourisme (RIFAP)'}
              />
            )}
          />
          <AppFormControlRHF
            control={control}
            name="clubSettings.customer.diving.nitrox"
            renderComponent={(props) => (
              <AppInputBooleanRHF
                {...props}
                type="checkbox"
                description={'Niveau nitrox (simple/confirmé)'}
              />
            )}
          />
          <AppFormControlRHF
            isDiveCenterSpecificFeature={true}
            className="max-w-xs"
            label="Gaz par défaut"
            control={control}
            name={'diveCenter.publicSettings.diving.defaultGaz.gazType'}
            required={false}
            renderComponent={(props) => (
              <AppSingleAutocomplete2RHF
                {...props}
                options={diveGazTypeOptions}
              />
            )}
          />
        </div>
      )}
    />
  );
};
