import { ClubProductPackageOffer_ServiceCategory } from '@mabadive/app-common-model';
import { urlBuilder } from 'src/_common-browser';
import { clubSettingsServicesPageTabLabelFormatter } from './ClubSettingsServices/_services';
import {
  SETTINGS_ACCOUNT_PAGE_COMPANY_INFO,
  SETTINGS_SECURITY_PAGE_USER_ACCOUNTS,
} from './SETTINGS_ACCOUNT.const';

export const clubSettingsAccountUrlBuilder = {
  companyInfo: {
    view: () => SETTINGS_ACCOUNT_PAGE_COMPANY_INFO.url,
  },
  userAccounts: {
    list: () => SETTINGS_SECURITY_PAGE_USER_ACCOUNTS.url,
    createUserAccount: ({ clubProfileId }: { clubProfileId?: string } = {}) =>
      urlBuilder.build(`${SETTINGS_SECURITY_PAGE_USER_ACCOUNTS.url}/nouveau`, {
        queryParams: {
          clubProfileId,
        },
      }),
    editUserAccount: ({ userAccountId }: { userAccountId: string }) =>
      `${SETTINGS_SECURITY_PAGE_USER_ACCOUNTS.url}/${userAccountId}/edit`,
    createUserProfile: ({
      cloneFromClubProfileId,
    }: { cloneFromClubProfileId?: string } = {}) =>
      urlBuilder.build('/club/settings/account/user-profiles/nouveau', {
        queryParams: {
          cloneFromClubProfileId,
        },
      }),
    editUserProfile: ({ clubProfileId }: { clubProfileId: string }) =>
      `/club/settings/account/user-profiles/${clubProfileId}/edit`,
  },
  servicePricing: (
    serviceCategory: ClubProductPackageOffer_ServiceCategory,
  ) => {
    const tab =
      clubSettingsServicesPageTabLabelFormatter.getTabFromServiceCategory(
        serviceCategory,
      );
    return `/club/settings/account/company-services?m=view&t=${tab}`;
  },
};
