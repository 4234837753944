/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  DiveCenterEquipmentConfig,
  DiveGazType,
  EquipmentType,
} from '@mabadive/app-common-model';
import { diveGazFormatter } from '@mabadive/app-common-services';
import Tippy from '@tippyjs/react';
import clsx from 'clsx';
import React, { useCallback } from 'react';
import {
  FieldArrayWithId,
  UseFieldArrayReturn,
  UseFormReturn,
  useWatch,
} from 'react-hook-form';
import { AppIconsAction } from 'src/business/_core/modules/layout/icons';
import { useAppSecurityUser } from 'src/business/auth/services';
import { useClubResume } from 'src/business/club/data/hooks';
import { ValueLabel } from 'src/business/club/modules/_common/form/components/ValueLabel.model';
import {
  AppInputBooleanSwitchRHF,
  AppInputRHF,
  AppSingleAutocomplete2RHF,
} from 'src/lib/form';
import { AppFormControlRHF_Deprecated } from 'src/lib/form/components/AppFormControl/AppFormControlRHF_Deprecated';
import { useGazTypeOptions } from 'src/pages/_components/options';
import { DiveCenterEquipmentConfigFormModel } from './model';

export type ClubSettingsEquipmentEditPageFormModelOnClickAction =
  | 'move-down'
  | 'move-up'
  | 'delete'
  | 'clone';
export const ClubSettingsEquipmentEditPageFormModel = <
  T extends 'club' | 'self',
>({
  className,
  type,
  form,
  fieldArray,
  field,
  modelsGroupName,
  fieldIndex,
  onClick,
}: {
  className?: string;
  type: EquipmentType;
  form: UseFormReturn<DiveCenterEquipmentConfigFormModel>;
  fieldArray: UseFieldArrayReturn<
    DiveCenterEquipmentConfig,
    'club.models' | 'self.models',
    'ref'
  >;
  modelsGroupName: T;
  field: FieldArrayWithId<
    DiveCenterEquipmentConfig,
    'club.models' | 'self.models',
    'ref'
  >;
  fieldIndex: number;
  onClick: (
    action: ClubSettingsEquipmentEditPageFormModelOnClickAction,
  ) => void;
}) => {
  const {
    register,
    handleSubmit,
    watch,
    formState,
    control,
    setValue,
    getValues,
  } = form;

  const securityUser = useAppSecurityUser();

  const clubResume = useClubResume();
  const clubReference = clubResume.reference;

  const { fields, append, prepend, remove, swap, move, insert } = fieldArray;

  const formAttributeBaseName =
    `${modelsGroupName}.models.${fieldIndex}` as const;

  const isMoveDownAllowed =
    fieldArray.fields.length > 1 && fieldIndex + 1 < fieldArray.fields.length;
  const isMoveUpAllowed = fieldArray.fields.length > 1 && fieldIndex !== 0;
  const diveGazTypeOptions: ValueLabel<DiveGazType>[] = useGazTypeOptions();

  const [capacity, inflation, gazDescription, gazType, newRef, originalRef] =
    useWatch({
      control,
      name: [
        `${formAttributeBaseName}.tank.capacity`,
        `${formAttributeBaseName}.tank.inflation`,
        `${formAttributeBaseName}.tank.gazDescription`,
        `${formAttributeBaseName}.tank.gazType`,
        `${formAttributeBaseName}.ref`,
        `${formAttributeBaseName}.__originalRef`,
      ],
    });

  const updateRefTank = useCallback(
    ({
      capacity,
      inflation,
      gazDescription,
      gazType,
    }: {
      capacity: string | number;
      inflation: boolean;
      gazDescription: string | number;
      gazType: DiveGazType;
    }) => {
      const chunks = [
        inflation ? 'G' : '',
        gazType
          ? diveGazFormatter.formatGazType(gazType, { format: 'short' })
          : '',
        `${gazDescription ?? ''}`,
      ].filter((x) => x.trim().length > 0);
      const ref = `${capacity ? `${capacity}L` : ''}${
        chunks.length ? ` ${chunks.join('-')}` : ''
      }`;
      setValue(`${formAttributeBaseName}.ref`, ref as any);
    },
    [formAttributeBaseName, setValue],
  );

  return (
    <div
      className={clsx(
        'border-l-4 border-gray-600 pl-2 grid md:flex gap-2 lg:gap-4 items-start',
        className,
      )}
    >
      <div
        className={clsx(
          'h-full flex md:flex-col md:justify-center gap-2',
          fieldArray.fields.length === 1 && 'invisible',
        )}
      >
        <Tippy
          delay={[1000, 100]}
          placement="top"
          content={'Déplacer au dessus'}
        >
          <AppIconsAction.up
            className={clsx(
              'p-1 w-8 h-8 bg-gray-200 rounded-full text-white',
              isMoveUpAllowed
                ? 'hover:bg-gray-600 cursor-pointer'
                : 'opacity-50 cursor-not-allowed',
            )}
            onClick={() => isMoveUpAllowed && onClick('move-up')}
          />
        </Tippy>
        <Tippy
          delay={[1000, 100]}
          placement="top"
          content={'Déplacer en dessous'}
        >
          <AppIconsAction.down
            className={clsx(
              'p-1 w-8 h-8 bg-gray-200 rounded-full text-white',
              isMoveDownAllowed
                ? 'hover:bg-gray-600 cursor-pointer'
                : 'opacity-50 cursor-not-allowed',
            )}
            onClick={() => isMoveDownAllowed && onClick('move-down')}
          />
        </Tippy>
      </div>
      <div>
        {type === 'tank' && (
          <div className="flex flex-row flex-wrap gap-2">
            <AppFormControlRHF_Deprecated
              label="Capacité (L)"
              className="w-24"
              control={control}
              name={`${formAttributeBaseName}.tank.capacity`}
              required={false}
              renderComponent={(props) => (
                <AppInputRHF
                  {...props}
                  type="number"
                  onChange={(capacity) => {
                    updateRefTank({
                      capacity,
                      inflation,
                      gazDescription,
                      gazType,
                    });
                  }}
                />
              )}
            />
            <AppFormControlRHF_Deprecated
              label="Gaz"
              className="w-24"
              control={control}
              name={`${formAttributeBaseName}.tank.gazType`}
              required={false}
              renderComponent={(props) => (
                <AppSingleAutocomplete2RHF
                  {...props}
                  options={diveGazTypeOptions}
                  onChange={(gazType) => {
                    updateRefTank({
                      capacity,
                      inflation,
                      gazDescription,
                      gazType,
                    });
                  }}
                />
              )}
            />
            <AppFormControlRHF_Deprecated
              label="Mélange (32%...)"
              control={control}
              name={`${formAttributeBaseName}.tank.gazDescription`}
              required={false}
              renderComponent={(props) => (
                <AppInputRHF
                  {...props}
                  type="text"
                  onChange={(gazDescription) => {
                    updateRefTank({
                      capacity,
                      inflation,
                      gazDescription,
                      gazType,
                    });
                  }}
                />
              )}
            />
            {modelsGroupName === 'self' && (
              <AppFormControlRHF_Deprecated
                label="Gonflage"
                className="w-24"
                control={control}
                name={`${formAttributeBaseName}.tank.inflation`}
                required={false}
                renderComponent={(props) => (
                  <AppInputBooleanSwitchRHF
                    {...props}
                    onChange={(inflation) => {
                      updateRefTank({
                        capacity,
                        inflation,
                        gazDescription,
                        gazType,
                      });
                    }}
                  />
                )}
              />
            )}
          </div>
        )}
        <div className="flex flex-row flex-wrap gap-2">
          <AppFormControlRHF_Deprecated
            label="Nom affiché"
            className="w-28"
            control={control}
            validation={{
              rules: {
                validate: (ref) => {
                  const allModels = getValues(`${modelsGroupName}.models`);

                  const existingRef = allModels.find(
                    (m, i) => m.ref === ref && i !== fieldIndex,
                  );
                  if (!!existingRef) {
                    return `Nom ${ref} déjà utilisée`;
                  }
                },
              },
            }}
            name={`${formAttributeBaseName}.ref`}
            required={true}
            renderComponent={(props) => <AppInputRHF {...props} type="text" />}
          />
          <AppFormControlRHF_Deprecated
            label="Description"
            control={control}
            name={`${formAttributeBaseName}.label`}
            required={false}
            renderComponent={(props) => <AppInputRHF {...props} type="text" />}
          />
          {modelsGroupName === 'club' && (
            <AppFormControlRHF_Deprecated
              label="Quantité dispo"
              className="w-24"
              control={control}
              name={`${formAttributeBaseName}.availableQuantity`}
              required={false}
              renderComponent={(props) => (
                <AppInputRHF {...props} type="number" />
              )}
            />
          )}
        </div>
        {originalRef && originalRef !== newRef && (
          <div className="my-1 text-xxs">
            <span className="">
              <b className="text-status-error">{originalRef}</b> {'=>'}{' '}
              <b className="text-status-success">{newRef}</b>
            </span>
            <span className="text-gray-600 font-bold">
              : les participants ayant cet équipement vont être mis à jour
            </span>
          </div>
        )}
      </div>
      <div className="flex gap-2 md:gap-6">
        <Tippy delay={[1000, 100]} placement="top" content={'Dupliquer'}>
          <AppIconsAction.clone
            className="p-1 w-8 h-8 bg-green-200 hover:bg-green-600 rounded-full text-white cursor-pointer"
            onClick={() => {
              onClick('clone');
            }}
          />
        </Tippy>
        <Tippy delay={[1000, 100]} placement="top" content={'Supprimer'}>
          <AppIconsAction.delete
            className="p-1 w-8 h-8 bg-red-200 hover:bg-red-600 rounded-full text-white cursor-pointer"
            onClick={() => {
              onClick('delete');
            }}
          />
        </Tippy>
      </div>
    </div>
  );
};
