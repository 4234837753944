/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useState } from 'react';

import { ClubStaffMemberAvailability } from '@mabadive/app-common-model';
import {
  dateService,
  jsonParser,
  uuidGenerator,
} from '@mabadive/app-common-services';
import clsx from 'clsx';
import { FieldArrayWithId, useFieldArray } from 'react-hook-form';
import { useDiveCenterResume } from 'src/business/club/data/hooks';
import {
  AppButton,
  AppMessageLight,
} from '../../../../../../business/_core/modules/layout';
import { AppIconsAction } from '../../../../../../business/_core/modules/layout/icons';
import { ClubSettingsEquipmentEditPageFormModelOnClickAction } from '../../../../SE-02_account/equipment/ClubSettingsEquipmentEditPage/ClubSettingsEquipmentEditPageForm/ClubSettingsEquipmentEditPageFormModel';
import { StaffMemberEditFormModel } from '../_model';
import { StaffMemberEditDialogLocalState } from '../useStaffMemberEditDialogLocalState.hook';
import { StaffMemberEditFormAvailabily } from './StaffMemberEditFormAvailabilities';

export const StaffMemberEditForm_Tab2_Availabilities = ({
  localState,
  className,
}: {
  localState: StaffMemberEditDialogLocalState;
  className?: string;
}) => {
  const {
    state: { form },
  } = localState;
  const { control, setValue, getValues } = form;

  const diveCenterResume = useDiveCenterResume();

  const baseArrayFormName = 'staffMember.availabilities' as const;

  const availabilitiesFieldsArray = useFieldArray({
    control,
    name: baseArrayFormName,
    shouldUnregister: false, // si true, le tableau est vidé si le composant est masqué (unmount)
  });

  const availabilitiesFields = availabilitiesFieldsArray.fields;

  const [sectionAnimations, setModelAnimations] =
    useState<{
      update: Date;
      up?: number;
      down?: number;
      remove?: number;
      clone?: number;
    }>();

  const isRecentAnimationUpdate =
    new Date().getTime() - sectionAnimations?.update?.getTime() < 1000;

  const onClick = useCallback(
    (
      field: FieldArrayWithId<
        StaffMemberEditFormModel,
        typeof baseArrayFormName,
        'id'
      >,
      fieldIndex: number,
      action: ClubSettingsEquipmentEditPageFormModelOnClickAction,
    ) => {
      const from = fieldIndex;
      const update = new Date();
      switch (action) {
        case 'move-down': {
          availabilitiesFieldsArray.move(from, from + 1);
          setModelAnimations({ update, up: from + 1, down: from });
          break;
        }
        case 'move-up': {
          availabilitiesFieldsArray.move(from, from - 1);
          setModelAnimations({ update, up: from, down: from - 1 });
          break;
        }
        case 'delete': {
          // setModelAnimations({ update, remove: from });
          availabilitiesFieldsArray.remove(from);
          // setTimeout(() => availabilitiesFieldsArray.remove(from), 100);
          break;
        }
        case 'clone': {
          if (field) {
            const original: ClubStaffMemberAvailability = getValues(
              `${baseArrayFormName}.${fieldIndex}`,
            );
            const clone: ClubStaffMemberAvailability =
              jsonParser.parseJSONWithDates<ClubStaffMemberAvailability>(
                JSON.stringify({
                  ...original,
                  _id: uuidGenerator.random(),
                  beginDate: dateService.getUTCDateSetTime(), // today
                  label: `Copie de "${original.label ?? ''}"`,
                }),
              );

            availabilitiesFieldsArray.insert(from + 1, clone as any);
            setModelAnimations({ update, clone: from });
            break;
          }
        }
      }
    },
    [getValues, availabilitiesFieldsArray],
  );
  return (
    <div className={clsx('grid gap-8', className)}>
      {/* <h2 className="text-left text-sm sm:text-lg leading-6 py-2 font-medium text-app-blue uppercase">
        Disponibilités
      </h2> */}
      <div className="grid gap-4">
        <div className="flex gap-4 flex-wrap justify-start">
          <AppButton
            color="primary-bg"
            icon={AppIconsAction.add}
            onClick={() => {
              const newAvailability: ClubStaffMemberAvailability = {
                _id: uuidGenerator.random(),
                beginDate: dateService.getUTCDateSetTime(), // today
                label: 'Saison xxx',
                jobType: availabilitiesFieldsArray.fields[0]?.jobType,
                defaultWorkingDays: {
                  1: {
                    availabilityPeriod: 'day',
                  },
                  2: {
                    availabilityPeriod: 'day',
                  },
                  3: {
                    availabilityPeriod: 'day',
                  },
                  4: {
                    availabilityPeriod: 'day',
                  },
                  5: {
                    availabilityPeriod: 'day',
                  },
                  6: {
                    availabilityPeriod: 'day',
                  },
                  7: {
                    availabilityPeriod: 'day',
                  },
                },
              };
              availabilitiesFieldsArray.append(newAvailability as any);
            }}
          >
            Nouvelle saison
          </AppButton>
          <AppButton
            color="success-outline-hover-bg"
            icon={AppIconsAction.add}
            onClick={() => {
              const newAvailability: ClubStaffMemberAvailability = {
                _id: uuidGenerator.random(),
                beginDate: dateService.getUTCDateSetTime(), // today
                label: 'Vacances xxx',
                jobType: availabilitiesFieldsArray.fields[0]?.jobType,
                defaultWorkingDays: {
                  1: {
                    availabilityPeriod: 'none',
                  },
                  2: {
                    availabilityPeriod: 'none',
                  },
                  3: {
                    availabilityPeriod: 'none',
                  },
                  4: {
                    availabilityPeriod: 'none',
                  },
                  5: {
                    availabilityPeriod: 'none',
                  },
                  6: {
                    availabilityPeriod: 'none',
                  },
                  7: {
                    availabilityPeriod: 'none',
                  },
                },
              };
              availabilitiesFieldsArray.append(newAvailability as any);
            }}
          >
            Période de vacances
          </AppButton>
        </div>

        <div className="grid gap-4">
          {availabilitiesFields.map((field, fieldIndex) => {
            return (
              <div
                key={field._id}
                className={clsx(
                  'app-card--no-bg app-p-content bg-gray-50 pl-2 ',
                  isRecentAnimationUpdate &&
                    sectionAnimations?.up === fieldIndex &&
                    'animate-move-up',
                  isRecentAnimationUpdate &&
                    sectionAnimations?.down === fieldIndex &&
                    'animate-move-down',
                  isRecentAnimationUpdate &&
                    sectionAnimations?.clone === fieldIndex &&
                    'animate-create',
                  isRecentAnimationUpdate &&
                    sectionAnimations?.remove === fieldIndex &&
                    'animate-remove',
                )}
              >
                <StaffMemberEditFormAvailabily
                  localState={localState}
                  field={field}
                  fieldIndex={fieldIndex}
                  onClick={onClick}
                  showDeleteButton={availabilitiesFields.length > 1}
                />
              </div>
            );
          })}
        </div>
        <AppMessageLight>
          Ajoutez les périodes de vacances de vos équipes, ainsi que les
          périodes avec un planning différent.
        </AppMessageLight>
      </div>
    </div>
  );
};
