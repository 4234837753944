/* eslint-disable @typescript-eslint/no-unused-vars */
import clsx from 'clsx';
import React from 'react';
import { AppPageBlock } from 'src/business/_core/modules/layout';
import {
  AppButton,
  AppMessageLight,
} from 'src/business/_core/modules/layout/components/_tailwind';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { useAppSecurityUserClubAuthorizations } from 'src/business/auth/services';
import { useDiveCenterResume } from 'src/business/club/data/hooks';
import { AppSingleAutocomplete2 } from 'src/lib/form';
import { ClubDialogsStateOld } from 'src/pages/_dialogs';
import { clubSettingsAccountUrlBuilder } from '../../../../../../../../pages/SE-settings/SE-02_account/clubSettingsAccountUrlBuilder.service';
import { BillingParticipantPurchaseResumeTable } from './../../components/BillingParticipantPurchaseResumeTable';
import { DiverBookingPageActions } from './../../useDiverBookingPageActions.hook';
import { DiverBookingPageGlobalState } from './../../useDiverBookingPageGlobalState.hook';
import { DiverBookingPageBillingTabArchivedPurchases } from './DiverBookingPageBillingTabArchivedPurchases';
import { DiverBookingPageBillingTabFilterCountsBar } from './DiverBookingPageBillingTabFilterCountsBar';
import {
  DiverBookingPageBillingTabState,
  useDiverBookingPageBillingTabState,
} from './useDiverBookingPageBillingTabState.hook';

export const DiverBookingPageBillingTab = ({
  globalState,
  dialogs,
  actions,
}: {
  globalState: DiverBookingPageGlobalState;
  dialogs: ClubDialogsStateOld;
  actions: DiverBookingPageActions;
}) => {
  const diveCenterResume = useDiveCenterResume();
  const clubSettings = diveCenterResume.clubResume.clubSettings;
  const diveCenterId = diveCenterResume._id;
  const az = useAppSecurityUserClubAuthorizations();

  const {
    aggregatedData: aggregatedDataInput,
    updateState,
    focus,
    billingTabFilterCriteria,
    setBillingTabFilterCriteria,
    dialogsState,
  } = globalState;

  const localState: DiverBookingPageBillingTabState =
    useDiverBookingPageBillingTabState({
      actions,
      dialogs,
      globalState,
    });

  const {
    data,
    model,
    selectedDiverId,
    setSelectedDiverId,
    diversFilterOptions,
    createPlan,
    createTraining,
    createFirstDive,
    createStorePurchase,
  } = localState;

  return (
    <AppPageBlock className="app-p-content">
      {model && (
        <>
          <div className="mb-5 block overflow-hidden app-card app-px-content">
            <h2 className="my-2 text-base sm:text-lg font-medium text-gray-800 leading-6 pt-0 mt-0 flex items-center">
              Prestations en cours ({model.activeParticipantPurchases.length})
            </h2>
            <DiverBookingPageBillingTabFilterCountsBar
              globalState={globalState}
              localState={localState}
            />
            {model.activeParticipantPurchases.length !== 0 ? (
              <BillingParticipantPurchaseResumeTable
                className="mt-2"
                billingTabLocalState={localState}
                participantPurchases={model.activeParticipantPurchases}
                globalState={globalState}
                dialogs={dialogs}
                actions={actions}
              />
            ) : (
              <AppMessageLight className="my-2" type="info" hideBorder={true}>
                Aucune prestation en cours
              </AppMessageLight>
            )}
          </div>
          {az.edit.participant.billingCreate && diversFilterOptions.length > 0 && (
            <div className="flex flex-wrap lg:flex-nowrap justify-between gap-2">
              <div className="w-full flex gap-2 lg:max-w-[50%]">
                {data.offersByServiceCategory['explo'].length > 0 && (
                  <AppButton
                    size="small"
                    color={'primary-outline-light'}
                    className={'w-full'}
                    fullWidth={true}
                    onClick={createPlan}
                  >
                    <div
                      className={clsx(
                        'overflow-hidden flex items-center gap-1 md:gap-2 uppercase',
                        'text-xs sm:text-sm md:text-base',
                      )}
                    >
                      <AppHeroIcons.actionAddCircle className="w-6 h-6" />

                      <div className="">forfait</div>
                    </div>
                  </AppButton>
                )}
                {data.offersByServiceCategory['training'].length > 0 && (
                  <AppButton
                    size="small"
                    color={'secondary-outline-light'}
                    className={'w-full'}
                    fullWidth={true}
                    onClick={createTraining}
                  >
                    <div
                      className={clsx(
                        'overflow-hidden flex items-center gap-1 md:gap-2 uppercase',
                        'text-xs sm:text-sm md:text-base',
                      )}
                    >
                      <AppHeroIcons.actionAddCircle className="w-6 h-6" />

                      <div className="">formation</div>
                    </div>
                  </AppButton>
                )}
                {data.offersByServiceCategory['first-dive'].length > 0 && (
                  <AppButton
                    size="small"
                    color={'gray-outline-light'}
                    className={'w-full'}
                    fullWidth={true}
                    onClick={createFirstDive}
                  >
                    <div
                      className={clsx(
                        'overflow-hidden flex items-center gap-1 md:gap-2 uppercase',
                        'text-xs sm:text-sm md:text-base',
                      )}
                    >
                      <AppHeroIcons.actionAddCircle className="w-6 h-6" />

                      <div className="">Baptême</div>
                    </div>
                  </AppButton>
                )}
                {data.offersByServiceCategory['store'].length > 0 && (
                  <AppButton
                    size="small"
                    color={'gray-outline-light'}
                    className={'w-full'}
                    fullWidth={true}
                    onClick={createStorePurchase}
                  >
                    <div
                      className={clsx(
                        'overflow-hidden flex items-center gap-1 md:gap-2 uppercase',
                        'text-xs sm:text-sm md:text-base',
                      )}
                    >
                      <AppHeroIcons.actionAddCircle className="w-6 h-6" />

                      <div className="">Boutique</div>
                    </div>
                  </AppButton>
                )}
              </div>

              {diversFilterOptions.length > 1 && (
                <div className="w-full lg:max-w-[50%]">
                  <AppSingleAutocomplete2
                    className={clsx('w-full')}
                    disableClearable={true}
                    options={diversFilterOptions}
                    value={selectedDiverId}
                    onChange={(selectedDiverId) => {
                      setSelectedDiverId(selectedDiverId);
                    }}
                  />
                </div>
              )}
            </div>
          )}

          {model.inactiveParticipantPurchases.length !== 0 && (
            <DiverBookingPageBillingTabArchivedPurchases
              actions={actions}
              dialogs={dialogs}
              globalState={globalState}
              localState={localState}
            />
          )}
          <AppMessageLight className="mt-8 lg:mt-16">
            <p>Configurez vos tarifs pour utiliser la facturation:</p>
            <ul className="mt-4 flex gap-4 flex-wrap">
              {clubSettings.firstDive.enabled && (
                <li>
                  <AppButton
                    color="gray-outline-light"
                    href={clubSettingsAccountUrlBuilder.servicePricing(
                      'first-dive',
                    )}
                  >
                    Baptêmes (
                    {data.offersByServiceCategory['first-dive'].length})
                  </AppButton>
                </li>
              )}
              {clubSettings.training.enabled && (
                <li>
                  <AppButton
                    color="gray-outline-light"
                    href={clubSettingsAccountUrlBuilder.servicePricing(
                      'training',
                    )}
                  >
                    Formations ({data.offersByServiceCategory.training.length})
                  </AppButton>
                </li>
              )}
              <li>
                <AppButton
                  color="gray-outline-light"
                  href={clubSettingsAccountUrlBuilder.servicePricing('explo')}
                >
                  Explorations ({data.offersByServiceCategory.explo.length})
                </AppButton>
              </li>
              {clubSettings.services?.store?.enabled && (
                <li>
                  <AppButton
                    color="gray-outline-light"
                    href={clubSettingsAccountUrlBuilder.servicePricing('store')}
                  >
                    Boutique ({data.offersByServiceCategory['store'].length})
                  </AppButton>
                </li>
              )}
            </ul>
          </AppMessageLight>
        </>
      )}
    </AppPageBlock>
  );
};
