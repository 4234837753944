/* eslint-disable jsx-a11y/alt-text */
import {
  AppBookletPageGql_Company,
  EcommerceCategoryGql_Company,
} from '@mabadive/app-common-model';
import clsx from 'clsx';
import React from 'react';
import { useAppTheme } from '../../../../../../../business/club/data/hooks';
import { AppRichTextH1 } from '../../../../../../../lib/form/components/AppRichText/rendering/elements';
import { BookletEditorDisplayWidth } from '../../../ClubSettingsOnlineBookingConfigPage/tabs/02.products/view/BookletEditor_EditablePreview';

export const EcommerceCategoryEditorTitle = ({
  displayWidth,
  category,
  categoryBookletPage,
  className,
}: {
  displayWidth: BookletEditorDisplayWidth;
  category: Pick<EcommerceCategoryGql_Company, 'name'>;
  categoryBookletPage: Partial<AppBookletPageGql_Company>;
  className?: string;
}) => {
  const theme = useAppTheme();

  const content = categoryBookletPage?.mainContent?.content;

  const image2 = categoryBookletPage?.mainContent?.cover?.image2;

  return (
    category && (
      <div
        className={`py-2 w-full overflow-y-auto flex items-center gap-4 ${className}`}
      >
        {image2?._id && (
          // eslint-disable-next-line jsx-a11y/alt-text
          <img className="h-[60px] rounded-lg" src={image2.publicUrl} />
        )}
        {content ? (
          <>
            <div>
              <div
                className={clsx(
                  'uppercase font-bold w-full text-left',
                  displayWidth === 'mobile'
                    ? 'text-base'
                    : 'text-base sm:text-lg md:text-xl lg:text-2xl xl:text-3xl',
                )}
                style={{ color: theme.title1.textColor }}
              >
                {content?.title}
              </div>
              {content?.subTitle && (
                <div
                  className={clsx(
                    'uppercase font-medium w-full text-left',
                    displayWidth === 'mobile'
                      ? 'text-sm'
                      : 'text-sm sm:text-base xl:text-lg 2xl:text-2xl',
                  )}
                  style={{ color: theme.title2.textColor }}
                >
                  {content?.subTitle}
                </div>
              )}
            </div>
          </>
        ) : (
          <>
            <AppRichTextH1 theme={theme}>{category.name}</AppRichTextH1>
          </>
        )}
      </div>
    )
  );
};
