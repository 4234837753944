import { ClubPurchasePackageCredits } from '@mabadive/app-common-model';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import {
  AppGenericStatus,
  AppGenericStatusIcon,
  appGenericStatusStyleBuilder,
} from 'src/business/_core/modules/layout/components/_tailwind';

export function BillingParticipantPurchaseResumeCreditsCount({
  credits,
  isVirtual,
  opacity,
  className,
}: {
  credits: ClubPurchasePackageCredits;
  isVirtual: boolean;
  opacity?: 60;
  className?: string;
}) {
  const totalConsumedCount = credits?.consumedTotalCount ?? 0;
  const badgeStatus: AppGenericStatus = useMemo(() => {
    if (totalConsumedCount === credits?.creditsTotalCount) {
      return 'info';
    }
    if (totalConsumedCount > credits?.creditsTotalCount) {
      return 'error';
    }
    return 'active';
  }, [credits?.creditsTotalCount, totalConsumedCount]);

  const badgeStatusClass: string = useMemo(
    () => appGenericStatusStyleBuilder.build(badgeStatus),
    [badgeStatus],
  );

  return (
    <div
      className={clsx(
        opacity === 60 && 'text-opacity-60',
        badgeStatusClass,
        className,
      )}
    >
      {!!credits?.creditsTotalCount && (
        <span className={'font-bold'}>
          {totalConsumedCount}/{credits?.creditsTotalCount}
        </span>
      )}
      {!isVirtual && (
        <AppGenericStatusIcon
          status={badgeStatus}
          className={'ml-1 inline w-4 h-4'}
        />
      )}
    </div>
  );
}
