import { nameFormatter } from '@mabadive/app-common-services';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { useAppBookingViewAuth } from 'src/business/auth/services';
import { useClubResume } from 'src/business/club/data/hooks';
import { AppHamburgerMenuItem } from 'src/business/club/modules/_common/ui';
import { DiveCenterProBadge } from 'src/pages/_components/company';
import { ClubDialogsStateOld } from 'src/pages/_dialogs';
import { AppButtonMultiActions } from '../../../../../../../_core/modules/layout';
import {
  BookingResumeOnClickMemberAction,
  DiverBookingCardBookingAction,
} from '../../../_from-diver-app';
import {
  BookingTabModel,
  DiverBookingPageLoadedContentFocus,
  PRO_BookingMemberFull_WithDocs,
} from '../../models';
import { DiverBookingPageActions } from '../../useDiverBookingPageActions.hook';
import { DiverBookingPageGlobalState } from '../../useDiverBookingPageGlobalState.hook';
import {
  AgencyLabel,
  BookingGroupLabel,
  BookingStatusLabel,
} from '../BookingLabelsComponents';
import { useDisplayBookingGroup } from '../BookingLabelsComponents/useDisplayBookingGroup.hook';
import { DiverBookingCardJourneySteps } from './DiverBookingCardJourneyStep';
import { DiverBookingCardMainButtonsBar } from './DiverBookingCardMainButtonsBar';
import { DiverBookingTitle } from './DiverBookingTitle';
import { useDiverBookingCardHamburgerMenu } from './useDiverBookingCardHamburgerMenu.hook';

export function DiverBookingCardHeader({
  globalState,
  actions,
  dialogs,
  focus,
  bookingTabModel,
  onClickMember,
  onClickBooking,
  extraMenuItems = [],
  isExpanded,
  setIsExpanded,
  className,
}: {
  globalState: DiverBookingPageGlobalState;
  actions: DiverBookingPageActions;
  dialogs: ClubDialogsStateOld;
  focus: DiverBookingPageLoadedContentFocus;
  bookingTabModel: BookingTabModel;
  onClickBooking: (
    bookingTabModel: BookingTabModel,
    action: DiverBookingCardBookingAction,
  ) => void;
  onClickMember: (attrs: {
    bookingMemberFull: PRO_BookingMemberFull_WithDocs;
    action: BookingResumeOnClickMemberAction;
  }) => void;
  extraMenuItems?: AppHamburgerMenuItem[];
  isExpanded: boolean;
  setIsExpanded: (value: boolean) => void;

  className?: string;
}) {
  const isUniqueMember = bookingTabModel.meta.isUniqueMember;

  const partiticipantsCount = useMemo(
    () =>
      bookingTabModel.participantsBySessions.reduce((acc, ps) => {
        return (
          acc + ps.participants.filter((x) => !!x.bookingParticipantFull).length
        );
      }, 0),
    [bookingTabModel.participantsBySessions],
  );

  const booking = bookingTabModel.aggregatedBooking.booking;

  const stepsToDisplay = useMemo(() => {
    return (booking?.bookingJourney?.steps ?? []).filter(
      (s) => !!s, // TODO filtrer par diveCenterId, sauf si on est admin?
    );
  }, [booking?.bookingJourney]);

  const bookingMembersFull =
    bookingTabModel.aggregatedBooking.bookingMembersFull;

  const datesRange = bookingTabModel.meta.datesRange;

  const hamburgerMenuItems = useDiverBookingCardHamburgerMenu({
    globalState,
    booking,
    bookingMembersFull,
    actions,
    dialogs,
    onClickBooking,
    bookingTabModel,
    setIsExpanded,
    extraMenuItems,
  });

  const clubResume = useClubResume();
  const customerSettings = clubResume?.clubSettings?.customer;
  const isMultiDiveCenters = clubResume.diveCenters.length > 1;
  const bookingDiveCenter = useMemo(
    () =>
      clubResume.diveCenters.find(
        (dc) => dc._id === bookingTabModel.bookingDiveCenterId,
      ),
    [bookingTabModel.bookingDiveCenterId, clubResume.diveCenters],
  );

  const bookingViewAuth = useAppBookingViewAuth({
    bookingDiveCenterId: bookingTabModel.bookingDiveCenterId,
    isSharedBooking: bookingTabModel.isSharedBooking,
  });

  const { isVisible: bookingGroupBookingGroup } = useDisplayBookingGroup({
    type: booking.bookingGroup?.type,
    label: booking.bookingGroup?.name?.toUpperCase(),
  });

  return (
    <div
      className={`flex flex-nowrap justify-between items-start ${
        className ?? ''
      }`}
    >
      <div
        className={clsx(
          'flex-grow px-2 grid gap-1',
          bookingViewAuth.edit && 'cursor-pointer',
        )}
        onClick={() => {
          if (isExpanded) {
            if (bookingViewAuth.edit) {
              actions.dialogs.setBookingGroupAndJourneyEditDialogState({
                isOpen: true,
                booking,
                bookingMembersFull,
              });
            } else {
              setIsExpanded(false);
            }
          } else {
            setIsExpanded(true);
          }
        }}
      >
        <h2 className="text-base sm:text-lg leading-6 pt-0 mt-0 flex items-center">
          <DiverBookingTitle
            className="font-medium text-gray-700"
            datesRangeClassName="text-sm sm:text-base"
            bookingNumber={bookingTabModel.bookingNumber}
            datesRange={datesRange}
          ></DiverBookingTitle>

          {isExpanded ? (
            <div
              className="flex cursor-pointer text-gray-400 hover:text-gray-600"
              onClick={(e) => {
                setIsExpanded(!isExpanded);
                e.stopPropagation();
              }}
            >
              <AppHeroIcons.actionReduce className="ml-1 h-4 w-4 lg:h-5 lg:w-5" />
              <div className="hidden sm:flex text-xs italic ml-1">DÉTAILS</div>
            </div>
          ) : (
            <div
              className="flex cursor-pointer text-gray-400 hover:text-gray-600"
              onClick={(e) => {
                setIsExpanded(!isExpanded);
                e.stopPropagation();
              }}
            >
              <AppHeroIcons.actionExtend className="ml-1 h-4 w-4 lg:h-5 lg:w-5" />
              <div className="hidden sm:flex text-xs italic ml-1">DÉTAILS</div>
            </div>
          )}
        </h2>

        <div className="flex gap-x-4 md:gap-x-8 gap-y-2 flex-wrap">
          {(bookingGroupBookingGroup || !!booking.details?.agencyId) && (
            <div className="flex gap-2 flex-nowrap items-start">
              <BookingGroupLabel bookingGroup={booking.bookingGroup} />

              <AgencyLabel
                className="text-xs"
                agencyId={booking.details?.agencyId}
              />
            </div>
          )}
          <div className="flex gap-2 flex-nowrap items-center">
            {isMultiDiveCenters && (
              <DiveCenterProBadge
                className="my-px"
                diveCenter={booking.isSharedBooking ? null : bookingDiveCenter}
              />
            )}
            <BookingStatusLabel booking={booking} />
            {booking.details?.externalRef?.trim()?.length > 0 && (
              <div className="text-xs text-app-dark-orange font-bold">
                #{booking.details?.externalRef}
              </div>
            )}
            <span className="text-xs text-app-blue whitespace-nowrap ">
              {partiticipantsCount} PLONGÉE(S)
            </span>
            {!isExpanded && !isUniqueMember && (
              <span className="text-xs whitespace-nowrap">
                <span className="text-gray-600 ">
                  {bookingMembersFull.length} PARTICIPANT(S)
                </span>
                <span className="hidden text-gray-600 md:inline text-xs">
                  {': '}
                  {bookingMembersFull
                    .map(
                      (bm, memberIndex) =>
                        nameFormatter.formatFirstName(bm.diver) ??
                        `n°${memberIndex + 1}`,
                    )
                    .join(', ')}
                </span>
              </span>
            )}
          </div>
        </div>
        {stepsToDisplay.length > 0 && (
          <div className="flex flex-col gap-1">
            {stepsToDisplay.map((step) => (
              <DiverBookingCardJourneySteps key={step._id} step={step} />
            ))}
          </div>
        )}
        <div>
          <div className="flex-grow flex gap-2 flex-nowrap items-center">
            {booking.details?.customField1?.trim()?.length > 0 && (
              <div className="text-xs">
                <h3 className="data-label text-gray-600 font-bold max-w-full">
                  {customerSettings?.booking?.customField1Label}
                </h3>
                <div className="data-value text-app-blue max-w-full truncate">
                  {booking.details?.customField1}
                </div>
              </div>
            )}
            {booking.details?.customField2?.trim()?.length > 0 && (
              <div className="text-xs">
                <h3 className="data-label text-gray-600 font-bold max-w-full">
                  {customerSettings?.booking?.customField2Label}
                </h3>
                <div className="data-value text-app-blue max-w-full truncate">
                  {booking.details?.customField2}
                </div>
              </div>
            )}
            {booking.details?.customField3?.trim()?.length > 0 && (
              <div className="text-xs">
                <h3 className="data-label text-gray-600 font-bold max-w-full">
                  {customerSettings?.booking?.customField3Label}
                </h3>
                <div className="data-value text-app-blue max-w-full truncate">
                  {booking.details?.customField3}
                </div>
              </div>
            )}{' '}
            {booking.details?.customBoolean1 === true && (
              <div className="text-xs">
                <h3 className="data-label text-gray-600 font-bold max-w-full">
                  {customerSettings?.booking?.customBoolean1Label}
                </h3>
                <div className="data-value text-app-blue max-w-full truncate">
                  {booking.details?.customBoolean1 ? 'oui' : 'non'}
                </div>
              </div>
            )}
            {booking.details?.customBoolean2 === true && (
              <div className="text-xs">
                <h3 className="data-label text-gray-600 font-bold max-w-full">
                  {customerSettings?.booking?.customBoolean2Label}
                </h3>
                <div className="data-value text-app-blue max-w-full truncate">
                  {booking.details?.customBoolean2 ? 'oui' : 'non'}
                </div>
              </div>
            )}
            {booking.details?.customBoolean3 === true && (
              <div className="text-xs">
                <h3 className="data-label text-gray-600 font-bold max-w-full">
                  {customerSettings?.booking?.customBoolean3Label}
                </h3>
                <div className="data-value text-app-blue max-w-full truncate">
                  {booking.details?.customBoolean3 ? 'oui' : 'non'}
                </div>
              </div>
            )}
          </div>
        </div>

        {booking.bookingInitialPlan?.privateComment && (
          <div className="text-xs text-gray-600 italic">
            {booking.bookingInitialPlan?.privateComment}
          </div>
        )}
      </div>
      <DiverBookingCardMainButtonsBar
        className="hidden lg:flex"
        globalState={globalState}
        actions={actions}
        bookingTabModel={bookingTabModel}
        onClickBooking={onClickBooking}
        setIsExpanded={setIsExpanded}
      />
      {bookingViewAuth.edit && (
        <div className="sm:ml-3 md:ml-4 flex items-center">
          {hamburgerMenuItems?.length > 0 && (
            <AppButtonMultiActions items={hamburgerMenuItems} />
          )}
          {/* <AppHamburgerMenu
            buttonClassName="mr-2 bg-status-warn hover:bg-status-warn-dark text-gray-200 hover:text-white"
            position="left-start"
            items={hamburgerMenuItems}
            buttonIcon={AppHeroIcons.actionMenu}
          >
            <h2 className="text-center text-lg leading-6 p-2 font-medium text-gray-800">
              Réservation
            </h2>
          </AppHamburgerMenu> */}
        </div>
      )}
    </div>
  );
}
