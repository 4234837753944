import {
  ClubResumeGql_pro,
  ClubSettingsCustomer,
  PRO_AppDocResume,
} from '@mabadive/app-common-model';
import { dataSorter, dateService } from '@mabadive/app-common-services';
import { AppIconsInquiry } from '../../../../../../../../_core/modules/layout/icons';
import { PRO_BookingMemberFull_WithDocs } from '../../../../DiverBookingPage/models';
import {
  DiverBookingSpace_DocDataResume,
  DiverBookingSpace_DocDataResume_Status,
} from '../model/DiverBookingSpace_DocDataResume.type';

export const docDataResumeBuilder_MedicalForm = {
  buildMedicalForm,
};

function buildMedicalForm({
  bookingMemberFull,
  clubResume,
}: {
  bookingMemberFull: PRO_BookingMemberFull_WithDocs;
  clubResume: ClubResumeGql_pro;
}): DiverBookingSpace_DocDataResume {
  const customerSettings = clubResume.clubSettings?.customer;

  const docResume = getDocResume(bookingMemberFull);

  const { status, issueDate } = buildAttributes({
    docResume,
    customerSettings,
    bookingMemberFull,
  });

  const icon = AppIconsInquiry['medical-form'];

  const docDataResume: DiverBookingSpace_DocDataResume = {
    title: 'Form. médical',
    issueDate,
    status,
    appDoc: docResume,
    icon,
  };
  return docDataResume;
}

function getDocResume(bookingMemberFull: PRO_BookingMemberFull_WithDocs) {
  const docResumes = bookingMemberFull.docResumes.filter(
    (x) => x.docRef === 'medical-form',
  );
  if (docResumes.length > 1) {
    const sorted = dataSorter.sortMultiple(docResumes, {
      getSortAttributes: (x) => [
        {
          value: x.details?.docDate,
          asc: false,
        },
        {
          value:
            x.status === 'validated'
              ? 1
              : x.status === 'submitted'
              ? 2
              : x.status === 'rejected'
              ? 3
              : 4,
          asc: false,
        },
      ],
    });
    // on retourne le premier d'après les critères de tri
    return sorted?.[0];
  } else {
    // on retourne le premier, ou rien
    return docResumes?.[0];
  }
}
function buildAttributes({
  docResume,
  customerSettings,
  bookingMemberFull,
}: {
  docResume: PRO_AppDocResume;
  customerSettings: ClubSettingsCustomer;
  bookingMemberFull: PRO_BookingMemberFull_WithDocs;
}): {
  status: DiverBookingSpace_DocDataResume_Status;
  issueDate: Date;
} {
  if (docResume) {
    // on se base sur un doc
    let issueDate = docResume.details?.docDate;
    if (
      !issueDate &&
      bookingMemberFull.bookingMember.details?.medicalForm?.date
    ) {
      issueDate = bookingMemberFull.bookingMember.details?.medicalForm?.date;
    }
    const docValidityInYears = docResume.details?.docValidityInYears ?? 1;

    let status: DiverBookingSpace_DocDataResume_Status;
    if (issueDate && dateService.getAge(issueDate) >= docValidityInYears) {
      status = 'expired';
    } else {
      status = docResume?.status;
    }

    return {
      status,
      issueDate,
    };
  } else {
    const docValidityInYears = 1;
    const issueDate =
      bookingMemberFull.bookingMember.details?.medicalForm?.date;

    let status: DiverBookingSpace_DocDataResume_Status;
    if (issueDate && dateService.getAge(issueDate) >= docValidityInYears) {
      status = 'expired';
    } else {
      if (
        issueDate ||
        bookingMemberFull.bookingMember.details?.medicalForm?.filled
      ) {
        if (
          customerSettings?.diving?.medicalFormChecked &&
          !bookingMemberFull.bookingMember.details?.medicalForm?.filled
        ) {
          status = 'submitted';
        } else {
          status = 'validated';
        }
      } else {
        status = 'missing';
      }
    }
    return {
      status,
      issueDate,
    };
  }
}
