/* eslint-disable @typescript-eslint/no-unused-vars */
import { BookingParticipantEditorFormModel } from '@mabadive/app-common-model';
import { clubMassiveUpdatorClient } from 'src/business/_core/data/app-operation';
import {
  BookingParticipantEditorParticipant,
  BookingParticipantEditorResult,
} from '../../../../../models';
import { bookingMemberChangesBuilder } from './booking-member/bookingMemberChangesBuilder.service';
import { clubDiverChangesBuilder } from './diver';

export const bookingParticipantEditorDialogChangesBuilderEditDiver = {
  buildChangesResult,
};
function buildChangesResult({
  participantData,
  hasFormChanges,
  initialFormValue,
  formValue,
}: {
  participantData?: BookingParticipantEditorParticipant;
  hasFormChanges: boolean;
  initialFormValue: BookingParticipantEditorFormModel;
  formValue: BookingParticipantEditorFormModel;
}): {
  hasChanges: boolean;
  result?: BookingParticipantEditorResult;
} {
  if (!hasFormChanges) {
    return {
      hasChanges: false,
    };
  }

  const bookingId = participantData?.bookingId;

  const payload = clubMassiveUpdatorClient.createEmptyPayload({
    logContext: 'edit diver',
    bookingIdsToClean: [bookingId],
  });
  const result: BookingParticipantEditorResult = {
    bookingId: bookingId,
    initialDiver: participantData.diver,
    bookingMemberId: participantData?.bookingMember?._id,
    changes: payload,
    aggregated: {},
  };

  const { finalDiver } = clubDiverChangesBuilder.buildClubDiverChanges({
    formValue,
    participantData,
    result,
  });

  if (participantData.bookingMember?._id && formValue?.bookingMember?._id) {
    // si édition d'un plongeur sans résa, il n'y a pas de bookingMember
    bookingMemberChangesBuilder.buildBookingMemberChanges({
      finalDiver,
      participantData,
      result,
      initialFormValue,
      formValue,
    });
  }

  return { hasChanges: true, result };
}
