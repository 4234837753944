/* eslint-disable @typescript-eslint/no-unused-vars */
import clsx from 'clsx';
import React from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { useClubResume } from 'src/business/club/data/hooks';
import {
  AppFormControlRHF,
  AppInputBooleanSwitchRHF,
  AppInputColorRHF,
  AppInputRHF,
} from 'src/lib/form';
import { AppMessageLight } from '../../../../../../business/_core/modules/layout';
import { useAppSecurityUserHasRole } from '../../../../../../business/auth/services';
import { useDiveModesOptions } from '../../../../../_components/options';
import { ClubSettingsInquiryEditPagePanelLocalState } from '../ClubSettingsInquiryEditPagePanel/useClubSettingsInquiryEditPagePanelLocalState.hook';
import { AppInquiryFormModel } from './model';

export const ClubSettingsInquiryEditPageForm_TabResponsesProcessing = ({
  localState,
  form,
  className,
}: {
  localState: ClubSettingsInquiryEditPagePanelLocalState;
  form: UseFormReturn<AppInquiryFormModel>;
  className?: string;
}) => {
  const { state, data } = localState;

  const clubResume = useClubResume();
  const isMultiDiveCenters = clubResume.diveCenters.length > 1;

  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const [color, inquiryType, enabled, autoActivationEnabled] = useWatch({
    control,
    name: [
      'settings.color',
      'type',
      'settings.enabled',
      'settings.autoActivation.enabled',
    ],
  });

  const diveModesOptions = useDiveModesOptions({
    type: 'all-included-disabled',
    format: 'short-ref',
  });

  const isSuperAdmin = useAppSecurityUserHasRole('super-admin');

  return (
    <div
      className={clsx('app-card p-4 border-t-8', className)}
      style={{
        borderColor: color,
      }}
    >
      <div className="grid gap-4">
        <div className="font-medium text-gray-800 uppercase">
          Questionnaire client - traitement des bonnes réponses
        </div>
        <div
          className={clsx(
            'w-full grid sm:grid-cols-2 md:grid-cols-2 lg:flex gap-4',
          )}
        >
          <AppFormControlRHF
            className="w-64"
            label="Couleur bonnes réponses (PDF)"
            helpDescription="Couleur des bonnes réponses sur le PDF"
            required={true}
            control={control}
            name={'settings.responsesProcessing.expectedAnswerColor'}
            renderComponent={(props) => <AppInputColorRHF {...props} />}
          />
          <AppFormControlRHF
            label="Valider automatiquement le questionnaire"
            helpDescription='Marquer le questionnaire comme "valide" si au moins une mauvaise réponse est fournie.'
            control={control}
            name={'settings.responsesProcessing.expectedAnswerAutoValidate'}
            renderComponent={(props) => (
              <AppInputBooleanSwitchRHF
                {...props}
                label={'Activé'}
                labelToggleFalse="Désactivé"
              />
            )}
          />
        </div>
        <div className="font-medium text-gray-800 uppercase">
          Questionnaire client - traitement des mauvaises réponses
        </div>
        <div
          className={clsx(
            'w-full grid sm:grid-cols-2 md:grid-cols-2 lg:flex gap-4',
          )}
        >
          <AppFormControlRHF
            className="w-64"
            label="Couleur mauvaises réponses (PDF)"
            helpDescription="Couleur des mauvaises réponses sur le PDF"
            required={true}
            control={control}
            name={'settings.responsesProcessing.unexpectedAnswerColor'}
            renderComponent={(props) => <AppInputColorRHF {...props} />}
          />
          <AppFormControlRHF
            label="Refuser automatiquement le questionnaire"
            helpDescription='Marquer le questionnaire comme "invalide" si au moins une mauvaise réponse est fournie.'
            control={control}
            name={'settings.responsesProcessing.unexpectedAnswerAutoDeny'}
            renderComponent={(props) => (
              <AppInputBooleanSwitchRHF
                {...props}
                label={'Activé'}
                labelToggleFalse="Désactivé"
              />
            )}
          />
          <AppFormControlRHF
            className={'w-full sm:col-span-2 md:col-span-1'}
            label='Message à afficher au client si "invalide"'
            helpDescription="Message qui s'affiche instantanément au client si il donne une réponse invalide."
            control={control}
            name={
              'settings.responsesProcessing.unexpectedAnswerMessageToDisplay'
            }
            renderComponent={(props) => (
              <AppInputRHF
                {...props}
                fullWidth={true}
                multiline
                rowsMax={15}
                rows={2}
              />
            )}
          />
        </div>
        <AppMessageLight type="info" className="mt-4 mb-2 text-sm md:text-base">
          <div className="grid gap-2">
            <p>
              Le questionnaire de chaque client est transformé en fichier PDF,
              et archivé en tant que document dans Mabadive, sur la fiche
              client.
            </p>
            <p>
              Le PDF contient toutes les questions et réponses, ainsi que la
              signature manuscrite du client, si vous l'avez activée.
            </p>
          </div>
        </AppMessageLight>
      </div>
    </div>
  );
};
