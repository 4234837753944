/* eslint-disable @typescript-eslint/no-unused-vars */
import Tippy from '@tippyjs/react';
import clsx from 'clsx';
import React from 'react';
import { UseFieldArrayReturn, UseFormReturn } from 'react-hook-form';
import { AppIconsAction } from 'src/business/_core/modules/layout/icons';
import { useAppSecurityUser } from 'src/business/auth/services';
import { useClubResume } from 'src/business/club/data/hooks';
import {
  AppInputBooleanSwitchRHF,
  AppInputColorRHF,
  AppInputRHF,
  AppSingleSelect2HeadlessUIRHF,
} from 'src/lib/form';
import { AppFormControlRHF } from 'src/lib/form/components/AppFormControl/AppFormControlRHF';
import { APP_LIB_ICONS_OPTIONS } from '../../../../../business/_core/modules/layout/icons/lib';
import { ClubSettingsCustomerFormFieldsEditFormResidentTypeOptions_OnClickAction } from './ClubSettingsCustomerFormFieldsEditFormResidentTypeOptions';
import { ClubSettingsCustomerEditorFormModel } from './model';

export const ClubSettingsCustomerFormFieldsEditFormResidentTypeOptionsItem = ({
  className,
  form,
  fieldArray,
  fieldIndex,
  onClick,
}: {
  className?: string;
  form: UseFormReturn<ClubSettingsCustomerEditorFormModel>;
  fieldArray: UseFieldArrayReturn<
    ClubSettingsCustomerEditorFormModel,
    'clubSettings.customer.general.residentTypeOptions',
    'form_ref'
  >;

  fieldIndex: number;
  onClick: (
    action: ClubSettingsCustomerFormFieldsEditFormResidentTypeOptions_OnClickAction,
  ) => void;
}) => {
  const {
    register,
    handleSubmit,
    watch,
    formState,
    control,
    setValue,
    getValues,
  } = form;

  const securityUser = useAppSecurityUser();

  const clubResume = useClubResume();
  const clubReference = clubResume.reference;

  const { fields, append, prepend, remove, swap, move, insert } = fieldArray;

  const formAttributeBaseName =
    `clubSettings.customer.general.residentTypeOptions.${fieldIndex}` as const;

  const isMoveDownAllowed =
    fieldArray.fields.length > 1 && fieldIndex + 1 < fieldArray.fields.length;
  const isMoveUpAllowed = fieldArray.fields.length > 1 && fieldIndex !== 0;

  return (
    <div
      className={clsx(
        'border-l-4 border-gray-600 pl-2 grid md:flex gap-2 lg:gap-4 items-start',
        className,
      )}
    >
      <div
        className={clsx(
          'h-full flex md:flex-col md:justify-center gap-2',
          fieldArray.fields.length === 1 && 'invisible',
        )}
      >
        <Tippy
          delay={[1000, 100]}
          placement="top"
          content={'Déplacer au dessus'}
        >
          <AppIconsAction.up
            className={clsx(
              'p-1 w-8 h-8 bg-gray-200 rounded-full text-white',
              isMoveUpAllowed
                ? 'hover:bg-gray-600 cursor-pointer'
                : 'opacity-50 cursor-not-allowed',
            )}
            onClick={() => isMoveUpAllowed && onClick('move-up')}
          />
        </Tippy>
        <Tippy
          delay={[1000, 100]}
          placement="top"
          content={'Déplacer en dessous'}
        >
          <AppIconsAction.down
            className={clsx(
              'p-1 w-8 h-8 bg-gray-200 rounded-full text-white',
              isMoveDownAllowed
                ? 'hover:bg-gray-600 cursor-pointer'
                : 'opacity-50 cursor-not-allowed',
            )}
            onClick={() => isMoveDownAllowed && onClick('move-down')}
          />
        </Tippy>
      </div>
      <div>
        <div className="flex flex-row flex-wrap gap-2">
          <AppFormControlRHF
            label={'Activé'}
            control={control}
            name={`${formAttributeBaseName}.enabled`}
            renderComponent={(props) => <AppInputBooleanSwitchRHF {...props} />}
          />

          <AppFormControlRHF
            label="Diminutif"
            className="w-16"
            control={control}
            name={`${formAttributeBaseName}.shortName`}
            required={true}
            validation={{
              rules: {
                maxLength: {
                  value: 1,
                  message:
                    'Le diminutif doit avoir une longueur de 1 caractère',
                },
              },
            }}
            renderComponent={(props) => <AppInputRHF {...props} />}
          />
          <AppFormControlRHF
            label="Libellé"
            className="w-48"
            control={control}
            name={`${formAttributeBaseName}.label`}
            required={true}
            renderComponent={(props) => <AppInputRHF {...props} />}
          />
          <AppFormControlRHF
            className="w-36"
            label={'Couleur'}
            control={control}
            name={`${formAttributeBaseName}.color`}
            required={true}
            renderComponent={(props) => (
              <AppInputColorRHF
                {...props}
                required={false}
                className={'my-2 text-center text-xl font-bold'}
              />
            )}
          />
          <AppFormControlRHF
            label={'Icône'}
            className="w-48"
            control={control}
            name={`${formAttributeBaseName}.libIconRef`}
            required={true}
            renderComponent={(props) => (
              <AppSingleSelect2HeadlessUIRHF
                {...props}
                theme={'tailwind'}
                options={APP_LIB_ICONS_OPTIONS}
                disableClearButton={true}
              />
            )}
          />
        </div>
      </div>
      {/* <div className="flex gap-2 md:gap-6">
        <Tippy delay={[1000, 100]} placement="top" content={'Dupliquer'}>
          <AppIconsAction.clone
            className="p-1 w-8 h-8 bg-green-200 hover:bg-green-600 rounded-full text-white cursor-pointer"
            onClick={() => {
              onClick('clone');
            }}
          />
        </Tippy>
        <Tippy delay={[1000, 100]} placement="top" content={'Supprimer'}>
          <AppIconsAction.delete
            className="p-1 w-8 h-8 bg-red-200 hover:bg-red-600 rounded-full text-white cursor-pointer"
            onClick={() => {
              onClick('delete');
            }}
          />
        </Tippy>
      </div> */}
    </div>
  );
};
