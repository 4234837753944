/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { uiStore } from 'src/business/_core/store';

import {
  ClubSettingsProductOfferCategoryDialogState,
  ClubSettingsProductOfferCategoryEditFormModel,
} from './_model';
import { ClubSettingsProductOfferCategoryDialogLocalState } from './useClubSettingsProductOfferCategoryDialogLocalState.hook';

export function useClubSettingsProductOfferCategoryDialogSubmit({
  handleSubmit,
  localState,
  inputState,
}: Pick<
  UseFormReturn<ClubSettingsProductOfferCategoryEditFormModel>,
  'handleSubmit'
> & {
  localState: ClubSettingsProductOfferCategoryDialogLocalState;
  inputState: React.PropsWithChildren<ClubSettingsProductOfferCategoryDialogState>;
}) {
  return useCallback(async () => {
    try {
      return handleSubmit(
        (formValue: ClubSettingsProductOfferCategoryEditFormModel) => {
          const { onConfirm, closeDialog } = inputState;
          console.log('xxx formValue:', formValue);

          console.log('xxx localState.state.mode:', localState.state.mode);
          if (
            localState.state.hasFormChanges ||
            localState.state.mode === 'create'
          ) {
            return onConfirm({
              formValue,
            });
          } else {
            return closeDialog();
          }
        },
        (err) => {
          uiStore.snackbarMessage.set({
            type: 'error',
            content:
              'Erreur innatendue. Veuillez vérifier votre connexion Internet et ré-essayer. Si cela persiste, merci de nous contacter.. Si cela persiste, merci de nous contacter.',
          });
          throw err;
        },
      )();
    } catch (err) {
      // message already displayed
    }
  }, [handleSubmit, inputState, localState]);
}
