/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { useAppSecurityUserHasRole } from 'src/business/auth/services';
import {
  useClubResume,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import { useAppCurrencyMain } from 'src/pages/_components/options';
import {
  ClubDialogsState,
  UseClubDialogsProps,
  useClubDialogs,
  useClubDialogsActionsPersist,
} from 'src/pages/_dialogs';
import { ClubSettingsServicesPageBasePanel } from '../../../_common';
import { ClubSettingsServicesPageLocalState } from '../../../useClubSettingsServicesPageLocalState.hook';
import { ClubSettingsServicesActivitiesViewPanelFreeDive } from './ClubSettingsServicesActivitiesViewPanelFreeDive';
import { ClubSettingsServicesActivitiesViewPanelOther } from './ClubSettingsServicesActivitiesViewPanelOther';
import { ClubSettingsServicesActivitiesViewPanelScuba } from './ClubSettingsServicesActivitiesViewPanelScuba';
import { ClubSettingsServicesActivitiesViewPanelTraining } from './ClubSettingsServicesActivitiesViewPanelTraining';

export const ClubSettingsServicesActivitiesViewPanel = ({
  localState,
}: {
  localState: ClubSettingsServicesPageLocalState;
}) => {
  const diveCenterResume = useDiveCenterResume();
  const clubResume = useClubResume();

  const clubSettings = clubResume?.clubSettings;

  const actionsPersist: UseClubDialogsProps = useClubDialogsActionsPersist();
  const dialogsState: ClubDialogsState = useClubDialogs(actionsPersist);

  const mainCurrency = useAppCurrencyMain();

  const isSuperAdmin = useAppSecurityUserHasRole('super-admin');

  const generalSettings = clubSettings.general;

  const { state, dialogs } = localState;

  const selectedTab = state.tabs.selected;

  return (
    <ClubSettingsServicesPageBasePanel
      localState={localState}
      onClick={() => {
        state.editEnabled && dialogs.clubSettingsServices.open(selectedTab, {});
      }}
    >
      <div className="grid gap-4 md:gap-6">
        <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-4">
          <ClubSettingsServicesActivitiesViewPanelScuba />
          <ClubSettingsServicesActivitiesViewPanelTraining />
          <ClubSettingsServicesActivitiesViewPanelFreeDive />
          <ClubSettingsServicesActivitiesViewPanelOther />
        </div>
      </div>
    </ClubSettingsServicesPageBasePanel>
  );
};
