/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  AppEntityUpdatePatch,
  CLUB_DIVER_TECHNICAL_LEVELS,
  ClubResumeDiveSite,
} from '@mabadive/app-common-model';
import {
  diverTechnicalLevelFormatter,
  diveSiteFormatter,
  jsonPatcher,
} from '@mabadive/app-common-services';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { proMultiMutationsApiClient } from 'src/business/_core/data/app-operation';
import { confirmDialog } from 'src/business/_core/modules/layout/components/ConfirmDialog/confirmDialog.service';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import {
  DiveSiteIconDef,
  useDiveSiteIcon,
} from 'src/business/club/modules/club-dive-session/components';
import { AppEmojiIcon } from 'src/lib/form/components/AppEmojiPicker';

export const DiveSitesTableRow = ({
  diveSite,
  onClick,
}: {
  diveSite: ClubResumeDiveSite;
  onClick?: () => void;
}) => {
  const diveSiteIcon: DiveSiteIconDef = useDiveSiteIcon(diveSite._id);

  const attributes: {
    label: string;
    icon?: JSX.Element;
  }[] = useMemo(
    () =>
      [
        {
          label: diveSiteFormatter.formatType(diveSite.type),
          icon: diveSiteIcon ? (
            <diveSiteIcon.icon
              className={clsx('w-4 h-4', diveSiteIcon.colorClass)}
            />
          ) : null,
        },
        {
          label: diveSiteFormatter.formatWreck(diveSite.wreck),
          // icon: AppIconsSites.artificial,
        },
        {
          label: diveSiteFormatter.formatWall(diveSite.wall),
          // icon: AppIconsSites.artificial,
        },
        {
          label: diveSiteFormatter.formatManMade(diveSite.manMade),
        },
      ].filter((x) => !!x.label),
    [
      diveSite.manMade,
      diveSite.type,
      diveSite.wall,
      diveSite.wreck,
      diveSiteIcon,
    ],
  );

  const minDepth = diveSite.privateDetails?.minDepth;
  const maxDepth = diveSite.depth;

  return (
    <tr className={'cursor-pointer'} onClick={onClick}>
      <td
        className={`px-2 py-0.5 whitespace-nowrap uppercase font-bold ${
          diveSite.enabled ? '' : 'text-red-400'
        }`}
      >
        <div className="flex gap-2">
          <AppHeroIcons.diveSite className="inline w-4 h-4" />
          <AppEmojiIcon id={diveSite.emojiId} size={20} />
          <span>{diveSite.name}</span>
        </div>
        {attributes.length > 0 && (
          <div className="text-xs text-gray-500 font-normal flex gap-2">
            {attributes.map((x, i) => (
              <span className="flex gap-1" key={i}>
                {x.icon && x.icon}
                {x.label}
              </span>
            ))}
          </div>
        )}
        <div className="text-xs text-gray-500 font-normal lg:hidden">
          {diveSite.description}
        </div>
        {diveSite.privateDetails?.divingTechnicalLevels?.length > 0 && (
          <div className="hidden xl:flex gap-1 flex-wrap text-xs text-app-primary-light font-normal">
            {diveSite.privateDetails?.divingTechnicalLevels?.length ===
            CLUB_DIVER_TECHNICAL_LEVELS.length
              ? 'Tous niveaux'
              : diveSite.privateDetails?.divingTechnicalLevels.map((x, i) => (
                  <span className="" key={i}>
                    {diverTechnicalLevelFormatter.formatTechnicalLevel(x, {
                      format: 'short',
                    })}
                  </span>
                ))}
          </div>
        )}
      </td>

      <td
        className={
          'hidden sm:table-cell px-2 py-0.5 whitespace-nowrap uppercase'
        }
      >
        {diveSite.ref}{' '}
      </td>
      <td className={'hidden sm:table-cell px-2 py-0.5 whitespace-nowrap'}>
        {diveSite.distance}{' '}
        {(diveSite.distance === 0 || diveSite.distance > 0) && (
          <span className="text-xs font-gray-400">nmi</span>
        )}
      </td>
      <td className={'hidden sm:table-cell px-2 py-0.5 whitespace-nowrap'}>
        {minDepth > 0 || (minDepth === 0 && maxDepth > 0) ? (
          maxDepth > 0 ? (
            <span>
              {minDepth}
              {' - '}
              {maxDepth}
              <span className="text-xs font-gray-400">m</span>
            </span>
          ) : (
            <span>
              {' > '}
              {minDepth}
              <span className="text-xs font-gray-400">m</span>
            </span>
          )
        ) : maxDepth > 0 ? (
          <span>
            {' < '}
            {maxDepth}
            <span className="text-xs font-gray-400">m</span>
          </span>
        ) : null}
      </td>
      <td className={'hidden lg:table-cell text-xs text-gray-500 px-2 py-0.5'}>
        {diveSite.description}
      </td>
      <td className={'px-2 py-0.5 text-right'}>
        {diveSite.enabled ? (
          <button
            type="button"
            title="Supprimer ce site"
            className="p-1 bg-gray-200 hover:bg-red-400 text-white rounded-full"
            onClick={async (e) => {
              e.stopPropagation();
              if (
                await confirmDialog.confirmPromise({
                  title: 'Supprimer ce site',
                  message:
                    'Êtes-vous sûr de vouloir supprimer ce site de plongée?',
                  type: 'noYesDanger',
                })
              ) {
                await proMultiMutationsApiClient.update({
                  deletedDiveSitesIds: [diveSite._id],
                });
              }
            }}
          >
            <AppHeroIcons.actionDelete className="h-6 w-6 " />
          </button>
        ) : (
          <button
            type="button"
            title="Ré-activer ce site"
            className="p-1 bg-gray-400 hover:bg-green-400 text-white rounded-full"
            onClick={async (e) => {
              e.stopPropagation();
              if (
                await confirmDialog.confirmPromise({
                  title: 'Ré-activer ce site',
                  message: 'Êtes-vous sûr de vouloir activer ce site archivé?',
                  type: 'noYesDanger',
                })
              ) {
                const patchOperations = jsonPatcher.compareObjects(
                  diveSite,
                  {
                    ...diveSite,
                    enabled: true,
                  },
                  {},
                );
                if (patchOperations.length) {
                  const patch: AppEntityUpdatePatch = {
                    pk: diveSite._id,
                    patchOperations,
                  };
                  await proMultiMutationsApiClient.update({
                    updatedDiveSites: [patch],
                  });
                }
              }
            }}
          >
            <AppHeroIcons.actionConfirm className="h-6 w-6 " />
          </button>
        )}
      </td>
    </tr>
  );
};
