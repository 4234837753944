/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ClubProductPackageOffer_ServiceCategory,
  ClubProductPackageOfferView,
  DIVE_TRAININGS_REFERENCE_MAP,
  DiveServiceOfferSupervision,
  DiveServiceOrganizationReference,
  DiveServiceTrainingCommercialCategoryId,
  DiveTraining,
} from '@mabadive/app-common-model';
import {
  clubProductPackageOfferViewSorter,
  offerSupervisionFormatter,
} from '@mabadive/app-common-services';
import { useEffect, useMemo, useState } from 'react';
import { useClubSettings } from 'src/business/club/data/hooks';
import { ValueLabel } from 'src/business/club/modules/_common/form';
import {
  ClubSettingsProductOfferTags,
  useClubSettingsProductOffersTags,
} from 'src/pages/SE-settings/SE-02_account/ClubSettingsProductOffersDialog/useClubSettingsProductOffersTags.hook';
import {
  useOrganizationsOptions,
  useTrainingCommercialCategoriesOptions,
} from 'src/pages/_components/options';

export type ClubSettingsProductOffersFilters = {
  commercialCategoryId?: DiveServiceTrainingCommercialCategoryId;
  orgRef?: DiveServiceOrganizationReference;
  divesCount?: number;
  supervision?: DiveServiceOfferSupervision;
};

export function useClubSettingsProductOffersFiltersState({
  contextMode,
  offers: offersAll,
  initialFilters,
}: {
  contextMode: ClubProductPackageOffer_ServiceCategory;
  offers: ClubProductPackageOfferView[];
  initialFilters?: ClubSettingsProductOffersFilters;
}) {
  const publicSettings = useClubSettings()?.publicSettings;
  const offersTagsAll: ClubSettingsProductOfferTags =
    useClubSettingsProductOffersTags({
      offers: offersAll,
    });

  const [filters, setFilters] = useState<ClubSettingsProductOffersFilters>(
    initialFilters ?? {
      commercialCategoryId: undefined,
      orgRef: undefined,
      divesCount: undefined,
      supervision: undefined,
    },
  );

  const trainingCommercialCategoriesOptions =
    useTrainingCommercialCategoriesOptions(offersTagsAll.trainingCategories);

  const divesCountsOptions = useMemo(
    () =>
      offersTagsAll.divesCounts.map((x) => ({
        value: x.divesCount,
        label: `x${x.divesCount} (${x.quantity})`,
      })),
    [offersTagsAll.divesCounts],
  );

  const clubSettings = useClubSettings();

  const offersSorted = useMemo(
    () =>
      clubProductPackageOfferViewSorter.sortOffersBy(offersAll, {
        attributeName: contextMode === 'training' ? 'training' : 'dive',
        asc: true,
        publicSettings,
        diveModesConf: clubSettings?.ui?.diveMode,
      }),
    [clubSettings?.ui?.diveMode, contextMode, offersAll, publicSettings],
  );

  const offersFiltered1 = useMemo(
    () =>
      offersSorted.filter((x) => {
        let isFilterOk = true;
        if (filters.commercialCategoryId) {
          const diveTraining: DiveTraining =
            DIVE_TRAININGS_REFERENCE_MAP[
              x.productPackage.trainingAttributes?.diveTrainingReference
            ];

          const commercialCategoryId = diveTraining?.commercialCategoryId;

          isFilterOk = commercialCategoryId === filters.commercialCategoryId;
        }
        if (filters.divesCount > 0) {
          isFilterOk =
            x.productPackage?.diveAttributes?.divesCount === filters.divesCount;
        }
        return isFilterOk;
      }),
    [filters.commercialCategoryId, filters.divesCount, offersSorted],
  );

  const offersTagsAfterFilter1: ClubSettingsProductOfferTags =
    useClubSettingsProductOffersTags({
      offers: offersFiltered1,
    });

  useEffect(() => {
    if (
      filters.orgRef &&
      !offersTagsAfterFilter1.organizations.find(
        (x) => x.orgRef === filters.orgRef,
      )
    ) {
      // reset filter 2
      setFilters({
        ...filters,
        orgRef: undefined,
      });
    }
  }, [filters, filters.orgRef, offersTagsAfterFilter1.organizations]);

  const organizationsOptions = useOrganizationsOptions(
    offersTagsAfterFilter1.organizations,
  );
  const supervisionOptions = useMemo(
    () =>
      offersTagsAfterFilter1.supervisions.map((x) => {
        const option: ValueLabel<DiveServiceOfferSupervision> = {
          value: x.supervision,
          label: `${offerSupervisionFormatter
            .formatOfferSupervision(x.supervision, { format: 'full' })
            .toUpperCase()} (${x.quantity})`,
        };
        return option;
      }),
    [offersTagsAfterFilter1.supervisions],
  );

  const offersFiltered = useMemo(
    () =>
      offersFiltered1.filter((x) => {
        let isFilterOk = true;
        if (filters.orgRef) {
          isFilterOk =
            x.productPackage?.productAttributes
              ?.defaultOrganizationReference === filters.orgRef;
        }
        if (filters.supervision) {
          isFilterOk =
            x.productPackage?.diveAttributes?.supervision ===
            filters.supervision;
        }
        return isFilterOk;
      }),
    [filters.orgRef, filters.supervision, offersFiltered1],
  );

  return {
    data: {
      offersAll,
      offersFiltered,
    },
    state: {
      filters,
      setFilters,
      trainingCommercialCategoriesOptions,
      organizationsOptions,
      divesCountsOptions,
      supervisionOptions,
    },
  };
}

export type ClubSettingsProductOffersFiltersState = ReturnType<
  typeof useClubSettingsProductOffersFiltersState
>;
