/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useMemo } from 'react';
import {
  FieldArrayWithId,
  UseFieldArrayReturn,
  UseFormReturn,
} from 'react-hook-form';
import { AppSingleAutocomplete2RHF } from 'src/lib/form';
import { AppFormControlRHF } from 'src/lib/form/components/AppFormControl/AppFormControlRHF';

import {
  DiveServiceTrainingType,
  TRAINING_TYPES,
} from '@mabadive/app-common-model';
import { trainingTypeFormatter } from '@mabadive/app-common-services';
import { useAppSecurityUserHasRole } from 'src/business/auth/services';
import { useClubResume, useClubSettings } from 'src/business/club/data/hooks';
import { ValueLabel } from 'src/business/club/modules/_common/form';
import { ClubSettingsProductOffersFormModel } from '../../_model';
import { ClubSettingsProductOffersDialogLocalState } from '../../useClubSettingsProductOffersDialogLocalState.hook';

export const ClubSettingsProductOffersFormOffer_Training_Category_SA = ({
  form,
  offerField,
  offersFieldArray,
  localState,
}: {
  form: UseFormReturn<ClubSettingsProductOffersFormModel>;
  offerField: FieldArrayWithId<
    ClubSettingsProductOffersFormModel,
    'offers',
    'id'
  >;
  offersFieldArray: UseFieldArrayReturn<
    ClubSettingsProductOffersFormModel,
    'offers',
    'id'
  >;
  localState: ClubSettingsProductOffersDialogLocalState;
}) => {
  const isAdminMode = localState.state.isAdminMode;
  const clubResume = useClubResume();
  const clubSettings = useClubSettings();
  const generalSettings = clubResume.clubSettings.general;
  const publicSettings = clubResume.clubSettings.publicSettings;
  const services = clubResume.clubSettings.services;

  const isSuperAdmin = useAppSecurityUserHasRole('super-admin');

  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const i = offersFieldArray.fields.findIndex(
    (x) => x.reference === offerField.reference,
  );

  // TODO plus tard : on pourrait améliorer ça en filtrant par clubResume.serviceTypes,
  // mais il faudrait que celui-ci soit éditable depuis la même page, pour ce qui concerne cette page
  const trainingTypeOptions = useMemo(
    () =>
      TRAINING_TYPES.map((trainingType) => {
        const option: ValueLabel<DiveServiceTrainingType> = {
          label: trainingTypeFormatter
            .formatTrainingType(trainingType)
            .toUpperCase(),
          value: trainingType,
        };
        return option;
      }),
    [],
  );

  return isAdminMode ? (
    <AppFormControlRHF
      visibility="super-admin"
      className="w-full col-span-2"
      label="Catégorie"
      control={control}
      name={`offers.${i}.productPackage.trainingAttributes.trainingType`}
      required={true}
      renderComponent={(props) => (
        <AppSingleAutocomplete2RHF {...props} options={trainingTypeOptions} />
      )}
    />
  ) : null;
};
