/* eslint-disable @typescript-eslint/no-unused-vars */
import { default as React } from 'react';
import { useAppSecurityUserClubAuthorizations } from 'src/business/auth/services';
import { AppFeatureListItem } from 'src/business/club/modules/_common/ui';
import { useAppCurrencyMain } from 'src/pages/_components/options';
import {
  useClubResume,
  useDiveCenterResume,
} from '../../../../../business/club/data/hooks';

export const ClubSettingsCustomerViewCard_CustomerMember = () => {
  const mainCurrency = useAppCurrencyMain();
  const diveCenterResume = useDiveCenterResume();
  const clubResume = useClubResume();

  const clubSettings = clubResume?.clubSettings;
  const customerSettings = clubSettings?.customer;

  const az = useAppSecurityUserClubAuthorizations();

  return (
    <>
      <h2 className="py-2  text-left uppercase text-lg leading-6 font-medium text-gray-800">
        Client - adhérent
      </h2>
      <ul className="relative grid gap-2 p-2 sm:px-4 font-bold text-gray-600">
        <AppFeatureListItem
          enabled={customerSettings?.general?.isMember}
          label="Membre du club (oui/non)"
        />
        <AppFeatureListItem
          enabled={customerSettings?.general?.memberRef}
          label="Numéro de membre"
        />
        <AppFeatureListItem
          enabled={customerSettings?.general?.membershipDate}
          label="Date d'adhésion"
        />
        <AppFeatureListItem
          enabled={customerSettings?.general?.isStudent}
          label="En formation club (oui/non)"
        />
        <AppFeatureListItem
          enabled={customerSettings?.general?.studentDescription}
          label="Détails formation club"
        />
      </ul>
    </>
  );
};
