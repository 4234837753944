import React, { useCallback } from 'react';
import { DiverPurchaseStoreEditorDialogState } from '../../models';

export function useOpenEditPurchaseStoreDialog({
  setPurchaseStoreEditorDialogState,
}: {
  setPurchaseStoreEditorDialogState: React.Dispatch<
    React.SetStateAction<DiverPurchaseStoreEditorDialogState>
  >;
}) {
  return useCallback(
    ({
      diver,
      purchasePackage,
      aggregatedData,
    }: Pick<
      DiverPurchaseStoreEditorDialogState,
      'diver' | 'purchasePackage' | 'aggregatedData'
    >) =>
      setPurchaseStoreEditorDialogState({
        isOpen: true,
        mode: 'edit',
        diver,
        purchasePackage,
        aggregatedData,
      }),
    [setPurchaseStoreEditorDialogState],
  );
}
