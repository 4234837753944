import {
  AppEntityUpdatePatch,
  ClubPurchasePackage,
} from '@mabadive/app-common-model';
import { changeDescriptorManager } from '@mabadive/app-common-services';
import { useCallback } from 'react';
import { DiverBookingPageUpdateState } from '../../../models';
import { DiverBookingPageSetUpdateStateFn } from '../../../useDiverBookingPageGlobalState.hook';

export function useDiverBookingPageCreatePurchasePackage({
  setUpdateState,
  updateState,
}: {
  setUpdateState: DiverBookingPageSetUpdateStateFn;
  updateState: DiverBookingPageUpdateState;
}) {
  return useCallback(
    ({
      newPurchasePackage,
      updatedProducts = [],
    }: {
      newPurchasePackage: ClubPurchasePackage;
      updatedProducts: AppEntityUpdatePatch[];
    }) => {
      let updateStateLocal = updateState;
      if (newPurchasePackage) {
        const purchasePackagesChanges = changeDescriptorManager.createOne(
          newPurchasePackage,
          {
            changeDescriptors: updateStateLocal.purchasePackagesChanges,
          },
        );
        const bookingProductsChanges = changeDescriptorManager.updateMany(
          updatedProducts,
          {
            changeDescriptors: updateStateLocal.bookingProductsChanges,
          },
        );

        updateStateLocal = {
          ...updateStateLocal,
          purchasePackagesChanges,
          bookingProductsChanges,
        };
        setUpdateState(updateStateLocal, {
          action: 'DiverBookingPageCreatePurchasePackage',
        });
      }
    },
    [setUpdateState, updateState],
  );
}
