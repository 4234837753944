import { useEffect, useState } from 'react';
import { useClubResume } from 'src/business/club/data/hooks';
import { ClubDialogsStateOld } from 'src/pages/_dialogs';
import {
  DiverBookingPageTriggerAction,
  DiverBookingPageTriggerActionId,
} from './models';
import { DiverBookingPageActions } from './useDiverBookingPageActions.hook';
import { DiverBookingPageGlobalState } from './useDiverBookingPageGlobalState.hook';

export function useDiverBookingPageInitialTriggerAction({
  globalState,
  dialogs,
  actions,
  isContentLoaded,
  setTriggerAction,
  setIsTriggerActionInitialized,
}: {
  globalState: DiverBookingPageGlobalState;
  dialogs: ClubDialogsStateOld;
  actions: DiverBookingPageActions;
  isContentLoaded: boolean;
  setTriggerAction: (triggerAction: DiverBookingPageTriggerAction) => any;
  setIsTriggerActionInitialized: (isTriggerActionInitialized: boolean) => any;
}) {
  const clubResume = useClubResume();

  const {
    updateState,
    setUpdateStateInner,
    setUpdateState,
    navigationContext,
    isPersistInProgress,
    setIsPersistInProgress,
    clubReference,
    focus,
    aggregatedData,
  } = globalState;

  const [initialActionId, setInitialActionId] = useState(
    navigationContext.action as DiverBookingPageTriggerActionId,
  );

  useEffect(
    () => {
      if (updateState.isInitialized && isContentLoaded) {
        if (initialActionId) {
          // avoid to trigger initial action twice
          setInitialActionId(undefined);

          // build action from navigationContext
          const {
            diverId,
            diveSessionReference,
            purchasePaymentId,
            bookingId,
          } = navigationContext;
          const initialAction = {
            id: initialActionId,
            diverId,
            diveSessionReference,
            purchasePaymentId,
            bookingId,
          } as unknown as DiverBookingPageTriggerAction;

          // trigger action now
          setTriggerAction(initialAction);
        }
        // done: auto-sélection du booking
        setIsTriggerActionInitialized(true);
      }
    },
    // NOTE: on ne veut déclencher ça qu'une seule fois
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [updateState.isInitialized, isContentLoaded, initialActionId],
  );
}
