/* eslint-disable @typescript-eslint/no-unused-vars */
import { Typography } from '@material-ui/core';
import React from 'react';
import { useClubResume } from 'src/business/club/data/hooks';
import { useRedirect } from 'src/business/_core/data/hooks';
import {
  AppPageContainerWithFixedBars,
  AppPageContentContainer,
  AppTabsBar,
  useAutoFocus,
} from 'src/business/_core/modules/layout';
import { clubListsUrlBuilder } from '../clubListsUrlBuilder.service';
import { CLUB_LISTS_TABS } from '../_common';
import { ClubDiversListPageConfigBar } from './ClubDiversListPageConfigBar';
import { ClubDiversListTable } from './ClubDiversListTable';
import {
  ClubDiversListPageLocalState,
  useClubDiversListPageLocalState,
} from './useClubDiversListPageLocalState.hook';

export const ClubDiversListPage = () => {
  const autoFocus = useAutoFocus();

  const redirectTo = useRedirect();

  const localState: ClubDiversListPageLocalState =
    useClubDiversListPageLocalState();

  const { diversTotalCount, loadableContent, filteredDivers } = localState;

  return (
    <AppPageContainerWithFixedBars
      marginBottom={false}
      paddingBottom={false}
      contentClassName="app-p-content"
      {...loadableContent}
      headerBar={() => (
        <>
          <AppTabsBar
            theme="primary"
            tabs={CLUB_LISTS_TABS}
            context={{}}
            value={'diver'}
            onChange={(tab) => {
              const url = clubListsUrlBuilder.buildClubListsTabUrl(tab);
              redirectTo(url);
            }}
            // disableNavigation={globalState?.updateState.hasChanges}
          />
          <div className="bg-gray-50 app-p-content">
            <ClubDiversListPageConfigBar localState={localState} />
          </div>
        </>
      )}
    >
      <AppPageContentContainer
        className="app-card-no-padding"
        paddingBottom={false}
      >
        <ClubDiversListTable divers={filteredDivers} />

        {diversTotalCount && !filteredDivers.length ? (
          <>
            <Typography variant="subtitle2">
              Aucun plongeur ne correspond à cette recherche.
            </Typography>
          </>
        ) : null}
      </AppPageContentContainer>
    </AppPageContainerWithFixedBars>
  );
};
