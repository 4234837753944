/* eslint-disable @typescript-eslint/no-unused-vars */
import { dateService } from '@mabadive/app-common-services';
import Tippy from '@tippyjs/react';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { UseFieldArrayReturn, UseFormReturn, useWatch } from 'react-hook-form';
import {
  AppHeroIcons,
  AppIconsAction,
} from 'src/business/_core/modules/layout/icons';
import { useAppSecurityUserClubAuthorizations } from 'src/business/auth/services';
import {
  useClubResume,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import { ValueLabel } from 'src/business/club/modules/_common/form';
import {
  AppFormLabel,
  AppInputBooleanSwitchRHF,
  AppSingleAutocomplete2RHF,
} from 'src/lib/form';
import { AppFormControlRHF_Deprecated } from 'src/lib/form/components/AppFormControl/AppFormControlRHF_Deprecated';
import { AppSingleSelect2HeadlessUIRHF } from 'src/lib/form/components/AppSingleSelect/AppSingleSelect2HeadlessUIRHF';
import { DiveCenterProBadge } from 'src/pages/_components/company';
import { useAccommodationsOptions } from 'src/pages/_components/options';
import { AppInputDatePickerMaterialRHF } from 'src/stories/components/04-form';
import { AccommodationLabel } from '../../../components/BookingLabelsComponents';
import { BookingInitialPlanEditFormModel } from '../model';

export type ClubSettingsEquipmentEditPageFormModelOnClickAction =
  | 'move-down'
  | 'move-up'
  | 'delete'
  | 'create';

export const BookingJourneyDetailsStepsFormStep = ({
  className,
  form,
  fieldArray,
  fieldIndex,
  onClick,
  enableAddButton,
  enableDeleteButton,
}: {
  className?: string;
  form: UseFormReturn<BookingInitialPlanEditFormModel>;
  fieldArray: UseFieldArrayReturn<
    BookingInitialPlanEditFormModel,
    'bookingJourney.steps',
    '_id'
  >;
  fieldIndex: number;
  onClick: (
    action: ClubSettingsEquipmentEditPageFormModelOnClickAction,
  ) => void;
  enableAddButton: boolean;
  enableDeleteButton: boolean;
}) => {
  const {
    register,
    handleSubmit,
    watch,
    formState,
    control,
    setValue,
    getValues,
  } = form;

  const clubResume = useClubResume();
  const diveCenterResume = useDiveCenterResume();
  const clubReference = clubResume.reference;
  const customerSettings = clubResume.clubSettings?.customer;
  const generalSettings = clubResume.clubSettings?.general;

  const formAttributeBaseName = `bookingJourney.steps.${fieldIndex}` as const;

  const [
    pickupActive,
    diveCenterId,
    accommodationId,
    arrivalDate,
    departureDate,
    isSharedBooking,
  ] = useWatch({
    control,
    name: [
      `${formAttributeBaseName}.pickup.active`,
      `${formAttributeBaseName}.diveCenterId`,
      `${formAttributeBaseName}.accommodationId`,
      `${formAttributeBaseName}.arrival.date`,
      `${formAttributeBaseName}.departure.date`,
      'isSharedBooking',
    ],
  });

  const isMoveDownAllowed =
    fieldArray.fields.length > 1 && fieldIndex + 1 < fieldArray.fields.length;
  const isMoveUpAllowed = fieldArray.fields.length > 1 && fieldIndex !== 0;

  const accommodation = useMemo(
    () =>
      accommodationId
        ? clubResume.accommodations.find((x) => x._id === accommodationId)
        : undefined,
    [clubResume.accommodations, accommodationId],
  );
  const accommodationsOptions = useAccommodationsOptions({ diveCenterId });

  const isMultiDiveCenters = clubResume.diveCenters.length > 1;
  const isFocusDiveCenter = diveCenterId === diveCenterResume._id;
  const stepDiveCenter = useMemo(
    () => clubResume.diveCenters.find((dc) => dc._id === diveCenterId),
    [clubResume.diveCenters, diveCenterId],
  );

  const az = useAppSecurityUserClubAuthorizations();
  const isMultiCenterEditionAllowed = az?.multiDiveCenters?.edit?.participant;
  const isEditionAllowed = isMultiCenterEditionAllowed || isFocusDiveCenter;

  const diveCentersOptions: ValueLabel<string, React.ReactNode>[] = useMemo(
    () =>
      (clubResume?.diveCenters ?? []).map((dc) => ({
        value: dc._id,
        label: <DiveCenterProBadge diveCenter={dc} />,
      })),
    [clubResume?.diveCenters],
  );
  return (
    <div
      className={clsx(
        'w-full flex gap-2 items-start flex-row gap-x-4',
        className,
      )}
    >
      {fieldArray.fields.length > 1 && (
        <div
          className={clsx('h-full flex md:flex-col md:justify-center gap-2')}
        >
          <Tippy
            delay={[1000, 100]}
            placement="top"
            content={'Déplacer au dessus'}
          >
            <AppIconsAction.up
              className={clsx(
                'p-1 w-8 h-8 bg-gray-200 rounded-full text-white',
                isMoveUpAllowed
                  ? 'hover:bg-gray-600 cursor-pointer'
                  : 'opacity-50 cursor-not-allowed',
              )}
              onClick={() => isMoveUpAllowed && onClick('move-up')}
            />
          </Tippy>
          <Tippy
            delay={[1000, 100]}
            placement="top"
            content={'Déplacer en dessous'}
          >
            <AppIconsAction.down
              className={clsx(
                'p-1 w-8 h-8 bg-gray-200 rounded-full text-white',
                isMoveDownAllowed
                  ? 'hover:bg-gray-600 cursor-pointer'
                  : 'opacity-50 cursor-not-allowed',
              )}
              onClick={() => isMoveDownAllowed && onClick('move-down')}
            />
          </Tippy>
        </div>
      )}
      <div className="w-full flex flex-col gap-2 items-start xs:flex-row xs:gap-x-4 xs:items-center xs:flex-wrap">
        {isMultiDiveCenters && (
          <>
            {isMultiCenterEditionAllowed && isSharedBooking ? (
              <AppFormControlRHF_Deprecated
                className="w-24"
                label={'Centre'}
                control={control}
                name={`${formAttributeBaseName}.diveCenterId`}
                required={true}
                renderComponent={(props) => (
                  <AppSingleSelect2HeadlessUIRHF
                    {...props}
                    disableClearButton={false}
                    theme={'material-ui'}
                    options={diveCentersOptions}
                    onChange={() => {
                      setValue(
                        `${formAttributeBaseName}.accommodationId`,
                        null,
                      );
                      setValue(`${formAttributeBaseName}.pickup.active`, false);
                    }}
                  />
                )}
              />
            ) : (
              <div className="flex-no-shrink">
                <AppFormLabel label="Centre" />
                <DiveCenterProBadge
                  className="w-24 overflow-hidden my-px"
                  diveCenter={stepDiveCenter}
                />
              </div>
            )}
          </>
        )}

        <div className="w-full sm:w-auto grid grid-cols-2 sm:flex gap-2">
          {!isEditionAllowed ? (
            <>
              <div className="w-full sm:w-40">
                <AppFormLabel label="Date d'arrivée" />
                {arrivalDate ? (
                  <div className="text-sm text-status-info font-medium ">
                    {dateService.formatUTC(arrivalDate, 'DD/MM/YYYY')}
                  </div>
                ) : (
                  <div className="text-sm text-status-info font-medium sm:ml-8">
                    ----
                  </div>
                )}
              </div>
              <div className="w-full sm:w-40">
                <AppFormLabel label="Date de départ" />
                {departureDate ? (
                  <div className="text-sm text-status-info font-medium ">
                    {dateService.formatUTC(departureDate, 'DD/MM/YYYY')}
                  </div>
                ) : (
                  <div className="text-sm text-status-info font-medium sm:ml-8">
                    ----
                  </div>
                )}
              </div>
            </>
          ) : (
            <>
              <AppFormControlRHF_Deprecated
                className={'w-full sm:w-40'}
                label={'Date d\'arrivée'}
                control={control}
                name={`${formAttributeBaseName}.arrival.date`}
                renderComponent={(props) => (
                  <AppInputDatePickerMaterialRHF
                    className="w-full"
                    {...props}
                    onChange={(arrivalDate) => {
                      if (!departureDate) {
                        setValue(
                          `${formAttributeBaseName}.departure.date`,
                          arrivalDate,
                        );
                      }
                    }}
                  />
                )}
              />
              <AppFormControlRHF_Deprecated
                className={'w-full sm:w-40'}
                label={'Date de départ'}
                control={control}
                name={`${formAttributeBaseName}.departure.date`}
                renderComponent={(props) => (
                  <AppInputDatePickerMaterialRHF
                    className="w-full"
                    {...props}
                    minDate={arrivalDate}
                  />
                )}
              />
            </>
          )}
        </div>

        {isEditionAllowed ? (
          <div className="w-full sm:w-auto flex gap-2 justify-between sm:justify-start">
            {generalSettings?.accommodations?.enabled &&
              customerSettings?.general?.accomodation && (
                <AppFormControlRHF_Deprecated
                  className={'w-full sm:w-40'}
                  label="Hébergement"
                  control={control}
                  name={`${formAttributeBaseName}.accommodationId`}
                  renderComponent={(props) => (
                    <AppSingleAutocomplete2RHF
                      className={'w-full'}
                      options={accommodationsOptions}
                      {...props}
                    />
                  )}
                />
              )}

            <div
              className={clsx('flex justify-between items-end gap-2 md:gap-6')}
            >
              <AppFormControlRHF_Deprecated
                className={clsx(
                  customerSettings?.general?.pickup &&
                    accommodation?.pickup?.enabled
                    ? ''
                    : 'invisible',
                )}
                label="Pickup"
                control={control}
                name={`${formAttributeBaseName}.pickup.active`}
                renderComponent={(props) => (
                  <AppInputBooleanSwitchRHF {...props} />
                )}
              />
            </div>
          </div>
        ) : (
          <div className="w-full sm:w-auto grid grid-cols-2 sm:flex gap-2">
            <div className="w-full sm:w-40">
              <AppFormLabel label="Hébergement" />
              {accommodationId ? (
                <div className="text-sm text-status-info font-medium ">
                  <AccommodationLabel
                    accommodation={accommodation}
                    pickupActive={pickupActive}
                  />
                </div>
              ) : (
                <div className="text-sm text-status-info font-medium sm:ml-8">
                  ----
                </div>
              )}
            </div>
            {customerSettings?.general?.pickup &&
              accommodation?.pickup?.enabled && (
                <div>
                  <AppFormLabel label="Pickup" />
                  <div className="sm:text-center">
                    {pickupActive ? (
                      <AppHeroIcons.check className="inline-block w-5 h-5 text-status-success" />
                    ) : (
                      <AppHeroIcons.cross className="inline-block w-5 h-5 text-gray-400" />
                    )}
                  </div>
                </div>
              )}
          </div>
        )}

        <div className="flex-grow"></div>
        {enableAddButton && (
          <div className="w-full sm:w-auto flex-no-shrink flex gap-2">
            <Tippy
              delay={[1000, 100]}
              placement="top"
              content={'Ajouter une étape au séjour'}
            >
              <AppIconsAction.add
                className="p-1 w-8 h-8 bg-app-primary-light hover:bg-app-primary-dark rounded-full text-white cursor-pointer"
                onClick={() => {
                  onClick('create');
                }}
              />
            </Tippy>
            <Tippy delay={[1000, 100]} placement="top" content={'Supprimer'}>
              <AppIconsAction.delete
                className={clsx(
                  'p-1 w-8 h-8 bg-red-200 hover:bg-red-600 rounded-full text-white cursor-pointer',
                  isEditionAllowed && enableDeleteButton ? '' : 'invisible',
                )}
                onClick={() => {
                  onClick('delete');
                }}
              />
            </Tippy>
          </div>
        )}
      </div>
    </div>
  );
};
