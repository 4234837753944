/* eslint-disable @typescript-eslint/no-unused-vars */
import clsx from 'clsx';
import React from 'react';
import { useWatch } from 'react-hook-form';
import { useClubResume } from 'src/business/club/data/hooks';
import { ClubSettingsInquiryEditPagePanelLocalState } from '../ClubSettingsInquiryEditPagePanel/useClubSettingsInquiryEditPagePanelLocalState.hook';
import { ClubSettingsInquiryEditPageForm_Header } from './ClubSettingsInquiryEditPageForm_Header';
import { ClubSettingsInquiryEditPageForm_TabActivationSettings } from './ClubSettingsInquiryEditPageForm_TabActivationSettings';
import { ClubSettingsInquiryEditPageForm_TabMainSettings } from './ClubSettingsInquiryEditPageForm_TabMainSettings';
import { ClubSettingsInquiryEditPageForm_TabPreview } from './ClubSettingsInquiryEditPageForm_TabPreview/ClubSettingsInquiryEditPageForm_TabPreview';
import { ClubSettingsInquiryEditPageForm_TabSections } from './ClubSettingsInquiryEditPageForm_TabSections';
import { ClubSettingsInquiryEditPageForm_TabTitleSettings } from './ClubSettingsInquiryEditPageForm_TabTitleSettings';
import { ClubSettingsInquiryEditPageForm_TabResponsesProcessing } from './ClubSettingsInquiryEditPageForm_TabResponsesProcessing';

export const ClubSettingsInquiryEditPageForm = ({
  localState,
  className,
}: {
  localState: ClubSettingsInquiryEditPagePanelLocalState;
  className?: string;
}) => {
  const { state, data } = localState;

  const form = data.form;

  const clubResume = useClubResume();
  const isMultiDiveCenters = clubResume.diveCenters.length > 1;

  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const [inquiryType, inquiryName] = useWatch({
    control,
    name: ['type', 'name'],
  });

  return (
    <div className={clsx('grid gap-y-4', className)}>
      <ClubSettingsInquiryEditPageForm_Header
        localState={localState}
        form={form}
      />
      {state.currentTabId === 'questions' && (
        <>
          {state.mode === 'create' && (
            <ClubSettingsInquiryEditPageForm_TabTitleSettings
              localState={localState}
              form={form}
            />
          )}
          {inquiryType && (
            <ClubSettingsInquiryEditPageForm_TabSections
              localState={localState}
              form={form}
            />
          )}
        </>
      )}
      {state.currentTabId === 'main-settings' && (
        <>
          <ClubSettingsInquiryEditPageForm_TabTitleSettings
            localState={localState}
            form={form}
          />
          <ClubSettingsInquiryEditPageForm_TabMainSettings
            localState={localState}
            form={form}
          />
        </>
      )}
      {state.currentTabId === 'activation-settings' && (
        <>
          <ClubSettingsInquiryEditPageForm_TabActivationSettings
            localState={localState}
            form={form}
          />
        </>
      )}{' '}
      {state.currentTabId === 'response-processing' && (
        <>
          <ClubSettingsInquiryEditPageForm_TabResponsesProcessing
            localState={localState}
            form={form}
          />
        </>
      )}{' '}
      {state.currentTabId === 'preview' && (
        <>
          <ClubSettingsInquiryEditPageForm_TabPreview
            localState={localState}
            form={form}
          />
        </>
      )}
    </div>
  );
};
