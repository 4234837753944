/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ClubMassMailing_FetchPreviewCriteria,
  ClubMassMailingSearchCriteria,
  ClubMassMailingSearchCriteria_ParticipantPeriod,
  DIVE_MODES_SCUBA,
} from '@mabadive/app-common-model';
import { dateService } from '@mabadive/app-common-services';
import { useCallback, useMemo } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useClubResume } from 'src/business/club/data/hooks';
import { LoadableContentState } from '../../../business/_core/data/app-loading';
import { AppTitleDateNavigatorModel } from '../../_components/title-navigators/model';
import { useClubMassMailing_FetchPreview } from './useClubMassMailing_FetchPreview.hook';

export function useClubMassMailingPageLocalState() {
  const clubResume = useClubResume();

  const initialValue: ClubMassMailingSearchCriteria = useMemo(() => {
    const participantPeriod: ClubMassMailingSearchCriteria_ParticipantPeriod =
      'last-1-year';
    const { minParticipantDate, maxParticipantDate } =
      buildDatesFromParticipantPeriod(participantPeriod);

    const searchCriteria: ClubMassMailingSearchCriteria = {
      participantPeriod,
      minParticipantDate,
      maxParticipantDate,
      diveModes: DIVE_MODES_SCUBA,
      mainCertificationReferences: [],
      gazCertificationReferences: [],
    };

    return searchCriteria;
  }, []);

  const form = useForm<ClubMassMailingSearchCriteria>({
    defaultValues: initialValue,
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const { control } = form;

  const onUpdateSelectedDate = useCallback(
    (selectedDate: Date) => {
      form.setValue('minParticipantDate', selectedDate, {
        shouldValidate: true,
      });
      form.setValue('participantPeriod', 'manual-mode', {
        shouldValidate: true,
      });
    },
    [form],
  );

  const onUpdateEndDate = useCallback(
    (endDate: Date) => {
      form.setValue('maxParticipantDate', endDate, {
        shouldValidate: true,
      });
      form.setValue('participantPeriod', 'manual-mode', {
        shouldValidate: true,
      });
    },
    [form],
  );

  const onUpdateParticipantPeriod = useCallback(
    (participantPeriod: ClubMassMailingSearchCriteria_ParticipantPeriod) => {
      if (participantPeriod !== 'manual-mode') {
        const { minParticipantDate, maxParticipantDate } =
          buildDatesFromParticipantPeriod(participantPeriod);

        form.setValue('participantPeriod', participantPeriod, {
          shouldValidate: true,
        });
        form.setValue('minParticipantDate', minParticipantDate, {
          shouldValidate: true,
        });
        form.setValue('maxParticipantDate', maxParticipantDate, {
          shouldValidate: true,
        });
      }
    },
    [form],
  );

  const [minParticipantDate, maxParticipantDate] = useWatch({
    control,
    name: ['minParticipantDate', 'maxParticipantDate'],
  });

  const datesModel: Pick<AppTitleDateNavigatorModel, 'isNow' | 'value'> =
    useMemo(
      () => ({
        isNow: false,
        value: {
          selectedDate: minParticipantDate,
          beginDate: minParticipantDate,
          endDate: maxParticipantDate,
        },
      }),
      [maxParticipantDate, minParticipantDate],
    );

  const formValue = useWatch({
    control,
  }) as ClubMassMailingSearchCriteria;

  const fetchCriteria: ClubMassMailing_FetchPreviewCriteria = useMemo(() => {
    const criteria: ClubMassMailing_FetchPreviewCriteria = {
      search: formValue,
      format: 'txt',
    };
    return criteria;
  }, [formValue]);

  const {
    data: previewResults,
    loadingInProgress,
    refetch,
  } = useClubMassMailing_FetchPreview(fetchCriteria);

  const contentState: LoadableContentState = previewResults ? 'full' : 'none';

  return {
    state: {
      clubResume,
      datesModel,
      form,
      previewResults,
      loadingInProgress,
      contentState,
      fetchCriteria,
    },
    actions: {
      onUpdateEndDate,
      onUpdateSelectedDate,
      onUpdateParticipantPeriod,
    },
  };
}

export type ClubMassMailingPageLocalState = ReturnType<
  typeof useClubMassMailingPageLocalState
>;
function buildDatesFromParticipantPeriod(
  participantPeriod: ClubMassMailingSearchCriteria_ParticipantPeriod,
) {
  const maxParticipantDate = dateService.getUTCDateSetTime(new Date());

  // Calcul de la date minimum en fonction de la période spécifiée
  let minParticipantDate;
  switch (participantPeriod) {
    case 'last-1-week':
      minParticipantDate = dateService.add(maxParticipantDate, -1, 'week');
      break;
    case 'last-1-month':
      minParticipantDate = dateService.add(maxParticipantDate, -1, 'month');
      break;
    case 'last-3-months':
      minParticipantDate = dateService.add(maxParticipantDate, -3, 'month');
      break;
    case 'last-6-months':
      minParticipantDate = dateService.add(maxParticipantDate, -6, 'month');
      break;
    case 'last-1-year':
      minParticipantDate = dateService.add(maxParticipantDate, -12, 'month');
      break;
    case 'last-2-years':
      minParticipantDate = dateService.add(maxParticipantDate, -24, 'month');
      break;
    case 'manual-mode':
      minParticipantDate = new Date(maxParticipantDate);
      break;
    default:
      throw new Error(`Période non gérée : ${participantPeriod}`);
  }

  return { minParticipantDate, maxParticipantDate };
}
