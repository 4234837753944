/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  EcommerceCategoryGql_Company,
  EcommerceProductGql_Company,
} from '@mabadive/app-common-model';
import { useCallback, useMemo } from 'react';
import { useRedirect } from 'src/business/_core/data/hooks';
import { useClubSettings } from 'src/business/club/data/hooks';
import {
  UseClubDialogsProps,
  useClubDialogsActionsPersist,
} from 'src/pages/_dialogs';
import { AppTabTailwind } from '../../../../../../../business/_core/modules/layout';
import { ClubSettingsOnlineBookingProductsPageLocalState } from '../../useClubSettingsOnlineBookingProductsPageLocalState.hook';

export type ProProductsPage_CategoryContentCard_TabId =
  | 'content'
  | 'products'
  | 'articles';

export function useProProductsPage_CategoryViewPanelLocalState({
  parentState,
}: {
  parentState: ClubSettingsOnlineBookingProductsPageLocalState;
}) {
  const actionsPersist: UseClubDialogsProps = useClubDialogsActionsPersist();
  const clubSettings = useClubSettings();
  const redirectTo = useRedirect();

  const enableCatalog =
    clubSettings?.publicSettings?.customerSpace?.onlineBooking?.enableCatalog;

  const { state, dialogs, data, actions } = parentState;

  const categoryId = state.viewState?.categoryId;

  const category: EcommerceCategoryGql_Company = useMemo(() => {
    return data.categories.find((x) => x._id === categoryId);
  }, [categoryId, data.categories]);
  const categoryBooklet = useMemo(() => {
    return data.booklets.find((x) => x._id === category.bookletId);
  }, [category.bookletId, data.booklets]);

  const categoryBookletPage = useMemo(() => {
    return data.bookletPages.find((x) => x._id === categoryBooklet?.rootPageId);
  }, [categoryBooklet?.rootPageId, data.bookletPages]);
  const categoryConfigs = useMemo(() => {
    return data.productCategoryConfigs.filter(
      (cf) => cf.categoryId === categoryId,
    );
  }, [categoryId, data.productCategoryConfigs]);

  const products: EcommerceProductGql_Company[] = useMemo(() => {
    return data.products.filter((p) =>
      categoryConfigs.some((cf) => cf.productId === p._id),
    );
  }, [categoryConfigs, data.products]);

  const nextIndex = useMemo(() => {
    if (products.length > 0) {
      return Math.max(...categoryConfigs.map((p) => p.sortIndex)) + 1;
    }
    return 1;
  }, [categoryConfigs, products.length]);

  const customerSpaceCurrentPageURL = state.customerSpaceCurrentPageURL;
  // const [tabId, setTabId] =
  //   useState<ProProductsPage_CategoryContentCard_TabId>('products');
  const tabId =
    parentState.state.viewState.modeCategoryView?.tabId ?? 'products';
  const setTabId = useCallback(
    (tabId: ProProductsPage_CategoryContentCard_TabId) => {
      parentState.actions.selectCategoryViewTab(tabId);
    },
    [parentState.actions],
  );

  const tabs = useMemo(() => {
    const tabs: AppTabTailwind<ProProductsPage_CategoryContentCard_TabId>[] =
      [];
    tabs.push({
      id: 'products',
      label: 'Produits',
    });
    if (products.length > 0) {
      tabs.push({
        id: 'articles',
        label: 'Tarifs',
      });
    }
    tabs.push({
      id: 'content',
      label: 'Fiche descriptive',
    });

    return tabs;
  }, [products.length]);

  return {
    parentState,
    state: {
      ...parentState?.state,
      enableCatalog,
      categoryId,
      nextIndex,
      customerSpaceCurrentPageURL,
      tabId,
      setTabId,
      tabs,
    },
    data: {
      ...parentState.data,
      category,
      categoryBooklet,
      categoryBookletPage,
      categoryConfigs,
      products,
    },
    dialogs: {
      ...parentState?.dialogs,
    },
    actions: {
      ...parentState?.actions,
    },
  };
}

export type ProProductsPage_CategoryViewPanelLocalState = ReturnType<
  typeof useProProductsPage_CategoryViewPanelLocalState
>;
