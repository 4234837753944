import { Disclosure } from '@headlessui/react';
import clsx from 'clsx';
import React from 'react';
import { useAutoFocus } from 'src/business/_core/modules/layout';
import {
  AppHeroIcons,
  AppIcons,
} from 'src/business/_core/modules/layout/icons';
import { ClubPurchasesListPageLocalState } from '../useClubPurchasesListPageLocalState.hook';
import { ClubPurchasesListPageConfigBarExtraFilters } from './ClubPurchasesListPageConfigBarExtraFilters';
import { ClubPurchasesListPageConfigBarFiltersResume } from './ClubPurchasesListPageConfigBarFiltersResume';

export const ClubPurchasesListPageConfigBar = ({
  localState,
}: {
  localState: ClubPurchasesListPageLocalState;
}) => {
  const autoFocus = useAutoFocus();

  const { state, actions } = localState;
  const { viewParameters } = state;

  return (
    <div className="mx-auto w-full ">
      <Disclosure
        as="div"
        className="app-card-no-padding pb-2 xs:px-2"
        defaultOpen={state.hasAnyExtraViewParameter}
      >
        {({ open }) => (
          <>
            <div className="flex gap-2 sm:gap-4 w-full justify-start items-center">
              <Disclosure.Button
                as={'div'}
                className={clsx(
                  'cursor-pointer focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75 ',
                  'focus:outline-none px-1 py-1',
                )}
              >
                <div className="flex gap-2 sm:gap-4 w-full justify-start items-center">
                  <div
                    className={clsx(
                      'py-0.5 px-1 focus:outline-none',
                      'border border-gray-300 hover:border-gray-400 rounded',
                      'bg-white hover:bg-gray-100 text-gray-800 hover:text-gray-900',
                    )}
                  >
                    <AppHeroIcons.actionSettingsDisplay
                      className={`${
                        open ? 'rotate-180 transform' : ''
                      } h-6 w-6 m-1 text-gray-500 `}
                    />
                  </div>
                  <h2 className="text-left text-lg leading-6 font-medium text-gray-800 uppercase">
                    Ventes
                  </h2>
                </div>
              </Disclosure.Button>
              <div
                className="hidden ml-4 xs:flex items-center gap-2 flex-grow cursor-default"
                onClick={(e) => e.stopPropagation()}
              >
                <input
                  className="mx-1 w-full hover:border-app-blue rounded px-2"
                  autoFocus={autoFocus}
                  autoComplete="new-password"
                  placeholder="Recherche par nom, prénom"
                  type="text"
                  value={viewParameters.searchTextContactName}
                  onChange={(e) => {
                    actions.setSearchTextContactName(e.target.value);
                  }}
                />
                <input
                  className="hidden md:flex mx-1 w-full hover:border-app-blue rounded px-2"
                  autoFocus={autoFocus}
                  autoComplete="new-password"
                  placeholder="Recherche par prestation"
                  type="text"
                  value={viewParameters.searchTextOfferName}
                  onChange={(e) => {
                    actions.setSearchTextOfferName(e.target.value);
                  }}
                />
                <button
                  type="button"
                  className={clsx(
                    'size-14 flex items-center',
                    state.hasAnyParameter
                      ? 'text-red-400 hover:text-red-800'
                      : 'invisible',
                  )}
                  onClick={() => {
                    actions.resetFilters();
                  }}
                >
                  <AppIcons.action.cancel className="h-6 w-6" />
                </button>
              </div>
              {!open && (
                <ClubPurchasesListPageConfigBarFiltersResume
                  className={clsx('pt-1')}
                  localState={localState}
                />
              )}
            </div>
            <Disclosure.Panel className="pt-2 pr-2 text-sm text-gray-500 ">
              <ClubPurchasesListPageConfigBarExtraFilters
                localState={localState}
              />
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  );
};

//   return (
//     <div className="mx-auto w-full ">
//       <div className="app-card p-4 ">
//         <div className="flex flex-col ms:flex-row gap-2">
//           <div className="m-2 flex gap-2">
//             <input
//               autoFocus={autoFocus}
//               autoComplete="new-password"
//               placeholder="Recherche par nom, prénom..."
//               type="text"
//               className="flex-grow w-max hover:border-app-blue rounded px-2"
//               defaultValue={searchTextContactName}
//               onChange={(e) => {
//                 setSearchTextContactName(e.target.value);
//               }}
//             />
//             <input
//               autoFocus={autoFocus}
//               autoComplete="new-password"
//               placeholder="Recherche par prestation"
//               type="text"
//               className="flex-grow w-max hover:border-app-blue rounded px-2"
//               defaultValue={searchTextOfferName}
//               onChange={(e) => {
//                 setSearchTextOfferName(e.target.value);
//               }}
//             />
//           </div>
//         </div>
//         <div className="m-2 flex gap-4">
//           <AppSingleSelect2HeadlessUI
//             className="w-40"
//             theme={'tailwind'}
//             disableClearButton={true}
//             value={validityStatus}
//             options={validityStatusOptions}
//             onChange={(value) => setValidityStatus(value)}
//           />
//           <AppSingleSelect2HeadlessUI
//             className="w-40"
//             theme={'tailwind'}
//             disableClearButton={true}
//             value={paymentStatus}
//             options={paymentStatusOptions}
//             onChange={(value) => setPaymentStatus(value)}
//           />
//         </div>
//       </div>
//     </div>
//   );
// };
