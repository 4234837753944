import {
  changeDescriptorManager,
  diverBookingCreator,
  diverBookingMemberCreator,
} from '@mabadive/app-common-services';
import { Dispatch, SetStateAction, useCallback } from 'react';
import { useDiveCenterResume } from 'src/business/club/data/hooks';
import { BookingGroupAndJourneyEditDialogInputState } from '../../../../CreateBookingPages';
import {
  DiverBookingPageLoadedContentFocus,
  DiverBookingPageUpdateState,
} from '../../../models';
import { DiverBookingPageSetUpdateStateFn } from '../../../useDiverBookingPageGlobalState.hook';
import { diverBookingPageUpdateStateManager } from '../../02.update-state';

export function useDiverBookingPageCreateBooking({
  clubReference,
  diveCenterId,
  diverId,
  focus,
  updateState,
  setUpdateState,
  setBookingGroupAndJourneyEditDialogState,
  openBooking,
}: {
  clubReference: string;
  diveCenterId: string;
  diverId: string;
  focus: DiverBookingPageLoadedContentFocus;
  updateState: DiverBookingPageUpdateState;
  setUpdateState: DiverBookingPageSetUpdateStateFn;
  setBookingGroupAndJourneyEditDialogState: Dispatch<
    SetStateAction<BookingGroupAndJourneyEditDialogInputState>
  >;
  openBooking: (bookingId: string) => void;
}) {
  const diveCenterResume = useDiveCenterResume();

  return useCallback(
    (opts: { onSuccess?: 'openEditBookingDetailsDialog' | 'openBooking' }) => {
      let updateStateLocal = updateState;

      const newBooking = diverBookingCreator.createBooking({
        clubReference,
        diveCenterId,
        bookingContactDiverId: diverId,
        publicSettings:
          diveCenterResume?.clubResume.clubSettings.publicSettings,
      });
      const bookingsChanges = changeDescriptorManager.createOne(newBooking, {
        changeDescriptors: updateStateLocal.bookingsChanges,
      });
      updateStateLocal = {
        ...updateStateLocal,
        bookingsChanges,
      };
      const newBookingMember =
        diverBookingMemberCreator.createBookingMemberFromDiver({
          diver: focus.clubDiver,
          bookingId: newBooking._id,
          clubReference,
          diveCenterId,
        });
      updateStateLocal =
        diverBookingPageUpdateStateManager.addNewBookingMemberToState({
          updateState: updateStateLocal,
          newBookingMember,
          diver: focus.clubDiver,
        });

      setUpdateState(updateStateLocal, {
        action: 'DiverBookingPageCreateBooking',
      });

      if (opts?.onSuccess === 'openEditBookingDetailsDialog') {
        setBookingGroupAndJourneyEditDialogState({
          isOpen: true,
          booking: newBooking,
          bookingMembersFull: [],
        });
      } else if (opts?.onSuccess === 'openBooking') {
        openBooking(newBooking._id);
      }

      return {
        updateState: updateStateLocal,
        booking: newBooking,
        bookingMembers: newBookingMember,
      };
    },
    [
      clubReference,
      diveCenterId,
      diveCenterResume?.clubResume.clubSettings.publicSettings,
      diverId,
      focus.clubDiver,
      openBooking,
      setBookingGroupAndJourneyEditDialogState,
      setUpdateState,
      updateState,
    ],
  );
}
