import {
  DIVE_MOVE_GROUPS_FULL,
  DiveModeGroup,
} from '@mabadive/app-common-model';
import { useMemo } from 'react';
import { useClubResume } from 'src/business/club/data/hooks/useClubResume.hook';
import { useDiveModes } from './useDiveModes.hook';

export function useDiveModeGroups({
  currentDiveModeGroup,
}: {
  currentDiveModeGroup?: DiveModeGroup;
}): DiveModeGroup[] {
  const clubResume = useClubResume();
  const clubSettings = clubResume.clubSettings;
  const diveModes = useDiveModes({});
  return useMemo(
    () =>
      DIVE_MOVE_GROUPS_FULL.filter(
        (dmg) =>
          dmg.diveModeGroup === currentDiveModeGroup ||
          dmg.diveModeGroup === 'store' || // TODO: désactiver si la boutique est OFF
          dmg.diveModes.some((dm) => diveModes.includes(dm)),
      ).map((x) => x.diveModeGroup),
    [currentDiveModeGroup, diveModes],
  );
}
