/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ClubResumeStaffMember,
  ClubSettings,
  DiveCenterDailyConfig,
  DiveMode,
  DiveSession,
  DiveSessionResumeGroup,
  DiveSessionResumeParticipant,
  DiveSessionStaffMemberTableModelStaffMember,
  DiveSessionTimeDayPeriod,
} from '@mabadive/app-common-model';
import {
  clubDiveSessionThemeBuilder,
  diveSessionGroupBuilder,
  diveSessionMultipleBuilder,
  diveSessionStaffMembersTableModelBuilder,
} from '@mabadive/app-common-services';
import {
  DiveSessionParticipantsStats,
  DiveSessionParticipantsStatsStaff,
} from 'src/business/club/modules/club-dive-session/model';
import {
  diveSessionRealGroupsStatsBuilder,
  diveSessionVirtualGroupsStatsBuilder,
} from './stats';

export const diveSessionParticipantStatsBuilder = {
  buildDiveSessionParticipantsStats,
};

const DIVERS_DIVE_MODES: DiveMode[] = [
  'autonomous',
  'first-dive',
  'training',
  'supervised',
];

export function buildDiveSessionParticipantsStats({
  clubReference,
  participants,
  groups,
  session,
  staffMembers,
  dailyConfigs,
  clubSettings,
}: {
  clubReference: string;
  participants: DiveSessionResumeParticipant[];
  groups: DiveSessionResumeGroup[];
  session: Pick<DiveSession, 'reference' | 'time' | 'diveTourSession2'>;
  staffMembers: ClubResumeStaffMember[];
  dailyConfigs: DiveCenterDailyConfig[];
  clubSettings: Pick<ClubSettings, 'ui'>;
}): DiveSessionParticipantsStats {
  const activeParticipants = participants.filter(
    (p) => p.bookingState.value !== 'cancelled',
  );

  // TODO: optimiser ce traitement, on n'a pas besoin de tout!!!

  const isMultiSession = diveSessionMultipleBuilder.isMultiSession(session);

  const isMultiSessionConfigForStaff =
    diveSessionMultipleBuilder.isMultiSessionConfigForStaff(
      session.diveTourSession2,
    );
  const now = session.time;

  const staffMembersFull =
    diveSessionStaffMembersTableModelBuilder.buildStaffMembersFull({
      staffMembers,
      filterMode: 'active-today',
      clubParticipants: participants,
      diveSession: session,
      groups: groups,
      staffFirstNameBefore: true,
      dailyConfigs,
      clubSettings,
    });

  const staff: DiveSessionParticipantsStatsStaff = buildStaffStats({
    staffMembersFull,
    now,
  });

  const realGroups =
    activeParticipants.find((p) => !!p.diveSessionGroupId) !== undefined;

  const timeDayPeriod: DiveSessionTimeDayPeriod =
    clubDiveSessionThemeBuilder.buildTimeDayPeriod(session.time);

  const participantsStats: DiveSessionParticipantsStats = {
    total: activeParticipants.length,
    observer: 0,
    firstDive: 0,
    snorkeling: 0,
    snorkelingSupervised: 0,
    watchingTour: 0,
    training: 0,
    theoreticalTraining: 0,
    autonomous: 0,
    autoSupervised: 0,
    supervised: 0,
    freeDive: 0,
    unknown: 0,
    instructor: 0,
    supervisorsAll: 0,
    realGroups,
    groups: undefined,
    sessionsCount: session.diveTourSession2 ? 2 : 1,
    timeDayPeriod,
    staff,
  };

  activeParticipants.reduce((stats, participant) => {
    switch (participant.diveMode) {
      case 'first-dive': {
        participantsStats.firstDive++;
        break;
      }
      case 'observer': {
        participantsStats.observer++;
        break;
      }
      case 'snorkeling': {
        participantsStats.snorkeling++;
        break;
      }
      case 'snorkelingSupervised': {
        participantsStats.snorkelingSupervised++;
        break;
      }
      case 'watchingTour': {
        participantsStats.watchingTour++;
        break;
      }
      case 'training': {
        participantsStats.training++;
        break;
      }
      case 'theoretical-training': {
        participantsStats.theoreticalTraining++;
        break;
      }
      case 'autonomous': {
        participantsStats.autonomous++;
        break;
      }
      case 'autoSupervised': {
        participantsStats.autoSupervised++;
        break;
      }
      case 'supervised': {
        participantsStats.supervised++;
        break;
      }
      case 'free-dive':
      case 'free-dive-auto':
      case 'free-dive-training': {
        participantsStats.freeDive++;
        break;
      }
      case 'unknown': {
        participantsStats.unknown++;
        break;
      }
      case 'instructor': {
        participantsStats.instructor++;
        break;
      }
    }

    return stats;
  }, participantsStats);

  const activeDivers = activeParticipants.filter((participant) =>
    DIVERS_DIVE_MODES.includes(participant.diveMode),
  );

  const assignedFreeInstructors: ClubResumeStaffMember[] =
    diveSessionGroupBuilder.buildAssignedFreeInstructors({
      staffMembers,
      diveSession: session,
      groups,
    });

  if (realGroups) {
    participantsStats.groups =
      diveSessionRealGroupsStatsBuilder.buildRealGroupsStats({
        activeDivers,
        diveSessionGroups: groups,
        clubReference,
        assignedFreeInstructorsCount: assignedFreeInstructors.length,
      });

    // participantsStats.supervisorsAll =
    //   staffMemberSessionBuilder.getStaffIdsInSession({
    //     diveSession: session,
    //     diveSessionGroups: groups,
    //     filteredStaffIds,
    //   }).length;
  } else {
    participantsStats.groups =
      diveSessionVirtualGroupsStatsBuilder.buildVirtualGroupsStats({
        activeDivers,
        autonomousCount: participantsStats.autonomous,
        clubReference,
        assignedFreeInstructorsCount: assignedFreeInstructors.length,
      });

    // participantsStats.supervisorsAll =
    //   staffMemberSessionBuilder.getStaffIdsInSession({
    //     diveSession: session,
    //     diveSessionGroups: [],
    //     filteredStaffIds,
    //   }).length;
  }
  const supervisorsAll = staffMembersFull.filter(
    (x) => x.meta.active, // actif aujourd'hui
    // x.meta?.availableRoles.includes('scuba-diving-instructor'), // instructor
    // !x.meta?.activeRoles.includes('surface-security') //
  );

  participantsStats.supervisorsAll = supervisorsAll.length;

  return participantsStats;
}
function buildStaffStats({
  staffMembersFull,
  now,
}: {
  staffMembersFull: DiveSessionStaffMemberTableModelStaffMember[];
  now: Date;
}) {
  const staffMembersFullWithAvailability = staffMembersFull
    // .map((staffMemberFull) => {
    //   const availability =
    //     staffMemberAvailabilityFinder.getDefaultAvailabilityToday(
    //       staffMemberFull.staffMember,
    //       {
    //         now,
    //       },
    //     );
    //   return {
    //     availability,
    //     staffMemberFull,
    //   };
    // })
    .filter(
      (x) =>
        x.sessionAssigmentResume?.isAvailableOnSession ||
        x.sessionAssigmentResume?.isActiveOnSession,
    );

  const staffMembersScubaInstructors = staffMembersFullWithAvailability.filter(
    (x) => x.meta.activeRoles.includes('scuba-diving-instructor'),
  );

  const staffMembersAssignedNonScubaInstructors =
    staffMembersFullWithAvailability.filter(
      (x) =>
        x.meta.active &&
        !x.meta.activeRoles.includes('scuba-diving-instructor'),
    );
  const casuals = staffMembersScubaInstructors.filter((x) => {
    return x?.dailyAvailabilityResume?.jobType !== 'regular';
  });
  const regulars = staffMembersScubaInstructors.filter((x) => {
    return x?.dailyAvailabilityResume?.jobType === 'regular';
  });

  const casualsInUse = casuals.filter((x) => x.meta.active);
  const regularsInUse = regulars.filter((x) => x.meta.active);

  const staff: DiveSessionParticipantsStatsStaff = {
    casualInstructors: {
      assigned: casualsInUse.length,
      remaining: casuals.length - casualsInUse.length,
      total: casuals.length,
    },
    regularInstructors: {
      assigned: regularsInUse.length,
      remaining: regulars.length - regularsInUse.length,
      total: regulars.length,
    },
    otherAssignedTotal: staffMembersAssignedNonScubaInstructors.length,
  };
  return staff;
}
