import { BookingGroupType } from '@mabadive/app-common-model';
import { useMemo } from 'react';

export function useDisplayBookingGroup({
  type,
  label,
  showIndividualWithoutLabel,
  preferLabelOnly,
}: {
  type: BookingGroupType;
  label: string;
  showIndividualWithoutLabel?: boolean;
  preferLabelOnly?: boolean;
}): { isVisible: boolean; displayType: boolean; displayLabel: boolean } {
  return useMemo(() => {
    const hasLabel = label?.trim()?.length > 0;
    if (type === 'individual') {
      if (hasLabel) {
        return {
          isVisible: true,
          displayType: false,
          displayLabel: true,
        };
      } else if (showIndividualWithoutLabel) {
        return {
          isVisible: true,
          displayType: true,
          displayLabel: false,
        };
      } else {
        return {
          isVisible: false,
          displayType: false,
          displayLabel: false,
        };
      }
    }
    const hasLabelAndPreferLabelOnly = preferLabelOnly && hasLabel;

    const displayType = !hasLabelAndPreferLabelOnly;
    const displayLabel = true;

    const isVisible = (displayType && !!type) || (displayLabel && !!hasLabel);
    return {
      displayType,
      displayLabel,
      isVisible,
    };
  }, [label, preferLabelOnly, showIndividualWithoutLabel, type]);
}
