/*----------- Render Prop -----------*/

import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, useRef, useState } from 'react';
import {
  AppPackageExtraCostFormPanel,
  AppPackageExtraCostFormPanelProps,
} from './AppPackageExtraCostFormPanel';
import { AppPackageExtraCostFormDialogDisplayState } from './model';

export const AppPackageExtraCostFormDialog: React.FC<
  AppPackageExtraCostFormPanelProps & {
    open: boolean;
    setOpen: (open: boolean) => void;
    children?: React.ReactNode | React.ReactNode[];
  }
> = ({ children, open, setOpen, ...props }) => {
  // const [referenceElement, setReferenceElement] = useState<any>();
  // const [popperElement, setPopperElement] = useState<any>();
  // const { styles, attributes } = usePopper(referenceElement, popperElement);

  const cancelButtonRef = useRef(null);

  const [displayState, setDisplayState] =
    useState<AppPackageExtraCostFormDialogDisplayState>({
      mode: 'view-list',
    });

  return (
    <>
      {children && children}
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed z-[1201] inset-0"
          open={open}
          onClose={setOpen}
        >
          <div className="flex items-end justify-center min-h-[100dvh] pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-[100dvh]"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="overflow-y-auto h-[100dvh] w-screen max-w-xl xl:max-h-[95vh] bg-white rounded-lg inline-block align-bottom text-left overflow-hidden shadow-xl transform transition-all align-middle p-6">
                <div className="h-full flex flex-col gap-4">
                  <AppPackageExtraCostFormPanel
                    displayState={displayState}
                    setDisplayState={setDisplayState}
                    className="h-full flex-grow overflow-y-auto"
                    {...props}
                    // onSelectDate={(d) => {
                    //   props.onSelectDate && props.onSelectDate(d);
                    //   setOpen(false);
                    // }}
                  />
                  <div className="w-full text-center mt-2 sm:mt-4">
                    {displayState.mode === 'view-list' && (
                      <button
                        type="button"
                        className="inline-flex justify-center w-full sm:w-48 mx-auto rounded-md border border-transparent shadow-sm px-4 py-2 bg-app-primary text-base font-medium text-white hover:bg-app-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-app-primary sm:text-sm"
                        onClick={() => setOpen(false)}
                      >
                        Fermer
                      </button>
                    )}
                    {displayState.mode === 'edit-one' && (
                      <button
                        type="button"
                        className="inline-flex justify-center w-full sm:w-48 mx-auto rounded-md border border-transparent shadow-sm px-4 py-2 bg-app-primary text-base font-medium text-white hover:bg-app-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-app-primary sm:text-sm"
                        onClick={() =>
                          setDisplayState({
                            mode: 'view-list',
                          })
                        }
                      >
                        Ok
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};
