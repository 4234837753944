/* eslint-disable @typescript-eslint/no-unused-vars */
import { AppInquiryForClubResumeGql_Company } from '@mabadive/app-common-model';
import React, { useMemo } from 'react';
import { UseFieldArrayReturn, useWatch } from 'react-hook-form';
import { useClubResume } from 'src/business/club/data/hooks';
import { BookingCustomerConfigEditFormModel } from '../../_model';
import { BookingCustomerConfigEditDialogLocalState } from '../../useBookingCustomerConfigEditDialogLocalState.hook';

export const BookingCustomerConfigEditForm_Inquiries_CheckAll = ({
  localState,
  inquiry,
  inquiryIndex,
  bookingMembersConfigsFieldsArray,
}: {
  localState: BookingCustomerConfigEditDialogLocalState;
  inquiry: AppInquiryForClubResumeGql_Company;
  inquiryIndex: number;
  bookingMembersConfigsFieldsArray: UseFieldArrayReturn<
    BookingCustomerConfigEditFormModel,
    'bookingMembersConfigs',
    'id'
  >;
}) => {
  const { state, data, actions } = localState;
  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    state.form;

  const clubResume = useClubResume();
  const customerSettings = clubResume?.clubSettings?.customer;
  const clubReference = clubResume.reference;

  const formPrefix = 'booking.bookingCustomerConfig' as const;

  const [bookingCustomerConfig] = useWatch({
    control,
    name: ['bookingMembersConfigs' as const],
  });

  const disabledParticipantsCount = useMemo(
    () =>
      bookingCustomerConfig.filter(
        (bookingMembersConfig) =>
          !bookingMembersConfig.config.inquiry.inquiries[inquiryIndex]?.enabled,
      ).length,
    [bookingCustomerConfig, inquiryIndex],
  );

  function enableOrDisableAllParticipants(value: boolean) {
    bookingCustomerConfig.forEach((x, bookingMembersConfigsFieldIndex) => {
      setValue(
        `bookingMembersConfigs.${bookingMembersConfigsFieldIndex}.config.inquiry.inquiries.${inquiryIndex}.enabled`,
        value,
      );
    });
  }

  return (
    <div
      className={`my-1 w-full font-bold py-1 px-1 cursor-pointer
            ${
              disabledParticipantsCount === 0
                ? 'text-app-primary'
                : 'text-gray-400 '
            }
          `}
      onClick={() => {
        if (disabledParticipantsCount === 0) {
          // uncheck all
          enableOrDisableAllParticipants(false);
        } else {
          // check all
          enableOrDisableAllParticipants(true);
        }
      }}
    >
      <div className="w-full flex flex-row justify-start items-center gap-1 px-0">
        <input
          className="text-app-primary ring-1 ring-white"
          type="checkbox"
          readOnly={true}
          checked={disabledParticipantsCount === 0}
        />
        <div>TOUS</div>
      </div>
    </div>
  );
};
