import {
  ProMultiOperationPayload,
  ProMultiOperationPayloadActionStep,
  ProMultiOperationResult,
} from '@mabadive/app-common-model';
import { apiClient } from 'src/_common-browser';
import { clubDiverStore } from '../../store-repository';

/**
 * NOTE: remplace clubMassiveUpdatorClient
 */
export const proMultiMutationsApiClient = {
  update,
  createEmptyPayload,
};

async function update(
  payload: ProMultiOperationPayload,
): Promise<ProMultiOperationResult> {
  // send remote operation
  return await apiClient
    .post<any>('/pro/multi-mutations', {
      authenticate: true,
      json: {
        payload,
      },
    })
    .toPromise()
    .catch((err) => {
      // invalidate cache
      clubDiverStore.clubDiverFullCollection.removeAll();
      throw err;
    });
}
function createEmptyPayload({
  bookingIdsToClean,
  logContext,
  actionSteps = [],
}: {
  bookingIdsToClean: string[];
  logContext: string;
  actionSteps?: ProMultiOperationPayloadActionStep[];
}): ProMultiOperationPayload {
  const payload: ProMultiOperationPayload = {
    logContext,
    bookingIdsToClean,
    diveCenters: {
      duplicated: [],
      updated: [],
    },
    actions: [],
    actionSteps,
    deletedBookingMembersIds: [],
    deletedBookingProductsIds: [],
    deletedBookingSessionParticipantsIds: [],
    deletedBookingSessionsIds: [],
    deletedBookingsIds: [],
    deletedClubParticipantsIds: [],
    deletedDiversIds: [],
    deletedDiveSessionGroupIds: [],
    deletedDiveSessionsIds: [],
    deletedPurchasePackageIds: [],
    deletedPurchasePaymentIds: [],
    deletedBookingCustomerUpdatesIds: [],
    newBookingMembers: [],
    newBookingProducts: [],
    newBookings: [],
    newBookingSessionParticipants: [],
    newBookingSessions: [],
    newClubParticipants: [],
    newDivers: [],
    newDiveSessionGroups: [],
    newDiveSessions: [],
    newPurchasePackages: [],
    newPurchasePayments: [],
    updatedBookingMembers: [],
    updatedBookingProducts: [],
    updatedBookings: [],
    updatedBookingSessionParticipants: [],
    updatedBookingSessions: [],
    updatedClubParticipants: [],
    updatedDivers: [],
    updatedDiveSessionGroups: [],
    updatedDiveSessions: [],
    updatedPurchasePackages: [],
    updatedBookingCustomerUpdates: [],
    updatedPurchasePayments: [],
    updatedDiveSites: [],
    newClubStaffMembers: [],
    deletedClubStaffMemberIds: [],
    updatedClubStaffMembers: [],
    newClubEvents: [],
    updatedClubEvents: [],
    deletedClubEventIds: [],
    bookingDeposits: {
      created: [],
      deletedIds: [],
      updated: [],
      impactedDiverIds: [],
    },
  };
  return payload;
}
