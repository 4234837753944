/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ClubDiver,
  DiveCertificationReference,
} from '@mabadive/app-common-model';
import { attributeStoreFactory, search } from '@mabadive/app-common-services';
import { useMemo } from 'react';
import { map } from 'rxjs/operators';
import { ClubDiverFullSortByAttribute } from 'src/business/club/data';
import { useClubResume } from 'src/business/club/data/hooks';

import { useLoadableContent } from 'src/business/_core/data/app-loading';
import { useLoadable } from 'src/business/_core/data/hooks';
import {
  clubDiverFullRepository,
  clubDiverFullSearchRepository,
} from 'src/business/_core/data/store-repository';
import {
  CollectionOrder,
  CollectionPagination,
} from 'src/business/_core/modules/layout';
import { baseStore } from 'src/business/_core/store/baseStore';
import { useClubDiversListPageFilters } from './useClubDiversListPageFilters.hook';

const pageStore = {
  diversOrder: attributeStoreFactory.create<
    CollectionOrder<ClubDiverFullSortByAttribute>
  >(baseStore, 'ClubDiversListPage_diversOrder'),
  diversPagination: attributeStoreFactory.create<CollectionPagination>(
    baseStore,
    'ClubDiversListPage_diversPagination',
  ),
};

export function useClubDiversListPageLocalState() {
  const { diversTotalCount } = useLoadable({
    load: () =>
      clubDiverFullRepository
        .getTotalCount()
        .pipe(map((diversTotalCount) => ({ diversTotalCount }))),
    debugName: 'diversTotalCount',
    initialValueFromLoadSnapshot: true,
  });

  const clubResume = useClubResume();

  const filters = useClubDiversListPageFilters();

  const {
    searchText,
    setSearchText,
    mainCertificationReference,
    setMainCertificationReference,
    diveCertificationsOptions,
    hasDiveToday,
    hasDiveTomorrow,
  } = filters;

  const searchAttributes: (keyof ClubDiver | 'divesMeta')[] = useMemo(
    () =>
      searchText?.includes('@')
        ? ['firstName', 'lastName', 'phoneNumber', 'emailAddress']
        : ['firstName', 'lastName', 'phoneNumber'], // l'email pose des problème (par exemple, le nom "Rang" est pollué par "@orange.fr")
    [searchText],
  );

  const { content: divers, ...loadableContent } = useLoadableContent(
    () =>
      fetchData(
        {
          fullText: searchText,
          searchAttributes,
          mainCertificationReference,
        },
        { clubReference: clubResume.reference },
      ),
    [
      clubResume.reference,
      mainCertificationReference,
      searchAttributes,
      searchText,
    ],
    {
      initialValue: {
        content: [],
        contentState: 'none',
        lastActionStatus: 'in-progress',
      },
    },
  );

  const filteredDivers = useMemo(() => {
    let items = divers ?? [];
    if (search.clean(searchText)?.length) {
      items = search.filter(items, {
        searchText,

        getAttributes: (x) => {
          const labels: string[] = [x.firstName, x.lastName, x.phoneNumber];
          if (searchText?.includes('@')) {
            labels.push(x.emailAddress);
          }
          return labels;
        },
      });
    }
    if (hasDiveToday) {
      items = items.filter((x) => !!x.divesMeta?.todayFirstDiveTime);
    }
    if (hasDiveTomorrow) {
      items = items.filter((x) => !!x.divesMeta?.tomorrowFirstDiveTime);
    }

    return items;
  }, [divers, hasDiveToday, hasDiveTomorrow, searchText]);

  return {
    diversTotalCount,
    filters,
    loadableContent,
    filteredDivers,
  };
}

function fetchData(
  filtersCriteria: {
    fullText: string;
    searchAttributes: (keyof ClubDiver | 'divesMeta')[];
    mainCertificationReference: DiveCertificationReference;
  },
  { clubReference }: { clubReference: string },
) {
  const { fullText, mainCertificationReference, searchAttributes } =
    filtersCriteria;
  return clubDiverFullSearchRepository.findByFilters({
    clubReference,
    fullText,
    mainCertificationReference,
    searchAttributes,
    minLettersForRemoteSearch: 2,
    maxLastUpdateRemoteResultsIfRemoteSearchIsSkipped:
      mainCertificationReference ? 500 : 50,
    maxReturnResults: 500,
  });
}

export type ClubDiversListPageLocalState = ReturnType<
  typeof useClubDiversListPageLocalState
>;
