/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ClubMassMailingSearchCriteria,
  ClubMassMailingSearchCriteria_ParticipantPeriod,
} from '@mabadive/app-common-model';
import React, { useMemo } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useRedirect } from 'src/business/_core/data/hooks';
import {
  AppButton,
  AppLoader,
  AppMessageLight,
  AppPageContainerWithFixedBars,
  AppPageContentContainer,
  useAutoFocus,
} from 'src/business/_core/modules/layout';
import { useClubResume } from 'src/business/club/data/hooks';
import { apiDownloader } from '../../../_common-browser';
import {
  AppIconsAction,
  AppIconsSocialMedia,
} from '../../../business/_core/modules/layout/icons';
import { ValueLabel } from '../../../business/club/modules/_common/form';
import {
  customerOriginOptionsBuilder,
  NITROX_CERTIFICATION_OPTIONS,
  useResidentTypesOptions,
} from '../../../business/club/modules/club-diver-participant/pages/DiverBookingPage/forms';
import { mainCertificationOptionBuilder } from '../../../business/club/modules/club-diver-participant/pages/DiverBookingPage/forms/BookingMemberDiverEditForm/mainCertificationOptionBuilder.service';
import {
  AppComboBoxMultipleRHF,
  AppFormControlRHF,
  AppInputBooleanSwitchRHF,
  AppSingleSelect2HeadlessUI,
  AppSingleSelect2HeadlessUIRHF,
} from '../../../lib/form';
import { AppMultiCheckboxesTailwindRHF } from '../../../lib/form/components/AppMultiCheckboxes/AppMultiCheckboxesTailwindRHF';
import {
  useDiveModesOptions,
  useDiverTechnicalLevelOptions,
} from '../../_components/options';
import { AppTitleDateNavigatorDateRangePicker } from '../../_components/title-navigators/components';
import { ClubDiversListTable } from '../../LI-lists/LI-01_divers/ClubDiversListTable';
import {
  ClubMassMailingPageLocalState,
  useClubMassMailingPageLocalState,
} from './useClubMassMailingPageLocalState.hook';

// | 'last-1-week'
// | 'last-1-mponth'
// | 'last-3-months'
// | 'last-6-months'
// | 'last-12-months'
// | 'last-24-months';

const PARTICIPANT_PERIOD_OPTIONS: ValueLabel<ClubMassMailingSearchCriteria_ParticipantPeriod>[] =
  [
    {
      value: 'last-1-week',
      label: 'Depuis 1 semaine',
    },
    {
      value: 'last-1-month',
      label: 'Depuis 1 mois',
    },
    {
      value: 'last-3-months',
      label: 'Depuis 3 mois',
    },
    {
      value: 'last-6-months',
      label: 'Depuis 6 mois',
    },
    {
      value: 'last-1-year',
      label: 'Depuis 1 an',
    },
    {
      value: 'last-2-years',
      label: 'Depuis 2 ans',
    },
    {
      value: 'manual-mode',
      label: 'Autre période',
    },
  ];

export const ClubMassMailingPage = () => {
  const autoFocus = useAutoFocus();
  const clubResume = useClubResume();
  const customerSettings = clubResume?.clubSettings?.customer;

  const redirectTo = useRedirect();

  const localState: ClubMassMailingPageLocalState =
    useClubMassMailingPageLocalState();

  const { state, actions } = localState;

  const form: UseFormReturn<ClubMassMailingSearchCriteria> = state.form;

  const { contentState, previewResults, loadingInProgress } = state;

  const { control } = form;

  const diveModesOptions = useDiveModesOptions({
    format: 'short-label',
    type: 'all',
  });
  const technicalLevelOptions = useDiverTechnicalLevelOptions();

  const mainCertificationOptions = useMemo(
    () =>
      mainCertificationOptionBuilder.buildOptions({
        // orgGroupReferencev2: orgGroup,
      }),
    [],
  );
  const residentTypesOptions = useResidentTypesOptions();

  const customerOriginOptions = useMemo(
    () =>
      customerOriginOptionsBuilder.buildOptions({
        clubSettings: clubResume?.clubSettings,
      }),
    [clubResume?.clubSettings],
  );

  return (
    <AppPageContainerWithFixedBars
      marginBottom={false}
      paddingBottom={false}
      contentClassName="bg-gray-50 app-p-content grid gap-8"
      // headerBar={() => <></>}
    >
      <>
        <div className="app-card app-p-content grid gap-2">
          <div className="grid gap-2">
            <div className="flex justify-start gap-2 lg:gap-4 flex-wrap">
              <AppFormControlRHF
                label="Période de plongée"
                control={control}
                name={'participantPeriod'}
                renderComponent={(props) => (
                  <AppSingleSelect2HeadlessUI
                    {...props}
                    className="min-w-36"
                    theme={'material-ui'}
                    disableClearButton={false}
                    options={PARTICIPANT_PERIOD_OPTIONS}
                    onChange={(value) => {
                      actions.onUpdateParticipantPeriod(value);
                    }}
                  />
                )}
              />
              <AppTitleDateNavigatorDateRangePicker
                model={state.datesModel}
                onUpdateEndDate={actions.onUpdateEndDate}
                onUpdateSelectedDate={actions.onUpdateSelectedDate}
              />
            </div>
          </div>

          <div className="grid">
            <h2 className="my-2 text-base sm:text-lg font-medium text-gray-800 leading-6 pt-0 mt-0 flex items-center">
              Prestation réalisée
            </h2>
            <div className="flex justify-start gap-2 lg:gap-4 flex-wrap">
              <AppFormControlRHF
                className={'md:col-span-3 2xl:col-span-2 w-full'}
                label={'Prestations concernées'}
                control={control}
                name={'diveModes'}
                renderComponent={(props) => (
                  <AppMultiCheckboxesTailwindRHF
                    {...props}
                    options={diveModesOptions}
                    gridLayoutModel={'grid-cols-2 => xl:grid-cols-6'}
                  />
                )}
              />
              {/* <AppFormControlRHF
                    label="Type de sortie"
                    control={control}
                    name={'diveModes'}
                    renderComponent={(props) => (
                      <AppComboBoxMultipleRHF
                        {...props}
                        className="w-full"
                        options={diveModesOptions}
                      />
                    )}
                  /> */}
            </div>
          </div>
          <div className="grid">
            <h2 className="my-2 text-base sm:text-lg font-medium text-gray-800 leading-6 pt-0 mt-0 flex items-center">
              Niveau de plongée
            </h2>
            <div className="flex justify-start gap-2 lg:gap-4 flex-wrap">
              <AppFormControlRHF
                label={'Niveau / certification'}
                control={control}
                name={'mainCertificationReferences'}
                renderComponent={(props) => (
                  <AppComboBoxMultipleRHF
                    {...props}
                    className="w-full"
                    options={mainCertificationOptions}
                  />
                )}
              />
              {customerSettings?.diving?.nitrox && (
                <AppFormControlRHF
                  label="Nitrox"
                  control={control}
                  name={'gazCertificationReferences' as const}
                  renderComponent={(props) => (
                    <AppComboBoxMultipleRHF
                      className={'w-full'}
                      {...props}
                      options={NITROX_CERTIFICATION_OPTIONS}
                    />
                  )}
                />
              )}
              {customerSettings.diving.divingTechnicalLevel && (
                <AppFormControlRHF
                  label="Niveau technique"
                  control={control}
                  name={'divingTechnicalLevels'}
                  renderComponent={(props) => (
                    <AppComboBoxMultipleRHF
                      {...props}
                      className="w-full"
                      options={technicalLevelOptions}
                    />
                  )}
                />
              )}
              {customerSettings.diving.photoInterest && (
                <AppFormControlRHF
                  label="Photo / vidéo"
                  control={control}
                  name={'photoInterest'}
                  renderComponent={(props) => (
                    <AppInputBooleanSwitchRHF {...props} />
                  )}
                />
              )}
            </div>
          </div>
          <div className="grid">
            <h2 className="my-2 text-base sm:text-lg font-medium text-gray-800 leading-6 pt-0 mt-0 flex items-center">
              Type de client
            </h2>
            <div className="flex justify-start gap-2 lg:gap-4 flex-wrap">
              <AppFormControlRHF
                label="Résidence"
                control={control}
                name={'residentType' as const}
                renderComponent={(props) => (
                  <AppSingleSelect2HeadlessUIRHF
                    className={'w-32'}
                    theme="material-ui"
                    color="theme"
                    {...props}
                    options={residentTypesOptions}
                  />
                )}
              />
              <AppFormControlRHF
                label={'Origine client'}
                control={control}
                name={'customerOrigins'}
                renderComponent={(props) => (
                  <AppComboBoxMultipleRHF
                    {...props}
                    className="w-full"
                    options={customerOriginOptions}
                  />
                )}
              />
            </div>
          </div>
        </div>

        <div className="app-card app-p-content grid gap-4">
          {loadingInProgress ? (
            <AppLoader className="my-4" />
          ) : (
            <AppPageContentContainer className="" paddingBottom={false}>
              {previewResults?.meta?.filteredDiversCount === 0 ? (
                <>
                  <AppMessageLight type="danger">
                    <p>Aucun plongeur ne correspond à cette recherche.</p>
                  </AppMessageLight>
                </>
              ) : previewResults?.meta?.filteredDiversCount > 0 ? (
                <div className="grid gap-4">
                  <AppMessageLight>
                    <p>
                      {previewResults?.meta?.filteredDiversCount} plongeurs
                      correspondent à cette recherche.
                    </p>
                    {previewResults?.meta?.filteredDiversCount !==
                    previewResults?.meta?.filteredDiversRecipientCount ? (
                      <p>
                        {previewResults?.meta?.filteredDiversRecipientCount}/
                        {previewResults?.meta?.filteredDiversCount} ont une
                        adresse email valide.
                      </p>
                    ) : (
                      <p>Toutes les plongeurs ont une adresse email valide.</p>
                    )}
                  </AppMessageLight>
                  <div>
                    <div className="col-span-2 xs:col-span-1 flex gap-8 justify-end items-end">
                      <AppButton
                        icon={AppIconsAction.download}
                        color="primary-outline-light"
                        onClick={() =>
                          apiDownloader.downloadDiversSheet({
                            criteria: {
                              ...state.fetchCriteria,
                              format: 'xls',
                              search: {
                                ...state.fetchCriteria.search,
                                onlyWithEmails: true,
                              },
                            },
                          })
                        }
                      >
                        <span className="">Télécharger les plongeurs</span>
                      </AppButton>
                      <AppButton
                        icon={AppIconsSocialMedia.mail}
                        color="primary-outline-light"
                        onClick={() =>
                          apiDownloader.downloadDiversSheet({
                            criteria: {
                              ...state.fetchCriteria,
                              format: 'txt',
                              search: {
                                ...state.fetchCriteria.search,
                                onlyWithEmails: true,
                              },
                            },
                          })
                        }
                      >
                        <span className="">Télécharger les emails</span>
                      </AppButton>
                    </div>
                  </div>
                  <h2 className="my-2 text-base sm:text-lg font-medium text-gray-800 leading-6 pt-0 mt-0 flex items-center">
                    Aperçu:
                  </h2>
                  <ClubDiversListTable divers={previewResults.firstResults} />
                </div>
              ) : null}
            </AppPageContentContainer>
          )}
        </div>
      </>
    </AppPageContainerWithFixedBars>
  );
};
