/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC } from 'react';
import {
  AppButton,
  AppFormAutoSubmit,
  AppPageContainerWithFixedBars,
} from 'src/business/_core/modules/layout';

import { useWatch } from 'react-hook-form';
import { AppIcons } from 'src/business/_core/modules/layout/icons';
import { ClubSettingsProductOfferCategoryDialogActionsBar } from './ClubSettingsProductOfferCategoryDialogActionsBar';
import {
  ClubSettingsProductOfferCategoryDialogState,
  ClubSettingsProductOfferCategoryEditFormModel,
} from './_model';
import { ClubSettingsProductOfferCategoryEditForm } from './form';
import {
  ClubSettingsProductOfferCategoryDialogLocalState,
  useClubSettingsProductOfferCategoryDialogLocalState,
} from './useClubSettingsProductOfferCategoryDialogLocalState.hook';
import { useClubSettingsProductOfferCategoryDialogSubmit } from './useClubSettingsProductOfferCategoryDialogSubmit.hook';

export const ClubSettingsProductOfferCategoryDialog: FC<ClubSettingsProductOfferCategoryDialogState> =
  (inputState) => {
    const {
      isOpen,
      initialState,
      onCancel,
      onConfirm,
      openDialog,
      closeDialog,
    } = inputState;

    const { defaultValue, mode } = initialState;

    const localState: ClubSettingsProductOfferCategoryDialogLocalState =
      useClubSettingsProductOfferCategoryDialogLocalState({
        initialState,
      });

    const {
      state: { form },
    } = localState;

    const {
      register,
      handleSubmit,
      watch,
      formState,
      control,
      setValue,
      reset,
    } = form;

    const formValue: ClubSettingsProductOfferCategoryEditFormModel = watch();

    const submitForm = useClubSettingsProductOfferCategoryDialogSubmit({
      handleSubmit,
      localState,
      inputState,
    });

    const [offerCategoryTitle] = useWatch({
      name: ['offerCategory.title'],
      control,
    });

    const hasChanges = form.formState.isDirty;

    return !isOpen ? null : (
      <AppPageContainerWithFixedBars
        className="max-w-screen-2xl"
        contentClassName="bg-gray-50"
        footerBar={() => (
          <ClubSettingsProductOfferCategoryDialogActionsBar
            className="sm:hidden"
            onCancel={onCancel}
            submitForm={submitForm}
          />
        )}
      >
        <div className="app-card app-p-content">
          <AppFormAutoSubmit onSubmit={submitForm}>
            {/* <ClubSettingsSection
            className="app-card app-p-content"
            title="Moniteur"
          > */}
            <h2 className="text-left text-sm sm:text-lg leading-6 py-2 font-medium text-app-blue uppercase">
              {initialState.mode === 'create'
                ? 'Nouvelle catégorie'
                : offerCategoryTitle}
            </h2>
            <ClubSettingsProductOfferCategoryEditForm
              className=""
              localState={localState}
            />
            {/* </ClubSettingsSection> */}
            <div className="hidden sm:block sticky -bottom-8 pt-8">
              <div className="flex gap-4 justify-start">
                <AppButton
                  size="normal"
                  icon={AppIcons.action.cancel}
                  tabIndex={500}
                  color="gray-outline-light"
                  onClick={() => {
                    onCancel();
                  }}
                >
                  Annuler
                </AppButton>
                <AppButton
                  type="submit"
                  size="normal"
                  icon={AppIcons.action.save}
                  color="primary-bg"
                  onClick={() => {
                    submitForm();
                  }}
                >
                  Confirmer
                </AppButton>
              </div>
            </div>
          </AppFormAutoSubmit>
        </div>
      </AppPageContainerWithFixedBars>
    );
  };
