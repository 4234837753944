import { ClubAuthUserProfile } from '@mabadive/app-common-model';
import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import {
  AppHeroIcons,
  AppIconsAction,
} from 'src/business/_core/modules/layout/icons';
import { AppInputBooleanSwitchRHF } from 'src/lib/form';

export function ClubSettingsUserProfileEditFormAuthSettings({
  form,
  className,
}: {
  form: UseFormReturn<ClubAuthUserProfile, object>;
  className?: string;
}) {
  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  return (
    <div className="mt-1 grid gap-4 md:grid-cols-2">
      <div className="border-2 border-status-success">
        <div className="font-bold bg-status-success/10 text-status-success border-b border-status-success uppercase text-base flex items-center gap-2 px-2">
          <AppHeroIcons.eye className={'inline w-4 h-4 font-bold'} />
          Accès
        </div>
        <div className="m-2 grid gap-4 sm:grid-cols-2 md:grid-cols-1 xl:grid-cols-2">
          <AppInputBooleanSwitchRHF
            control={control}
            name="authorizations.view.settings.offers"
            label={'Offres, forfaits, formations'}
            theme={'success'}
          />
          <AppInputBooleanSwitchRHF
            control={control}
            name="authorizations.view.settings.global"
            label={'Configuration générale'}
            theme={'success'}
          />
          <AppInputBooleanSwitchRHF
            control={control}
            name="authorizations.view.settings.diveCenter"
            label={'Bateaux, sites, moniteurs...'}
            theme={'success'}
          />
          <AppInputBooleanSwitchRHF
            control={control}
            name="authorizations.view.settings.userAccounts"
            label={'Comptes utilisateurs'}
            theme={'warn'}
          />
          <AppInputBooleanSwitchRHF
            control={control}
            name="authorizations.view.settings.mabadiveBilling"
            label={'Forfait & facturation mabadive'}
            theme={'warn'}
          />
        </div>
      </div>
      <div className="border-2 border-status-warn">
        <div className="font-bold bg-status-warn/10 text-status-warn border-b border-status-warn uppercase text-base flex items-center gap-2 px-2">
          <AppIconsAction.edit className={'inline w-4 h-4 font-bold'} />
          Actions
        </div>
        <div className="m-2 grid gap-4 sm:grid-cols-2 md:grid-cols-1 xl:grid-cols-2">
          <AppInputBooleanSwitchRHF
            control={control}
            name="authorizations.edit.settings.offers"
            label={'Configuration "Offres, forfaits, formations"'}
            theme={'warn'}
          />
          <AppInputBooleanSwitchRHF
            control={control}
            name="authorizations.edit.settings.global"
            label={'Configuration "Configuration générale"'}
            theme={'warn'}
          />
          <AppInputBooleanSwitchRHF
            control={control}
            name="authorizations.edit.settings.diveCenter"
            label={'Configuration "Bateaux, sites, moniteurs..."'}
            theme={'warn'}
          />
          <AppInputBooleanSwitchRHF
            control={control}
            name="authorizations.edit.settings.accomodation"
            label={'Configuration "Hébergements"'}
            theme={'warn'}
          />
          <AppInputBooleanSwitchRHF
            control={control}
            name="authorizations.edit.settings.agencies"
            label={'Configuration "Agences"'}
            theme={'warn'}
          />
          <AppInputBooleanSwitchRHF
            control={control}
            name="authorizations.edit.settings.customerSpace"
            label={'Configuration "Espace client"'}
            theme={'warn'}
          />
          <AppInputBooleanSwitchRHF
            control={control}
            name="authorizations.edit.settings.inquiry"
            label={'Configuration "Questionnaires"'}
            theme={'warn'}
          />
          <AppInputBooleanSwitchRHF
            control={control}
            name="authorizations.edit.settings.equipment"
            label={'Configuration "Équipements"'}
            theme={'warn'}
          />
          <AppInputBooleanSwitchRHF
            control={control}
            name="authorizations.edit.settings.exportDaily"
            label={'Configuration "Exports"'}
            theme={'warn'}
          />
          <AppInputBooleanSwitchRHF
            control={control}
            name="authorizations.edit.settings.userAccounts"
            label={'Configuration "Comptes utilisateurs"'}
            theme={'danger'}
          />
          <AppInputBooleanSwitchRHF
            control={control}
            name="authorizations.edit.settings.onlinePayment"
            label={'Configuration "Paiement en ligne"'}
            theme={'danger'}
          />
          <AppInputBooleanSwitchRHF
            control={control}
            name="authorizations.edit.settings.archiveData"
            label={'Archiver les anciennes données'}
            theme={'danger'}
          />
          <AppInputBooleanSwitchRHF
            control={control}
            name="authorizations.edit.settings.accountProfilesImpersonate"
            label={'Se connecter en tant qu\'un autre profil'}
            theme={'danger'}
          />
        </div>
      </div>
    </div>
  );
}
