/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ClubProductPackageNameDetails,
  ClubProductPackageNameDetailsFormatProps,
  productPackageFormatter,
  productTypeFormatter,
} from '@mabadive/app-common-services';
import React, { useMemo } from 'react';
import { useClubResume, useClubSettings } from 'src/business/club/data/hooks';

export const ClubOfferNameDetailsLabel = ({
  nameDetails,
  productPackage,
  hideStoreCategory,
  className = '',
}: {
  nameDetails?: ClubProductPackageNameDetails;
  productPackage?: ClubProductPackageNameDetailsFormatProps;
  hideStoreCategory?: boolean;
  className?: string;
}) => {
  const clubResume = useClubResume();
  const publicSettings = clubResume.clubSettings.publicSettings;
  const clubSettings = useClubSettings();
  const nonDivePlan = productPackage?.productAttributes?.nonDivePlan;

  nameDetails = useMemo(() => {
    if (productPackage) {
      if (productPackage.type === 'product') {
        // supplément
        const nameDetails: ClubProductPackageNameDetails = {
          prefix: '',
          name: productPackage.label,
        };
        return nameDetails;
      }
      if (productPackage.type === 'store') {
        // magasin
        const nameDetails: ClubProductPackageNameDetails = {
          prefix: !hideStoreCategory
            ? productPackage.productAttributes?.productCategoryTitle
            : undefined,
          name: productPackage.label,
        };
        // if (nonDivePlan?.enabled) {
        //   nameDetails.suffix = `x${nonDivePlan?.quantity}`;
        // }
        return nameDetails;
      }
      const nameDetails: ClubProductPackageNameDetails =
        productPackageFormatter.formatNameDetails(productPackage, {
          publicSettings,
          diveModesConf: clubSettings?.ui?.diveMode,
        });
      return nameDetails;
    }
    return nameDetails;
  }, [
    productPackage,
    nameDetails,
    publicSettings,
    clubSettings?.ui?.diveMode,
    hideStoreCategory,
  ]);

  return !nameDetails ? null : (
    <div className={` ${className}`}>
      <div className={''}>
        {nameDetails.prefix && <span className="">{nameDetails.prefix}</span>}{' '}
        {nameDetails.name && <span className="">{nameDetails.name}</span>}{' '}
        {nameDetails.label && (
          <span className="text-app-orange">{nameDetails.label}</span>
        )}{' '}
        {nameDetails.suffix && (
          <div className="text-gray-500">{nameDetails.suffix}</div>
        )}
      </div>
      <div className={'flex gap-4 items-end'}>
        {nonDivePlan?.enabled && (
          <div className="text-orange-600 text-sm font-normal uppercase">
            Forfait x{nonDivePlan.quantity}
          </div>
        )}{' '}
        {productPackage?.type !== 'store' && productPackage?.productType && (
          <div className="text-gray-500 text-sm font-normal uppercase">
            {productTypeFormatter.format(productPackage.productType)}{' '}
          </div>
        )}
      </div>
    </div>
  );
};
