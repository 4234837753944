// ISO 3166-1 codes - https://github.com/umpirsky/country-list

import { ClubDiverResidentType } from '@mabadive/app-common-model';
import React, { useMemo } from 'react';
import { ValueLabel } from 'src/business/club/modules/_common/form/components/ValueLabel.model';
import { useClubSettings } from '../../../../../../data/hooks';
import { ResidentTypeLabelBadge } from '../../components/ResidentTypeLabelBadge';

export function useResidentTypesOptions() {
  const clubSettings = useClubSettings();
  const diveModesOptions: ValueLabel<ClubDiverResidentType, React.ReactNode>[] =
    useMemo(
      () =>
        (clubSettings?.customer?.general?.residentTypeOptions ?? [])
          ?.filter((x) => x.enabled)
          .map((residentTypeOption) => {
            const residentType = residentTypeOption.ref;
            return {
              value: residentType,
              label: (
                <ResidentTypeLabelBadge
                  className="text-xs"
                  residentType={residentType}
                />
              ),
            };
          }),
      [clubSettings],
    );

  return diveModesOptions;
}
