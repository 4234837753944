/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import { useClubResume } from 'src/business/club/data/hooks';
import { AppInputBooleanRHF } from 'src/lib/form';
import { AppFormControlRHF } from 'src/lib/form/components/AppFormControl/AppFormControlRHF';
import { AppExpansionPanel } from '../../../../../business/_core/modules/layout';
import { ClubSettingsCustomerFormFieldsEditPageLocalState } from './useClubSettingsCustomerFormFieldsEditPageLocalState.hook';

export const ClubSettingsCustomerFormFieldsEditForm_CustomerMember = ({
  localState,
  className,
}: {
  localState: ClubSettingsCustomerFormFieldsEditPageLocalState;
  className?: string;
}) => {
  const { form } = localState;
  const { control, setValue } = form;

  const clubResume = useClubResume();

  const [isExpanded, setIsExpanded] = useState(true);
  return (
    <AppExpansionPanel
      className={className}
      isExpandable={true}
      isExpanded={isExpanded}
      expandPanel={() => {
        return () => setIsExpanded(!isExpanded);
      }}
      panelId="archived-purchases"
      renderSummaryTitle={() => (
        <div className="flex justify-between items-center">
          <h2 className="text-left uppercase text-lg leading-6 font-medium text-gray-800">
            Client - adhérent
          </h2>
          {!isExpanded && (
            <span className="hidden sm:flex text-xs text-gray-400">
              CLIQUER POUR CONFIGURER
            </span>
          )}
        </div>
      )}
      renderSummaryExtra={() => null}
      renderDetails={() => (
        <div className="flex flex-col gap-y-2">
          <AppFormControlRHF
            control={control}
            name="clubSettings.customer.general.isMember"
            renderComponent={(props) => (
              <AppInputBooleanRHF
                {...props}
                type="checkbox"
                description={'Membre (oui/non)'}
              />
            )}
          />
          <AppFormControlRHF
            control={control}
            name="clubSettings.customer.general.memberRef"
            renderComponent={(props) => (
              <AppInputBooleanRHF
                {...props}
                type="checkbox"
                description={'Numéro de membre'}
              />
            )}
          />
          <AppFormControlRHF
            control={control}
            name="clubSettings.customer.general.membershipDate"
            renderComponent={(props) => (
              <AppInputBooleanRHF
                {...props}
                type="checkbox"
                description={'Date d\'adhésion'}
              />
            )}
          />
          <AppFormControlRHF
            control={control}
            name="clubSettings.customer.general.isStudent"
            renderComponent={(props) => (
              <AppInputBooleanRHF
                {...props}
                type="checkbox"
                description={'En formation (oui/non)'}
              />
            )}
          />
          <AppFormControlRHF
            control={control}
            name="clubSettings.customer.general.studentDescription"
            renderComponent={(props) => (
              <AppInputBooleanRHF
                {...props}
                type="checkbox"
                description={'Détails formation'}
              />
            )}
          />
        </div>
      )}
    />
  );
};
