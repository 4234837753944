/* eslint-disable @typescript-eslint/no-unused-vars */
import { ClubSettingsCustomerGeneral_CustomerOriginOption } from '@mabadive/app-common-model';
import { uuidGenerator } from '@mabadive/app-common-services';
import clsx from 'clsx';
import React, { useCallback, useState } from 'react';
import {
  FieldArrayWithId,
  UseFormReturn,
  useFieldArray,
  useWatch,
} from 'react-hook-form';
import {
  AppButton,
  AppExpansionPanel,
} from 'src/business/_core/modules/layout';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { ClubSettingsCustomerFormFieldsEditFormCustomerOriginOptionsItem } from './ClubSettingsCustomerFormFieldsEditFormCustomerOriginOptionsItem';
import { ClubSettingsCustomerEditorFormModel } from './model';
export type ClubSettingsCustomerFormFieldsEditFormCustomerOriginOptions_OnClickAction =
  'move-down' | 'move-up' | 'delete' | 'clone';
export const ClubSettingsCustomerFormFieldsEditFormCustomerOriginOptions = ({
  form,
  className,
}: {
  form: UseFormReturn<ClubSettingsCustomerEditorFormModel, object>;
  className?: string;
}) => {
  const {
    register,
    handleSubmit,
    watch,
    formState,
    control,
    setValue,
    reset,
    getValues,
  } = form;

  const [modelEnabled] = useWatch({
    control,
    name: ['clubSettings.customer.general.customerOrigin'],
  });

  const modelsFieldArray = useFieldArray({
    control,
    name: 'clubSettings.customer.general.customerOriginOptions',
    keyName: 'form_ref',
    shouldUnregister: false, // si true, le tableau est vidé si le composant est masqué (unmount)
  });
  const [modelAnimations, setModelAnimations] =
    useState<{
      update: Date;
      up?: number;
      down?: number;
      remove?: number;
      clone?: number;
    }>();

  const isRecentAnimationUpdate =
    new Date().getTime() - modelAnimations?.update?.getTime() < 1000;

  const onClick = useCallback(
    (
      field: FieldArrayWithId<
        ClubSettingsCustomerEditorFormModel,
        'clubSettings.customer.general.customerOriginOptions',
        'form_ref'
      >,
      fieldIndex: number,
      action: ClubSettingsCustomerFormFieldsEditFormCustomerOriginOptions_OnClickAction,
    ) => {
      const from = fieldIndex;
      const update = new Date();
      switch (action) {
        case 'move-down': {
          modelsFieldArray.move(from, from + 1);
          setModelAnimations({ update, up: from + 1, down: from });
          break;
        }
        case 'move-up': {
          modelsFieldArray.move(from, from - 1);
          setModelAnimations({ update, up: from, down: from - 1 });
          break;
        }
        case 'delete': {
          // setModelAnimations({ update, remove: from });
          modelsFieldArray.remove(from);
          // setTimeout(() => modelsFieldArray.remove(from), 100);
          break;
        }
        case 'clone': {
          if (field) {
            const original: ClubSettingsCustomerGeneral_CustomerOriginOption =
              getValues(
                `clubSettings.customer.general.customerOriginOptions.${fieldIndex}`,
              );
            const clone: ClubSettingsCustomerGeneral_CustomerOriginOption =
              JSON.parse(
                JSON.stringify({
                  ...original,
                  ref: uuidGenerator.random(),
                }),
              );

            modelsFieldArray.insert(from + 1, clone as any);
            setModelAnimations({ update, clone: from });
            break;
          }
        }
      }
    },
    [getValues, modelsFieldArray],
  );

  const [isExpanded, setIsExpanded] = useState(false);

  return modelEnabled ? (
    <AppExpansionPanel
      isExpandable={true}
      isExpanded={isExpanded}
      expandPanel={() => {
        return () => setIsExpanded(!isExpanded);
      }}
      renderSummaryTitle={() => (
        <div className="flex justify-between items-center">
          <h2 className="text-left uppercase text-lg leading-6 font-medium text-gray-800">
            Origines clients
          </h2>
          {!isExpanded && (
            <span className="hidden sm:flex text-xs text-gray-400">
              CLIQUER POUR PERSONNALISER
            </span>
          )}
        </div>
      )}
      renderSummaryExtra={() => (
        <div className="py-4 text-base text-gray-600">
          {modelsFieldArray.fields.map((x) => x.label).join(', ')}
        </div>
      )}
      renderDetails={() => (
        <div className={clsx('grid gap-2', className)}>
          <div className="grid gap-2">
            {modelsFieldArray.fields.map((field, fieldIndex) => {
              return (
                <ClubSettingsCustomerFormFieldsEditFormCustomerOriginOptionsItem
                  key={`${field.ref}-${fieldIndex}`}
                  form={form}
                  fieldArray={modelsFieldArray as any}
                  fieldIndex={fieldIndex}
                  className={clsx(
                    isRecentAnimationUpdate &&
                      modelAnimations?.up === fieldIndex &&
                      'animate-move-up',
                    isRecentAnimationUpdate &&
                      modelAnimations?.down === fieldIndex &&
                      'animate-move-down',
                    isRecentAnimationUpdate &&
                      modelAnimations?.clone === fieldIndex &&
                      'animate-create',
                    isRecentAnimationUpdate &&
                      modelAnimations?.remove === fieldIndex &&
                      'animate-remove',
                  )}
                  onClick={(action) => onClick(field, fieldIndex, action)}
                />
              );
            })}
            <AppButton
              className="my-4"
              size="normal"
              icon={AppHeroIcons.actionAdd}
              color="primary-outline-light"
              onClick={() => {
                modelsFieldArray.append({
                  ref: uuidGenerator.random(),
                  label: '',
                } as any);
              }}
            >
              Ajouter une origine client
            </AppButton>
          </div>
        </div>
      )}
      panelId={'customer-origin-options'}
    />
  ) : null;
};
