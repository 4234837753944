import {
  dateService,
  HASURA_DATE_TIME_WITH_MS_FORMAT,
} from '@mabadive/app-common-services';
import { MQueryDescription } from 'src/_common-browser';
import { PurchaseResumeCollectionCriteria } from 'src/business/_core/data/store-repository';
import { PurchaseListPagePurchaseFetchedModel } from '../model';

export const purchasesListPageGraphqlQueryBuilder = {
  buildQuery,
};
function buildQuery({
  clubReference,
  diveCenterId,
  status: { purchasePackages },
  maxResults = 100,
  updatedAfter,
  beginPurchaseDate,
  endPurchaseDate,
  validityStatus,
  billedByBookingAgencyId,
  paymentStatus,
}: PurchaseResumeCollectionCriteria) {
  const purchasePackagesFilters: string[] = [];

  // Filtre obligatoire : clubReference
  purchasePackagesFilters.push(`clubReference: {_eq: "${clubReference}"}`);

  // Filtre optionnel : diveCenterId
  if (diveCenterId) {
    purchasePackagesFilters.push(`diveCenterId: {_eq: "${diveCenterId}"}`);
  }

  // Gestion des statuts actifs
  if (purchasePackages === 'only-active') {
    purchasePackagesFilters.push(`
      _or: [
        {validityStatus: {_eq: "active"}},
        {purchasePaymentStatus: {_neq: "done"}},
        {purchasePaymentPending: {_eq: "true"}}
      ]
    `);
  }

  // Filtre optionnel : updatedAfter
  if (updatedAfter) {
    const updatedAfterString = dateService.formatUTC(
      updatedAfter,
      HASURA_DATE_TIME_WITH_MS_FORMAT,
    );
    purchasePackagesFilters.push(`_updatedAt: {_gt: "${updatedAfterString}"}`);
  }

  // Gestion des plages de dates
  if (beginPurchaseDate || endPurchaseDate) {
    const dateFilters: string[] = [];

    if (beginPurchaseDate) {
      const beginDateString = dateService.formatUTC(
        beginPurchaseDate,
        HASURA_DATE_TIME_WITH_MS_FORMAT,
      );
      dateFilters.push(`_gte: "${beginDateString}"`);
    }

    if (endPurchaseDate) {
      const endDateString = dateService.formatUTC(
        endPurchaseDate,
        HASURA_DATE_TIME_WITH_MS_FORMAT,
      );
      dateFilters.push(`_lte: "${endDateString}"`);
    }

    purchasePackagesFilters.push(`purchaseDate: {${dateFilters.join(', ')}}`);
  }

  // Filtre optionnel : validityStatus
  if (validityStatus) {
    purchasePackagesFilters.push(`validityStatus: {_eq: "${validityStatus}"}`);
  }

  // Filtre optionnel : billedByBookingAgencyId
  if (billedByBookingAgencyId) {
    purchasePackagesFilters.push(
      `billedByBookingAgencyId: {_eq: "${billedByBookingAgencyId}"}`,
    );
  }

  // Filtre optionnel : paymentStatus
  if (paymentStatus) {
    if (paymentStatus === 'pending') {
      purchasePackagesFilters.push('purchasePaymentPending: {_eq: true}');
    } else {
      purchasePackagesFilters.push(
        `purchasePaymentStatus: {_eq: "${paymentStatus}"}`,
      );
    }
  }

  // Construction du WHERE
  const where = `{${purchasePackagesFilters.join(',')}}`;

  // Attributs retournés
  const returnAttributes = buildAttributes();

  // Description finale de la requête
  const queryDescription: MQueryDescription<PurchaseListPagePurchaseFetchedModel> =
    {
      returnName: 'purchaseResumes',
      queryName: 'club_commerce_purchase_package',
      returnType: 'all',
      where,
      orderBy: '{purchaseDate: desc}',
      returnAttributes,
      limit: maxResults,
    };

  return queryDescription;
}

function buildAttributes() {
  return `
    _id
    _updatedAt
    productPackageType
    purchaseDate
    validityStatus
    extraCosts
    purchasePaymentStatus
    purchasePaymentPending
    price
    payedAmount
    productPackageOffer
    credits
    comment
    contact: diver {
      _id
      firstName
      lastName
      fakeName
      phoneNumber
      emailAddress
      birthdate
      age
    }
    isUnitOffer
    unitPrice
    unitQuantity
    discountAmount
    totalPriceExtraCosts
    totalPriceBeforeDiscount
    totalPriceThirdPartyCollect
    billedByBookingAgencyId
`;
}
