/* eslint-disable @typescript-eslint/no-unused-vars */
import { ClubSettingsCustomerGeneral_ResidentTypeOption } from '@mabadive/app-common-model';
import { uuidGenerator } from '@mabadive/app-common-services';
import clsx from 'clsx';
import React, { useCallback, useState } from 'react';
import {
  FieldArrayWithId,
  UseFormReturn,
  useFieldArray,
  useWatch,
} from 'react-hook-form';
import { AppExpansionPanel } from 'src/business/_core/modules/layout';
import { useClubSettings } from '../../../../../business/club/data/hooks';
import { ResidentTypeLabelBadge } from '../../../../../business/club/modules/club-diver-participant/pages/DiverBookingPage/components/ResidentTypeLabelBadge';
import { ClubSettingsCustomerFormFieldsEditFormResidentTypeOptionsItem } from './ClubSettingsCustomerFormFieldsEditFormResidentTypeOptionsItem';
import { ClubSettingsCustomerEditorFormModel } from './model';
export type ClubSettingsCustomerFormFieldsEditFormResidentTypeOptions_OnClickAction =
  'move-down' | 'move-up' | 'delete' | 'clone';
export const ClubSettingsCustomerFormFieldsEditFormResidentTypeOptions = ({
  form,
  className,
}: {
  form: UseFormReturn<ClubSettingsCustomerEditorFormModel, object>;
  className?: string;
}) => {
  const {
    register,
    handleSubmit,
    watch,
    formState,
    control,
    setValue,
    reset,
    getValues,
  } = form;

  const clubSettings = useClubSettings();

  const [modelEnabled] = useWatch({
    control,
    name: ['clubSettings.customer.general.residentType'],
  });

  const modelsFieldArray = useFieldArray({
    control,
    name: 'clubSettings.customer.general.residentTypeOptions',
    keyName: 'form_ref',
    shouldUnregister: false, // si true, le tableau est vidé si le composant est masqué (unmount)
  });
  const [modelAnimations, setModelAnimations] =
    useState<{
      update: Date;
      up?: number;
      down?: number;
      remove?: number;
      clone?: number;
    }>();

  const isRecentAnimationUpdate =
    new Date().getTime() - modelAnimations?.update?.getTime() < 1000;

  const onClick = useCallback(
    (
      field: FieldArrayWithId<
        ClubSettingsCustomerEditorFormModel,
        'clubSettings.customer.general.residentTypeOptions',
        'form_ref'
      >,
      fieldIndex: number,
      action: ClubSettingsCustomerFormFieldsEditFormResidentTypeOptions_OnClickAction,
    ) => {
      const from = fieldIndex;
      const update = new Date();
      switch (action) {
        case 'move-down': {
          modelsFieldArray.move(from, from + 1);
          setModelAnimations({ update, up: from + 1, down: from });
          break;
        }
        case 'move-up': {
          modelsFieldArray.move(from, from - 1);
          setModelAnimations({ update, up: from, down: from - 1 });
          break;
        }
        case 'delete': {
          // setModelAnimations({ update, remove: from });
          modelsFieldArray.remove(from);
          // setTimeout(() => modelsFieldArray.remove(from), 100);
          break;
        }
        case 'clone': {
          if (field) {
            const original: ClubSettingsCustomerGeneral_ResidentTypeOption =
              getValues(
                `clubSettings.customer.general.residentTypeOptions.${fieldIndex}`,
              );
            const clone: ClubSettingsCustomerGeneral_ResidentTypeOption =
              JSON.parse(
                JSON.stringify({
                  ...original,
                  ref: uuidGenerator.random(),
                }),
              );

            modelsFieldArray.insert(from + 1, clone as any);
            setModelAnimations({ update, clone: from });
            break;
          }
        }
      }
    },
    [getValues, modelsFieldArray],
  );

  const [isExpanded, setIsExpanded] = useState(false);

  return modelEnabled ? (
    <AppExpansionPanel
      isExpandable={true}
      isExpanded={isExpanded}
      expandPanel={() => {
        return () => setIsExpanded(!isExpanded);
      }}
      renderSummaryTitle={() => (
        <div className="">
          <h2 className="text-left uppercase text-lg leading-6 font-medium text-gray-800">
            Résidence (catégorie de client)
          </h2>
          {!isExpanded && (
            <div className="mt-2 hidden sm:flex text-xs text-gray-400">
              CLIQUER POUR PERSONNALISER
            </div>
          )}
        </div>
      )}
      renderSummaryExtra={() => (
        <div className="py-4 text-base text-gray-600 grid grid-cols-2 gap-2">
          {modelsFieldArray.fields
            .filter((x) => x.enabled)
            .map((x) => (
              <ResidentTypeLabelBadge
                key={x.ref}
                residentType={x.ref}
                iconSize="size-5"
              />
            ))}
        </div>
      )}
      renderDetails={() => (
        <div className={clsx('grid gap-2', className)}>
          <div className="grid gap-2">
            {modelsFieldArray.fields.map((field, fieldIndex) => {
              return (
                <ClubSettingsCustomerFormFieldsEditFormResidentTypeOptionsItem
                  key={`${field.ref}-${fieldIndex}`}
                  form={form}
                  fieldArray={modelsFieldArray as any}
                  fieldIndex={fieldIndex}
                  className={clsx(
                    isRecentAnimationUpdate &&
                      modelAnimations?.up === fieldIndex &&
                      'animate-move-up',
                    isRecentAnimationUpdate &&
                      modelAnimations?.down === fieldIndex &&
                      'animate-move-down',
                    isRecentAnimationUpdate &&
                      modelAnimations?.clone === fieldIndex &&
                      'animate-create',
                    isRecentAnimationUpdate &&
                      modelAnimations?.remove === fieldIndex &&
                      'animate-remove',
                  )}
                  onClick={(action) => onClick(field, fieldIndex, action)}
                />
              );
            })}
            {/* <AppButton
              className="my-4"
              size="normal"
              icon={AppHeroIcons.actionAdd}
              color="primary-outline-light"
              onClick={() => {
                modelsFieldArray.append({
                  ref: '',
                  label: '',
                } as any);
              }}
            >
              Ajouter une origine client
            </AppButton> */}
          </div>
        </div>
      )}
      panelId={'customer-origin-options'}
    />
  ) : null;
};
