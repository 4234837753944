import clsx from 'clsx';
import React, { useCallback, useState } from 'react';
import {
  FieldArrayWithId,
  UseFormReturn,
  useFieldArray,
} from 'react-hook-form';
import { AppButton } from 'src/business/_core/modules/layout';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';

import { ClubEcommerceProductArticlePrice } from '@mabadive/app-common-model';
import { uuidGenerator } from '@mabadive/app-common-services';
import { EcommerceProductArticleEditorFormModel } from '../model';
import { EcommerceProductArticleEditorFormSettingsPricesItem } from './EcommerceProductArticleEditorFormSettingsPricesItem';

export type EcommerceProductArticleEditorFormSettingsPricesOnClickAction =
  | 'move-down'
  | 'move-up'
  | 'delete'
  | 'clone'
  | 'create';

export const EcommerceProductArticleEditorFormSettingsPrices = ({
  form,
  className,
}: {
  form: UseFormReturn<EcommerceProductArticleEditorFormModel, object>;
  className?: string;
}) => {
  const { control, setValue, getValues } = form;

  // const [modelEnabled] = useWatch({
  //   control,
  //   name: [`productArticle.pricing.prices`],
  // });

  const pricesFieldArray = useFieldArray({
    control,
    name: 'productArticle.pricing.prices',
    keyName: '_id',
    shouldUnregister: false, // si true, le tableau est vidé si le composant est masqué (unmount)
  });

  const [animations, setModelAnimations] =
    useState<{
      update: Date;
      up?: number;
      down?: number;
      remove?: number;
      clone?: number;
    }>();

  const isRecentAnimationUpdate =
    new Date().getTime() - animations?.update?.getTime() < 1000;

  const onClick = useCallback(
    (
      field: FieldArrayWithId<
        EcommerceProductArticleEditorFormModel,
        'productArticle.pricing.prices',
        '_id'
      >,
      fieldIndex: number,
      action: EcommerceProductArticleEditorFormSettingsPricesOnClickAction,
    ) => {
      const from = fieldIndex;
      const update = new Date();
      switch (action) {
        case 'move-down': {
          pricesFieldArray.move(from, from + 1);
          setModelAnimations({ update, up: from + 1, down: from });
          break;
        }
        case 'move-up': {
          pricesFieldArray.move(from, from - 1);
          setModelAnimations({ update, up: from, down: from - 1 });
          break;
        }
        case 'delete': {
          // setModelAnimations({ update, remove: from });
          pricesFieldArray.remove(from);
          // setTimeout(() => pricesFieldArray.remove(from), 100);
          break;
        }
        case 'clone': {
          if (field) {
            const original: ClubEcommerceProductArticlePrice = getValues(
              `productArticle.pricing.prices.${fieldIndex}`,
            );
            const clone: ClubEcommerceProductArticlePrice = JSON.parse(
              JSON.stringify({
                ...original,
                _id: uuidGenerator.random(),
              }),
            );

            pricesFieldArray.insert(from + 1, clone as any);
            setModelAnimations({ update, clone: from });
            break;
          }
        }
      }
    },
    [getValues, pricesFieldArray],
  );

  return (
    <div className={clsx('grid gap-2', className)}>
      <div className="font-medium text-gray-500">{'TARIFS'}</div>
      <div className={clsx('grid sm:flex gap-4')}>
        <div className="grid gap-2">
          {pricesFieldArray.fields.map((field, fieldIndex) => {
            return (
              <EcommerceProductArticleEditorFormSettingsPricesItem
                key={`${field._id}-${fieldIndex}`}
                form={form}
                fieldArray={pricesFieldArray}
                field={field as any}
                fieldIndex={fieldIndex}
                className={clsx(
                  isRecentAnimationUpdate &&
                    animations?.up === fieldIndex &&
                    'animate-move-up',
                  isRecentAnimationUpdate &&
                    animations?.down === fieldIndex &&
                    'animate-move-down',
                  isRecentAnimationUpdate &&
                    animations?.clone === fieldIndex &&
                    'animate-create',
                  isRecentAnimationUpdate &&
                    animations?.remove === fieldIndex &&
                    'animate-remove',
                )}
                onClick={(action) => onClick(field, fieldIndex, action)}
              />
            );
          })}
          <AppButton
            className="my-4"
            size="normal"
            icon={AppHeroIcons.actionAdd}
            color="primary-outline-light"
            onClick={() => {
              pricesFieldArray.append({
                _id: '',
                label: 'Tarif normal',
              } as any);
            }}
          >
            Ajouter un tarif
          </AppButton>
        </div>
      </div>
    </div>
  );
};
