import {
  FREE_DIVE_CERTIFICATIONS_REFERENCES,
  FreeDiveCertificationReference,
} from '@mabadive/app-common-model';
import { useMemo } from 'react';
import { ValueLabel } from 'src/business/club/modules/_common/form/components/ValueLabel.model';

export function useFreeDiveCertificationOptions(): ValueLabel<
  FreeDiveCertificationReference,
  string
>[] {
  return useMemo(
    () =>
      FREE_DIVE_CERTIFICATIONS_REFERENCES.map((reference) => ({
        value: reference,
        label: reference?.toUpperCase(),
      })),
    [],
  );
}
