/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { ConfigAssistantProgressBar_Step } from './ConfigAssistantProgressBar_Step';
import { ConfigAssistantProgressStep } from './model';

export type ConfigAssistantProgressBar_LocalStep = {
  name: string;
  href: string;
  status: 'complete' | 'current' | 'upcoming';
  step: ConfigAssistantProgressStep;
};

export function ConfigAssistantProgressBar({
  progressSteps,
  className,
}: {
  progressSteps: ConfigAssistantProgressStep[];
  className?: string;
}) {
  const steps: ConfigAssistantProgressBar_LocalStep[] = useMemo(() => {
    return progressSteps.map((step) => {
      return {
        name: step.page.label,
        href: step.page.url,
        status: step.progress?.enabled
          ? step.progress?.configSet
            ? 'complete'
            : 'current'
          : 'upcoming',
        step,
      };
    });
  }, [progressSteps]);

  return (
    <nav className={className} aria-label="Progress">
      <ol className="flex items-center">
        {steps.map((step, stepIdx) => (
          <ConfigAssistantProgressBar_Step
            key={step.name}
            className={clsx(
              stepIdx !== steps.length - 1 ? 'pr-8 sm:pr-20' : '',
              'relative',
            )}
            step={step}
          />
        ))}
      </ol>
    </nav>
  );
}
