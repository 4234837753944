/* eslint-disable @typescript-eslint/no-unused-vars */
import { ProMultiOperationPayload } from '@mabadive/app-common-model';
import { useCallback, useMemo, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { clubMassiveUpdatorClient } from 'src/business/_core/data/app-operation';
import { useRedirect } from 'src/business/_core/data/hooks';
import { useAutoFocus } from 'src/business/_core/modules/layout';
import { confirmDialog } from 'src/business/_core/modules/layout/components/ConfirmDialog/confirmDialog.service';
import { authenticationClient } from 'src/business/auth/services';
import { useClubResume } from 'src/business/club/data/hooks';
import {
  DiverLinkParametersToReplace,
  useDiverLinkParametersToReplace,
} from 'src/business/club/modules/club-diver-participant/pages/DiverBookingPage/components/BookingCustomerConfigEditDialog/BookingCustomerConfigEditDialogPanel2Links/components/useDiverLinkParametersToReplace.hook';
import {
  CustomerSpaceConfigDialogInitialState,
  CustomerSpaceConfigFormModel,
} from './model';
import { buildCustomerSpaceConfigPersistPayload } from './useCustomerSpaceConfigDialogActionPersist.hook';

export function useCustomerSpaceConfigDialogLocalState({
  initialState,
}: {
  initialState: CustomerSpaceConfigDialogInitialState;
}) {
  const autoFocus = useAutoFocus();
  const redirectTo = useRedirect();

  const clubResume = useClubResume();
  const clubReference = clubResume.reference;

  const form = useForm<CustomerSpaceConfigFormModel>({
    values: initialState.defaultValue,
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const diverLinkParametersToReplace: DiverLinkParametersToReplace =
    useDiverLinkParametersToReplace({
      firstName: 'James',
      lastName: 'La Plongée',
      url: 'https://diver.mabadive.com/xxx',
    });

  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const formValue = useWatch({
    control,
  });
  const hasFormChanges = useMemo(
    () => form && (formState.isDirty || formState.isValid),
    [form, formState.isDirty, formState.isValid],
  );
  const [linkGenerationInProgress, setLinkGenerationInProgress] =
    useState(false);

  const generateNewCustomerAccountLink = useCallback(async () => {
    if (!linkGenerationInProgress) {
      setLinkGenerationInProgress(true);
      try {
        const payload: ProMultiOperationPayload =
          buildCustomerSpaceConfigPersistPayload({
            formValue: formValue as CustomerSpaceConfigFormModel,
            initialValue: initialState.defaultValue,
          });
        const publicAccoutCreationLink: string =
          await authenticationClient.createCustomerSpaceNewCustomerAccountLink({
            payload,
          });
        setValue(
          'clubSettings.customer.publicAccoutCreationLink',
          publicAccoutCreationLink,
        );
        return publicAccoutCreationLink;
      } finally {
        setLinkGenerationInProgress(false);
      }
    }
  }, [
    formValue,
    initialState.defaultValue,
    linkGenerationInProgress,
    setValue,
  ]);

  const expireNewCustomerAccountLink = useCallback(async () => {
    if (!linkGenerationInProgress) {
      if (
        await confirmDialog.confirmPromise({
          title: 'Supprimer définitivement le lien d\'inscription',
          message:
            'Le lien actuel ne fonctionnera plus, mais vous pourrez en créer un autre',
          type: 'noYesDanger',
        })
      ) {
        try {
          setLinkGenerationInProgress(true);
          const publicAccoutCreationLink: string =
            await authenticationClient.expireCustomerSpaceNewCustomerAccountLink();
          setValue('clubSettings.customer.publicAccoutCreationLink', undefined);
          return publicAccoutCreationLink;
        } finally {
          setLinkGenerationInProgress(false);
        }
      }
    }
  }, [linkGenerationInProgress, setValue]);

  const persistChanges = useCallback(async () => {
    const payload: ProMultiOperationPayload =
      buildCustomerSpaceConfigPersistPayload({
        formValue: formValue as CustomerSpaceConfigFormModel,
        initialValue: initialState.defaultValue,
      });

    if (payload) {
      return clubMassiveUpdatorClient.update(payload);
    }
  }, [formValue, initialState.defaultValue]);

  return {
    form,
    hasFormChanges,
    initialState,
    actions: {
      generateNewCustomerAccountLink,
      expireNewCustomerAccountLink,
      persistChanges,
    },
    data: {
      linkGenerationInProgress,
      diverLinkParametersToReplace,
    },
  };
}

export type CustomerSpaceConfigDialogLocalState = ReturnType<
  typeof useCustomerSpaceConfigDialogLocalState
>;
