/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { FieldArrayWithId, UseFormReturn, useWatch } from 'react-hook-form';
import { AppSingleAutocomplete2RHF } from 'src/lib/form';
import { AppFormControlRHF } from 'src/lib/form/components/AppFormControl/AppFormControlRHF';

import { useSupervisionOptions } from 'src/pages/_components/options';
import { ClubSettingsProductOffersFormModel } from '../../_model';

export const ClubSettingsProductOffersFormOffer_Explo_Supervision = ({
  form,
  offerField,
  offerFieldIndex,
}: {
  form: UseFormReturn<ClubSettingsProductOffersFormModel>;
  offerField: FieldArrayWithId<
    ClubSettingsProductOffersFormModel,
    'offers',
    'id'
  >;
  offerFieldIndex: number;
}) => {
  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const i = offerFieldIndex;

  const [diveMode] = useWatch({
    control,
    name: [`offers.${i}.productPackage.diveAttributes.diveMode`],
  });

  const supervisionOptions = useSupervisionOptions(diveMode);

  return diveMode && supervisionOptions.length > 1 ? (
    <AppFormControlRHF
      className={'w-full col-span-2'}
      label="Encadrement"
      control={control}
      name={`offers.${i}.productPackage.diveAttributes.supervision`}
      required={true}
      renderComponent={(props) => (
        <AppSingleAutocomplete2RHF {...props} options={supervisionOptions} />
      )}
    />
  ) : null;
};
