/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { useWatch } from 'react-hook-form';
import { useClubResume } from 'src/business/club/data/hooks';
import { AppInputBooleanRHF, AppInputRHF } from 'src/lib/form';
import { AppFormControlRHF } from 'src/lib/form/components/AppFormControl/AppFormControlRHF';
import { ClubSettingsCustomerFormFieldsEditPageLocalState } from './useClubSettingsCustomerFormFieldsEditPageLocalState.hook';

export const ClubSettingsCustomerFormFieldsEditForm_Booking_CustomBooleans = ({
  localState,
}: {
  localState: ClubSettingsCustomerFormFieldsEditPageLocalState;
}) => {
  const { form } = localState;
  const { control, setValue } = form;

  const clubResume = useClubResume();

  const [bookingCustomBoolean1, bookingCustomBoolean2, bookingCustomBoolean3] =
    useWatch({
      control,
      name: [
        'clubSettings.customer.booking.customBoolean1',
        'clubSettings.customer.booking.customBoolean2',
        'clubSettings.customer.booking.customBoolean3',
      ],
    });

  return (
    <>
      <div className="flex gap-4 flex-wrap">
        <AppFormControlRHF
          control={control}
          name="clubSettings.customer.booking.customBoolean1"
          renderComponent={(props) => (
            <AppInputBooleanRHF
              {...props}
              type="checkbox"
              description={'Case à cocher perso N°1'}
            />
          )}
        />
        {bookingCustomBoolean1 && (
          <AppFormControlRHF
            className="max-x-40"
            control={control}
            name="clubSettings.customer.booking.customBoolean1Label"
            label="Nom du champ"
            required={true}
            renderComponent={(props) => <AppInputRHF {...props} />}
          />
        )}
      </div>
      {(bookingCustomBoolean1 || bookingCustomBoolean2) && (
        <div className="flex gap-4 flex-wrap">
          <AppFormControlRHF
            control={control}
            name="clubSettings.customer.booking.customBoolean2"
            renderComponent={(props) => (
              <AppInputBooleanRHF
                {...props}
                type="checkbox"
                description={'Case à cocher perso N°2'}
              />
            )}
          />
          {bookingCustomBoolean2 && (
            <AppFormControlRHF
              className="max-x-40"
              control={control}
              name="clubSettings.customer.booking.customBoolean2Label"
              label="Nom du champ"
              required={true}
              renderComponent={(props) => <AppInputRHF {...props} />}
            />
          )}
        </div>
      )}
      {((bookingCustomBoolean1 && bookingCustomBoolean2) ||
        bookingCustomBoolean3) && (
        <div className="flex gap-4 flex-wrap">
          <AppFormControlRHF
            control={control}
            name="clubSettings.customer.booking.customBoolean3"
            renderComponent={(props) => (
              <AppInputBooleanRHF
                {...props}
                type="checkbox"
                description={'Case à cocher perso N°3'}
              />
            )}
          />
          {bookingCustomBoolean3 && (
            <AppFormControlRHF
              className="max-x-40"
              control={control}
              name="clubSettings.customer.booking.customBoolean3Label"
              label="Nom du champ"
              required={true}
              renderComponent={(props) => <AppInputRHF {...props} />}
            />
          )}
        </div>
      )}
    </>
  );
};
