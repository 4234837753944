/* eslint-disable @typescript-eslint/no-unused-vars */
import { ClubDiveSiteType } from '@mabadive/app-common-model';
import React, { useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { ValueLabel } from 'src/business/club/modules/_common/form/components/ValueLabel.model';
import {
  AppEmojiPickerPopoverRHF,
  AppInputBooleanSwitchRHF,
  AppInputRHF,
  AppSingleAutocomplete2RHF,
} from 'src/lib/form';
import { AppFormControlRHF } from 'src/lib/form/components/AppFormControl';
import { AppSingleSelect2HeadlessUIRHF } from 'src/lib/form/components/AppSingleSelect/AppSingleSelect2HeadlessUIRHF';
import {
  useDiveModesOptions,
  useDiverTechnicalLevelOptions,
  useDiveSitesTypesOptions,
} from 'src/pages/_components/options';
import { AppMultiCheckboxesTailwindRHF } from '../../../../../../lib/form/components/AppMultiCheckboxes/AppMultiCheckboxesTailwindRHF';
import { DiveSiteFormLocalState } from '../model';

export const DiveSiteEditForm = ({
  localState,
}: {
  localState: DiveSiteFormLocalState;
}) => {
  const { form, diveSiteGroups } = localState;
  const { control, setValue } = form;

  const diveSitesGroupsOptions = useMemo(
    () =>
      diveSiteGroups.map((g) => {
        const option: ValueLabel<string> = {
          label: g.name,
          value: g._id,
        };
        return option;
      }),
    [diveSiteGroups],
  );

  const diveSitesTypesOptions: ValueLabel<ClubDiveSiteType>[] =
    useDiveSitesTypesOptions();

  const [name, emojiId] = useWatch({
    control,
    name: ['name', 'emojiId'],
  });

  const technicalLevelOptions = useDiverTechnicalLevelOptions();
  const diveModeOptions = useDiveModesOptions({
    format: 'long-label',
  });

  return (
    <div className="px-5">
      <h2 className="text-left text-sm sm:text-lg leading-6 py-2 font-medium text-app-blue uppercase">
        Site de plongée{' '}
        <span className="font-bold text-app-orange">
          {name?.trim()?.length > 0 && `"${name.toUpperCase()}"`}
        </span>
      </h2>
      <div className={'grid gap-4'}>
        <div className={'grid sm:grid-cols-2 lg:grid-cols-4 gap-4'}>
          <div className="w-full flex gap-4">
            <AppFormControlRHF
              className={'flex-grow w-full'}
              label="Nom"
              control={control}
              name={'name'}
              renderComponent={(props) => (
                <AppInputRHF {...props} fullWidth type="text" />
              )}
            />

            <AppFormControlRHF
              label={'Icône Emoji'}
              control={control}
              name={'emojiId'}
              renderComponent={(props) => (
                <AppEmojiPickerPopoverRHF {...props} />
              )}
            />
          </div>
          <AppFormControlRHF
            className={'w-full'}
            label={'Groupe'}
            required={true}
            control={control}
            name={'groupId'}
            renderComponent={(props) => (
              <AppSingleSelect2HeadlessUIRHF
                {...props}
                theme="material-ui"
                disableClearButton={true}
                className="w-full text-gray-600"
                options={diveSitesGroupsOptions}
              />
            )}
          />
        </div>
        <div className={'grid sm:grid-cols-2 lg:grid-cols-4 gap-4'}>
          <AppFormControlRHF
            className={'w-full'}
            label={'Type'}
            required={false}
            control={control}
            name={'type'}
            renderComponent={(props) => (
              <AppSingleAutocomplete2RHF
                {...props}
                // theme="material-ui"
                // disableClearButton={true}
                className="w-full text-gray-600"
                options={diveSitesTypesOptions}
              />
            )}
          />
          <AppFormControlRHF
            className={'w-full'}
            label="Tombant"
            control={control}
            name={'wall'}
            renderComponent={(props) => <AppInputBooleanSwitchRHF {...props} />}
          />
          <AppFormControlRHF
            className={'w-full'}
            label="Épave"
            control={control}
            name={'wreck'}
            renderComponent={(props) => <AppInputBooleanSwitchRHF {...props} />}
          />
          <AppFormControlRHF
            className={'w-full'}
            label="Artificiel"
            control={control}
            name={'manMade'}
            renderComponent={(props) => <AppInputBooleanSwitchRHF {...props} />}
          />
        </div>
        <div className={'grid sm:grid-cols-2 lg:grid-cols-4 gap-4'}>
          <AppFormControlRHF
            className={'w-full'}
            label="Numéro"
            control={control}
            name={'ref'}
            renderComponent={(props) => (
              <AppInputRHF {...props} fullWidth type="text" />
            )}
          />
          <AppFormControlRHF
            className={'w-full'}
            label="Distance (nmi)"
            control={control}
            name={'distance'}
            renderComponent={(props) => (
              <AppInputRHF {...props} fullWidth type="number" />
            )}
          />
          <AppFormControlRHF
            className={'w-full'}
            label="Profondeur mini (m)"
            control={control}
            name={'privateDetails.minDepth'}
            renderComponent={(props) => (
              <AppInputRHF {...props} fullWidth type="number" />
            )}
          />
          <AppFormControlRHF
            className={'w-full'}
            label="Profondeur maxi (m)"
            control={control}
            name={'depth'}
            renderComponent={(props) => (
              <AppInputRHF {...props} fullWidth type="number" />
            )}
          />
        </div>
        <div className={'grid gap-4'}>
          <AppFormControlRHF
            label={'Niveau technique'}
            control={control}
            name={'privateDetails.divingTechnicalLevels'}
            renderComponent={(props) => (
              <AppMultiCheckboxesTailwindRHF
                {...props}
                className="w-full"
                options={technicalLevelOptions}
                direction="horizontal"
                labelPosition="right"
              />
            )}
          />
          <AppFormControlRHF
            label={'Prestation associée'}
            control={control}
            name={'privateDetails.diveModes'}
            renderComponent={(props) => (
              <AppMultiCheckboxesTailwindRHF
                {...props}
                className="w-full"
                options={diveModeOptions}
                direction="horizontal"
                labelPosition="right"
              />
            )}
          />
        </div>
        <AppFormControlRHF
          className={'w-full'}
          label="Description"
          control={control}
          name={'description'}
          renderComponent={(props) => (
            <AppInputRHF {...props} fullWidth rowsMin={2} multiline />
          )}
        />
      </div>
    </div>
  );
};
