/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  BookingParticipantEditorFormModel,
  BookingParticipantEditorFormModelClubParticipant,
  BookingSessionParticipant,
  ClubParticipant,
  ClubParticipantAutoSupervisedDetails,
  ClubParticipantDetails,
  ClubParticipantEquipment,
  EquipmentDescription,
  MultipleDiveSessionNumber,
  ParticipantActionDescription,
  ParticipantActionUser,
  ParticipantBookingStatus,
} from '@mabadive/app-common-model';
import { diveModeAnalyser } from '@mabadive/app-common-services';

export const participantFormParser = {
  parseFormValueClubParticipant,
  updateBookingState,
};
function parseFormValueClubParticipant({
  formValue,
  existingParticipant,
  sessionsCount,
}: {
  formValue: BookingParticipantEditorFormModel;
  existingParticipant?: Pick<
    ClubParticipant,
    'bookingHistory' | 'bookingState'
  >;
  sessionsCount: number;
}): {
  clubParticipant: Partial<ClubParticipant>;
  bookingSessionParticipant: Partial<BookingSessionParticipant>;
} {
  const formValueParticipant: BookingParticipantEditorFormModelClubParticipant =
    formValue.clubParticipant;

  const diveMode = formValueParticipant.diveMode;

  const equipment: ClubParticipantEquipment =
    formValueParticipant.equipment ?? {};

  const details: ClubParticipantDetails = formValueParticipant.details ?? {};
  const autoSupervisedDetails: ClubParticipantAutoSupervisedDetails =
    formValueParticipant.diveMode === 'autoSupervised'
      ? formValueParticipant.autoSupervisedDetails
      : undefined;
  if (details?.multiSessionsPresenceNumbers && sessionsCount === 1) {
    details.multiSessionsPresenceNumbers = undefined;
  }
  const divesCount = (details?.multiSessionsPresenceNumbers?.length ??
    sessionsCount) as MultipleDiveSessionNumber;

  const isScubaDivingEquipmentRequired =
    diveModeAnalyser.hasScubaDivingEquipments(diveMode);

  parseEquipment({
    equipment: equipment,
    isScubaDivingEquipmentRequired,
  });

  const clubParticipant: Pick<
    ClubParticipant,
    | 'firstDiveReference'
    | 'trainingReference'
    | 'certificationReference'
    | 'targetDeep'
    | 'gaz'
    | 'diveMode'
    | 'autoSupervisedDetails'
    | 'tags'
    | 'comment'
    | 'bookingHistory'
    | 'bookingState'
    | 'diveSessionGroupId'
    | 'diveSessionGroupDiveGuide'
    | 'certificationDeepDiver'
    | 'aptitude'
    | 'details'
    | 'equipment'
    | 'divesCount'
  > = {
    // trainingReference: !diveType ? undefined : diveType.trainingReference,
    firstDiveReference:
      diveMode === 'first-dive'
        ? formValueParticipant.firstDiveReference
        : null,
    trainingReference:
      diveMode === 'training' || diveMode === 'theoretical-training'
        ? formValueParticipant.trainingReference
        : null,
    certificationReference:
      diveMode === 'supervised' ||
      diveMode === 'autonomous' ||
      diveMode === 'instructor'
        ? formValueParticipant.certificationReference
        : null,
    certificationDeepDiver: formValueParticipant.certificationDeepDiver,
    targetDeep: formValueParticipant.targetDeep,
    gaz: formValueParticipant.gaz,
    diveMode,
    aptitude: formValueParticipant.aptitude,
    tags: formValueParticipant.tags ?? [],
    autoSupervisedDetails,
    details,
    equipment,
    comment: formValueParticipant.comment,
    diveSessionGroupId: formValueParticipant.diveSessionGroupId,
    diveSessionGroupDiveGuide:
      formValueParticipant.diveSessionGroupDiveGuide ?? null,
    bookingHistory: undefined, // filled later in updateBookingState
    bookingState: undefined, // filled later in updateBookingState
    divesCount,
  };

  updateBookingState({
    newValue: formValueParticipant,
    updatedClubParticipant: clubParticipant,
    existingParticipant,
  });

  const bookingSessionParticipant: Partial<BookingSessionParticipant> = {
    participantBookingStatus: clubParticipant.bookingState?.value,
  };
  return {
    clubParticipant,
    bookingSessionParticipant,
  };
}

function parseEquipment({
  equipment,
  isScubaDivingEquipmentRequired,
}: {
  equipment: ClubParticipantEquipment;
  isScubaDivingEquipmentRequired: boolean;
}) {
  if (equipment) {
    equipment.fins = equipmentOrUndefined(equipment.fins);
    equipment.wetsuit = equipmentOrUndefined(equipment.wetsuit);
    if (isScubaDivingEquipmentRequired) {
      equipment.jacket = equipmentOrUndefined(equipment.jacket);
      equipment.scubaRegulator = equipmentOrUndefined(equipment.scubaRegulator);
      equipment.divingBoots = equipmentOrUndefined(equipment.divingBoots);
      equipment.mask = equipmentOrUndefined(equipment.mask);
      equipment.flashLight = equipmentOrUndefined(equipment.flashLight);
      equipment.computer = equipmentOrUndefined(equipment.computer);
      equipment.tank = equipmentOrUndefined(equipment.tank);
      equipment.compass = equipmentOrUndefined(equipment.compass);
      equipment.weighting = equipmentOrUndefined(equipment.weighting);
    } else {
      equipment.jacket = undefined;
      equipment.scubaRegulator = undefined;
      equipment.divingBoots = undefined;
      equipment.mask = undefined;
      equipment.flashLight = undefined;
      equipment.computer = undefined;
      equipment.tank = undefined;
      equipment.compass = undefined;
      equipment.weighting = undefined;
    }
  }
}

function trimOrNull(str: string): string {
  str = str?.trim();
  if (str) {
    return str;
  }
  return null; // c'est important de retourner null, sinon le jsonPatch sur des champs vide va envoyer des replace+null inutiles
}
function equipmentOrUndefined(eq: EquipmentDescription): EquipmentDescription {
  if (
    eq?.clubEquipped ||
    eq?.selfEquipped ||
    eq?.model1?.ref ||
    eq?.model2?.ref
  ) {
    return eq;
  }
}

function updateBookingState({
  newValue,
  updatedClubParticipant,
  existingParticipant,
}: {
  newValue: Pick<
    BookingParticipantEditorFormModelClubParticipant,
    'bookingStatus' | 'bookingComment'
  >;
  updatedClubParticipant: Pick<
    ClubParticipant,
    'bookingHistory' | 'bookingState'
  >;
  existingParticipant?: Pick<
    ClubParticipant,
    'bookingHistory' | 'bookingState'
  >;
}) {
  if (existingParticipant && existingParticipant.bookingState) {
    // booking state already exists
    if (
      existingParticipant.bookingState.value === newValue.bookingStatus &&
      existingParticipant.bookingState.comment === newValue.bookingComment
    ) {
      // no change (or keep original)
      updatedClubParticipant.bookingHistory = [
        ...existingParticipant.bookingHistory,
      ];
      updatedClubParticipant.bookingState = {
        ...existingParticipant.bookingState,
      };
    } else {
      // state changed: save history
      updatedClubParticipant.bookingHistory =
        existingParticipant.bookingHistory.concat([
          {
            ...existingParticipant.bookingState,
          },
        ]);
      updatedClubParticipant.bookingState =
        parseBookingStateFromValue(newValue);
    }
  } else {
    // create booking state
    updatedClubParticipant.bookingState = parseBookingStateFromValue(newValue);
    updatedClubParticipant.bookingHistory = [];
  }
}
function parseBookingStateFromValue(
  value: Pick<
    BookingParticipantEditorFormModelClubParticipant,
    'bookingStatus' | 'bookingComment'
  >,
): ParticipantActionDescription<ParticipantBookingStatus> {
  let bookingStateStatus: ParticipantBookingStatus;
  let bookingStateUser: ParticipantActionUser;
  if (value.bookingStatus === 'pending-club') {
    bookingStateStatus = 'pending';
    bookingStateUser = 'club';
  } else if (value.bookingStatus === 'pending-diver') {
    bookingStateStatus = 'pending';
    bookingStateUser = 'diver';
  } else if (value.bookingStatus === 'cancelled-club') {
    bookingStateStatus = 'cancelled';
    bookingStateUser = 'club';
  } else if (value.bookingStatus === 'cancelled-diver') {
    bookingStateStatus = 'cancelled';
    bookingStateUser = 'diver';
  } else if (value.bookingStatus === 'confirmed') {
    bookingStateStatus = 'confirmed';
    bookingStateUser = undefined;
  }
  return {
    date: new Date(),
    comment: value.bookingComment,
    value: bookingStateStatus,
    user: bookingStateUser,
  };
}
