import { BookingMember, ClubResume } from '@mabadive/app-common-model';
import { useMemo } from 'react';
import { useClubResume } from 'src/business/club/data/hooks';
import { BookingMemberCardAttributeName } from './BookingMemberCardAttributeName.type';
import { ClubDiverCardDiver } from './ClubDiverCardAttribute';
import { ClubDiverCardAttributeName } from './ClubDiverCardAttributeName.type';

export function useClubDiverCardAttributes({
  diver,
  bookingMember,
  additionalClubDiverAttributes = [],
  additionalBookingMemberAttributes = [],
}: {
  diver: ClubDiverCardDiver;
  bookingMember?: BookingMember;
  additionalClubDiverAttributes?: ClubDiverCardAttributeName[];
  additionalBookingMemberAttributes?: BookingMemberCardAttributeName[];
}): {
  generalAttributes: (
    | ClubDiverCardAttributeName
    | BookingMemberCardAttributeName
  )[];
  diverAttributes: (
    | ClubDiverCardAttributeName
    | BookingMemberCardAttributeName
  )[];
} {
  const clubResume = useClubResume();
  const customerSettings = clubResume.clubSettings?.customer;

  return useMemo(
    () =>
      buildVisibleAttributes({
        diver,
        bookingMember,
        additionalClubDiverAttributes,
        additionalBookingMemberAttributes,
        clubResume,
      }),
    [
      diver,
      bookingMember,
      additionalClubDiverAttributes,
      additionalBookingMemberAttributes,
      clubResume,
    ],
  );
}

function buildVisibleAttributes({
  diver,
  bookingMember,
  clubResume,
  additionalClubDiverAttributes,
  additionalBookingMemberAttributes,
}: {
  diver: ClubDiverCardDiver;
  bookingMember?: BookingMember;
  clubResume: ClubResume;
  additionalClubDiverAttributes: ClubDiverCardAttributeName[];
  additionalBookingMemberAttributes: BookingMemberCardAttributeName[];
}) {
  if (!diver) {
    return { generalAttributes: [], diverAttributes: [] };
  }
  const customerSettings = clubResume.clubSettings?.customer;
  const generalAttributesNames: ClubDiverCardAttributeName[] =
    additionalClubDiverAttributes.concat([
      'age',
      'size',
      'weight',
      'shoeSize',
      // 'emailAddress', // already displayed as "header button"
      // 'phoneNumber', // already displayed as "header button"
      'homeAddress',
      'comment',
      'residentType',
      'externalRef',
      'identificationNumber',
      'isCustomer',
      'isMember',
      'memberStudent',
      'membershipDate',
      'customField1',
      'customField2',
      'customField3',
      'customBoolean1',
      'customBoolean2',
      'customBoolean3',
      'language',
    ]);
  const generalAttributes = generalAttributesNames.filter((attr) =>
    isNotEmptyClubDiverCardAttribute(diver, attr),
  );

  const diverAttributesNames: ClubDiverCardAttributeName[] = [
    'mainCertificationReference',
    'freeDiveCertificationReference',
    'safetyCertificationReference',
    'medicalCertificateStatus',
    'orgsMembers',
    'divingComment',
  ];
  let diverAttributes: (
    | ClubDiverCardAttributeName
    | BookingMemberCardAttributeName
  )[] = diverAttributesNames.filter((attr) =>
    isNotEmptyClubDiverCardAttribute(diver, attr),
  );
  const bookingMemberAttributesNames: BookingMemberCardAttributeName[] =
    additionalBookingMemberAttributes;
  if (customerSettings?.diving?.insurance) {
    bookingMemberAttributesNames.push('insurance');
  }
  if (
    customerSettings?.diving?.medicalFormChecked ||
    customerSettings?.diving?.medicalFormDate
  ) {
    bookingMemberAttributesNames.push('medicalFormStatus');
  }

  diverAttributes = diverAttributes.concat(
    bookingMemberAttributesNames.filter((attr) => {
      if (attr === 'insurance') {
        const hasInsurance = bookingMember?.details?.insurance?.hasInsurance;
        return hasInsurance === true || hasInsurance === false;
      }
      if (attr === 'medicalFormStatus') {
        return (
          bookingMember?.details?.medicalForm?.filled ||
          bookingMember?.details?.medicalForm?.date
        );
      }
      return false;
    }),
  );

  return { generalAttributes, diverAttributes };
}

function isNotEmptyClubDiverCardAttribute(
  diver: ClubDiverCardDiver,
  attributeName: ClubDiverCardAttributeName,
) {
  if (!diver) {
    return false;
  }

  switch (attributeName) {
    case 'age': {
      return !!diver.birthdate;
    }
    case 'fullName': {
      return !!diver.firstName || !!diver.lastName;
    }
    case 'mainCertificationReference': {
      return (
        diver.divingCertification1?.mainCertificationReference ||
        diver.divingCertification1?.gazCertificationReference ||
        diver.divingCertification2?.mainCertificationReference ||
        diver.divingCertification2?.gazCertificationReference
      );
    }
    case 'safetyCertificationReference': {
      return (
        diver.divingCertification1?.safetyCertificationReferences?.length ||
        diver.divingCertification2?.safetyCertificationReferences?.length
      );
    }
    case 'freeDiveCertificationReference': {
      return diver.details?.freeDiveCertification1
        ?.freeDiveCertificationReference;
    }
    case 'shoeSize': {
      return diver.details?.measurements?.shoeSize;
    }
    case 'customField1':
    case 'customField2':
    case 'customField3':
    case 'customBoolean1':
    case 'customBoolean2':
    case 'customBoolean3':
    case 'externalRef':
    case 'isCustomer':
    case 'identificationNumber': {
      return diver.details?.[attributeName];
    }
    case 'medicalCertificateStatus': {
      return diver.medicalCertificateDate || diver.medicalCertificateChecked;
    }
    case 'isMember': {
      return (
        diver.details?.membership?.isMember ||
        diver.details?.membership?.memberRef?.trim()?.length > 0
      );
    }
    case 'membershipDate': {
      return diver.details?.membership?.membershipDate;
    }
    case 'language': {
      return diver.languageCode;
    }
    case 'memberStudent': {
      return (
        diver.details?.membership?.isStudent ||
        diver.details?.membership?.studentDescription?.trim()?.length > 0
      );
    }
  }
  return !!diver[attributeName];
}
