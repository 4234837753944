/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  BOOKING_GROUP_TYPES,
  BookingGroupType,
  ClubPurchasePackagePaymentStatus,
  DiveServicePurchaseStatus,
  PURCHASE_VALIDITY_STATUSES,
} from '@mabadive/app-common-model';
import {
  bookingGroupTypeFormatter,
  diveServicePurchaseStatusFormatter,
} from '@mabadive/app-common-services';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { PurchaseResumeCollectionCriteriaPurchaseType } from 'src/business/_core/data/store-repository';
import { ValueLabel } from 'src/business/club/modules/_common/form/components/ValueLabel.model';
import { uiStore } from '../../../business/_core/store';
import { useClubPurchasePackagePaymentStatusOptionsWithPending } from '../../_components/options';
import { PurchaseListPageViewParameters } from './model/PurchaseListPageViewParameters.type';
import { purchasesListPageUrlManager } from './services';

export type MonthId = -1 | 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11;

const VALIDITY_STATUS_OPTIONS = PURCHASE_VALIDITY_STATUSES.map((value) => {
  const option: ValueLabel<DiveServicePurchaseStatus> = {
    label: diveServicePurchaseStatusFormatter.formatStatus(value).toUpperCase(),
    value,
  };
  return option;
});

export const PURCHASE_TYPE_OPTIONS: ValueLabel<
  PurchaseResumeCollectionCriteriaPurchaseType | 'all'
>[] = [
  {
    value: 'training',
    label: 'Formation',
  },
  {
    value: 'package',
    label: 'Forfait',
  },
  {
    value: 'store',
    label: 'Boutique',
  },
];

const BOOKING_GROUP_TYPES_OPTIONS = BOOKING_GROUP_TYPES.map((value) => {
  const option: ValueLabel<BookingGroupType> = {
    label: bookingGroupTypeFormatter
      .format(value, {
        format: 'long',
      })
      .toUpperCase(),
    value,
  };
  return option;
});
const BOOKING_GROUP_TYPES_OPTIONS_ALL = (
  [
    {
      label: 'TYPE',
      value: 'all',
    },
  ] as ValueLabel<BookingGroupType | 'all'>[]
).concat(BOOKING_GROUP_TYPES_OPTIONS);

const DEFAULT_VIEW_PARAMETERS: PurchaseListPageViewParameters = {
  searchTextContactName: '',
  searchTextOfferName: '',
  validityStatus: undefined,
  paymentStatus: undefined,
  purchaseType: undefined,
  bookingAgencyId: undefined,
  beginPurchaseDate: undefined,
  endPurchaseDate: undefined,
};

export function useClubPurchasesListPageFilters() {
  const initialViewParameters = useMemo(() => {
    const queryParams: PurchaseListPageViewParameters =
      purchasesListPageUrlManager.getQueryParameters();

    const storeParams: Partial<PurchaseListPageViewParameters> =
      uiStore.purchasesListViewParameters.getSnapshot();

    const initialViewParameters: PurchaseListPageViewParameters = {
      searchTextContactName: queryParams?.searchTextContactName ?? '', // on ne conserve pas la recherche dans le store
      searchTextOfferName: queryParams?.searchTextOfferName ?? '', // on ne conserve pas la recherche dans le store
      validityStatus:
        queryParams?.validityStatus ??
        storeParams?.validityStatus ??
        DEFAULT_VIEW_PARAMETERS?.validityStatus,
      paymentStatus:
        queryParams?.paymentStatus ??
        storeParams?.paymentStatus ??
        DEFAULT_VIEW_PARAMETERS?.paymentStatus,
      purchaseType:
        queryParams?.purchaseType ??
        storeParams?.purchaseType ??
        DEFAULT_VIEW_PARAMETERS?.purchaseType,
      bookingAgencyId:
        queryParams?.bookingAgencyId ??
        storeParams?.bookingAgencyId ??
        DEFAULT_VIEW_PARAMETERS?.bookingAgencyId,
      beginPurchaseDate:
        queryParams?.beginPurchaseDate ??
        storeParams?.beginPurchaseDate ??
        DEFAULT_VIEW_PARAMETERS?.beginPurchaseDate,
      endPurchaseDate:
        queryParams?.endPurchaseDate ??
        storeParams?.endPurchaseDate ??
        DEFAULT_VIEW_PARAMETERS?.endPurchaseDate,
    };
    return initialViewParameters;
  }, []);

  const [viewParameters, setViewParameters] =
    useState<PurchaseListPageViewParameters>(initialViewParameters);

  const hasAnyMainViewParameter =
    viewParameters.searchTextContactName?.trim()?.length > 0 ||
    viewParameters.searchTextOfferName?.trim()?.length > 0;

  const hasAnyExtraViewParameter =
    !!viewParameters.validityStatus ||
    !!viewParameters.purchaseType ||
    !!viewParameters.paymentStatus ||
    !!viewParameters.bookingAgencyId ||
    !!viewParameters.beginPurchaseDate ||
    !!viewParameters.endPurchaseDate;
  const hasAnyParameter = hasAnyMainViewParameter || hasAnyExtraViewParameter;

  const history = useHistory();
  useEffect(() => {
    // update url if criteria changes
    const searchParams: string[] =
      purchasesListPageUrlManager.buildSearchParams(viewParameters);

    history.replace({
      pathname: window.location.pathname,
      search: searchParams?.length ? `?${searchParams.join('&')}` : undefined,
    });
  }, [history, viewParameters]);

  useEffect(() => {
    // update store
    uiStore.purchasesListViewParameters.set(viewParameters);
  }, [viewParameters]);

  const setSearchTextContactName = useCallback(
    (searchTextContactName: string) =>
      setViewParameters({ ...viewParameters, searchTextContactName }),
    [viewParameters],
  );
  const setSearchTextOfferName = useCallback(
    (searchTextOfferName: string) =>
      setViewParameters({ ...viewParameters, searchTextOfferName }),
    [viewParameters],
  );

  const setValidityStatus = useCallback(
    (validityStatus: DiveServicePurchaseStatus) =>
      setViewParameters({ ...viewParameters, validityStatus }),
    [viewParameters],
  );

  const setPaymentStatus = useCallback(
    (paymentStatus: ClubPurchasePackagePaymentStatus | 'pending') =>
      setViewParameters({ ...viewParameters, paymentStatus }),
    [viewParameters],
  );

  const setPurchaseType = useCallback(
    (purchaseType: PurchaseResumeCollectionCriteriaPurchaseType | 'all') =>
      setViewParameters({ ...viewParameters, purchaseType }),
    [viewParameters],
  );

  const setBookingAgencyId = useCallback(
    (bookingAgencyId: string) =>
      setViewParameters({ ...viewParameters, bookingAgencyId }),
    [viewParameters],
  );

  const setBeginPurchaseDate = useCallback(
    (beginPurchaseDate: Date) =>
      setViewParameters({ ...viewParameters, beginPurchaseDate }),
    [viewParameters],
  );

  const setEndPurchaseDate = useCallback(
    (endPurchaseDate: Date) =>
      setViewParameters({ ...viewParameters, endPurchaseDate }),
    [viewParameters],
  );

  const resetFilters = useCallback(() => {
    setViewParameters(DEFAULT_VIEW_PARAMETERS);
  }, []);
  const paymentStatusOptions =
    useClubPurchasePackagePaymentStatusOptionsWithPending();

  return {
    state: {
      options: {
        validityStatusOptions: VALIDITY_STATUS_OPTIONS,
        purchaseTypeOptions: PURCHASE_TYPE_OPTIONS,
        paymentStatusOptions,
      },
      viewParameters,
      hasAnyExtraViewParameter,
      hasAnyMainViewParameter,
      hasAnyParameter,
    },
    actions: {
      setSearchTextContactName,
      setSearchTextOfferName,
      setValidityStatus,
      setPaymentStatus,
      setPurchaseType,
      setBookingAgencyId,
      setBeginPurchaseDate,
      setEndPurchaseDate,
      resetFilters,
    },
  };
}

export type ClubPurchasesListPageFilters = ReturnType<
  typeof useClubPurchasesListPageFilters
>;
