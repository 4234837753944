import { ClubDiverCertificationLabels } from '@mabadive/app-common-model';
import {
  dateService,
  diverCertificationFormatter,
  nameFormatter,
} from '@mabadive/app-common-services';
import React, { useMemo } from 'react';
import { ResidentTypeLabelBadge } from '../../DiverBookingPage/components/ResidentTypeLabelBadge';
import { PRO_BookingMemberFull } from '../../DiverBookingPage/models';
import { DiveCenterBookingMemberCertification } from './DiveCenterBookingMemberCertification';

export function DiveCenterBookingMemberNameLabel({
  bookingMemberFull,
  isUniqueMember,
  displayResidentType,
  className = '',
}: {
  bookingMemberFull: PRO_BookingMemberFull;
  isUniqueMember: boolean;
  displayResidentType?: boolean;
  className?: string;
}) {
  const diver = bookingMemberFull?.diver;
  const age = useMemo(
    () =>
      bookingMemberFull.diver.birthdate
        ? dateService.getAge(bookingMemberFull.diver.birthdate)
        : bookingMemberFull.diver.age,
    [bookingMemberFull.diver.age, bookingMemberFull.diver.birthdate],
  );

  const certificationLabels: ClubDiverCertificationLabels = useMemo(
    () =>
      diverCertificationFormatter.formatCertificationLabels({
        diver,
        format: 'short',
      }),
    [diver],
  );

  return (
    <div className={`flex flex-col justify-center text-left ${className}`}>
      <p
        className={`leading-4 font-bold text-sm  text-app-blue whitespace-nowrap ${
          bookingMemberFull?.bookingMember?.details?.creationStatus === 'draft'
            ? 'italic'
            : ''
        }`}
      >
        {nameFormatter.formatFullName(bookingMemberFull.diver, {
          format: 'firstName-first',
        })}{' '}
        {age > 0 && <span className="font-bold text-gray-500">{age} ans</span>}
      </p>
      <p className="leading-4 flex gap-2">
        {certificationLabels.hasCertification && (
          <span className="uppercase text-gray-600 font-bold">
            <DiveCenterBookingMemberCertification
              certificationLabels={certificationLabels}
            />
          </span>
        )}
        {displayResidentType && (
          <ResidentTypeLabelBadge
            residentType={diver?.residentType}
            className="text-xs"
          />
        )}
        {bookingMemberFull.diver._id ===
          bookingMemberFull.booking.bookingContactDiverId &&
          !isUniqueMember && (
            <span className="uppercase px-0.5 text-app-orange font-medium text-app-xxs">
              contact résa
            </span>
          )}
      </p>
    </div>
  );
}
