/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  DiveSession,
  DiveSessionResumeFull,
  DiveSessionTimeDayPeriod,
} from '@mabadive/app-common-model';
import {
  clubDiveSessionThemeBuilder,
  dateService,
  diveSessionTitleBuilder,
} from '@mabadive/app-common-services';
import React, { useMemo } from 'react';
import { useMediaSizeTailwind } from 'src/business/_core/modules/layout';
import { AppIconsUI } from 'src/business/_core/modules/layout/icons';
import { AppEmojiIcon } from 'src/lib/form/components/AppEmojiPicker';
import { ClubPlanningPanelSessionDisplayStyle } from '../../../model';
import { CalendarSessionWidthMode } from '../session-common';

export const DiveSessionSimpleCardTime = ({
  session,
  selectionColor,
  onClick,
  widthMode,
  displaySessionIndex,
  displayDate = false,
  onClickPreviousSession,
  onClickNextSession,
  className,
}: {
  session: Pick<
    DiveSession,
    | 'diveSiteId'
    | 'boatsIds'
    | 'status'
    | 'name'
    | 'time'
    | 'comment'
    | 'tide'
    | 'emojiId'
    | 'theme'
    | 'type'
  >;
  selectionColor?: ClubPlanningPanelSessionDisplayStyle;
  onClick?: () => void;
  widthMode: CalendarSessionWidthMode;
  displaySessionIndex?: { index: number; total: number };
  displayDate?: boolean;
  displaySessionNavBar?: boolean;
  displaySessionNavBarDiveSessionResumes?: DiveSessionResumeFull[];
  onClickPreviousSession?: () => void;
  onClickNextSession?: () => void;
  className?: string;
}) => {
  const sessionTitle = useMemo(
    () => diveSessionTitleBuilder.build(session),
    [session],
  );
  const timeDayPeriod: DiveSessionTimeDayPeriod = useMemo(
    () => clubDiveSessionThemeBuilder.buildTimeDayPeriod(session.time),
    [session.time],
  );
  const isPast = useMemo(
    () =>
      dateService.isPastUTC(session.time) &&
      !dateService.isTodayUTC(session.time),
    [session.time],
  );
  const mediaSize = useMediaSizeTailwind();

  return (
    <div
      className={`font-bold bg-opacity-80 group-hover:bg-opacity-100 flex justify-center ${
        selectionColor
          ? `bg-app-${selectionColor}`
          : `bg-day-period-${timeDayPeriod}-dark`
      } text-white relative ${isPast ? 'opacity-80' : ''} ${className ?? ''}`}
      onClick={onClick}
    >
      {displayDate && (
        <div className="sm:hidden absolute top-0.5 left-1 text-app-xxs text-gray-900 uppercase">
          {dateService.formatUTC(session.time, 'ddd DD/MM/YYYY')}
        </div>
      )}
      <div className={`flex items-center ${className ?? ''}`}>
        <div className="">
          {onClickPreviousSession && (
            <button
              className="text-white hover:bg-gray-800 cursor-pointer px-3 py-0.5"
              onClick={(e) => {
                e.stopPropagation();
                onClickPreviousSession();
              }}
            >
              <div className="flex items-center">
                <AppIconsUI.navLeft className="w-4 h-4" />
              </div>
            </button>
          )}
        </div>
        <div className="px-1 sm:px-5 py-0.5 text-xs">
          {dateService.formatUTC(session.time, 'HH:mm')}
        </div>
        <div className="">
          {onClickNextSession && (
            <button
              className="text-white hover:bg-gray-800 cursor-pointer px-3 py-0.5"
              onClick={(e) => {
                e.stopPropagation();
                onClickNextSession();
              }}
            >
              <div className="flex items-center">
                <AppIconsUI.navRight className="w-4 h-4" />
              </div>
            </button>
          )}
        </div>
      </div>
      {widthMode !== 'tiny' && displaySessionIndex && (
        <div className="absolute top-0.5 right-1 text-app-xxs2 sm:text-app-xxs text-gray-900">
          {displaySessionIndex.index}/{displaySessionIndex.total}
        </div>
      )}

      {session?.emojiId && !sessionTitle && (
        <div className="hidden xs:block absolute top-0 left-0 sm:left-0.5">
          <AppEmojiIcon
            id={session.emojiId}
            size={mediaSize === 'xs' ? 14 : 18}
          />
        </div>
      )}
    </div>
  );
};
