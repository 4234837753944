import React, { useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { EcommerceCategoryEditorLocalState } from '../useEcommerceCategoryEditorPanelLocalState.hook';
import { EcommerceCategoryEditorFormContent } from './EcommerceCategoryEditorFormContent';

export const EcommerceCategoryEditorFormContentWithPreview = ({
  localState,
}: {
  localState: EcommerceCategoryEditorLocalState;
}) => {
  const { state, actions } = localState;

  const [appBookletPage, categoryName] = useWatch({
    control: state.form.control,
    name: ['appBookletPage', 'category.name'],
  });
  const content = appBookletPage?.mainContent?.content;
  const category = useMemo(
    () => ({
      name: categoryName,
    }),
    [categoryName],
  );
  return (
    <div className={'grid grid-cols-1 gap-4'}>
      <div className="flex gap-8 items-start">
        <EcommerceCategoryEditorFormContent
          className="w-full"
          localState={localState}
        />
      </div>
    </div>
  );
};
