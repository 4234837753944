import {
  AppEntityUpdatePatch,
  ClubPurchasePaymentPackageDetails,
} from '@mabadive/app-common-model';
import {
  changeDescriptorManager,
  jsonPatcher,
} from '@mabadive/app-common-services';
import { useCallback } from 'react';
import { DiverBookingPageUpdateState } from '../../../models';
import { DiverBookingPageSetUpdateStateFn } from '../../../useDiverBookingPageGlobalState.hook';
export function useDiverBookingPageDeletePurchasePackage({
  setUpdateState,
  updateState,
}: {
  setUpdateState: DiverBookingPageSetUpdateStateFn;
  updateState: DiverBookingPageUpdateState;
}) {
  return useCallback(
    ({
      deletedPurchasePackageId,
      updatedProducts,
      paymentsPackagesDetailsToFix,
    }: {
      deletedPurchasePackageId: string;
      updatedProducts: AppEntityUpdatePatch[];
      paymentsPackagesDetailsToFix: ClubPurchasePaymentPackageDetails[];
    }) => {
      let updateStateLocal = updateState;
      if (deletedPurchasePackageId) {
        const purchasePackagesChanges = changeDescriptorManager.deleteOne(
          deletedPurchasePackageId,
          {
            changeDescriptors: updateStateLocal.purchasePackagesChanges,
          },
        );
        const bookingProductsChanges = changeDescriptorManager.updateMany(
          updatedProducts ?? [],
          {
            changeDescriptors: updateStateLocal.bookingProductsChanges ?? [],
          },
        );

        if (paymentsPackagesDetailsToFix?.length) {
          const paymentsPackagesDetailsToFixIds =
            paymentsPackagesDetailsToFix.map((x) => x._id);

          // fix created
          updateStateLocal.paymentsPackagesDetailsChanges =
            updateStateLocal.paymentsPackagesDetailsChanges.map((x) => {
              if (
                !!x.created &&
                paymentsPackagesDetailsToFixIds.includes(x.pk)
              ) {
                return {
                  ...x,
                  created: {
                    ...x.created,
                    purchasePackageId: null,
                  },
                };
              }
              return x;
            });

          // fix existing
          const updatedPaymentsPackagesDetails: AppEntityUpdatePatch[] =
            paymentsPackagesDetailsToFix.map((initial) => {
              const final: ClubPurchasePaymentPackageDetails = {
                ...initial,
                purchasePackageId: null,
              };
              const productPatchOperations = jsonPatcher.compareObjects(
                initial,
                final,
                {},
              );
              const patch: AppEntityUpdatePatch = {
                pk: initial._id,
                patchOperations: productPatchOperations,
              };
              return patch;
            });

          updateStateLocal.paymentsPackagesDetailsChanges =
            updatedPaymentsPackagesDetails.length !== 0
              ? changeDescriptorManager.updateMany(
                  updatedPaymentsPackagesDetails,
                  {
                    changeDescriptors:
                      updateStateLocal.paymentsPackagesDetailsChanges,
                  },
                )
              : updateStateLocal.paymentsPackagesDetailsChanges;
        }

        setUpdateState(
          {
            ...updateStateLocal,
            purchasePackagesChanges,
            bookingProductsChanges,
          },
          {
            action: 'DiverBookingPageDeletePurchasePackage',
          },
        );
      }
    },
    [setUpdateState, updateState],
  );
}
