/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  APP_CURRENCIES,
  AppCurrency,
  ClubPaymentCurrencySettings,
} from '@mabadive/app-common-model';
import {
  paymentMethodFormatter,
  paymentPlatformFormatter,
} from '@mabadive/app-common-services';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import {
  useClubResume,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import { AppCurrencyChangeLabel } from 'src/business/club/modules/_common/ui';
import { AppFeatureBadge } from 'src/business/club/modules/_common/ui/AppFeatureBadge';
import { useAppCurrencyMain } from 'src/pages/_components/options';
import {
  ClubDialogsState,
  useClubDialogs,
  useClubDialogsActionsPersist,
  UseClubDialogsProps,
} from 'src/pages/_dialogs';
import {
  ClubSettingsSection,
  ClubSettingsViewActivableFeature,
} from 'src/pages/SE-settings/_core';

export const ClubSettingsGeneralPaymentsViewPanel = () => {
  const diveCenterResume = useDiveCenterResume();
  const clubResume = useClubResume();

  const clubSettings = clubResume?.clubSettings;

  const actionsPersist: UseClubDialogsProps = useClubDialogsActionsPersist();
  const dialogsState: ClubDialogsState = useClubDialogs(actionsPersist);

  const mainCurrency = useAppCurrencyMain();

  const paymentsSettings = clubSettings.general.payments;
  const depositsSettings = clubSettings.general.deposits;
  const onlineBooking = clubSettings.general.onlineBooking;
  const creditNoteSettings = clubSettings.general.creditNote;
  const customerSpace = clubSettings.publicSettings?.customerSpace;

  const availablePaymentCurrenciesSettings: (ClubPaymentCurrencySettings & {
    paymentCurrency: AppCurrency;
  })[] = useMemo(
    () =>
      (paymentsSettings?.currencies ?? []).map((currencySettings) => ({
        ...currencySettings,
        paymentCurrency: APP_CURRENCIES.find(
          (x) => x.isoCode === currencySettings.currencyIsoCode,
        ),
      })),
    [paymentsSettings?.currencies],
  );

  const currencyConversionEnabled = paymentsSettings?.currencyConversionEnabled;

  const onlinePaymentPlatform = useMemo(
    () =>
      paymentsSettings?.online?.platformId
        ? clubResume.paymentPlatforms.find(
            (x) => x._id === paymentsSettings?.online?.platformId,
          )
        : undefined,
    [clubResume.paymentPlatforms, paymentsSettings?.online?.platformId],
  );

  return (
    <div className="grid gap-4 md:gap-6">
      <ClubSettingsSection title="Paiements">
        <ClubSettingsViewActivableFeature
          isActive={paymentsSettings?.enabled}
          inactiveLabel="Paiements désactivés"
        >
          <div className="grid gap-2">
            <AppFeatureBadge
              label={'Moyens de paiement'}
              enabled={paymentsSettings?.methods?.length > 0}
            >
              <div className="text-app-primary">
                {paymentsSettings?.methods
                  .map((paymentMethod) =>
                    paymentMethodFormatter.formatPaymentMethod(paymentMethod),
                  )
                  .join(', ')}
              </div>
            </AppFeatureBadge>

            {currencyConversionEnabled &&
              availablePaymentCurrenciesSettings?.length > 0 && (
                <AppFeatureBadge label={'Devises de paiement'} enabled={true}>
                  <table className="mt-2 app-table">
                    <thead className="">
                      <tr>
                        <th
                          scope="col"
                          className="px-2 sm:py-1  uppercase tracking-wider"
                        >
                          Devise
                        </th>
                        <th
                          scope="col"
                          className="px-2 sm:py-1  uppercase tracking-wider"
                        >
                          Symbole
                        </th>
                        <th
                          scope="col"
                          className="px-2 sm:py-1  uppercase tracking-wider"
                        >
                          Taux par défaut
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {availablePaymentCurrenciesSettings.map(
                        ({ paymentCurrency, ...currencySettings }) => (
                          <tr
                            className={'text-sm'}
                            key={paymentCurrency.isoCode}
                          >
                            <td
                              className={
                                'py-0.5 px-2 whitespace-nowrap text-xs md:text-sm font-bold uppercase'
                              }
                            >
                              {paymentCurrency.label}
                            </td>
                            <td
                              className={clsx(
                                'py-0.5 px-2 whitespace-nowrap text-center text-xs md:text-sm font-bold uppercase',
                              )}
                            >
                              {paymentCurrency.sign}
                            </td>
                            <td
                              className={clsx(
                                'py-0.5 px-2 whitespace-nowrap text-xs md:text-sm font-bold uppercase',
                              )}
                            >
                              <AppCurrencyChangeLabel
                                className={clsx(
                                  paymentCurrency.isoCode ===
                                    mainCurrency.isoCode && 'hidden',
                                )}
                                mainCurrency={mainCurrency}
                                paymentCurrency={paymentCurrency}
                                defaultRate={currencySettings.defaultRate}
                                defaultRateReverse={
                                  currencySettings.defaultRateReverse
                                }
                              />
                            </td>
                          </tr>
                        ),
                      )}
                    </tbody>
                  </table>
                </AppFeatureBadge>
              )}
          </div>
        </ClubSettingsViewActivableFeature>
      </ClubSettingsSection>
      <ClubSettingsSection title="Acomptes">
        <ClubSettingsViewActivableFeature
          isActive={depositsSettings?.enabled}
          inactiveLabel="Acomptes désactivés"
        >
          <AppFeatureBadge
            label={'Acomptes activés'}
            enabled={depositsSettings?.enabled}
          />
        </ClubSettingsViewActivableFeature>
      </ClubSettingsSection>
      <ClubSettingsSection title="Paiement en ligne">
        <ClubSettingsViewActivableFeature
          isActive={paymentsSettings?.online?.platformsEnabled}
          inactiveLabel="Paiements en ligne désactivés"
        >
          <AppFeatureBadge
            label={'Paiement en ligne'}
            enabled={!!onlinePaymentPlatform}
            description={paymentPlatformFormatter.formatPaymentPlatform(
              onlinePaymentPlatform,
              { format: 'simple' },
            )}
          />
          <AppFeatureBadge
            label={'Vente / réservation en ligne'}
            enabled={!!onlineBooking?.enabled}
            description={
              onlineBooking?.enabled
                ? customerSpace?.onlineBooking?.enableBooking
                  ? 'activé sur l\'espace client'
                  : 'désactivé sur l\'espace client'
                : undefined
            }
          />
        </ClubSettingsViewActivableFeature>
      </ClubSettingsSection>
      <ClubSettingsSection title="Avoirs (remboursements)">
        <ClubSettingsViewActivableFeature
          isActive={creditNoteSettings?.enabled}
          inactiveLabel="Avoirs désactivés"
        >
          <div className="grid gap-2">
            <AppFeatureBadge
              label={'Avoirs activés'}
              enabled={creditNoteSettings?.enabled}
            ></AppFeatureBadge>
            <AppFeatureBadge
              label={'Moyens de paiement des avoirs'}
              enabled={creditNoteSettings.paymentMethods?.length > 0}
            >
              <div className="text-app-primary">
                {creditNoteSettings.paymentMethods
                  .map((paymentMethod) =>
                    paymentMethodFormatter.formatPaymentMethod(paymentMethod),
                  )
                  .join(', ')}
              </div>
            </AppFeatureBadge>
          </div>
        </ClubSettingsViewActivableFeature>
      </ClubSettingsSection>
    </div>
  );
};
