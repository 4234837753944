import {
  AppDocUploadContext,
  AppTranslationContentItemDoc,
  PRO_AppDocResume,
} from '@mabadive/app-common-model';
import clsx from 'clsx';
import React, { useState } from 'react';
import { Control, FieldPath, useWatch } from 'react-hook-form';
import {
  AppDocResumeImageUploader,
  AppFormControlRHF,
  AppInputRHF,
} from 'src/lib/form';
import { AppButton } from '../../../../business/_core/modules/layout';
import { AppIconsAction } from '../../../../business/_core/modules/layout/icons';
import { useAppDocImageUrl } from '../../../../business/club/modules/club-diver-participant/pages/_from-diver-app/DiveCenterBookingMembersList/useAppDocImageUrl.hook';
export function AppInputImageRHF<T>({
  control,
  name: nameInput,
  required,
  label,
  setValue,
  appDocUploadContext,
  width,
  height,
  className,
  previewImageClassName = 'h-[80px] lg:h-[160px]',
  isRemovable = true,
}: {
  control: Control<T>;
  name: FieldPath<T>;
  required?: boolean;
  label: string;
  setValue: any; // UseFormSetValue<Partial<T>>;
  appDocUploadContext: AppDocUploadContext;
  width: 1000 | 400;
  height: 1000 | 400;
  className?: string;
  previewImageClassName?: string;
  isRemovable?: boolean;
}) {
  const name = nameInput as any;
  const [contentImageAny] = useWatch({
    control,
    name: [name],
  });
  const contentImage: AppTranslationContentItemDoc = contentImageAny as any;

  const [showImageSelector, setShowImageSelector] = useState(false);

  const imageUrl = useAppDocImageUrl({
    appDocId: contentImage?._id,
    publicUrl: contentImage?.publicUrl,
  });

  return !appDocUploadContext ? null : (
    <>
      <AppFormControlRHF
        className={clsx('min-w-32', className)}
        label={label}
        control={control}
        name={`${name}._id` as any}
        // validation={{
        //   rules: {
        //     maxLength: 200,
        //   },
        // }}
        required={required}
        renderComponent={(props) => (
          <>
            <div className="hidden">
              <AppInputRHF {...props} />
            </div>
            <div
              className={'mt-2 -mb-2 cursor-pointer flex gap-4 items-end'}
              onClick={() => setShowImageSelector(true)}
            >
              {contentImage?._id && (
                // eslint-disable-next-line jsx-a11y/alt-text
                <img className={previewImageClassName} src={imageUrl} />
              )}

              {isRemovable && contentImage?._id ? (
                <AppButton
                  icon={AppIconsAction.delete}
                  color={'danger-outline-light'}
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation();
                    setValue(name, null);
                  }}
                >
                  {' '}
                </AppButton>
              ) : (
                <AppButton
                  icon={AppIconsAction.actionAddPhoto}
                  color={'primary-outline-light'}
                  size="small"
                >
                  {contentImage?._id ? 'Remplacer' : 'Image'}
                </AppButton>
              )}
            </div>
          </>
        )}
      />

      {showImageSelector && (
        <AppDocResumeImageUploader
          uploadUrl="/pro/docs/upload"
          expectedImage={{
            width,
            height,
            variantsRatio: [1 / 2],
          }}
          uploadPayload={{ context: appDocUploadContext }}
          onSuccess={(appDoc: PRO_AppDocResume) => {
            setShowImageSelector(false);
            const contentImage: AppTranslationContentItemDoc = {
              _id: appDoc._id,
              fileType: appDoc.fileType,
              publicUrl: appDoc.publicUrl,
            };
            setValue(name, contentImage as any);

            const coverImage: AppTranslationContentItemDoc = {
              _id: appDoc._id,
              fileType: appDoc.fileType,
              publicUrl: appDoc.variantsPublicUrls?.[0],
            };
          }}
          onCancel={() => {
            setShowImageSelector(false);
          }}
        />
      )}
    </>
  );
}
