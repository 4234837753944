/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useMemo } from 'react';

import {
  CLUB_STAFF_MEMBER_JOB_TYPES,
  ClubStaffMemberJobType,
  WEEK_DAYS_ISO,
} from '@mabadive/app-common-model';
import {
  clubStaffMemberJobTypeFormatter,
  dateService,
} from '@mabadive/app-common-services';
import Tippy from '@tippyjs/react';
import clsx from 'clsx';
import { FieldArrayWithId, useWatch } from 'react-hook-form';
import { ValueLabel } from 'src/business/club/modules/_common/form';
import { AppFormControlRHF, AppFormLabel, AppInputRHF } from 'src/lib/form';
import { AppSingleSelect2HeadlessUIRHF } from 'src/lib/form/components/AppSingleSelect/AppSingleSelect2HeadlessUIRHF';
import { AppInputDatePickerMaterialRHF } from 'src/stories/components/04-form';
import { AppButton } from '../../../../../../../business/_core/modules/layout';
import { AppIconsAction } from '../../../../../../../business/_core/modules/layout/icons';
import { DateRangeLabel } from '../../../../../../_components';
import { ClubSettingsEquipmentEditPageFormModelOnClickAction } from '../../../../../SE-02_account/equipment/ClubSettingsEquipmentEditPage/ClubSettingsEquipmentEditPageForm/ClubSettingsEquipmentEditPageFormModel';
import { StaffMemberEditFormModel } from '../../_model';
import { StaffMemberEditDialogLocalState } from '../../useStaffMemberEditDialogLocalState.hook';
import { StaffMemberEditFormAvailabilyDay } from './StaffMemberEditFormAvailabilyDay';

export const StaffMemberEditFormAvailabily = ({
  localState,
  showDeleteButton,
  field,
  fieldIndex,
  onClick,
  className,
}: {
  localState: StaffMemberEditDialogLocalState;
  showDeleteButton: boolean;
  field: FieldArrayWithId<
    StaffMemberEditFormModel,
    'staffMember.availabilities',
    'id'
  >;
  fieldIndex: number;
  onClick: (
    field: FieldArrayWithId<
      StaffMemberEditFormModel,
      'staffMember.availabilities',
      'id'
    >,
    fieldIndex: number,
    action: ClubSettingsEquipmentEditPageFormModelOnClickAction,
  ) => void;
  className?: string;
}) => {
  const {
    state: { form },
  } = localState;
  const { control, setValue } = form;

  const STAFF_MEMBER_JOB_TYPES_OPTIONS = useMemo(
    () =>
      CLUB_STAFF_MEMBER_JOB_TYPES.map((jobType) => {
        const option: ValueLabel<ClubStaffMemberJobType> = {
          label: clubStaffMemberJobTypeFormatter.formatJobType(jobType),
          value: jobType,
        };
        return option;
      }),
    [],
  );

  const basePath = `staffMember.availabilities.${fieldIndex}` as const;

  const [label, beginDate, endDate] = useWatch({
    control,
    name: [`${basePath}.label`, `${basePath}.beginDate`, `${basePath}.endDate`],
  });

  return (
    <div className="grid gap-2">
      <div className="flex flex-col lg:flex-row lg:items-start gap-4">
        <h2 className="flex-grow text-left uppercase text-base md:text-lg leading-6 font-normal text-gray-600">
          <div className="font-bold text-app-primary-light">{label}</div>
          <DateRangeLabel
            className="text-sm"
            beginDate={beginDate}
            endDate={endDate}
          />
        </h2>
        <div className="flex gap-2 md:gap-6 justify-end">
          <AppButton
            color="success-outline-light"
            icon={AppIconsAction.clone}
            onClick={(e) => {
              e.stopPropagation();
              onClick(field, fieldIndex, 'clone');
            }}
          >
            Dupliquer
          </AppButton>

          {/* <AppButtonFreeColor
            className="text-sm whitespace-nowrap rounded"
            icon={AppIconsAction.clone}
            type="button"
            onClick={(e) => {
              e.stopPropagation();
              onClick(field, fieldIndex, 'clone');
            }}
            bgColor="white"
            textColor="#16a34a"
            // {...appUiTheme.buttonPrimary}
          >
            Dupliquer
          </AppButtonFreeColor> */}
          {/* <AppButtonFreeColor
                  className="text-sm whitespace-nowrap rounded"
                  icon={AppIconsAction.delete}
                  type="button"
                  onClick={(e) => {
                    e.stopPropagation();
                    onClick(field, fieldIndex, 'delete');
                  }}
                  bgColor="#dc2626"
                  textColor="#ffffff"
                  // {...appUiTheme.buttonPrimary}
                >
                  Supprimer
                </AppButtonFreeColor> */}
          <Tippy delay={[1000, 100]} placement="top" content={'Supprimer'}>
            <AppIconsAction.delete
              className={clsx(
                'p-1 w-8 h-8 bg-red-200 hover:bg-red-600 rounded-full text-white cursor-pointer',
                !showDeleteButton && 'invisible',
              )}
              onClick={() => {
                onClick(field, fieldIndex, 'delete');
              }}
            />
          </Tippy>
        </div>
      </div>
      <div className={'w-full grid sm:grid-cols-2 md:grid-cols-4 gap-4'}>
        <AppFormControlRHF
          className="w-full"
          label={'Nom (saison 2025, vacances...)'}
          required={true}
          control={control}
          name={`${basePath}.label`}
          renderComponent={(props) => <AppInputRHF fullWidth {...props} />}
        />
        <AppFormControlRHF
          className="w-full"
          label={'Emploi'}
          required={true}
          control={control}
          name={`${basePath}.jobType`}
          renderComponent={(props) => (
            <AppSingleSelect2HeadlessUIRHF
              {...props}
              theme="material-ui"
              disableClearButton={true}
              className="w-full text-gray-600"
              options={STAFF_MEMBER_JOB_TYPES_OPTIONS}
            />
          )}
        />
        <AppFormControlRHF
          className="w-full"
          label="Date de début"
          control={control}
          required={true}
          name={`${basePath}.beginDate`}
          renderComponent={(props) => (
            <AppInputDatePickerMaterialRHF
              {...props}
              initialView="weeks"
              className="w-full"
            />
          )}
        />
        <AppFormControlRHF
          className="w-full"
          label="Date de fin"
          control={control}
          required={false}
          name={`${basePath}.endDate`}
          renderComponent={(props) => (
            <AppInputDatePickerMaterialRHF
              {...props}
              initialView="weeks"
              className="w-full"
            />
          )}
        />
      </div>
      <AppFormLabel
        className="mt-4"
        label={'Jours de travail réguliers (matin et après-midi)'}
      />
      <div className="w-[14rem] grid grid-cols-2 gap-2 md:hidden">
        {WEEK_DAYS_ISO.map((isoWeekDay) => (
          <React.Fragment key={isoWeekDay}>
            <div className="text-left uppercase font-bold text-app-primary text-base">
              {dateService.formatIsoDayIso(isoWeekDay, {
                format: 'dddd',
              })}
            </div>
            <StaffMemberEditFormAvailabilyDay
              key={isoWeekDay}
              isoWeekDay={isoWeekDay}
              field={field}
              fieldIndex={fieldIndex}
              localState={localState}
            />
          </React.Fragment>
        ))}
      </div>
      <div className="hidden md:grid md:grid-cols-7 gap-2">
        {WEEK_DAYS_ISO.map((isoWeekDay) => (
          <div
            key={isoWeekDay}
            className="text-center uppercase font-bold text-app-primary text-base"
          >
            {dateService.formatIsoDayIso(isoWeekDay, {
              format: 'dddd',
            })}
          </div>
        ))}
        {WEEK_DAYS_ISO.map((isoWeekDay) => (
          <StaffMemberEditFormAvailabilyDay
            key={isoWeekDay}
            isoWeekDay={isoWeekDay}
            field={field}
            fieldIndex={fieldIndex}
            localState={localState}
          />
        ))}
      </div>
    </div>
  );
};
