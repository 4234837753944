import {
  AppInquiryForClubResumeGql_Company,
  AppInquiryResponseForBookingGql_Company,
  CustomerInquiryWithResponse,
  INQUIRY_RESPONSE_EDITABLE_STATUSES,
} from '@mabadive/app-common-model';
import { dataSorter } from '@mabadive/app-common-services';
import { PRO_BookingParticipantFull } from '../../../../models';

export const proInquiriesByMemberBuilder = {
  buildCustomerInquiryWithResponse,
};

function buildCustomerInquiryWithResponse({
  activeInquiries,
  memberBookingParticipantsFull,
}: {
  activeInquiries: AppInquiryForClubResumeGql_Company[];
  memberBookingParticipantsFull: PRO_BookingParticipantFull[];
}): CustomerInquiryWithResponse[] {
  if (!activeInquiries?.length) {
    return [];
  }
  // only booking member diver inquiries responses
  const bmInquiryResponses: AppInquiryResponseForBookingGql_Company[] =
    memberBookingParticipantsFull.reduce(
      (acc, x) => acc.concat(x?.inquiryResponses ?? []),
      [] as AppInquiryResponseForBookingGql_Company[],
    );

  return activeInquiries
    .map((inquiry) => {
      const matchingInquiryResponses: AppInquiryResponseForBookingGql_Company[] =
        (bmInquiryResponses ?? []).filter((x) => x.inquiryId === inquiry._id);
      const responses = dataSorter.sortMultiple(matchingInquiryResponses, {
        getSortAttributes: (response) => {
          return [
            {
              // les réponses éditables en premier
              value: INQUIRY_RESPONSE_EDITABLE_STATUSES.includes(
                response?.status,
              )
                ? 1
                : 2,
            },
            {
              // puis par autre statut
              value:
                response?.status === 'draft'
                  ? 1
                  : response?.status === 'rejected'
                  ? 2
                  : response?.status === 'submitted'
                  ? 3
                  : 4,
            },
            {
              // puis par date
              value: response?._createdAt,
            },
          ];
        },
      });
      const lastResponse = responses?.[0];
      return {
        inquiry,
        responses,
        lastResponse,
      };
    })
    .filter(
      (x) =>
        !x.lastResponse ||
        INQUIRY_RESPONSE_EDITABLE_STATUSES.includes(x.lastResponse?.status),
    ); // le questionnaire est masqué une fois validé
}
