/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useMemo } from 'react';
import { usePageWidth } from 'src/business/_core/modules/layout/services/usePageWidth.hook';
import {
  useClubResume,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import { ClubDialogsStateOld } from 'src/pages/_dialogs';
import { DiverBookingSpace_BookingList } from '../../../DiverBookingSpace/components/DiverBookingSpace_BookingList';
import { DiverBookingPageBookingCard } from '../../components/DiverBookingPageBookingCard';
import { BookingTabModel } from '../../models';
import { DiverBookingPageActions } from '../../useDiverBookingPageActions.hook';
import { DiverBookingPageGlobalState } from '../../useDiverBookingPageGlobalState.hook';

export const DiverBookingPageBookingTab = ({
  globalState,
  dialogs,
  actions,
}: {
  globalState: DiverBookingPageGlobalState;
  dialogs: ClubDialogsStateOld;
  actions: DiverBookingPageActions;
}) => {
  const pageWidth = usePageWidth({ ignoreMenu: false });

  const {
    data,
    aggregatedData,
    updateState,
    setUpdateState,
    focus,
    navigationContext,
    loadedContent,
    includeArchivedBookings,
    linkedData,
    state,
  } = globalState;

  const diveCenterResume = useDiveCenterResume();
  const clubResume = useClubResume();
  const diveCenterId = diveCenterResume?._id;

  // view model
  const bookingTabModels: BookingTabModel[] = data.bookingTabModels;

  const subTab = state.viewState?.tabBooking_tab ?? 'booking-registration';
  const tabBooking_bookingId = state.viewState?.tabBooking_bookingId;

  const selectedBookingTabModel = useMemo(() => {
    return bookingTabModels.find((x) => x.bookingId === tabBooking_bookingId);
  }, [bookingTabModels, tabBooking_bookingId]);

  return (
    <div className="">
      {/* <AppBreadcrumbBar
        color={'#333'}
        items={[
          {
            icon: EntitiesIcons.booking,
            label: 'Réservations',
            onClick: () => {
              actions.backToBookingList();
            },
          },
          {
            label: 'Réservation N°2 du 22/08/2024 au 23/08/2024',
            url: '/club/settings',
          },
        ]}
      /> */}
      {subTab === 'booking-list' || !tabBooking_bookingId ? (
        <DiverBookingSpace_BookingList
          globalState={globalState}
          dialogs={dialogs}
          actions={actions}
        />
      ) : selectedBookingTabModel ? (
        <DiverBookingPageBookingCard
          key={selectedBookingTabModel?.bookingId}
          bookingTabModel={selectedBookingTabModel}
          globalState={globalState}
          actions={actions}
          dialogs={dialogs}
          aggregatedData={aggregatedData}
          bookingTabModels={bookingTabModels}
          focus={focus}
          updateState={updateState}
          setUpdateState={setUpdateState}
          openSessionDialog={actions.openSessionDialog}
          openDiverSelector={actions.openDiverSelector}
        />
      ) : null}
    </div>
  );
};
