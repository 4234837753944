/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { AppButtonMultiActions } from 'src/business/_core/modules/layout';
import { AppHamburgerMenuItem } from '../../../../../../../business/club/modules/_common/ui';
import { ClubSettingsServicesViewPanelOffersPricesLocalState } from './useClubSettingsServicesViewPanelOffersPricesLocalState.hook';
import { useOffersPricesActionsBar_Store_MenuItems } from './useOffersPricesActionsBar_Store_MenuItems.hook';

export const ClubSettingsServicesViewPanelOffersPricesActionsBar_Store = ({
  localState,
}: {
  localState: ClubSettingsServicesViewPanelOffersPricesLocalState;
}) => {
  const { data, state, actions } = localState;
  const publicSettings = data.publicSettings;
  const clubReference = data.clubReference;
  const clubSettings = data.clubSettings;

  const extraMenuItems: AppHamburgerMenuItem[] =
    useOffersPricesActionsBar_Store_MenuItems({
      localState,
    });

  return (
    <>
      <AppButtonMultiActions items={extraMenuItems} />
    </>
  );
};
