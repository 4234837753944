/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  AppEntityUpdatePatch,
  AppInquiry,
  AppInquirySection,
  ProMultiOperationPayload,
  ProMultiOperationPayloadAction,
} from '@mabadive/app-common-model';
import {
  commonEntityBuilder,
  jsonPatcher,
} from '@mabadive/app-common-services';
import { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useRedirect } from 'src/business/_core/data/hooks';
import { useDiveCenterResume } from 'src/business/club/data/hooks';
import { proMultiMutationsApiClient } from '../../../../../../business/_core/data/app-operation';
import { confirmDialog } from '../../../../../../business/_core/modules/layout/components/ConfirmDialog/confirmDialog.service';
import { appLogger } from '../../../../../../business/_core/modules/root/logger';
import { clubSettingsCustomersUrlBuilder } from '../../../clubSettingsCustomersUrlBuilder.service';
import { inquerySectionBuilder } from '../../_services';
import {
  AppInquiryFormModel,
  ClubSettingsInquiryEdit_TabId,
} from '../ClubSettingsInquiryEditPageForm/model';
import { ClubSettingsInquiryEditPageLocalState } from '../useClubSettingsInquiryEditPageLocalState.hook';

export function useClubSettingsInquiryEditPagePanelLocalState({
  parentState,
}: {
  parentState: ClubSettingsInquiryEditPageLocalState;
}) {
  const mode = parentState?.state?.mode;
  const [operationPending, setOperationPending] = useState(false);

  const redirectTo = useRedirect();

  const diveCenterResume = useDiveCenterResume();

  const initialValueIfExists: AppInquiry = parentState?.data?.initialValue;
  const initialFormValue: AppInquiryFormModel = useMemo(() => {
    if (initialValueIfExists) {
      const initialFormValue: AppInquiryFormModel = {
        ...initialValueIfExists,
      };

      return initialFormValue;
    } else {
      const defaultSection1: AppInquirySection =
        inquerySectionBuilder.buildSection({
          sortIndex: 1,
          sectionTitle: 'Questions générales',
        });
      // const sections = inqueryDefaultBuilder.buildFromType({
      //   inquiryType: 'medical-form',
      // });
      const model: AppInquiryFormModel = {
        type: undefined,
        // type: 'medical-form',
        name: '',
        // sections,
        sections: [defaultSection1],
        settings: {
          enabled: true,
          color: '#772233',
        },
      };
      return model;
    }
  }, [initialValueIfExists]);

  const form = useForm<AppInquiryFormModel>({
    defaultValues: initialFormValue,
    // mode: 'onChange',
    // reValidateMode: 'onChange',
  });

  const {
    register,
    handleSubmit,
    watch,
    formState,
    control,
    setValue,
    reset,
    setError,
  } = form;

  const back = useCallback(() => {
    return redirectTo(clubSettingsCustomersUrlBuilder.inquiries.list());
  }, [redirectTo]);

  const submitForm = useCallback(() => {
    handleSubmit(async (formValue: AppInquiryFormModel) => {
      setOperationPending(true);

      try {
        if (mode === 'create') {
          const actionContext = 'create-inquiry';
          const newInquiry: AppInquiry =
            commonEntityBuilder.buildEntity<AppInquiry>({
              ...formValue,
            });
          const actions: ProMultiOperationPayloadAction[] = [
            {
              actionContext,
              appInquiry: {
                created: [newInquiry],
              },
            },
          ];
          const payload: ProMultiOperationPayload = {
            logContext: actionContext,
            actions,
          };
          const result = await proMultiMutationsApiClient.update(payload);
        } else {
          const actionContext = 'edit-inquiry';

          const patchOperations = jsonPatcher.compareObjects(
            initialValueIfExists,
            {
              ...initialValueIfExists,
              ...formValue,
            },
            {
              // else, value won't be deleted by typeorm
              // https://github.com/typeorm/typeorm/issues/2934
              replaceDeleteByNullValue: false,
              attributesToReplaceFully: ['sections'],
            },
          );
          if (patchOperations.length) {
            const patch: AppEntityUpdatePatch = {
              pk: initialValueIfExists._id,
              patchOperations,
            };
            const actions: ProMultiOperationPayloadAction[] = [
              {
                actionContext,
                appInquiry: {
                  updated: [patch],
                },
              },
            ];
            const payload: ProMultiOperationPayload = {
              logContext: actionContext,
              actions,
            };
            const result = await proMultiMutationsApiClient.update(payload);
          }
        }

        back();
      } catch (error) {
        appLogger.error('Unexpected error while saving inquiry', error);
      } finally {
        setOperationPending(false);
      }
    })();
  }, [back, handleSubmit, initialValueIfExists, mode]);

  const confirmDelete = useCallback(() => {
    confirmDialog
      .confirm({
        title: 'Supprimer le questionnaire',
        message: 'Êtes-vous sûr de vouloir supprimer ce questionnaire?',
        type: 'remove',
      })
      .subscribe(async (confirmed) => {
        if (confirmed) {
          setOperationPending(true);

          try {
            const actionContext = 'delete-inquiry';

            const actions: ProMultiOperationPayloadAction[] = [
              {
                actionContext,
                appInquiry: {
                  deletedIds: [initialValueIfExists?._id],
                },
              },
            ];
            const payload: ProMultiOperationPayload = {
              logContext: actionContext,
              actions,
            };
            const result = await proMultiMutationsApiClient.update(payload);

            back();
          } catch (error) {
            appLogger.error('Unexpected error while deleting inquiry', error);
          } finally {
            setOperationPending(false);
          }
        }
      });
  }, [back, initialValueIfExists?._id]);
  const [currentTabId, setCurrentTabId] =
    useState<ClubSettingsInquiryEdit_TabId>('questions');

  return {
    state: {
      form,
      operationPending,
      mode,
      currentTabId,
      setCurrentTabId,
    },
    data: {
      form,
      initialValueIfExists,
    },
    actions: {
      back,
      submitForm,
      confirmDelete,
    },
  };
}

export type ClubSettingsInquiryEditPagePanelLocalState = ReturnType<
  typeof useClubSettingsInquiryEditPagePanelLocalState
>;
