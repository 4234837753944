/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  BookingParticipantEditorFormModel,
  DiveCenterPrivateSettingsEquipment,
} from '@mabadive/app-common-model';
import {
  diveModeAnalyser,
  equipmentFormatter,
} from '@mabadive/app-common-services';
import clsx from 'clsx';
import React, { useCallback, useMemo } from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { useAppSecurityUser } from 'src/business/auth/services';
import {
  useClubResume,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import {
  AppFormControlRHF_Deprecated,
  AppInputBooleanSwitch,
} from 'src/lib/form';
import { BookingParticipantEquipmentFormGroup } from './BookingParticipantEquipmentFormGroup';

export const BookingParticipantEditFormRHFGroup2 = ({
  form,
  maxWidthMd,
  className = '',
}: {
  form: UseFormReturn<BookingParticipantEditorFormModel>;
  maxWidthMd: boolean;
  className?: string;
}) => {
  const securityUser = useAppSecurityUser();

  const clubResume = useClubResume();
  const diveCenterResume = useDiveCenterResume();

  const equipmentsSettings: DiveCenterPrivateSettingsEquipment =
    diveCenterResume?.privateSettings?.equipment;
  const customerSettings = clubResume.clubSettings?.customer;

  const { control, setValue, getValues } = form;

  const [
    diveMode,
    equipmentProvider,
    jacket,
    wetsuit,
    fins,
    mask,
    scubaRegulator,
    divingBoots,
  ] = useWatch({
    control,
    name: [
      'clubParticipant.diveMode',
      'clubParticipant.equipment.provider',
      'clubParticipant.equipment.jacket',
      'clubParticipant.equipment.wetsuit',
      'clubParticipant.equipment.fins',
      'clubParticipant.equipment.mask',
      'clubParticipant.equipment.scubaRegulator',
      'clubParticipant.equipment.divingBoots',
    ],
  });

  const isScubaDivingEquipmentRequired = useMemo(
    () => diveModeAnalyser.hasScubaDivingEquipments(diveMode),
    [diveMode],
  );

  const onEquipmentProviderChanges = useCallback(
    (equipmentProviderCustomer: boolean): void => {
      setValue(
        'clubParticipant.equipment.provider',
        equipmentProviderCustomer ? 'customer' : 'club',
        {
          shouldDirty: true,
          shouldTouch: true,
          shouldValidate: true,
        },
      );

      const equipmentsToAutoSet = equipmentsSettings.equipments.filter(
        (equipmentConfig) =>
          equipmentConfig.enabled && equipmentConfig.self.autoSetOnGlobal,
      );

      // auto-check / uncheck main equipments
      equipmentsToAutoSet.forEach((eq) => {
        const path = `clubParticipant.equipment.${eq.type}` as const;
        const isEnabled = getValues(path);
        if (equipmentProviderCustomer) {
          if (!isEnabled) {
            setValue(path, {
              selfEquipped: true,
            });
          }
        } else {
          if (isEnabled) {
            setValue(path, null);
          }
        }
      });
    },
    [equipmentsSettings.equipments, getValues, setValue],
  );

  return (
    <>
      {!!diveMode && (
        <>
          <div
            className={clsx(
              'w-full flex flex-col sm:grid sm:grid-cols-4 gap-4',
              !maxWidthMd && 'xl:grid-cols-8',
              className,
            )}
          >
            {equipmentsSettings?.provider && (
              <AppFormControlRHF_Deprecated
                className="w-full sm:col-span-2"
                control={control}
                name="clubParticipant.equipment.provider"
                renderComponent={(props) => (
                  <AppInputBooleanSwitch
                    className="w-full"
                    {...props}
                    label={'Équipement'}
                    label2={'PERSO'}
                    value={equipmentProvider === 'customer'}
                    onChange={onEquipmentProviderChanges}
                  />
                )}
              />
            )}
            <BookingParticipantEquipmentFormGroup
              className={'w-full'}
              classNameIfModel2={'sm:col-span-2'}
              type="jacket"
              label={equipmentFormatter.formatEquipmentType('jacket')}
              control={control}
              name="clubParticipant.equipment.jacket"
            />
            <BookingParticipantEquipmentFormGroup
              className={'w-full'}
              classNameIfModel2={'sm:col-span-2'}
              type="wetsuit"
              label={equipmentFormatter.formatEquipmentType('wetsuit')}
              control={control}
              name="clubParticipant.equipment.wetsuit"
            />
            <BookingParticipantEquipmentFormGroup
              className={'w-full'}
              classNameIfModel2={'sm:col-span-2'}
              type="fins"
              label={equipmentFormatter.formatEquipmentType('fins')}
              control={control}
              name="clubParticipant.equipment.fins"
            />
            {isScubaDivingEquipmentRequired && (
              <>
                <BookingParticipantEquipmentFormGroup
                  className={'w-full'}
                  classNameIfModel2={'sm:col-span-2'}
                  type="scubaRegulator"
                  label={equipmentFormatter.formatEquipmentType(
                    'scubaRegulator',
                  )}
                  control={control}
                  name="clubParticipant.equipment.divingBoots"
                />
                <BookingParticipantEquipmentFormGroup
                  className={'w-full'}
                  classNameIfModel2={'sm:col-span-2'}
                  type="divingBoots"
                  label={equipmentFormatter.formatEquipmentType('divingBoots')}
                  control={control}
                  name="clubParticipant.equipment.divingBoots"
                />
                <BookingParticipantEquipmentFormGroup
                  className={'w-full'}
                  classNameIfModel2={'sm:col-span-2'}
                  type="mask"
                  label={equipmentFormatter.formatEquipmentType('mask')}
                  control={control}
                  name="clubParticipant.equipment.mask"
                />
                <BookingParticipantEquipmentFormGroup
                  className={'w-full'}
                  classNameIfModel2={'sm:col-span-2'}
                  type="flashLight"
                  label={equipmentFormatter.formatEquipmentType('flashLight')}
                  control={control}
                  name="clubParticipant.equipment.flashLight"
                />
                <BookingParticipantEquipmentFormGroup
                  className={'w-full'}
                  classNameIfModel2={'sm:col-span-2'}
                  type="computer"
                  label={equipmentFormatter.formatEquipmentType('computer')}
                  control={control}
                  name="clubParticipant.equipment.computer"
                />
                <BookingParticipantEquipmentFormGroup
                  className={'w-full'}
                  classNameIfModel2={'sm:col-span-2'}
                  type="tank"
                  label={equipmentFormatter.formatEquipmentType('tank')}
                  control={control}
                  name="clubParticipant.equipment.tank"
                  onChange={(tankEquipment) => {
                    const tank = tankEquipment?.selection?.model?.tank;
                    if (tank?.gazDescription) {
                      setValue(
                        'clubParticipant.gaz.gazDescription',
                        tank.gazDescription,
                      );
                    }
                    if (tank?.gazType) {
                      setValue('clubParticipant.gaz.gazType', tank.gazType);
                    }
                    if (tank?.capacity) {
                      setValue(
                        'clubParticipant.gaz.gazQuantity',
                        tank.capacity,
                      );
                    }
                  }}
                />
                <BookingParticipantEquipmentFormGroup
                  className={'w-full'}
                  classNameIfModel2={'sm:col-span-2'}
                  type="compass"
                  label={equipmentFormatter.formatEquipmentType('compass')}
                  control={control}
                  name="clubParticipant.equipment.compass"
                />
                <BookingParticipantEquipmentFormGroup
                  className={'w-full'}
                  classNameIfModel2={'sm:col-span-2'}
                  type="weighting"
                  label={equipmentFormatter.formatEquipmentType('weighting')}
                  control={control}
                  name="clubParticipant.equipment.weighting"
                />
                <BookingParticipantEquipmentFormGroup
                  className={'w-full'}
                  classNameIfModel2={'sm:col-span-2'}
                  type="rebreather"
                  label={equipmentFormatter.formatEquipmentType('rebreather')}
                  control={control}
                  name="clubParticipant.equipment.rebreather"
                />
                <BookingParticipantEquipmentFormGroup
                  className={'w-full'}
                  classNameIfModel2={'sm:col-span-2'}
                  type="underwaterScooter"
                  label={equipmentFormatter.formatEquipmentType(
                    'underwaterScooter',
                  )}
                  control={control}
                  name="clubParticipant.equipment.underwaterScooter"
                />
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};
