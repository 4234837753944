/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  BookingParticipantEditorFormModel,
  ClubParticipantAutoSupervisedDiveMode,
  DiveCertificationReference,
  DiveTrainingReference,
} from '@mabadive/app-common-model';
import clsx from 'clsx';
import React from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { useClubResume } from 'src/business/club/data/hooks';
import { ValueLabel } from 'src/business/club/modules/_common/form/components/ValueLabel.model';
import { AppFormControlRHF } from 'src/lib/form';
import { AppSingleAutocomplete2RHF } from 'src/lib/form/components/AppSingleAutocomplete';
import {
  useAutonomousCertificationOptions,
  useDiveModesOptions,
  useInstructorCertificationOptions,
  useSupervisedCertificationOptions,
  useTrainingOptionsAll,
} from 'src/pages/_components/options';

export const BookingParticipantAutoSupervisedForm = ({
  form: inputForm,
  context,
  onAutoSupervisedDiveModeChange,
  classNameEveryField = '',
}: {
  form: UseFormReturn<BookingParticipantEditorFormModel>;
  context: 'clubParticipant' | 'clubDiver';
  onAutoSupervisedDiveModeChange?: (
    diveMode: ClubParticipantAutoSupervisedDiveMode,
  ) => void;
  classNameEveryField?: string;
}) => {
  const clubResume = useClubResume();
  const clubSettings = clubResume.clubSettings;
  const services = clubResume.clubSettings.services;

  // IMPORTANT: composant utilisé dans le basePathe d'un participant, ou bien d'un bookingMember
  // Pour que les types de react-form fonctionne, on force typescript à considérer
  // que c'est toujours "form:BookingParticipantEditorFormModel" et "basePath:'clubParticipant'""
  const form = inputForm as UseFormReturn<BookingParticipantEditorFormModel>;
  const basePath =
    context === 'clubParticipant'
      ? 'clubParticipant'
      : 'diver.defaultDiveConfig';
  const { control, setValue } = form;

  const [diveMode, autoSupervisedDiveMode] = useWatch({
    control,
    name: [
      `${basePath}.diveMode`,
      `${basePath}.autoSupervisedDetails.diveMode`,
    ],
  });

  const autoSupervisedDiveModesOptions: ValueLabel<ClubParticipantAutoSupervisedDiveMode>[] =
    useDiveModesOptions({
      currentDiveMode: autoSupervisedDiveMode,
      format: 'short-ref-label',
      type: 'autosupervised',
    }) as ValueLabel<ClubParticipantAutoSupervisedDiveMode>[];

  // ici ce sont les formations du club qui encadre, donc pas limitées à celles du centre de plongée
  const trainingOptionsAll: ValueLabel<DiveTrainingReference>[] =
    useTrainingOptionsAll();

  const supervisedCertificationOptions: ValueLabel<DiveCertificationReference>[] =
    useSupervisedCertificationOptions();

  const autonomousCertificationOptions: ValueLabel<DiveCertificationReference>[] =
    useAutonomousCertificationOptions();

  const instructorCertificationOptions: ValueLabel<DiveCertificationReference>[] =
    useInstructorCertificationOptions();

  return diveMode === 'autoSupervised' ? (
    <>
      <AppFormControlRHF
        className={clsx('w-full', classNameEveryField)}
        label={'Plongée réelle'}
        control={control}
        name={`${basePath}.autoSupervisedDetails.diveMode`}
        required={false}
        renderComponent={(props) => (
          <AppSingleAutocomplete2RHF
            {...props}
            options={autoSupervisedDiveModesOptions}
            onChange={(
              autoSupervisedDiveMode: ClubParticipantAutoSupervisedDiveMode,
            ) => {
              onAutoSupervisedDiveModeChange &&
                onAutoSupervisedDiveModeChange(autoSupervisedDiveMode);
            }}
          />
        )}
      />
      {autoSupervisedDiveMode === 'training' && (
        <AppFormControlRHF
          className={clsx('w-full', classNameEveryField)}
          label="Formation"
          control={control}
          name={`${basePath}.autoSupervisedDetails.trainingReference`}
          required={false} // 17/05/2022 : le niveau n'est plus obligatoire
          renderComponent={(props) => (
            // ici ce sont les formations du club qui encadre, donc pas limitées à celles du centre de plongée
            <AppSingleAutocomplete2RHF
              {...props}
              options={trainingOptionsAll}
            />
          )}
        />
      )}
      {autoSupervisedDiveMode === 'supervised' && (
        <AppFormControlRHF
          className={clsx('w-full', classNameEveryField)}
          label="Niveau"
          control={control}
          name={`${basePath}.autoSupervisedDetails.certificationReference`}
          required={false}
          renderComponent={(props) => (
            <AppSingleAutocomplete2RHF
              {...props}
              options={supervisedCertificationOptions}
            />
          )}
        />
      )}
      {autoSupervisedDiveMode === 'autonomous' && (
        <AppFormControlRHF
          className={clsx('w-full', classNameEveryField)}
          label="Niveau"
          control={control}
          name={`${basePath}.autoSupervisedDetails.certificationReference`}
          required={false}
          renderComponent={(props) => (
            <AppSingleAutocomplete2RHF
              {...props}
              options={autonomousCertificationOptions}
            />
          )}
        />
      )}
      {autoSupervisedDiveMode === 'instructor' && (
        <AppFormControlRHF
          className={clsx('w-full', classNameEveryField)}
          label="Niveau"
          control={control}
          name={`${basePath}.autoSupervisedDetails.certificationReference`}
          required={false}
          renderComponent={(props) => (
            <AppSingleAutocomplete2RHF
              {...props}
              options={instructorCertificationOptions}
            />
          )}
        />
      )}
    </>
  ) : null;
};
