import { DiveSession } from '@mabadive/app-common-model';
import {
  clubDiveSessionThemeBuilder,
  diveSessionTitleBuilder,
} from '@mabadive/app-common-services';
import React, { useMemo } from 'react';
import { useDiveCenterResume } from 'src/business/club/data/hooks';
import { ClubDiveSessionBoats } from '../components/ClubDiveSessionBoats';
import { DiveSiteIconDef, useDiveSiteIcon } from '../hooks';
import { ClubDiveSessionHeaderTitleDbBlock } from './ClubDiveSessionHeaderTitleDbBlock';
import { ClubDiveSessionNameLight } from './ClubDiveSessionNameLight';

export const ClubDiveSessionHeaderTitle = function ({
  diveSession: session,
  hideBoats = false,
  className = '',
}: {
  diveSession: Pick<
    DiveSession,
    | 'name'
    | 'status'
    | 'special'
    | 'time'
    | 'diveSiteId'
    | 'boatsIds'
    | 'diveTourSession2'
    | 'emojiId'
  >;
  hideBoats?: boolean;
  className?: string;
}) {
  const { timeDayPeriod } = useMemo(
    () =>
      clubDiveSessionThemeBuilder.buildSessionAttributes({
        diveSession: session,
      }),
    [session],
  );

  const diveCenterResume = useDiveCenterResume();
  const clubResume = diveCenterResume?.clubResume;

  const diveCenterId = diveCenterResume?._id;
  const clubReference = diveCenterResume?.clubReference;

  const sessionTitle = useMemo(
    () => diveSessionTitleBuilder.build(session),
    [session],
  );
  const diveSiteIcon: DiveSiteIconDef = useDiveSiteIcon(
    session?.diveSiteId,
    session.diveTourSession2?.diveSiteId,
  );

  const hasAnySessionName: boolean =
    session.status !== 'on' ||
    !!diveSiteIcon ||
    !!session?.emojiId ||
    !!sessionTitle ||
    !!session.diveTourSession2;

  const sessionsCount = session.diveTourSession2 ? 2 : 1;

  const boats = useMemo(() => {
    return hideBoats
      ? []
      : (session.boatsIds ?? [])
          .map((boatId) => clubResume?.boats.find((b) => b._id === boatId))
          .filter((x) => !!x);
  }, [clubResume?.boats, hideBoats, session.boatsIds]);

  return hasAnySessionName || sessionsCount > 1 || boats.length ? (
    <div
      className={`px-0.5 leading-3 flex flex-wrap gap-x-5 text-xs sm:text-sm ${className}`}
    >
      {!hideBoats && (
        <ClubDiveSessionBoats
          diveSession={session}
          className="ml-0.5 sm:ml-1"
        />
      )}

      <ClubDiveSessionHeaderTitleDbBlock
        format="full"
        sessionsCount={sessionsCount}
        timeDayPeriod={timeDayPeriod}
      />

      {hasAnySessionName && (
        <ClubDiveSessionNameLight
          className={'font-bold'}
          diveSession={session}
          truncateText={true}
        />
      )}
    </div>
  ) : null;
};
