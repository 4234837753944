import { AppDocRef, PRO_AppDocResume } from '@mabadive/app-common-model';
import { dataSorter, dateService } from '@mabadive/app-common-services';
import {
  AppDropzoneFileTypeIcon,
  AppIcon,
} from '../../../../../../../../_core/modules/layout/icons';
import { PRO_BookingMemberFull_WithDocs } from '../../../../DiverBookingPage/models';
import {
  DiverBookingSpace_DocDataResume,
  DiverBookingSpace_DocDataResume_Status,
} from '../model/DiverBookingSpace_DocDataResume.type';

export const docDataResumeBuilder_DocOnly = {
  buildDocForm,
};

function buildDocForm({
  bookingMemberFull,
  docRef,
  icon: iconInput,
  title,
}: {
  bookingMemberFull: PRO_BookingMemberFull_WithDocs;
  docRef: AppDocRef;
  icon?: AppIcon;
  title: string;
}): DiverBookingSpace_DocDataResume {
  const docResume = getDocResume(bookingMemberFull, docRef);

  const { status, issueDate } = buildAttributes({
    docResume,
  });

  const icon: AppIcon =
    iconInput ?? AppDropzoneFileTypeIcon[docResume?.fileType];

  const docDataResume: DiverBookingSpace_DocDataResume = {
    title,
    issueDate,
    status,
    appDoc: docResume,
    icon,
  };
  return docDataResume;
}

function getDocResume(
  bookingMemberFull: PRO_BookingMemberFull_WithDocs,
  docRef: AppDocRef,
) {
  const docResumes = bookingMemberFull.docResumes.filter(
    (x) => x.docRef === docRef,
  );
  if (docResumes.length > 1) {
    const sorted = dataSorter.sortMultiple(docResumes, {
      getSortAttributes: (x) => [
        {
          value: x.details?.docDate,
          asc: false,
        },
        {
          value:
            x.status === 'validated'
              ? 1
              : x.status === 'submitted'
              ? 2
              : x.status === 'rejected'
              ? 3
              : 4,
          asc: false,
        },
      ],
    });
    // on retourne le premier d'après les critères de tri
    return sorted?.[0];
  } else {
    // on retourne le premier, ou rien
    return docResumes?.[0];
  }
}
function buildAttributes({ docResume }: { docResume: PRO_AppDocResume }): {
  status: DiverBookingSpace_DocDataResume_Status;
  issueDate: Date;
} {
  if (docResume) {
    // on se base sur un doc
    let issueDate = docResume.details?.docDate;

    const docValidityInYears = docResume.details?.docValidityInYears ?? 10;
    let status: DiverBookingSpace_DocDataResume_Status;
    if (issueDate && dateService.getAge(issueDate) >= docValidityInYears) {
      status = 'expired';
    } else {
      status = docResume?.status ?? 'submitted';
    }

    return {
      status,
      issueDate,
    };
  } else {
    return {
      status: 'missing',
      issueDate: undefined,
    };
  }
}
