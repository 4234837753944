/* eslint-disable @typescript-eslint/no-unused-vars */
import { APP_INQUIRY_TYPES, AppInquiryType } from '@mabadive/app-common-model';
import { inquiryFormatter } from '@mabadive/app-common-services';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { useClubResume } from 'src/business/club/data/hooks';
import {
  AppFormControlRHF,
  AppInputColorRHF,
  AppInputRHF,
  AppSingleSelect2HeadlessUIRHF,
} from 'src/lib/form';
import { AppIconsInquiry } from '../../../../../../business/_core/modules/layout/icons';
import { useAppSecurityUserHasRole } from '../../../../../../business/auth/services';
import { ValueLabel } from '../../../../../../business/club/modules/_common/form';
import { useDiveModesOptions } from '../../../../../_components/options';
import { inqueryDefaultBuilder } from '../../_services';
import { ClubSettingsInquiryEditPagePanelLocalState } from '../ClubSettingsInquiryEditPagePanel/useClubSettingsInquiryEditPagePanelLocalState.hook';
import { AppInquiryTypeLabel } from './AppInquiryTypeLabel';
import { AppInquiryFormModel } from './model';

export const ClubSettingsInquiryEditPageForm_TabTitleSettings = ({
  localState,
  form,
  className,
}: {
  localState: ClubSettingsInquiryEditPagePanelLocalState;
  form: UseFormReturn<AppInquiryFormModel>;
  className?: string;
}) => {
  const { state, data } = localState;

  const clubResume = useClubResume();
  const isMultiDiveCenters = clubResume.diveCenters.length > 1;

  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const [color, inquiryType, enabled, autoActivationEnabled] = useWatch({
    control,
    name: [
      'settings.color',
      'type',
      'settings.enabled',
      'settings.autoActivation.enabled',
    ],
  });

  const diveModesOptions = useDiveModesOptions({
    type: 'all-included-disabled',
    format: 'short-ref',
  });

  const InquiryIcon = AppIconsInquiry[inquiryType];

  const inquiryTypesOptions: ValueLabel<AppInquiryType, React.ReactNode>[] =
    useMemo(
      () =>
        APP_INQUIRY_TYPES.map((type) => {
          const option: ValueLabel<AppInquiryType, React.ReactNode> = {
            value: type,
            label: (
              <AppInquiryTypeLabel
                className="px-1 py-px text-xs mr-2 sm:mr-4 md:mr-5"
                type={type}
              />
            ),
          };
          return option;
        }),
      [],
    );

  const isSuperAdmin = useAppSecurityUserHasRole('super-admin');

  return (
    <div
      className={clsx('app-card p-4 border-t-8', className)}
      style={{
        borderColor: color,
      }}
    >
      <div className={clsx('grid gap-x-4 gap-y-8')}>
        <div
          className={clsx('w-full grid sm:grid-cols-2 md:grid-cols-4 gap-4')}
        >
          <AppFormControlRHF
            className={'w-full'}
            label="Type de questionaire"
            control={control}
            name={'type'}
            renderComponent={(props) => (
              <AppSingleSelect2HeadlessUIRHF
                disableClearButton={true}
                theme={'tailwind'}
                className={'w-full'}
                options={inquiryTypesOptions}
                {...props}
                // IMPORTANT: si on utilise le champ "disabled", ça fait complètement bugger le formulaire (si on déplace une section, tout disparait!)
                // disabled={state.mode === 'edit'}
                onChange={(inquiryType: AppInquiryType) => {
                  if (inquiryType) {
                    setValue(
                      'name',
                      inquiryFormatter.formatInquiryType(inquiryType),
                    );
                    const { sections, settings } =
                      inqueryDefaultBuilder.buildFromType({
                        inquiryType,
                      });
                    setValue('sections', sections);
                    setValue('settings.shortName', settings.shortName);
                    setValue('settings.color', settings.color);
                    setValue(
                      'settings.autoActivation',
                      settings.autoActivation,
                    );
                  }
                }}
              />
            )}
          />
          {inquiryType && (
            <>
              <AppFormControlRHF
                className={'w-full'}
                label="Nom du questionnaire"
                control={control}
                name={'name'}
                required={true}
                renderComponent={(props) => <AppInputRHF {...props} />}
              />
              <AppFormControlRHF
                className={'w-full'}
                label="Diminutif"
                control={control}
                name={'settings.shortName'}
                required={true}
                validation={{
                  rules: {
                    minLength: 1,
                    maxLength: 6,
                  },
                }}
                renderComponent={(props) => <AppInputRHF {...props} />}
              />
              <AppFormControlRHF
                className={'w-full'}
                label={'Couleur'}
                required={true}
                control={control}
                name={'settings.color'}
                renderComponent={(props) => (
                  <AppInputColorRHF
                    {...props}
                    required={false}
                    className={'my-2 text-center text-xl font-bold'}
                  />
                )}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};
