/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback } from 'react';
import { Control, UseFormReturn, useWatch } from 'react-hook-form';
import { AppFormControlRHF } from 'src/lib/form/components/AppFormControl/AppFormControlRHF';

import {
  CLUB_DEFAULT_UI_DIVE_MODE,
  DiveMode,
} from '@mabadive/app-common-model';
import { diveModeFormatter } from '@mabadive/app-common-services';
import clsx from 'clsx';
import { ClubSettingsSection } from 'src/pages/SE-settings/_core';
import { AppButton } from '../../../../../../../business/_core/modules/layout';
import { AppIcons } from '../../../../../../../business/_core/modules/layout/icons';
import { useAppSecurityUserHasRole } from '../../../../../../../business/auth/services';
import { AppInputColorRHF, AppInputRHF } from '../../../../../../../lib/form';
import { ClubSettingsServicesFormModel } from '../../../_model';

function ClubSettingsServicesActivitiesFormColors_DiveModeSection({
  control,
  diveMode,
  displayExpertMode,
}: {
  control: Control<ClubSettingsServicesFormModel, any>;
  diveMode: DiveMode;
  displayExpertMode: boolean;
}) {
  const label = diveModeFormatter.formatDiveMode(diveMode, {
    format: 'long-label',
    // diveModesConf: clubSettings?.ui?.diveMode, NOTE: ici on ne surcharge pas le label, on garde celui par défaut
  });
  const colorName = `clubSettings.ui.diveMode.${diveMode}.color` as const;
  const refName = `clubSettings.ui.diveMode.${diveMode}.ref` as const;
  const labelShortName =
    `clubSettings.ui.diveMode.${diveMode}.labelShort` as const;
  const labelLongName =
    `clubSettings.ui.diveMode.${diveMode}.labelLong` as const;

  const isSuperAdmin = useAppSecurityUserHasRole('super-admin');

  return (
    <div
      className={clsx(
        'grid gap-4 grid-cols-2',
        displayExpertMode
          ? 'sm:grid-cols-3  2xl:grid-cols-6'
          : 'sm:grid-cols-4  2xl:grid-cols-4',
      )}
    >
      <AppFormControlRHF
        control={control}
        label={label}
        name={colorName}
        required={true}
        renderComponent={(props) => <AppInputColorRHF {...props} />}
      />
      <AppFormControlRHF
        control={control}
        label="Diminutif"
        name={refName}
        validation={{
          rules: {
            maxLength: 3,
          },
        }}
        required={true}
        renderComponent={(props) => <AppInputRHF fullWidth={true} {...props} />}
      />{' '}
      <AppFormControlRHF
        control={control}
        label="Nom court"
        name={labelShortName}
        validation={{
          rules: {
            maxLength: 20,
          },
        }}
        required={true}
        renderComponent={(props) => <AppInputRHF fullWidth={true} {...props} />}
      />{' '}
      <AppFormControlRHF
        control={control}
        label="Nom complet"
        name={labelLongName}
        validation={{
          rules: {
            maxLength: 30,
          },
        }}
        required={true}
        renderComponent={(props) => <AppInputRHF fullWidth={true} {...props} />}
      />
      {displayExpertMode && (
        <>
          <AppFormControlRHF
            visibility="super-admin"
            control={control}
            label="Min participants (détaillé)"
            helpDescription="Compacter les palanquées en vue détaillé sur le planning hebdomadaire à partir de N participants"
            name={
              `clubSettings.ui.diveMode.${diveMode}.shrinkGroup.minPartFull` as const
            }
            validation={{
              rules: {
                min: 1,
                max: 100,
              },
            }}
            required={true}
            renderComponent={(props) => (
              <AppInputRHF fullWidth={true} {...props} type="number" />
            )}
          />
          <AppFormControlRHF
            visibility="super-admin"
            control={control}
            label="Min participants (compact)"
            helpDescription="Compacter les palanquées en vue compacte sur le planning hebdomadaire à partir de N participants"
            name={
              `clubSettings.ui.diveMode.${diveMode}.shrinkGroup.minPartCompact` as const
            }
            validation={{
              rules: {
                min: 1,
                max: 100,
              },
            }}
            required={true}
            renderComponent={(props) => (
              <AppInputRHF fullWidth={true} {...props} type="number" />
            )}
          />
        </>
      )}
    </div>
  );
}

export const ClubSettingsServicesActivitiesFormColors = ({
  form,
  displayExpertMode,
}: {
  form: UseFormReturn<ClubSettingsServicesFormModel>;
  displayExpertMode: boolean;
}) => {
  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const [
    firstDiveEnabled,
    supervisedEnabled,
    autonomousEnabled,
    instructorEnabled,
    autoSupervisedEnabled,
    trainingEnabled,
    theoreticalTrainingEnabled,
    observerEnabled,
    snorkelingEnabled,
    snorkelingSupervisedEnabled,
    watchingTourEnabled,
    freeDiveEnabled,
    freeDiveAutoEnabled,
    freeDiveTrainingEnabled,
  ] = useWatch({
    control,
    name: [
      'clubSettings.firstDive.enabled',
      'clubSettings.supervised.enabled',
      'clubSettings.autonomous.enabled',
      'clubSettings.instructor.enabled',
      'clubSettings.autoSupervised.enabled',
      'clubSettings.training.enabled',
      'clubSettings.theoreticalTraining.enabled',
      'clubSettings.observer.enabled',
      'clubSettings.snorkeling.enabled',
      'clubSettings.snorkelingSupervised.enabled',
      'clubSettings.watchingTour.enabled',
      'clubSettings.freeDive.enabled',
      'clubSettings.ui.diveMode.free-dive-auto.enabled',
      'clubSettings.ui.diveMode.free-dive-training.enabled',
    ],
  });

  const resetCustomization = useCallback(() => {
    setValue('clubSettings.ui.diveMode', CLUB_DEFAULT_UI_DIVE_MODE);
  }, [setValue]);

  return (
    <ClubSettingsSection title="Personnalisation de l'apparence des prestations">
      <div className="grid gap-4">
        {firstDiveEnabled && (
          <ClubSettingsServicesActivitiesFormColors_DiveModeSection
            control={control}
            diveMode="first-dive"
            displayExpertMode={displayExpertMode}
          />
        )}
        {supervisedEnabled && (
          <ClubSettingsServicesActivitiesFormColors_DiveModeSection
            control={control}
            diveMode="supervised"
            displayExpertMode={displayExpertMode}
          />
        )}
        {autonomousEnabled && (
          <ClubSettingsServicesActivitiesFormColors_DiveModeSection
            control={control}
            diveMode="autonomous"
            displayExpertMode={displayExpertMode}
          />
        )}
        {instructorEnabled && (
          <ClubSettingsServicesActivitiesFormColors_DiveModeSection
            control={control}
            diveMode="instructor"
            displayExpertMode={displayExpertMode}
          />
        )}
        {autoSupervisedEnabled && (
          <ClubSettingsServicesActivitiesFormColors_DiveModeSection
            control={control}
            diveMode="autoSupervised"
            displayExpertMode={displayExpertMode}
          />
        )}
        {trainingEnabled && (
          <ClubSettingsServicesActivitiesFormColors_DiveModeSection
            control={control}
            diveMode="training"
            displayExpertMode={displayExpertMode}
          />
        )}
        {theoreticalTrainingEnabled && (
          <ClubSettingsServicesActivitiesFormColors_DiveModeSection
            control={control}
            diveMode="theoretical-training"
            displayExpertMode={displayExpertMode}
          />
        )}
        {observerEnabled && (
          <ClubSettingsServicesActivitiesFormColors_DiveModeSection
            control={control}
            diveMode="observer"
            displayExpertMode={displayExpertMode}
          />
        )}
        {snorkelingEnabled && (
          <ClubSettingsServicesActivitiesFormColors_DiveModeSection
            control={control}
            diveMode="snorkeling"
            displayExpertMode={displayExpertMode}
          />
        )}
        {snorkelingSupervisedEnabled && (
          <ClubSettingsServicesActivitiesFormColors_DiveModeSection
            control={control}
            diveMode="snorkelingSupervised"
            displayExpertMode={displayExpertMode}
          />
        )}
        {watchingTourEnabled && (
          <ClubSettingsServicesActivitiesFormColors_DiveModeSection
            control={control}
            diveMode="watchingTour"
            displayExpertMode={displayExpertMode}
          />
        )}
        {freeDiveEnabled && (
          <ClubSettingsServicesActivitiesFormColors_DiveModeSection
            control={control}
            diveMode="free-dive"
            displayExpertMode={displayExpertMode}
          />
        )}
        {freeDiveAutoEnabled && (
          <ClubSettingsServicesActivitiesFormColors_DiveModeSection
            control={control}
            diveMode="free-dive-auto"
            displayExpertMode={displayExpertMode}
          />
        )}{' '}
        {freeDiveTrainingEnabled && (
          <ClubSettingsServicesActivitiesFormColors_DiveModeSection
            control={control}
            diveMode="free-dive-training"
            displayExpertMode={displayExpertMode}
          />
        )}
      </div>
      <AppButton
        size="normal"
        icon={AppIcons.action.cancel}
        tabIndex={500}
        color="gray-outline-light"
        onClick={() => {
          resetCustomization();
        }}
      >
        Ré-initialiser les paramètres par défaut
      </AppButton>
    </ClubSettingsSection>
  );
};
