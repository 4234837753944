/* eslint-disable @typescript-eslint/no-unused-vars */
import { AppInquiryQuestion } from '@mabadive/app-common-model';
import { jsonParser, uuidGenerator } from '@mabadive/app-common-services';
import clsx from 'clsx';
import React, { useCallback, useState } from 'react';
import {
  FieldArrayWithId,
  UseFormReturn,
  useFieldArray,
} from 'react-hook-form';
import { AppButton } from 'src/business/_core/modules/layout';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { ClubSettingsEquipmentEditPageFormModelOnClickAction } from '../../../../../SE-02_account/equipment/ClubSettingsEquipmentEditPage/ClubSettingsEquipmentEditPageForm/ClubSettingsEquipmentEditPageFormModel';
import { inqueryQuestionBuilder } from '../../../_services';
import { ClubSettingsInquiryEditPagePanelLocalState } from '../../ClubSettingsInquiryEditPagePanel';
import { AppInquiryFormModel } from '../model';
import { ClubSettingsInquiryEditPageFormQuestion } from './ClubSettingsInquiryEditPageFormQuestion';

export const ClubSettingsInquiryEditPageFormQuestions = ({
  localState,
  sectionIndex,
  form,
  className,
}: {
  localState: ClubSettingsInquiryEditPagePanelLocalState;
  sectionIndex: number;
  form: UseFormReturn<AppInquiryFormModel, object>;
  className?: string;
}) => {
  const {
    register,
    handleSubmit,
    watch,
    formState,
    control,
    setValue,
    reset,
    getValues,
  } = form;

  const questionFormName = `sections.${sectionIndex}.questions` as const;
  // const [sectionEnabled] = useWatch({
  //   control,
  //   name: [`${questionsGroupName}.enabled`],
  // });

  const questionsFieldArray = useFieldArray({
    control,
    name: questionFormName,
    keyName: 'questionId',
    shouldUnregister: false, // si true, le tableau est vidé si le composant est masqué (unmount)
  });

  const [sectionAnimations, setModelAnimations] =
    useState<{
      update: Date;
      up?: number;
      down?: number;
      remove?: number;
      clone?: number;
    }>();

  const isRecentAnimationUpdate =
    new Date().getTime() - sectionAnimations?.update?.getTime() < 1000;

  const onClick = useCallback(
    (
      field: FieldArrayWithId<
        AppInquiryFormModel,
        `sections.${number}.questions`,
        'questionId'
      >,
      questionIndex: number,
      action: ClubSettingsEquipmentEditPageFormModelOnClickAction,
    ) => {
      const from = questionIndex;
      const update = new Date();
      switch (action) {
        case 'move-down': {
          questionsFieldArray.move(from, from + 1);
          setModelAnimations({ update, up: from + 1, down: from });
          break;
        }
        case 'move-up': {
          questionsFieldArray.move(from, from - 1);
          setModelAnimations({ update, up: from, down: from - 1 });
          break;
        }
        case 'delete': {
          // setModelAnimations({ update, remove: from });
          questionsFieldArray.remove(from);
          // setTimeout(() => questionsFieldArray.remove(from), 100);
          break;
        }
        case 'clone': {
          if (field) {
            const original: AppInquiryQuestion = getValues(
              `${questionFormName}.${questionIndex}`,
            );
            const clone: AppInquiryQuestion =
              jsonParser.parseJSONWithDates<AppInquiryQuestion>(
                JSON.stringify({
                  ...original,
                  questionId: uuidGenerator.random(),
                }),
              );

            questionsFieldArray.insert(from + 1, clone as any);
            setModelAnimations({ update, clone: from });
            break;
          }
        }
      }
    },
    [getValues, questionFormName, questionsFieldArray],
  );

  return (
    <div className={clsx('grid gap-2', className)}>
      <div className="font-medium text-gray-800 uppercase">Questions</div>
      <div className="grid gap-2">
        {questionsFieldArray.fields.map((field, questionIndex) => {
          return (
            <ClubSettingsInquiryEditPageFormQuestion
              key={`${field.questionId}-${questionIndex}`}
              form={form}
              fieldArray={questionsFieldArray}
              field={field}
              sectionIndex={sectionIndex}
              questionIndex={questionIndex}
              className={clsx(
                isRecentAnimationUpdate &&
                  sectionAnimations?.up === questionIndex &&
                  'animate-move-up',
                isRecentAnimationUpdate &&
                  sectionAnimations?.down === questionIndex &&
                  'animate-move-down',
                isRecentAnimationUpdate &&
                  sectionAnimations?.clone === questionIndex &&
                  'animate-create',
                isRecentAnimationUpdate &&
                  sectionAnimations?.remove === questionIndex &&
                  'animate-remove',
              )}
              onClick={(action) => onClick(field, questionIndex, action)}
            />
          );
        })}
        <AppButton
          className="my-4"
          size="normal"
          icon={AppHeroIcons.actionAdd}
          color="primary-outline-light"
          onClick={() => {
            const newQuestion = inqueryQuestionBuilder.buildQuestion({
              sortIndex: 1,
              questionTitle: 'Avez-vous ... ?',
            });

            questionsFieldArray.append(newQuestion);
          }}
        >
          Ajouter une question
        </AppButton>
      </div>
    </div>
  );
};
