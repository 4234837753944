import {
  ClubPublicData,
  ClubPublicSettings,
  DiveCenterPublicData,
  DiveCenterPublicSettings,
} from '@mabadive/app-common-model';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { AppIconButtonFreeColor } from 'src/business/_core/modules/layout';
import { AppIcons } from 'src/business/_core/modules/layout/icons';

export function DiveCenterBrandSocialIcons({
  clubPublicData,
  clubPublicSettings,
  diveCenterPublicData,
  diveCenterPublicSettings,
  className = '',
}: {
  clubPublicData: ClubPublicData;
  clubPublicSettings: ClubPublicSettings;
  diveCenterPublicData: DiveCenterPublicData;
  diveCenterPublicSettings: DiveCenterPublicSettings;
  className?: string;
}) {
  const { appUiTheme } = clubPublicSettings;
  const iconClassName = `
    rounded w-9 h-9
    border border-[#1d98c9]
    fill-white text-[#1c87b1]
    hover:text-[#1a7498] hover:fill-white
    hover:border-[#1c87b1] hover:bg-[#b6e4f6]"
  `;

  const iconStyle: React.CSSProperties = useMemo(
    () => ({
      //  border-b-2 pb-1
      color: appUiTheme.buttonPrimary.bgColor ?? '#555',
    }),
    [appUiTheme.buttonPrimary.bgColor],
  );

  const primaryIconButtonTheme = useMemo(
    () => ({
      bgColor: clubPublicSettings.appUiTheme.buttonPrimary.bgColor,
      textColor: clubPublicSettings.appUiTheme.buttonPrimary.textColor,
    }),
    [
      clubPublicSettings.appUiTheme.buttonPrimary.bgColor,
      clubPublicSettings.appUiTheme.buttonPrimary.textColor,
    ],
  );

  return !clubPublicData ? null : (
    <div className={clsx('flex gap-1', className)}>
      {clubPublicData?.links?.webSite?.url && (
        <AppIconButtonFreeColor
          title="Site Web"
          icon={AppIcons.socialMedia.website}
          href={clubPublicData?.links?.webSite?.url}
          externalLink={true}
          {...primaryIconButtonTheme}
        />
      )}
      {clubPublicData?.links?.facebook?.url && (
        <AppIconButtonFreeColor
          title="Facebook"
          icon={AppIcons.socialMedia.facebook}
          href={clubPublicData?.links?.facebook?.url}
          externalLink={true}
          {...primaryIconButtonTheme}
        />
      )}
      {clubPublicData?.links?.instagram?.url && (
        <AppIconButtonFreeColor
          title="Instagram"
          icon={AppIcons.socialMedia.instagram}
          href={clubPublicData?.links?.instagram?.url}
          externalLink={true}
          {...primaryIconButtonTheme}
        />
      )}{' '}
      {clubPublicData?.links?.tripAdvisor?.url && (
        <AppIconButtonFreeColor
          title="Trip Advisor"
          icon={AppIcons.socialMedia.tripAdvisor}
          href={clubPublicData?.links?.tripAdvisor?.url}
          externalLink={true}
          {...primaryIconButtonTheme}
        />
      )}
      {clubPublicData?.links?.youtube?.url && (
        <AppIconButtonFreeColor
          title="Youtube"
          icon={AppIcons.socialMedia.youtube}
          href={clubPublicData?.links?.youtube?.url}
          externalLink={true}
          {...primaryIconButtonTheme}
        />
      )}
    </div>
  );
}
