import {
  ClubEvent,
  ClubPlanningLightSessionDef,
  DiveCenterDailyConfig,
  DiveCenterResume,
  DiveSessionResumeFull,
  StaffMemberResumeSessionsModel,
  WeekPlanning,
} from '@mabadive/app-common-model';
import {
  calendarWeekModelBuilder,
  clubDiveSessionThemeBuilder,
  commonDiveSessionReferenceParser,
  dateService,
  diveSessionFilter,
  diveSessionsSorter,
  staffMemberResumeSessionsBuilder,
  weekPlanningBuilder,
} from '@mabadive/app-common-services';
import { useMemo } from 'react';
import { useAppSecurityUserHasRole } from 'src/business/auth/services';
import {} from '../club-planning/_components';
import { PlanningViewConfig } from '../club-planning/_model';
import { ClubPlanningDayFull } from './model';
import { ClubPlanningLightDayDef } from './model/ClubPlanningLightDayDef.type';

export function useClubPlanningDayFull({
  diveSessionResumes,
  clubEvents,
  dailyConfigs,
  weekPlanning,
  diveCenterResume,
  showVirtualSessions,
  planningConfig,
}: {
  diveSessionResumes: DiveSessionResumeFull[];
  clubEvents: ClubEvent[];
  dailyConfigs: DiveCenterDailyConfig[];
  weekPlanning: WeekPlanning;
  diveCenterResume: DiveCenterResume;
  showVirtualSessions: boolean;
  planningConfig: PlanningViewConfig;
}): ClubPlanningDayFull[] {
  const isSuperAdmin = useAppSecurityUserHasRole('super-admin');

  const daysLightBase = useMemo(() => {
    if (diveSessionResumes && weekPlanning) {
      const dayReferences =
        weekPlanningBuilder.buildDayReferences(weekPlanning);

      const weekRealDiveSessions = diveSessionResumes.filter((s) =>
        dayReferences.includes(s.dayReference),
      );

      const weekClubEvents = clubEvents.filter(
        (x) =>
          x.beginDate.getTime() <= weekPlanning.lastDate.getTime() &&
          x.endDate.getTime() >= weekPlanning.firstDate.getTime(),
      );

      return dayReferences.map((dayReference, dayIndex) => {
        const dayDate =
          commonDiveSessionReferenceParser.parseDayReference(dayReference);

        const realDiveSessions = weekRealDiveSessions.filter(
          (s) => dayReference === s.dayReference,
        );
        const dayClubEvents = weekClubEvents.filter(
          (x) =>
            x.beginDate.getTime() <= dayDate.getTime() &&
            x.endDate.getTime() >= dayDate.getTime(),
        );

        const {
          settingsPlanning,
          clubReference,
          _id: diveCenterId,
        } = diveCenterResume;

        const diveSessionResumes = showVirtualSessions
          ? calendarWeekModelBuilder.addVirtualSessions({
              realDiveSessions,
              dayReference,
              clubReference,
              diveCenterId,
              settingsPlanning,
            })
          : realDiveSessions;

        const sortedSessions = diveSessionsSorter.sortDiveSessionsBy(
          diveSessionResumes,
          {
            attributeName: 'time',
            asc: true,
          },
        );

        const date =
          commonDiveSessionReferenceParser.parseDayReference(dayReference);

        const dailyConfig: DiveCenterDailyConfig = dailyConfigs.find(
          (x) => x.dateReference === dayReference,
        );

        const day: ClubPlanningLightDayDef = {
          date,
          theme: dateService.isTodayUTC(date) ? 'today' : 'default',
          dayReference,
          sessions: sortedSessions.map((ds, i) => {
            const x: ClubPlanningLightSessionDef = {
              ...ds,
              timeDayPeriod: clubDiveSessionThemeBuilder.buildTimeDayPeriod(
                ds.time,
              ),
            };
            return x;
          }),
          clubEvents: dayClubEvents,
          dailyConfig,
        };
        return day;
      });
    }
  }, [
    diveSessionResumes,
    weekPlanning,
    clubEvents,
    showVirtualSessions,
    diveCenterResume,
    dailyConfigs,
  ]);

  const daysLight: ClubPlanningLightDayDef[] = useMemo(() => {
    return (daysLightBase ?? [])
      .filter(
        (d) =>
          planningConfig.viewPeriod !== 'day' ||
          dateService.isSameDayUTC(planningConfig.focusDate, d.date),
      )
      .map((d) => {
        const result: ClubPlanningLightDayDef = {
          ...d,
          sessions: d.sessions.filter((x) => {
            if (x.isVirtual && dateService.isPastWeekUTCFromLocal(x.time)) {
              // on masque les sessions virtuelles des semaines passées
              return false;
            }
            if (
              planningConfig.viewPeriod !== 'session' &&
              !planningConfig.showInactiveSessions &&
              x.status !== 'on'
            ) {
              // on masque les sessions annulées ou fermées
              return false;
            }
            if (x.status === 'deleted' && !isSuperAdmin) {
              return false;
            }
            if (
              planningConfig.viewPeriod !== 'session' &&
              !planningConfig.showFullSessions &&
              x.details?.isFull
            ) {
              // on masque les sessions complètes
              return false;
            }
            return true;
          }),
        };
        return result;
      });
  }, [
    daysLightBase,
    isSuperAdmin,
    planningConfig.focusDate,
    planningConfig.showFullSessions,
    planningConfig.showInactiveSessions,
    planningConfig.viewPeriod,
  ]);

  const days: ClubPlanningDayFull[] = useMemo(() => {
    return (daysLight ?? []).map((day) => {
      const isPast =
        !dateService.isTodayUTC(day.date) && dateService.isPastUTC(day.date);

      const {
        diveSessions,
        staffMembersResumeSessions,
      }: {
        diveSessions: ClubPlanningLightSessionDef[];
        staffMembersResumeSessions: StaffMemberResumeSessionsModel[];
      } = staffMemberResumeSessionsBuilder.buildStaffMemberResumeSessions({
        diveCenterResume,
        sessions: day.sessions,
        staffFirstNameBefore: planningConfig.staffFirstNameBefore,
        staffScope: 'all-with-active-working-period',
        dailyConfigs,
        days: daysLight,
      });

      const diveSessionsNotCanceled = diveSessionFilter.filterSessions(
        diveSessions,
        {
          isPast,
          filterCancelled: true,
          filterPastAndEmpty: false,
        },
      );

      const dayFull: ClubPlanningDayFull = {
        ...day,
        diveSessions,
        staffMembersResumeSessions,
        diveSessionsNotCanceled,
      };
      return dayFull;
    });
  }, [dailyConfigs, daysLight, diveCenterResume, planningConfig]);

  return days;
}
