import { BookingGroupType } from '@mabadive/app-common-model';
import { bookingGroupTypeFormatter } from '@mabadive/app-common-services';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { useDisplayBookingGroup } from './useDisplayBookingGroup.hook';

export const BookingGroupTypeLabel = ({
  type,
  label,
  showIndividualWithoutLabel,
  preferLabelOnly,
  maxLabelWidthClassName = 'max-w-[80px]',
  className,
}: {
  type: BookingGroupType;
  label?: string;
  showIndividualWithoutLabel?: boolean;
  preferLabelOnly?: boolean;
  maxLabelWidthClassName?: string;
  className?: string;
}) => {
  const { isVisible, displayType, displayLabel } = useDisplayBookingGroup({
    type,
    label,
    showIndividualWithoutLabel,
    preferLabelOnly,
  });

  const typeClassNames = useMemo(() => {
    switch (type) {
      case 'club':
        return 'bg-app-secondary text-white';
      case 'company':
        return 'bg-gray-600 text-white';
      case 'individual':
        return 'bg-app-primary text-white';
      case 'association':
        return 'bg-purple-600 text-white';
      case 'collectivity':
        return 'bg-pink-600 text-white';
      default:
        return 'bg-white border border-gray-600 text-gray-600';
    }
  }, [type]);

  return isVisible ? (
    <div className={clsx('font-bold', typeClassNames, className)}>
      <div className="flex gap-1 items-center">
        {displayType && type && (
          <div className="">
            {bookingGroupTypeFormatter
              .format(type, {
                format: 'short',
              })
              .toUpperCase()}
          </div>
        )}
        <div className={clsx(maxLabelWidthClassName, 'truncate')}>
          {displayLabel && label}
        </div>
      </div>
    </div>
  ) : null;
};
