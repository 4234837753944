import {
  ClubSettings,
  DIVE_MODES,
  DIVE_MODES_AUTO_SUPERVISED,
  DIVE_MODES_SCUBA,
  DiveMode,
} from '@mabadive/app-common-model';
import { useMemo } from 'react';
import { useClubResume } from 'src/business/club/data/hooks/useClubResume.hook';

export type DiveModeSubGroupId =
  | 'all'
  | 'all-included-disabled'
  | 'autosupervised'
  | 'scuba-diving';

export function useDiveModes({
  currentDiveMode,
  type = 'all',
}: {
  currentDiveMode?: string;
  type?: DiveModeSubGroupId;
}): DiveMode[] {
  const clubResume = useClubResume();
  const clubSettings = clubResume.clubSettings;
  const diveModes =
    type === 'autosupervised'
      ? DIVE_MODES_AUTO_SUPERVISED
      : type === 'scuba-diving'
      ? DIVE_MODES_SCUBA
      : DIVE_MODES;
  return useMemo(
    () =>
      diveModes.filter(
        (dm) =>
          dm === currentDiveMode ||
          type === 'all-included-disabled' ||
          isDiveModeEnabledInClubclubSettings({
            diveMode: dm,
            clubSettings,
          }),
      ),
    [clubSettings, currentDiveMode, diveModes, type],
  );
}

function isDiveModeEnabledInClubclubSettings({
  diveMode,
  clubSettings,
}: {
  diveMode: DiveMode;
  clubSettings: ClubSettings;
}): boolean {
  switch (diveMode) {
    case 'observer':
      return clubSettings.observer?.enabled;
    case 'instructor':
      return clubSettings.instructor?.enabled;
    case 'snorkeling':
      return clubSettings.snorkeling?.enabled;
    case 'snorkelingSupervised':
      return clubSettings.snorkelingSupervised?.enabled;
    case 'watchingTour':
      return clubSettings.watchingTour?.enabled;
    case 'first-dive':
      return clubSettings.firstDive?.enabled;
    case 'training':
      return clubSettings.training?.enabled;
    case 'theoretical-training':
      return clubSettings.theoreticalTraining?.enabled;
    case 'supervised':
      return clubSettings.supervised?.enabled;
    case 'autonomous':
      return clubSettings.autonomous?.enabled;
    case 'autoSupervised':
      return clubSettings.autoSupervised?.enabled;
    case 'free-dive':
      return clubSettings.freeDive?.enabled;
    case 'free-dive-auto':
      return clubSettings.ui?.diveMode?.['free-dive-auto']?.enabled;
    case 'free-dive-training':
      return clubSettings.ui?.diveMode?.['free-dive-training']?.enabled;
    case 'unknown':
      return true;
  }
}
