/* eslint-disable no-lone-blocks */
/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  ClubProductPackageAttributeProductType,
  ClubProductPackageAttributesDive,
  ClubProductPackageAttributesDiveMode,
  ClubProductPackageAttributesProduct,
  ClubProductPackageAttributesSalesCriteria,
  ClubProductPackageAttributesTraining,
  ClubProductPackageNamingConfiguration,
  ClubProductPackageOffer,
  ClubProductPackageOfferCategory,
  ClubProductPackageOfferView,
  ClubPublicSettings,
  ClubSettings,
  DIVE_SERVICE_ORGANIZATIONS,
  DIVE_TRAININGS,
  DiveServiceOrganizationReference,
} from '@mabadive/app-common-model';
import {
  clubProductPackageOfferViewSorter,
  commonEntityBuilder,
} from '@mabadive/app-common-services';
import { clubOfferDiveEditorResultBuilder } from 'src/business/club/modules/club-settings/club-settings-offers/club-settings-offer-dive/ClubOfferDiveEditor/clubOfferDiveEditorResultBuilder.service';

export const clubSettingsProductOfferBuilder = {
  buildNewOffer,
  buildNewOfferProductExtra,
  buildNewOfferProductStore,
  buildNewOffersFromTrainingOrg,
  buildNewOffersExplo,
  buildNewOffersExploSpecial,
};
export type CreateOfferPayloadMeta = {
  createDisplayComment?: string;
};
export type CreateOfferPayload = {
  offer: ClubProductPackageOfferView;
  meta?: CreateOfferPayloadMeta;
};

function buildNewOffer(
  {
    reference,
    label,
    clubReference,
    diveMode,
    trainingAttributes,
    diveAttributes,
    productAttributes,
    namingConfiguration,
    comment,
    i,
  }: {
    reference?: string;
    label?: string;
    clubReference: string;
    diveMode: ClubProductPackageAttributesDiveMode;
    trainingAttributes?: Partial<ClubProductPackageAttributesTraining>;
    diveAttributes?: ClubProductPackageAttributesDive;
    productAttributes?: Partial<ClubProductPackageAttributesProduct>;
    namingConfiguration?: ClubProductPackageNamingConfiguration;
    comment?: string;
    i: number;
  },
  meta?: CreateOfferPayloadMeta,
): CreateOfferPayload {
  const offer = commonEntityBuilder.buildEntity<ClubProductPackageOffer>({
    reference: reference ?? `new-${i}-${Date.now()}`, // tmp
    clubReference: clubReference,
    price: undefined,
    thirdPartyCollectPrice: 0,
    agencyNonCommissionable: undefined,
    productPackage: {
      comment,
      label,
      reference,
      type: diveMode === 'training' ? 'training' : 'dive',
      productAttributes: {
        includeDives: true,
        ...(productAttributes ?? {}),
      },
      diveAttributes: diveAttributes ?? {
        diveMode,
        divesCount: 1,
        divePriceType: 'single',
        diveType:
          diveMode === 'free-dive' ||
          diveMode === 'snorkeling' ||
          diveMode === 'snorkelingSupervised' ||
          diveMode === 'watchingTour'
            ? 'free'
            : diveMode === 'observer'
            ? 'observer'
            : 'scuba',
        firstDiveTrainingReference: null,
        extraDiveModes: null,
        minDepth: null,
        maxDepth: null,
        successiveDivesCount: null,
        divesCountMax: null,
        divesCountInfinite: null,
        divePackageAttributes: null,
        diveSingleAttributes: null,
        equipment: null,
        supervision: null,
        minDistance: null,
        maxDistance: null,
        specialDiveType: null,
      },
      salesCriteria: {
        standalone: null,
        favorite: null,
        extraCostTraining: null,
        extraCostDivePlan: null,
        extraCostUnitDive: null,
        extraCostSession: null,
        residentType: null,
        dontSelectByDefault: null,
        diveSessionType: null,
        diveSessionTheme: null,
      },
      namingConfiguration: namingConfiguration ?? {
        includeEquipment: false,
        includeSupervision: false,
        includeDefaultName: false,
        overrideDefaultName: {
          enabled: false,
          prefix: '',
          name: '',
          label: '',
          suffix: '',
        },
      },
      trainingAttributes:
        trainingAttributes as unknown as ClubProductPackageAttributesTraining,
    },
  });
  return {
    offer,
    meta,
  };
}

function buildNewOfferProductExtra({
  clubReference,
  defaultOrganizationReference,
  label,
  productType,
  salesCriteria,
}: {
  clubReference: string;
  defaultOrganizationReference?: DiveServiceOrganizationReference;
  label: string;
  productType: ClubProductPackageAttributeProductType;
  salesCriteria: ClubProductPackageAttributesSalesCriteria;
}): CreateOfferPayload {
  const reference =
    clubOfferDiveEditorResultBuilder.rebuildReferenceProductExtra({
      clubReference,
      productType,
    });
  const newOffer = commonEntityBuilder.buildEntity<ClubProductPackageOffer>({
    reference,
    clubReference: clubReference,
    price: undefined,
    productPackage: {
      reference,
      type: 'product',
      productAttributes: {
        includeDives: false,
        defaultOrganizationReference,
        organizationReferences: defaultOrganizationReference
          ? [defaultOrganizationReference]
          : undefined,
      },
      productType,
      salesCriteria,
      label,
      namingConfiguration: undefined,
    },
  });
  return { offer: newOffer };
}
function buildNewOfferProductStore({
  clubReference,
  defaultOrganizationReference,
  label,
  productType,
  salesCriteria,
  productCategory,
}: {
  clubReference: string;
  defaultOrganizationReference?: DiveServiceOrganizationReference;
  label: string;
  productType: ClubProductPackageAttributeProductType;
  salesCriteria: ClubProductPackageAttributesSalesCriteria;
  productCategory?: Pick<ClubProductPackageOfferCategory, '_id' | 'title'>;
}): CreateOfferPayload {
  const reference =
    clubOfferDiveEditorResultBuilder.rebuildReferenceProductStore({
      clubReference,
      productType,
    });
  const newOffer = commonEntityBuilder.buildEntity<ClubProductPackageOffer>({
    categoryId: productCategory?._id,
    reference,
    clubReference: clubReference,
    price: undefined,
    productPackage: {
      reference,
      type: 'store',
      productAttributes: {
        includeDives: false,
        defaultOrganizationReference,
        organizationReferences: defaultOrganizationReference
          ? [defaultOrganizationReference]
          : undefined,
        productCategoryId: productCategory?._id,
        productCategoryTitle: productCategory?.title,
      },
      productType,
      salesCriteria,
      label,
      namingConfiguration: undefined,
    },
  });
  return { offer: newOffer };
}

function buildNewOffersFromTrainingOrg(
  orgReference: DiveServiceOrganizationReference,
  {
    clubReference,
    publicSettings,
    clubSettings,
  }: {
    clubReference: string;
    publicSettings: ClubPublicSettings;
    clubSettings: ClubSettings;
  },
) {
  let newOffers: CreateOfferPayload[] = [];
  if (orgReference) {
    const organizationv2 = DIVE_SERVICE_ORGANIZATIONS.find(
      (org) => org.reference === orgReference,
    );
    const trainings = DIVE_TRAININGS.filter(
      (x) =>
        x.organizationv2.orgGroupReferencev2 ===
        organizationv2.orgGroupReferencev2,
    );

    if (trainings.length) {
      newOffers = newOffers.concat(
        trainings.map((t, i) => {
          return clubSettingsProductOfferBuilder.buildNewOffer({
            reference: `new-${i}`, // ne pas créer une nouvelle réference à chaque appel, sinon on les ajoute à l'infini
            clubReference,
            diveMode: 'training',
            trainingAttributes: {
              trainingType: t.trainingType,
              diveTrainingReference: t.reference,
            },
            productAttributes: {
              defaultOrganizationReference: orgReference,
            },
            i,
          });
        }),
      );
    }
    let i = newOffers.length;
    if (orgReference === 'ssi') {
      {
        i++;
        newOffers.push(
          clubSettingsProductOfferBuilder.buildNewOffer({
            reference: `new-${i}`,
            // https://www.divessi.com/fr/advanced-training/extended-range/open-circuit-diving/extended-range-foundations
            label: 'Extended Range Foundations',
            clubReference,
            diveMode: 'training',
            trainingAttributes: {
              trainingType: 'main-certification',
              diveTrainingReference: 'FTEC',
            },
            productAttributes: {
              defaultOrganizationReference: orgReference,
            },
            i,
          }),
        );
      }
      {
        i++;
        newOffers.push(
          clubSettingsProductOfferBuilder.buildNewOffer({
            reference: `new-${i}`,
            // https://www.divessi.com/fr/advanced-training/extended-range/open-circuit-diving/extended-range-nitrox-diving
            label: 'Extended Range Nitrox',
            clubReference,
            diveMode: 'training',
            trainingAttributes: {
              trainingType: 'main-certification',
              diveTrainingReference: 'FTEC',
            },
            productAttributes: {
              defaultOrganizationReference: orgReference,
            },
            i,
          }),
        );
      }
      {
        i++;
        newOffers.push(
          clubSettingsProductOfferBuilder.buildNewOffer({
            reference: `new-${i}`,
            // https://www.divessi.com/fr/advanced-training/extended-range/open-circuit-diving/extended-range-nitrox-diving
            label: 'Extended Range Trimix',
            clubReference,
            diveMode: 'training',
            trainingAttributes: {
              trainingType: 'main-certification',
              diveTrainingReference: 'FTEC',
            },
            productAttributes: {
              defaultOrganizationReference: orgReference,
            },
            i,
          }),
        );
      }
      {
        i++;
        newOffers.push(
          clubSettingsProductOfferBuilder.buildNewOffer({
            reference: `new-${i}`,
            // https://www.divessi.com/fr/advanced-training/extended-range/open-circuit-diving/extended-range-nitrox-diving
            label: 'Extended Range Sidemount',
            clubReference,
            diveMode: 'training',
            trainingAttributes: {
              trainingType: 'main-certification',
              diveTrainingReference: 'FTEC',
            },
            productAttributes: {
              defaultOrganizationReference: orgReference,
            },
            i,
          }),
        );
      }
      {
        i++;
        newOffers.push(
          clubSettingsProductOfferBuilder.buildNewOffer({
            reference: `new-${i}`,
            // https://www.divessi.com/fr/advanced-training/extended-range/open-circuit-diving/extended-range-nitrox-diving
            label: 'Technical Extended Range Trimix',
            clubReference,
            diveMode: 'training',
            trainingAttributes: {
              trainingType: 'main-certification',
              diveTrainingReference: 'FTEC',
            },
            productAttributes: {
              defaultOrganizationReference: orgReference,
            },
            i,
          }),
        );
      }
      {
        i++;
        newOffers.push(
          clubSettingsProductOfferBuilder.buildNewOffer({
            reference: `new-${i}`,
            // https://www.divessi.com/fr/advanced-training/extended-range/open-circuit-diving/extended-range-nitrox-diving
            label: 'Hypoxic Trimix',
            clubReference,
            diveMode: 'training',
            trainingAttributes: {
              trainingType: 'main-certification',
              diveTrainingReference: 'FTEC',
            },
            productAttributes: {
              defaultOrganizationReference: orgReference,
            },
            i,
          }),
        );
      }
    }
    {
      newOffers.push(
        clubSettingsProductOfferBuilder.buildNewOffer({
          reference: `new-${i}`,
          // https://www.divessi.com/fr/advanced-training/extended-range/open-circuit-diving/extended-range-nitrox-diving
          label: 'Technique',
          clubReference,
          diveMode: 'training',
          trainingAttributes: {
            trainingType: 'main-certification',
            diveTrainingReference: 'F-CCR',
          },
          productAttributes: {
            defaultOrganizationReference: orgReference,
          },
          i,
        }),
      );
    }
    {
      newOffers.push(
        clubSettingsProductOfferBuilder.buildNewOffer({
          reference: 'new-f-ccr',
          // https://www.divessi.com/fr/advanced-training/extended-range/open-circuit-diving/extended-range-nitrox-diving
          label: 'Recycleur',
          clubReference,
          diveMode: 'training',
          trainingAttributes: {
            trainingType: 'main-certification',
            diveTrainingReference: 'F-CCR',
          },
          productAttributes: {
            defaultOrganizationReference: orgReference,
          },
          i,
        }),
      );
    }
  }

  return clubProductPackageOfferViewSorter.sortOffersBy_withTransform(
    newOffers,
    {
      publicSettings,
      attributeName: 'dive',
      asc: true,
      transform: (x) => x.offer,
      diveModesConf: clubSettings?.ui?.diveMode,
    },
  );
}
function buildNewOffersExplo({
  divesCount,
  clubReference,
  publicSettings,
}: {
  divesCount: number;
  clubReference: string;
  publicSettings: ClubPublicSettings;
}) {
  let i = 0;
  const newOffers: CreateOfferPayload[] = [
    clubSettingsProductOfferBuilder.buildNewOffer(
      {
        clubReference,
        diveMode: 'supervised',
        diveAttributes: {
          diveMode: 'supervised',
          divesCount,
          diveType: 'scuba',
          equipment: 'not-equipped',
          supervision: 'supervised',
          divePriceType: divesCount === 1 ? 'single' : 'package',
        },
        namingConfiguration: {
          includeDefaultName: true,
          includeEquipment: true,
          includeSupervision: true,
        },
        i: i++,
      },
      {
        createDisplayComment: 'Encadré - équipement club inclu',
      },
    ),
    clubSettingsProductOfferBuilder.buildNewOffer(
      {
        clubReference,
        diveMode: 'supervised',
        diveAttributes: {
          diveMode: 'supervised',
          divesCount,
          diveType: 'scuba',
          equipment: 'equipped',
          supervision: 'supervised',
          divePriceType: divesCount === 1 ? 'single' : 'package',
        },
        namingConfiguration: {
          includeDefaultName: true,
          includeEquipment: true,
          includeSupervision: true,
        },
        i: i++,
      },
      {
        createDisplayComment: 'Encadré - équipement perso',
      },
    ),
    clubSettingsProductOfferBuilder.buildNewOffer(
      {
        clubReference,
        diveMode: 'supervised',
        diveAttributes: {
          diveMode: 'supervised',
          divesCount,
          diveType: 'scuba',
          equipment: 'any-equipment',
          supervision: 'supervised',
          divePriceType: divesCount === 1 ? 'single' : 'package',
        },
        namingConfiguration: {
          includeDefaultName: true,
          includeEquipment: false,
          includeSupervision: true,
        },
        i: i++,
      },
      {
        createDisplayComment: 'Encadré - équipement facturé en supplément',
      },
    ),
    clubSettingsProductOfferBuilder.buildNewOffer(
      {
        clubReference,
        diveMode: 'autonomous',
        diveAttributes: {
          diveMode: 'autonomous',
          divesCount,
          diveType: 'scuba',
          equipment: 'not-equipped',
          supervision: 'autonomous',
          divePriceType: divesCount === 1 ? 'single' : 'package',
        },
        namingConfiguration: {
          includeDefaultName: true,
          includeEquipment: true,
          includeSupervision: true,
        },
        i: i++,
      },
      {
        createDisplayComment: 'Autonome - équipement club inclu',
      },
    ),
    clubSettingsProductOfferBuilder.buildNewOffer(
      {
        clubReference,
        diveMode: 'autonomous',
        diveAttributes: {
          diveMode: 'autonomous',
          divesCount,
          diveType: 'scuba',
          equipment: 'equipped',
          supervision: 'autonomous',
          divePriceType: divesCount === 1 ? 'single' : 'package',
        },
        namingConfiguration: {
          includeDefaultName: true,
          includeEquipment: true,
          includeSupervision: true,
        },
        i: i++,
      },
      {
        createDisplayComment: 'Autonome - équipement perso',
      },
    ),
    clubSettingsProductOfferBuilder.buildNewOffer(
      {
        clubReference,
        diveMode: 'autonomous',
        diveAttributes: {
          diveMode: 'autonomous',
          divesCount,
          diveType: 'scuba',
          equipment: 'any-equipment',
          supervision: 'autonomous',
          divePriceType: divesCount === 1 ? 'single' : 'package',
        },
        namingConfiguration: {
          includeDefaultName: true,
          includeEquipment: false,
          includeSupervision: true,
        },
        i: i++,
      },
      {
        createDisplayComment: 'Autonome - équipement facturé en supplément',
      },
    ),
    clubSettingsProductOfferBuilder.buildNewOffer(
      {
        clubReference,
        diveMode: 'instructor',

        diveAttributes: {
          diveMode: 'instructor',
          divesCount,
          diveType: 'scuba',
          equipment: 'any-equipment',
          supervision: 'any-supervision',
        },
        namingConfiguration: {
          includeDefaultName: true,
          includeEquipment: false,
          includeSupervision: false,
        },
        i: i++,
      },
      {
        createDisplayComment:
          'Guide de palanquée - équipement facturé en supplément',
      },
    ),
    clubSettingsProductOfferBuilder.buildNewOffer(
      {
        clubReference,
        diveMode: 'autoSupervised',
        diveAttributes: {
          diveMode: 'autoSupervised',
          divesCount,
          diveType: 'scuba',
          equipment: 'any-equipment',
          supervision: 'any-supervision',
        },
        namingConfiguration: {
          includeDefaultName: true,
          includeEquipment: false,
          includeSupervision: false,
        },
        i: i++,
      },
      {
        createDisplayComment: 'Auto-encadré - équipement facturé en supplément',
      },
    ),
  ];

  return newOffers;
}
function buildNewOffersExploSpecial({
  divesCount,
  clubReference,
  publicSettings,
}: {
  divesCount: number;
  clubReference: string;
  publicSettings: ClubPublicSettings;
}) {
  let i = 0;
  const newOffers: CreateOfferPayload[] = [
    clubSettingsProductOfferBuilder.buildNewOffer({
      clubReference,
      diveMode: 'supervised',
      label: 'Ré-adaptation',
      comment: 'Plongée de ré-adaptation',
      diveAttributes: {
        diveMode: 'supervised',
        divesCount,
        diveType: 'scuba',
        equipment: 'any-equipment',
        supervision: 'supervised',
        specialDiveType: 'rehabilitation',
        divePriceType: 'single',
      },
      namingConfiguration: {
        includeDefaultName: true,
        includeEquipment: true,
        includeSupervision: true,
      },
      i: i++,
    }),
    clubSettingsProductOfferBuilder.buildNewOffer({
      clubReference,
      diveMode: 'supervised',
      label: 'Technique',
      comment: 'Plongée technique',
      diveAttributes: {
        diveMode: 'supervised',
        divesCount,
        diveType: 'scuba',
        equipment: 'any-equipment',
        supervision: 'supervised',
        specialDiveType: 'technical',
        divePriceType: 'single',
      },
      namingConfiguration: {
        includeDefaultName: true,
        includeEquipment: true,
        includeSupervision: true,
      },
      i: i++,
    }),
    clubSettingsProductOfferBuilder.buildNewOffer({
      clubReference,
      diveMode: 'supervised',
      label: 'Nuit',
      comment: 'Plongée de nuit - encadré',
      diveAttributes: {
        diveMode: 'supervised',
        divesCount,
        diveType: 'scuba',
        equipment: 'any-equipment',
        supervision: 'supervised',
        divePriceType: 'single',
      },
      namingConfiguration: {
        includeDefaultName: true,
        includeEquipment: true,
        includeSupervision: true,
      },
      i: i++,
    }),
    clubSettingsProductOfferBuilder.buildNewOffer({
      clubReference,
      diveMode: 'autonomous',
      label: 'Nuit',
      comment: 'Plongée de nuit - autonome',
      diveAttributes: {
        diveMode: 'autonomous',
        divesCount,
        diveType: 'scuba',
        equipment: 'any-equipment',
        supervision: 'autonomous',
        divePriceType: 'single',
      },
      namingConfiguration: {
        includeDefaultName: true,
        includeEquipment: true,
        includeSupervision: true,
      },
      i: i++,
    }),
  ];

  return newOffers;
}
