import { dateService } from '@mabadive/app-common-services';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import {
  useAppBookingSessionAuth,
  useAppBookingViewAuth,
} from 'src/business/auth/services';
import { ClubDialogsStateOld } from 'src/pages/_dialogs';
import {
  AggregatedBookingSessionFull,
  BookingResumeParticipantForSession,
  BookingTabModel,
  PRO_BookingMemberFull_WithDocs,
  PRO_BookingParticipantFull,
} from '../../models';
import { DiverBookingPageActions } from '../../useDiverBookingPageActions.hook';
import { DiverBookingPageGlobalState } from '../../useDiverBookingPageGlobalState.hook';
import { DiverBookingCardPastSessionsTableDayParticipantCell } from './DiverBookingCardPastSessionsTableDayParticipantCell';
import { DiverBookingSessionsTableDaySessionMenu } from './DiverBookingSessionsTableDaySessionMenu';
import { DiverBookingSessionsTableSessionHeader } from './DiverBookingSessionsTableSessionHeader';

export function DiverBookingCardPastSessionsTableDay({
  globalState,
  dialogs,
  actions,
  bookingTabModel,
  bookingSessionFull,
  participants,
  filteredDiversIds,
  onClickSession,
  onClickParticipant,
  displayName,
  displayLastName,
  displaySessionName,
  displayDiveSite,
  className,
}: {
  globalState: DiverBookingPageGlobalState;
  dialogs: ClubDialogsStateOld;
  actions: DiverBookingPageActions;
  bookingTabModel: BookingTabModel;
  bookingSessionFull: AggregatedBookingSessionFull;
  participants: BookingResumeParticipantForSession[];
  filteredDiversIds?: string[];
  displayName?: boolean;
  displayLastName?: boolean;
  displaySessionName: boolean;
  displayDiveSite: boolean;
  onClickSession: (bookingSessionFull: AggregatedBookingSessionFull) => void;
  onClickParticipant: (props: {
    bookingSessionFull: AggregatedBookingSessionFull;
    bookingParticipantFull: PRO_BookingParticipantFull;
    bookingMemberFull: PRO_BookingMemberFull_WithDocs;
  }) => void;
  className?: string;
}) {
  const isPast = useMemo(
    () =>
      dateService.isPastUTC(bookingSessionFull.diveSession.time) &&
      !dateService.isTodayUTC(bookingSessionFull.diveSession.time),
    [bookingSessionFull.diveSession.time],
  );

  const theme = useMemo(() => (isPast ? 'light' : 'normal'), [isPast]);

  const { updateState, setUpdateState } = globalState;

  const bookingSessionAuth = useAppBookingSessionAuth({
    bookingSessionDiveCenterId: bookingSessionFull.bookingSession?.diveCenterId,
  });

  const bookingViewAuth = useAppBookingViewAuth({
    bookingDiveCenterId: bookingTabModel.bookingDiveCenterId,
    isSharedBooking: bookingTabModel.isSharedBooking,
  });
  const participantsToDisplay = useMemo(
    () =>
      filteredDiversIds?.length > 0
        ? participants.filter((x) =>
            filteredDiversIds.includes(x.bookingMemberFull.diver._id),
          )
        : participants,
    [filteredDiversIds, participants],
  );
  const hasAnyFilteredParticipant = useMemo(
    () =>
      participantsToDisplay.find(
        (x) => !!x.bookingParticipantFullAnyBooking,
      ) !== undefined,
    [participantsToDisplay],
  );
  return !hasAnyFilteredParticipant ? null : (
    <div className={`w-24 px-1 ${className ?? ''}`}>
      <DiverBookingSessionsTableSessionHeader
        displaySessionName={displaySessionName}
        displayDiveSite={displayDiveSite}
        className="mb-1"
        theme={theme}
        bookingSessionFull={bookingSessionFull}
        onClick={() => onClickSession(bookingSessionFull)}
      />
      {bookingViewAuth.edit && participants.length > 0 && (
        <DiverBookingSessionsTableDaySessionMenu
          className={bookingSessionAuth.edit ? '' : 'invisible'}
          globalState={globalState}
          dialogs={dialogs}
          actions={actions}
          bookingTabModel={bookingTabModel}
          bookingSessionFull={bookingSessionFull}
          participants={participants}
          onClickSession={onClickSession}
          onClickParticipant={onClickParticipant}
          displayName={displayName}
          displayLastName={displayLastName}
        />
      )}
      {participantsToDisplay.map((participant, k) => {
        const { bookingMemberFull, bookingParticipantFull, style } =
          participant;

        return (
          <DiverBookingCardPastSessionsTableDayParticipantCell
            key={k}
            className={clsx(
              'mb-2',
              updateState.filteredDiverId &&
                bookingMemberFull.diver._id !== updateState.filteredDiverId &&
                'hidden',
            )}
            theme={theme}
            globalState={globalState}
            bookingParticipantFull={bookingParticipantFull}
            bookingSessionFull={bookingSessionFull}
            bookingMemberFull={bookingMemberFull}
            bookingSessionAuth={bookingSessionAuth}
            displayName={displayName}
            displayLastName={displayLastName}
            style={style}
            onClick={
              bookingSessionAuth.edit
                ? ({
                    action, // "create" | "edit-on-current-booking" | "edit-on-other-booking"
                    bookingParticipantFull,
                    bookingSessionFull,
                    bookingMemberFull,
                  }) =>
                    // attention: il peut s'agir d'un autre booking ici!

                    onClickParticipant({
                      bookingSessionFull,
                      bookingParticipantFull,
                      bookingMemberFull,
                    })
                : undefined
            }
          />
        );
      })}
    </div>
  );
}
