import { DiveServicePurchaseStatus } from '@mabadive/app-common-model';
import { diveServicePurchaseStatusFormatter } from '@mabadive/app-common-services';
import React from 'react';
import { BillingTabParticipantPurchase } from '../../models';
import { BillingParticipantPurchaseResumeDivesCount } from './BillingParticipantPurchaseResumeDivesCount';

export function BillingParticipantPurchaseResumePurchasePackageState({
  participantPurchase,
  opacity,
  className,
}: {
  participantPurchase: Pick<
    BillingTabParticipantPurchase,
    'isVirtual' | 'divesCounts' | 'isArchived' | 'type'
  > & {
    validityStatus: DiveServicePurchaseStatus;
  };
  opacity?: 60;
  className?: string;
}) {
  const { isVirtual, divesCounts, isArchived } = participantPurchase;

  return (
    <div className={className}>
      <div className="w-full flex flex-col items-start">
        <BillingParticipantPurchaseResumeDivesCount
          divesCounts={divesCounts}
          isVirtual={isVirtual}
          opacity={opacity}
        />
      </div>
      <div
        className={`w-full pt-0.5 uppercase whitespace-nowrap font-bold text-gray-600 text-xs ${
          opacity === 60 ? 'text-opacity-60' : ''
        }`}
      >
        {participantPurchase.type !== 'store' &&
        participantPurchase.validityStatus ? (
          <div className="text-gray-500 text-xs">
            {diveServicePurchaseStatusFormatter.formatStatus(
              participantPurchase.validityStatus,
            )}
          </div>
        ) : (
          isArchived && <div className="text-gray-500 text-xs">ARCHIVÉ</div>
        )}
      </div>
    </div>
  );
}
