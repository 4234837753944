/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import {
  useAppSecurityUser,
  useAppSecurityUserHasRole,
} from 'src/business/auth/services';
import { useClubResume } from 'src/business/club/data/hooks';
import { AppFormControlRHF_Deprecated } from 'src/lib/form/components/AppFormControl/AppFormControlRHF_Deprecated';

import { AppIconsSocialMedia } from 'src/business/_core/modules/layout/icons';
import { AppInputBooleanRHF, AppInputBooleanSwitchRHF } from 'src/lib/form';
import { AppSingleSelect2HeadlessUIRHF } from 'src/lib/form/components/AppSingleSelect/AppSingleSelect2HeadlessUIRHF';
import { ClubSettingsSection } from 'src/pages/SE-settings/_core';
import { useAppCompanyMessageTargetModesOptions } from 'src/pages/_components/options';
import {
  ClubSettingsCommunicationDialogInitialState,
  ClubSettingsCommunicationFormModel,
} from '../../../_model';

export const ClubSettingsCommunicationMainForm_Booking = ({
  form,
  initialState,
}: {
  form: UseFormReturn<ClubSettingsCommunicationFormModel>;
  initialState: ClubSettingsCommunicationDialogInitialState;
}) => {
  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const securityUser = useAppSecurityUser();

  const isSuperAdmin = useAppSecurityUserHasRole('super-admin');

  const clubResume = useClubResume();
  const customerSettings = clubResume?.clubSettings?.customer;
  const clubReference = clubResume.reference;

  const isMultiDiveCenters = clubResume.diveCenters.length > 1;

  const [
    enableMessage,
    bookingEmailEnabled,
    channelEmailEnabled,
    channelSmsEnabled,
    channelWhatsAppEnabled,
    channelsEmailAutoModeEnabled,
  ] = useWatch({
    control,
    name: [
      'clubSettings.communication.booking.enableMessage',
      'clubSettings.communication.booking.email.enabled',
      'clubSettings.communication.channels.email.enabled',
      'clubSettings.communication.channels.sms.enabled',
      'clubSettings.communication.channels.whatsApp.enabled',
      'clubSettings.communication.channels.email.directMode.directManualEnabled',
    ],
  });

  const messageTargetModesOptions = useAppCompanyMessageTargetModesOptions();
  return (
    <ClubSettingsSection
      title="Réservations"
      comment="Envoyer un message prédéfini aux clients d'une réservations"
    >
      <div className="grid lg:grid-cols-2 gap-x-4 gap-y-4">
        <div className="flex flex-col gap-x-4 gap-y-2">
          <AppFormControlRHF_Deprecated
            control={control}
            name={'clubSettings.communication.booking.enableMessage'}
            label="Activer les messages de réservation"
            renderComponent={(props) => (
              <AppInputBooleanSwitchRHF {...props} label={'ACTIVÉ'} />
            )}
          />

          {enableMessage && (
            <div className="grid gap-2">
              <AppFormControlRHF_Deprecated
                label={'Contacter uniquement le responsable (par défaut)'}
                control={control}
                name={
                  'clubSettings.communication.booking.recipients.contactOnlyAsDefault'
                }
                renderComponent={(props) => (
                  <AppInputBooleanSwitchRHF {...props} label={'Activé'} />
                )}
              />
              {isSuperAdmin && (
                <>
                  <AppFormControlRHF_Deprecated
                    visibility="super-admin"
                    label={'Permettre de contacter les autres participants'}
                    control={control}
                    name={
                      'clubSettings.communication.booking.recipients.allowToContactAllRecipients'
                    }
                    renderComponent={(props) => (
                      <AppInputBooleanSwitchRHF {...props} label={'Activé'} />
                    )}
                  />
                  <AppFormControlRHF_Deprecated
                    visibility="super-admin"
                    label={
                      'Permettre de forcer l\'envoi de messages individuels'
                    }
                    control={control}
                    name={
                      'clubSettings.communication.booking.recipients.personalMessageSwitchEnabled'
                    }
                    renderComponent={(props) => (
                      <AppInputBooleanSwitchRHF {...props} label={'Activé'} />
                    )}
                  />
                  <AppFormControlRHF_Deprecated
                    visibility="super-admin"
                    label={
                      'Chaque participant d\'une réservation reçoit un lien de connexion individuel'
                    }
                    control={control}
                    name={
                      'clubSettings.communication.customerSpace.individualLinksEnabled'
                    }
                    renderComponent={(props) => (
                      <AppInputBooleanSwitchRHF {...props} label={'Activé'} />
                    )}
                  />
                </>
              )}
            </div>
          )}
        </div>
        {enableMessage && (
          <div className="flex flex-col gap-x-4 gap-y-4">
            {channelEmailEnabled && (
              <div className="flex gap-4">
                <AppFormControlRHF_Deprecated
                  control={control}
                  // label="Bouton Email"
                  name={'clubSettings.communication.booking.email.enabled'}
                  renderComponent={(props) => (
                    <AppInputBooleanRHF
                      {...props}
                      type="checkbox"
                      label={'Email'}
                      margin="ml-4 mr-2.5"
                      scale="lg"
                      icon={
                        <AppIconsSocialMedia.mail className="h-6 w-6 text-app-mail" />
                      }
                    />
                  )}
                />
                {channelsEmailAutoModeEnabled && bookingEmailEnabled && (
                  <AppSingleSelect2HeadlessUIRHF
                    theme="material-ui"
                    control={control}
                    name={'clubSettings.communication.booking.email.mode'}
                    disableClearButton={true}
                    options={messageTargetModesOptions}
                  />
                )}
              </div>
            )}
            {channelSmsEnabled && (
              <AppFormControlRHF_Deprecated
                control={control}
                name={'clubSettings.communication.booking.sms.enabled'}
                renderComponent={(props) => (
                  <AppInputBooleanRHF
                    {...props}
                    type="checkbox"
                    label={'SMS'}
                    label2={'manuel'}
                    margin="ml-4 mr-2.5"
                    scale="lg"
                    icon={
                      <AppIconsSocialMedia.sms className="h-6 w-6 text-app-sms" />
                    }
                  />
                )}
              />
            )}
            {channelWhatsAppEnabled && (
              <AppFormControlRHF_Deprecated
                control={control}
                name={'clubSettings.communication.booking.whatsApp.enabled'}
                renderComponent={(props) => (
                  <AppInputBooleanRHF
                    {...props}
                    type="checkbox"
                    label="WhatsApp"
                    label2={'manuel'}
                    margin="ml-5 mr-2.5"
                    scale="lg"
                    icon={
                      <AppIconsSocialMedia.whatsapp className="h-5 w-5 text-app-whatsapp" />
                    }
                  />
                )}
              />
            )}
          </div>
        )}
      </div>
    </ClubSettingsSection>
  );
};
