/* eslint-disable @typescript-eslint/no-unused-vars */
import { BookingParticipantEditorFormModel } from '@mabadive/app-common-model';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { useAppSecurityUser } from 'src/business/auth/services';
import { useClubResume } from 'src/business/club/data/hooks';
import {
  ClubDiverSimilarSearchCandidatesState,
  ClubDiverSimilarSearchEntry,
} from 'src/business/club/modules/club-diver-participant/ClubDiverSimilarSearch';
import { ClubDiverSimilarSearchCandidateFormOptions } from 'src/business/club/modules/club-diver-participant/ClubDiverSimilarSearch/components/ClubDiverSimilarSearchCandidateFormOptions';
import { AppInputRHF } from 'src/lib/form';
import { AppFormControlRHF_Deprecated } from 'src/lib/form/components/AppFormControl/AppFormControlRHF_Deprecated';
import { AppSingleAutocomplete2RHF } from 'src/lib/form/components/AppSingleAutocomplete';
import { AppSingleSelect2HeadlessUIRHF } from 'src/lib/form/components/AppSingleSelect/AppSingleSelect2HeadlessUIRHF';
import { AppInputDatePickerMaterialRHF } from 'src/stories/components/04-form';
import { customerOriginOptionsBuilder } from '../customerOriginOptionsBuilder.service';
import { useResidentTypesOptions } from '../useResidentTypesOptions.hook';

export const BookingMemberGeneralEditFormFieldsFrequentOnly = ({
  form,
  similarSearchCandidatesState,
  className = '',
}: {
  form: UseFormReturn<BookingParticipantEditorFormModel>;
  similarSearchCandidatesState?: ClubDiverSimilarSearchCandidatesState;
  className?: string;
}) => {
  const securityUser = useAppSecurityUser();

  const clubResume = useClubResume();
  const customerSettings = clubResume.clubSettings?.customer;
  const residentTypesOptions = useResidentTypesOptions();
  const customerOriginOptions = useMemo(
    () =>
      customerOriginOptionsBuilder.buildOptions({
        clubSettings: clubResume?.clubSettings,
      }),
    [clubResume?.clubSettings],
  );
  const { control, setValue } = form;

  const birthdate = useWatch({
    control,
    name: 'diver.birthdate',
  });

  const displayAge = customerSettings?.general?.age && !birthdate;

  return (
    <div className={className}>
      <div
        className={
          'w-full flex flex-col sm:grid sm:grid-cols-2 md:grid-cols-4 gap-4'
        }
      >
        <AppFormControlRHF_Deprecated
          className={'md:col-span-2 w-full'}
          label="Prénom"
          control={control}
          required={false}
          name={'diver.firstName'}
          renderComponent={(props) => (
            <AppInputRHF {...props} fullWidth type="text" />
          )}
        />
        <AppFormControlRHF_Deprecated
          className={'md:col-span-2 w-full'}
          label="Nom"
          control={control}
          name={'diver.lastName'}
          renderComponent={(props) => (
            <AppInputRHF {...props} fullWidth type="text" />
          )}
        />
        {similarSearchCandidatesState && (
          <ClubDiverSimilarSearchCandidateFormOptions
            className={'w-full sm:col-span-2 md:col-span-4'}
            similarSearchCandidatesState={similarSearchCandidatesState}
            open={(selectedCandidate: ClubDiverSimilarSearchEntry) => {
              // TODO open preview then redirect
              similarSearchCandidatesState.setSelectedDuplicatedCandidate(
                selectedCandidate,
              );
            }}
          />
        )}
        <AppFormControlRHF_Deprecated
          className={'md:col-span-2 w-full'}
          label="Téléphone"
          control={control}
          name={'diver.phoneNumber'}
          renderComponent={(props) => (
            <AppInputRHF {...props} fullWidth type="text" />
          )}
        />
        <AppFormControlRHF_Deprecated
          className={'md:col-span-2 w-full'}
          label="Adresse e-mail"
          control={control}
          validation={{
            email: true,
          }}
          name={'diver.emailAddress'}
          renderComponent={(props) => (
            <AppInputRHF {...props} fullWidth type="text" />
          )}
        />
        <AppFormControlRHF_Deprecated
          className={clsx('w-full', !displayAge && 'md:col-span-2')}
          label="Date de naissance"
          control={control}
          name={'diver.birthdate'}
          renderComponent={(props) => (
            <AppInputDatePickerMaterialRHF
              initialView={birthdate ? 'weeks' : 'years'}
              maxYear={new Date().getFullYear()}
              {...props}
              className="w-full"
            />
          )}
        />
        {displayAge && (
          <AppFormControlRHF_Deprecated
            className={'w-full'}
            label="Âge"
            control={control}
            name={'diver.age'}
            renderComponent={(props) => (
              <AppInputRHF {...props} fullWidth type="number" />
            )}
          />
        )}
        {customerSettings?.general?.residentType && (
          <AppFormControlRHF_Deprecated
            className={clsx(
              'w-full',
              !customerSettings?.general?.customerOrigin && 'md:col-span-2',
            )}
            label="Résidence"
            control={control}
            name={'diver.residentType'}
            renderComponent={(props) => (
              <AppSingleSelect2HeadlessUIRHF
                className={'w-full'}
                theme="material-ui"
                color="theme"
                {...props}
                options={residentTypesOptions}
              />
            )}
          />
        )}
        {customerSettings?.general?.customerOrigin && (
          <AppFormControlRHF_Deprecated
            className={clsx(
              'w-full',
              !customerSettings?.general?.residentType && 'md:col-span-2',
            )}
            label="Origine"
            control={control}
            name={'diver.details.customerOriginRef'}
            renderComponent={(props) => (
              <AppSingleAutocomplete2RHF
                className={'w-full'}
                {...props}
                options={customerOriginOptions}
                onChange={(value) => {
                  const option = customerOriginOptions.find(
                    (x) => x.value === value,
                  );
                  setValue('diver.details.customerOriginLabel', option?.label);
                }}
              />
            )}
          />
        )}
      </div>
      <div className={'mt-4 w-full'}>
        <AppFormControlRHF_Deprecated
          className={'w-full'}
          label="Commentaire général privé 🚫"
          control={control}
          name={'diver.comment'}
          renderComponent={(props) => (
            <AppInputRHF {...props} fullWidth multiline rowsMax={15} rows={2} />
          )}
        />
      </div>
    </div>
  );
};
