/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';

import clsx from 'clsx';
import { useDiveCenterResume } from 'src/business/club/data/hooks';
import { AppDocViewPanelStaffMember } from '../../../../../../business/club/modules/app-doc/AppDocViewPanel/AppDocViewPanelStaffMember';
import { StaffMemberEditDialogLocalState } from '../useStaffMemberEditDialogLocalState.hook';

export const StaffMemberEditForm_Tab4_Documents = ({
  localState,
  className,
}: {
  localState: StaffMemberEditDialogLocalState;
  className?: string;
}) => {
  const {
    data,
    state: { staffMemberId, form, dialogsState, updateState, setUpdateState },
  } = localState;
  const { control, setValue } = form;

  const diveCenterResume = useDiveCenterResume();

  return !staffMemberId ? null : (
    <div className={clsx('', className)}>
      <AppDocViewPanelStaffMember
        docResumes={data.docResumes}
        staffMemberId={staffMemberId}
        dialogsState={dialogsState}
      />
    </div>
  );
};
