import {
  AppCompanyMessageLocalized,
  AppCompanyMessageTarget,
} from '@mabadive/app-common-model';
import { companyMessageBuilder } from '@mabadive/app-common-services';
import clsx from 'clsx';
import React, { FC, useCallback, useMemo } from 'react';
import { useFormState } from 'react-hook-form';
import { useRedirect } from 'src/business/_core/data/hooks';
import {
  AppButton,
  AppDialogModalFixedBar,
} from 'src/business/_core/modules/layout';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import {
  useClubSettings,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import { ValueLabel } from 'src/business/club/modules/_common/form';
import { AppFormControlRHF_Deprecated } from 'src/lib/form/components/AppFormControl/AppFormControlRHF_Deprecated';
import { AppSingleSelect2HeadlessUIRHF } from 'src/lib/form/components/AppSingleSelect/AppSingleSelect2HeadlessUIRHF';
import {
  AppToogleButtonGroupRHFV2,
  AppToogleButtonGroupTailwindV2OptionStyleBuilder,
} from 'src/lib/form/components/AppToogleButton';
import { useAppCompanyMessageTargetOptions } from 'src/pages/_components/options';
import { createMessageToCustomersChangesBuilder } from '../services';
import { CreateMessageToCustomersLocalState } from '../useCreateMessageToCustomersLocalState.hook';

export const CreateMessageToCustomersFormStep1_Message: FC<{
  localState: CreateMessageToCustomersLocalState;
  className?: string;
}> = ({ localState, className }) => {
  const redirectTo = useRedirect();

  const diveCenterResume = useDiveCenterResume();
  const clubResume = diveCenterResume.clubResume;
  const clubReference = clubResume?.reference;

  const {
    data: { form, result, messageTemplatesOptions, messageTemplates },
    actions,
    state,
  } = localState;

  const { aggregated, changes } = result;

  const { register, handleSubmit, watch, control, setValue, getValues } = form;

  const formState = useFormState({
    control,
  });

  const clubSettings = useClubSettings();
  const context = localState.state.context;

  const communication = clubSettings?.communication;

  const targets: AppCompanyMessageTarget[] = useMemo(() => {
    const targets: AppCompanyMessageTarget[] = [];
    if (
      communication?.channels?.email?.enabled &&
      ((context === 'session' && communication?.session?.email?.enabled) ||
        (context === 'booking' && communication?.booking?.email?.enabled)) &&
      state.selectedMessageTemplate?.emailContent?.enabled
    ) {
      targets.push('email');
    }
    if (
      communication?.channels?.sms?.enabled &&
      ((context === 'session' && communication?.session?.sms?.enabled) ||
        (context === 'booking' && communication?.booking?.sms?.enabled)) &&
      state.selectedMessageTemplate?.smsContent?.enabled
    ) {
      targets.push('sms');
    }
    if (
      communication?.channels?.whatsApp?.enabled &&
      ((context === 'session' && communication?.session?.whatsApp?.enabled) ||
        (context === 'booking' && communication?.booking?.whatsApp?.enabled)) &&
      state.selectedMessageTemplate?.whatsAppContent?.enabled
    ) {
      targets.push('whatsapp');
    }
    return targets;
  }, [
    communication?.booking?.email?.enabled,
    communication?.booking?.sms?.enabled,
    communication?.booking?.whatsApp?.enabled,
    communication?.channels?.email?.enabled,
    communication?.channels?.sms?.enabled,
    communication?.channels?.whatsApp?.enabled,
    communication?.session?.email?.enabled,
    communication?.session?.sms?.enabled,
    communication?.session?.whatsApp?.enabled,
    context,
    state.selectedMessageTemplate?.emailContent?.enabled,
    state.selectedMessageTemplate?.smsContent?.enabled,
    state.selectedMessageTemplate?.whatsAppContent?.enabled,
  ]);

  const messageTargetOptions = useAppCompanyMessageTargetOptions(targets);

  // @see appToogleButtonGroupTailwindV2OptionStyleBuilder
  const buildOptionStyle = useCallback(
    (
      option: ValueLabel<
        AppCompanyMessageTarget,
        React.ReactNode,
        React.ReactNode
      >,
      {
        isDisabled,
        isActive,
        isSelected,
        isChecked,
      }: {
        isDisabled?: boolean;
        isActive?: boolean;
        isSelected?: boolean;
        isChecked?: boolean; // différent de isSelected ?
      },
    ) => {
      const className = clsx(
        '',
        isDisabled
          ? 'opacity-25 cursor-not-allowed'
          : 'cursor-pointer focus:outline-none',
        isSelected
          ? clsx(
              'text-white border-transparent ring-2 ring-offset-2',
              option.value === 'email'
                ? 'ring-app-email bg-app-email hover:bg-app-email--dark'
                : option.value === 'sms'
                ? 'ring-app-sms bg-app-sms hover:bg-app-sms--dark'
                : option.value === 'whatsapp'
                ? 'ring-app-whatsapp bg-app-whatsapp hover:bg-app-whatsapp--dark'
                : 'ring-gray-600',
            )
          : clsx(
              option.value === 'email'
                ? 'text-app-email hover:text-app-email--dark'
                : option.value === 'sms'
                ? 'text-app-sms hover:text-app-sms--dark'
                : option.value === 'whatsapp'
                ? 'text-app-whatsapp hover:text-app-whatsapp--dark'
                : 'text-gray-400',
            ),
        // : 'bg-white border-gray-400 text-gray-600 hover:bg-gray-50',
      );

      return {
        className,
      };
    },
    [],
  ) as AppToogleButtonGroupTailwindV2OptionStyleBuilder;

  const goToStep2 = useCallback(
    (messageTarget: AppCompanyMessageTarget) => {
      const formValue = getValues();

      const messageTemplate = messageTemplates.find(
        (x) => x._id === formValue.messageTemplateId,
      );
      if (messageTemplate) {
        // sélection automatique des participants valides
        aggregated.allRecipients.forEach((x) => {
          // auto-select each valid participant

          const targetValidContact: string =
            createMessageToCustomersChangesBuilder.buildTargetValidContact({
              messageTarget,
              validContacts: x.validContacts,
            });
          const isValid = !!targetValidContact;
          const autoSelect =
            (context === 'booking' &&
            clubSettings.communication?.booking?.recipients
              ?.contactOnlyAsDefault
              ? x.formRecipient.isBookingContact
              : isValid) && x.formRecipient.bookingStatus !== 'cancelled';

          setValue(
            `recipients.${x.formRecipient.formDiverIndex}.isSelected`,
            autoSelect,
          );
        });

        const messageContent: AppCompanyMessageLocalized =
          companyMessageBuilder.buildMessageContentFromTemplate(
            messageTarget === 'email'
              ? messageTemplate.emailContent
              : messageTarget === 'sms'
              ? messageTemplate.smsContent
              : messageTemplate.whatsAppContent,
            {
              clubSettings,
            },
          );

        setValue('messageContent', messageContent);

        actions.autoSetRecipientType({
          nextStep: 'step2-recipients',
        });
        state.setStep('step2-recipients');
      }
    },
    [
      actions,
      aggregated.allRecipients,
      clubSettings,
      context,
      getValues,
      messageTemplates,
      setValue,
      state,
    ],
  );

  return (
    <div className={clsx('mb-12 flex flex-col gap-8', className)}>
      <AppFormControlRHF_Deprecated
        className="w-full"
        label="Modèle de message"
        helpDescription={'Vous pourrez modifier le message avant de l\'envoyer'}
        control={control}
        name={'messageTemplateId'}
        renderComponent={(props) => (
          <div className="pt-1">
            <AppSingleSelect2HeadlessUIRHF
              // optionsStyle={{
              //   minWidth: '300px',
              //   maxWidth: '80%',
              // }}
              {...props}
              theme="tailwind"
              disableClearButton={true}
              options={messageTemplatesOptions}
              // onChange={(messageTemplateId) => {
              //   if (messageTemplateId) {
              //     onMessageTemplateSelected(messageTemplateId)
              // }}
            />
          </div>
        )}
      />
      <AppFormControlRHF_Deprecated
        className={'w-full'}
        label="Mode de communication"
        control={control}
        name={'messageTarget'}
        renderComponent={(props) => (
          <AppToogleButtonGroupRHFV2
            {...props}
            items={messageTargetOptions}
            buildOptionStyle={buildOptionStyle}
            size="lg"
            // selected={selected}
            onChanges={(value) => {
              if (value) {
                goToStep2(value as unknown as AppCompanyMessageTarget);
              }
            }}
          />
        )}
      />
      <div className="flex-grow"></div>

      <AppDialogModalFixedBar>
        <div className="flex gap-4 justify-center">
          <AppButton
            className="w-full uppercase sm:max-w-xs"
            fullWidth={true}
            size="normal"
            icon={AppHeroIcons.actionClose}
            color={'gray-outline-light'}
            tabIndex={500}
            onClick={() => {
              actions.onCancel();
            }}
          >
            Fermer
          </AppButton>
        </div>
      </AppDialogModalFixedBar>
    </div>
  );
};
