/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react';
import { useFieldArray, useWatch } from 'react-hook-form';
import { useClubResume } from 'src/business/club/data/hooks';
import { AppFormLabel } from 'src/lib/form';
import { BookingCustomerConfigEditDialogLocalState } from '../../useBookingCustomerConfigEditDialogLocalState.hook';
import { BookingCustomerConfigEditForm_Inquiries_CheckAll } from './BookingCustomerConfigEditForm_Inquiries_CheckAll';
import { BookingCustomerConfigEditForm_Inquiries_TableRow } from './BookingCustomerConfigEditForm_Inquiries_TableRow';
import { BookingResumeMembersInquiryBadge } from './BookingResumeMembersInquiryBadge';

export const BookingCustomerConfigEditForm_Inquiries = ({
  localState,
}: {
  localState: BookingCustomerConfigEditDialogLocalState;
}) => {
  const { state, data, actions } = localState;
  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    state.form;

  const clubResume = useClubResume();
  const customerSettings = clubResume?.clubSettings?.customer;
  const clubReference = clubResume.reference;

  const isDiversPostalAddressInfoEnabledByDefault =
    clubReference === 'la-bulle-d-her-noirmoutier-27455';

  const formPrefix = 'booking.bookingCustomerConfig' as const;

  const [
    customerUiEnableCustomerUpdate,
    customerUiPendingListDiversContactInfo,
    customerUiPendingListDiversDivingInfo,
    customerUiPendingListDiversDiversPostalAddressInfo,
    customerUiPendingListDiversExpectedDiveInfo,
  ] = useWatch({
    control,
    name: [
      `${formPrefix}.customerUi.enableCustomerUpdate`,
      `${formPrefix}.pendingList.diversContactInfo`,
      `${formPrefix}.pendingList.diversDivingInfo`,
      `${formPrefix}.pendingList.diversPostalAddressInfo`,
      `${formPrefix}.pendingList.diversExpectedDiveInfo`,
    ],
  });

  const customerUpdateRequested =
    customerUiPendingListDiversContactInfo ||
    customerUiPendingListDiversDivingInfo ||
    customerUiPendingListDiversDiversPostalAddressInfo ||
    customerUiPendingListDiversExpectedDiveInfo;
  useEffect(() => {
    if (!customerUpdateRequested && customerUiEnableCustomerUpdate) {
      setValue(`${formPrefix}.customerUi.enableCustomerUpdate`, null);
    }
  });

  const bookingMembersConfigsFieldsArray = useFieldArray({
    control,
    name: 'bookingMembersConfigs',
  });

  const showCheckAllButton = bookingMembersConfigsFieldsArray.fields.length > 1;

  return data.enabledInquiries?.length > 0 ? (
    <div>
      <table className="my-2 app-table">
        <thead>
          <tr className="bg-white text-gray-600 uppercase align-bottom">
            <th colSpan={2} className="px-2">
              <AppFormLabel
                className={'form-label text-base w-full'}
                labelFontSize="text-md"
                label="Questionnaires à remplir"
              />
            </th>
            {data.enabledInquiries.map((x) => (
              <th className="px-2" key={x._id}>
                <BookingResumeMembersInquiryBadge
                  key={x._id}
                  inquiry={x}
                  status="ok"
                />
              </th>
            ))}
          </tr>

          <tr className="bg-white text-gray-600 uppercase">
            <th className="px-2 py-2 uppercase text-gray-500">Participant</th>
            <th className="px-2 py-2 uppercase text-gray-500">
              Prochaines sorties
            </th>
            {data.enabledInquiries.map((inquiry, inquiryIndex) => (
              <th className="px-2 py-2" key={inquiry._id}>
                {showCheckAllButton && (
                  <BookingCustomerConfigEditForm_Inquiries_CheckAll
                    inquiry={inquiry}
                    inquiryIndex={inquiryIndex}
                    bookingMembersConfigsFieldsArray={
                      bookingMembersConfigsFieldsArray
                    }
                    localState={localState}
                  />
                )}
              </th>
            ))}
          </tr>
        </thead>

        <tbody>
          {bookingMembersConfigsFieldsArray.fields.map(
            (bookingMembersConfigsField, bookingMembersConfigsFieldIndex) => (
              <BookingCustomerConfigEditForm_Inquiries_TableRow
                key={bookingMembersConfigsFieldIndex}
                bookingMembersConfigsField={bookingMembersConfigsField}
                bookingMembersConfigsFieldIndex={
                  bookingMembersConfigsFieldIndex
                }
                localState={localState}
              />
            ),
          )}
        </tbody>
      </table>
    </div>
  ) : null;
};
