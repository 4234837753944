/* eslint-disable @typescript-eslint/no-unused-vars */
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { useClubResume } from 'src/business/club/data/hooks';
import {
  AppTabsTailwind,
  AppTabTailwind,
} from '../../../../../../business/_core/modules/layout';
import { AppIconsInquiry } from '../../../../../../business/_core/modules/layout/icons';
import { ClubSettingsInquiryEditPagePanelLocalState } from '../ClubSettingsInquiryEditPagePanel/useClubSettingsInquiryEditPagePanelLocalState.hook';
import { AppInquiryFormModel, ClubSettingsInquiryEdit_TabId } from './model';

export const ClubSettingsInquiryEditPageForm_Header = ({
  localState,
  form,
  className,
}: {
  localState: ClubSettingsInquiryEditPagePanelLocalState;
  form: UseFormReturn<AppInquiryFormModel>;
  className?: string;
}) => {
  const { state, data } = localState;

  const clubResume = useClubResume();
  const isMultiDiveCenters = clubResume.diveCenters.length > 1;

  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const [color, inquiryType, name, shortName] = useWatch({
    control,
    name: ['settings.color', 'type', 'name', 'settings.shortName'],
  });

  const InquiryIcon = AppIconsInquiry[inquiryType];

  const { currentTabId, setCurrentTabId } = state;

  const tabs = useMemo(() => {
    const tabs: AppTabTailwind<ClubSettingsInquiryEdit_TabId>[] = [
      {
        id: 'questions',
        label: 'Questions',
      },
    ];
    if (currentTabId !== 'questions' || !!inquiryType) {
      tabs.push({
        id: 'preview',
        label: 'Aperçu',
      });
      tabs.push({
        id: 'main-settings',
        label: 'Configuration générale',
      });
      tabs.push({
        id: 'activation-settings',
        label: 'Activation automatique',
      });
      tabs.push({
        id: 'response-processing',
        label: 'Traitement des réponses',
      });
    }
    return tabs;
  }, [currentTabId, inquiryType]);

  return (
    <div
      className={clsx('app-card px-4 py-2 md:py-4 border-t-8', className)}
      style={{
        borderColor: color,
      }}
    >
      <div
        className={clsx(
          'md:py-2 flex items-center gap-4 text-xl font-bold uppercase',
          'text-gray-600',
        )}
      >
        {InquiryIcon && <InquiryIcon className="h-6 w-6" />}
        <div className="flex gap-2">
          <span>
            {state.mode === 'create' && !inquiryType
              ? 'Nouveau questionnaire'
              : name ?? data.initialValueIfExists?.name}
          </span>
          {(state.mode !== 'create' || !!inquiryType) && (
            <span>
              ({shortName ?? data.initialValueIfExists?.settings?.shortName})
            </span>
          )}
        </div>
      </div>
      <AppTabsTailwind
        className={clsx(
          'mt-4 w-full',
          //  tabs.length === 1 && 'invisible'
        )}
        tabs={tabs}
        selected={currentTabId}
        theme="pills"
        breakpoint="sm"
        onChange={(tabId) => setCurrentTabId(tabId)}
      />
    </div>
  );
};
