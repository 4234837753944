/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ClubProductPackageOffer_ServiceCategory,
  ClubProductPackageOfferView,
  ClubProductPackageOfferViewDisplayGroup,
  ClubResume,
  ClubSettings,
} from '@mabadive/app-common-model';
import { offersDisplayGroupsBuilder_Explo } from './offersDisplayGroupsBuilder_Explo.hook';
import { offersDisplayGroupsBuilder_FirstDive } from './offersDisplayGroupsBuilder_FirstDive.hook';
import { offersDisplayGroupsBuilder_Store } from './offersDisplayGroupsBuilder_Store.hook';
import { offersDisplayGroupsBuilder_Training } from './offersDisplayGroupsBuilder_Training.hook';

export const offersDisplayGroupsBuilder = {
  buildGroups,
};
function buildGroups({
  clubResume,
  offers,
  contextMode,
}: {
  clubResume: ClubResume;
  offers: ClubProductPackageOfferView[];
  contextMode: ClubProductPackageOffer_ServiceCategory;
}) {
  const clubSettings: ClubSettings = clubResume.clubSettings;

  if (contextMode === 'first-dive') {
    return offersDisplayGroupsBuilder_FirstDive.buildGroups_FirstDive({
      clubSettings,
      offers,
    });
  } else if (contextMode === 'explo') {
    return offersDisplayGroupsBuilder_Explo.buildGroups_Explo({
      clubSettings,
      offers,
    });
  } else if (contextMode === 'training') {
    return offersDisplayGroupsBuilder_Training.buildGroups_Training({
      clubSettings,
      offers,
    });
  } else if (contextMode === 'store') {
    return offersDisplayGroupsBuilder_Store.buildGroups_Store({
      categories: clubResume.productPackageOfferCategories,
      offers,
    });
  } else {
    const offersByDisplayGroups: ClubProductPackageOfferViewDisplayGroup[] = [];
    offersByDisplayGroups.push({
      offers: offers,
    });
    return offersByDisplayGroups;
  }
}
