import { CustomerSpaceDiverLink } from '@mabadive/app-common-model';
import { useAppMessageParams } from 'src/pages/_components';

export function useDiverLinkParametersToReplace(
  diverLink: Pick<CustomerSpaceDiverLink, 'firstName' | 'lastName' | 'url'>,
) {
  return useAppMessageParams({
    context: 'booking',
    recipientType: 'personal',
    bookingContext: {
      customerSpaceAuthenticationUrl: diverLink.url,
      diver: {
        firstName: diverLink.firstName,
        lastName: diverLink.lastName,
      },
      bookingJourney: undefined,
    },
  });
  // const diveCenterResume = useDiveCenterResume();

  // return useMemo(() => {
  //   return {
  //     PARTICIPANT_NOM: nameFormatter.formatLastName(diverLink),
  //     PARTICIPANT_PRENOM: nameFormatter.formatFirstName(diverLink),
  //     ESPACE_CLIENT_LIEN_RESA: diverLink?.url,
  //     CENTRE_NOM: diveCenterResume?.publicData?.brand?.name,
  //     CENTRE_LIEU: diveCenterResume?.publicData?.brand?.locationResume,
  //     CENTRE_TELEPHONE: diveCenterResume?.publicData?.contact?.phoneNumber,
  //   };

  // }, [
  //   diveCenterResume?.publicData?.contact?.phoneNumber,
  //   diverLink,
  //   diveCenterResume?.publicData?.brand?.locationResume,
  //   diveCenterResume?.publicData?.brand?.name,
  // ]);
}

export type DiverLinkParametersToReplace = ReturnType<
  typeof useDiverLinkParametersToReplace
>;
