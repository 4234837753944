import React from 'react';
import { AppInputBooleanSwitch } from '../../../../../../../../lib/form';
import { DiverBookingPageGlobalState } from '../../../DiverBookingPage/useDiverBookingPageGlobalState.hook';

export const DiverBookingSpace_BookingList_SwitchArchivedBookingVisibility = ({
  globalState,
}: {
  globalState: DiverBookingPageGlobalState;
}) => {
  // const tabBooking_bookingId =
  //   globalState.state.viewState?.tabBooking_bookingId;
  const bookingsArchivedCount =
    globalState.data?.aggregatedData?.bookingResumesLoaded.filter(
      (x) => !x.booking?.active,
    ).length;

  // globalState.linkedData?.bookingArchivedIds?.length;

  return bookingsArchivedCount > 0 ? (
    <div className="flex justify-start gap-2 flex-wrap">
      <AppInputBooleanSwitch
        label={`Afficher les réservations archivées (${bookingsArchivedCount})`}
        value={globalState.includeArchivedBookings}
        onChange={(value) =>
          globalState.setIncludeArchivedBookings(
            !globalState.includeArchivedBookings,
          )
        }
      />
    </div>
  ) : null;
};
