/* eslint-disable @typescript-eslint/no-unused-vars */
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useRedirect } from 'src/business/_core/data/hooks';
import { useAutoFocus } from 'src/business/_core/modules/layout';
import { useClubResume } from 'src/business/club/data/hooks';
import {
  ClubSettingsProductOfferCategoryDialogInitialState,
  ClubSettingsProductOfferCategoryEditFormModel,
} from './_model';

export function useClubSettingsProductOfferCategoryDialogLocalState({
  initialState,
}: {
  initialState: ClubSettingsProductOfferCategoryDialogInitialState;
}) {
  const autoFocus = useAutoFocus();
  const redirectTo = useRedirect();

  const clubResume = useClubResume();
  const clubReference = clubResume.reference;

  const { serviceCategory } = initialState;

  const initialFormValue: Partial<ClubSettingsProductOfferCategoryEditFormModel> =
    useMemo(() => {
      if (initialState.mode === 'create') {
        const createInitialValue: Partial<ClubSettingsProductOfferCategoryEditFormModel> =
          {
            offerCategory: {
              title: initialState.defaultValue?.offerCategory?.title ?? '',
              description:
                initialState.defaultValue?.offerCategory?.description ?? '',
              serviceCategory,
            },
          };
        return createInitialValue;
      } else {
        return initialState.defaultValue;
      }
    }, [initialState.defaultValue, initialState.mode, serviceCategory]);

  const form = useForm<ClubSettingsProductOfferCategoryEditFormModel>({
    defaultValues: initialFormValue,
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const offerCategoryId = initialState.defaultValue?.offerCategory?._id;

  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const hasFormChanges = useMemo(
    () => form && (formState.isDirty || formState.isValid),
    [form, formState.isDirty, formState.isValid],
  );

  return {
    data: {},
    state: {
      mode: initialState.mode,
      offerCategoryId,
      form,
      hasFormChanges,
      initialState,
      // filters,
      // setFilters,
      // trainingCommercialCategoriesOptions,
      // organizationsOptions,
    },
  };
}

export type ClubSettingsProductOfferCategoryDialogLocalState = ReturnType<
  typeof useClubSettingsProductOfferCategoryDialogLocalState
>;
