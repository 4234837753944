/* eslint-disable @typescript-eslint/no-unused-vars */
import { nameFormatter } from '@mabadive/app-common-services';
import React, { useMemo } from 'react';
import {
  AppButton,
  AppMessage,
  AppMessageLight,
} from 'src/business/_core/modules/layout/components/_tailwind';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { ClubDialogsStateOld } from 'src/pages/_dialogs';
import { BookingTabModel } from '../../models';
import { DiverBookingPageActions } from '../../useDiverBookingPageActions.hook';
import { DiverBookingPageGlobalState } from '../../useDiverBookingPageGlobalState.hook';

export const DiverBookingPageBookingTabHeaderNoBooking = ({
  globalState,
  dialogs,
  actions,
  bookingTabModels,
}: {
  globalState: DiverBookingPageGlobalState;
  dialogs: ClubDialogsStateOld;
  actions: DiverBookingPageActions;
  bookingTabModels: BookingTabModel[];
}) => {
  const {
    aggregatedData,
    updateState,
    setUpdateState,
    focus,
    navigationContext,
    linkedData,
  } = globalState;

  const {
    openDiverSelector,
    openSessionDialog,
    openSessionSelector,
    createNewBooking,
    deleteDiverOnly,
  } = actions;

  const isFocusDiverDeleted = useMemo(
    () =>
      (updateState.diversChanges ?? []).find(
        (d) => d.pk === focus?.clubDiver?._id && d.deleted,
      ) !== undefined,
    [focus?.clubDiver?._id, updateState.diversChanges],
  );

  const bookingsArchivedCount = linkedData?.bookingArchivedIds?.length;
  return bookingTabModels.length === 0 && !!focus?.clubDiver ? (
    <>
      <h2 className="text-center text-lg leading-6 p-2 font-medium text-gray-800">
        {nameFormatter.formatFullName(focus.clubDiver, {
          format: 'firstName-first',
        })}
      </h2>
      {isFocusDiverDeleted ? (
        <AppMessage
          className="mb-5"
          title={'Plongeur supprimé'}
          message={
            <div>
              Cliquer sur "Enregistrer" pour confirmer l'effacement définitif de
              toutes les données concernant ce plongeur.
            </div>
          }
        />
      ) : (
        <div className="grid gap-8 my-4">
          <div className="grid gap-4">
            <div className="my-2 text-base font-bold uppercase text-left text-app-primary">
              Aucune réservation {bookingsArchivedCount > 0 ? ' active' : ''}
            </div>
            <div className="flex gap-4">
              <AppButton
                icon={AppHeroIcons.actionAdd}
                color="primary-outline-light"
                onClick={() => createNewBooking({ onSuccess: 'openBooking' })}
              >
                <span>Créer une réservation</span>
              </AppButton>
              {bookingsArchivedCount === 0 &&
                globalState.aggregatedData.purchasePackages.length === 0 &&
                globalState.aggregatedData.purchasePayments.length === 0 && (
                  <AppButton
                    icon={AppHeroIcons.actionRemove}
                    color="danger-outline-light"
                    onClick={() =>
                      deleteDiverOnly({
                        diverId: navigationContext.diverId,
                      })
                    }
                  >
                    <span>Supprimer ce plongeur</span>
                  </AppButton>
                )}
            </div>
          </div>
          {bookingsArchivedCount > 0 ? (
            <AppMessageLight type="danger" className="font-bold">
              <div className="grid gap-4">
                Pour supprimer ce plongeur, supprimez d'abord les{' '}
                {bookingsArchivedCount} réservations archivées.
                <AppButton
                  icon={AppHeroIcons.eye}
                  color="primary-outline-light"
                  onClick={() => {
                    globalState.setIncludeArchivedBookings(true);
                  }}
                >
                  <span>Afficher les réservations archivées</span>
                </AppButton>
              </div>
            </AppMessageLight>
          ) : globalState.aggregatedData.purchasePackages.length > 0 ? (
            <AppMessageLight type="danger" className="font-bold">
              Pour supprimer ce plongeur, supprimez d'abord la facturation
              {globalState.aggregatedData.purchasePayments.length > 0 && (
                <> et les paiements</>
              )}
              .
            </AppMessageLight>
          ) : globalState.aggregatedData.purchasePayments.length > 0 ? (
            <AppMessageLight type="danger" className="font-bold">
              Pour supprimer ce plongeur, supprimez d'abord les paiements.
            </AppMessageLight>
          ) : null}
        </div>
      )}
    </>
  ) : null;
};
