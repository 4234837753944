/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ClubProductPackageOffer_ServiceCategory,
  ClubProductPackageOfferView,
  ColoredTag,
  RegionalSettings,
} from '@mabadive/app-common-model';
import {
  diveModeFormatter,
  participantSpecialDiveTypeFormatter,
  productPackageFormatter,
} from '@mabadive/app-common-services';
import React, { useMemo } from 'react';
import {
  AppButtonMultiActions,
  ColoredTagAvatarCard,
} from 'src/business/_core/modules/layout';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import {
  useClubResume,
  useClubSettings,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import {
  AppHamburgerMenuItem,
  AppPriceLabel,
} from 'src/business/club/modules/_common/ui';
import { ClubOfferNameDetailsLabel } from 'src/business/club/modules/club-diver-participant/components';
import { participantTagsBuilder } from 'src/business/club/modules/club-planning/_components';
import {
  useAppCurrencyMain,
  useRegionalSettings,
} from 'src/pages/_components/options';
import {
  ClubDialogsState,
  useClubDialogs,
  useClubDialogsActionsPersist,
  UseClubDialogsProps,
} from 'src/pages/_dialogs';
import { ResidentTypeLabelBadge } from '../../../../../../../business/club/modules/club-diver-participant/pages/DiverBookingPage/components/ResidentTypeLabelBadge';
import { ClubSettingsServicesActivitiesViewPanelDiveMode } from '../../../tabs/01.activities/view/ClubSettingsServicesActivitiesViewPanelDiveMode';
import { useClubSettingsProductOffersFormOfferMenuItems } from './useClubSettingsProductOffersFormOfferMenuItems.hook';
import { ClubSettingsServicesViewPanelOffersPricesLocalState } from './useClubSettingsServicesViewPanelOffersPricesLocalState.hook';

export const ClubSettingsServicesViewPanelOffersPricesListItem = ({
  localState,
  offer,
  contextMode,
}: {
  localState: ClubSettingsServicesViewPanelOffersPricesLocalState;
  offer: ClubProductPackageOfferView;
  contextMode: ClubProductPackageOffer_ServiceCategory;
}) => {
  const diveCenterResume = useDiveCenterResume();
  const publicSettings = useClubSettings()?.publicSettings;
  const clubResume = useClubResume();

  const clubSettings = clubResume?.clubSettings;

  const actionsPersist: UseClubDialogsProps = useClubDialogsActionsPersist();
  const dialogsState: ClubDialogsState = useClubDialogs(actionsPersist);

  const regionalSettings: RegionalSettings = useRegionalSettings({
    effectiveDate: new Date(),
  });

  const mainCurrency = useAppCurrencyMain();

  const productAttributes = offer.productPackage.productAttributes;
  const diveAttributes = offer.productPackage.diveAttributes;
  const salesCriteria = offer.productPackage.salesCriteria;

  const diveMode = offer.productPackage.diveAttributes?.diveMode;

  const hamburgerMenuItems: AppHamburgerMenuItem[] =
    useClubSettingsProductOffersFormOfferMenuItems({
      contextMode,
      localState,
      offer,
    });

  const specialDiveType = offer.productPackage?.diveAttributes?.specialDiveType;
  const specialDiveTypeTag: ColoredTag = useMemo(() => {
    if (specialDiveType) {
      return participantTagsBuilder.getSpecialDiveTypeTag(specialDiveType);
    }
  }, [specialDiveType]);

  return (
    <div
      key={offer.reference}
      className="bg-white cursor-pointer hover:bg-gray-100 py-2 flex flex-col sm:flex-row sm:items-center sm:justify-between "
      onClick={() => {
        localState.actions.openOfferEditDialog({
          mode: 'edit',
          offers: [{ offer }],
        });
      }}
    >
      <div className="px-2 flex-grow flex items-start justify-between ">
        <div className="flex flex-col">
          <div className="flex gap-2 items-center">
            {offer.productPackage?.diveAttributes?.diveMode && (
              <ClubSettingsServicesActivitiesViewPanelDiveMode
                diveMode={offer.productPackage?.diveAttributes?.diveMode}
              />
            )}
            <ClubOfferNameDetailsLabel
              className="font-bold text-app-blue"
              productPackage={offer.productPackage}
              hideStoreCategory={true}
            />
            {specialDiveTypeTag && (
              <ColoredTagAvatarCard tag={specialDiveTypeTag} size={'normal'} />
            )}
            {salesCriteria?.dontSelectByDefault && (
              <AppHeroIcons.forbidden className="h-4 w-4 text-gray-400" />
            )}
          </div>
          <div className="flex gap-2 items-center">
            {diveMode === 'training' && (
              <div className="text-gray-600 text-xs uppercase">
                {
                  offer.productPackage.productAttributes
                    ?.defaultOrganizationReference
                }
              </div>
            )}
          </div>
          <div className="flex gap-2 items-center">
            {offer.productPackage.diveAttributes?.divesCount >= 0 && (
              <div className="flex gap-4 text-gray-600 text-xs">
                <div className="">
                  <span className="font-bold">
                    {offer.productPackage.diveAttributes?.divesCount}
                  </span>
                  <span> plongée(s)</span>
                </div>
                {specialDiveType && (
                  <div className="uppercase">
                    {participantSpecialDiveTypeFormatter.formatSpecialDiveType(
                      specialDiveType,
                      {
                        format: 'long',
                      },
                    )}
                  </div>
                )}
              </div>
            )}
            <div className="flex gap-4 text-gray-600 text-xs">
              {productAttributes.sharedOfferMaxPersonsCount > 1 && (
                <div className="text-xs text-green-700">
                  partagées entre{' '}
                  <span className="font-bold">
                    {productAttributes.sharedOfferMaxPersonsCount}
                  </span>{' '}
                  personnes max
                </div>
              )}
              {(productAttributes.minAge > 0 ||
                productAttributes.maxAge > 0) && (
                <div className="text-xs text-app-blue">
                  {productPackageFormatter.formatAgeDescription(
                    productAttributes,
                  )}
                </div>
              )}
            </div>
          </div>
          {diveAttributes?.extraDiveModes?.length > 0 && (
            <div className="text-xs">
              +{' '}
              {diveAttributes?.extraDiveModes
                .map((diveMode) =>
                  diveModeFormatter.formatDiveMode(diveMode, {
                    format: 'short-label',
                    diveModesConf: clubSettings?.ui?.diveMode,
                  }),
                )
                .join(', ')}
            </div>
          )}
          {offer.productPackage.comment?.trim().length > 0 && (
            <div className="text-xs text-gray-600">
              {offer.productPackage.comment}
            </div>
          )}
        </div>
        <div className="flex flex-col items-end">
          <div className="div text-right font-medium text-gray-600">
            {offer.price > 0 && (
              <AppPriceLabel amount={offer.price} mainCurrency={mainCurrency} />
            )}
            {offer.thirdPartyCollectPrice > 0 && (
              <div className="font-normal text-xs">
                {' '}
                (dont{' '}
                <AppPriceLabel
                  amount={offer.thirdPartyCollectPrice}
                  mainCurrency={mainCurrency}
                />{' '}
                tiers)
              </div>
            )}
            {offer.agencyNonCommissionable && (
              <div className="font-normal text-xs text-red-400">
                {' '}
                (non commissionable)
              </div>
            )}
          </div>
          {salesCriteria?.residentType && (
            <ResidentTypeLabelBadge
              className="text-xs"
              residentType={salesCriteria?.residentType}
            />
          )}
        </div>
      </div>
      <div className="text-gray-600 self-end sm:self-start">
        <AppButtonMultiActions items={hamburgerMenuItems} />
      </div>
    </div>
  );
};
