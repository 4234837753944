import { AppDurationParserString } from '@mabadive/app-common-services';
import { useMemo } from 'react';
import { PurchaseResumeCollectionCriteria } from 'src/business/_core/data/store-repository';
import {
  AppFetchDataCacheStore,
  appFetchDataCacheStoreBuilder,
} from 'src/business/_core/modules/root/app-cache/AppFetchDataCacheStore';
import { useAppFetchDataListEntitiesWithCache } from 'src/business/_core/modules/root/app-cache/AppFetchDataCacheStore/useAppFetchDataListEntitiesWithCache.hook';
import { useDiveCenterResume } from 'src/business/club/data/hooks';
import { PurchaseListPagePurchaseFetchedModel } from './model';
import { PurchaseListPageViewParameters } from './model/PurchaseListPageViewParameters.type';
import { purchasesListPageGraphqlFetcher } from './services/purchasesListPageGraphqlFetcher.service';

export const clubPurchasesCacheStore: AppFetchDataCacheStore<
  PurchaseResumeCollectionCriteria,
  PurchaseListPagePurchaseFetchedModel[]
> = appFetchDataCacheStoreBuilder.buildCache({
  baseKey: 'club-purchases',
  cacheValidity: '2 days',
});

export function useFetchPurchasesWithCache(
  viewParameters: PurchaseListPageViewParameters,
  options: {
    autoRefetchInterval: AppDurationParserString;
    staleInterval: AppDurationParserString;
  },
) {
  const diveCenterResume = useDiveCenterResume();
  const diveCenterId = diveCenterResume._id;
  const clubReference = diveCenterResume.clubReference;

  const fetchCriteria: PurchaseResumeCollectionCriteria = useMemo(() => {
    return {
      clubReference,
      diveCenterId,
      status: {
        purchasePackages: 'all',
      },
      type: 'all',
      beginPurchaseDate: viewParameters.beginPurchaseDate,
      endPurchaseDate: viewParameters.endPurchaseDate,
      validityStatus: viewParameters.validityStatus,
      billedByBookingAgencyId: viewParameters.bookingAgencyId,
      paymentStatus: viewParameters.paymentStatus,
    };
  }, [
    clubReference,
    diveCenterId,
    viewParameters.beginPurchaseDate,
    viewParameters.bookingAgencyId,
    viewParameters.endPurchaseDate,
    viewParameters.paymentStatus,
    viewParameters.validityStatus,
  ]);

  const cacheState =
    useAppFetchDataListEntitiesWithCache<PurchaseListPagePurchaseFetchedModel>(
      fetchCriteria,
      {
        store: clubPurchasesCacheStore,
        ...options,
        fetch: async (
          fetchCriteria,
          { cacheState, fetchType },
        ): Promise<PurchaseListPagePurchaseFetchedModel[]> => {
          // NOTE: maxResults ne doit pas être dans les criteria, sinon il va impacter la clé du cache

          return await purchasesListPageGraphqlFetcher
            .findMany(
              {
                ...fetchCriteria,
                maxResults: 1000,
                updatedAfter:
                  fetchType === 'partial'
                    ? cacheState?.fetchResult?.dataModifiedAt
                    : undefined,
              },
              { type: 'query' },
            )
            .toPromise();
        },
      },
    );

  return cacheState;
}
