/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ClubProductPackageMeta,
  ClubProductPackageOfferView,
} from '@mabadive/app-common-model';
import {
  clubProductPackageMetaReader,
  clubPurchasePackageMetaReader,
  nameFormatter,
} from '@mabadive/app-common-services';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import {
  AppButtonsBar,
  AppPageBlock,
  AppPageContainerWithFixedBars,
} from 'src/business/_core/modules/layout';
import { confirmDialog } from 'src/business/_core/modules/layout/components/ConfirmDialog/confirmDialog.service';
import { AppButtonDeprecated } from 'src/business/_core/modules/layout/components/_tailwind';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { useGlobalClasses } from 'src/business/_core/modules/root/theme';
import { useAppSecurityUser } from 'src/business/auth/services';
import {
  useClubResume,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import { ClubPurchasesExtraCostDescription } from 'src/pages/LI-lists/LI-03_purchase/ClubPurchasesTable/ClubPurchasesExtraCostDescription';
import {
  DiverPurchaseCommonEditorDialogResult,
  DiverPurchaseStoreEditorDialogState,
} from '../../models';
import {
  DiverPurchaseStoreFormModel,
  DiverPurchaseStoreFormRHF,
} from './components';
import { useDiverPurchaseStorePackageOffers } from './components/DiverPurchaseStoreForm/useDiverPurchaseStorePackageOffers.hook';
import { diverPurchaseStoreEditorDialogUpdateBuilder } from './diverPurchaseStoreEditorDialogUpdateBuilder.service';
import { useDiverPurchaseStoreEditorDialogInitialValue } from './useDiverPurchaseStoreEditorDialogInitialValue.hook';

export const DiverPurchaseStoreEditorDialog: FC<{
  state: DiverPurchaseStoreEditorDialogState;
  setState: React.Dispatch<
    React.SetStateAction<DiverPurchaseStoreEditorDialogState>
  >;
  onConfirm?: (result: DiverPurchaseCommonEditorDialogResult) => void;
}> = ({ onConfirm, state, setState }) => {
  // eslint-disable-next-line no-console
  console.log('[OPEN xxx] DiverPurchaseStoreEditorDialog');

  const globalClasses = useGlobalClasses();

  const {
    diver,
    purchasePackage: initialPurchasePackage,
    mode,
    aggregatedData,
  } = state;

  const setIsOpen = useCallback(
    (isOpen: boolean) => {
      setState({
        ...state,
        isOpen,
      });
    },
    [setState, state],
  );

  const diveCenterResume = useDiveCenterResume();
  const clubResume = useClubResume();
  const clubSettings = clubResume?.clubSettings;
  const diveCenterId = diveCenterResume?._id;
  const clubReference = diveCenterResume?.clubReference;

  const initialPurchasePackageMeta = useMemo(
    () => clubPurchasePackageMetaReader.readMeta(initialPurchasePackage),
    [initialPurchasePackage],
  );

  const productPackageOffers = useDiverPurchaseStorePackageOffers();

  const initialFormValue = useDiverPurchaseStoreEditorDialogInitialValue({
    initialPurchasePackage, // undefined if 'create' mode
    initialPurchasePackageMeta,
    productPackageOffers,
    mode,
    clubSettings,
    aggregatedData,
    diveCenterId,
  });

  const form = useForm<DiverPurchaseStoreFormModel>({
    defaultValues: initialFormValue,
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const { register, handleSubmit, watch, formState, control, setValue } = form;

  const [productPackageOfferReference, extraCosts, consumedExternalCount] =
    useWatch({
      control,
      name: [
        'productPackageOfferReference',
        'extraCosts',
        'consumedExternalCount',
      ],
    });

  const selectedOffer: ClubProductPackageOfferView = useMemo(
    () =>
      productPackageOfferReference
        ? productPackageOffers.find(
            (o) => o.reference === productPackageOfferReference,
          )
        : undefined,
    [productPackageOfferReference, productPackageOffers],
  );

  const selectedOfferProductPackageMeta: ClubProductPackageMeta =
    useMemo(() => {
      if (selectedOffer) {
        const meta = clubProductPackageMetaReader.readMeta(
          selectedOffer.productPackage,
        );
        return meta;
      }
    }, [selectedOffer]);

  const unitPrice: number = useWatch({
    control,
    name: 'unitPrice',
  });

  const securityUser = useAppSecurityUser();

  const [pageLoaded, setPageLoaded] = useState(false);

  const isPaymentEnabled = useMemo(
    () => securityUser?.roles.includes('club-edit-participant-payment'),
    [securityUser.roles],
  );

  const creditsInitialCount: number = useWatch({
    control,
    name: 'creditsInitialCount',
  });

  const submitForm = useCallback(() => {
    handleSubmit(
      (formValue: DiverPurchaseStoreFormModel, event) => {
        if (!!selectedOffer) {
          const { result, hasChanges } =
            diverPurchaseStoreEditorDialogUpdateBuilder.buildUpdateResult({
              diverId: diver._id,
              clubReference,
              diveCenterId,
              initialPurchasePackage,
              selectedOffer,
              selectedOfferProductPackageMeta,
              formValue,
              isDirty: formState.isDirty, // Note: il semble que formState n'est pas immuable, donc il faut avoir un observer sur formState.isDirty et pas juste sur formState
              state,
              isPaymentEnabled,
              clubSettings,
            });

          if (!hasChanges) {
            // no changes
            setIsOpen(false);
            return;
          } else {
            onConfirm(result);
          }
        }
      },
      (err) => {
        console.log('xxx submitForm error', err);
      },
    )();
  }, [
    handleSubmit,
    selectedOffer,
    diver._id,
    clubReference,
    initialPurchasePackage,
    selectedOfferProductPackageMeta,
    formState.isDirty,
    state,
    diveCenterId,
    isPaymentEnabled,
    clubSettings,
    setIsOpen,
    onConfirm,
  ]);

  return !state.isOpen ? null : (
    <>
      <AppPageContainerWithFixedBars
        className={state.isOpen ? '' : 'hidden'}
        contentClassName="bg-white"
        footerBar={() => (
          <AppButtonsBar className="" hasChanges={true}>
            <>
              <AppButtonDeprecated
                icon={AppHeroIcons.actionCancel}
                buttonStyle="outline-transparent"
                size="normal"
                onClick={() => {
                  setIsOpen(false);
                }}
              >
                Annuler
              </AppButtonDeprecated>
              {initialPurchasePackage && (
                <AppButtonDeprecated
                  icon={AppHeroIcons.actionRemove}
                  buttonStyle="danger"
                  className="mx-5"
                  onClick={async () => {
                    const confirm = await confirmDialog.confirmPromise({
                      title: 'Supprimer cette prestation',
                      message:
                        'Êtes-vous sûr de vouloir supprimer cette prestation?',
                      type: 'remove',
                    });
                    if (confirm) {
                      // supprimer l'état "pourchasé" sur tous les produits liés

                      onConfirm({
                        deletedPurchasePackageId: initialPurchasePackage._id,
                      });
                    }
                  }}
                >
                  Supprimer
                </AppButtonDeprecated>
              )}
              <AppButtonDeprecated
                icon={AppHeroIcons.actionSave}
                buttonStyle="outline-transparent-primary"
                onClick={() => {
                  submitForm();
                }}
              >
                Confirmer
              </AppButtonDeprecated>
            </>
          </AppButtonsBar>
        )}
      >
        <AppPageBlock className="px-2">
          {diver && (
            <h2 className="text-center text-lg leading-6 p-2 font-medium text-app-blue">
              {nameFormatter.formatFullName(diver, {
                format: 'firstName-first',
              })}
            </h2>
          )}
          <div className={globalClasses.pageBlock}>
            {state.isOpen && initialFormValue && (
              <>
                <DiverPurchaseStoreFormRHF
                  form={form}
                  diver={diver}
                  initialBookingId={initialFormValue?.bookingId}
                  productPackageOffers={productPackageOffers}
                  isCreation={mode === 'create'}
                  aggregatedData={state?.aggregatedData}
                  purchasePackage={state?.purchasePackage}
                />
              </>
            )}

            {extraCosts?.length > 0 && (
              <div className="my-2">
                <h2 className="text-left text-lg leading-6 p-2 font-medium text-app-primary">
                  Suppléments
                </h2>
                <ClubPurchasesExtraCostDescription
                  extraCosts={extraCosts}
                  className="pl-5 text-base"
                />
              </div>
            )}
          </div>
        </AppPageBlock>
      </AppPageContainerWithFixedBars>
    </>
  );
};
