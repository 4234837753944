import { dateService, nameFormatter } from '@mabadive/app-common-services';
import { useEffect } from 'react';
import { appLogger } from 'src/business/_core/modules/root/logger';
import { useDiveCenterResume } from 'src/business/club/data/hooks';
import { ClubDialogsStateOld } from 'src/pages/_dialogs';
import { BookingMassiveEditorDialogInputState } from './components/BookingMassiveEditorDialog';
import { buildParticipantEditorEditOpenInputState } from './components/useDiverBookingPageBookingCardLocalState.hook';
import {
  BookingParticipantEditorParticipant,
  DiverBookingPageTriggerAction,
} from './models';
import {
  updateStateWithInitialActionCreateBookingInitialPlan,
  updateStateWithInitialActionCreateBookingParticipant,
} from './services';
import { DiverBookingPageActions } from './useDiverBookingPageActions.hook';
import { DiverBookingPageGlobalState } from './useDiverBookingPageGlobalState.hook';

export function useDiverBookingPageTriggerAction({
  globalState,
  dialogs,
  actions,
  triggerAction,
  setTriggerAction,
}: {
  globalState: DiverBookingPageGlobalState;
  dialogs: ClubDialogsStateOld;
  actions: DiverBookingPageActions;
  triggerAction: DiverBookingPageTriggerAction;
  setTriggerAction: (triggerAction: DiverBookingPageTriggerAction) => any;
}): void {
  const diveCenterResume = useDiveCenterResume();

  const { updateState, setUpdateState, aggregatedData } = globalState;

  const { setBookingParticipantEditorState, setPaymentEditorDialogState } =
    dialogs;

  const { openSessionSelector } = actions;

  useEffect(() => {
    if (
      updateState.isInitialized &&
      triggerAction?.id &&
      aggregatedData?.isFullyLoaded
    ) {
      let localUpdateState = updateState;

      // avoid to trigger the action twice
      setTriggerAction(undefined);

      appLogger.info(
        `[useDiverBookingPageTriggerAction] trigger action: ${triggerAction?.id}`,
      );

      switch (triggerAction?.id) {
        case 'create-booking-initial-plan': {
          const { diverId, diveSessionReference } = triggerAction;
          const result = updateStateWithInitialActionCreateBookingInitialPlan({
            diverId,
            diveSessionReference,
            aggregatedData,
            updateState: localUpdateState,
            diveCenterResume,
          });
          if (result.updatedState) {
            localUpdateState = result.updatedState;
            setUpdateState(localUpdateState, {
              action: triggerAction?.id,
              meta: {
                diverId,
                diveSessionReference,
              },
            });
          }
          setTriggerAction(result.triggerNextAction);
          break;
        }
        case 'open-session-selector-dialog': {
          openSessionSelector({
            bookingId: triggerAction.bookingId,
            defaultFocusDate: triggerAction.defaultFocusDate,
          });
          break;
        }
        case 'create-booking-participant': {
          const { diverId, diveSessionReference } = triggerAction;

          const result = updateStateWithInitialActionCreateBookingParticipant({
            diverId,
            diveSessionReference,
            aggregatedData,
            updateState: localUpdateState,
            diveCenterResume,
          });
          if (result.updatedState) {
            // TODO, si c'est une nouvelle session, elle n'est pas trouvée
            const diver = aggregatedData.divers.find((x) => x._id === diverId);
            const diveSession = aggregatedData.diveSessions.find(
              (x) => x.reference === diveSessionReference,
            );
            localUpdateState = result.updatedState;

            setUpdateState(localUpdateState, {
              action: triggerAction?.id,
              meta: {
                diverName: diver
                  ? nameFormatter.formatFullName(diver)
                  : undefined,
                sessionTime: diveSession
                  ? dateService.formatUTC(diveSession.time, 'DD/MM/YYYY HH:mm')
                  : undefined,
                diverId,
                diveSessionReference,
              },
            });
          }
          setTriggerAction(result.triggerNextAction);

          break;
        }
        case 'open-create-participant-dialog': {
          const { participant } = triggerAction;
          setBookingParticipantEditorState({
            isOpen: true,
            mode: 'create-participant',
            participant,
          });
          break;
        }
        case 'open-massive-editor-dialog': {
          const { bookingId, bookingSessionsIds } = triggerAction;

          const inputState: BookingMassiveEditorDialogInputState = {
            isOpen: true,
            bookingId,
            loadedContent: globalState.loadedContent,
            updateState: globalState.updateState,
            bookingSessionsIds,
          };
          actions.dialogs.setBookingMassiveEditorDialogState(inputState);
          break;
        }
        case 'edit-booking-participant': {
          const { diverId, diveSessionReference } = triggerAction;

          const bookingParticipantFull =
            aggregatedData.bookingParticipantsFull.find(
              (x) =>
                x.diver._id === diverId &&
                x.diveSession.reference === diveSessionReference,
            );
          if (bookingParticipantFull) {
            const participant: BookingParticipantEditorParticipant =
              buildParticipantEditorEditOpenInputState({
                bookingParticipantFull,
              });
            setBookingParticipantEditorState({
              isOpen: true,
              mode: 'edit-participant',
              participant,
            });
          }
          break;
        }
        case 'open-payment-edit-dialog': {
          const { purchasePaymentId } = triggerAction;
          if (purchasePaymentId) {
            // const p = aggregatedData.

            const purchasePayment = aggregatedData.purchasePayments.find(
              (p) => p._id === purchasePaymentId,
            );

            if (!purchasePayment) {
              appLogger.error(`Error opening payment "${purchasePaymentId}"`);
            } else {
              const diver = aggregatedData.divers.find(
                (d) => d._id === purchasePayment.diverId,
              );
              if (!diver) {
                appLogger.error(
                  `Error opening payment "${purchasePaymentId}" for diver "${purchasePayment.diverId}"`,
                );
              } else {
                setPaymentEditorDialogState({
                  isOpen: true,
                  mode: 'edit',
                  aggregatedData,
                  purchasePayment,
                  // dans un autre contexte, on met à jour 'bookingDepositsWithPayments' avant d'ouvrir le dialog, donc on ne le prend pas toujours dans aggregatedData
                  bookingDepositsWithPayments:
                    aggregatedData.bookingDepositsWithPayments,
                });
              }
            }
          }
          break;
        }
      }
    }
  }, [
    updateState.isInitialized,
    triggerAction,
    updateState,
    aggregatedData,
    setTriggerAction,
    setUpdateState,
    openSessionSelector,
    setBookingParticipantEditorState,
    setPaymentEditorDialogState,
    actions.dialogs,
    globalState.loadedContent,
    globalState.updateState,
    diveCenterResume,
  ]);
}
